
.flex-container#tableOfContents {
	flex-direction: row;
	flex-wrap: wrap;
	padding:0 20px;
}
.toc-6 .flex-container#tableOfContents,
.toc-7 .flex-container#tableOfContents,
.toc-5 .flex-container#tableOfContents {
	padding:0;
}
.toc-6 .gallery-settings {
	position: absolute;
	width: 600px;
	top: auto;
}
.gallery-settings {
	display: flex;
	text-transform: uppercase;
	width: 100%;
	font-size: 13px;
	padding:10px 20px;
	margin-top: -70px;
	justify-content: space-between;
	box-shadow: 0 -5px 10px rgba(0,0,0,0.05);
	opacity: 0;
	visibility: hidden;
	transition:all 0.3s;
	transform: translateY(10px);
	position: sticky;
	top: -80px;
	z-index: 4;
	background: #fff;
}
.grid-container:hover .gallery-settings {
	opacity: 1;
	visibility: visible;
	transform: none;
}
.add-photo {
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	color:#fff;
	padding:15px 30px;
	border-radius: 50px;
	cursor: pointer;
	display: flex;
	align-items: center;
}
.add-photo:hover {
	background: linear-gradient(-135deg, #28C76F 0%, #28C76F 100%);
}
.add-photo img {
	width: 12px;
	display: inline-block;
	margin-right: 15px;
}


.design-block-background-wrapper .gallery-title-wrapper {
	position: relative;
	z-index: 3;
}
.gallery-title-wrapper p {
	margin:0;
}
.gallery-title {
	width: 100%;
	max-width: 1300px;
	margin:0 auto;
	padding:60px;
}
.gallery-title h1,
.gallery-title h2,
.gallery-title h3,
.gallery-title h4 {
	margin:0 0 0px 0!important;
	padding:0;
}
.gallery-title h1 {
	font-size: 55px
}
.gallery-title h2 {
	font-size: 35px;
}
.gallery-title h3 {
	font-size: 25px;
}
#tableOfContents .grid-container {
	width: calc(100% - 360px);
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	padding-top: 30px;

}
#tableOfContents .grid-container.full-width {
	width: 100%;
	padding:0 30px;
	max-width: 1200px;
	margin:0 auto;
}
#tableOfContents .grid-container.full-width a.larger:not(.filter-item) img {
	border-radius: 6px;
}
#tableOfContents .grid-container.full-width a.larger:not(.filter-item) {
	border:solid 10px transparent;
}

.grid-container.full-width .gallery-image-wrapper:before {
	padding-bottom: 80%;
}

.grid-container a.larger:not(.filter-item) {
	width: 33.333%;
	color: inherit;
	position: relative;
	overflow-y: hidden;
	opacity: 0;
	cursor: pointer;
	animation: fadeIn .7s forwards;
	animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
.grid-container a:nth-child(2){
	animation-delay: 0.1s;
}
.grid-container a:nth-child(3){
	animation-delay: 0.2s;
}
.grid-container a:nth-child(4){
	animation-delay: 0.1s;
}
.grid-container a:nth-child(5){
	animation-delay: 0.2s;
}
.grid-container a:nth-child(6){
	animation-delay: 0.3s;
}
.grid-container a:nth-child(7){
	animation-delay: 0.3s;
}
.grid-container a:nth-child(8){
	animation-delay: 0.4s;
}
.grid-container a:nth-child(9){
	animation-delay: 0.5s;
}
.grid-container.smaller a {
	width: 25%;
}
.grid-container.smaller .month-title {
	width: 25%;
	margin-right: -25%;
	
}

.grid-container .month-title {
	width: 33.333%;
	margin-right: -33.333%;
	position: relative;
	padding:10px;
	z-index: 2;
}

.grid-container .month-title span {
	padding:5px 10px;
	border-radius: 20px;
	background: #fff;
	font-size: 13px;
	text-transform: uppercase;
}
.grid-container a:hover img:not(.like-icon) {
	opacity: 0.73;
}
.grid-container a img:not(.like-icon) {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	object-fit: cover;
	transition: all 0.3s ease;
}
.grid-container a .like-icon {
	width: 10px;

	height: 10px;
	 margin-top: 4px;
    margin-right: 5px;

}
.grid-container a .details {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background: rgba(255,255,255,1);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 3;
	opacity: 0;
	transition: 0.3s all;
	font-size: 23px;
	font-weight: 400;
}
.grid-container a:hover .details {
	opacity: 1;
}
.grid-container a .details i {
	width: 30px;
	height: 30px;
	font-style: normal;
	border-radius: 50%;
	border:solid 1px #000;
	text-align: center;
	line-height: 30px;
	margin-top: 80px;
	opacity: 0;
	font-size: 18px;
	font-weight: 400;
	transition:all 0.3s;
}
.grid-container a:hover .details i {
	opacity: 1;
	margin-top: 10px;
}
.grid-container a img:hover {
	opacity: 0.6;
}
.grid-container a span {
	position: absolute;
	display: flex;
	color: #fff;
	bottom: 20px;
	left: 20px;
	z-index: 4;
	font-size: 13px;
}
.grid-container .gallery-image-wrapper:before {
	content: "";
  display: block;
  padding-bottom: 100%;
  /*background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);*/
  position: relative;
  z-index: 2;
  opacity: 0.2;
}

.image-display-square .grid-container .gallery-image-wrapper:before {
	padding-bottom: 100%!important;
}

.image-display-landscape .grid-container .gallery-image-wrapper:before {
	padding-bottom: 66.666%!important;
}

.image-display-portrait .grid-container .gallery-image-wrapper:before {
	padding-bottom: 130%!important;
}



.grid-container .duration {
	font-size: 11px;
	color: #fff;
	position: absolute;
	bottom: 70px;
	right: 10px;
	background: #000;
	padding:3px 6px;
	font-style: normal;
	border-radius: 4px;
	z-index: 2;
}

#tableOfContents .info-container {
	width: 260px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    height: calc(100vh - 80px);
    position: relative;
    padding: 80px;
    opacity: 0;
	animation: fadeIn .7s forwards;
	animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
.toc-1 .flex-container#tableOfContents .gallery-title-wrapper,
.toc-7 .flex-container#tableOfContents .gallery-title-wrapper,
.toc-4 .flex-container#tableOfContents .gallery-title-wrapper {
	max-width: 1100px;
	margin:0 auto;
}
.toc-1 .flex-container#tableOfContents .gallery-title-wrapper .gallery-title,
.toc-7 .flex-container#tableOfContents .gallery-title-wrapper .gallery-title,
.toc-4 .flex-container#tableOfContents .gallery-title-wrapper .gallery-title {
	padding:60px 0;
}
.toc-1 .flex-container#tableOfContents .grid-container,
.toc-7 .flex-container#tableOfContents .grid-container,
.toc-4 .flex-container#tableOfContents .grid-container {
	width: 100%;
	max-width: 1100px;
	justify-content: flex-start;
	margin:0 auto 30px auto;
}

.toc-7 .flex-container#tableOfContents .grid-container {
	max-width: 1440px;
}
.toc-4 .flex-container#tableOfContents .grid-container {
	max-width: 1300px;
}
.toc-7 .grid-container .gallery-image-wrapper:before,
.toc-4 .grid-container .gallery-image-wrapper:before {
	padding-bottom: 75%;
	/*background: rgba(0,0,0,0.3);*/
}	
.toc-7 .grid-container .gallery-image-wrapper:hover:before,
.toc-4 .grid-container .gallery-image-wrapper:hover:before {
	opacity: 1;
}
.toc-4 .grid-container a.larger .gallery-item-title {
	display: none;
}
.toc-7 .grid-container a.larger .gallery-item-title {
	position: absolute;
	bottom: 30px;
	left: 0;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #fff!important;
    opacity: 0;
    margin: 0;
    transition: all 0.3s;
    transform: translateY(20px);
    z-index: 2;
}

.toc-7 .grid-container a:hover img:not(.like-icon),
.toc-4 .grid-container a:hover img:not(.like-icon) {
	opacity: 1;
}

.toc-7 .grid-container a.larger:hover .gallery-item-title {
	opacity: 1;
	transform: none;
}
.toc-4 .grid-container a.larger {
	border:solid 3px transparent;
}
.toc-4.edit-body-mode .grid-container a.larger:nth-child(2)
/*.toc-4.edit-body-mode .grid-container a.larger:nth-child(3)*/ {
	width: 50%;
}

.toc-4 .grid-container a.larger:nth-child(1),
.toc-4 .grid-container a.larger:nth-child(2) {
	width: 50%;
}

.toc-1 .flex-container#tableOfContents .grid-container a .gallery-item-title {
	display: none;
}

.toc-1 .flex-container#tableOfContents .info-container {
	display: none;
}

.toc-2 .flex-container#tableOfContents,
.toc-3 .flex-container#tableOfContents {
	flex-direction: row;
}
.toc-2 .flex-container#tableOfContents .info-container,
.toc-3 .flex-container#tableOfContents .info-container,
.toc-5 .flex-container#tableOfContents .info-container {

	display: none;
}	
.toc-2 .flex-container#tableOfContents .grid-container,
.toc-5 .flex-container#tableOfContents .grid-container {
	width: 100%;
	max-width: 700px;
	margin:0 auto;
}

.toc-3 .flex-container#tableOfContents .grid-container {
	max-width: 1300px!important;
	width: 100%;
	margin:0 auto;
	padding:30px;
}
.gallery-image-wrapper {
	width: 100%;
	position: relative;
}
.gallery-image-wrapper img {
	display: block;
}
.toc-2 .flex-container#tableOfContents .grid-container a,
.toc-3 .flex-container#tableOfContents .grid-container a,
.toc-5 .flex-container#tableOfContents .grid-container a{
	width: 100%;
	height: auto;
	padding-bottom:20px;
	margin-bottom: 0px;
}
.toc-2 .flex-container#tableOfContents .grid-container a:hover img,
.toc-3 .flex-container#tableOfContents .grid-container a:hover img,
.toc-5 .flex-container#tableOfContents .grid-container a:hover img {
	opacity: 1;
}
.toc-2 .flex-container#tableOfContents .grid-container a .gallery-item-title,
.toc-5 .flex-container#tableOfContents .grid-container a .gallery-item-title {
	margin:0;
	padding:20px 0px 0 0px;
	font-size: 16px;
}

.toc-5 .flex-container#tableOfContents .grid-container a .gallery-item-title {
	font-size: 20px;
}
.gallery-image-wrapper {
	width: 100%;
	position: relative;
}
.toc-2 .flex-container#tableOfContents .grid-container .gallery-image-wrapper:before,
.toc-5 .flex-container#tableOfContents .grid-container .gallery-image-wrapper:before  {
	display: none;
}
.toc-2 .flex-container#tableOfContents .grid-container a  img,
.toc-5 .flex-container#tableOfContents .grid-container a  img {
	position: relative;
	height: auto;
}

.toc-3 .flex-container#tableOfContents .grid-container,
.toc-5 .flex-container#tableOfContents .grid-container {
	justify-content: space-between;
	max-width: 1000px;
}
.toc-3 .flex-container#tableOfContents .grid-container a {
	width: calc(50% - 20px);
	padding-bottom: 0;
	border-bottom:none;
	overflow: visible;
	margin-bottom: 40px;
}

.toc-3 .flex-container#tableOfContents .grid-container a .gallery-item-title {
	font-weight: normal;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-top: 10px;
}

.toc-5 .flex-container#tableOfContents .grid-container {
	max-width: 1440px;
}
.toc-5 .flex-container#tableOfContents .grid-container a {
	width: 100%;
	display: flex;
	flex-direction: row;
	    justify-content: space-between;
    height: auto;
    align-items: center;
    min-height: auto;
    border-bottom: none;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0;
}
.toc-5 .flex-container#tableOfContents .grid-container a:nth-child(even) {
	flex-direction: row-reverse;
}
.toc-5 .flex-container#tableOfContents .grid-container a .gallery-image-wrapper {
	width: 50%;
	object-fit: contain;
	background: transparent;
}
.toc-5 .flex-container#tableOfContents .grid-container a .gallery-item-title {
	width: 50%;
	padding: 0px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.toc-6 .gallery-title {
	width: 100%;
	max-width: 100%;
	padding-left: 105px;
	text-align: left;
	margin:50px 0;
}
.toc-6 .gallery-title h1 {
	margin-bottom: 0;
}
.toc-6 #tableOfContents .grid-container {
	flex-wrap: nowrap;
	padding-left: 100px;
	overflow:auto;
	width: 100%;
}
.toc-6 .grid-container {
	margin-bottom: 50px;
}
.toc-6 .grid-container a.larger:not(.filter-item) {
	width: auto;
	overflow-y: initial;
}

.toc-6 .grid-container .gallery-image-wrapper:before {
	display: none;
}

.toc-6 .grid-container a img:not(.like-icon) {
	position: relative;
	height: 450px;
	width: auto;
	margin-right: 5px;
}

.toc-6 .grid-container .gallery-item-title {
	display: none;
}

.toc-1 .flex-container#tableOfContents .grid-container a {
	width: calc(33.333% - 10px);
	margin: 5px;
}


/***RESPONSIVE***/
@media (max-width: 779px) {
	#tableOfContents .info-container {
		padding: 10px 30px 30px 30px;
		width: 100%;
		flex-direction: row;
		flex-wrap: wrap;
		height: auto;
	}

	.toc-7 .flex-container#tableOfContents .grid-container a.larger, 
	.toc-4 .flex-container#tableOfContents .grid-container a.larger {
		width: 50%;
	}

	.toc-4 .flex-container#tableOfContents .grid-container a.larger {
		width: 50%;
		margin:0;
	}
	.gallery-title {
		padding:10px 20px;
	}
	#tableOfContents .info-container .toc-filter {
		display: none;
	}
	#tableOfContents .info-container .toc-logo {
		margin-right: 30px;
		width: 55px;
		height: 55px;
	}
	#tableOfContents .info-container .info-wrapper {
		width: calc(100% - 90px);
	}
	.info-wrapper .profile-stats {
		position: relative;
		left: auto;
		bottom: auto;
		margin-left: -10px;
		margin-bottom: 20px;
	}
	#tableOfContents .grid-container {
		width: 100%;
		height: auto;
		padding-top: 0;
	}
	.toc-1 .flex-container#tableOfContents .grid-container a {
		width: calc(50% - 2px);
		margin: 1px;
	}
	.grid-container.smaller a {
		width: 33.333%;
	}
	.grid-container.smaller .month-title {
		display: none;
	}
	#tableOfContents .grid-container a span {
		left: 10px;
		bottom: 10px;
	}
	#tableOfContents .grid-container.full-width {
		padding: 0 10px;
	}
	#tableOfContents .grid-container.full-width a:not(.filter-item) {
		border-width: 3px;
		width: 50%;
	}
	#tableOfContents .grid-container.full-width a:not(.filter-item) img {
		border-radius: 3px;
	}
	.toc-filter.fixed {
		top: 70px;
		left: 20px;
		transform: scale(0.7,0.7);
    	transform-origin: 0 0;
    	z-index: 3;
	}
	.toc-4 #tableOfContents .info-container {
		padding:0;
	}
	.toc-4 .grid-container a.larger {
		width: calc(50% - 10px);
		margin-left:5px;
		margin-right: 5px;
	}
	
	.toc-2 .flex-container#tableOfContents .grid-container a,
	.toc-3 .flex-container#tableOfContents .grid-container a,
	.toc-5 .flex-container#tableOfContents .grid-container a {
		width: 100%!important;
		display: block;
	}
	.toc-5 .flex-container#tableOfContents .grid-container a {
		margin-bottom: 20px;
		padding-bottom:0px;
		padding-top: 0;
	}
	.toc-5 .flex-container#tableOfContents .grid-container a .gallery-image-wrapper,
	.toc-5 .flex-container#tableOfContents .grid-container a .gallery-item-title {
		width: 100%;
	}
	.toc-5 .flex-container#tableOfContents .grid-container a .gallery-item-title {
		margin-top: 20px;
	}

	.toc-6 #tableOfContents .grid-container {
		padding-left: 30px;
	}
	.toc-6 .gallery-title {
		padding-left: 30px;
	}
	.toc-6 .gallery-settings {
		justify-content: flex-start;
		margin-top: -60px;
		padding-left: 0;
	}
	.toc-6 .gallery-settings .gallery-filter {
		display: none;
	}
	.toc-6 .add-photo {
		margin:0;
	}
	.toc-6 #tableOfContents .grid-container {
		padding-top: 60px;
	}
	.toc-6 .grid-container a img:not(.like-icon) {
		height: 300px;
	}

	.edit-body-mode .gallery-title {
		padding-top: 40px;
	}
	.gallery-title {
		margin-bottom: 00px;
		margin-top: 0px;
	}
	.flex-container#tableOfContents .gallery-title-wrapper .gallery-title {
		padding:40px 20px;
	}
	.toc-7 .flex-container#tableOfContents .gallery-title-wrapper .gallery-title,
	.toc-4 .flex-container#tableOfContents .gallery-title-wrapper .gallery-title,
	.toc-1 .flex-container#tableOfContents .gallery-title-wrapper .gallery-title {
		padding:40px 20px;
	}
	.flex-container#tableOfContents .grid-container a .gallery-item-title {
		padding-left: 20px!important;
		padding-right: 20px!important;
	}
}
