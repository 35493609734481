#videoContents {
	width: 100%;
	padding:0px 20px;
	margin:0 auto;
	flex-wrap: wrap;
}

#videoContents .gallery-title-wrapper {
	width: 100%;
	max-width: initial;
}

.video-categories {
	width: 200px;
}

.videos-grid {
	width: calc(100% - 200px);
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 40px;
}

.videos-grid .video-box {
	width: calc(33.333% - 20px);
    margin: 0 10px 30px 10px;
	cursor: pointer;
	position: relative;
}

.videos-2 .videos-grid .video-box {
	width: calc(50% - 40px);
}

.video-box h4 {
	margin:10px 0 0 0!important;
	font-weight: 600!important;
	font-size: 16px!important;
    width: 100%;
}

.video-box-image {
	width: 100%;
	position: relative;
	overflow:hidden;
	border-radius: 6px;
}

.video-box-image .duration {
	padding:3px 6px;
	background: rgba(0,0,0,0.8);
	font-size: 12px;
	text-transform: none;
	font-style: normal;
	position: absolute;
	bottom: 10px;
	right: 10px;
	border-radius: 4px;
	color: #fff;
	z-index: 7;
}

.video-box-image .play-icon {
	width: 40px;
	position: absolute;
	left: calc(50% - 20px);
	top: calc(50% - 20px);
	z-index: 5;
	opacity: 0;
	transform: translateY(10px);
	transition:all 0.3s;
}

.video-box-image:before {
	padding-bottom: 56.25%;
	content: " ";
	display: block;
	width: 100%;
	position: relative;
	z-index: 4;
	background: #000;
	opacity: 0;
	transition: all 0.3s;
}

.video-box:hover .video-box-image .play-icon {
	opacity: 1;
	transform: none;
}
.video-box:hover .video-box-image:before {
	opacity: 0.3;
}
.video-box-image .video-thumb {
	width: 100%;
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	border-radius: 6px;
}
.video-categories .add-photo {
	display: inline-block;
	padding:10px 20px;
	font-size: 14px;
	margin-top: 20px;
	opacity: 0.2;

	transition: all 0.2s;
	text-transform: uppercase;
}
.video-categories:hover .add-photo {
	opacity: 1;
}
.empty-store-placeholder-item.video-ph .add-photo {
	z-index: 3;
}
.empty-store-placeholder-item.video-ph:before {
	padding-top: 60%;

}
.empty-store-placeholder-item.video-ph:after {
	 width: 0;
      height: 0;
      border-top: 20px solid transparent;
      border-left: 30px solid #ccc;
      background: transparent;
      border-bottom: 20px solid transparent;
      left: calc(50% - 15px);
      bottom: calc(50% - 20px);
}
.video-categories {
	font-size: 18px;
}

.video-categories .playlist-item {
	padding:5px 20px;
	margin:5px 2px;
	cursor: pointer;
	position: relative;
	opacity: 0.5;
	background: #fff;
	border-radius: 4px;
	box-shadow: 0 4px 8px rgba(0,0,0,0.0);
	border-left: solid 2px transparent;
}

.text-white .video-categories .playlist-item {
	color: #333;
}

.playlist-item .edit-playlist-buttons {
	position: absolute;
	right: 0;
	top: 0;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-right: 10px;
	opacity: 0;
	visibility: hidden;
	transition: all 0.2s;
	transform: scale(0.8,0.8);
}

.video-3 .playlist-item .edit-playlist-buttons,
.video-4 .playlist-item .edit-playlist-buttons
.video-5 .playlist-item .edit-playlist-buttons {
	top: -30px;
}
.playlist-item:hover .edit-playlist-buttons {
	opacity: 1;
	visibility: visible;
	transform: none;
}

.playlist-item .edit-playlist-buttons div {
	width: 30px;
	height: 30px;
	transition: all 0.2s;
	border-radius: 50%;
	background: linear-gradient(-45deg, #3c4665 0%, #21283F 100%);
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 5px;
}

.playlist-item .edit-playlist-buttons div:hover {
	transform: scale(1.1,1.1);
}

.playlist-item .edit-playlist-buttons div img {
	width: 13px;
}

.playlist-item .edit-playlist-buttons div:last-child {
	background: #fff;
	border:solid 1px #ccc;
	transform: scale(0.8,0.8);
}


.playlist-item .edit-playlist-buttons div:last-child:hover {
	border-color: #000;
}

.video-categories .playlist-item.active,
.video-categories .playlist-item:hover {
	opacity: 1;
	box-shadow: 0 4px 8px rgba(0,0,0,0.1);
	border-left: solid 2px #000;

}

.new-category-input {
	width: 100%;
}

.new-category-input input {
	height: 33px;
	border: solid 1px #efefef;
	font-size: 16px;
	border-left: solid 2px #333;
	padding-left: 20px;
	margin-bottom: 5px;
	outline: none;
}


.new-category-input button {
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	border:none;
	color: #fff;
	padding:5px 10px;
	font-size: 12px;
	text-transform: uppercase;
	border-radius: 20px;
}

.new-category-input button.cancel {
	background: transparent;
	color: #ccc;
}


.video-2 .videos-grid .video-box {
	width: calc(50% - 5px);
	margin: 0 2px 4px 2px
}
.video-2 .video-box-image {
	border-radius: 0;
}

.video-2 .video-box-image .video-thumb {
	border-radius: 0;
}

.video-2 .video-box h4 {
	position: absolute;
	bottom: 0;
	left: 0;
	padding:20px;
	color: #fff;
	font-size: 21px;
	font-weight: 400;
	height: auto;
	max-height: initial;
	z-index: 4;
}

.video-2 .video-box-image:before {
	opacity: 1!important;
	background: linear-gradient(to bottom left, transparent 20%, rgba(0,0,0,0.4) 100%);
}


.video-3 .videos-grid .video-box {
	width: calc(33.333% - 5px);
	margin: 0 2px 4px 2px
}
.video-3 .video-box-image {
	border-radius: 0;
}

.video-3 .video-box-image .video-thumb {
	border-radius: 0;
}

.video-3 .video-box h4 {
	position: absolute;
	bottom: 0;
	left: 0;
	padding:20px;
	color: #fff;
	font-size: 21px;
	font-weight: 400;
	height: auto;
	max-height: initial;
	z-index: 4;
}

.video-3 .video-box-image:before {
	opacity: 1!important;
	background: linear-gradient(to bottom left, transparent 20%, rgba(0,0,0,0.4) 100%);
}


.video-3 .videos-grid,
.video-4 .videos-grid,
.video-5 .videos-grid  {
	width: 100%;
}
.video-3 .new-category-input,
.video-4 .new-category-input,
.video-5 .new-category-input {
	width: auto;
}
.video-3 .new-category-input input,
.video-4 .new-category-input input,
.video-5 .new-category-input input {
	border-left-color: #efefef;
	border-bottom: solid 2px #000;
	background: #fff;
	margin-right: 10px;
	padding:5px;
}
.video-3 .video-categories,
.video-4 .video-categories,
.video-5 .video-categories {
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
	margin-bottom: 20px;
}
.video-3 .video-categories .add-photo,
.video-4 .video-categories .add-photo,
.video-5 .video-categories .add-photo {
	display: flex;
	margin-top: 0;
}
.video-3 .video-categories .playlist-item,
.video-4 .video-categories .playlist-item,
.video-5 .video-categories .playlist-item {
	border-left: none!important;
	border-bottom: solid 2px transparent;
}
.video-3 .video-categories .playlist-item.active,
.video-4 .video-categories .playlist-item.active,
.video-5 .video-categories .playlist-item.active {
	border-bottom: solid 2px #000;
}


.video-4 .videos-grid .video-box:nth-child(1),
.video-4 .videos-grid .video-box:nth-child(2) {
	width: calc(50% - 5px);
}

.video-4 .videos-grid .video-box {
	width: calc(33.333% - 5px);
	margin: 0 2px 4px 2px
}
.video-4 .video-box-image {
	border-radius: 0;
}

.video-4 .video-box-image .video-thumb {
	border-radius: 0;
}

.video-4 .video-box h4 {
	position: absolute;
	bottom: 0;
	left: 0;
	padding:20px;
	color: #fff;
	font-size: 21px;
	font-weight: 400;
	height: auto;
	max-height: initial;
	z-index: 4;
}

.video-4 .video-box-image:before {
	opacity: 1!important;
	background: linear-gradient(to bottom left, transparent 20%, rgba(0,0,0,0.4) 100%);
}


.video-5 .videos-grid .video-box {
	width: calc(25% - 10px);
	margin:0 5px 30px 5px;
}

@media (max-width: 779px) {
	.videos-grid .video-box {
		width: 100%!important;
		margin:0 20px 35px 20px!important;
	}

	.videos-grid  {
		padding:0 10px;
		width: 100%;
		margin-top: 20px;
	}
	.new-category-input {
		width: auto;
	}
	.new-category-input input {
		border-left-color: #efefef;
		border-bottom: solid 2px #000;
		background: #fff;
		margin-right: 10px;
		padding:5px;
	}
	.video-categories {
		width: 100%;
		display: flex;
		flex-wrap: nowrap;
		justify-content: center;
		margin-bottom: 0px!important;
		overflow: auto;
	}
	.video-categories .add-photo{
		display: flex;
		margin-top: 0;
		white-space: nowrap;
		margin:0;
	}
	.video-categories .playlist-item{
		border-left: none!important;
		border-bottom: solid 2px transparent;
		white-space: nowrap;
	}
	.video-categories .playlist-item.active{
		border-bottom: solid 2px #000;
	}
	.video-2 .video-box h4,
	.video-3 .video-box h4,
	.video-4 .video-box h4 {
		padding:10px;
		font-size: 15px!important;
	}

	.video-box h4 {
		font-size: 16px!important;
		font-weight: bold!important;
		margin-top: 10px!important;
		margin-bottom: 20px;
	}
	.videos-grid .gallery-settings {
		padding:0;
		margin:0;
	}

	.videos-grid .gallery-settings .add-photo {
		margin:5px auto;
		padding:8px 16px;
	}

	.video-1 .video-box h4,
	.video-5 .video-box h4 {
		margin:5px 0 20px 0;
	}
}















