.custom-html-placeholder {
	width: 100%;
	max-width: 1100px;
	height: 400px;
	margin:0px auto;
	border-radius: 8px;
	border:dashed 1px #ccc;
	color: #ccc;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.custom-html-placeholder img {
	width: 300px;
}

.custom-html-placeholder h2 {
	margin:0 0 20px 0!important;
	font-size: 20px!important;
}

textarea.html-syntax {
	width: 100%;
	height: 500px;
	background: #333;
	padding:15px;
	color: #fff;
	font-size: 16px;
	border-radius: 6px;
	border:none;
	outline: none;
}
.design-block-custom-html iframe {
	width: 100%;
	border:none;
	/* height: auto!important; 
	min-height: 100vh;*/
	outline: none;
	box-shadow: none;
	background: transparent;
}

.rc-trigger-popup {
	position: fixed!important;
	top: calc(50% - 158px)!important;
	left: calc(50% - 155px)!important;
	z-index: 2000000;
}
@media (max-width: 779px) {
	.custom-html-placeholder {
		text-align: center;
	}
}