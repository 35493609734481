.design-block-text-video {
	width:100%;
	max-width: 1100px;
	padding:0 20px;
	margin:0px auto;
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	align-items: center;
	position: relative;
}
.layout-3.design-block-text-video {
	flex-direction: row;
}
.layout-3.design-block-text-video .design-block-video {
	margin-left: 0;
	margin-right: 50px;
}
.layout-2.design-block-text-video {
	flex-direction: column;
	max-width: initial;
	margin:-50px 0 0px 0;
	padding:0;
}

.layout-2.design-block-text-video iframe {
	border-radius: 0;
}
.layout-2 .design-block-video {
	width:100%;
	margin-bottom: 0px;
	margin-left: 0;
}
.layout-2 .design-block-video-text {
	display: none;
}
.design-block-video {
	width: 60%;
	margin-left: 50px;
	position: relative;
}
.design-block-video:before {
	width: 100%;
	content: " ";
	display: block;
	padding-bottom: 56.25%;
}
.design-block-video iframe {
	width: 100%;
	height: 100%;
	border:none;
	outline: none;
	position: absolute;
	top: 0;
	left: 0;
}

.design-block-video-text {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 40%;
}
.design-block-video-text h1 {
	margin:0;
}

.design-block-video-text p {
	margin:15px 0 0 0;
}
.design-block-padding-small .layout-2.design-block-text-video {
	margin:-30px 0;
}

.design-block-padding-medium .layout-2.design-block-text-video {
	margin:-66px 0;
}
.design-block-padding-big .layout-2.design-block-text-video {
	margin:-161px 0;
}
@media (max-width: 779px) {
	.design-block-video:before {
		padding-bottom:67.66%;
	}
	.design-block-video-text p {
		margin:0;
	}
	.layout-2 .design-block-video {
		margin-bottom: 0;
	}
	.edit-body-mode .layout-2.design-block-text-video {
		margin:0px 0 0px 0;
	}
	.design-block-text-video {
		flex-direction: column!important;
	}
	.layout-3.design-block-text-video .design-block-video {
		margin-right: 0;
	}
	.design-block-video-text,
	.design-block-video {
		width: 100%;
		padding:0 30px;
		margin:0;
	}

	.design-block-video-text {
		padding:0;
	}
	.design-block-video {
		width: calc(100% - 0px);
		margin:0 auto;
	}
	.layout-2.design-block-text-video {
		margin:-30px 0 30px 0;
	}
	.design-block-video {
		margin-bottom: 20px;
	}
}