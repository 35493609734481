@import url('https://fonts.googleapis.com/css?family=Lato:400,700|Martel|Audiowide|Viaoda+Libre|Special+Elite|Frijole|Amatic+SC|Langar|Bungee+Outline|Cormorant+Garamond:300,400,500,600,700|Lobster|Lora:400,700|Montserrat:400,700|Oswald:400,700|Playfair+Display:400,700,900|Raleway:400,700,900|Roboto+Condensed:400,700|Roboto+Slab:400,700|Slabo+27px|Source+Serif+Pro:400,700&display=swap');

.langar-font .master-container h1,
.langar-font .master-container h2,
.langar-font .master-container h3,
.langar-font .master-container h4,
.langar-font .master-container h5,
.langar-font .master-container h6,
.langar-font .master-container h1 span,
.langar-font .master-container h2 span,
.langar-font .master-container h3 span,
.langar-font .master-container h4 span,
.langar-font .master-container h5 span,
.langar-font .master-container h6 span,
.langar-font-body .master-container p,
.langar-font-body .master-container ul,
.langar-font-body .master-container span,
.langar-font-body .master-container .blog-post-content,
.langar-font-nav .master-container .nav-bar,
.langar-font-body .font-group div:after,
.font-group .langar-font,
.font-group .langar-font-body,
.font-group .langar-font-nav,
.font-group .langar-font-footer,

.font-group .langar-font-button,
.explore-btn.langar-font-button,

.langar-font-footer .site-footer {
	font-family: 'Langar';
}

.bungee-outline-font .master-container h1,
.bungee-outline-font .master-container h2,
.bungee-outline-font .master-container h3,
.bungee-outline-font .master-container h4,
.bungee-outline-font .master-container h5,
.bungee-outline-font .master-container h6,
.bungee-outline-font .master-container h1 span,
.bungee-outline-font .master-container h2 span,
.bungee-outline-font .master-container h3 span,
.bungee-outline-font .master-container h4 span,
.bungee-outline-font .master-container h5 span,
.bungee-outline-font .master-container h6 span,
.bungee-outline-font-body .master-container p,
.bungee-outline-font-body .master-container ul,
.bungee-outline-font-body .master-container span,
.bungee-outline-font-body .master-container .blog-post-content,
.bungee-outline-font-nav .master-container .nav-bar,
.bungee-outline-font-body .font-group div:after,
.font-group .bungee-outline-font,
.font-group .bungee-outline-font-body,
.font-group .bungee-outline-font-nav,
.font-group .bungee-outline-font-footer,

.font-group .bungee-outline-font-button,
.explore-btn.bungee-outline-font-button,

.bungee-outline-font-footer .site-footer {
	font-family: 'Bungee Outline';
}

.amaticsc-font .master-container h1,
.amaticsc-font .master-container h2,
.amaticsc-font .master-container h3,
.amaticsc-font .master-container h4,
.amaticsc-font .master-container h5,
.amaticsc-font .master-container h6,
.amaticsc-font .master-container h1 span,
.amaticsc-font .master-container h2 span,
.amaticsc-font .master-container h3 span,
.amaticsc-font .master-container h4 span,
.amaticsc-font .master-container h5 span,
.amaticsc-font .master-container h6 span,
.amaticsc-font-body .master-container p,
.amaticsc-font-body .master-container ul,
.amaticsc-font-body .master-container span,
.amaticsc-font-body .master-container .blog-post-content,
.amaticsc-font-nav .master-container .nav-bar,
.amaticsc-font-body .font-group div:after,
.font-group .amaticsc-font,
.font-group .amaticsc-font-body,
.font-group .amaticsc-font-nav,
.font-group .amaticsc-font-footer,

.font-group .amaticsc-font-button,
.explore-btn.amaticsc-font-button,

.amaticsc-font-footer .site-footer {
	font-family: 'Amatic SC';
}

.audiowide-font .master-container h1,
.audiowide-font .master-container h2,
.audiowide-font .master-container h3,
.audiowide-font .master-container h4,
.audiowide-font .master-container h5,
.audiowide-font .master-container h6,
.audiowide-font .master-container h1 span,
.audiowide-font .master-container h2 span,
.audiowide-font .master-container h3 span,
.audiowide-font .master-container h4 span,
.audiowide-font .master-container h5 span,
.audiowide-font .master-container h6 span,
.audiowide-font-body .master-container p,
.audiowide-font-body .master-container ul,
.audiowide-font-body .master-container span,
.audiowide-font-body .master-container .blog-post-content,
.audiowide-font-nav .master-container .nav-bar,
.audiowide-font-body .font-group div:after,
.font-group .audiowide-font,
.font-group .audiowide-font-body,
.font-group .audiowide-font-nav,
.font-group .audiowide-font-footer,

.font-group .audiowide-font-button,
.explore-btn.audiowide-font-button,

.audiowide-font-footer .site-footer {
	font-family: 'Audiowide';
}


.frijole-font .master-container h1,
.frijole-font .master-container h2,
.frijole-font .master-container h3,
.frijole-font .master-container h4,
.frijole-font .master-container h5,
.frijole-font .master-container h6,
.frijole-font .master-container h1 span,
.frijole-font .master-container h2 span,
.frijole-font .master-container h3 span,
.frijole-font .master-container h4 span,
.frijole-font .master-container h5 span,
.frijole-font .master-container h6 span,
.frijole-font-body .master-container p,
.frijole-font-body .master-container ul,
.frijole-font-body .master-container span,
.frijole-font-body .master-container .blog-post-content,
.frijole-font-nav .master-container .nav-bar,
.frijole-font-body .font-group div:after,
.font-group .frijole-font,
.font-group .frijole-font-body,
.font-group .frijole-font-nav,
.font-group .frijole-font-footer,

.font-group .frijole-font-button,
.explore-btn.frijole-font-button,

.frijole-font-footer .site-footer {
	font-family: 'Frijole';
}

.special-elite-font .master-container h1,
.special-elite-font .master-container h2,
.special-elite-font .master-container h3,
.special-elite-font .master-container h4,
.special-elite-font .master-container h5,
.special-elite-font .master-container h6,
.special-elite-font .master-container h1 span,
.special-elite-font .master-container h2 span,
.special-elite-font .master-container h3 span,
.special-elite-font .master-container h4 span,
.special-elite-font .master-container h5 span,
.special-elite-font .master-container h6 span,
.special-elite-font-body .master-container p,
.special-elite-font-body .master-container ul,
.special-elite-font-body .master-container span,
.special-elite-font-body .master-container .blog-post-content,
.special-elite-font-nav .master-container .nav-bar,
.special-elite-font-body .font-group div:after,
.font-group .special-elite-font,
.font-group .special-elite-font-body,
.font-group .special-elite-font-nav,
.font-group .special-elite-font-footer,

.font-group .special-elite-font-button,
.explore-btn.special-elite-font-button,

.special-elite-font-footer .site-footer {
	font-family: 'Special Elite';
}

.viaoda-libre-font .master-container h1,
.viaoda-libre-font .master-container h2,
.viaoda-libre-font .master-container h3,
.viaoda-libre-font .master-container h4,
.viaoda-libre-font .master-container h5,
.viaoda-libre-font .master-container h6,
.viaoda-libre-font .master-container h1 span,
.viaoda-libre-font .master-container h2 span,
.viaoda-libre-font .master-container h3 span,
.viaoda-libre-font .master-container h4 span,
.viaoda-libre-font .master-container h5 span,
.viaoda-libre-font .master-container h6 span,
.viaoda-libre-font-body .master-container p,
.viaoda-libre-font-body .master-container ul,
.viaoda-libre-font-body .master-container span,
.viaoda-libre-font-body .master-container .blog-post-content,
.viaoda-libre-font-nav .master-container .nav-bar,
.viaoda-libre-font-body .font-group div:after,
.font-group .viaoda-libre-font,
.font-group .viaoda-libre-font-body,
.font-group .viaoda-libre-font-nav,
.font-group .viaoda-libre-font-footer,

.font-group .viaoda-libre-font-button,
.explore-btn.viaoda-libre-font-button,

.viaoda-libre-font-footer .site-footer {
	font-family: 'Viaoda Libre';
}

/* Original */

.montserrat-font .master-container h1,
.montserrat-font .master-container h2,
.montserrat-font .master-container h3,
.montserrat-font .master-container h4,
.montserrat-font .master-container h5,
.montserrat-font .master-container h6,
.montserrat-font .master-container h1 span,
.montserrat-font .master-container h2 span,
.montserrat-font .master-container h3 span,
.montserrat-font .master-container h4 span,
.montserrat-font .master-container h5 span,
.montserrat-font .master-container h6 span,
.montserrat-font-body .master-container p,
.montserrat-font-body .master-container ul,
.montserrat-font-body .master-container span,
.montserrat-font-body .master-container .blog-post-content,
.montserrat-font-nav .master-container .nav-bar,
.montserrat-font-body .font-group div:after,
.font-group .montserrat-font,
.font-group .montserrat-font-body,
.font-group .montserrat-font-nav,
.font-group .montserrat-font-footer,

.font-group .montserrat-font-button,
.explore-btn.montserrat-font-button,

.montserrat-font-footer .site-footer {
	font-family: 'Montserrat';
}
.lato-font .master-container h1,
.lato-font .master-container h2,
.lato-font .master-container h3,
.lato-font .master-container h4,
.lato-font .master-container h5,
.lato-font .master-container h6,
.lato-font .master-container h1 span,
.lato-font .master-container h2 span,
.lato-font .master-container h3 span,
.lato-font .master-container h4 span,
.lato-font .master-container h5 span,
.lato-font .master-container h6 span,
.lato-font-body .master-container p,
.lato-font-body .master-container ul,
.lato-font-body .master-container span,
.lato-font-body .master-container .blog-post-content,
.lato-font-nav .nav-bar,
.lato-font-body .font-group div:after,
.font-group .lato-font,
.font-group .lato-font-body,
.font-group .lato-font-footer,
.font-group .lato-font-nav,

.font-group .lato-font-button,
.explore-btn.lato-font-button,

.lato-font-footer .site-footer {
	font-family: 'Lato';
}
.oswald-font .master-container h1,
.oswald-font .master-container h2,
.oswald-font .master-container h3,
.oswald-font .master-container h4,
.oswald-font .master-container h5,
.oswald-font .master-container h6,
.oswald-font .master-container h1 span,
.oswald-font .master-container h2 span,
.oswald-font .master-container h3 span,
.oswald-font .master-container h4 span,
.oswald-font .master-container h5 span,
.oswald-font .master-container h6 span,
.oswald-font-body .master-container p,
.oswald-font-body .master-container ul,
.oswald-font-body .master-container span,
.oswald-font-body .master-container .blog-post-content,
.oswald-font-body .font-group div:after,
.oswald-font-nav .nav-bar,
.font-group .oswald-font,
.font-group .oswald-font-body,
.font-group .oswald-font-nav,
.font-group .oswald-font-footer,

.font-group .oswald-font-button,
.explore-btn.oswald-font-button,

.oswald-font-footer .site-footer {
	font-family: 'Oswald';
}
.roboto-condensed-font .master-container h1,
.roboto-condensed-font .master-container h2,
.roboto-condensed-font .master-container h3,
.roboto-condensed-font .master-container h4,
.roboto-condensed-font .master-container h5,
.roboto-condensed-font .master-container h6,
.roboto-condensed-font .master-container h1 span,
.roboto-condensed-font .master-container h2 span,
.roboto-condensed-font .master-container h3 span,
.roboto-condensed-font .master-container h4 span,
.roboto-condensed-font .master-container h5 span,
.roboto-condensed-font .master-container h6 span,
.roboto-condensed-font-body .master-container p,
.roboto-condensed-font-body .master-container ul,
.roboto-condensed-font-body .master-container span,
.roboto-condensed-font-body .master-container .blog-post-content,
.roboto-condnsed-font-body .font-group div:after,
.roboto-condensed-font-nav .master-container .nav-bar,
.font-group .roboto-condensed-font,
.font-group .roboto-condensed-font-body,
.font-group .roboto-condensed-font-nav,
.font-group .roboto-condensed-font-footer,

.font-group .roboto-font-button,
.explore-btn.roboto-font-button,

.roboto-font-footer .site-footer {
	font-family: 'Roboto Condensed';
}
.roboto-slab-font .master-container h1,
.roboto-slab-font .master-container h2,
.roboto-slab-font .master-container h3,
.roboto-slab-font .master-container h4,
.roboto-slab-font .master-container h5,
.roboto-slab-font .master-container h6,
.roboto-slab-font .master-container h1 span,
.roboto-slab-font .master-container h2 span,
.roboto-slab-font .master-container h3 span,
.roboto-slab-font .master-container h4 span,
.roboto-slab-font .master-container h5 span,
.roboto-slab-font .master-container h6 span,
.roboto-slab-font-body .master-container p,
.roboto-slab-font-body .master-container ul,
.roboto-slab-font-body .master-container span,
.roboto-slab-font-body .master-container .blog-post-content,
.roboto-slab-font-body .font-group div:after,
.roboto-slab-font-nav .master-container .nav-bar,
.font-group .roboto-slab-font,
.font-group .roboto-slab-font-body,
.font-group .roboto-slab-font-nav,
.font-group .roboto-slab-font-footer,

.font-group .roboto-font-button,
.explore-btn.roboto-font-button,

.roboto-slab-font-footer .site-footer   {
	font-family: 'Roboto Slab';
}
.slabo-font .master-container h1,
.slabo-font .master-container h2,
.slabo-font .master-container h3,
.slabo-font .master-container h4,
.slabo-font .master-container h5,
.slabo-font .master-container h6,
.slabo-font .master-container h1 span,
.slabo-font .master-container h2 span,
.slabo-font .master-container h3 span,
.slabo-font .master-container h4 span,
.slabo-font .master-container h5 span,
.slabo-font .master-container h6 span,
.slabo-font-nav .master-container .nav-bar,
.slabo-font-body .master-container p,
.slabo-font-body .master-container ul,
.slabo-font-body .master-container span,
.slabo-font-body .master-container .blog-post-content,
.slabo-font-body .font-group div:after,
.font-group .slabo-font,
.font-group .slabo-font-body,
.font-group .slabo-font-nav,
.font-group .slabo-font-footer,

.font-group .slabo-font-button,
.explore-btn.slabo-font-button,

.slabo-font-footer .site-footer {
	font-family: 'Slabo 27px', serif;
}
.playfair-display-font .master-container h1,
.playfair-display-font .master-container h2,
.playfair-display-font .master-container h3,
.playfair-display-font .master-container h4,
.playfair-display-font .master-container h5,
.playfair-display-font .master-container h6,
.playfair-display-font .master-container h1 span,
.playfair-display-font .master-container h2 span,
.playfair-display-font .master-container h3 span,
.playfair-display-font .master-container h4 span,
.playfair-display-font .master-container h5 span,
.playfair-display-font .master-container h6 span,
.playfair-display-font-body .master-container p,
.playfair-display-font-body .master-container ul,
.playfair-display-font-body .master-container span,
.playfair-display-font-body .master-container .blog-post-content,
.playfair-display-font-body .font-group div:after,
.playfair-display-font-nav .master-container .nav-bar,
.font-group .playfair-display-font,
.font-group .playfair-display-font-body,
.font-group .playfair-display-font-nav,
.font-group .playfair-display-font-footer,

.font-group .playfair-display-font-button,
.explore-btn.playfair-display-font-button,

.playfair-display-font-footer .site-footer {
	font-family: 'Playfair Display';
}
.lora-font .master-container h1,
.lora-font .master-container h2,
.lora-font .master-container h3,
.lora-font .master-container h4,
.lora-font .master-container h5,
.lora-font .master-container h6,
.lora-font .master-container h1 span,
.lora-font .master-container h2 span,
.lora-font .master-container h3 span,
.lora-font .master-container h4 span,
.lora-font .master-container h5 span,
.lora-font .master-container h6 span,
.lora-font-nav .master-container .nav-bar,
.lora-font-body .master-container p,
.lora-font-body .master-container ul,
.lora-font-body .master-container span,
.lora-font-body .master-container .blog-post-content,
.lora-font-body .font-group div:after,
.font-group .lora-font,
.font-group .lora-font-body,
.font-group .lora-font-nav,
.font-group .lora-font-footer,

.font-group .lora-font-button,
.explore-btn.lora-font-button,

.lora-font-footer .site-footer {
	font-family: 'Lora';
}
.lobster-font .master-container h1,
.lobster-font .master-container h2,
.lobster-font .master-container h3,
.lobster-font .master-container h4,
.lobster-font .master-container h5,
.lobster-font .master-container h6,
.lobster-font .master-container h1 span,
.lobster-font .master-container h2 span,
.lobster-font .master-container h3 span,
.lobster-font .master-container h4 span,
.lobster-font .master-container h5 span,
.lobster-font .master-container h6 span,
.lobster-font-body .master-container p,
.lobster-font-body .master-container ul,
.lobster-font-body .master-container span,
.lobster-font-body .master-container .blog-post-content,
.lobster-font-nav .master-container .nav-bar,
.lobster-font-body .font-group div:after,
.font-group .lobster-font,
.font-group .lobster-font-body,
.font-group .lobster-font-footer,
.font-group .lobster-font-nav,

.font-group .lobster-font-button,
.explore-btn.lobster-font-button,

.lobster-font-footer .site-footer {
	font-family: 'Lobster';
}
.source-serif-pro-font .master-container h1,
.source-serif-pro-font .master-container h2,
.source-serif-pro-font .master-container h3,
.source-serif-pro-font .master-container h4,
.source-serif-pro-font .master-container h5,
.source-serif-pro-font .master-container h6,
.source-serif-pro-font .master-container h1 span,
.source-serif-pro-font .master-container h2 span,
.source-serif-pro-font .master-container h3 span,
.source-serif-pro-font .master-container h4 span,
.source-serif-pro-font .master-container h5 span,
.source-serif-pro-font .master-container h6 span,
.source-serif-pro-font-body .master-container p,
.source-serif-pro-font-body .master-container ul,
.source-serif-pro-font-body .master-container span,
.source-serif-pro-font-body .master-container .blog-post-content,
.source-serif-profont-body .font-group div:after,
.source-serif-pro-font-nav .master-container .nav-bar,
.font-group .source-serif-pro-font,
.font-group .source-serif-pro-font-body,
.font-group .source-serif-pro-font-nav,
.font-group .source-serif-pro-font-footer,

.font-group .source-serif-pro-font-button,
.explore-btn.source-serif-pro-font-button,

.source-serif-pro-font-footer .site-footer {
	font-family: 'Source Serif Pro';
}
.raleway-font .master-container h1,
.raleway-font .master-container h2,
.raleway-font .master-container h3,
.raleway-font .master-container h4,
.raleway-font .master-container h5,
.raleway-font .master-container h6,
.raleway-font .master-container h1 span,
.raleway-font .master-container h2 span,
.raleway-font .master-container h3 span,
.raleway-font .master-container h4 span,
.raleway-font .master-container h5 span,
.raleway-font .master-container h6 span,
.raleway-font-body .master-container p,
.raleway-font-body .master-container ul,
.raleway-font-body .master-container span,
.raleway-font-body .master-container .blog-post-content,
.raleway-font-body .font-group div:after,
.raleway-font-nav .master-container .nav-bar,
.font-group .raleway-font,
.font-group .raleway-font-body,
.font-group .raleway-font-nav,
.font-group .raleway-font-footer,

.font-group .raleway-font-button,
.explore-btn.raleway-font-button,

.raleway-font-footer .site-footer {
	font-family: 'Raleway';
}

.cormorant-garamond-font .master-container h1,
.cormorant-garamond-font .master-container h2,
.cormorant-garamond-font .master-container h3,
.cormorant-garamond-font .master-container h4,
.cormorant-garamond-font .master-container h5,
.cormorant-garamond-font .master-container h6,
.cormorant-garamond-font .master-container h1 span,
.cormorant-garamond-font .master-container h2 span,
.cormorant-garamond-font .master-container h3 span,
.cormorant-garamond-font .master-container h4 span,
.cormorant-garamond-font .master-container h5 span,
.cormorant-garamond-font .master-container h6 span,
.cormorant-garamond-font-body .master-container p,
.cormorant-garamond-font-body .master-container ul,
.cormorant-garamond-font-body .master-container span,
.cormorant-garamond-font-body .master-container .blog-post-content,
.cormorant-garamond-font-body .font-group div:after,
.cormorant-garamond-font-nav .master-container .nav-bar,
.font-group .cormorant-garamond-font,
.font-group .cormorant-garamond-font-body,
.font-group .cormorant-garamond-font-nav,
.font-group .cormorant-garamond-font-footer,

.font-group .cormorant-garamond-font-button,
.explore-btn.cormorant-garamond-font-button,

.cormorant-garamond-font-footer .site-footer {
	font-family: 'Cormorant Garamond';
}
 

.martel-font .master-container h1,
.martel-font .master-container h2,
.martel-font .master-container h3,
.martel-font .master-container h4,
.martel-font .master-container h5,
.martel-font .master-container h6,
.martel-font .master-container h1 span,
.martel-font .master-container h2 span,
.martel-font .master-container h3 span,
.martel-font .master-container h4 span,
.martel-font .master-container h5 span,
.martel-font .master-container h6 span,
.martel-font-body .master-container p,
.martel-font-body .master-container ul,
.martel-font-body .master-container span,
.martel-font-body .master-container .blog-post-content,
.martel-font-body .font-group div:after,
.martel-font-nav .master-container .nav-bar,
.font-group .martel-font,
.font-group .martel-font-body,
.font-group .martel-font-nav,
.font-group .martel-font-footer,

.font-group .martel-font-button,
.explore-btn.martel-font-button,

.martel-font-footer .site-footer {
	font-family: 'Martel';
}

/* WEB SAFE FONTS */

.arial-font .master-container h1,
.arial-font .master-container h2,
.arial-font .master-container h3,
.arial-font .master-container h4,
.arial-font .master-container h5,
.arial-font .master-container h6,
.arial-font .master-container h1 span,
.arial-font .master-container h2 span,
.arial-font .master-container h3 span,
.arial-font .master-container h4 span,
.arial-font .master-container h5 span,
.arial-font .master-container h6 span,
.arial-font-body .master-container p,
.arial-font-body .master-container ul,
.arial-font-body .master-container span,
.arial-font-body .master-container .blog-post-content,
.arial-font-body .font-group div:after,
.arial-font-nav .master-container .nav-bar,
.font-group .arial-font,
.font-group .arial-font-body,
.font-group .arial-font-nav,
.font-group .arial-font-footer,

.font-group .arial-font-button,
.explore-btn.arial-font-button,

.arial-font-footer .site-footer {
	font-family: 'Arial';
}

.comic-sans-ms-font .master-container h1,
.comic-sans-ms-font .master-container h2,
.comic-sans-ms-font .master-container h3,
.comic-sans-ms-font .master-container h4,
.comic-sans-ms-font .master-container h5,
.comic-sans-ms-font .master-container h6,
.comic-sans-ms-font .master-container h1 span,
.comic-sans-ms-font .master-container h2 span,
.comic-sans-ms-font .master-container h3 span,
.comic-sans-ms-font .master-container h4 span,
.comic-sans-ms-font .master-container h5 span,
.comic-sans-ms-font .master-container h6 span,
.comic-sans-ms-font-body .master-container p,
.comic-sans-ms-font-body .master-container ul,
.comic-sans-ms-font-body .master-container span,
.comic-sans-ms-font-body .master-container .blog-post-content,
.comic-sans-ms-font-body .font-group div:after,
.comic-sans-ms-font-nav .master-container .nav-bar,
.font-group .comic-sans-ms-font,
.font-group .comic-sans-ms-font-body,
.font-group .comic-sans-ms-font-nav,
.font-group .comic-sans-ms-font-footer,

.font-group .comic-sans-ms-font-button,
.explore-btn.comic-sans-ms-font-button,

.comic-sans-ms-font-footer .site-footer {
	font-family: 'Comic Sans MS';
}

.courier-new-font .master-container h1,
.courier-new-font .master-container h2,
.courier-new-font .master-container h3,
.courier-new-font .master-container h4,
.courier-new-font .master-container h5,
.courier-new-font .master-container h6,
.courier-new-font .master-container h1 span,
.courier-new-font .master-container h2 span,
.courier-new-font .master-container h3 span,
.courier-new-font .master-container h4 span,
.courier-new-font .master-container h5 span,
.courier-new-font .master-container h6 span,
.courier-new-font-body .master-container p,
.courier-new-font-body .master-container ul,
.courier-new-font-body .master-container span,
.courier-new-font-body .master-container .blog-post-content,
.courier-new-font-body .font-group div:after,
.courier-new-font-nav .master-container .nav-bar,
.font-group .courier-new-font,
.font-group .courier-new-font-body,
.font-group .courier-new-font-nav,
.font-group .courier-new-font-footer,

.font-group .courier-new-font-button,
.explore-btn.courier-new-font-button,

.courier-new-font-footer .site-footer {
	font-family: 'Courier New';
}

.georgia-font .master-container h1,
.georgia-font .master-container h2,
.georgia-font .master-container h3,
.georgia-font .master-container h4,
.georgia-font .master-container h5,
.georgia-font .master-container h6,
.georgia-font .master-container h1 span,
.georgia-font .master-container h2 span,
.georgia-font .master-container h3 span,
.georgia-font .master-container h4 span,
.georgia-font .master-container h5 span,
.georgia-font .master-container h6 span,
.georgia-font-body .master-container p,
.georgia-font-body .master-container ul,
.georgia-font-body .master-container span,
.georgia-font-body .master-container .blog-post-content,
.georgia-font-body .font-group div:after,
.georgia-font-nav .master-container .nav-bar,
.font-group .georgia-font,
.font-group .georgia-font-body,
.font-group .georgia-font-nav,
.font-group .georgia-font-footer,

.font-group .georgia-font-button,
.explore-btn.georgia-font-button,

.georgia-font-footer .site-footer {
	font-family: 'Georgia';
}

.impact-font .master-container h1,
.impact-font .master-container h2,
.impact-font .master-container h3,
.impact-font .master-container h4,
.impact-font .master-container h5,
.impact-font .master-container h6,
.impact-font .master-container h1 span,
.impact-font .master-container h2 span,
.impact-font .master-container h3 span,
.impact-font .master-container h4 span,
.impact-font .master-container h5 span,
.impact-font .master-container h6 span,
.impact-font-body .master-container p,
.impact-font-body .master-container ul,
.impact-font-body .master-container span,
.impact-font-body .master-container .blog-post-content,
.impact-font-body .font-group div:after,
.impact-font-nav .master-container .nav-bar,
.font-group .impact-font,
.font-group .impact-font-body,
.font-group .impact-font-nav,
.font-group .impact-font-footer,

.font-group .impact-font-button,
.explore-btn.impact-font-button,

.impact-font-footer .site-footer {
	font-family: 'Impact';
}

.lucida-console-font .master-container h1,
.lucida-console-font .master-container h2,
.lucida-console-font .master-container h3,
.lucida-console-font .master-container h4,
.lucida-console-font .master-container h5,
.lucida-console-font .master-container h6,
.lucida-console-font .master-container h1 span,
.lucida-console-font .master-container h2 span,
.lucida-console-font .master-container h3 span,
.lucida-console-font .master-container h4 span,
.lucida-console-font .master-container h5 span,
.lucida-console-font .master-container h6 span,
.lucida-console-font-body .master-container p,
.lucida-console-font-body .master-container ul,
.lucida-console-font-body .master-container span,
.lucida-console-font-body .master-container .blog-post-content,
.lucida-console-font-body .font-group div:after,
.lucida-console-font-nav .master-container .nav-bar,
.font-group .lucida-console-font,
.font-group .lucida-console-font-body,
.font-group .lucida-console-font-nav,
.font-group .lucida-console-font-footer,

.font-group .lucida-console-font-button,
.explore-btn.lucida-console-font-button,

.lucida-console-font-footer .site-footer {
	font-family: 'Lucida Console';
}

.palatino-font .master-container h1,
.palatino-font .master-container h2,
.palatino-font .master-container h3,
.palatino-font .master-container h4,
.palatino-font .master-container h5,
.palatino-font .master-container h6,
.palatino-font .master-container h1 span,
.palatino-font .master-container h2 span,
.palatino-font .master-container h3 span,
.palatino-font .master-container h4 span,
.palatino-font .master-container h5 span,
.palatino-font .master-container h6 span,
.palatino-font-body .master-container p,
.palatino-font-body .master-container ul,
.palatino-font-body .master-container span,
.palatino-font-body .master-container .blog-post-content,
.palatino-font-body .font-group div:after,
.palatino-font-nav .master-container .nav-bar,
.font-group .palatino-font,
.font-group .palatino-font-body,
.font-group .palatino-font-nav,
.font-group .palatino-font-footer,

.font-group .palatino-font-button,
.explore-btn.palatino-font-button,

.palatino-font-footer .site-footer {
	font-family: 'Palatino';
}

.tahoma-font .master-container h1,
.tahoma-font .master-container h2,
.tahoma-font .master-container h3,
.tahoma-font .master-container h4,
.tahoma-font .master-container h5,
.tahoma-font .master-container h6,
.tahoma-font .master-container h1 span,
.tahoma-font .master-container h2 span,
.tahoma-font .master-container h3 span,
.tahoma-font .master-container h4 span,
.tahoma-font .master-container h5 span,
.tahoma-font .master-container h6 span,
.tahoma-font-body .master-container p,
.tahoma-font-body .master-container ul,
.tahoma-font-body .master-container span,
.tahoma-font-body .master-container .blog-post-content,
.tahoma-font-body .font-group div:after,
.tahoma-font-nav .master-container .nav-bar,
.font-group .tahoma-font,
.font-group .tahoma-font-body,
.font-group .tahoma-font-nav,
.font-group .tahoma-font-footer,

.font-group .tahoma-font-button,
.explore-btn.tahoma-font-button,

.tahoma-font-footer .site-footer {
	font-family: 'Tahoma';
}

.times-new-roman-font .master-container h1,
.times-new-roman-font .master-container h2,
.times-new-roman-font .master-container h3,
.times-new-roman-font .master-container h4,
.times-new-roman-font .master-container h5,
.times-new-roman-font .master-container h6,
.times-new-roman-font .master-container h1 span,
.times-new-roman-font .master-container h2 span,
.times-new-roman-font .master-container h3 span,
.times-new-roman-font .master-container h4 span,
.times-new-roman-font .master-container h5 span,
.times-new-roman-font .master-container h6 span,
.times-new-roman-font-body .master-container p,
.times-new-roman-font-body .master-container ul,
.times-new-roman-font-body .master-container span,
.times-new-roman-font-body .master-container .blog-post-content,
.times-new-roman-font-body .font-group div:after,
.times-new-roman-font-nav .master-container .nav-bar,
.font-group .times-new-roman-font,
.font-group .times-new-roman-font-body,
.font-group .times-new-roman-font-nav,
.font-group .times-new-roman-font-footer,

.font-group .times-new-roman-font-button,
.explore-btn.times-new-roman-font-button,

.times-new-roman-font-footer .site-footer {
	font-family: 'Times New Roman';
}

.trebuchet-ms-font .master-container h1,
.trebuchet-ms-font .master-container h2,
.trebuchet-ms-font .master-container h3,
.trebuchet-ms-font .master-container h4,
.trebuchet-ms-font .master-container h5,
.trebuchet-ms-font .master-container h6,
.trebuchet-ms-font .master-container h1 span,
.trebuchet-ms-font .master-container h2 span,
.trebuchet-ms-font .master-container h3 span,
.trebuchet-ms-font .master-container h4 span,
.trebuchet-ms-font .master-container h5 span,
.trebuchet-ms-font .master-container h6 span,
.trebuchet-ms-font-body .master-container p,
.trebuchet-ms-font-body .master-container ul,
.trebuchet-ms-font-body .master-container span,
.trebuchet-ms-font-body .master-container .blog-post-content,
.trebuchet-ms-font-body .font-group div:after,
.trebuchet-ms-font-nav .master-container .nav-bar,
.font-group .trebuchet-ms-font,
.font-group .trebuchet-ms-font-body,
.font-group .trebuchet-ms-font-nav,
.font-group .trebuchet-ms-font-footer,

.font-group .trebuchet-ms-font-button,
.explore-btn.trebuchet-ms-font-button,

.trebuchet-ms-font-footer .site-footer {
	font-family: 'Trebuchet MS';
}

.verdana-font .master-container h1,
.verdana-font .master-container h2,
.verdana-font .master-container h3,
.verdana-font .master-container h4,
.verdana-font .master-container h5,
.verdana-font .master-container h6,
.verdana-font .master-container h1 span,
.verdana-font .master-container h2 span,
.verdana-font .master-container h3 span,
.verdana-font .master-container h4 span,
.verdana-font .master-container h5 span,
.verdana-font .master-container h6 span,
.verdana-font-body .master-container p,
.verdana-font-body .master-container ul,
.verdana-font-body .master-container span,
.verdana-font-body .master-container .blog-post-content,
.verdana-font-body .font-group div:after,
.verdana-font-nav .master-container .nav-bar,
.font-group .verdana-font,
.font-group .verdana-font-body,
.font-group .verdana-font-nav,
.font-group .verdana-font-footer,

.font-group .verdana-font-button,
.explore-btn.verdana-font-button,

.verdana-font-footer .site-footer {
	font-family: 'Verdana';
}


				
/* FONTS END */