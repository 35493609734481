.design-block-text-image {
	width:100%;
	max-width: 1200px;
	padding:0 30px;
	margin:0px auto;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	position: relative;
}
.design-block-text-image.layout-6 {
	align-items: center;
}

.design-block-text-image.layout-6 {
	flex-direction: column-reverse;
}

.design-block-text-image.layout-6 .design-block-image {
	margin:30px 0 0 0!important;
	height: auto!important;
}


.design-block-text-image.layout-6 .design-block-image,
.design-block-text-image.layout-6 .design-block-image-text {
	width: 100%;
	max-width: 700px;
	padding:0;
} 
.design-block-text-image.layout-6 .design-block-image img {
	    width: 100%;
    height: auto;
    max-height: initial;
    object-fit: contain;
}

.design-block-text-image.layout-2 {
	flex-direction: row-reverse;
}

.design-block-text-image.layout-3,
.design-block-text-image.layout-7 {
	max-width: initial;
	position: relative;
	height: 500px;
	overflow: hidden;
	padding:0;
	margin:-66px 0;
}

.design-block-text-image.layout-3 .design-block-image img,
.design-block-text-image.layout-7 .design-block-image img {
	max-height: initial;
	height: 100%!important;
}
.design-block-padding-small .design-block-text-image.layout-3,
.design-block-padding-small .design-block-text-image.layout-7 {
	margin:-31px 0;
	height: 350px;
}

.design-block-padding-medium .design-block-text-image.layout-3,
.design-block-padding-medium .design-block-text-image.layout-7  {
	margin:-67px 0;
	height: 550px;
}

.design-block-padding-big .design-block-text-image.layout-3,
.design-block-padding-big .design-block-text-image.layout-7  {
	margin:-161px 0;
	height: 700px;
}
.design-block-text-image.layout-3 .design-block-image,
.design-block-text-image.layout-7 .design-block-image {
	position: absolute;
	width: 100%;
	padding:0;
	height: 100%;
	margin-right: 0;
}
.design-block-text-image.layout-3 .design-block-image:before {
	content: " ";
	background: #333;
	opacity: 0.3;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
.design-block-text-image.layout-3 .design-block-image .change-block-image-btn {
	opacity: 1;
    visibility: visible;
    left: auto;
    right: 20px;
    bottom: 20px;
    top: auto;
}
.design-block-text-image.layout-3 .design-block-image-text {
	padding:0 0 0 80px;
	color: #fff;
	z-index: 4;
}

.design-block-text-image.layout-7 .design-block-image-text {
	display: none!important;
}
.design-block-text-image.layout-3 .design-block-image,
.design-block-text-image.layout-7 .design-block-image  {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.design-block-text-image.layout-2 .design-block-image {
	margin-left: 50px;
	margin-right: 0;
}
.design-block-text-image .design-block-image:hover .change-block-image-btn {
	opacity: 1;
	visibility: visible
}
.design-block-text-image .design-block-image {
	width: 50%;
	position: relative;
	margin-right: 50px;
}
.design-block-text-image .design-block-image-text {
	width: 50%;
	padding:0 20px;
	background: inherit;
}
.design-block-text-image .design-block-image img {
	width: 100%;
	display: block;
	max-height: 500px;
	object-fit: cover;
}

.design-block-text-image.layout-2 .design-block-image img,
.design-block-text-image.layout-1 .design-block-image img {
	object-fit: contain;
}	
.design-block-text-image h1 {
	font-weight: 800;
	margin:0;
}
.design-block-text-image.layout-3 .design-block-image-text {
	width: 100%;
	padding:0 60px;
}

@media (max-width: 779px) {
	.design-block-background-wrapper.design-block-padding-big .design-block-text-image.layout-5,
	.design-block-background-wrapper.design-block-padding-big .design-block-text-image.layout-4 {
		margin-top: -70px;
	}
	.design-block-background-wrapper.design-block-padding-medium .design-block-text-image.layout-5,
	.design-block-background-wrapper.design-block-padding-medium .design-block-text-image.layout-4 {
		margin-top: -30px;
	}
	.design-block-background-wrapper.design-block-padding-small .design-block-text-image.layout-5,
	.design-block-background-wrapper.design-block-padding-small .design-block-text-image.layout-4 {
		margin-top: -10px;
	}

	.design-block-text-image.layout-4,
	.design-block-text-image.layout-5 {
		padding-top: 0;
	}
	.design-block-text-image {
		flex-direction: column;
		padding:20px;
	}
	.design-block-text-image.layout-2 {
		flex-direction: column;
	}
	.design-block-text-image.layout-2 .design-block-image-text {
		margin-bottom: 20px!important;
	}
	.design-block-text-image .design-block-image,
	.design-block-text-image .design-block-image-text {
		width: 100%!important;
		margin:0!important;
		padding:0;
	}
	.design-block-text-image .design-block-image {
		margin-bottom: 30px!important;
	}

	.design-block-text-image.layout-2 .design-block-image,
	.design-block-text-image.layout-1 .design-block-image {
		height: auto!important;
	}
	.design-block-text-image.layout-2 .design-block-image img,
	.design-block-text-image.layout-1 .design-block-image img {
		height: auto;
	}
	.design-block-text-image .design-block-image img {
		height: 100%;
	}
	.design-block-text-image .design-block-image-text {
		height: auto!important;
	}

	.design-block-text-image.layout-5,
	.design-block-text-image.layout-4 {
		padding-left: 0;
		padding-right: 0;

	}
	.design-block-text-image.layout-5 .design-block-image-text,
	.design-block-text-image.layout-4 .design-block-image-text {
		padding:0 30px;
	}
	.layout-3.design-block-text-image .design-block-image img {
		max-height: initial!important;
	}
	.design-block-text-image.layout-3,
	.design-block-text-image.layout-7 {
		margin:-18px 0;
		border-radius: 0;
		height: 400px;
	}
	.design-block-text-image.layout-3,
	.design-block-text-image.layout-7 {
		height: 400px!important;
	}
	.design-block-text-image.layout-3 .design-block-image,
	.design-block-text-image.layout-7 .design-block-image {
		height: 100%!important;
	}
	.design-block-padding-small .design-block-text-image.layout-3,
	.design-block-padding-small .design-block-text-image.layout-7  {
		height: 350px!important;
		margin:-11px 0;
	}

	.design-block-padding-medium .design-block-text-image.layout-3,
	.design-block-padding-medium .design-block-text-image.layout-7 {
		margin:-31px 0;
	}
	.design-block-padding-big .design-block-text-image.layout-3,
	.design-block-padding-big .design-block-text-image.layout-7 {
		height: 600px!important;
		margin:-71px 0;
	}

	.design-block-text-image.layout-3 .design-block-image img,
	.design-block-text-image.layout-7 .design-block-image img {
		height: 100%;
		object-fit: cover;
		max-height: initial;
	}
	.design-block-text-image.layout-3 .design-block-image-text {
		padding:30px;
		margin: auto!important;
	}
	.master-container .design-block-background-wrapper .design-block-text-image.layout-3 h1 {
		font-size: 55px;
	}

	.master-container .design-block-background-wrapper .design-block-text-image.layout-3 h2 {
		font-size: 45px;
	}
	.master-container .design-block-background-wrapper .design-block-text-image.layout-3 h3 {
		font-size: 35px;
	}
	.master-container .design-block-background-wrapper .design-block-text-image.layout-3 h4 {
		font-size: 25px;
	}
	.design-block-text-image.layout-3 .design-block-image-text {
		padding:30px;
		height: 100%;
		overflow: auto;
	}
	.design-block-text-image.layout-3,
	.design-block-text-image.layout-7 {
		padding:0!important;
	}
}

@media (min-width: 779px) {
	.design-block-text-image.layout-4,
	.design-block-text-image.layout-5 {
		margin:-65px 0;
		/*min-height: 600px;*/
		max-width: initial;
		overflow: auto;
		padding:0;
		/*background: inherit;*/
		border-radius: 0;
		border:none;
	}
	.design-block-padding-big .design-block-text-image.layout-4,
	.design-block-padding-big .design-block-text-image.layout-5 {
		margin:-160px 0;
	}
	.design-block-padding-small .design-block-text-image.layout-4,
	.design-block-padding-small .design-block-text-image.layout-5 {
		margin:-30px 0;
	}
	
	.design-block-text-image.layout-4 .design-block-image,
	.design-block-text-image.layout-5 .design-block-image {
		width: 50%;
		margin-right: 0;
		height: 100%;
	}

	.design-block-padding-small .design-block-text-image.layout-4 .design-block-image,
	.design-block-padding-small .design-block-text-image.layout-5 .design-block-image {
		height: 450px;
	}

	.design-block-padding-big .design-block-text-image.layout-4 .design-block-image,
	.design-block-padding-big .design-block-text-image.layout-5 .design-block-image {
		height: 750px;
	}

	.design-block-text-image.layout-4 .design-block-image,
	.design-block-text-image.layout-5 .design-block-image {
		height: 600px;
	}
	.design-block-text-image.layout-4 .design-block-image img,
	.design-block-text-image.layout-5 .design-block-image img {
		height: 100%;
		max-height: initial;
		object-fit: cover;
	}
	.design-block-text-image.layout-4 .design-block-image-text,
	.design-block-text-image.layout-5 .design-block-image-text {
		width: 50%;
		max-width: 550px;
		margin-left: auto;
		margin-right: auto;
		padding:70px 40px;
		background: inherit;
		position: relative;
	}
	.design-block-text-image.layout-5 {
		flex-direction: row-reverse;
	}

	.design-block-text-image .design-block-image-text {
		margin:auto;
	}
}