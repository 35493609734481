.design-block-contact {
	width: 100%;
	max-width: 1200px;
	margin:0 auto;
	display: flex;
	padding:0 20px;
}
.design-block-contact-image {
	width: 50%;
	margin-right: 100px;
	position: relative;
	height: 400px;
	background: #efefef;
}
.layout-2 .design-block-contact-image {
	display: none;
}

.design-block-contact-image:hover .hover-box {
	visibility: visible;
	opacity: 1;
}
.design-block-contact-image img,
.design-block-contact-image iframe {
	width: 100%;
	height: 100%;
}

.design-block-contact-image img {
	object-fit: cover;
}

.design-block-contact-image iframe {
	height: 400px;
	border:none;
	border-radius: 6px;
	outline: none;
}

.design-block-contact-form {
	width: 50%;
	display: flex;
	flex-direction: column;
}
.design-block-contact-form .contact-points {
	margin:10px 0;
}
.layout-2 .design-block-contact-form .contact-points {
	flex-direction: column;
}
.design-block-contact-form .contact-points img {
	max-width: 14px;
	max-height: 14px;
	transform: translateY(2px);
	margin-right: 8px;
}
.design-block-contact-form .contact-points div {
	margin-right: 20px;
	margin-bottom: 15px;
}

.layout-2 .design-block-contact-form {
	width: 100%;
	flex-direction: row;
}
.layout-2 .design-block-contact-form form {
	width: 50%;
	margin-top: 0;
}
.layout-2 .design-block-contact-form-info {
	width: 50%;
	padding-right: 50px;
}
.design-block-contact-form h1,
.design-block-contact-form p {
	margin:0;
}

.design-block-contact-form h1 {
	font-size: 35px;
	font-weight: 800;
}

.design-block-contact-form form {
	width: 100%;
	margin-top: 20px;
}
.design-block-contact-form .input-wrapper {
	margin-bottom: 10px;
}
.design-block-contact-form .input-wrapper,
.design-block-contact-form input,
.design-block-contact-form textarea,
.design-block-contact-form label,
.design-block-contact-form select {
	width: 100%;
}
.design-block-contact-form label {
	font-size: 14px;
	font-weight: bold;
}

.design-block-contact-form input,
.design-block-contact-form textarea,
.design-block-contact-form select {
	width: 100%;
    padding: 12px 20px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    height: 45px;
    font-family: inherit;
    font-size: 16px;
    color: Source Sans Pro;
}
.design-block-contact-form textarea {
	height: 100px;
}

.design-block-contact-form input:focus,
.design-block-contact-form textarea:focus {
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
	border-color: #333;
}
.design-block-contact-social  {
	display: flex;
	margin-top: 30px;
	margin-bottom: 30px;
	flex-wrap: wrap;
	width: 100%;
}
.design-block-contact-social .socicon,
.design-block-contact-social span {
	width: 50px;
	height: 50px;
	margin-right: 5px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 23px;
	cursor: pointer;
	transition: all 0.2s;
}

.design-block-contact-social span {
	background: linear-gradient(-45deg, #3c4665 0%, #21283F 100%);
	transition: all 0.2s;
	transform: scale(0.8,0.8);
	opacity: 0;
	visibility: hidden;
}

.design-block-contact-social:hover span {
	opacity: 1;
	visibility: visible;
	transform: scale(0.9,0.9);
}
.design-block-contact-social span img {
	width: 11px;
}
.design-block-contact-social .socicon:hover {
	transform: scale(1.2,1.2);
}

@media (max-width: 779px) {
	.design-block-contact-form .contact-points div {
		width: 100%;
	}
	.design-block-contact {
		flex-direction: column;
	}
	.design-block-contact-image {
		width: 100%;
		margin-right: 0!important;
		margin-left: 0!important;
		position: relative;
	}
	.design-block-contact-image iframe {
	    width: 100%;
	    height: 100%;
	    border: none;
	    outline: none;
	    box-shadow: 0 8px 16px rgba(0,0,0,0.1);
	}
	.layout-2 .design-block-contact-form {
		flex-direction: column;
		width: 100%;
	}
	.layout-2 .design-block-contact-form-info {
		width: 100%;
		padding-right: 0;
	}
	.design-block-contact-form {
		width: 100%;
	}
	.layout-2 .design-block-contact-form form {
		width: 100%;
		margin:20px 0;
	}
	.layout-1 .design-block-contact-form ,
	.layout-3 .design-block-contact-form {
		margin-top: 20px;
	}
}