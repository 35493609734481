.gallery-modal {
	position: fixed;
	top: 0px;
	left: 0;
	height: calc(100% - 0px);
	width:100%;
	background: rgba(255,255,255,1);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 44444;
	opacity: 0;
	visibility: hidden;
	transition: all 0.4s;
}
.gallery-modal.video-gallery-modal {
	background: #111;
}

.gallery-modal.video-gallery-modal .gallery-nav,
.gallery-modal.video-gallery-modal .gallery-number,
.gallery-modal.video-gallery-modal .close-gallery-modal {
	filter: invert(1);
}

/***MODAL ANIMATIONS***/
.gallery-modal-active .gallery-title {
	animation:fadeOut .5s forwards;
}
.gallery-modal-active .grid-container a.larger {
	animation: fadeOutDown 1s forwards;
}
.gallery-modal-active .gallery-modal-image img {
	opacity: 0;
	animation: fadeIn 1s forwards;
	animation-delay: 0s;
}
.gallery-modal-active .gallery-modal {
	opacity: 1;
	transition-delay: 0.2s;
	visibility: visible;
}

.gallery-modal-description {
	opacity: 0;
}
.gallery-modal-active .gallery-modal-description {
	animation: descIn forwards .8s;
	animation-delay: 0.6s;
}

.gallery-modal-active .close-gallery-modal {
	animation: closeModal 1s forwards;
	animation-delay: 0.6s;
}

.gallery-number {
	transition:0.7s all;
}
.gallery-modal-active .gallery-number {
	left: 0;
	opacity: 0.1;
	transition-delay: .6s;

}

.gallery-modal-active .gallery-nav {
	right: 0;
	opacity: 1;
	transition-delay: .6s;

}

@keyframes descIn {
	0% {opacity: 0; transform: translateX(-400px);}
	100% {opacity: 1; transform: translateX(0);}
}

@keyframes fadeOut {
	0% {opacity: 1;}
	100% {opacity: 0;}
}


/***MODAL ANIMATIONS END***/
.gallery-modal-content {
	width:calc(100% - 0px);
	height: calc(100% - 0px);
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	justify-content: flex-start;
	box-shadow: 0 15px 30px rgba(0,0,0,0);
}

.close-gallery-modal {
	width: 30px;
	height: 30px;
	position: fixed;
	top: 20px;
	right: 20px;
	transform: rotate(45deg);
	opacity: 0;
	animation-delay: 0.3s;
	cursor: pointer;
	z-index: 4;
}

.close-gallery-modal:before {
	position: absolute;
	width: 2px;
	height: 100%;
	background: #000;
	content: " ";
	top: 0;
	left: calc(50% - 1px);
}
.close-gallery-modal:after {
	position: absolute;
	width: 100%;
	height: 2px;
	background: #000;
	content: " ";
	left: 0;
	top: calc(50% - 1px);
}

@keyframes closeModal {
	0% {opacity: 0; transform: rotate(-20deg)}
	100% {opacity: 1; transform: rotate(45deg)}
}

.gallery-modal-image {
	height: 100%;
	width: 100%;
	padding:50px 100px;
	display: flex;
	align-items: center;
	/*align-items: center;
	align-content: center;*/
}

.gallery-modal-image img {
	max-height: 100%;
	max-width: 100%;
	margin:auto;
	/*box-shadow:0 14px 28px rgba(0,0,0,0.06), 0 10px 10px rgba(0,0,0,0.05);*/
	display: block;
	cursor: zoom-in;
	object-fit: contain;
}

.gallery-modal-image.zoomed img {
	max-height: initial;
	cursor: zoom-out;
	margin-bottom: 40px;
}
.close-description {
	width: 50px;
	height: 50px;
	position: absolute;
	right: 0px;
	top: 0px;
	cursor: pointer;
	z-index: 4;
	transition: all 0.5s;
}


.gallery-modal-description:not(.hide) .close-description:after {
	height: 0;
}
.gallery-modal-description:not(.hide) .close-description:hover:after {
	background: #000;
	height: calc(100% - 30px);
}
.gallery-modal-description:not(.hide) .close-description:hover {
	transform: rotate(45deg);
}
.gallery-number {
	font-size: 80px;
	opacity: 0.1;
	padding-left: 30px;
	position: fixed;
	left: -50px;
	top: 0;
	height: 100%;
	display: flex;
	justify-content: flex-start;
	width: 100px;
	align-items: flex-start;
	opacity: 0;
	right: -20px;
}
.gallery-number span {
	font-size: 22px;
	transform: translateY(19px);
}
.close-description:before {
	width: calc(100% - 30px);
	height: 2px;
	background: #000;
	position: absolute;
	content: " ";
	right: 15px;
	top: calc(50% - 1px);
	z-index: 2;
}

.close-description:after {
	height: calc(100% - 30px);
	width: 2px;
	background: #fff;
	position: absolute;
	content: " ";
	top: 15px;
	transition:all 0.2s;
	left: calc(50% - 1px);
}
.gallery-modal-description.hide {
	left: -340px;
	background: transparent;
}
.gallery-modal-description.hide h1 {
	opacity: 0;
}

.gallery-modal-description.hide .close-description {
	top: calc(100% - 60px);
	background: #000;
}
.gallery-modal-description.hide .close-description:before {
	background:#fff;
}
.gallery-modal-description {
	width: 400px;
	padding:0px;
	margin:0 auto;
	position: fixed;
	transition: all 0.5s;
	bottom: 0px;
	left: 0px;
	box-shadow: 0 10px 20px rgba(0,0,0,0);
	background: #fff;
}
.gallery-modal-description h1 {
	font-size: 18px;
	transition: all 0.3s;
	font-weight: 300;
	line-height: 26px;
	margin:0;
	padding:40px;
	overflow:auto;
	max-height: 100vh;
}

.gallery-nav {
	position: fixed;
	bottom: 20px;
	display: flex;
	flex-direction: column;
	right: -20px;
	padding-right: 30px;
	transition:all 0.3s;
	opacity: 0;
}

.gallery-arrow {
	display: flex;
	color:#000;
	width: 100%;
	justify-content: flex-end;
	align-items: center;
	font-size: 11px;
	text-transform: uppercase;
	padding:15px 0px;
	transition: all 0.2s;
	cursor: pointer;
	color: transparent;
	text-shadow: none;
}
.gallery-arrow:hover {
	color: #000;
}
.gallery-arrow:hover span  {
	margin-left: 10px;
}
.gallery-arrow span {
	width: 15px;
	height: 15px;
	border-right: solid 2px #000;
	border-top: solid 2px #000;
	transform: rotate(45deg);
	transition:all 0.2s;
	margin-left: 0px;
}

.gallery-map {
	width: calc(100% - 200px);
    height: calc(100% - 200px);
    margin: auto;
}
.gallery-map iframe {
	width: 100%;
	height: 100%;
	box-shadow:0 14px 28px rgba(0,0,0,0.06), 0 10px 10px rgba(0,0,0,0.05);
}

.gallery-music {
	margin: auto;
    width: 500px;
    height: 500px;

}
.gallery-music iframe {
	width: 100%;
	height: 100%;
	box-shadow:0 14px 28px rgba(0,0,0,0.06), 0 10px 10px rgba(0,0,0,0.05);
}
.gallery-arrow:last-of-type span {
	border-right: none;
	border-top: none;
	border-left: solid 2px #000;
	margin-right: -10px;
	border-bottom: solid 2px #000;
}
.gallery-video iframe {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	border:none;
	box-shadow:0 14px 28px rgba(0,0,0,0.06), 0 10px 10px rgba(0,0,0,0.05);
	opacity: 0;
	animation: scaleInVideo 0.5s forwards;
	animation-delay: 0.4s;
}
@keyframes scaleInVideo {
   0% {transform: scale(0.8); opacity: 0;}
   100% {transform: scale(1); opacity: 1} 
  }
.gallery-modal-content.video {
	padding:0px 120px 0 120px;
}
#videoModal .gallery-modal-description {
	padding:30px 50px;
}
#videoModal .gallery-modal-description h2 {
	margin:0;
	padding:0;
	font-weight: 600;
}
.gallery-video {
	width: 100%;
	max-width: 1000px;
	position: relative;
	margin:auto;
}
.gallery-video:before {
	display: block;
	content: " ";
	padding-bottom: 56.33%;
}
.gallery-video-title h1 {
	font-size: 45px;
	margin-bottom: 10px;
	margin-top: 0;
	padding:0 0px;
}
.gallery-video-description h1 {
	font-size: 45px;
	margin-bottom: 10px;
	margin-top: 0;
	padding:0 0px;
}
.gallery-video-title {
	width: 100%;
	max-width: 1000px;
	margin:30px auto;
}
.gallery-video-description {
	width: 100%;
	padding:50px 20px;
	max-width: 700px;
	margin:0 auto;
	font-size: 21px;
	line-height: 1.58;
}

@media (max-width: 779px) {
	.gallery-modal-description.hide {
		left: 0;
	}
	.gallery-modal-description.hide h1 {
		opacity: 1;
	}
	.gallery-map,
	.gallery-music {
		width: 100%;
		height: 400px;
	}
	.gallery-number {
		font-size: 22px;
		padding-left: 20px;
		padding-top: 20px;
	}
	.gallery-number span {
		transform: none;
	}
	.gallery-modal-image {
		padding:63px 0 20px 0;
		display: block;
	}

	.gallery-modal-image {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.close-description {
		display: none
	}
	.gallery-modal-description {
		position: relative;
	}
	.gallery-modal-description h1 {
		    max-height: initial;
    padding: 0px 30px 60px 30px;
	}
	.gallery-modal-image.zoomed img {
		margin-bottom: 0;
	}
	.gallery-nav {
		width: 100%;
    flex-direction: row-reverse;
    z-index: 4;
    bottom: 0px;
    padding-bottom: 10px;
    background: rgba(255,255,255,0.9);
    font-size: 0!important;
	}
	.gallery-arrow {
		font-size: 0!important;
	}

	.gallery-arrow:last-of-type {
		justify-content: flex-start;
		padding-left: 30px;
	}





	}