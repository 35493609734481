.design-block-blog {
	width:100%;
	max-width: 1100px;
	margin:0px auto;
	display: flex;
	flex-wrap: wrap;
	padding:30px;
}

.blog-cards-container {
	width: 100%;
	justify-content: flex-start;
	flex-wrap: wrap;
	display: flex;

}

.design-block-blog.layout-2 {
	max-width: 1100px;
}

.design-block-blog.layout-2 .design-block-blog-card {
	position: relative;
}


.design-block-blog.layout-3 .design-block-blog-card {
	width: calc(50% - 50px);
	box-shadow: none;
	background: transparent;
	border-radius: 0;
	margin:25px;
}

.design-block-blog.layout-3 {
	max-width: 1250px;
}
.design-block-blog.layout-3 .design-block-blog-card:nth-child(1) {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin:0;
	margin-bottom: 40px;
	padding-bottom: 60px;
	border-bottom: solid 1px rgba(0,0,0,0.1);
}
.design-block-blog.layout-3 .design-block-blog-card:nth-child(1) .design-block-blog-card-image {
	width: 50%;
	flex-shrink: 0;
}
.design-block-blog.layout-3 .design-block-blog-card:nth-child(1) .design-block-blog-card-description {
	padding:40px;
	flex-shrink: 0;
	width: 50%;
}
.design-block-blog.layout-3 .design-block-blog-card:nth-child(1) .design-block-blog-card-description h3 {
	font-size: 45px!important;
}

.design-block-blog.layout-3 .design-block-blog-card-description {
	padding:20px 0;
}
.design-block-blog.layout-2 .design-block-blog-card-image:after {
	content: " ";
	height: 100%;
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 100%);
}
.design-block-blog.layout-2 .design-block-blog-card-description {
	position: absolute;
	bottom:0;
	z-index: 2;
	left: 0;
	color: #fff;
	padding:30px;
	transition: all 0.2s;
	width: 100%;
}

.design-block-blog.layout-2 .design-block-blog-card-description h4,
.design-block-blog.layout-2 .design-block-blog-card-description p,
.design-block-blog.layout-2 .design-block-blog-card-description span {
	color: #fff!important;
}
.design-block-blog.layout-2 .design-block-blog-card:hover .design-block-blog-card-description {
	padding:30px 30px 40px 30px;
}

.design-block-blog.layout-2 .design-block-blog-card-description p {
	opacity: 1;
	display: none;
}
.design-block-blog.layout-2 .design-block-blog-card-image:before  {
	padding-top: 100%;
}
.design-block-blog-card {
	width: calc(33.333% - 10px);
	border-radius: 6px;
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
	overflow: hidden;
	cursor: pointer;
	margin:5px;
}

.design-block-blog-card-image {
	width: 100%;
	position: relative;
	overflow: hidden;
}

.design-block-blog-card-image:before {
	content: " ";
	padding-top: 70%;
	width: 100%;
	display: block;
}

.design-block-blog-card-image img {
	width: 100%;
	position: absolute;
	top: 0;
	height: 100%;
	left: 0;
	object-fit: cover;
	transition: 0.3s;
}

.design-block-blog-card-image:hover img {
	transform: scale(1.1);
}

.design-block-blog-card-description {
	padding:20px;
}

.design-block-blog-card-description h3 {
	margin:0;
	font-size: 25px!important;
	font-weight: bold!important;
}

.design-block-blog-card-description p {
	font-size: 15px;
	opacity: 0.5;
	margin:0;
}

.design-block-blog-card-description span {
	font-size: 13px;
	opacity: 0.5;
}
@media (max-width: 779px) {
	.design-block-blog {
		margin:0 auto;
	}
	.design-block-blog-card {
		width: 100%!important;
		margin-bottom: 20px;
	}
	.design-block-blog-card-image:before {
		padding-top: 66%;
	}
	#blogPost .design-block-toolbar {
		visibility: visible;
		opacity: 1;
		transform: none;
	}
	.design-block-blog-card-description h3 {
		font-size: 26px!important
	}
	.design-block-blog-card-description p {
		font-size: 16px!important;
	}
	.design-block-blog .design-block-blog-card-image,
	.design-block-blog .design-block-blog-card-description {
		width: 100%!important;
	}
	.design-block-blog.layout-3 .design-block-blog-card {
		flex-wrap: wrap;
		margin:20px 0!important;
		padding-bottom: 0!important;
		border-bottom: none!important;
	}
	.design-block-blog.layout-3 .design-block-blog-card-description {
		padding:20px 0!important;
	}
}