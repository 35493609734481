img.signup-bg-image {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	object-fit: cover;
}
.design-block-signup {
	width: 100%;
	display: flex;
	padding:60px;
	margin:0px auto;
	position: relative;
	color: inherit;
	justify-content: center;
	align-items: center;
}
.design-block-signup.layout-1 {
	justify-content: flex-start;
	max-width: 1000px;
	align-items: flex-start;
}

.design-block-signup.layout-1 .design-block-signup-form {
	margin-left: 20px;
}
.design-block-signup {
	background: transparent;
	display: flex;
}

.design-block-signup.layout-2 {
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.design-block-signup {
	position: relative;
	z-index: 3;
}

.design-block-signup.layout-2 .design-block-signup-wrapper-description {
	margin-bottom: 30px;
}

.design-block-signup.layout-2 .design-block-signup-form {
	margin-left: 0;
}


.design-block-signup.layout-3 {
	color: #333;
}
.design-block-signup.layout-3:before {
	display:none;
}
.design-block-signup.layout-3 {
	flex-direction: column;
	max-width: 900px;
	padding:30px;
	background: #fff;
	border-radius: 0;
	box-shadow: 0 6px 12px rgba(0,0,0,0.05);
	z-index: 2;
	min-height: initial;
}

.design-block-signup.layout-3 .explore-btn {
	margin-top: 0;
	height: 60px!important;
	display: flex;
	justify-content: center;
	align-items: center;
}
.design-block-signup.layout-3 .design-block-signup-wrapper-description {
	width: 100%;
	margin-left: 0;
}
.design-block-signup.layout-3 .design-block-signup-form {
	width: 100%;
	display: flex;
	margin:20px 0 0 0;
	justify-content: space-between;
	align-items: center;
}
.design-block-signup.layout-3 .design-block-signup-form input {
	width: calc(50% - 80px);
    height: 60px;
    background: #f3f2f2;
    border: none;
    font-size: 16px;
    padding: 0 20px;
    outline: none!important;
    transition: 0.2s;
    flex-shrink: 0;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 0;
}

.design-block-signup.layout-3 .design-block-signup-form input:focus {
	background: #fff;
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
	border-bottom: solid 2px #333;
}

.design-block-signup.layout-3 .design-block-signup-form .design-block-signup-button {
	border: none;
    text-transform: uppercase;
    font-size: 15px;
    outline: none;
    width: 120px;
    background: #333;
    height: 60px;
    line-height: 60px;
    color: #fff;
    cursor: pointer;
    line-height: 60px;
    transition: all 0.2s;
    border-radius: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.design-block-signup .signup-bg-image {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	object-fit: cover;
}

.design-block-signup-wrapper {
	display: flex;
	position: relative;
	z-index: 4;
	width:100%;
	max-width: 800px;
	align-items: center;
	margin:0 auto;
}

.design-block-signup-wrapper h1 {
	margin:0;
	font-size: 40px;
}
.design-block-signup-wrapper h2 {
	margin:0;
	font-size: 30px;
}
.design-block-signup-wrapper h3 {
	margin:0;
	font-size: 20px;
}

.design-block-signup-wrapper p {
	margin:0;
	font-size: 18px;
}

.design-block-signup-wrapper-description {
	width: 60%;
}

.layout-3 .design-block-signup-wrapper-description {
	color: #333;
}

.design-block-signup-form {
	width: 40%;
	margin-left: 80px;
}

.design-block-signup-form input {
	width: 100%;
	height: 50px;
	font-size: 16px;
	padding:0 20px;
	border-radius: 6px;
	outline: none;
	border:none;
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
	margin-bottom: 10px;
	border:solid 1px #efefef;
}

.design-block-signup-button {
	padding:13px 26px;
	color: #fff;
	font-size: 15px;
	font-weight: 500;
	border-radius: 40px;
	background: #63CAA1;
	display: inline-block;
	text-transform: uppercase;
	margin-top: 0px;
	cursor: pointer;
	transition: all 0.2s;
}
.design-block-signup-button:hover {
	transform: scale(1.05,1.05);
}

@media (max-width: 779px) {
	.design-block-signup {
		padding:60px 30px;
		flex-wrap: wrap;
	}
	.design-block-signup.layout-1 .design-block-signup-form {
		margin-left: 0;
	}
	.design-block-signup-wrapper {
		flex-direction: column;
	}
	.design-block-signup-wrapper h1 {
		font-size: 30px;
	}
	.design-block-signup-wrapper p {
		font-size: 16px;
		text-align: left;
	}
	.design-block-signup-wrapper-description {
		width: 100%!important;
		margin-bottom: 40px;
	}
	.layout-3 .design-block-signup-wrapper-description {
		margin-bottom: 0;
	}

	.design-block-signup.layout-3 {
		width: calc(100% - 40px);
	}
	.design-block-signup-form {
		width: 100%!important;
		margin:0;
	}
	.design-block-signup.layout-3 .design-block-signup-form {
		flex-direction: column;
	}
	.design-block-signup.layout-3 .design-block-signup-form input {
		width: 100%;
		margin-bottom: 10px;
	}
	.design-block-signup .design-block-toolbar-background {
		display: flex!important;
	    width: 70px;
	    height: 50px;
	    margin-top: 0;
	    border-radius: 0 0 6px 6px;
	    margin-left: 2px;
	}
	.design-block-signup .design-block-toolbar .left {
		flex-direction: row;
	}
	.design-block-signup.layout-3 .design-block-background-wrapper {
		padding:20px!important;
	}
	.design-block-signup .design-block-background-wrapper {
		flex-wrap: wrap;
	}
}