@import url('blocks/features.css');
@import url('blocks/text.css');
@import url('blocks/text-image.css');
@import url('blocks/products.css');
@import url('blocks/gallery.css');
@import url('blocks/blog.css');
@import url('blocks/signup.css');
@import url('blocks/video.css');
@import url('blocks/table.css');
@import url('blocks/contact.css');
@import url('blocks/map.css');
@import url('blocks/music.css');
@import url('blocks/html.css');
@import url('blocks/carousel.css');
@import url('blocks/custom-form.css');

@keyframes blankShimmer {
  0%{ background-position: -468px 0; }
  100%{ background-position: 468px 0; }
}
.hide-title-set .design-block-title,
.hide-title-set .design-block-music-title {
	display: none!important;
}

.design-block-title {
	width: calc(100% - 100px);
	margin:30px auto;
}
.design-block-title h1 {
	margin:0;
	font-size: 45px;
	font-weight: 800;
}
.title-set h1,
.title-set h2,
.title-set h3,
.title-set h4,
.title-set p {
	width: 100%;
}
.hide-title-set .title-set {
	display: none!important;
}
.title-set {
	width: 100%;
	max-width: 1100px;
	margin:0px auto 20px auto;
	display: block;
	margin-bottom: 40px!important;
	padding: 0 20px;
}

.title-set h1,
.title-set h2,
.title-set h3,
.title-set h4,
.title-set p {
	margin:0;
}
.title-set .see-all-btn {
	font-size:15px;
	font-weight: 500;
	text-transform: uppercase;
	display: none;
}
.title-set .see-all-btn:after {
	width: 5px;
	height: 5px;
	border-top: solid 2px #000;
	border-right: solid 2px #000;
	content: " ";
	transform: rotate(45deg) translateY(-2px);
	margin-left: 10px;
	display: inline-block;
}
.design-block-features,
.design-block-products,
.design-block-text,
.design-block-text-image,
.design-block-gallery,
.design-block-blog,
.design-block-table {
	z-index: 2;
}
.design-block-align-center .design-block-title {
	max-width: 900px;
	margin-left: auto;
	margin-right: auto;
}
.edit-body-mode .faq-edit-wrapper:hover .design-block-toolbar{
	opacity: 1;
	visibility: visible;
	transform: none;
	width: 100%;
}
.master-container .design-block-background-wrapper h1 {
	font-size: 60px;
	font-weight: normal;
	margin:0;
}

.master-container .design-block-background-wrapper h2 {
	font-size: 50px;
	font-weight: normal;
	margin:0;
}

.master-container .design-block-background-wrapper h3 {
	font-weight: normal;
	font-size: 37px;
	margin:0;
}

.master-container .design-block-background-wrapper h4 {
	font-size: 23px;
	font-weight: normal;
	margin:0;
}

.master-container .design-block-background-wrapper h5,
.master-container .design-block-background-wrapper h6,
.flex-container h5,
.flex-container h6 {
	font-weight: normal;
	margin:0;
}

.master-container .design-block-background-wrapper h5 {
	font-size: 20px;
}

.master-container .design-block-background-wrapper h6 {
	font-size: 15px;
}

@media (max-width: 779px) {
	.design-block-background-wrapper.design-block-padding-big {
		padding:70px 0!important;
	}
	.design-block-background-wrapper.design-block-padding-medium {
		padding:30px 0!important;
	}
	.design-block-background-wrapper.design-block-padding-small {
		padding:10px 0!important;
	}
}

@media (min-width: 779px) {
	.design-block-background-wrapper.design-block-padding-big {
		padding:160px 0;
	}

	.design-block-background-wrapper.design-block-padding-small {
		padding:30px 0;
	}
	
}
.design-block-background-wrapper {
	padding: 65px 0;
	background: #fff;
	width: 100%;
	position: relative;
	transition: 0.3s all;
}

.design-block-background-wrapper.design-block-text-overlay,
.design-block-background-wrapper.design-block-text-light {
	color: #fff!important;
}

.block-background-overlay {
	z-index: 2;
	background: rgba(0,0,0,0.4);
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	transition: 0.2s;
}
.design-block-background-wrapper.design-block-text-overlay .block-background-overlay {
	opacity: 1;
}
.design-block-background-wrapper.design-block-align-center {
	text-align: center;
}

.design-block-background-wrapper.design-block-align-right {
	text-align: right;
}

.design-block-background-wrapper.block-title-hidden .title-set  {
	display: none!important;
}

.design-block-background-wrapper .text-white,
.design-block-background-wrapper .text-white a {
	color: #fff;
}
.design-block-background-image {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	object-fit: cover;
}

.design-block-background-wrapper .layout-1,
.design-block-background-wrapper .layout-2,
.design-block-background-wrapper .layout-3,
.design-block-background-wrapper .layout-4,
.design-block-background-wrapper .layout-5,
.design-block-background-wrapper .layout-6,
.design-block-background-wrapper .layout-7,
.design-block-background-wrapper .layout-8,
.design-block-background-wrapper .layout-9,
.design-block-background-wrapper .layout-10 {
	z-index: 4;
	position: relative;
}

@media (max-width: 779px) {
	.design-block-background-wrapper {
		padding:15px 0!important;
	}
	.block-product-item {
		width: 100%!important;
		margin-bottom: 30px!important;
	}
	.design-block-title {
		width: 100%;
		margin:0;
		padding:0 30px 30px 30px;
	}

 	.master-container .design-block-background-wrapper h1 span,
 	.master-container .design-block-background-wrapper h2 span,
 	.master-container .design-block-background-wrapper h3 span,
 	.master-container .design-block-background-wrapper h4 span,
 	.master-container .design-block-background-wrapper h5 span {
 		font-size: inherit!important
 	}

 	.design-block-text p span {
 		font-size: inherit!important;
 	}

	.master-container .design-block-background-wrapper h1 {
		font-size: 40px;
	}

	.master-container .design-block-background-wrapper h2 {
		font-size: 34px;
	}

	.master-container .design-block-background-wrapper h3 {
		font-size: 24px;
	}

	.master-container .design-block-background-wrapper h4 {
		font-size: 20px;
	}
	.order-blocks-container {
		padding:30px;
	}
	.menu-links-tree .order-blocks-container {
		padding:30px 0;
	}
	.title-set {
		padding-left: 0;
		padding-right: 0;
	}
}

.MuiInputBase-input {
	box-sizing: border-box!important;
}
.MuiFormControl-root,
.MuiInputBase-root {
	width: 100%;
}