.home-icon .edit-image {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	border: dashed 1px #ccc;
	background:#fff;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;
	box-shadow: 0 0 0 10px rgba(255,255,255,1);
}

.home-icon:hover .edit-image {
	opacity: 1;
}

.home-icon .edit-image img {
	width: 15px;
	opacity: 0.4;
	filter: invert(1);
}

.home-info {
	display: flex;
	flex-direction: column;
}
#homePage {
	height: calc(100vh - 80px);
	min-height: 600px;
}

@media (min-width: 779px) {
	.edit-body-mode #homePage {
		height: calc(100vh - 130px)
	}
	.nav-bar-medium #homePage {
		height: calc(100vh - 110px)
	}
	.edit-body-mode.nav-bar-medium #homePage {
		height: calc(100vh - 170px)
	}
	.edit-body-mode.nav-bar-large #homePage {
		height: calc(100vh - 250px)
	}
	.nav-bar-large #homePage {
		height: calc(100vh - 200px)
	}
}
.edit-body-mode.nav-1 #homePage ,
.edit-body-mode.nav-3 #homePage ,
.edit-body-mode.nav-4 #homePage ,
.edit-body-mode.nav-5 #homePage ,
.edit-body-mode.nav-6 #homePage {
	height: calc(100vh - 120px);
}

.body-edit-mode #homePage {
	height: 100%;
}
.home-photos,
.home-video {
	display: none;
}

#homePage:hover .hover-box {
	opacity: 1;
	visibility: visible;
}

.home-info h1,
.home-info h2,
.home-info h3,
.home-info h4,
.home-info p {
	margin:0;
	/*text-align: inherit!important;*/
}
.home-icon {
	width: 80px;
	height: 80px;
	border-radius: 50%;
	margin-bottom: 20px;
	overflow: hidden;
	position: relative;
	z-index: 4;
}

.home-icon .icon-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
#homePage {
	background: #fff;
}
.home-text {
	width: 100%;
}
.home-1 .home-info {
	width: 100%;
	justify-content: flex-end;
	height: 100%;
	padding:0 70px 30px 70px;
	position: relative;
	max-width: 1400px;
	margin:0 auto;
}
.home-1 .home-text {
	opacity: 0;
    animation: fadeIn 0.6s forwards;
    padding-left: 0px;
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.home-1 .home-text .home-text-title {
	width: 100%;
}
.home-1 #homePage .home-icon {
	display: none;
}
.home-1 .home-description {
	opacity: 0;
	animation: fadeRight 0.6s forwards;
	animation-delay: 0.6s;
}

.home-info h1 {
	margin:0!important;
}
.home-1 .home-info h1,
.home-1 .home-info h2,
.home-1 .home-info h3,
.home-1 .home-info h4 {
	width: 100%;
	margin:0;
}

.home-1 #homePage {
	transform: scale(1,1);
}


.home-1 .home-icon {
	position: absolute;
	left: 80px;
	bottom: 80px;
}
.home-1 .home-info .home-description {
	flex-shrink: 0;
	width: 400px;
	padding:0 30px;
	margin-left: 30px;
	border-left: solid 2px #efefef
}

.home-2 #homePage {
	flex-direction: column;
	justify-content: space-between;
	min-height: 700px;
	height: auto!important;
	padding:30px;
	max-width: 1600px;
	margin:auto;
}
.home-2 .home-photos {
	height: 600px;
	display: block;
	width: 100%;
	position: relative;
	opacity: 0;
	animation: fadeIn 1.2s forwards;
}
.home-2 .home-info {
	width: 100%;
	padding:30px 60px 60px 60px;
	opacity: 0;
	animation:fadeIn 1s forwards;
	min-height: 60%;
	justify-content: center;
	max-width: 900px;
}

.home-2 .design-page-align-center .home-info {
	margin:0 auto;
}

.home-2 .design-page-align-right .home-info {
	margin:0;
}
.home-2 .design-page-align-right#homePage {
	align-items: flex-end;

}
.home-2 .home-info .home-icon {
	margin:auto;
	display: none;
}

.home-2 .home-photos img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.home-photos iframe {
	width: 100%;
	height: 100%;
	border:none;
	outline: none;
	position: relative;
}

.home-3 .home-info .home-icon {
	display: none;
}

.home-3 #homePage {
	/*max-width: 1440px;
	margin:0 auto;*/
}

.home-3 .home-info {
	width: 40%;
	align-items: flex-start;
	padding:60px;
	justify-content: flex-end;
	opacity: 0;
	animation:fadeRight 1s forwards;
	position: relative;
	z-index: 2;
}
.home-3 .home-photos {
	height: 100%;
	padding:0px;
	display: block;
	width: 60%;
	position: relative;
	opacity: 0;
	animation:fadeRight 1s forwards;
	animation-delay: 0.5s;
}

@media (min-width: 779px) {
	.home-3 .home-photos {
		border-bottom:solid 40px transparent;
		border-right:solid 40px transparent;
	}
}
.nav-2.home-3 .home-photos {
	padding:30px;
}

.home-3 .home-photos img {
	width: 100%;
	height: 100%;
	position: relative;
	object-fit: cover;
}

.home-4 .home-info .home-icon {
	display: none;
}

.home-4 #homePage {
	margin:0 auto;
}

.home-4 .home-info {
	width: 100%;
	align-items: flex-start;
	padding:0 70px 70px 70px;
	justify-content: flex-end;
	color: #fff!important;
	position: relative;
	z-index: 13;
	opacity: 0;
	animation:fadeRight 1s forwards;
}
.home-4 .home-info .home-description {
	margin-top: 10px;
}
.home-3 .home-info .home-description {

}
.home-4 .home-photos {
	height: 100%;
	padding:0;
	display: block;
	width: 100%;
	position: absolute;
	background:#000;;
}

.home-4 .home-photos img {
	width: 100%;
	height: 100%;
	position: relative;
	object-fit: cover;
	opacity: 1;
}

.home-4 .home-photos:before {
	content: " ";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	background: linear-gradient(to bottom left, transparent 20%, rgba(0,0,0,0.3) 100%);
}



#homePage h1 {
	/*font-size: 85px;*/
	margin:0;
	/*text-align: inherit!important;*/
}
#homePage h2 {
	/*font-size: 60px;*/
	margin:0;
	/*text-align: inherit!important;*/
}
#homePage h3 {
	/*font-size: 45px;*/
	margin:0;
	/*text-align: inherit!important;*/
}
#homePage h4 {
	/*font-size: 20px;*/
	margin:0;
	/*text-align: inherit!important;*/
}

.flex-container h1 span,
.flex-container h2 span,
.flex-container h3 span,
.flex-container h4 span {
	/*font-size: inherit!important;*/
}

.home-5 .home-icon {
	display: none;
}
.home-5 #homePage {
	justify-content: center;
	align-items: center;
}
.home-5 .home-info {
	min-width: 400px;
	max-width: 500px;
	align-items: center;
	padding:30px;
	background: #fff;
	background: inherit;
	text-align: center;
	justify-content: flex-end;
	position: relative;
	z-index: 13;
	opacity: 0;
	animation:fadeRight 1s forwards;
}

.home-5 .home-info .explore-btn {
	margin-top: 10px;
}
.home-5 #homePage .home-info h1 {
	font-size: 45px;
	margin-bottom: 0;
}
.home-5 #homePage .home-info h2 {
	font-size: 43px;
}
.home-5 #homePage .home-info h3 {
	font-size: 40px;
}
.home-5 #homePage .home-info h4 {
	font-size: 20px;
}
.home-5 #homePage .home-info {
	text-align: center;
}

.home-1 #homePage .home-info h1,
.home-1 #homePage .home-info h2,
.home-1 #homePage .home-info h3,
.home-1 #homePage .home-info h4 {
	/*text-align: left;*/
}
.home-5 .home-info h4,
.home-5 .home-info h2,
.home-5 .home-info h3 {
	margin-bottom: 0!important;
	
}
/*.home-1 #homePage .home-info h1,
.home-4 #homePage .home-info h1,
.home-7 #homePage .home-info h1 {
	font-size: 70px;
}
.home-1 #homePage .home-info h2,
.home-4 #homePage .home-info h2,
.home-7 #homePage .home-info h2 {
	font-size: 60px;
}
.home-1 #homePage .home-info h3,
.home-4 #homePage .home-info h3,
.home-7 #homePage .home-info h3 {
	font-size: 50px;
}
.home-1 #homePage .home-info h4,
.home-4 #homePage .home-info h4,
.home-7 #homePage .home-info h4 {
	font-size: 40px;
}*/
.home-5 .home-photos {
	height: 100%;
	padding:0;
	display: block;
	width: 100%;
	position: absolute;
	background:#000;;
}

.home-5 .home-photos img {
	width: 100%;
	height: 100%;
	position: relative;
	object-fit: cover;
	opacity: 1;
}


.home-6 .home-icon {
	display: none;
}
.home-6 #homePage {
	justify-content: flex-end;
	align-items: center;
	background: #fff;
}
.home-6 .home-info {
	width: 500px;
	align-items: center;
	padding:30px 30px;
	justify-content: flex-end;
	background: inherit;
	position: relative;
	z-index: 13;
	transform: translateX(-100px);
}

.home-6 .home-photos .hover-box .change-image-btn,
.home-5 .home-photos .hover-box .change-image-btn {
	align-self: flex-start;
	margin: 5px 0 0 20px;
}

.home-6 .home-info .home-text div {
	
}
.home-6 .home-photos {
	height: 100%;
	padding:0px 400px 0px 0px;
	display: block;
	width: 100%;
	position: absolute;
	background:transparent;
}


.home-6 .home-photos img {
	width: 100%;
	height: 100%;
	position: relative;
	object-fit: cover;
	opacity: 1;
}

.home-7 .home-info .home-icon {
	display: none;
}

.home-7 #homePage {
	max-width: initial;
	margin:0 auto;
}

.home-7 .home-info {
	width: 100%;
	max-width: 800px;
	align-items: flex-start;
	padding:0 40px 0px 40px;
	justify-content: center;
	color: #fff!important;
	position: relative;
	z-index: 13;
	text-align: center;
	margin:0 auto;
	opacity: 0;
	animation:fadeInTop 1s forwards;
}
.home-7 .home-info .home-text {
	margin:0 auto;
}
.home-7 .home-info .home-description {
	margin-top: 10px;
}
.home-7 .home-photos {
	height: 100%;
	padding:0;
	display: block;
	width: 100%;
	position: absolute;
	background:#000;;
}
.home-8 #homePage .home-info h1 {
	font-size:50px;
}
.home-8 #homePage .home-info h2 {
	font-size:40px;
}
.home-8 #homePage .home-info h3 {
	font-size:30px;
}
.home-8 #homePage .home-info h4 {
	font-size:20px;
}
.home-8 .home-info {
	max-width: 500px;
	background: inherit;
	padding: 40px 50px 70px 50px;
	position: relative;
	z-index: 2;
	opacity: 0;
	animation: fadeInTop 1s forwards;
}
.home-8 .home-info .explore-btn {
	position: absolute;
	bottom: 5px;
	right: 5px;
}
.home-8 .home-photos {
    height: 100%;
    padding: 0;
    display: block;
    width: 100%;
    position: absolute;
    background: inherit;
    left: 0;
    top: 0;
    border:solid 30px transparent;
	border-top:none;
}
.home-8 #homePage {
	align-items: flex-end;
	padding:0 60px 60px 60px;
}
.home-8 .home-icon {
	width: 100px;
	height: 100px;
	position: absolute;
	top: -100px;
	left: 0;
	box-shadow: 0 -8px 16px rgba(0,0,0,0.1);
	border-radius: 0;
	object-fit: cover;
	display: none;
}
.home-8 .home-photos img.home-page-hero-image {
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: cover;
    opacity: 1;
}
.home-photos .hover-box {
	height: 40px!important;
	right: 20px!important;
	bottom: 80px!important;
	top: auto;
	left: auto!important;
	visibility: visible;
	opacity: 1;
	flex-direction: row;
	z-index: 20;

}

.home-6 .home-photos .hover-box {
	right: auto!important;
    left: 20px;
    bottom: 20px!important;
    border:none;
}

.home-photos .hover-box .change-image-btn {
	margin-left: 5px;
	background: linear-gradient(-45deg, #3c4665 0%, #21283F 100%);
	box-shadow: 0 3px 6px rgba(0,0,0,0.3);
}

.home-7 .home-photos img {
	width: 100%;
	height: 100%;
	position: relative;
	object-fit: cover;
	opacity: 1;
}

.home-7 .home-photos:before {
	content: " ";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	background:#000;
	opacity: 0.1;
}

/*.home-7 #homePage h1 {
	font-size: 85px;
}

.home-7 #homePage h2 {
	font-size: 65px;
}
.home-7 #homePage h3 {
	font-size: 45px;
}
.home-7 #homePage h4 {
	opacity: 1;
}*/



.home-photos:hover .cover-images-button {
	opacity: 1;
}

@media (min-width: 779px)  {

	/*.home-7:not(.nav-2) .master-container,
	.home-5:not(.nav-2) .master-container,
	.home-4:not(.nav-2) .master-container {
		padding-top: 0;
	}
	
	.home-7:not(.nav-2) .master-container .nav-bar.text-black,
	.home-5:not(.nav-2) .master-container .nav-bar.text-black,
	.home-4:not(.nav-2) .master-container .nav-bar.text-black {
		background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%)!important;
		box-shadow: 0 0 0 1px rgba(255,255,255,0.2);
	}
	
	.home-7:not(.nav-2) .master-container .nav-bar.text-black:hover,
	.home-5:not(.nav-2) .master-container .nav-bar.text-black:hover,
	.home-4:not(.nav-2) .master-container .nav-bar.text-black:hover {
		box-shadow: 0 0 0 4px rgba(255,255,255,0.3);
	}
	
	.home-7:not(.nav-2) .master-container .nav-bar.text-black a,
	.home-5:not(.nav-2) .master-container .nav-bar.text-black a,
	.home-4:not(.nav-2) .master-container .nav-bar.text-black a {
		color: #fff;
	}
	
	.home-7:not(.nav-2) .master-container #homePage .design-block-toolbar,
	.home-5:not(.nav-2) .master-container #homePage .design-block-toolbar,
	.home-4:not(.nav-2) .master-container #homePage .design-block-toolbar {
		margin-top: 80px!important;
	}
	
	.home-7:not(.nav-2) .master-container #homePage,
	.home-5:not(.nav-2) .master-container #homePage,
	.home-4:not(.nav-2) .master-container #homePage {
		height: calc(100% - 30px);
	}
	
	.home-7:not(.nav-2) .master-container .home-photos .hover-box,
	.home-5:not(.nav-2) .master-container .home-photos .hover-box,
	.home-4:not(.nav-2) .master-container .home-photos .hover-box {
		top: 90px!important;
	}





	.home-7:not(.nav-3) .master-container,
	.home-5:not(.nav-3) .master-container,
	.home-4:not(.nav-3) .master-container {
		padding-top: 0;
	}
	
	.home-7:not(.nav-3) .master-container .nav-bar.text-black,
	.home-5:not(.nav-3) .master-container .nav-bar.text-black,
	.home-4:not(.nav-3) .master-container .nav-bar.text-black {
		background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%)!important;
		box-shadow: 0 0 0 1px rgba(255,255,255,0.2);
	}
	
	.home-7:not(.nav-3) .master-container .nav-bar.text-black:hover,
	.home-5:not(.nav-3) .master-container .nav-bar.text-black:hover,
	.home-4:not(.nav-3) .master-container .nav-bar.text-black:hover {
		box-shadow: 0 0 0 4px rgba(255,255,255,0.3);
	}
	
	.home-7:not(.nav-3) .master-container .nav-bar.text-black a,
	.home-5:not(.nav-3) .master-container .nav-bar.text-black a,
	.home-4:not(.nav-3) .master-container .nav-bar.text-black a {
		color: #fff;
	}
	
	.home-7:not(.nav-3) .master-container #homePage .design-block-toolbar,
	.home-5:not(.nav-3) .master-container #homePage .design-block-toolbar,
	.home-4:not(.nav-3) .master-container #homePage .design-block-toolbar {
		margin-top: 80px!important;
	}
	
	.home-7:not(.nav-3) .master-container #homePage,
	.home-5:not(.nav-3) .master-container #homePage,
	.home-4:not(.nav-3) .master-container #homePage {
		height: calc(100% - 30px);
	}
	
	.home-7:not(.nav-3) .master-container .home-photos .hover-box,
	.home-5:not(.nav-3) .master-container .home-photos .hover-box,
	.home-4:not(.nav-3) .master-container .home-photos .hover-box {
		top: 90px!important;
	}*/
}
@media (max-width: 779px) {
	.home-8 .home-photos {
		height: 50vh;
		position: relative;
		border-width: 20px;
	}
	.home-8 .home-info {
		padding:80px 30px;
		box-shadow: none;
		width: 100%;
		max-width: initial;
	}
	.home-8 .home-info .explore-btn {
		position: relative;
	}
	#homePage {
		/*height: calc(100vh - 100px);
		max-height: 650px;*/
		height: auto!important;
		max-height: initial!important;
		overflow: hidden;
	}
	/*.home-1 #homePage h1 {
		font-size: 65px!important;
	}*/
	.home-6 #homePage .home-info h1 {
		line-height: normal;
	}
	#homePage .home-info .home-icon {
		position: relative;
		bottom:auto;
		left: auto;
	}
	#homePage .home-info .home-description {
		border:none;
		width: 100%;
		margin-left: 0;
		padding:0;
	}
	#homePage .home-info .home-description:before {
		display: none;
	}
	#homePage .home-text {
		width: 100%;
		display: flex;
		padding:0;
		flex-direction: column;
	}
	.home-5 .home-info .home-description {
		padding:10px 0 10px 0!important;
	}
	.home-1 .home-info {
		padding:30px;
	}
	.home-3 #homePage {
		padding:0px!important;
		flex-direction: column;
	}
	.home-3 .home-info,
	.home-3 .home-photos {
		width: 100%;
		padding:0!important;
	}

	.home-3 .home-photos {
		height: 50vh;
		margin-top: 50px;
	}

	.home-3 .home-info {
		padding:30px 30px 0 30px!important;
		min-height: 45vh;
		justify-content: center;
	}

	.home-4 .home-info {
		width: 100%!important;
		padding:40px!important;
		margin:auto;
		z-index: 6;
	}
	.home-4 .home-info .home-description {
		margin-top: 15px!important;
	}
	.home-4 .home-photos {
		min-height: 550px;
	}
	.home-6 #homePage {
		height: auto;
	}
	.home-6 .home-photos {
		padding:0;
		position: relative;
		height: 400px;
	}
	.home-6 .home-info {
		transform: none;
		width: 100%;
		z-index: 6;
		padding:30px;
		justify-content: center;
		min-height: 45vh;
	}

	.home-6 .home-info h1 {
		line-height: normal;
		background: transparent;
		padding:0;
	}
	.home-6 .home-info h4 {
		font-size: 16px;
		background: transparent;
		padding:0
	}
	.home-5 .home-info {
		width: 90%;
		z-index: 6;
		min-width: initial;
	}

	.home-1 #homePage {
		height: auto;
		padding:100px 0!important;
		min-height: 550px!important;
	}
	.home-2 #homePage {
		height: auto;
		min-height: initial;
		padding:20px!important;
	}
	.home-2 .home-info {
		min-height: 40vh;
		padding:20px 0px 50px 0px;
	}
	.home-2 .home-photos {
		height: 45vh;
	}
	.home-7 .home-info {
		margin: auto!important;
	}
	.home-7 #homePage,
	.home-5 #homePage,
	.home-4 #homePage {
		min-height: calc(100vh - 80px)!important;
	}
}

.home-4 .design-page-align-center .home-info {
	align-items: center;
}

.home-4 .design-page-align-right .home-info {
	align-items: flex-end;
}

.home-5 .design-page-align-left .home-info {
	text-align: left!important;
}

.home-5 .design-page-align-right .home-info {
	text-align: right!important;
}

.home-5 .design-page-align-left#homePage {
	justify-content: flex-start;
}

.home-5 .design-page-align-right#homePage {
	justify-content: flex-end;
}

.home-7 .design-page-align-left .home-info {
	text-align: left;
	margin:0;
}

.home-7 .design-page-align-right .home-info {
	text-align: right;
	margin-left: auto;
	margin-right: 0;
}
@media (max-width: 778px) {
	.home-7 #homePage, .home-5 #homePage, .home-4 #homePage {
		overflow: visible;
	}
	.home-photos .hover-box {
		bottom: auto!important;
		top: 20px;
		border:none!important;
	}
	/*.home-4 .home-photos .hover-box ,
	.home-5 .home-photos .hover-box,
	.home-7 .home-photos .hover-box {
		top: 80px;
	}*/
	.home-photos .hover-box .change-image-btn {
		font-size: 0;
		padding:10px;
		width: 45px;
		height: 45px;
		display: flex;
	    justify-content: center;
	    align-items: center;
	}
	.home-photos .hover-box .change-image-btn img {
		    margin-right: 0!important;
    		width: 13px!important;
    		transform: none!important;
	}
	.home-5 #homePage .home-info h1 {
		font-size: 35px!important;
		margin-bottom: 0;
	}
	.home-5 #homePage .home-info h2 {
		font-size: 30px!important;
	}
	.home-5 #homePage .home-info h3 {
		font-size: 25px!important;
	}
	.home-5 #homePage .home-info h4 {
		font-size: 20px!important;
	}
	.home-5 #homePage .home-info {
		text-align: center;
	}
}
@media (min-width: 779px) {
	/*.flex-container.design-page-padding-small {
		padding:0;
	}
	.flex-container.design-page-padding-medium {
		padding: 40px 0
	}
	.flex-container.design-page-padding-big {
	 	padding:80px 0;
	}*/
}



