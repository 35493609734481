.site-footer {
	width: 100%;
	font-size: 14px;
	padding:60px;
	margin-top: 0px;
	display: flex;
	font-size: 14px;
	justify-content: space-between;
	background: #fafafa;
	font-weight: 500;
	position: relative;
	overflow: hidden;
	flex-wrap: wrap;
}
.click-creation {
	margin-top: 20px;
	opacity: 0.5;
}
.site-footer a {
	color: inherit;
}
.site-footer a strong {
	font-weight: normal;
}
.site-footer.text-white a,
.site-footer.text-white {
	color: #fff;
}
.site-footer-section a,
.site-footer-section span {
	margin-bottom: 6px;
}
.site-footer-section a:hover {
	opacity: 0.7
}
.site-footer-section {
	/*width: 28%;*/
	max-width: 28%;
	padding:0 10px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;
	align-content: flex-start;
}
.site-footer-section.social-media-footer-section {
	flex-direction: row;
	width: 16%;
}

.site-footer-section.social-media-footer-section a {
	margin-right: 10px;
	cursor: pointer;
}
.site-footer-section h4 {
	width: 100%;
	margin:0 0 10px 0;
	font-size: 12px!important;
    text-transform: uppercase;
}
.site-footer-legal {
	width: 100%;
	text-align: center;
	padding-top: 20px;
	margin-top: 40px;
	border-top: solid 1px rgba(0,0,0,0.1);
}

.site-footer-legal a {
	margin:0 15px;
	cursor: pointer;
}
.site-footer-legal a:hover {
	opacity: 0.7;
}
@media (min-width: 779px) {
	.site-footer-2 .site-footer {
		padding:30px;
	}
	.site-footer-2 .site-footer-section h4 {
		display: none;
	}
	.site-footer-2 .site-footer-section {
		width: 100%!important;
		flex-direction: row;
		text-align: center;
		margin:8px 0;
		max-width: initial;
		justify-content: center;
	}

	.site-footer-2 .site-footer-section a,
	.site-footer-2 .site-footer-section span {
		margin:0 7px;
	}

	.site-footer-2 .site-footer-legal {
		margin-top: 20px;
	}

	.site-footer-3 .site-footer-section h4 {
		display: none;
	}
	.site-footer-3 .site-footer-section a {
		margin:0 15px 4px 15px;
	}
	.site-footer-3 .site-footer-section  {
		max-width: initial;
		order: 1;
		flex-direction: row;
	}
	.site-footer-3 .site-footer-section.contact-footer-section {
		order: 3;
		width: 100%;
		flex-direction: column;
		margin-top: 18px;
	}

	.site-footer-3 .site-footer-legal {
		order: 4;
	}
	.site-footer-3 .site-footer-section.social-media-footer-section {
		width: 230px!important;
		justify-content: flex-end;
	}
	.site-footer-3 .site-footer-section.copyright-footer-section {
		width: 230px;
	}
	.site-footer-3 .site-footer-section.site-map-footer-section {
		width: calc(100% - 460px);
		justify-content: center;
	}
	.site-footer-4 .site-footer-section h4 {
		display: none;
	}

	.site-footer-4 .site-footer {
		display: block;
	}
	.site-footer-4 .site-footer-section {
		width: auto;
		max-width: initial;
	}
	.site-footer-4 .site-footer-section.social-media-footer-section,
	.site-footer-4 .site-footer-section.contact-footer-section {
		float: right;
		align-items: flex-end;
		text-align: right;
	}
	.site-footer-4 .site-footer-section.social-media-footer-section {
		clear: right;
	}
	.site-footer-4 .site-footer-section.site-map-footer-section {
		clear: left;
		display: block;
		margin-top: 10px;
	}

	.site-footer-4 .site-footer-section.site-map-footer-section a {
		display: inline-block;
		margin-right: 14px;
	}
	.site-footer-4 .site-footer-section.site-map-footer-section,
	.site-footer-4 .site-footer-section.copyright-footer-section {
		float: left;
		text-align: left;
	}
	.site-footer-4 .site-footer-legal {
		float: left;
		text-align: left;
		width: 100%;
	}
}

@media (max-width: 779px) {
	.site-footer {
		padding:20px 30px;
		flex-direction: column;
		text-align: left!important;
	}
	.site-footer-section {
		width: 100%!important;
		margin:18px 0;
		padding:0;
		max-width: initial;
	}
	.site-footer-legal {
		padding-top: 20px;
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		text-align: left;
	}
	.site-footer-legal a {
		margin:0 0 6px 0;
	}
	.site-footer-2 .site-footer-section h4 {
		display: none;
	}
	.site-footer-2 .site-footer-section {
		justify-content: center;
		align-content: center;
		text-align: center;
		align-content: center;
		align-items: center;
	}
	.site-footer-2 .site-footer-legal { 
		text-align: center;
	}
	.site-footer-2 .site-footer-section.social-media-footer-section a {
		margin:0 5px;
	}

}

.click-ai-footer {
	width: 100vw;
	position: relative;
	z-index: 5001;
	height: 50px;
	background: #333;
	display: flex;
	justify-content: center;
	padding:0 20px;
	align-items: center;
	font-size: 16px;
	font-weight: 500;
	color: #fff;
	border-top:dashed 1px rgba(0,0,0,0.1);
}

.click-ai-footer a {
	color: #fff;
	text-decoration: underline;
	font-weight: bold;
}

.click-ai-footer span {
	margin-right: 20px;
}

.click-ai-footer img {
	height: 24px;
	margin-left: 5px;
}
.click-ai-footer .left,
.click-ai-footer .right {
	display: flex;
	align-items: center;
}

.click-ai-footer .publish-btn {
	padding:8px 16px;
	text-decoration: none;
	font-weight: normal;
	font-size: 14px;
	margin:0;
}



.nav-2 .click-ai-footer {
		width: calc(100vw - 80px);
	}

@media (max-width: 779px) {
	.click-ai-footer {
		flex-direction: column;
		height: 100px;
	}
	.click-ai-footer .publish-btn {
		margin-top: 8px;
	}
	.nav-2 .click-ai-footer {
		width: 100vw;
	}
}

