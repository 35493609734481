.faq-1 .faq-edit-wrapper,
.faq-2 .faq-edit-wrapper,
.faq-3 .faq-edit-wrapper,
.faq-4 .faq-edit-wrapper,
.faq-5 .faq-edit-wrapper {
	margin:0;
}
.faq-image {
	position: relative;
}
.faq-image:hover .hover-box {
	opacity: 1;
	visibility: visible;
}
.edit-body-mode .design-block-table {
	padding:0;
}
.faq-edit-wrapper {
	position: relative;
}

.nav-1 .faq-image {
	margin-top: 0px;
}

.faq-2 .faq-image,
.faq-3 .faq-image,
.faq-4 .faq-image,
.layout-2 .faq-image,
.layout-3 .faq-image,
.layout-4 .faq-image  {
	display: block;
}

.faq-3 .faq-image,
.faq-4 .faq-image,
.layout-3 .faq-image,
.layout-4 .faq-image {
	height: 400px;
}
.faq-image {
	width: 100%;
	height: 450px;
	display: none;
}
.faq-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.faq-1 #faqContent {
	padding-top: 80px;
	padding-bottom: 80px;
}
#faqContent {
	padding:20px 20px 20px 20px;
	width:100%;
	max-width: 800px;
	margin:0 auto;
	flex-direction: column;
	display: block;
}
.faq-container {
	padding:0 0px;
}
.faq-container .draggable.placeholder {
	width: 100%;
	height: 71px;
	background: #efefef;
	border:dashed 2px #ccc;
	border-radius: 6px;
	color: #ccc;
	text-transform: uppercase;
	font-weight: 600;
	display: flex;
	justify-content: center;
	align-items: center;
}
#faqContent .faq-title {
	position: relative;
	padding-bottom: 40px;
}
#faqContent .faq-title p {
	margin:10px 0 0 0;
	padding:0;
	opacity: 0.5;
}

#faqContent .accordion-content {
	padding:20px 0;
	position: relative;
}
#faqContent .accordion-content:before {
	content: " ";
	width: 1px;
	height: 100%;
	background: #efefef;
	position: absolute;
	left: 30px;
	top: 0;
	display: none;
}
.faq-buttons {
	position: absolute;
	display: flex;
	font-size: 14px;
	right: 0;
	transition: 0.2s all;
	opacity: 0;
	visibility: hidden;
	z-index: 2;
}
.faq-buttons .btn {
	margin-left: 5px;
	font-size: 14px!important;
}
.accordion-toggle:hover .faq-buttons {
	opacity: 1;
	visibility: visible;
}
.faq-buttons .edit-faq {
	padding:5px 10px;
	background: #21283F;
	border-radius: 20px;
	color: #fff;
	margin-left: 10px;
}
.faq-buttons .edit-faq.remove {
	background: transparent;
	color: #CE6C6C;
}
#faqContent .green-add-btn {
	    margin-top: 20px;
    margin-bottom: 20px;
}
@media (min-width: 779px) {
	.faq-2 #faqContent,
	.layout-2 #faqContent {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		max-width: 1100px;
		padding-top: 50px;
	}
	.faq-2 #faqContent .faq-title,
	.layout-2 #faqContent .faq-title {
		width: 300px;
	}
	.faq-2 #faqContent .faq-title:before,
	.layout-2 #faqContent .faq-title:before {
		display: none;
	}

	.faq-2 #faqContent #accordion,
	.layout-2 #faqContent #accordion {
		width: calc(100% - 400px);
	}
	.faq-3 #faqContent,
	.layout-3 #faqContent {
		width: 50%;
		float: right;
		padding:80px 80px 20px 80px;
	}
	.faq-3 .faq-image,
	.layout-3 .faq-image,
	.faq-4 .faq-image,
	.layout-4 .faq-image {
		width: 50%;
		height: 80vh!important;
	}
	.faq-3 .faq-edit-wrapper,
	.layout-3 .faq-edit-wrapper,
	.faq-4 .faq-edit-wrapper,
	.layout-4 .faq-edit-wrapper {
		display: flex;
	}
	.faq-4 .faq-edit-wrapper,
	.layout-4 .faq-edit-wrapper {
		flex-direction: row-reverse;
	}
	.faq-4 .faq-image,
	.layout-4 .faq-image {
		left: auto;
		right: 0;
	}
	.faq-4 #faqContent,
	.layout-4 #faqContent {
		width: 50%;
		float: left;
		padding:80px 80px 20px 80px;
	}

}

@media (max-width: 779px) {
	.faq-image {
		height: 240px!important;
	}
}