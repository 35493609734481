.design-block-custom-form-info {
	width: 100%;
	max-width: 800px;
	margin:0 auto;
	padding:20px;
}
.custom-form-wrapper {
	width:100%;
	max-width: 800px;
	margin:20px auto;
	background: inherit!important;
	color: inherit!important;
	padding: 20px!important;
	position: relative;
}
.layout-1 .custom-form-wrapper,
.layout-3 .custom-form-wrapper,
.layout-4 .custom-form-wrapper {
	background: transparent;
}
.custom-form-label {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	color: #fff;
	background: #333;
	cursor: pointer;
	position: absolute;
	left: 0;
	top: -30px;
	padding:6px 12px;
	border-radius: 4px 4px 0 0;
}
.custom-form-label:hover {
	opacity: 0.8;
}
.custom-form-label img {
	width: 12px;
	height: 12px;
	object-fit: contain;
	margin-right: 8px;
	filter: invert(1);
}
.edit-body-mode .custom-form-wrapper  {
	background: none;
	padding:0!important;
}
.custom-form-item-wrapper {
	width: 100%;
	padding:10px;
	position: relative;
}

.custom-form-item-wrapper label {
	font-size: 16px;
    font-weight: 600;
    margin-bottom: 6px;
    display: block;
    float: left;
    width: 100%;
}
.custom-form-item-wrapper input[type=text],
.custom-form-item-wrapper input[type=tel], 
.custom-form-item-wrapper input[type=email], 
.custom-form-item-wrapper input[type=password], 
.custom-form-item-wrapper input[type=date],
.custom-form-item-wrapper input[type=datetime-local],
.custom-form-item-wrapper input[type=time], 
.custom-form-item-wrapper select,
.custom-form-item-wrapper textarea {
	  width: 100%;
	  padding: 12px;
	  display: inline-block;
	  border: 1px solid #ccc;
	  border-radius: 4px;
	  outline: none;
	  height: 45px;
	  font-family: inherit;
	  font-size: 16px;
	  background: #fff;
}


#customFormModal .input-group textarea {
	min-height: 90px;
}

#customFormModal h1.modal-title {
	font-family: Source Sans Pro!important;
	margin-top: 0;
}
.custom-form-item-wrapper input[type=range] {
	width: 100%;
}
.custom-form-checkbox,
.custom-form-radio {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
}

.custom-form-checkbox input,
.custom-form-radio input {
	margin: 0;
    transform: scale(1.15) translateY(4px);
    transform-origin: 0 0;
}

.custom-form-checkbox label,
.custom-form-radio label {
	font-size: 18px;
	margin-left: 15px;
	font-weight: normal;
}

.custom-form-item-wrapper legend {
	font-size: 14px;
	margin: 8px;
	color: #333;
}

.custom-form-item-wrapper textarea {
	min-height: 150px;
}

.design-block-custom-form {
	width: 100%;
	max-width: 1440px;
	margin-left: auto!important;
	margin-right: auto!important;
	background: inherit;
}

.layout-1.design-block-custom-form:after,
.layout-4.design-block-custom-form:after {
    content: " ";
    clear: both;
    width: 100%;
    display: block;
}

.custom-form-image {
	width: 60%;
	float: left;
	height: calc(100vh - 0px);
	min-height: 600px;
	position: relative;
}
.custom-form-image:hover .hover-box {
	opacity: 1;
	visibility: visible;
}
.custom-form-image .custom-form-image-img,
.custom-form-image iframe {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border:none;
	outline: none;
	display: block;
}

.design-block-custom-form.layout-4,
.design-block-custom-form.layout-1,
.design-block-custom-form.layout-5 {
	padding:0 40px;
}

@media (max-width: 779px) {
	.design-block-custom-form.layout-4,
	.design-block-custom-form.layout-1,
	.design-block-custom-form.layout-5 {
		display: flex;
		flex-direction: column;
	}

}
.design-block-custom-form-info,
.custom-form-wrapper {
	float: right;
	width: 40%;
	padding:0 30px;
}
.layout-4 .custom-form-image {
	float: right;
}

.layout-4 .design-block-custom-form-info,
.layout-4 .custom-form-wrapper {
	float: left;
}
.layout-3 .custom-form-image,
.layout-5 .custom-form-image {
	display: none!important;
}


.layout-3.design-block-custom-form {
	max-width: 700px;
	margin:0 auto;
	display: flex;
	flex-direction: column;
}
.layout-3 .design-block-custom-form-info,
.layout-3 .custom-form-wrapper {
	width: 100%;
}
.layout-3 .custom-form-wrapper {
	margin-top: 50px;
	padding-top: 0;
}
@media (min-width: 779px) {
	
	.layout-1 .custom-form-image,
	.layout-4 .custom-form-image {
		padding:30px;
	}
	
	.layout-2.design-block-custom-form {
		min-height: 70vh;
		display: flex;
		justify-content: center;
	}
	.layout-2 .design-block-custom-form-info {
		width: 100%;
	    max-width: 600px;
	    margin: 0;
	    padding-top: 0;
	    margin-top: 0px;
	}

	.layout-2 .custom-form-wrapper {
		width: 100%;
		max-width: 400px;
		margin-right: 30px;
	    position: relative;
	    z-index: 20;
	    padding: 0;
	    float: left;
	    margin:80px 0 0 0;
	}
	.layout-2 .custom-form-image {
		width: 100%;
		height: auto;
		min-height: 400px;
		position: absolute;
		left: 0;
		bottom: 100px;
		top: 200px;
		right: 0;
	}

	.layout-2 .order-blocks-container {
		min-height: 70vh!important;
		padding:25px;
	}
	.layout-5 .design-block-custom-form-info,
	.layout-5 .custom-form-wrapper  {
		width: 50%;
		display: inline-block;
	}

	.layout-5 .design-block-custom-form-info {
		
	}
	.layout-5.design-block-custom-form {
		max-width: 1000px;
		display: flex;
	}
	.layout-5.design-block-custom-form .custom-form-wrapper {
		float: none;
	}

}

.layout-5.design-block-custom-form:after {
	display: none;
}

.layout-1 .custom-form-wrapper,
.layout-4 .custom-form-wrapper {
	padding-top: 0;
}
.form-elements-scroll {
	width: 100%;
}
@media (max-width: 779px) {
	.edit-custom-form-item.delete {
		bottom: auto;
		top: -1px;
		right: -28px;
		left: auto;
	}
	.design-block-custom-form-info,
	.custom-form-wrapper {
		width: 100%!important;
		padding:0px;
		float: none!important;
	}
	.design-block-custom-form-info {
		padding:30px 20px!important;
	}
	.layout-5 .design-block-custom-form-info,
	.layout-1 .design-block-custom-form-info,
	.layout-4 .design-block-custom-form-info {
		padding:20px 0!important;
	}
	.custom-form-wrapper {
		padding:20px!important;
		border:none!important;
	}
	.custom-form-image {
		width: 100%!important;
		height: 320px!important;
		min-height: initial!important;
		float: none!important;
	}
	.layout-1 .custom-form-wrapper, 
	.layout-4 .custom-form-wrapper {
		padding-left: 0!important;
		padding-right: 0!important;
	}
	.design-block-custom-form.layout-4, 
	.design-block-custom-form.layout-1, 
	.design-block-custom-form.layout-5 {
		padding-left: 20px!important;
		padding-left: 20px!important;
	}
	.layout-3 .custom-form-wrapper {
		margin-top: 0!important;
	}
}

