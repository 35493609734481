.flex-container#loadingFlex {
  min-height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation:fadeTop 1s forwards;
}
.website-loader-wrapper strong {
  font-weight: 600;
  font-size: 14px;
  margin-top: 10px;
  color: rgba(0,0,0,0.3);
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 30px;
  left: 0;
  text-transform: uppercase;
}
.website-loader-wrapper .cai-bot-icon {
  width: 80px;
  height: 80px;
  /*animation: botIn 1s forwards;*/
  animation: botIconPulse 2s ease infinite, loaderShade 1s infinite;
  animation-timing-function: none;
  border-radius: 50%;
  box-shadow: 0 0 16px rgba(237,0,170,0);
  margin:0;
  position: absolute;
  left: calc(50% - 40px);
  top:calc(50% - 40px);
}
@keyframes loaderShade {
  0% {box-shadow: 0 0 0 0 rgba(0,0,0,0.2)}
  100% {box-shadow: 0 0 0 10px rgba(0,0,0,0)}
}
.website-loader-wrapper .loader-logo {
  position: absolute;
  object-fit: contain;
  z-index: 3;
}

.website-loader-wrapper .cai-bot-icon {
  /*background: rgba(237,0,170,0.1);*/
  background: rgba(237,0,170,0.1);
  mix-blend-mode: multiply;
}
.website-loader-wrapper .cai-bot-icon:after {
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  top: 3px;
  left: 3px;
  background: #fff;
  box-shadow:inset 0 0 16px rgba(237,0,170,0);
  animation-duration: 1s;
  animation: none;
  opacity: 1;
}
.website-loader-wrapper .cai-bot-icon:before {
  /*height: 50%;
  width: 50%;
  border-radius: 0;
  transform-origin: 100% 100%;
  box-shadow: none;*/
  /*background: #99A9C5;
  box-shadow: 0 0 10px #99A9C5;*/
  animation-duration: 1.2s;
  animation-timing-function: linear;
  /*background: linear-gradient(45deg, rgba(30,87,153,0) 1%,rgba(100,57,159,0) 10%,rgba(233,2,170,1) 50%,rgba(237,0,170,0.97) 51%,rgba(166,117,209,0) 90%,rgba(125,185,232,0) 100%);*/
}
.website-loader-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.website-loader-wrapper h4 {
  margin:10px 0 0 0;
  font-size: 16px;
}
.website-loader-wrapper span {
  font-size: 12px;
  text-transform: uppercase;
  opacity: 0.2;
}

.website-loader-wrapper .branded-loading h5 {
  margin:0;
  font-weight: normal;
  font-family: Montserrat;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 15px;
}
.website-loader-wrapper .branded-loading h5:before {
  width: 30px;
  height: 30px;
  content: " ";
  display: block;
  border:solid 3px #333;
  border-radius: 50%;
  margin-bottom: 15px;
  animation: pulsateLoader 1s ease-out infinite;
  opacity: 1;
  margin:15px auto;
}
@keyframes pulsateLoader {
    0% {transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {transform: scale(1.2, 1.2); opacity: 0.0;}
}
@keyframes loading {
  from {transform: rotate(0deg)}
  from {transform: rotate(-360deg)}
}
.website-loader-wrapper .branded-loading h4 {
  font-family: Montserrat;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 15px;
  letter-spacing: 2px;
  margin:0;
}


.website-loader-wrapper .branded-loading h4:before,
.website-loader-wrapper .branded-loading h4:after {
  height: 1px;
  background: #efefef;
  width: calc(100% + 40px);
  position: absolute;
  bottom: -10px;
  left: -20px;
  content: " ";
}

.website-loader-wrapper .branded-loading h4:after {
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(51,51,51,1) 100%);
  /*background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(51,51,51,1) 50%, rgba(255,255,255,0) 100%);*/
  animation:brandedLoader 1.5s infinite ease;
}
.page-loader img {
  width: 70px;
  height: 70px;
  position: absolute;
  border-radius: 50%;
  object-fit: contain;
  left: 5px;
  top: 5px;
  z-index: 4;
  animation: shrinkDown 1s infinite /*, pulseLogo 1s infinite*/;
}
.page-loader {
  width: 80px;
  height: 80px;
  position: relative;
  z-index: 4;
  border-radius: 50%;
  background:#ccc;
  opacity: 1;
  transition: all 0.3s;
  margin-bottom: 10px;
  animation: fadeInTop 1s forwards;
}

.page-loader .socicon {
  font-size: 25px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: calc(50% - 10px);
    top: calc(50% - 10px);
    color: #000;
    z-index: 5;
}
.page-loader .socicon.socicon-facebook {
  color: #4267b2;
}
.page-loader .socicon.socicon-google {
  color: #cc3a31;
} 
.page-loader .socicon.socicon-youtube {
  color: #cc3a31;
}

.page-loader .socicon.socicon-linkedin {
  color: #0077B5;
}

.page-loader .socicon.socicon-instagram {
  color:#bc1888;
}

.page-loader.toc-loader {
  position: absolute;
}

.page-loader:after {
  content: " ";
    width: 78px;
    height: 78px;
    position: absolute;
    left: 1px;
    top: 1px;
    border-radius: 50%;
    background: #fff;
}

.page-loader:before {
  content: " ";
    background: #fff;
    position: absolute;
    width: 40px;
    height: 40px;
    right: 0px;
    top: 0px;
    transform-origin: 0% 100%;
    transition: all 0.5s ease;
    animation: loading 1s infinite;
}
@keyframes brandedLoader {
  0% {width: 0}
  50% {width: calc(100% + 40px); left: -20px}
  100% {width: calc(0px); left: calc(100% + 20px); }
}

.website-loader-wrapper span,
.website-loader-wrapper h4 {
  animation: fadeInBottom 1s forwards;
}
.loader {
  width: 150px;
  height: 100px;
  position: absolute;
  left: 50%;
  margin-left:-75px;
  top: 50%;
  margin-top: -50px;
  border: solid 1px #efefef;
  border-radius: 4px;
  background: #fff;
  box-shadow: 13px 13px 46px 0 rgba(0,0,0,0.08);
  padding:15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
/*.loader:after {
  position: absolute;
  content: "Creating your click";
  font-size: 12px;
  bottom: -25px;
  color: #ccc;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  left: 0px;
}*/

.loader .square {
  width: 45%;
  height: 100%; 
  box-shadow:1px 1px 0px 1px #4eb170;
  background: #5FBF80;
  animation: loader 1.3s infinite;
}

.loader .text-square {
  width: 45%;
  height: 7px;  
  position: relative;
  margin-top: 10px;
  box-shadow:1px 1px 0px 1px #4eb170;
  background: #5FBF80;
  animation: loaderText 1.2s infinite;
  transform-origin: 0% 0%;  
}
.loader .text-square:before, .loader .text-square:after {
  width: 70%;
  height: 2px;
  background: #5FBF80;
  box-shadow:1px 1px 0px 1px #4eb170;
  position: absolute;
  bottom: -7px;
  content: " ";
}
.loader .text-square:after {
  width: 50%;
  bottom: -14px;
}
@keyframes loader {
  0% {transform: scale(1,1)}
  50% {transform: scale(0.95,0.95)}
  100% {transform: scale(1,1)}
}

@keyframes loaderText {
  0% {opacity:1;transform: scale(0,1)}
  50% {opacity:1;transform: scale(1,1)}
  90% {opacity:1;transform: scale(1,1)}
  100% {opacity: 0;}
}

.click-loading-container {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
}
.click-loading-container img {
	width: 100px;
	animation:loadPulse 1s infinite;
}

.master_container .click-loading-container img {
	position: fixed;
	left: calc(50% - 50px);
	top: calc(50% - 10px);
}

@keyframes loadPulse {
	0% {opacity: 1; transform: scale(1,1)}
	50% {opacity: 0.7; transform: scale(1.1,1.1)}
	100% {opacity: 1; transform: scale(1,1)}
}

/***FOLDING LOADER***/
.sk-folding-cube-container {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
}

.sk-folding-cube-wrapper {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
}

.sk-folding-cube {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
          transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
          transform: scale(1.1); 
}
.sk-folding-cube .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:linear-gradient(-45deg, #455173 0%, #21283F 100%);
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
          transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
          transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
          transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
	background: #9BA8C3;
          animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3:before {
          animation-delay: 0.6s; 
}
.sk-folding-cube .sk-cube4:before {
          animation-delay: 0.9s;
}

@keyframes sk-foldCubeAngle {
  0%, 10% {
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0; 
  } 25%, 75% {
            transform: perspective(140px) rotateX(0deg);
    opacity: 1; 
  } 90%, 100% {
            transform: perspective(140px) rotateY(180deg);
    opacity: 0; 
  }
}


/***ADDING BLOCK LOADER***/

.adding-block-loader-wrapper {
	height: 75%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	animation: 0.3s addingBlockLoaderIn forwards;
	flex-direction: column;
}

.adding-block-loader-wrapper.edit-loader {
	width: 100vw;
	height: 100vh;
	background: #fff;
}

.adding-block-loader-wrapper.edit-loader div,
.adding-block-loader-wrapper.image-loader div {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #fff;
}

.adding-block-loader-wrapper span {
	font-size: 15px;
}
.adding-block-loader-wrapper.image-loader {
	margin:100px auto;
}

.adding-block-loader-wrapper.edit-loader .adding-block-loader {
	white-space: nowrap;
	box-shadow: 0 14px 28px rgba(0,0,0,0.02), 0 10px 10px rgba(0,0,0,0.05);
}

.adding-block-loader-wrapper.edit-loader .adding-block-loader span,
.adding-block-loader-wrapper.image-loader .adding-block-loader span {
	
}

.adding-block-loader {
	width: 100px;
	height: 80px;
	position: relative;
	border-radius: 5px 5px 0 0;
	border:#21283F solid 2px;
	background: #fff;
}

.adding-block-loader:before {
	width: 100%;
	position: absolute;
	top: 10px;
	height: 2px;
	background: #21283F;
	content: " ";
	left: 0;
}

.adding-block-loader:after {
	position: absolute;
	left: 5px;
	top: -11px;
	content: "...";
	color: #21283F;
}
.adding-block-loader div {
	width: calc(100% - 20px);
    height: calc(100% - 30px);
    position: absolute;
    bottom: 10px;
    left: 10px;
    background: #21283F;
    animation: 1s addingBlockProcess infinite;
    opacity: 0.3;
    border-radius: 6px;
}

.adding-block-loader div img {
	width: 22px;
	margin:auto;
}
.adding-block-loader span {
	position: absolute;
	bottom: -43px;
	font-size: 13px;
	text-align: center;
	width: 100%;
	left: 0;
	opacity: 0.5;
}

.adding-block-loader-wrapper strong {
	font-weight: normal;
	font-size: 15px;
	margin-top: -30px;
}

@keyframes addingBlockLoaderIn {
	from {transform: translateY(-20px); opacity: 0;}
	to {transform: translateY(0px); opacity: 1;}
}
@keyframes addingBlockProcess {
	0% {opacity: 0.3; transform: scale(1,1)}
	50% {opacity: 0.6; transform: scale(1.05,1.05)}
	0% {opacity: 0.3; transform: scale(1,1)}
}


.loading-ghost {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	margin-top: 50px;
	justify-content: space-between;
}

.loading-ghost div {
	width: calc(50% - 5px);
	height: 140px;
	border-radius: 6px;
	display: block;
	flex-wrap: wrap;
	margin-bottom: 10px;
	background: #f6f7f8;
	background: linear-gradient(to right, #21283F 8%, #3c4665 18%, #21283F 33%);
	background-size: 800px 104px;
	opacity: 0.4;
	animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeHolderShimmer;
}


@keyframes placeHolderShimmer {
  0%{ background-position: -468px 0; }
  100%{ background-position: 468px 0; }
}

.infinite-loader {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	font-weight: normal;
	margin:15px 0 0 0;
}
.infinite-loader i {
	margin-right: 8px;
}



.adding-block-loader-wrapper svg {
	 overflow: visible;
	 width: 100px;
	 height: 150px;
	 transform:scale(0.5);
}
 .adding-block-loader-wrapper svg g {
	 animation: slideSvg 2s linear infinite;
}
 .adding-block-loader-wrapper svg g:nth-child(2) {
	 animation-delay: 0.5s;
}
 .adding-block-loader-wrapper svg g:nth-child(2) path {
	 animation-delay: 0.5s;
	 stroke-dasharray: 0px 108px;
	 stroke-dashoffset: 1px;
}
.adding-block-loader-wrapper  svg path {
	 stroke: url(#gradient);
	 stroke-width: 20px;
	 stroke-linecap: round;
	 fill: none;
	 stroke-dasharray: 0 157px;
	 stroke-dashoffset: 0;
	 animation: escalade 2s cubic-bezier(0.8, 0, 0.2, 1) infinite;
}
 @keyframes slideSvg {
	 0% {
		 transform: translateY(-50px);
	}
	 100% {
		 transform: translateY(50px);
	}
}
 @keyframes escalade {
	 0% {
		 stroke-dasharray: 0 157px;
		 stroke-dashoffset: 0;
	}
	 50% {
		 stroke-dasharray: 156px 157px;
		 stroke-dashoffset: 0;
	}
	 100% {
		 stroke-dasharray: 156px 157px;
		 stroke-dashoffset: -156px;
	}
}


/***ADD BLOCK/PAGE***/
.image-loader .block-loader-wrap {
	transform: scale(0.3);
}

.image-loader .block-loader-wrap .box1,
.image-loader .block-loader-wrap .box2,
.image-loader .block-loader-wrap .box3 {
	border-color: #fff;
}

.block-loader-wrap {
  width: 112px;
  height: 112px;
  transform: scale(0.5);
  background: transparent!important;
}
.block-loader-wrap .box1,
.block-loader-wrap .box2,
.block-loader-wrap .box3 {
  border: 16px solid #3c4665;
  box-sizing: border-box;
  position: absolute;
  display: block;
  background: transparent!important;
}
.block-loader-wrap .box1 {
  width: 112px;
  height: 48px;
  margin-top: 64px;
  margin-left: 0px;
  animation: anime1 2s 0s forwards ease-in-out infinite;
}
.block-loader-wrap .box2 {
  width: 48px;
  height: 48px;
  margin-top: 0px;
  margin-left: 0px;
  animation: anime2 2s 0s forwards ease-in-out infinite;
}
.block-loader-wrap .box3 {
  width: 48px;
  height: 48px;
  margin-top: 0px;
  margin-left: 64px;
  animation: anime3 2s 0s forwards ease-in-out infinite;
}
.generic-loading-spin {
  display: flex; 
  height: 400px;
  width: 100%;
  align-items: center;
  justify-content: center
}
@keyframes anime1 {
  0% {
    width: 112px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
  12.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
  25% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
  37.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
  50% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
  62.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
  75% {
    width: 48px;
    height: 112px;
    margin-top: 0px;
    margin-left: 0px;
  }
  87.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
  100% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
}


@keyframes anime2 {
  0% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
  12.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
  25% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
  37.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
  50% {
    width: 112px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
  62.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }
  75% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }
  87.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }
  100% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }
}


@keyframes anime3 {
  0% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }
  12.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }
  25% {
    width: 48px;
    height: 112px;
    margin-top: 0px;
    margin-left: 64px;
  }
  37.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }
  50% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }
  62.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }
  75% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }
  87.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }
  100% {
    width: 112px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
}















