.design-block-text {
	width: 100%;
	max-width: 550px;
	margin:0px auto;
	position: relative;
}

.design-block-text.layout-1 {
	max-width: 700px;
}

.design-block-text.layout-2 {
	max-width: 1200px;
	padding-left: 20px;
	padding-right: 20px;
}

.design-block-text.layout-3 {
	max-width: 1100px;
	padding-left: 20px;
	padding-right: 20px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
}

.design-block-text.layout-3 .design-block-text-left,
.design-block-text.layout-3 .design-block-text-right {
	width: 50%;
}

.design-block-text.layout-3 .design-block-text-right {
	margin-top: 30px;
}

.design-block-text.layout-3 .design-block-text-left {
	padding-right: 50px;
}
.design-block-text h1 {
	font-weight: 800;
	font-size: 35px;
	margin:0;
}

@media (max-width: 779px) {
	.design-block-text {
		padding:20px;
	}
	.design-block-text.layout-2,
	.design-block-text.layout-3 {
		flex-direction: column;
	}
	.design-block-text.layout-3 .design-block-text-left,
	.design-block-text.layout-3 .design-block-text-right {
		width: 100%;
		padding:0;
	}
	.edit-body-mode .design-block-text.layout-2 div {
		width: 100%!important;
		margin-left: 0!important;
	}
	.design-block-text.layout-2 p {
		width: 100%;
		padding:0;
	}
}