/**MUSIC**/
.design-block-music {
	width:100%;
	max-width: 1200px;
	padding:0 30px;
	margin:0px auto;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	position: relative;
}

.design-block-music-title {
	width: 100%;
	margin-bottom: 20px;
}

.design-block-music-title h1 {
	margin:0;
	font-size: 35px;
	font-weight: 800;
}
.design-block-music-title p {
	margin:0;
}
.layout-2 .design-block-music-item {
	width: 100%;
	height: 150px;
	margin-bottom: 10px;
}
.layout-2 .design-block-music-item:before {
	display: none;
}
.design-block-music-item {
	width: calc(33.33% - 20px);
	margin:10px;
	position: relative;
}
.design-block-music-item:before {
	display: block;
	content: " ";
	padding-bottom: 100%;
}
.design-block-music-item iframe {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 6px;
	border:none;
	outline: none;
}

.design-block-music-item:hover .hover-box {
	opacity: 1;
	visibility: visible;
}
@media (max-width: 779px) {
	.design-block-music {
		padding:0 20px;
	}
	.design-block-music-item {
		width:100%;
		margin:10px 0;
	}
	.design-block-music .List .draggable {
		width: 100%;
	}
}