#menuContent {
	padding-bottom: 150px;
}

.nav-2 #menuContent {
	padding-top: 0px;
}

#menuContent .article-image {
	height: 100%;
	display: none;
}
#menuContent .article-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.menu-content {
	width: 100%;
	padding:20px;
	max-width: 900px;
	margin:0 auto;
}

.menu-title {
	width: 100%;
	padding:50px;
	padding-bottom: 100px;
}

.menu-title .change-block-image-btn {
	display: none;
	position: absolute;
	bottom: 10px;
	right: 10px;
	left: auto;
	top: auto;
}
.menu-5 .menu-title .change-block-image-btn {
	visibility: visible;
	display: flex;
	opacity: 1;
}
.menu-title h1 {
    margin:0 auto;
    width: 100%;
    padding:0;
}
.menu-title-description {
	font-size: 18px;
	width: 100%;
	max-width: 500px;
	margin:0 auto;
}
#menuContent .article-content {
	padding:60px;
}
.menu-1 .menu-block {
	margin-left: auto;
	margin-right: auto;
}
.menu-2 .menu-blocks,
.menu-4 .menu-blocks {
	flex-direction: column;
	align-items: center;
}
.menu-2 .menu-block,
.menu-4 .menu-block  {
	width: 70%;
}
.menu-4 .menu-image {
	display: flex;
	width: 100%;
}

.menu-blocks {
	text-align: left;
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
}
.menu-blocks .List {
	display: flex;
	flex-wrap: wrap;
}
.menu-blocks .List .draggable {
	width: calc(50% - 40px);
	margin:0 20px;
}
.menu-2 .menu-blocks .List {
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 600px;
}
.menu-2 .menu-blocks .List .draggable,
.menu-4 .menu-blocks .List .draggable  {
	width: 100%;
}
.menu-4 .menu-blocks .List .menu-block {
	width: 100%;
	max-width: 500px;
	margin:0 auto 80px auto;
}
.menu-4 .menu-blocks .List .draggable .menu-image {
	width: 100%;
}
.menu-5 .menu-blocks .List .draggable .menu-image {
	display: none
}

.menu-5 .click-custom-editor-style {
	z-index: 5;
	color: #fff;
}
.menu-list-hero {
	display: none;
}

.menu-blocks .List .draggable.placeholder {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #efefef;
	border:dashed 2px #ccc;
	border-radius: 6px;
	color: #ccc;
	font-weight: 600;
}
.menu-3 .menu-blocks .List .draggable {
	width: 300%;
	display: flex;	
	justify-content: space-between;
	margin-bottom: 50px;
}
.menu-3 .menu-blocks .List .draggable .menu-block {
	width: 40%;
}
.menu-3 .menu-blocks .List .draggable .menu-image {
	width: 50%;
}
.menu-blocks .List .draggable .menu-block  {
	width: 100%;
}
.menu-blocks .List .draggable .menu-image,
.menu-blocks .List .draggable .menu-block {
	width: 100%;
}

.menu-5 .menu-content {
	max-width: initial;
	padding:0;
}
.menu-5 .menu-blocks {
	max-width: 900px;
	margin:0 auto;
}

.menu-5 .menu-image {
	display: none;
}

.menu-5 .menu-title {
	padding: 0px;
	width: 100%;
	height: 400px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	flex-direction: column;
	margin-bottom: 50px;
	padding:30px;
}
.menu-5 .menu-title:before {
	content: " ";
	position: absolute;
	left: 0;
	top: 0;
	background: #000;
	opacity: 0.3;
	z-index: 2;
	width: 100%;
	height: 100%;
}
.menu-title .carousel-slide-buttons {
	display: flex;
}
.menu-title .carousel-slide-buttons .change-block-image-btn {
	    opacity: 1;
    visibility: visible;
    right: 0;
    bottom: 0px;
    top: auto;
}
.menu-title:hover .carousel-slide-buttons {
   opacity: 1;
   visibility: visible;
   width: 200px;
}

.menu-5 .menu-title h1,
.menu-5 .menu-title h2,
.menu-5 .menu-title h3,
.menu-5 .menu-title h4,
.menu-5 .menu-title p {
	position: relative;
	color: #fff;
	z-index: 4;
	margin:0;
}

.menu-5 .menu-list-hero {
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
	display: block;
}
.menu-5 .menu-block {
	text-align: left;
}
.menu-5 .menu-block-title:before {
	left: calc(50% - 50px);
}

.menu-5 .menu-block .menu-table .row {
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
}

.menu-3 .menu-image {
	display: flex;
}
.menu-image {
	width: calc(50% - 0px);
	display: flex;
	align-items: center;
	display: none;
	margin-bottom: 30px;
	position: relative;
}
.menu-image:hover .hover-box {
	opacity: 1;
	visibility: visible;
}
.menu-image img {
	width: 100%;
	height: 300px;
	object-fit: cover;
}
.menu-block {
	width: calc(50% - 40px);
	margin-bottom: 50px;
}
.menu-block-title {
	position: relative;
	font-size: 32px;
	margin-top: 0;
	width: 100%;
	margin-bottom: 0!important;
}
.menu-block-description {
	width: 100%;
	opacity: 0.8;
	margin-bottom: 25px;
}
.menu-block-title:before {
	display: none;
	content: " ";
	height: 3px;
	width: 100px;
	position: absolute;
	bottom: -10px;
	left: 0;
	background: #efefef;
}

.menu-table {
	display: flex;
	width: 100%;
	flex-direction: column;
}
.menu-table .row {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding:6px 4px;
	align-items: flex-end;
	transition:all 0.2s;
}
.menu-table .row:hover {
	box-shadow: 0 2px 6px rgba(0,0,0,0.1);
}
.menu-table .row strong {
	font-weight: 600;
}
.menu-table .row span {
	opacity: 0.9;
}
.menu-block {
	position: relative;
}
.menu-block .button-set {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	display: flex;
	justify-content: center;
	opacity: 0;
	visibility: hidden;
	align-items: center;
	z-index: 4;
	background: rgba(255,255,255,0.6);
}

.menu-content .draggable {
	transition: all 0.3s;
}


.menu-content .draggable.dragged {
	background:#fff;
	width: 320px;
}
.menu-content .draggable.dragged .menu-image {
	display: none;

}

.menu-block:hover .button-set {
	opacity: 1;
	visibility: visible;
}

.body-edit-mode .menu-block:hover .menu-block-title,
.body-edit-mode .menu-block:hover .menu-table {
	opacity: 0.4;
}

.menu-block .button-set .btn {
	margin:0 5px;
}

#addMenuItem {
	display: block;
	margin:20px auto 30px auto;
	width: 176px;
}

@media (max-width: 779px) {
	.menu-title {
		padding:10px 0px 50px 0;
	}
	.menu-image {
		width: 100%!important;
	}
	.menu-block{
		width: 100%!important;
	}
	.menu-blocks .List,
	.menu-blocks {
		flex-direction: column!important;
	}
	.menu-blocks .List .draggable .menu-block,
	.menu-blocks .List .draggable .menu-image,
	.menu-blocks .menu-block,
	.menu-blocks .menu-image {
		width: 100%!important;
	}
	.menu-blocks .List .draggable {
		margin:0;
		width: 100%!important;
		flex-direction: column!important;
	}
	.menu-image img {
		height: 200px!important;
	}
	.menu-5 .menu-blocks {
		padding:0 20px;
	}
}