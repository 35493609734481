#reviews h1,
#reviews .medium-editor-element {
	width: 100%;
}
#reviews {
	display: block;
	padding:50px 100px;
}
#reviews .reviews-title {
	width: 100%;
	text-align: center;
	padding:0 50px;
}

#reviews .reviews-title p {
	margin:0;
	font-weight: normal;
}

#reviews.design-page-align-left .reviews-title {
	text-align: left;
}
#reviews.design-page-align-right .reviews-title {
	text-align: right;
}
#reviews .slider-buttons {
	width: 100%;
	bottom: 0;
	left: 0px;
	justify-content: space-between;
	border:none;
	box-shadow: none;
	bottom: calc(50% - 105px);
	background: transparent;
}
#reviews .slider-buttons button {
	width: 50px;
	height: 50px;
	border:none;
	font-size: 20px;
}

#reviews .green-add-btn {
	width: 153px;
	position: absolute;
	top: 10px;
	opacity: 0;
	z-index: 3;
	right: 0;
	z-index: 51;
}

#reviews:hover .green-add-btn {
	opacity: 1;
}
.input-group .rating {
	margin:0px 0 10px 0;
}
.input-group .rating img {
	width: 15px;
	height: 15px;
}
.review .button-set {
	position: absolute;
	top: 0;
	left: 0;
	bottom:0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	z-index: 2;
	opacity: 0;
	transition: all 0.3s;
	transform: translateY(-10px);
}
.edit-body-mode .review:hover .review-profile,
.edit-body-mode .review:hover h4,
.edit-body-mode .review:hover .rating,
.edit-body-mode .review:hover p {
	opacity: 0.4;
}
.review:hover .button-set {
	opacity: 1;
	transform: translateY(0px);
}
.review .button-set .btn {
	border:none;
	box-shadow: 0 5px 10px rgba(0,0,0,0.1);
	font-size: 14px;
	margin:5px 0;
	cursor: pointer;
	min-width: 80px;
	background: #21283F;
	color: #fff;
}
.review .button-set .btn.remove {
	color: #000;
	background: #fff;
}

.recommendations-3 .slider-buttons,
.layout-3 .slider-buttons {
	display: none!important;
}

.recommendations-3 .review,
.layout-3 .review {
	display: block!important;
	width: 100%;
	text-align: left;
	min-height: auto;
	border-bottom: solid 1px #efefef;
	padding:60px 60px 60px 100px;
	max-width: 600px;
	margin:0 auto;
}
.recommendations-3 .review .review-profile,
.layout-3 .review .review-profile {
	width: 70px;
	height: 70px;
	position: absolute;
	top: 60px;
	left: 0;
}


.edit-body-mode.recommendations-2 #recom_items_box,
.edit-body-mode .layout-2 #recom_items_box {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}
#reviews #page_title {
	width: 100%;
}

.recommendations-2 #reviews,
.layout-2 #reviews {
	display: flex;
	flex-wrap: wrap;
}
.recommendations-2 .review,
.layout-2 .review {
	display: block!important;
	width: 50%;
	text-align: left;
	min-height: auto;
	padding:60px;
}
.recommendations-2 .review .review-profile,
.layout-2 .review .review-profile {
	width: 60px;
	height: 60px;
	float: right;
}
.recommendations-2 .rating,
.layout-2 .rating {
	margin:4px 0 40px 0;
}
.recommendations-2 .review,
.layout-2 .review {
	border:none;
}
.recommendations-2 .review:nth-child(even),
.layout-2 .review:nth-child(even) {
	border-right: none;
}
.recommendations-2 .slider-buttons,
.layout-2 .slider-buttons {
	display: none!important;
}

.recommendations-1 .review,
.layout-1 .review {
	width: calc(100% - 100px);
	margin:0 auto;
	position: relative;
	z-index: 3;
}
.review-placeholder {
	width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    align-items: center;
    max-width: 500px;
    padding: 20px;
    margin: 0 auto;
    margin-bottom: 150px;
}

#reviews .review-placeholder .green-add-btn {
	right: calc(50% - 76px);
    top: calc(50% - 25px);
}
.review-placeholder strong {
	width: 100px;
	height: 100px;
	background:#ccc;
	border-radius: 50%;
	display: block;
}

.review-placeholder .rating {
	opacity: 0.3;
	margin:20px 0;
	transform: scale(1.1,1.1);
}

.review-placeholder p {
	width: 100%;
	height: 30px;
	border-top:  solid 10px #ccc;
	border-bottom:  solid 10px #ccc;
	position: relative;
	margin-top: 0;
}

.review-placeholder p:after {
	width: 50%;
	height: 10px;
	display:  block;
	background: #ccc;
	content: " ";
	position: absolute;
	bottom: -30px;
	left: calc(50% - 25%);
}

.review {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	align-items: center;
	align-content: center;
	padding:50px 0;
	position: relative;
	min-height: 400px;
	opacity: 0;
	animation: fadeIn 0.6s forwards;
}

.review .review-profile {
	width: 100px;
	height: 100px;
	object-fit: cover;
	border-radius: 50%;
	margin-bottom: 10px;
}

.review h4 {
	font-size: 22px;
	margin:0;
	padding:0;
}

.review p {
	max-width: 500px;
	line-height: 25px;
	margin:0;
}

.rating {
	display: flex;
	margin:14px 0;
}


.rating .fa {
	font-weight: bold;
}


.rating.star-0 .fa:nth-child(5),
.rating.star-0 .fa:nth-child(4),
.rating.star-0 .fa:nth-child(3),
.rating.star-0 .fa:nth-child(2),
.rating.star-0 .fa:nth-child(1) {
	opacity: 0.2;
}


.rating.star-1 .fa:nth-child(5),
.rating.star-1 .fa:nth-child(4),
.rating.star-1 .fa:nth-child(3),
.rating.star-1 .fa:nth-child(2) {
	opacity: 0.2;
}


.rating.star-2 .fa:nth-child(5),
.rating.star-2 .fa:nth-child(4),
.rating.star-2 .fa:nth-child(3) {
	opacity: 0.2;
}

.rating.star-3 .fa:nth-child(5),
.rating.star-3 .fa:nth-child(4) {
	opacity: 0.2;
}
.rating.star-4 .fa:nth-child(5) {
	opacity: 0.2;
}

.rating .fa {
	margin:0 2px;
}

.rating .modal .fa:hover {
	opacity: 1!important;
}
.green-add-btn#addReview {
	width: 154px;
	position: absolute;
	top: 0px;
	left: calc(50% - 77px);
	margin:0px auto;
	display: block;
}

#reviewsBlock {
	width: 100%;
    max-width: 1200px;
    padding: 0 20px;
    position: relative;
    margin: 0 auto;
}
#reviewsBlock .slider-buttons {
	bottom: 0;
}
.layout-1 #reviewsBlock {
	max-width: 600px;
	padding-bottom: 40px;
}
.layout-2 #reviewsBlock {
	display: flex;
	flex-wrap: wrap;
}
@media (max-width: 779px) {
	.recommendations-2 .review,
	.layout-2 .review {
		width: 100%;
		padding:50px 20px;
		margin-bottom: 50px;
		border-bottom: solid 1px #efefef;
	}
	.recommendations-1 .review,
	.layout-1 .review {
		width: calc(100% - 50px);
	}
	.recommendations-3 .review,
	.layout-3 .review  {
		padding:60px 20px 60px 80px;
	}
	.recommendations-3 .review .review-profile,
	.layout-3 .review .review-profile {
		width: 50px;
		height: 50px;
	}
	.review {
		padding:30px 0px;
	}
	#reviews .green-add-btn {
		position: relative;
		margin:10px auto;
		display: block;
		opacity: 1;
	}
	#reviews {
		padding:20px!important;
	}
	#reviews h1 {
		font-size: 28px;
	}
	.edit-body-mode #reviews h1 {
		margin-top: 20px;
	}
	#reviews .reviews-title {
		padding-left:0;
		padding-right: 0;
	}
}