.empty-store-product-picker {
	width: 100%;
	max-width: 400px;
	margin:0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding:0 30px;
}

.empty-store-product-picker h4 {
	margin:30px 0;
	text-align: center;
	width: 100%;
}
.empty-store-product-picker img {
	width: 100%;
}

body.click-store .nav-bar {
	position: fixed;
	top: 0;
	background: rgba(255,255,255,0.95);
	z-index: 4;
}
body.click-store .master-container {
	/*padding-top: 100px;*/
}

body.click-store {
    background-repeat: repeat;
    background-size: 6px 6px;
}

body.click-store .nav-bar .nav-logo {
	width: auto;
	margin:0;
	height: 30px;
	box-shadow:none;
}

.blank-btn {
	padding:12px 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #262E47;
	border-radius: 6px;
	color: #fff;
	cursor: pointer;
	margin-top: 20px;
	outline: none;
	border:none;
}

.product-inventory .blank-btn {
	padding: 10px 20px;
    margin-top: 0;
}

.blank-btn:hover {
	background: #465379;
}

.blank-btn .fa {
	margin-right: 10px;
}
.btn.green img {
	width: 15px;
	margin-right: 15px;
}

.btn.green:focus {
	outline: none;
}


.product-options select {
	width: calc(100% - 120px);
	padding-left: 20px;
	padding-right: 20px;
	height: 60px;
	border:solid 1px #efefef;
	border-bottom: solid 2px #000;
}
.product-options label {
	position: absolute;
	font-size: 12px;
	font-weight: 600;
	left: 0px;
	top: -19px;
	z-index: 2;
	opacity: 0.5;
}

.text-white .product-options label {
	color: #fff;
}
.product-options select {
	background-image: linear-gradient(45deg, transparent 50%, #333 50%),
    linear-gradient(135deg, #333 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(2em + 0px), calc(100% - 15px) calc(2em + 0px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 45px;
    background-repeat: no-repeat;
    background-color: #fff;
    appearance: none;
    -webkit-appearance: none;
  	-moz-appearance: none;
}
.product-options input {
	height: 60px;	
	width: 100px;
	margin-right: 20px;
	margin-bottom: 20px;
	background: #fff;
	padding:10px;
	transition: all 0.2s;
	text-align: center;
	color: #333;
	border:solid 1px #efefef;
	border-bottom: solid 2px #000;
	font-size: 16px;
}
.product-options select:hover,
.product-options input:hover,
.product-options select:focus,
.product-options input:focus{
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
}

#productContent {
	justify-content: flex-end;
}

.product-1#productContent,
.product-2#productContent {
	justify-content: center;
	align-items: flex-start;
	padding:30px;
}
.product-1#productContent .product-image-wrapper,
.product-2#productContent .product-image-wrapper {
	position: relative;
	display: block;
	min-height: initial;
	height: auto!important;
	max-height: initial;
	margin-bottom: 200px;
}
.product-1#productContent .product-image-wrapper,
.product-1#productContent .product-details-wrapper,
.product-2#productContent .product-image-wrapper,
.product-2#productContent .product-details-wrapper {
	max-width: 600px;
}
.product-1#productContent .product-image-wrapper:before ,
.product-2#productContent .product-image-wrapper:before {
	padding-top: 100%;
	content: " ";
	display: block;
}

.product-1 .product-image-wrapper .product-image-thumbs,
.product-2 .product-image-wrapper .product-image-thumbs {
	z-index: 3;
    width: 100%;
    height: 20%;
    flex-direction: row;
    left: 0;
    bottom: calc(-20% - 3px);
    justify-content: flex-start;
}

.product-1#productContent .product-image-wrapper .product-image-thumbs img,
.product-2#productContent .product-image-wrapper .product-image-thumbs img	{
	width: 20%;
	height: 100%;
	border-radius: 0;
	border:none;
	margin:0px;
	border-right:solid 3px transparent;
	transform: none;
	box-shadow: none;
	opacity: 0.5;
}
.product-1#productContent .product-image-wrapper .product-image-thumbs img.active,
.product-2#productContent .product-image-wrapper .product-image-thumbs img.active,
.product-1#productContent .product-image-wrapper .product-image-thumbs img:hover,
.product-2#productContent .product-image-wrapper .product-image-thumbs img:hover {
	opacity: 1;
}
.product-1#productContent .product-image-wrapper .product-image-thumbs img:last-child,
.product-2#productContent .product-image-wrapper .product-image-thumbs img:last-child {
	border:none;
}

.product-1 .product-image-wrapper img.active ,
.product-2 .product-image-wrapper img.active {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
}

@media (max-width: 779px) {
	.product-1#productContent,
	.product-2#productContent {
		justify-content: flex-start;
		align-items: center;
		padding-top: 0px!important;
	}
	.product-1#productContent .product-image-wrapper, 
	.product-2#productContent .product-image-wrapper {
		margin-bottom: 0;
		display: flex;
		flex-direction: column;
		height: auto;
	}
	.product-1#productContent .product-image-wrapper img.active,
	.product-2#productContent .product-image-wrapper img.active {
		max-height: 520px;
		position: relative;
	} 
	.product-1#productContent .product-image-wrapper:before,
	.product-2#productContent .product-image-wrapper:before {
		display: none;
	}
	.product-1 .product-image-wrapper .product-image-thumbs,
	.product-2 .product-image-wrapper .product-image-thumbs  {
		position: relative;
		bottom: auto;
		left: auto;
		order: 2;
		margin-top: 3px;
	}
	.product-1#productContent .product-image-wrapper .product-image-thumbs img,
	.product-2#productContent .product-image-wrapper .product-image-thumbs img {
		width: 20%;
		height: 80px!important;
	}
}
#productContent .design-block-toolbar {
	opacity: 1;
    visibility: visible;
    transform: none;
}
.product-details-title {
	position: relative;
}
#productContent.product-2 {
	flex-direction: row-reverse;
}

@media (min-width: 779px) { 
#productContent.product-3 {
	flex-wrap: wrap;
}

@media (min-width: 779px) { 
	#productContent.product-3 .product-options {
		flex-wrap: nowrap;
	}
	#productContent.product-3 .product-options select {
		margin-right:20px;
	}
	#productContent.product-4 {
		justify-content: center;
	}
	#productContent.product-4 .product-image-wrapper {
		max-width: 550px;
	}
	#productContent.product-4 .product-image-wrapper img {
		object-fit: contain;
		object-position: 50%;
	}

	#productContent.product-4 .product-details-title h1 {
		font-size: 40px;
	}

	#productContent.product-4 .product-details-wrapper {
		padding:50px;
		max-width: 550px;
	}
	#productContent.product-4 .product-image-wrapper .product-image-thumbs img{
		object-fit: cover;
		object-position: 50% 50%;
	}

	#productContent.product-4 .product-image-wrapper .product-image-thumbs {
		margin-left: 20px;
	}
}


#productContent.product-3 .product-image-wrapper {
	width: 100%;
	border-left:solid 0px transparent;
	border-right:solid 0px transparent;
	max-width: 1400px;
	margin:0 auto;
}
#productContent.product-3 .product-image-wrapper .product-image-thumbs {
	left: auto;
	right: 30px;
	z-index: 45;
}
#productContent.product-3 .product-image-detail {
	position: absolute;
	top: 0;
	left: 0;
}
#productContent.product-3 .product-details-wrapper {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	max-width: 900px;
	margin:-140px auto 100px auto;
	background: #fff;
	width: 100%;
	justify-content: space-between;
	padding:30px;
	align-items: flex-start;
	background: inherit;
}

#productContent.product-3 .product-details-title h1 {
	font-size: 45px;
}

#productContent.product-3 .product-details-title {
	order:1;
	width: calc(100% - 350px);
}
}

#productContent.product-3 .price-wrapper {
	order:2;
	width: 100%;
	border-bottom: none;
	padding:0;
	justify-content: space-between;
}
#productContent.product-1 .price-wrapper,
#productContent.product-2 .price-wrapper,
#productContent.product-4 .price-wrapper {
	flex-direction: row;
	align-items: flex-start;
}

#productContent.product-1 .price-wrapper .cart-button,
#productContent.product-2 .price-wrapper .cart-button,
#productContent.product-4 .price-wrapper .cart-button {
	margin-top: 15px;
}
#productContent.product-3 .product-options {
	order:3;
	width: 100%;
}

#productContent.product-3 .product-description {
	order:4;
	width: 100%;
	max-width: 700px;
	margin:50px auto;
}
.edit-body-mode .product-image-wrapper {
	height: calc(100vh - 120px);
}
.product-image-wrapper .product-image-thumbs {
	position: absolute;
	left: 10px;
	bottom: 0px;
	display: flex;
	flex-direction: column;
	width: 60px;
	height: calc(100% - 0px);
	justify-content: center;
}
.product-image-wrapper .product-image-thumbs img,
.product-image-wrapper .product-image-thumbs img.active {
	width: 60px;
	height: 60px;
	transition: all 0.2s;
	object-fit: cover;
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
	border-radius: 4px;
	margin-bottom: 5px;
	border:solid 2px #fff;
	cursor: pointer;
	position: relative;
	opacity:1;
	transform: scale(0.9,0.9);
}

.product-image-wrapper .product-image-thumbs img:hover,
.product-image-wrapper .product-image-thumbs img.active  {
	transform: none;
}
.product-image-wrapper:hover .change-block-image-btn {
	opacity: 1;
	visibility: visible;
}
.product-image-wrapper {
	width: 50%;
	position: relative;
    /*padding: 100px 0px 0px 60px;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    top: 0;
    height: calc(100vh - 80px);
    min-height: 550px;
    align-items: center;
    align-content: center;
    padding-top: 0px;
}

.product-image-wrapper img {
	/*max-height: 100%;
    max-width: 100%;*/
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.product-image-wrapper img.active {
	display: block;
}

.product-details-wrapper {
	width: 50%;
	padding:80px;
}
.price-wrapper {
	width: 100%;
	justify-content: flex-start;
	align-items: center;
	font-size: 35px;
	position: relative;
	display: flex;
	padding:20px 0;
	/*border-bottom: solid 1px #efefef;*/
	margin-bottom: 20px;
}
.cart-button {
	background: #63CAA1;
	height: 60px;
	border-radius: 8px;
	padding:15px 30px;
	box-shadow: inset 0 -2px 0 rgba(0,0,0,0.2);
	color: #fff;
	font-size: 14px;
	position: relative;
	font-weight: bold;
	text-transform: uppercase;
	/*margin-left: 40px;*/
	cursor: pointer;
	min-width: 161px;
	display: flex;
	align-items: center;
	transition: all 0.2s;
	flex-shrink: 0; 
	justify-content: center;
}

.cart-button.sold-out-button {
	background: #ccc;
	color: #333;
	opacity: 0.4;
	box-shadow: none;
	cursor: not-allowed;
}

.cart-button .cart-icon {
	width: 20px;
	margin-right: 10px;
	filter: invert(100%);
	margin-left: -10px;
	transition: all 0.2s;
}
.cart-button:hover .cart-icon {
	transform: scale(1.15,1.15);
}
.cart-button:hover {
	filter: grayscale(0.3);
}
#productContent .product-details-title h1 {
	margin:0;
	padding:0;
}

#productContent .product-details-title h2 {
	margin:0;
}

#productContent .product-details-title h3 {
	margin:0;
}

#productContent .product-details-title h4 {
	margin:0;
}

.product-options {
	width: 100%;
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	margin: 20px 0;
	flex-wrap: wrap;
}

.product-options .rc-input-number {
	height: 52px;
	width: 73px!important;
	flex-shrink: 0;
}
.product-options .rc-input-number-handler-wrap {
	position: absolute;
	height: 100%;
	width: 73px;
	display: flex;
	justify-content: space-between;
}

.product-options .rc-input-number-handler {
	width: 20px;
	height: 100%;
	cursor: pointer;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	border-left: solid 1px #efefef;
}

.product-options .rc-input-number-handler div {
	width: 7px;
	height: 7px;
	border-bottom: solid 1px #333;
	border-right: solid 1px #333;
	transform: rotate(45deg);
}
.product-options .rc-input-number-handler.rc-input-number-handler-up div {
	border-bottom: none;
	border-right: none;
	border-top: solid 1px #333;
	border-left: solid 1px #333;
	margin-top: 7px;
}

.product-options .rc-input-number-handler.rc-input-number-handler-up {
	border-right: solid 1px #efefef;
	border-left: none;
}

.product-options .rc-input-number-input-wrap {
	height: 100%;
}
.product-options .rc-input-number-input-wrap input {
	height: 100%;
	text-align: center;
	padding: 0;
	width: 100%;
}
.flex-container .product-description h2 {
	font-size: 27px;
	font-weight: 600;
	margin:15px 0 5px 0;
}

.flex-container .product-description li {
	margin-bottom: 15px;
}

#productCategoryContent {
	flex-direction: column;
}

#cartModal h1 {
	font-size: 25px;
	font-weight: 200;
	padding-bottom: 20px;
}

#cartModal h4 i {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
}
#cartModal h4 p {
	font-weight: normal;
    font-size: 12px;
    margin: 6px 0;
    padding: 3px 6px;
    border-radius: 4px;
    border: solid 1px #efefef;
    display: inline-block;
}

.cart-item {
	display: flex;
	position: relative;
	flex-direction: row;
	justify-content: center;
	padding:20px 0;
	border-bottom: solid 1px #efefef;
	align-items: center;
	cursor: pointer;
	transition: all 0.4s ease;
}

.cart-item h4 i {
	font-style: normal;
	font-weight: 400;
}

.cart-item:before {
	height: 100%;
	width: 5px;
	background: #63CAA1;
	left: -50px;
	top: 0;
	position: absolute;
	content: " ";
	transition: all 0.2s;
}
.cart-item:hover:before {
	width: 10px;
}

.cart-item .remove {
	position: absolute;
	background: #63CAA1;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	box-shadow: 0 5px 10px rgba(0,0,0,0.1);
	top: 10px;
	right: 0px;
	color: #fff;
	text-align: center;
	line-height: 17px;
	font-size: 12px;
}
.cart-modal-close-overlay {
	background: rgba(0,0,0,0.3);
	width: 100vw;
	height: 100vh;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
	animation:fadeIn 0.3s forwards;
}
#cartModal.modal .modal-content {
	padding:0px;
	animation: cartIn .5s forwards;
	height: 100%;
	right: 0;
	top: 0;
	border-radius: 0;
}
.cart-total-flex,
.saved-amount-row {
	width:100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.cart-checkout-button {
	width: 100%;
	text-align: center;
	padding:18px 36px;
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	border-radius: 6px;
	box-shadow: inset 0 0 5px rgba(0,0,0,0.1);
	margin-top: 20px;
	cursor: pointer;
	font-size: 18px;
	transition:0.2s;
}

.cart-checkout-button:hover {
	opacity: 0.9;
	box-shadow: 0 6px 18px rgba(0,0,0,0.2);
}
.cart-modal-header {
	padding:20px;
	background: #fff;
	position: sticky;
	top: 0;
	z-index: 2;
	width: 100%;
	box-shadow: 0 6px 20px rgba(0,0,0,0.03);
}
#cartModal .cart-modal-header h1 {
	width: 100%;
    margin: 0;
    padding: 10px;
    font-size: 21px;
}
.cart-modal-scroll {
	padding:30px 30px 30px 20px;
	background: #fbfbfb;
	width: 100%;
}
.empty-cart-placeholder {
	width: 100%;
	min-height: 180px;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0.5;
	flex-direction: column;
}
.empty-cart-placeholder img {
	width: 90px;
	height: 90px;
	object-fit: contain;
}

.empty-cart-placeholder h4 {
	margin:0;
}

.cart-modal-footer {
	width: 100%;
	background: #fff;
	position: sticky;
	bottom: 0;
	z-index: 2;
	padding:20px 30px;
	box-shadow: 0 -6px 20px rgba(0,0,0,0.03);
}
.cart-product-price i {
	font-style: normal;
}
.cart-product-option {
	position: absolute;
	bottom: 20px;
}
.cart-product-footer {
	width: calc(100% - 128px);
	padding-right: 20px;
	display: flex;
	justify-content: space-between;
	font-size: 12px;
	opacity: 0.4;
	position: absolute;
	bottom: 16px;
	right: 0;
}
.cart-product {
	width: 100%;
	display: flex;
	border-radius: 6px;
	background: #fff;
	padding:8px;
	margin-bottom: 15px;
	justify-content: flex-start;
	align-items: flex-start;
	position: relative;
	box-shadow: 0 6px 20px rgba(0,0,0,0.03);
}
.cart-product:last-of-type {
	margin-bottom: 0;
}
.cart-product-old-price {
	text-decoration: line-through;
	color: red;
	margin-left: 8px;
}
.cart-product-price {
	font-size: 14px;
}
.cart-product-right {
	position: relative;
	width: calc(100% - 120px);
	margin-top: 10px;
}

.cart-product-remove {
	position: absolute;
    top: 9px;
    right: 9px;
    font-size: 10px;
    opacity: 0.5;
    cursor: pointer;
}
.cart-product-remove:hover {
	opacity: 1;
}
.cart-product img {
	width: 100px;
	height: 100px;
	border-radius: 4px;
	object-fit: cover;
	margin-right: 20px;
}
.cart-product h4 {
	font-weight: 600;
	margin:0;
	font-size: 18px;
	width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}
#orderDetail.modal.side .modal-content {
	background: #fff;
	color: #333;
	opacity: 0;
	animation: cartIn .5s forwards;
}

#orderDetail.modal.side .modal-content .close {
	filter: invert(1);
}

.stripe-secure-payment {
	width: 100%;
	margin-top: 30px;
	display: flex;
	justify-content: center;
}
.stripe-secure-payment img {
	max-width: 360px;
}

#checkoutModal.modal.side .modal-content {
	max-width: initial;
	width: 100vw;
	background: #fafafa;
	opacity: 0;
	animation: fadeIn .5s forwards;
	color: #333;
	padding:0;
	left: 0;
	top: 0;
	border-radius: 0;
	height: 100%;
	justify-content: center;
}

#cartModal #closeModal:before,
#cartModal #closeModal:after {
	background: #333;
}

@keyframes cartIn {
	0% {opacity: 0; transform: translateX(200px);}
	100% {opacity: 1; transform: translateX(0px);}
}

#cartModal .cart-item {
	opacity: 0;
	transform: translateY(20px);
	border:solid 1px #efefef;
	cursor: default;
}

#cartModal .cart-item:hover {
	transform: scale(1.03,1.03);
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
}

#cartModal.active .cart-item {
	opacity: 1;
    transition-delay: 0.3s;
    transform: none;
    width: 100%;
    border-radius: 6px;
    justify-content: flex-start;
    box-shadow: 0 6px 12px rgba(0,0,0,0.05);
    padding: 0;
    overflow: hidden;
    margin-bottom: 10px;
}

.cart-item h4 {
	font-size: 16px;
	margin:10px 0;
}

.cart-item h4 strong {
	font-weight: normal;
}

#cartModal.active .cart-item img {
	border-radius: 3px;
	margin:0 14px 0 7px;
	width: 80px;
	height: 80px;
	object-fit: cover;
}

#cartModal.active .cart-item .remove {
	opacity: 0;
	transform: scale(0.8,0.8);
	transition: 0.2s all;
}

#cartModal.active .cart-item:hover .remove {
	opacity: 1;
	transform: scale(1,1);
}

#removeCategory {
	opacity: 0;
	visibility: hidden;
	transition: all 0.2s;
}

#categoryTable  {
	box-shadow: none;
	background: transparent;
}
#categoryTable .table-row {
	background: #fff;
	border:solid 1px #efefef;
	margin-bottom: 10px;
}
#categoryTable .table-row:hover {
	transform: none;
}
#categoryTable .table-row:hover #removeCategory {
	opacity: 1;
	visibility: visible;
}
#removeCategory span {
	padding:5px 10px;
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	color: #fff;
	border-radius: 20px;
}

.cart-item span {
	padding:0 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: center;
	height: 100%;
}

.cart-item img {
	width: 80px;
	transition: all 0.3s ease;
}

.cart-item:hover img {
}
.checkout-button {
	background: #63CAA1;
	box-shadow: 0 10px 15px rgba(0,0,0,0.1), 0 0 0 0 rgba(0,0,0,0);
	border-radius: 40px;
	padding:20px 40px;
	color: #fff;
	font-size: 14px;
	font-weight: bold;
	text-transform: uppercase;
	cursor: pointer;
	margin-top: 30px;
	transition: all 0.2s;
	min-width: 161px;
}
.checkout-button:hover {
	box-shadow: 0 10px 15px rgba(0,0,0,0.1), 0 0 0 10px rgba(0,0,0,0.05);
	transform: translateY(-3px);
}
.total-amount {
	width: 100%;
	text-align: center;
	font-size: 25px;
	font-weight: bold;
	left: 0;
}
.total-amount span {
	display: block;
	font-weight: normal;
	font-size: 14px;
	opacity: 0.8;
}

.saved-amount-row {
	font-size: 17px;
    font-weight: normal;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: solid 1px #efefef;
}

.saved-amount-row strong {
	font-weight: 600;
	color: #4ac94a;
}


#cartContent {
	flex-direction: row;
	position: relative;
	justify-content: flex-start;
}

.checkout-cart h1 {
	font-size: 19px;
	margin: 30px 0 20px 0!important;
	font-weight: bold;
}

.checkout-cart .cart-item {
	border-radius: 5px;
	background: #fff;
	border:solid 1px #efefef;
	justify-content: flex-start;
	margin-bottom: 10px;
	padding:0px ;
	box-shadow: 0 5px 25px rgba(0,0,0,0.05);
}

.shipping-well {
	border: solid 1px #efefef;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: normal;
    box-shadow: 0 4px 8px rgba(0,0,0,0.05);
    margin-top: 20px;
}

.shipping-well h4,
.shipping-well p {
	margin:0;
}
.shipping-well h4 {
	margin-bottom: 5px;
}
.checkout-cart .cart-item h4 strong {
	font-size: 16px;
	font-weight: normal;
	opacity: 0.5;
	padding-left: 6px;
	margin-left: 6px;
	border-left: solid 1px #efefef;
}
.checkout-cart .cart-totals-table .row {
	font-size: 17px;
}

.checkout-cart .cart-item img {
	width: 120px;
	height: 120px;
	object-fit: cover;
}
.checkout-cart .cart-item:before {
	display: none;
}
.checkout-cart .cart-item span {
	width: 60px;
}

.checkout-cart .cart-item .remove {
	top: -5px;
	right: -5px;
}


.checkout-cart {
	width: 50%;
	max-width: 700px;
	padding:60px;
	height: 100vh;
	overflow: auto;
	background: #fafafa;
	right: 0;
	bottom: 0;
}
.cart-totals-table {
	display: flex;
	padding: 10px 20px;
	flex-direction: column;
}
.cart-totals-table .row {
	font-size: 14px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin:3px 0;
}

.cart-totals-table .row.large {
	font-size: 24px;
	border-top: solid 1px #efefef;
	padding-top: 20px;
	margin-top: 20px;
}
.cart-totals-table .row.large .amount {
	font-weight: bold;
}


.checkout-form {
	width: 50%;
	background: #fff;
	height: 100vh;
	padding: 60px 100px;
	overflow: auto;
	display: none;
	opacity: 0;
	animation: fadeIn 1s forwards;
	transform: translateX(0px);
	transition: all 0.4s ease;
	max-width: 700px
}

.checkout-form.active {
	display: block;
	opacity: 1;
}

@keyframes fadeInCheckout {
	0% {opacity: 0; transform:translateX(100px);}
	100% {opacity: 1; transform:translateY(0px);}
}

.checkout-form h2 {
	font-size: 19px;
	font-weight: bold;
	margin: 30px 0 10px 0;
}
.checkout-form h2.separator {
	margin-top: 50px;
}
.checkout-terms {
	display: flex;
	justify-content: flex-start;
	font-size: 14px;
	width: 100%!important;
	margin-top: 10px;
}
.checkout-terms a {
	text-decoration: underline;
}
.checkout-terms div {
	width: 100%!important;
	margin-left: 5px;
}
.checkout-form-group {
	width: 100%;
	margin-bottom: 10px;
	position: relative;
}
.checkout-form-group .edit-checkout-form-group {
	width: 30px;
    height: 30px;
    color: #fff;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    top: 10px;
    position: absolute;
    z-index: 2;
    background: #262E47;
    right: 10px;
    transition:0.1s;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
}

.checkout-form-group .edit-checkout-form-group:hover {
	background: #2f3d67;
}
.checkout-form-group:hover .edit-checkout-form-group {
	opacity: 1;
	visibility: visible;
}
.checkout-form-group .dd-group {
	width:calc(50% - 2px);
}
.checkout-form-group .dd-group div,
.checkout-form-group .dd-group div select {
	width: 100%;
}
.checkout-form-group.row, 
.checkout-form-group.credit-card-name,
.checkout-form-group.credit-card-number {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.checkout-form-group.credit-card-name input:first-child {
	width: calc(70% - 5px);
}	
.checkout-form-group.credit-card-name input:last-child {
	width: calc(30% - 5px);
}	

.checkout-form-group.credit-card-number input {
	width: calc(25% - 5px);
}

.checkout-form-group.row input {
	width: calc(50% - 5px);
}

.checkout-form-group.row select {
	width: calc(100% - 10px);
	background: transparent;
}

.checkout-form-group select {
	height: 45px;
}
.checkout-form-group input,
.checkout-form-group select,
.checkout-form-group textarea {
	border: 1px #d9d9d9 solid;
    background-clip: padding-box;
    border-radius: 5px;
    display: block;
    outline: none!important;
    width: 100%;
    padding: 0.92857em 0.78571em;
    word-break: normal;
    font-size: 14px;
	height: 45px;
	font-family: inherit;
}
.checkout-form-group textarea {
	height: 100px;
}
.checkout-form-group input:focus,
.checkout-form-group textarea:focus {
	box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}
.checkout-form .checkmark {
	top: 15px;
	left: 15px;
}
 .checkout-form .checkmark:after {
 	display: none!important;
 }
.checkout-form .cart-button {
	float: right;
	padding:20px 30px;
	min-width: auto;
	margin:0;
	justify-content: center;
}
.checkout-form .cart-button {
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
}
.checkout-form .cart-button.paypal {
	background: #003087;
}

.checkout-form .cart-button.paypal img {
	width: 60%;
	margin:auto;
	max-width: 65px;
}
.checkout-form .button-set {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.checkout-form .button-set .cart-button {
	width: calc(50% - 10px);
	text-align: center;
}

.checkout-form .button-set div:not(.cart-button) {
	width: calc(50% - 10px)
}
.checkout-form .button-set div .cart-button {
	width: 100%;
}

.checkout-form .cart-button.empty {
	float: left;
	background: transparent;
	box-shadow: none;
	color: #000;
	margin-left: 0;
	padding-left: 0;
}

.radio-button-container {
	padding:0px;
	border: 1px #d9d9d9 solid;
	border-radius: 6px;
}
.radio-button {
  display: block;
  position: relative;
  cursor: pointer;
 height: 45px;
  font-size: 16px;
      padding: 12px 40px;
  user-select: none;
  border-bottom: 1px #d9d9d9 solid;
}

/* Hide the browser's default radio button */
.radio-button input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.radio-button:last-of-type {
	border-bottom: none;
}
/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 12px;
  left: 10px;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}
.radio-button .add-price {
	float: right;
	font-size: 14px;
	opacity: 0.5;
	margin-right: -10px;
}

/* On mouse-over, add a grey background color */
.radio-button:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio-button input:checked ~ .checkmark {
  background-color: #63CAA1;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-button input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-button .checkmark:after {
 	top: 6px;
	left: 6px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}


.summary-table {
	width: 100%;
	margin-bottom: 50px;
	border-radius: 5px;
	box-shadow: 0 8px 16px rgba(0,0,0,0.05);
	background-color: #fff;
	border:solid 1px #efefef;
}

.summary-table .row {
	display: flex;
	padding:10px;
	border-bottom: solid 1px #efefef;
	flex-direction: row;
	font-size: 14px;
	justify-content: flex-start;
}

.summary-table .row a {
	color: #63CAA1;
	width: 50px;
}
.summary-table .row div {
	width: calc(100% - 130px);
}
.summary-table .row span {
	font-size: 14px;
	font-weight: bold;
	width: 80px;
}





@media screen and (max-width: 992px) {
 .product-grid-item {
 	width: 33.333%;
 }
}

@media screen and (max-width: 600px) {
 .product-grid-item {
 	width: 50%;
 }


}

#manageStoreContent {
	height: 100vh;
	padding-top: 0;
}

.manage-store-navbar {
	width: 250px;
	border-right: 1px solid #efefef;
	padding: 0 40px;
	background: linear-gradient(-45deg, #3c4665 0%, #21283F 100%);
	color: #fff;
	height: calc(100vh - 40px);
	margin-top: 20px;
	margin-left: 20px;
	padding-top: 36px;
	box-shadow: 0 14px 28px rgba(0,0,0,0.05), 0 10px 10px rgba(0,0,0,0.1);
	border-radius: 8px;
	position: relative;

}

.manage-store-navbar .clickai-logo {
	width: 60px;
	position: absolute;
	bottom: 20px;
	left: 40px;
}
.manage-store-navbar .clickai-logo img {
	width: 100%;
}

.manage-store-navbar ul {
	margin:50px 0 0 0;
	padding:0;
}

.manage-store-navbar ul li {
	list-style-type: none;
	margin:0;
	float: left;
	padding:0;
	width: 100%;
}

.manage-store-navbar ul li a {
	padding:10px 0px 10px 0px;
	color: #fff;
	float: left;
	opacity: 0.4;
	width: 100%;
	position: relative;
}
.manage-store-navbar ul li a:hover,
.manage-store-navbar ul li a.active {
	opacity: 1;
}


.manage-store-navbar ul li a.active:after {
  width: 0; 
  height: 0; 
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #323a53;
  content: " ";
  position: absolute;
  right: -48px;
  top: 10px;
}
.manage-store-navbar ul li a:hover img {
	transform:translateY(3px) scale(1.15,1.15);
}

.manage-store-navbar ul li img {
	height: 15px;
	margin-right: 20px;
	width: 15px;
	transform:translateY(3px);
	object-fit: contain;
	transition: all 0.3s;
	filter: invert(1);
}

.store-info {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
}
.flex-container .store-info h4 {
	font-size: 25px;
	margin:0;
}

.store-info h1 {
	font-weight: 400;
	margin:0;
	font-size:24px;
	padding:0;
}

.manage-store-cards {
	display: flex;
	justify-content: space-between;
	margin-bottom: 50px;
	margin-top: -50px;
}

.manage-store-cards div {
	display: flex;
	flex-direction: column;
	padding: 20px 40px 20px 40px;
	align-items: flex-start;
	justify-content: flex-start;
	text-align: left;
	font-size: 30px;
	border-radius: 8px;
	width: calc(33.33% - 20px);
	position: relative;
	border:solid 1px #efefef;
	font-weight: 600;
	overflow: hidden;
	box-shadow: 0 8px 16px rgba(0,0,0,0.05);
	background: #fff;
}

.manage-store-cards div .recharts-wrapper {
	/*width: calc(100% + 80px)!important;
	margin-left: -40px!important;*/
}
.manage-store-cards div.pending:before {
	position: absolute;
	top: 0px;
	left: 0px;
	content: " ";
	width: 9px;
	height: 100%;
	background:linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
}

.manage-store-cards div span {
	font-size: 14px;
	text-transform: uppercase;
	margin-top: 0px;
	opacity: 0.6;
	font-weight: normal;
}

.manage-store-title {
	width: 100%;
	position: relative;
	margin-bottom: 50px;
}

.manage-store-title h1 {
	font-size: 35px!important;
	font-weight: 700;
	margin:0 0 0px 0!important;
	line-height: normal;
}

.manage-store-title p {
	margin:0;
	opacity: 0.7;
	font-size: 16px;
}

.manage-store-subtitle  {
	margin:0;
	padding:0;
	width: 100%;
}

.payment-options-info {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}
.payment-options-info div {
	width: 50%;
	margin-bottom: 20px;
}

.payment-options-info div.credit-cards-options {
	width: 100%;
	flex-wrap: wrap;
	display: flex;
}
.payment-options-info div.credit-cards-options img {
	width: 45px;
	height: 30px;
	border-radius: 6px;
	margin-right: 5px;
	margin-top: 5px;
	border:solid 1px #efefef;
}
.payment-options-info div h4,
.payment-options-info div p {
	margin:0;
}
.payment-options-info div h4 {
	font-size: 19px;
	width: 100%;
}
.payment-options-info div p {
	opacity: 0.6;
	font-weight: normal;
	font-size: 17px;
}

.currency-dropdown.input-group {
	margin-top: 0;
	margin-bottom: 20px;
}
.currency-dropdown.input-group select,
.currency-dropdown.input-group select:focus {
	background: #fff;
	transition: 0.2s;
	box-shadow: 0 6px 12px rgba(0,0,0,0.05);
	cursor: pointer;
	background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat #fff;
    background-position: right 10px top!important; /* Change the px value here to change the distance */
    -moz-appearance: none; 
    -moz-appearance: none; 
    -webkit-appearance: none; 
    appearance: none;
    font-size: 18px;
    font-weight: 600;
}
.currency-dropdown.input-group select:hover {
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
}


.payment-option-accordion  .p-o-content {
	border-top-color: #fff;
}
.p-o-footer {
	width: calc(100% + 60px);
	margin-left: -30px;
	background: #fff;
	border-top:dashed 1px #efefef;
	padding:20px;
}
.p-o-footer .connect-button {
	margin-right: 10px;
}
.p-o-footer .connect-button.empty {
	background: #fff;
	border:solid 1px #efefef;
	color: #333;
}
.p-o-footer .connect-button.empty:hover {
	background: #efefef;
}
.payment-option-accordion.active  .p-o-content {
	padding:30px 30px 0 30px;
	background: #fafafa;
	border-top: dashed 1px #efefef;
}
#productList,
#productEdit {
	display: none;
}
#productList.active,
#productEdit.active {
	display: block;
}
.add-product-button {
	position: absolute;
	right: 0;
	top: 0;
	padding:15px 20px;
	box-shadow: 0 5px 15px rgba(0,0,0,0.1);
	border-radius: 40px;
	color: #fff;
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	text-transform: uppercase;
	font-size: 14px;
	cursor: pointer;
	font-weight: 600;
}

.add-product-button.empty {
	color: #333;
	border:solid 1px #efefef;
	background: #fff;
}
.add-product-button.empty:hover {
	background: #efefef;
}
.add-product-button.empty:before {
	display: none;
}
.add-product-button:hover {
	background: #28C76F;
}

.add-product-button:before {
	width: 15px;
	height: 15px;
	content: "+";
	line-height: 15px;
	border: solid 1px #fff;
	border-radius: 50%;
	display: inline-block;
	text-align: center;
	margin-right: 10px;
}

.add-option-button:hover {
	border-color: #000;
}
.add-option-button {
	width: auto;
	display: inline-block;
	padding:15px 20px;
	box-shadow: 0 5px 15px rgba(0,0,0,0.1);
	border-radius: 40px;
	color: #fff;
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	font-size: 14px;
	font-weight: 600;
	cursor: pointer;
	transition: all 0.2s;
}

.add-option-button:before {
	width: 15px;
	height: 15px;
	content: "+";
	line-height: 15px;
	border: solid 1px #fff;
	border-radius: 50%;
	display: inline-block;
	text-align: center;
	margin-right: 10px;
}

.section-actions {
	position: fixed;
	bottom: 0;
	right: 0;
	padding:30px;
	display: flex;
}


.section-actions .button {
	width: 150px;
	text-align: center;
	padding:15px 20px;
	
	border-radius: 40px;
	color: #000;
	background: #fff;
	border: solid 1px #efefef;
	text-transform: uppercase;
	font-size: 14px;
	margin-left: 20px;
	font-weight: 600;
}

.section-actions .button.save-section {
	background:  #63CAA1;
	color: #fff;
	box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.store-info p, .section-title p {
	font-size: 13px;
	opacity: 0.4;
	font-weight: 400;
	margin:0;
}

.manage-store-sections {
	width: 100%;
	padding:100px 20px 60px 20px;
	overflow:auto;
	min-height: 600px;
}

.manage-store-sections .section {
	width: 100%;
	max-width: 768px;
	display: block;
	flex-direction: column;
	margin:0 auto;
	animation:sectionIn 0.8s ease forwards;
}

@keyframes sectionIn {
	from {opacity: 0; margin-top: 20px;}
	to {opacity: 1; margin-top: 0}
}
.section input, .section textarea {
	    border: 1px #d9d9d9 solid;
    background-clip: padding-box;
    border-radius: 5px;
    display: block;
    outline: none!important;
    width: 100%;
    padding: 0.92857em 0.78571em;
    word-break: normal;
    font-size: 14px;
}
.section textarea {
	height: 210px;
}
.product-input-group {
	display: flex;
	flex-direction: row;
}
.product-input-wrapper {
	margin-bottom: 20px;
}

.product-input-wrapper input {
	width: 100%;
}

.product-input-group .product-input-wrapper {
	margin-right: 20px;
}

.section label {
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 500;
	margin-bottom: 5px;
}
.section .product-title input,
.section .product-price-wrapper input {
	font-size: 18px;
}
.manage-store-sections .section.active {
	display: block;
}

.cart-item-trigger {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 10;
	cursor: pointer;
}

.manage-store-sections .section .cart-item {
	justify-content: flex-start;
	overflow: visible;
}
.manage-store-sections .section .cart-item:before {
	display: none;
}
.manage-store-sections .section .cart-item img {
	min-width: 160px;
	width: 160px;
	height: 160px;
	border-radius: 6px 0 0 6px;
	object-fit: cover;
	margin-right: 40px;
}
.manage-store-sections .section .cart-item .card-dropdown {
	right: 210px;
	z-index: 20;
}
.action-buttons  {
	margin-left: auto;
	align-self: center;
	transform: scale(0.9,0.9);
	opacity: 0;
	transition:0.2s ease all;
}
.manage-store-sections .section .cart-item:hover .action-buttons {
	opacity: 1;
	transform: none;
}
.manage-store-sections .section .cart-item .action-buttons {
	position: absolute;
	left: 47px;
}
.placing-order-loader {
	width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.action-buttons .button {
	width: 60px;
	font-size: 12px;
	text-transform: uppercase;
	background:linear-gradient(-45deg, rgb(60, 70, 101) 0%, rgb(33, 40, 63) 100%);
	color: #fff;
	text-align: center;
	font-weight: normal;
	margin:4px 0;
	padding:5px 0;
	border-radius: 4px;
}
.action-buttons .button.delete {
	background: linear-gradient(-135deg, rgb(245, 106, 106) 0%, rgb(199, 40, 40) 100%);
}
.store-info img {
	margin-right: 20px;
	margin-bottom: 15px;
	border-radius: 50%;
	width: 70px;
	height: 70px;
	object-fit: contain;
	background: #fff;
}

.section .two-row {
	display: flex;
	flex-direction: row;
}
.section .two-row .product-input-wrapper {
	width: 50%;
}

.section .two-row .product-images {
	width: 50%;
	display: flex;
	padding:0 0 0 20px;
	justify-content: space-between;
	flex-wrap: wrap;
	align-content: flex-start;
	align-items: flex-start;
}
.section .two-row .product-images label {
	width: 100%;
}
.section .two-row .product-images .product-image {
	width: calc(33.333% - 5px);
    height: 100px;
    padding: 0px;
    margin-bottom: 10px;
    border: solid 1px #efefef;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}
.section .two-row .product-images .product-image.add-image {
	display: flex;
	justify-content: center;
	text-align: center;
	font-weight: 500;
	align-content: center;
	align-items: center;
	border: 2px dashed #ccc;
}
.section .two-row .product-images .product-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.flex-container h3.manage-store-subtitle {
	font-size: 30px;
}
.flex-table {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-content: flex-start;
	font-size: 14px;
	background:#fff;
	border-radius: 6px;
	box-shadow: 0 6px 16px rgba(0,0,0,0.05);
}
.flex-table .table-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-bottom: solid 1px #efefef;
	transition: all 0.2s;
	cursor: pointer;
}
.flex-table .table-row.placeholder-row {
	padding:20px;
	cursor: initial;
}
.flex-table .table-row.placeholder-row:hover {
	transform: none!important;
}
.flex-table .table-row.placeholder-row h1 {
	font-size: 25px;
	width: 100%;
	padding:40px;
	text-align: center;
	border:dashed 1px #efefef;
	color: #ccc;
	border-radius: 6px;
}
.flex-table .table-row div {
	padding:15px;
}

.flex-table .table-row .status {
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 600;
}
.flex-table .table-row .status:before {
	width: 8px;
	height: 8px;
	margin-right: 5px;
	border-radius: 50%;
	display: inline-block;
	background: #000;
	content: " ";
}

.flex-table .table-row .status.complete {
	color: #63CAA1;
}

.flex-table .table-row .status.complete:before {
	background: #63CAA1;
}

.flex-table .table-row .status.pending {
	color: #DAA96B;
}

.flex-table .table-row .status.pending:before {
	background: #DAA96B;
	}
.flex-table .table-row .status.canceled {
	color: #ccc;
}

.flex-table .table-row .status.canceled:before {
	background: #ccc;
}

.flex-table .table-row.thead {
	font-weight: bold;
	background: #fafafa;
}

.flex-table .table-row:not(.thead):hover {
	box-shadow:0 10px 15px rgba(0,0,0,0.1);
	background: #fff;
	transform: scale(1.02,1.02);
}

.flex-table .draggable.placeholder {
	width: 100%;
	height: 77px;
	background: #fafafa;
	border:dashed 1px #efefef;
	border-radius: 8px;
	margin:10px 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.flex-table .draggable {
	cursor: grab;
}
#categoryTable {
	font-weight: 500;
}
#categoryTable .table-row img {
	width: 15px;
}

#categoryTable .table-row {
	align-items: center;
	cursor: pointer;
	border:solid 1px #fafafa;
	box-shadow:0 10px 15px rgba(0,0,0,0.05);
}

#categoryTable .List .table-row  {
	box-shadow:0 10px 15px rgba(0,0,0,0.1);
}

#addCategoryRow {
	display: none;
}

#addCategoryRow.active {
	display: flex;
}

#categoryTable .table-row.add-category {
	border:none;
	margin-bottom: 40px;
	box-shadow: none!important;
	background: transparent;
}
#categoryTable .table-row.add-category  div:nth-child(3) {
	display: none;
}
#categoryTable .table-row.add-category div:nth-child(2) {
	padding:0;
	width: 100%;
	margin-right: 10px;
}
#categoryTable .table-row.add-category .btn {
	margin: 0;
}
#categoryTable .table-row div:nth-child(1) {
	width: 40px;
}

.manage-store-sections #categoryTable .table-row div:nth-child(1) {
	width: 0;
	padding:15px 0;
}

#categoryTable .table-row div:nth-child(2) {
	width: calc(100% - 200px);
}

#categoryTable .table-row div:nth-child(4) {
	width: 40px;
	margin-right: 15px;
}

#categoryTable .table-row input {
	border: none;
	background: #f3f2f2;
	font-size: 15px;
}

#categoryTable .table-row.add-category input {

}
#categoryTable .table-row.add-category div:nth-child(4) {
	width: 54px;
	font-weight: bold;
	border-radius: 50px;
}
#categoryTable .table-row div:nth-child(3) {
	width: 120px;
	font-size: 12px;
	opacity: 0.4;
}
.flex-table.row-3 .table-row div {
	width: 33.333%;

}

.flex-table.row-3 .table-row div:last-child {
	text-align: right;
}
.flex-table.row-5 .table-row div:nth-child(1) {
	width: 20%
}
.flex-table.row-5 .table-row div:nth-child(2) {
	width: 15%
}
.flex-table.row-5 .table-row div:nth-child(4) {
	width:28%
}

.flex-table.row-5 .table-row div.flag {
	width: 40px;
	position: relative;
}

.flex-table.row-5 .table-row div.flag .tooltip {
	position: absolute;
	width: 200px;
	padding:10px;
	font-size: 14px;
	font-weight: normal;
	color: #fff;
	background: rgba(0,0,0,0.9);
	border-radius: 10px;
	bottom: 32px;
	left: calc(50% - 100px);
	visibility: hidden;
	opacity: 0;
	transform:translateY(-5px);
	transition: 0.2s;
	z-index: 20000;
}
.flex-table.row-5 .table-row div.flag:hover .tooltip {
	opacity: 1;
	visibility: visible;
	transform: none;
}
.flex-table.row-5 .table-row div.flag img {
	width: 12px;
	height: 12px;
}
.flex-table.row-5 .table-row div:nth-child(5) {
	font-size: 15px;
	width: 15%;
	font-weight: bold;
}
.flex-table.row-5 .table-row div:nth-child(6) {
	width: 15%
}


.payment-option-accordion {
	width: 100%;
	background: #fff;
	border-radius: 6px;
	box-shadow: 0 8px 16px rgba(0,0,0,0.05);
	margin-bottom: 10px;
	transition: all 0.2s;
	border:solid 1px #efefef;
}
.flex-container .payment-option-accordion h3 {
	font-size: 23px;
	margin:0;
}
.payment-option-accordion:hover {
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}
.connect-button {
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	color: #fff;
	border-radius: 10px;
	padding:10px 20px;
	text-transform: uppercase;
	font-weight: 400;
	font-size: 14px;
	text-align: center;
	transition:all 0.3s;
	display: inline-block;
	cursor: pointer;
	width: auto!important;
}

.connect-button:hover {
	background: linear-gradient(-135deg, #28C76F 0%, #28C76F 100%);
}
.p-o-content .connect-button {
	width: 114px;
}
.p-o-content {
	padding:0 30px 0px 30px;
	height: 0;
	min-height: 0;
	transition: all 0.4s;
	overflow:hidden;
}
.active .p-o-content {
	padding:0 30px 30px 30px;
	height: auto;
	min-height: 90px;
}
.p-o-content a {
	color: #9ea9c2;
}
.p-o-title {
	margin:0;
	padding:0;
	position: relative;
}
.p-o-title {
	padding:20px 20px 20px 30px;
	height: 80px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}
.p-o-title .left {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.p-o-title .option-switch {
	width: 50px;
	height: 25px;
	border-radius: 50px;
	background: #efefef;
	margin-right: 10px;
	position: relative;
	border:solid 1px #efefef;
	transition: all 0.2s;
}
.p-o-title .option-switch:before {
	width: calc(25px - 2px);
	height: calc(25px - 2px);
	border-radius: 50%;
	background: #fff;
	position: absolute;
	left: 0;
	top: 0;
	content: " ";
	transition: all 0.2s;
}
.active .p-o-title .option-switch {
 	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
 }
.active .p-o-title .option-switch:before {
	left: calc(100% - 23px);
}

.p-o-title span {
	font-size: 13px;
	text-transform: uppercase;
	color: #ccc;
}
.p-o-title span:before {
	content: " ";
	width: 8px;
	height: 8px;
	display: inline-block;
	margin-right: 5px;
	border-radius: 50%;
	background: #ccc;
}

.p-o-title span.enabled:before {
	background: #489742;
}
.p-o-title span.enabled {
	color: #489742;
}
.p-o-title img {
	height: 25px;
}

.shipping-table {
	flex-wrap: wrap;
	    display: flex;
    flex-direction: column;
    width: 100%;
}

.shipping-table .row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 10px;
	position: relative;
	flex-wrap: wrap;
	 padding: 20px;
    box-shadow: 0 8px 16px rgba(0,0,0,0.05);
    background: #fff;
    border-radius: 6px;
    margin-bottom: 15px;
}
.shipping-table .row span {
	position: absolute!important;
	top: 15px!important;
	right: 20px!important;
}

.shipping-table .row .add-shipping-button {
	border:transparent;
	box-shadow: none;
	padding:0;
	opacity: 0.5;
	margin-left: 10px;
}
.shipping-table .row .add-shipping-button:hover {
	opacity: 1;
}
.shipping-table .row .col {
	width: calc(33.333% - 20px);
	position: relative;
	margin-bottom: 15px;
}

.shipping-table .row .col strong {
	margin-bottom: 5px;
	display: block;
}

.shipping-table .row .col input,
.shipping-table .row .col select {
	width: 100%;
	height: 50px;
	outline: none;
	border-radius: 4px;
	border:1px #d9d9d9 solid;
	transition: all 0.2s;
	padding:0 20px;
}

.shipping-table .row .col input:focus,
.shipping-table .row .col select:focus {
	border-color: #ccc;

}

.shipping-table .row .col.countries {
	width: 100%;
}
.shipping-table .row .col.currency-input {
	position: relative;
	width: calc(50% - 10px);
}
.currency-input input,
.shipping-table .row .col.currency-input input {
	padding-left: 52px;
}

.currency-input i {
	font-style: normal;
	font-size: 11px;
	text-transform: uppercase;
	padding:3px 6px;
	border-radius: 4px;
	border:solid 1px #ccc;
	position: absolute;
	margin-left: 10px;
	bottom: 14px;
}

.delete-shipping-btn {
	width: 40px;
	height: 40px;
	position: absolute;
	right: 5px;
	top: 5px;
	z-index: 2;
	cursor: pointer;
	border-radius: 6px;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	border:solid 1px #efefef;
	transition: all 0.2s;
	opacity: 0;
	transform: translateX(-3px);
}

.col .delete-shipping-btn {
	bottom: 5px;
	top: auto;
	right: 5px;
}

.row:hover .delete-shipping-btn {
	opacity: 1;
	background: #fafafa;
	transform: none;
}
.delete-shipping-btn img {
	width: 12px;
	height: 12px;
}
.add-shipping-button {
	padding:10px 20px;
	border:solid 1px #efefef;
	border-radius: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	display: inline-block;
	font-size: 14px;
	box-shadow: 0 8px 16px rgba(0,0,0,0.05);
	transition: all 0.2s;
	background: #fff;
	cursor: pointer;
	margin-right: 10px;
}
.add-shipping-button:hover {
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}
.add-shipping-button:before {
	content: "+";
	font-size: 18px;
	width: 25px;
	height: 25px;
	text-align: center;
	border:solid 1px #000;
	border-radius: 50%;
	margin-right: 10px;
	display: inline-block;
	margin-left: -10px;
}
.manage-store-sections hr {
	opacity: 0.2;
	margin:40px 0;
}
#manageCoupons .flex-table .table-row.thead div {
	padding:10px;
}
#manageCoupons .flex-table .table-row div {
	padding:30px 10px;
}


.add-coupon-button {
	padding:15px 20px;
	box-shadow: 0 5px 15px rgba(0,0,0,0.1);
	border-radius: 40px;
	color: #fff;
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	text-transform: uppercase;
	cursor: pointer;
	font-size: 14px;
	font-weight: 600;
	display: inline-block;
	margin-bottom: 20px;
}

.manage-store-title .add-coupon-button {
	position: absolute;
	right: 0;
	top: 0;
}

.add-coupon-button:hover {
	background: #28C76F;
}

.add-coupon-button:before {
	width: 15px;
	height: 15px;
	content: "+";
	line-height: 15px;
	border: solid 1px #fff;
	border-radius: 50%;
	display: inline-block;
	text-align: center;
	margin-right: 10px;
}

.coupon-tabs {
	width: 100%;
	display: flex;
	font-size: 14px;
	text-transform: uppercase;
	justify-content: space-between;
}

.coupon-tabs {
	padding:15px 0 0 0;
	color: #ccc;
	font-size: 12px;
	font-weight: bold;
	margin:10px 0 0 0;
}
.coupon-tabs div {
	border-bottom: solid 1px #efefef;
	padding:20px 0;
	width: 33.333%;
	text-align: center;
	cursor: pointer;
}
.coupon-tabs .active,
.coupon-tabs div:hover {
	color: #000;
	border-bottom: solid 1px #000;
}
.coupon-tab-content {
	width: 100%;
	display: none;
}

.coupon-tab-content.active {
	display: block;
}

.option {
  font-size: 14px;
  width: 100%;
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}
.option:hover:before, 
.option.active:before{
  background: #7FCA76;
}

.option:before {
    content: " ";
    display: inline-block;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    border:solid 2px #efefef;
    margin-right: 7px;
    font-size: 13px
}
 .option.square:before {
  border-radius: 5px;
}

.coupon-note {
	width: 100%;
	padding:20px;
	font-size: 14px;
	border-radius: 6px;
	background: #ede9c6;
	border:solid 1px #dbd6aa;
	margin:10px 0;
}
.manage-store-sections .cart-item {
	padding:0px 20px 0 0;
	overflow: hidden;
	background: #fff;
	box-shadow: 0 8px 16px rgba(115,135,150,0.1);
	border-bottom: none;
	border-radius: 6px;
	margin-bottom: 10px;
	height: 100%;
	border:solid 1px #efefef;
}

.manage-store-sections .cart-item .product-inventory {
	position: absolute;
	right: 0px;
	display: flex;
	flex-wrap: wrap;
	height: 100%;
	width: 200px;
	border-left:solid 1px #efefef;
}

.manage-store-sections .cart-item .product-inventory div {
	width: 50%;
	 font-size: 12px;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    justify-content: center;
    background: #fafafa;
    transition: 0.2s;
    height: 0%;
    opacity: 0;
}
.manage-store-sections .cart-item .product-inventory div:first-child {
	width: 100%;
	border-bottom:solid 1px #efefef;
	opacity: 1;
	height: 100%;
}

.manage-store-sections .cart-item:hover .product-inventory div:first-child {
	height: 50%;
}
.manage-store-sections .cart-item:hover .product-inventory div {
	height: 50%;
	opacity: 1;
}

.manage-store-sections.cart-item .product-inventory div:last-child {
	border-left:solid 1px #efefef;
}

.cart-item .product-inventory {
	font-weight: normal;
	font-size: 14px;
}

.cart-item .product-inventory strong {
	font-size: 18px;
}

.manage-store-sections .cart-item:hover {
	box-shadow: 0 8px 16px rgba(115,135,150,0.2);
}
.manage-store-sections .cart-item h4 {
	font-size: 21px;
	line-height: 24px;
	margin:0;
}

.manage-store-sections .cart-item h4 p {
	font-weight: normal;
        font-size: 12px;
        margin: 10px 0;
        padding: 3px 6px;
        border-radius: 4px;
        border: solid 1px #efefef;
        margin:5px 4px 0 0px;
}
.manage-store-sections .cart-item h4 i {
	font-size: 18px;
}
.order-summary-content {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.order-summary-block.col-50 {
	width: calc(50% - 10px);
}
.order-summary-block {
	width: 100%;
	padding:15px;
	border: solid 1px #efefef;
	margin-bottom: 20px;
	border-radius: 6px;
	font-size: 15px;
	font-weight: 500;
	position: relative;
}
.order-summary-block label {
	font-size: 13px;
	font-weight: bold;
	color: #333;
	margin-right: 10px;
	text-transform: uppercase;
	display: inline-block;
	width: 40px;
}
.order-summary-block h4 {
	margin:0;
	padding:0;
	width: 100%;
	font-weight: 400;
	font-size: 14px!important;
	text-transform: uppercase;
	opacity: 0.4;
	margin-bottom: 4px;
}
.order-item {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	overflow:hidden;
	border-radius: 6px;
	box-shadow: 0 4px 16px rgba(0,0,0,0.05);
	margin-bottom: 5px;
}
.order-item img {
	width: 80px;
	height: 80px;
	object-fit: cover;
	margin-right: 20px;
}

.order-item sub {
	padding:5px;
	border:solid 1px #efefef;
	font-size: 11px;
	text-transform: uppercase;
	transform: none;
	margin:3px 0;
	border-radius: 6px;
	display: inline-block;
}

.order-item sub.order-ref {
	opacity: 0;
	transition: 0.2s;
}
.order-item:hover sub.order-ref {
	opacity: 1;
}
.order-item sub i {
	font-size: inherit!important;
}
.order-item h5 {
	font-size: 15px;
	margin:0;
}
.order-item h5 i {
	font-weight: normal;
	font-size: 13px;
	font-style: normal;
	opacity: 0.5;
}

.order-item h5 i.sale-price {
	color: red;
	margin-left: 10px;
	text-decoration: line-through;
}

.order-summary-footer {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.order-button {
	color: #fff;
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	padding:15px 30px;
	border-radius: 40px;
	font-size: 14px;
	text-transform: uppercase;
	cursor: pointer;
}
.order-button:hover {
	opacity: 0.7;
}

.order-button.empty {
	background: transparent;
	color: red;
}

.order-status-dropdown {
	position: absolute;
	width: 100%;
	left: 0;
	top: 40px;
	z-index: 2;
	background: #fff;
	border-radius: 6px;
	overflow: hidden;
}
.order-status-dropdown:after {
	position: absolute;
	right: 15px;
	top: 16px;
	width: 4px;
	height: 4px;
	border-left: solid 2px #ccc;
	border-bottom: solid 2px #ccc;
	transform: rotate(-45deg);
	content: " ";
}

.order-status-dropdown div {
	display: none;
	font-size: 13px;
	width: 100%;
	padding:10px 15px;
	text-transform: uppercase;
	font-weight: bold;
	cursor: pointer;
	background: #fff;
}
.order-status-dropdown:hover div {
	display: block;
}

.order-status-dropdown div:hover {
	background-color: #efefef;
}

.order-status-dropdown:hover div.active {
	background: #fafafa;
}
.order-status-dropdown:hover {
	z-index: 10;
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
}
.order-status-dropdown div:hover {
	box-shadow: 0 4px 8px rgba(0,0,0,0.05);
}
.order-status-dropdown div:before {
	width: 8px;
	height: 8px;
	margin-right: 5px;
	border-radius: 50%;
	background: #ccc;
	content: " ";
	display: inline-block;
}
.order-status-dropdown div.active {
	display: block;
}

.order-status-dropdown div.pending:before {
	background: #DAA96B;
}

.order-status-dropdown div.complete:before {
	background: #63CAA1;
}

.order-status-dropdown div.complete {
	color: #63CAA1;
}
.order-status-dropdown div.pending {
	color: #DAA96B;
}
.order-status-dropdown div.cancel {
	color: #ccc;
}

.flex-table.payouts-table {
	font-size: 16px; 
	font-weight: normal;
	color: #8c8b8b;
	margin-bottom: 80px;
}
.flex-table.payouts-table strong {
	color: #333;
}
.flex-container h4.table-title {
	font-size: 18px;
	text-transform: uppercase;
}
.currency-selector {
	width:100%;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 40px;
}
.currency-selector div {
	text-align: center;
	padding:6px 18px;
	border-radius: 30px;
	border:solid 1px #efefef;
	font-weight: normal;
	background: #fff;
	cursor: pointer;
	margin-right: 7px;
	margin-bottom: 7px;
	color: #333;
}
.currency-selector div:not(.active):hover {
	border-color: #ccc;
}
.currency-selector div.active {
	background: linear-gradient(-45deg, #3c4665 0%, #21283F 100%);
	color: #fff;
}
.payouts-dashboard {
	font-weight: normal;
	display: flex;	
	justify-content: space-between;
	padding:30px;
	font-size: 14px;
	background: #fff;
    border: solid 1px #efefef;
    border-radius: 6px;
    margin: 0px 0 30px 0;
    align-items: center;
    box-shadow: 0 8px 16px rgba(0,0,0,0.05);
}
.payouts-dashboard .btn {
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	padding:15px 30px;
	font-size: 15px;
    border-radius: 50px;
    margin-bottom: 5px;
}
.payouts-dashboard .payouts-cta {
	text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.payouts-dashboard i {
	font-style: normal;
}
.payouts-dashboard strong {
	font-weight: normal;
	color: #8c8b8b;
}
.payouts-dashboard a {
	color: #28C76F;
	cursor: pointer;
}
.payouts-dashboard a:hover {
	text-decoration: underline;
}
.payouts-dashboard h3 {
	font-size: 24px!important;
	margin:0px 0!important;
}
.manage-store-navbar .burger {
	display: none;
	filter: invert(1);
}

.edit-product-form {
	max-width: 900px;
	margin:0 auto;
}

.left-cards {
	float: left;
	width: calc(100% - 320px);
}

.right-cards {
	float: right;
	width: 300px
}

.live-layout-dropdown {
	border:solid 1px #efefef;
	border-radius: 6px;
	position: relative;
	width: 100%;
}
.live-layout-dropdown-item {
	padding:10px 10px 10px 10px;
	background: #fff;
	transition: 0.2s;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	border-radius: 6px;
}
.live-layout-dropdown-item:hover {
	background: #fafafa;
}
.live-layout-dropdown-item img {
	height: 90px;
	box-shadow: 0 4px 8px rgba(0,0,0,0.05);
	border-radius:6px;
	border:solid 1px #efefef;
}

.live-layout-dropdown-item:after {
	width: 8px;
	height: 8px;
	margin-right: 15px;
	border-bottom:solid 2px #333;
	border-right:solid 2px #333;
	content: " ";
	transform: rotate(45deg);
}

.live-layout-numbers {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.live-layout-numbers span {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 0;
	font-size: 14px;
	border:solid 1px #efefef;
}

.email-edit-header,
.edit-product-card {
	width: 100%;
    padding: 20px;
    /*box-shadow: 0 8px 16px rgba(0,0,0,0.05);*/
    box-shadow: 0 8px 16px rgba(115,135,150,0.15);
    min-height: 100px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
    border-radius: 6px;
    background: #fff;
    position: relative;
}
.email-edit-header {
	min-height: initial;
}

.email-edit-header .btn {
	padding:13px 30px!important;
	text-transform: uppercase;
	border-radius: 10px;
}
.email-edit-header .btn .fa {
	transform: translateY(1px);
}
.edit-product-card .autofill-dropdown-item {
	padding:0;
	margin-top: 0px;
	border:none;
	justify-content: space-between;
}

.edit-product-card .autofill-dropdown-item .btn {
	padding:5px 15px;
	margin-left: 5px;
}

.edit-product-card legend {
	color: #8895a0;
	font-size: 14px;
	margin-top: 5px;
}

.edit-product-card .input-group label {
	font-weight: normal;
	font-size: 16px;
	margin-bottom: 5px;
	text-transform: none;
}
.edit-product-card-separator {
	width: calc(100% + 40px);
	margin-left: -20px;
	height: 1px;
	background: #efefef;
	margin:30px 0;
}
.edit-product-card .input-group {
	margin-top: 10px;
	justify-content: flex-start;
}

.edit-product-card h4 {
	margin:0 0 15px 0;
	font-size: 18px;
	width: 100%;
	color: #333;
}

.edit-product-card h4 legend {
	font-weight: normal;
	margin-top: 0;
}

.edit-product-card .product-switch-wrapper {
	    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.edit-product-card .product-switch-wrapper .publish-switch {
	width: 100%;
	height: 50px;
    border-radius: 6px;
    background: transparent;
    border: solid 1px #efefef;
    display: flex;
    align-items: center;
    padding-left: 15px;
}

.edit-product-card .product-switch-wrapper .publish-switch:hover {
	background: #fafafa;
}

.edit-product-card .product-switch-wrapper .publish-switch:after {
	width: 45px;
    height: 20px;
    content: " ";
    background: #ccc;
    position: absolute;
    right: 10px;
    top: calc(25px - 10px);
    border-radius: 40px;
}

.edit-product-card .product-switch-wrapper .publish-switch.active:after {
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
}

.edit-product-card .product-switch-wrapper .publish-switch:before  {
	left: auto;
	right: 37px;
	top: calc(25px - 8px);
	z-index: 2;
	width: 16px;
    height: 16px;
}
.edit-product-card .product-switch-wrapper .publish-switch.active:before {
	right: 12px;
}
.input-group.big .product-switch-wrapper {
	right: 10px;
	top: 20px;
	position: absolute;
}

.input-group.big input {
	/*background: #fff;
	border:none;
	border-bottom: solid 1px rgba(0,0,0,0.2);
	border-radius: 0;
	padding:0;*/
	font-size: 25px;
	height: 60px;
	/*line-height: 70px;
	font-weight: bold;*/
	padding-right: 50px;
}
.input-group.big label {
	margin-bottom: 0;
}
.input-group.big input:focus {
	/*border-bottom-color: #333;
	box-shadow: 6px 6px 12px rgba(0,0,0,0.1);*/
}

.product-variant-upload {
	display: flex;
	width: 100%;
	margin-bottom: 10px;
	padding:20px;
	border:dashed 1px #ccc;
	font-weight: 600;
	background: #f3f2f2;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	box-shadow: inset 1px 1px 1px rgba(0,0,0,0.1), inset -1px -1px 1px rgba(255,255,255,0.1);
	border-radius: 6px;
	transition: 0.2s;
	cursor: pointer;
	position: relative;
}

.product-variant-upload .btn {
	position: absolute;
	top: 16px;
	right: 20px;
	padding:6px 12px;
	border-radius: 10px;
}
.product-variant-upload .btn.remove {
	background: #D93939;
	color: #fff;
	font-size: 13px;
}
.product-variant-upload:hover {
	background: #fff;
	box-shadow: 0 5px 10px rgba(0,0,0,0.1);
}

.product-variant-upload.active {
	border:solid 1px #efefef;
	background: #fff;
	box-shadow: 0 5px 10px rgba(0,0,0,0.1);
	justify-content: flex-start;
}
.product-variant-upload img {
	height: 16px;
	object-fit: contain;
	margin-right: 10px;
}

.edit-product-variant .input-group {
	margin-top: 10px;
}

.edit-product-variant .input-group:first-of-type {
	margin-top: 0;
}

.edit-product-variant .input-group label {
	margin-bottom: 5px;
}
.edit-product-variant.description {
	padding:30px 0 0 0;
	overflow: hidden;
}
.edit-product-options .separator {
	margin:40px 0;
	width: 100%;
	height: 1px;
	background: #efefef;
	border:none;
}
.edit-product-variant .input-group textarea {
	min-height: 171px;
}

.input-group.third {
	width: calc(33.333% - 10px);
}

.input-group.half {
	width: calc(50% - 10px);
}

.edit-product-columns {
	display: flex;
	flex-wrap: nowrap;
	width: 100%;
	margin-top: 60px;
}


.edit-product-variant-footer {
	display: flex;
	text-transform: uppercase;
	font-size: 14px;
	border-top: solid 1px #efefef;
	padding-top: 20px;
	justify-content: flex-end;
	margin-bottom: 0px;
	margin-top: 20px;
	width: 100%;
}

.edit-product-variant-footer div {
	padding:10px 20px;
	cursor: pointer;
	border-radius: 40px;
	margin-left: 0px;
	color: rgba(0,0,0,0.5);
}

.edit-product-variant-footer div:hover {
	color: #333;
}

.edit-product-variant-footer div:last-child {
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	color: #fff;
}

.edit-product-variant-footer div:last-child:hover {
	background: #28C76F;
	color: #fff;
}

.edit-product-images-scroll {
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	width: calc(100% + 0px);
    flex-shrink: 0;
}
.edit-product-images-drag {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
    justify-content: center;
    align-items: center;
}

.edit-product-images-drag .edit-product-images-item {
	width: 100%;
}

.edit-product-images-drag [data-rbd-drag-handle-context-id="0"] {
    margin-bottom: 10px;
    flex-shrink: 0;
    width: 100%;
    border:solid 1px #efefef;
    border-radius: 6px;
    overflow: hidden;
}
.edit-product-images-item:after {
	font-size: 15px;
}
.edit-product-images-drag [data-rbd-drag-handle-context-id="0"]:nth-child(1) .edit-product-images-item:after  {
	content: "Main Image";
}
.edit-product-images-drag [data-rbd-drag-handle-context-id="0"]:nth-child(2) .edit-product-images-item:after  {
	content: "Image order: 2";
}
.edit-product-images-drag [data-rbd-drag-handle-context-id="0"]:nth-child(3) .edit-product-images-item:after  {
	content: "Image order: 3";
}
.edit-product-images-drag [data-rbd-drag-handle-context-id="0"]:nth-child(4) .edit-product-images-item:after  {
	content: "Image order: 4";
}
.edit-product-images-drag [data-rbd-drag-handle-context-id="0"]:nth-child(5) .edit-product-images-item:after  {
	content: "Image order: 5";
}
.edit-product-images {
	width: 100%;
	margin-right: 0px;
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: space-between;
}
.edit-product-images {
	width: 100%;
}
.edit-product-images .action-buttons {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	display: flex;
	justify-content: center;
	flex-direction: column;
	z-index: 2;
	align-items: center;
}

.edit-product-images-item:hover .action-buttons {
	opacity: 1;
}

.edit-product-images .add-shipping-button {
	width: 100%;
	text-align: center;
	justify-content: center;
}
.edit-product-images-item {
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-shrink: 0;
	margin-bottom: 10px;
}
.edit-product-images-item:hover .hover-box {
	opacity: 1;
	visibility: visible;
}
.edit-product-images-item img {
	width: 80px;
	height: 80px;
	object-fit: cover;
	border:solid 1px #efefef;
	display: block;
	border-radius: 6px;
}

.edit-product-options {
	width: 100%;
}
.edit-product-options h4,
.edit-product-images h4 {
	font-size: 18px;
	margin:0 0 20px 0;
}
.edit-product-variant {
	width: 100%;
	min-height: 100px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 40px;
}

.product-variant-complete .edit-block-btn {
	padding: 6px 20px;
	margin-right: 4px;
	padding-left: 32px;
}
.product-variant-complete {
	box-shadow: 0 8px 16px rgba(0,0,0,0.1), 0 0 0 5px rgba(0,0,0,0.1);
	background: #fff;
	border: solid 1px #efefef;
	border-radius: 6px;
	margin-bottom: 20px;
	min-height: 100px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	transition: 0.2s;
}
.product-variant-complete:hover {
	box-shadow: 0 8px 16px rgba(0,0,0,0.1), 0 0 0 10px rgba(0,0,0,0.1);
}
.product-variant-complete .left,
.product-variant-complete .right {
	padding:20px 30px;
}

.product-variant-complete .hover-box {
	opacity: 1;
	visibility: visible;
}

.product-variant-complete .action-buttons {
	display: flex;
	opacity: 1;
}

.product-variant-complete .left {
	display: flex;
	align-items: center;
}

.product-variant-complete .left strong {
	text-transform: uppercase;
	padding:5px 10px;
	border:solid 1px #efefef;
	border-radius: 6px;
	font-size: 16px;
	margin-right: 10px;
}

.product-variant-complete h2 {
	font-size: 16px;
	font-weight: normal;
	margin: 0;
}
.form-sub-title {
	font-size: 12px;
	text-transform: uppercase;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	opacity: 0.5;
	width: 100%;
	font-weight: bold;
	margin:30px 0 20px 0;
}
.form-sub-title:after {
	height: 1px;
	width: 100%;
	margin-left: 10px;
	border-top: dashed 1px #ccc;
	content: " ";
}
.icon-btn {
	display: flex;
	align-items: center;
	color: #fff;
	padding:9px 18px;
	font-size: 16px;
	cursor: pointer;
	border-radius: 6px;
	margin-right: 8px;
}
.icon-btn .fa {
	font-size: 13px;
	transform:translateY(1px);
	margin-right: 6px;
}
.icon-btn:hover {
	background: rgba(255,255,255,0.1);
}
.edit-product-form-footer {
	display: flex;
    justify-content: center;
    margin-top: 0px;
    padding: 0 10px;
    margin-bottom: 50px;
    /* border-top: solid 1px #efefef; */
    width: calc(100% - 80px);
    position: fixed;
    top: 0px;
    right: 0px;
    background: rgba(38,46,71,1);
    height: 65px;
    z-index: 200;
    border-radius: 0px;
    margin: 0px;
    animation:fadeInTop 0.4s forwards;
    align-items: center;
    box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}

.edit-product-action-wrapper {
	display: flex;
	justify-content: space-between;
	max-width: 900px;
	margin:0 auto;
	width: 100%;
}

.edit-product-form-footer .left,
.edit-product-form-footer .right {
	display: flex;
	align-items: center;
}
@media (min-width: 1050px) {

}
@media (min-width: 900px) {
	.edit-product-form-footer .left,
	.edit-product-form-footer .right {
		width: 450px;
	}

	.edit-product-form-footer .right {
		justify-content: flex-end;
	}
}


.edit-product-form-footer .btn {
	font-size: 16px;
	border-radius: 10px;
	padding:10px 15px!important;
}

.edit-product-form-footer .btn.empty {
	border:none;
	color: #fff;
	background: transparent;
}

.product-type-select,
.multiple-products-cards {
	width: 100%;
	max-width: 600px;
	padding:20px;
	display: flex;
	flex-wrap: wrap;
	margin:40px auto;
}
.product-type-select  {
	max-width: 1000px;
}
.product-type-select h1,
.multiple-products-cards h1 {
	font-size: 28px;
	text-align: center;
	margin:20px 0;
	width: 100%;
}

.multiple-products-cards-item {
	width: 100%;
	border-radius: 8px;
	border:solid 1px #efefef;
	transition: 0.2s;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding:5px 10px;
	margin:10px 0;
	background: #fff;
	display: flex;
	flex-wrap: wrap;
	position: relative;
	padding-left: 132px;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.12);
}

.multiple-products-cards-item .delete-item {
	opacity: 0.2;
	position: absolute;
	width: 18px;
	height: 18px;
	object-fit: contain;
	top: calc(50% - 9px);
	right: -30px;
	cursor: pointer;
}

.multiple-products-cards-item .delete-item:hover {
	opacity: 0.8;
}

.multiple-products-cards-item-img {
	width: 112px;
	height: 112px;
	position: absolute;
	left: 10px;
	top: 10px;
	border-radius: 6px;
	border:solid 1px #efefef;
	cursor: pointer;
	transition: 0.2s;
}

.multiple-products-cards-item-img:hover {
	box-shadow: 0 0 0 3px #B2A7A7;
}

.multiple-products-cards-item-img strong,
.multiple-products-cards-item-img span {
	font-weight: normal;
	font-size: 11px;
	position: absolute;
}

.multiple-products-cards-item-img span {
	font-size: 11px;
	font-weight: 600;
	top: 3px;
	right: 3px;
	color: #fff;
	background: #333;
	border-radius: 4px;
	padding:2px 5px;
}

.multiple-products-cards-item-img strong {
	bottom: 0px;
	color: #B2A7A7;
	width: 100%;
	padding:20px 10px;
	text-align: center;
	left: 0;

}

.multiple-products-cards-item-img.empty {
	border:dashed 1px #B2A7A7;
}

.multiple-products-cards-item-img img:not(.icon) {
	width: 100%;
	height: 100%;
	object-fit: contain;
	border-radius: 6px;
}

.multiple-products-cards-item .input-group {
	margin:5px 0px;
}

.product-type-select-item {
	width: calc(33.333% - 20px);
	border-radius: 8px;
	border:solid 1px #efefef;
	transition: 0.2s;
	cursor: pointer;
	text-align: center;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	padding:50px;
	margin:0 10px;
	background: #fff;
}

.product-type-select-item img {
	width: 70px;
	height: 70px;
	object-fit: contain;
	margin-bottom: 20px;
	opacity: 0.5;
	transition: 0.2s;
}
.product-type-select-item h4 {
	font-size: 20px;
}
.product-type-select-item p {
	font-size: 15px;
}
.product-type-select-item h4,
.product-type-select-item p {
	margin:0;
}
.product-type-select-item:hover {
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
	border-color: #333;
}
.product-type-select-item:hover img {
	opacity: 1;
	transform: scale(1.05);
}
@media (max-width: 779px) { 
	.email-edit-header .btn {
		padding:12px 22px!important;
	}
	.edit-product-form-footer {
	    width: 100%;
	    left: 0;
	    margin: 0;
	    bottom: 0;
	    border-radius: 6px 6px 0 0;
	}
	.edit-product-form-footer .btn {
		padding:8px 16px!important;
	}
	.edit-product-form-footer .btn:not(.green) {
		background: #fff;
		color: #333;
		margin-right: 5px;
		box-shadow: 0 4px 8px rgba(0,0,0,0.1);
	}
	.product-type-select {
		margin-top: 0;
	}
	.product-type-select-item {
		width: 100%;
		margin:0 0 20px 0;
	}
	.edit-product-columns {
		flex-wrap: wrap;
		margin-top: 20px;
	}
	.edit-product-form .input-group.big {
		color: #fff;
	}
	.edit-product-form .input-group.big input {
		font-size: 22px;
		padding-right: 65px;
		/*background: transparent;
		color: #fff;
		border-bottom: solid 1px rgba(255,255,255,0.4);*/
	}

	.edit-product-form .input-group.big .product-switch-wrapper:after {
		height: 70px;
	    width: 1px;
	    background: #ccc;
	    content: " ";
	    position: absolute;
	    left: -7px;
	    top: -20px;
	}
	.edit-product-form .input-group.big input::placeholder {
		/*color: rgba(255,255,255,0.6);*/
	}
	.edit-product-form .input-group.big input:focus {
		/*box-shadow: 0;
		border-bottom-color: #fff;*/
	}


	.edit-product-images-scroll {
		flex-wrap: nowrap;
		overflow: auto;
	}
	.edit-product-images {
		width: 100%;
		margin-right: 0;
		margin-bottom: 30px;
		justify-content: center;
	}
	.edit-product-images .add-shipping-button {
		margin-top: 20px;
	}
	.payouts-dashboard {
		flex-wrap: wrap;
	}
	#emailSettings .modal-content {
		max-width: 300px!important;
	}
	.payouts-user {
		width: 100%;
	    margin-bottom: 15px;
	    text-align: center;
	    border-bottom: solid 1px #efefef;
	    padding-bottom: 15px;
	}
	.payouts-dashboard .payouts-cta {
		width: 100%;
		margin-top: 15px;
	    text-align: center;
	    border-top: solid 1px #efefef;
	    padding-top: 15px;
	}
	.payouts-dashboard .payouts-value {
		width: 100%;
		text-align: center;
	}
	.product-image-wrapper {
		height: 380px!important;
		width: 100vw;
		min-height: initial;
	}
	.product-details-wrapper {
		width: 100vw;
		padding:30px;
	}
	.price-wrapper span {
		font-size: 24px!important;
	}
	#productContent .product-details-title h1 {
		font-size: 35px!important;
	}
	#productContent .product-details-title h2 {
		font-size: 30px;
	}
	#productContent .product-details-title h3 {
		font-size: 30px;
	}
	#productContent .product-details-title h4 {
		font-size: 30px;
	}
	.cart-button {
		padding:15px 20px;
	}
	.cart-button .cart-icon {
		margin-left: 0;
	}
	.price-wrapper {
		padding-top: 0;
	}
	#productContent.product-3 .price-wrapper {
		flex-direction: column;
		margin-bottom: 30px;
		align-items: flex-start;
	}
	#productContent.product-3 .product-description {
		margin-top: 10px;
	}
	#productContent.product-3 .price-wrapper .cart-button {
		margin-top: 15px;
	}
	.manage-store-navbar {
		position: fixed;
		overflow: visible;
		width: 102%;
		height: 60px;
		top: 0;
		left: 0;
		overflow: auto;
		margin:0;
		border-radius: 0;
		padding:10px;
		z-index: 10;
		overflow: visible;
	}

	.add-product-button {
		position: relative;
		display: inline-block;
		margin-top: 20px;
		width: 100%;
		text-align: center;
	}
	.master_container .manage-store-title {
		margin-bottom: 20px;
	}
	.manage-store-navbar ul {
		width: 100vw;
		height: 70px;
		bottom: 0;
		position: fixed;
		left: 0;
		background: linear-gradient(-45deg, #3c4665 0%, #21283F 100%);
		display: flex;
	    flex-direction: row;
	    justify-content: center;
	    padding:0;
	    transition: all 0.4s;
	    font-size: 12px;
	    font-weight: normal;
	    left: 0;
	    padding-bottom: 10px;
	    z-index: 20;
	}
	.manage-store-navbar ul li {
		width: 20%;
	}
	.manage-store-navbar ul li.bar-dropdown {
		display: flex;
		flex-direction: column;
		height: 60px;
		justify-content: center;
		align-items: center;
		align-content: center;
		font-size: 12px;
		opacity: 0.4;
	}
	.manage-store-navbar ul li.bar-dropdown:hover {
		opacity: 1;
		color: #fff;
	}
	.manage-store-navbar ul li.bar-dropdown .bar-dropdown-content {
		position: fixed;
	    bottom: 70px;
	    top: auto;
	    box-shadow: 0 0 16px rgba(0,0,0,0.2);
	    width: 100%;
	    color: #333!important;
	}
	.manage-store-navbar .bar-dropdown-content .bar-dropdown-content-item h3 span {
		font-size: 12px!important;
	}
	.manage-store-navbar .bar-dropdown-content .bar-dropdown-content-item img {
		filter: none!important;
	}
	.manage-store-navbar ul li:nth-child(3),
	.manage-store-navbar ul li:nth-child(4) {
		display: none;
	}
	.manage-store-navbar ul li a {
		display: flex;
		flex-direction: column;
		height: 60px;
		justify-content: center;
		align-items: center;
		align-content: center;
		width: 100%;
	}
	.manage-store-navbar .hide-desktop {
		display: flex!important;
	}
	.manage-store-navbar ul li a:hover img,
	.manage-store-navbar ul li a.active img {
		transform: none!important;
	}
	.manage-store-navbar ul li img {
		width: 14px;
		height: 14px;
		margin:0 0 4px 0;
	}
	.manage-store-navbar.active ul {
		left: 0;
	}
	.flex-container .store-info h4 {
		font-size: 16px;
	}
	.nav-2 #cartBtn {
		margin-top: 0;
		margin-left: 20px;
	}
	.store-info {
		flex-direction: row;
		align-items: center;
		padding:0;
	}
	.stripe-secure-payment img {
		max-width: initial;
		width: 100%;
		object-fit: contain;
	}
	.store-info img {
		width: 40px;
		height: 40px;
		object-fit: cover;
		margin-bottom: 0;
		margin-right: 10px;
	}
	.store-info div p {
		display: none;
	}
	.manage-store-navbar .clickai-logo {
		display: none;
	}

	.manage-store-sections {
		width: 100%;
		padding:100px 0px 20px 0px;
		overflow: visible;
	}
	h4.table-title {
		color: #fff;
		margin-top: 20px;	
	}
	.shipping-table .row .col input, .shipping-table .row .col select {
		padding:10px;
	}
	#manageStoreContent .flex-container {
		padding-bottom: 60px!important;
	}
	.checkout-cart {
		width: 100%;
		padding:30px;
		height: auto;
	}
	.checkout-form {
		width: 100%;
		padding:30px;
		height: auto;
	}

	.product-image-wrapper .product-image-thumbs img {
		width: 50px;
		height: 50px;
		border:none;
	}

	.back-to-store-btn {
		width: 100%;
		padding:10px 0;
	}
	#productContent .design-block-toolbar,
	#blogPost .design-block-toolbar {
		    opacity: 1;
		    visibility: visible;
		    transform: none;
	}
	#productContent .design-block-toolbar .left .design-block-toolbar-background.green,
	#blogPost .design-block-toolbar .left .design-block-toolbar-background.green  {
		margin:0;
	}
	.manage-store-cards {
		flex-wrap: wrap;
		margin-top: 0;
		margin-bottom: 20px;
	} 
	.manage-store-cards div:first-child {
		width: 100%;
	}
	.manage-store-cards div {
		width: calc(50% - 10px);
		margin-bottom: 15px;
		padding:20px 30px;
	}

	.manage-store-sections .section .cart-item img {
		width: 100px;
		height: 100px;
		margin-right: 20px;
	}
	#manageStoreContent .cart-item h4 {
		font-size: 20px;
	}

	#manageStoreContent .cart-item .action-buttons {
		position: absolute;
		right: auto;
		left: 40px;
	}

	#manageStoreContent .cart-item .product-inventory {
		right: 20px;
	}
	#manageStoreContent .cart-item {
		padding-right: 20px;
	}

	.payment-options-info div {
		width: 100%;
	}

	#manageStoreContent .cart-item .product-inventory {
		display: none;
	}
	.flex-table.row-5 .table-row div {
		padding:10px;
	}
	.flex-table.row-5 .table-row div:nth-child(1),
	.flex-table.row-5 .table-row div:nth-child(6) {
		display: none;
	}
	.flex-table.row-5 .table-row div.flag {
		display: none;
	}
	.flex-table.row-5 .table-row div:nth-child(2),
	.flex-table.row-5 .table-row div:nth-child(5) {
		width:auto;
	}
	.flex-table.row-5 .table-row div:nth-child(5) {

	}
	.manage-store-sections .section .cart-item .card-dropdown {
		right: 10px;
	}
	.manage-store-sections .section .cart-item {
		height: auto;
		flex-direction: column;
		padding-right: 0;
		margin-bottom: 20px;
	}
	.manage-store-sections .cart-item h4 {
		padding:20px;
		width: 100%;
	}

	.manage-store-sections .section .cart-item img {
		position: relative;
		width: 100%;
		height: 100%;
		margin-right: 0;
		border-radius: 6px 6px 0 0;
	}

	.manage-store-sections .cart-item .product-inventory {
		position: relative;
		width: 100%;
	}

	.manage-store-sections .cart-item .product-inventory div {
		opacity: 1!important;
		text-align: left;
		padding:10px;
		justify-content: flex-start;
	}
	.cart-item .product-inventory strong {
		font-size: 14px;
	}

	.edit-product-images .action-buttons {
		opacity: 1;
		flex-direction: row;
		align-items: flex-end;
	}
	.product-variant-complete .left {
		padding:10px;
		width: 100%;
		    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
	}
	.product-variant-complete {
		position: relative;
	}
	.product-variant-complete .left strong {
		/*position: absolute;
		left: 10px;
		top: 10px;*/
	}
	.product-variant-complete .right {
		padding:0;
	}
	.product-variant-complete .action-buttons {
		position: absolute;
		flex-direction: row-reverse;
		bottom: 5px;
		right: 5px;
		display: flex;
	}
	.product-variant-complete h2 {
		position: absolute;
		bottom: 10px;
		left: 14px;
		font-size: 14px;
	}
	.product-variant-complete .left h2 {
		position: relative;
		left: auto;
		bottom: auto;
		font-size: 16px;
	}

	.product-variant-complete .action-buttons .button {
		margin-left: 5px;
	}
	.product-variant-complete .action-buttons .button.delete {
		background: transparent;
		color: red;
	}

	#cartBtn.hide-desktop {
		display: block!important;
		position: absolute;
		right: 46px;
		font-size: 23px;
	}
	#cartBtn.hide-desktop .fa {
		font-weight: 300;
	}
	#cartBtn.hide-desktop img {
		width: 23px!important;
	}

	#cartBtn.hide-desktop span {
		top: 0;
		right: -6px;
	}

	.left-cards,
	.right-cards {
		width: 100%;
	}
	.right-cards {
		margin-top: 20px;
	}
	.input-group.half,
	.input-group.third {
		width: 100%;
	}
}
