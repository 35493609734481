#storeProducts,
#productContent{
	animation:fadeIn 0.6s forwards;
}

@media (min-width:1025px) {
	#storeProducts.cat-left .cat-filter-nav {
		width: 200px;
		display: block;
		padding-left: 35px;
	}

	#storeProducts.cat-left .product-grid {
		width: calc(100% - 200px);
		margin:0;
	}

	#storeProducts.cat-left {
		justify-content: center;
	}

	#storeProducts.cat-left .cat-filter-nav div:after {
		display: none;
	}
}

.cat-filter-nav-position {
	width: 100%;
	position: absolute;
	top: -30px;
	display: flex;
	justify-content: center;
	align-items: center;
	border:none;
	transition: 0.2s;
	transform: translateY(5px);
	opacity: 0;
}

.layout-preview-footer {
	    position: sticky;
    bottom: 0px;
    border-radius: 6px 6px 0 0;
    left: 10px;
    width: calc(100vw - 20px);
    height: 50px;
    display: flex;
    padding: 10px;
    background: linear-gradient(0deg, #3c4665 0%, #21283F 100%);
    box-shadow: 0 8px 16px rgba(0,0,0,0.08);
    text-align: center;
    justify-content: center;
    opacity: 0;
    animation: previewFooter 0.6s forwards;
    animation-delay: 1s;
    z-index: 20;
    align-items: center;
}

.layout-preview-footer .btn {
	font-size: 14px;
    margin: 0 auto;
    padding: 6px 12px!important;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}

.layout-preview-footer:before {
	/*width: calc(100% - 8px);
    height: calc(100% - 8px);
    border: dashed 1px #fff;
    position: absolute;
    left: 3px;
    top: 3px;
    content: " ";
    border-radius: 4px;
    opacity: 0.3;*/
}

@keyframes previewFooter {
	0% {opacity: 0; transform: translateY(20px);}
	100% {opacity: 1; transform: translateY(0px);}
}

.cat-filter-nav:hover .cat-filter-nav-position {
	opacity: 1;
	transform:none;
}
.cat-filter-nav-position:after {
	display: none!important;
}
.cat-filter-nav-position .pos-selector {
	width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    color: #333;
    background: #fff;
    font-size: 30px;
    transition: 0.2s;
    border: solid 1px #efefef;
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);
    z-index: 10;
    cursor: pointer;
    margin:0 2px;
}

.cat-filter-nav-position .pos-selector img {
	width: 16px;
    height: 16px;
    object-fit: contain;
    border-radius: 0;
    object-position: unset;
    opacity: 0.5;
}
.cat-filter-nav-position .pos-selector:after {
	display: none!important;
}

.cat-filter-nav-position .pos-selector.active img,
.cat-filter-nav-position .pos-selector:hover img {
	opacity: 1;
}

.cat-filter-nav-position .pos-selector.active {
	background: linear-gradient(-45deg, #3c4665 0%, #21283F 100%);
}
.cat-filter-nav-position .pos-selector.active img {
	filter: invert(1);
}

.edit-body-mode .cat-filter-nav {
	transition: 0.2s;
	border:dashed 1px transparent;
}

.edit-body-mode .cat-filter-nav:hover {
	border:dashed 1px #ccc;
}

.cat-filter-nav {
	width: 100%;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	overflow: auto;
	display: flex;
	margin-bottom: 20px;
	position: relative;
	z-index: 2;
	overflow: visible;
}

#storeProducts:not(.cat-left) .cat-filter-nav {
	margin-left: auto;
	margin-right: auto;
	max-width: 1200px;
	padding:0 50px;
}

#storeProducts .cat-filter-nav.cat-left {
	padding:0;
	margin-left: 0;
	margin-right: 0;
}
#storeProducts.design-page-align-left .cat-filter-nav:not(.cat-left) {
	justify-content: flex-start;
}
#storeProducts.design-page-align-right .cat-filter-nav:not(.cat-left) {
	justify-content: flex-end;
}

.cat-filter-nav span {
	opacity: 0.4;
}

.cat-filter-nav div:not(.pos-selector):not(.cat-filter-nav-position) {
	position: relative;
	padding:3px 8px;
	margin:3px 3px;
	transition: 0.2s;
	cursor: pointer;
	opacity: 0.6;
	flex-shrink: 0;
}

.text-white .cat-filter-nav div:after {
	background: #fff;
}

.cat-filter-nav div:after {
	width: 0%;
	bottom: 0px;
	left: 0;
	position: absolute;
	height: 2px;
	background: #333;
	transition: 0.2s;
	content: " ";
}
.cat-filter-nav div:hover,
.cat-filter-nav div.active {
	opacity: 1!important;
}
.cat-filter-nav div.active:after,
.cat-filter-nav div:hover:after {
	width: 100%;
}

.store-button {
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
    color: #fff;
    padding: 10px 20px;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 15px;
}
.store-button:hover {
	background: linear-gradient(-135deg, #28C76F 0%, #28C76F 100%);
}
.store-button img {
	width: 12px;
	display: inline-block;
	margin-right: 15px;
}

.product-item:hover .hover-box {
	opacity: 1;
	visibility: visible;
	transition-delay: 0.3s;
}


#storeProducts {
	flex-wrap: wrap;
	padding:0px 0 50px 0;
}

#storeProducts .gallery-title-wrapper  {
	width: 100%;
	height: 420px;
	max-height: initial;
	position: relative;
	margin-bottom: 50px;
	transition: 0.3s;
}

#storeProducts .gallery-title-wrapper.no-image .store-hero-image {
	display: none;
}
#storeProducts .gallery-title-wrapper.no-image  {
	height: auto;
	min-height: 150px;
}
.edit-body-mode #storeProducts .gallery-title-wrapper.no-image:hover {
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}

#storeProducts .gallery-title-wrapper.no-image:before {
	opacity: 0;
}
#storeProducts .gallery-title-wrapper.no-image .gallery-title {
	color: inherit;
}

#storeProducts .gallery-title-wrapper:before {
	position: absolute;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: 0.1;
	content: " ";
	top: 0;
	left: 0;
	z-index: 2;
}

#storeProducts .gallery-title-wrapper .store-hero-image {
	display: none;
}
#storeProducts .gallery-title-wrapper .store-hero-image {
	width: 100%;
	display: block;
	position: absolute;
	height: 100%;
	top: 0;
	left: 0;
	object-fit: cover;
}
#storeProducts .gallery-title-wrapper .gallery-title {
	position: relative;
	z-index: 3;
	color: #fff;
	height: 100%;
	max-width: 1200px;
	justify-content: center;
    display: flex;
    flex-direction: column;
}

#storeProducts.cat-left .gallery-title-wrapper .gallery-title {
	max-width: 1440px;
}
.category-navigation {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	padding:10px 50px;
	width: 100%;
	max-width: 1200px;
	margin:0 auto;
}
.category-item {
	text-transform: uppercase;
	margin-right: 35px;
	font-size: 14px;
	opacity: 0.3;
	cursor: pointer;
	border-bottom: solid 1px transparent;
}
.category-item:hover,
.category-item.active {
	opacity: 1;
	border-bottom: solid 1px #000;
}
.product-grid {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	width: 100%;
	/*max-width: 1000px;*/
	max-width: 1200px;
	padding:0 40px;
	margin:0 auto;
	position: relative;
}

.product-grid  .add-product-btn-set {
	margin-top: -50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    position: sticky;
    top: -60px;
    z-index: 50;

}
.add-product-btn-set .title-badge {
	margin-right: 10px;
	margin-top: 12px;
}
.product-grid .add-product-btn-set .add-photo {
	padding:10px 20px;
	font-weight: normal;
	cursor: pointer;
}

.product-grid .add-product-btn-set .add-photo.empty {
	background: #fff;
	color: #333;
	border:solid 1px #efefef;
	box-shadow: 0 8px 16px rgba(0,0,0,0.05);
	margin-right: 10px;
}
.product-grid .add-product-btn-set .add-photo.empty:hover {
	background: #fafafa;
}

.product-grid .product-item {
	width: calc(33.333% - 20px);
	margin:20px 10px;
	position: relative;
}

.product-grid .product-item-info h4 {
	margin-bottom: 0;
	margin-top: 10px;
	font-size: 23px;
}

.product-grid .product-item-info .product-price .old-price {
	text-decoration: line-through;
	margin-left: 20px;
	font-size: 14px;
}

.product-grid .product-image {
	width: 100%;
	position: relative;
}

.product-grid .product-image:after {
	content: "+";
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border:solid 1px #fff;
	position: absolute;
	left: calc(50% - 15px);
	top: calc(50% - 15px);
	font-size: 20px;
	text-align: center;
	line-height: 40px;
	color: #fff;
	transition: all 0.3s;
	transform: translateY(10px);
	opacity: 0;
	z-index: 4;
}

.special-offer-label {
	position: absolute;
	top: 10px;
	left: 10px;
	font-size: 12px;
	border-radius: 6px;
	z-index: 4;
	text-transform: uppercase;
	padding:5px 10px;
	color: #fff;
	background: #38964c;
}
.product-grid .product-image:before {
	content: " ";
	padding-top: 100%;
	display: block;
	width: 100%;
	transition: all 0.3s;
	background: rgba(0,0,0,0.25);
	position: relative;
	z-index: 3;
	opacity: 0;
}

.image-display-portrait .product-grid .product-image:before {
	padding-top: 140%;
}
.image-display-landscape .product-grid .product-image:before {
	padding-top: 66.666%;
}
.product-grid .product-item:hover .product-image:before,
.product-grid .product-item:hover .product-image:after {
	opacity: 1;
	transform:none;
}
.product-grid .product-image img {
	position: absolute;
	top: 0;
	left: 0;
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.store-1 .product-grid .product-image img {
	border-radius: 4px;
}
.store-3 .product-grid .product-item  {
	margin:10px;
}
.store-3 .product-grid .product-item-info {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: flex-end;
		align-items: flex-start;
		color: #fff;
		text-align: center;
		z-index: 5;
		top: 0;
		left: 0;
		flex-direction: column;
		transition: all 0.4s;
		opacity: 1;
		padding:20px;
}

.store-3 .product-grid .product-item-info .grid-price {
	transition: all 0.3s;
	opacity: 0;
	height: 0px;
	font-size: 23px;
}

.store-3 .product-grid .product-item:hover .product-item-info .grid-price {
	transition: all 0.3s;
	opacity: 1;
	height: 25px
}

.store-3 .product-grid .product-image:before {
	opacity: 1;
	background: linear-gradient(to bottom left, transparent 20%, rgba(0,0,0,0.2) 100%)
}

.store-3 .product-grid .product-item:first-of-type {
	width:100%;
}

.store-3 .product-grid .product-item:first-of-type .product-item-info {
	padding:50px;
}

.store-3 .product-grid .product-item:first-of-type .product-item-info h4 {
	font-size: 45px;
}


.store-4 .product-grid .product-item  {
	width: calc(25% - 20px);
	border-radius: 6px;
    overflow: hidden;
    transition: all 0.2s;
    background: #fff;
    box-shadow: 0 5px 10px rgba(0,0,0,0.08);
}
.store-4 .product-grid .product-item:hover {
	box-shadow: 0 5px 10px rgba(0,0,0,0.15);
	transform: scale(1.02,1.02);
}

.store-4 .product-item-info h4 {
	 margin: 0;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
}
.store-4 .product-item-info .grid-price {
	font-size: 14px;
}

.store-4 .product-item-info {
	padding:20px;
	color: #333;
}

.store-5 .product-grid .product-item  {
	width: calc(20% - 20px);
}

.store-5 .product-grid .product-image img {
	border-radius: 6px;
}
.store-5 .product-grid .product-item-info h4 {
	margin-top: 10px;
	font-size: 16px;
}

.product-grid .product-item-info .grid-price .old-price,
.block-product-item .old-price {
	text-decoration: line-through;
	opacity: 0.6;
	transform: scale(0.8);
	margin-left: 10px;
	color: red;
}



.price-wrapper .grid-price {
	display: flex;
	flex-direction: column-reverse;
}

.price-wrapper .grid-price  .old-price {
	text-decoration: line-through;
	opacity: 0.6;
	font-size: 20px;
	color: red;
}

.dashboard-image-placeholder {
	width: calc(100% - 40px);
	max-width: 900px;
	margin:20px auto 100px auto;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.dashboard-image-placeholder img {
	width: 100%;
	max-width: 400px;
	margin:auto;
}
.empty-store-placeholder {
	width: calc(100% - 40px);
	max-width: 900px;
	margin:20px auto 100px auto;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.store-placeholder-title {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}
.store-placeholder-title h1,
.dashboard-image-placeholder h1 {
	font-size: 25px!important;
	font-weight: 700;
	margin:0!important;
}
.empty-store-placeholder-item {
	width: calc(33.333% - 20px);
	background: #fff;
	box-shadow: 0 8px 16px rgba(0,0,0,0.07);
	border:dashed 1px #efefef;
	border-radius: 6px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.empty-store-placeholder-item .add-photo {
	padding:10px 20px;
	font-size: 14px;
	position: absolute;
	transition: all 0.2s;
	opacity: 0;
	transform: translateY(10px);
}
.empty-store-placeholder-item:hover .add-photo {
	opacity: 1;
	transform: none;
}
.empty-store-placeholder-item:before {
	content: " ";
	padding-top: 100%;
	display: block;
	width: 100%;
}
.music-grid .empty-store-placeholder-item:after {
	display: none;
}
.empty-store-placeholder-item:after {
	width: 70%;
	height: 20px;
	background:#efefef;
	content: " ";
	position: absolute;
	bottom: 20px;
	left: 20px;
	z-index: 2;
}
@keyframes productPlaceholder {
	0% {opacity: 0.4};
	25% {opacity: 0.2};
	50% {opacity: 0.4};
	75% {opacity: 0.2};
	100% {opacity: 0.4};
}

	.store-2 .product-grid .product-image:after {
		display: none;
	}

	.store-2 .product-grid .product-item {
		width: calc(50% - 20px);
	}
	.store-2 .product-grid .product-item {
		margin:10px;
	}
	.store-2 .product-grid .product-item-info {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		text-align: center;
		z-index: 5;
		top: 0;left: 0;
		flex-direction: column;
		transition: all 0.4s;
		opacity: 0;
		transform: translateY(20px);
	}

	.store-2 .product-grid .product-item:hover .product-item-info {
		opacity: 1;
		transform: none;
	}

	.store-2 .product-grid .product-item-info h4,
	.store-2 .product-grid .product-item-info span,
	.store-3 .product-grid .product-item-info h4,
	.store-3 .product-grid .product-item-info span {
		color: #fff!important;
	}

	.store-2 .product-grid .product-item-info span.old-price,
	.store-3 .product-grid .product-item-info span.old-price {
		color: red!important;
	}

.flex-container#storeProducts .product-item {
	text-align: left;
}
@media (min-width: 778px) {
	.store-3 .product-grid .product-item:first-of-type .product-image:before {
		padding-top: 50%;
	}
}
@media (max-width: 778px) {
	.product-grid .product-item {
		width: 100%!important;
		margin:20px 25px 0px 25px;
	}
	.product-grid {
		padding:0 10px;
		margin-bottom: 50px;
	}
	.product-grid .product-item-info h4 {
		font-size: 21px!important;
		font-weight: 600;
	}
	.product-grid .product-item-info .grid-price {
		font-size: 18px!important;
	}
	.category-navigation {
		padding:10px 0px 10px 20px;
	}
	#storeProducts .gallery-title-wrapper {
		margin-bottom: 20px;
		height: 300px;
	}
	
	.store-3 .product-grid .product-item {
		margin:5px;
	}
	.store-3 .product-grid .product-item-info h4,
	.store-3 .product-grid .product-item:first-of-type .product-item-info h4 {
		font-size: 20px;
	}
	.store-3 .product-grid .product-item .product-item-info {
		padding:15px!important;
	}
	.store-3 .product-grid .product-item .product-item-info .grid-price {
		transition: all 0.3s;
	    /*opacity: 1;
	    height: 25px;*/
	}
	.store-4 .product-grid .product-item,
	.store-5 .product-grid .product-item {
		width: calc(50% - 10px);
	}
	.store-4 .product-item-info {
		padding:20px;
	}
	.store-4 .product-item-info h4 {
		font-size: 16px;
		margin-bottom: 0;
	}
	.store-2 .product-grid .product-item {
		width: calc(50% - 2px);
		margin:1px!important;
	}
	.store-2 .product-grid .product-item .product-item-info {
		/*opacity: 1;
    	transform: none;*/
	}

	.store-2 .product-grid .product-item .product-image:before {
		/*opacity: 1;*/
	}
	.store-3 .product-grid .product-item .product-item-info .grid-price {
		opacity: 1;
    	height: 25px;
	}
	.product-grid .add-product-btn-set {
			width: 60px;
		    height: 50px;
		    position: absolute;
		    top: -40px;
		    right: 0;
		    overflow: visible;
		    flex-direction: column;
		    justify-content: flex-start;
		    align-items: flex-start;
		    align-content: flex-start;
		    padding-top: 50px;
		    background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
		    border-radius: 4px 0 0 4px;
	}
	.product-grid .add-product-btn-set:before {
		content: "+";
		width: 100%;
		height: 100%;
		position: absolute;
		right: 0;
		top: 0;
		font-size: 25px;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;
	}

	.edit-body-mode .product-grid .add-photo {
		position: relative;
		top: auto;
		right: auto;
		width: 144px;
		justify-content: center;
		flex-shrink: 0;
		font-size: 15px;
		border-radius: 0;
		margin:0!important;
		margin-left: -82px!important;
		text-transform: none;
		display: none;
	}
	.edit-body-mode .product-grid .add-product-btn-set:hover .add-photo {
		display: block;
		animation: addProductBtn 0.4s forwards;
	}
	.edit-body-mode .product-grid .add-photo img {
		display: none;
	}
	.add-product-btn-set .title-badge {
		display: none;
	}
	#storeProducts .gallery-title-wrapper .gallery-title {
		padding:20px 30px;
		margin-top: 0;
		margin-bottom: 0;
	}
	.product-grid .add-product-btn-set {
		margin-top: 0;
	}
	.flex-container#storeProducts .design-block-toolbar {
		z-index: 2000!important;
	}

	.save-article-button {
		margin-left: 0px;
		position: absolute;
		top: 39px;
	}

	.blog-grid-container .title-badge {
		display: none;
	}
}

@keyframes addProductBtn {
	0% {opacity:0; transform: translateX(150px);}
	100% {opacity:1; transform: translateX(0px);}
}




