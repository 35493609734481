.collage-icon {
	position: relative;
	display: none;
}
.collage-icon .edit-image {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	border: dashed 1px #ccc;
	background:#fff;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;
	box-shadow: 0 0 0 10px rgba(255,255,255,1);
}

.collage-icon:hover .edit-image {
	opacity: 1;
}

.collage-icon .edit-image img {
	width: 15px;
	opacity: 0.4;
	filter: invert(1);
}

#collagePage {
	display: flex;
	flex-direction: row;
	background: inherit;
}

#collagePage .slider-buttons {
	display: none!important;
}

.cover-3 #collagePage .slider-buttons,
.cover-4 #collagePage .slider-buttons {
	display: flex!important;
	z-index: 3;
}
.cover-3 #collagePage .slider-buttons {
	bottom: 50px;
	top:auto;
	justify-content: center;
	padding-right: 40px;
	padding-left: 0;
}
.collage-photos {
	position: relative;
}

#collagePage .change-image-btn {
	opacity: 1;
	visibility: visible;
	position: absolute;
	top: 20px;
	right: 20px;
	font-size: 15px;
	font-weight: 600;
	padding:12px 24px;
	z-index: 200;
}
.collage-photos:hover .change-image-btn {
	opacity: 1;
	visibility: visible;
}

.collage-photos .change-image-btn {
	position: absolute;
	top: 20px;
	right: 20px;
	font-weight: 500;
	opacity: 0;
	visibility: hidden;
	width: 110px;
	z-index: 20;
	height: 37px;
}

.collage-info {
	width: 35%;
	padding:30px 80px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}
.collage-info .explore-btn {
	display: inline-block;
	margin-top: 20px;
}
.collage-info p,
.collage-info h1,
.collage-info h2,
.collage-info h3,
.collage-info h4 {
	margin:0!important;
}
.collage-icon {
	width: 80px;
	height: 80px;
	border-radius: 50%;
	overflow: hidden;
	margin-bottom: 20px;
}
.collage-icon .icon-image {
	width:100%;
	height: 100%;
	object-fit: cover;
}

.cover-1 .collage-photos {
	width: 65%;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	
}

.cover-1 .collage-photos .collage-photo {
	width: 33.333%;
	height: 33.333%;
}

.collage-photos .collage-photo img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.collage-info .collage-text h1 span,
.collage-info .collage-text h2 span,
.collage-info .collage-text h3 span,
.collage-info .collage-text h4 span {
	display: inline-block;
}

.cover-1 .collage-icon,
.cover-3 .collage-icon,
.cover-5 .collage-icon {
	display: none;
}
.cover-1 .collage-photos,
.cover-1 .collage-info,
.cover-3 .collage-photos,
.cover-3 .collage-info {
	height: calc(100vh - 80px);
}
.cover-1.nav-2 .collage-photos,
.cover-1.nav-2 .collage-info,
.cover-3.nav-2 .collage-photos,
.cover-3.nav-2 .collage-info {
	height: 100vh;
}

.edit-body-mode.cover-1 .collage-photos,
.edit-body-mode.cover-1 .collage-info,
.edit-body-mode.cover-3 .collage-photos,
.edit-body-mode.cover-3 .collage-info,
.edit-body-mode.cover-5 .collage-info  {
	height: calc(100vh - 130px);
}
.edit-body-mode.nav-2.cover-1 .collage-photos,
.edit-body-mode.nav-2.cover-1 .collage-info,
.edit-body-mode.nav-2.cover-3 .collage-photos,
.edit-body-mode.nav-2.cover-3 .collage-info,
.edit-body-mode.nav-2.cover-5 .collage-info {
	height: calc(100vh - 50px);
}

.cover-1 .collage-photos,
.cover-1 .collage-info,
.cover-3 .collage-photos,
.cover-3 .collage-info,
.cover-5 .collage-info,
.cover-1 .collage-info,
.cover-1 .collage-photos-wrapper {
	min-height: 550px;
}

.cover-8 .collage-icon {
	display: none;
}

.cover-8 .collage-info {
	width: 100%;
	padding:90px 30px;
	position: relative;
	z-index: 20;
}
.cover-8.edit-body-mode .collage-info h1 {
	height: auto;
	animation: none;
}
.cover-8 .collage-info h1 {
	font-size: 65px;
}
@keyframes titleBlind {
	0% {height: 0; overflow: hidden;}
	99% {height: 160px; overflow: hidden;}
	100% {height: auto; overflow: visible;}
}
.cover-8 .collage-info h2 {
	font-size: 50px;
}
.cover-8 .collage-text {
	opacity: 0;
	animation: fadeInTop 0.4s forwards;
	width: 100%;
}
.cover-8 .collage-info h3 {
	font-size: 40px;
}
.cover-8 .collage-info h4 {
	font-size: 22px;
}
.cover-8 .collage-photos {
	width: 100%;
	display: block;
}
.cover-8 .collage-info p {
	font-size: 18px;
}

.cover-8 .collage-photos .collage-photo:before {
	display: block;
	content: " ";
	padding-top: 75%;
}

.cover-8 .collage-photos .collage-photo img {
	width: 100%;
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	object-fit: cover;
}
.cover-8 .collage-photos:hover .collage-photo img {
	/*transform: scale(1.1);*/
}
@keyframes coverImgZoom {
	0% {transform: scale(1); transform-origin: 0 50%;}
	50% {transform: scale(1.15); transform-origin: 50% 50%;}
	100% {transform: scale(1); transform-origin: 0 50%;}
}


@keyframes imgFloat {
	from {margin-top: 20px; opacity: 0;}
	to {margin-top: 0px; opacity: 1;}
}
.cover-8 .collage-photos .collage-photo {
	width: calc(50% - 30px);
	position: relative;
	z-index: 2;
	flex-shrink: 0;
	margin-left: 20px;
	transition: 0.2s ease;
	height: auto;
}

.cover-8 .collage-photos .collage-photo:nth-child(3),
.cover-8 .collage-photos .collage-photo:nth-child(5) {
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
}
.cover-8 .collage-photos .collage-photo:nth-child(4),
.cover-8 .collage-photos .collage-photo:nth-child(6) {
	position: absolute;
	right: 20px;
	top: 0px;
	opacity: 0;
}

.cover-8 .collage-photos .collage-photo:nth-child(3),
.cover-8 .collage-photos .collage-photo:nth-child(4) {
	opacity: 0;
	animation: dribbbleFade 8s infinite;
	animation-delay: 4s!important;
	z-index: 40;
}

.cover-8 .collage-photos .collage-photo:nth-child(5),
.cover-8 .collage-photos .collage-photo:nth-child(6) {
	opacity: 0;
	animation: dribbbleFadeTwo 8s infinite;
	animation-delay: 4s!important;
	z-index: 50;
}


@keyframes dribbbleFade {
	0% {opacity: 0}
	8% {opacity: 1}
	41% {opacity: 1}
	48% {opacity: 0;}
	100% {opacity: 0}
}

@keyframes dribbbleFadeTwo {
	0% {opacity: 0}
	33% {opacity: 0;}
	41% {opacity: 1;}
	62% {opacity: 1;}
	70% {opacity: 0}
	100% {opacity: 0}
}

.cover-8 .collage-photos .collage-photo:nth-child(7) {
	display: none;
}
.cover-8 .collage-photos:hover .collage-photo:not(:first-of-type) {
	
}
.cover-8 .collage-photos-wrapper {
	flex-wrap: nowrap;
	align-items: flex-start;
	padding-bottom: 20px;
	overflow: auto;
	justify-content: flex-start;
}

.cover-8 .collage-photos-wrapper {

}
@media (min-width:779px) {
	.cover-8 .collage-text {
		display: flex;
		max-width: 1300px;
		margin:0 auto;
	}
	.cover-8 .collage-text-title {
		width: 60%;
		flex-shrink: 0;
		padding-right: 50px;
	}
	.cover-8 .collage-text-description {
		width: 40%;
		flex-shrink: 0;
		padding-left: 50px;
		padding-right: 50px;
	}
}

.cover-3 .collage-photos-wrapper {
	/*background: #000;*/
	overflow: hidden;

}
.collage-photos .design-block-gallery-nav {
	display: none;
}
.cover-3 .design-block-gallery-nav {
	display: flex!important;
	bottom: 40px;
	background: inherit;
}


.cover-4 .design-block-gallery-nav {
		display: flex!important;
		background: transparent!important;
		color: #fff!important;
	}

@media (min-width: 779px) {
	.cover-4 .design-block-gallery-nav span {
		position: absolute;
		bottom: 50vh;
	}

	.cover-3 .design-block-gallery-nav {
		transform: translateX(-20px);
	}

	.cover-4 .design-block-gallery-nav span.next {
		right: 0;
	}

	.cover-4 .design-block-gallery-nav {
		width:100%;
		left: 0;
		background: transparent!important;
		color: #fff!important;
		justify-content: space-between;
	}
	.cover-3 .collage-photos-wrapper {
		border-bottom:solid 40px transparent;
		border-right:solid 40px transparent;
	}

	.cover-3.nav-2 .collage-photos-wrapper {
		border-top:solid 40px transparent;
	}
}

.cover-1.nav-2 .collage-photos {
	height: 100vh;
}
.cover-1 .collage-photos .collage-photo img {
	border:solid 1px transparent;
}
.cover-1 .collage-photos .collage-photo:first-of-type {
	/*margin-left: 33.333%;*/
}

.cover-1 .collage-photos .collage-photos-wrapper div:nth-child(3) {
	width: 40%;
}

.cover-1 .collage-info,
.cover-5 .collage-info {
	padding:30px 0px 30px 50px;
}

.cover-2 #collagePage {
	flex-direction: column;
	justify-content: space-between;
}

.cover-2 .collage-info {
	height: auto;
	min-height: 50vh;
	width: 100%;
	justify-content: center;
	text-align: center;
	align-items: center;
}
.cover-2 .collage-photos {
	width: 100%;
	height: 350px;
	flex-wrap:nowrap;
	display: block;
	overflow: auto;
	margin-top: 0px;
	white-space: nowrap;
}
.cover-2 .collage-photos-wrapper {
	display: block;
	white-space: nowrap;
	position: relative;
}
.collage-photos-wrapper {
	width: 100%;
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	transition: all 0.3s;
	position: relative;
}
.edit-body-mode .collage-photos:hover .collage-photos-wrapper {
	cursor: pointer;
}
.cover-2 .collage-photos .collage-photo {
	width: auto;
	display: inline-block;
	margin-right: 2px;
	height: 100%;
}
.cover-2 .collage-photos .collage-photo img {
	height: 100%;
	margin-bottom: -10px;
	width: auto;
}

.cover-2 .collage-icon {
	margin-top: 20px;
	margin-bottom: 15px;
	display: none;
}
.cover-3 .collage-info {
	width: 40%;
	justify-content: flex-end;
	padding:60px;
}
.cover-3 .collage-photos {
	width: 60%;
	background: inherit;
	position: relative;
}

.cover-3 .collage-photos .collage-photo {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	opacity: 1;
	transition:0.5s;
}

@keyframes imageScaleIn {
	0% {opacity: 0; transform: scale(1);}
	100% {opacity: 1; transform: scale(1.1);}
}

.cover-4 .collage-photos {
	width: 100%;
	height: calc(100vh - 80px);
	position: absolute;
	left: 0;
	top: 0;
	min-height: 600px;
	overflow: hidden;
	background:inherit;
}
.cover-4 .design-block-gallery-nav span {
	font-size: 25px;
}
.cover-4 .collage-photos:before {
	content: " ";
	width: 100%;
	height: 100%;
	background:rgba(0,0,0,0.2);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
}

.cover-4.nav-2 .collage-photos {
	height: 100vh;
}

.cover-4 .collage-photos .collage-photo {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	opacity: 1;
	transition:.5s;
}

.cover-4.nav-2 .collage-info {
	height: 100vh;
}
.cover-4 .collage-info {
	color: #fff;
    height: calc(100vh - 80px);
    width: 100%;
    padding: 100px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    position: relative;
    z-index: 5;
    min-height: 600px;
}

.cover-4 .collage-info .collage-text {
	width: 100%;
}

.cover-4 .design-page-align-right .collage-info {
	text-align: right;
}

.cover-4 .design-page-align-center .collage-info {
	text-align: center;
	justify-content: center;
	padding-top: 0;
}

.cover-6 .design-page-align-right .collage-info {
	margin-left: auto;
	margin-right: 0;
	text-align: right;
}

.cover-6 .design-page-align-left .collage-info {
	margin-left: 0;
	margin-right: 0;
	text-align: left;
	align-self: flex-start;
}
.cover-4 .slider-buttons,
.cover-3 .slider-buttons  {
	top: calc(50% - 25px);
    width: 100%;
    left: 0;
    background:transparent;
    justify-content: space-between;
    padding:0 20px;
    box-shadow: none;
    z-index: auto!important;
}
.cover-4 .slider-buttons button,
.cover-3 .slider-buttons button {
	width: 50px;
	height: 100%;
	border-radius: 0;
	font-size: 0;
	background: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	border:none;
	z-index: 6;
}
.cover-4 .slider-buttons button:hover,
.cover-3 .slider-buttons button:hover {
	transform: translateX(-3px);
	background: transparent;
}
.cover-4 .slider-buttons button:last-child:hover,
.cover-3 .slider-buttons button:last-child:hover {
	transform: translateX(3px);
}
.cover-4 .slider-buttons button:before,
.cover-3 .slider-buttons button:before {
	content: " ";
	width: 30px;
	height: 30px;
	border-bottom: solid 2px #fff;
	border-left:solid 2px #fff;
	transform: rotate(45deg);
}
.cover-4 .slider-buttons button:last-child:before,
.cover-3 .slider-buttons button:last-child:before {
	transform: rotate(225deg);
}
.cover-4 .collage-icon {
	display: none;
}


.cover-5 .collage-info {
	width: 35%;
	justify-content: center;
	position: sticky;
	top: 0;
	height: calc(100vh - 100px);
}
.cover-5.nav-2 .collage-info {
	height: 100vh;
}
.cover-5 .collage-photos {
	padding-left: 50px;
	width: 65%;
}
.cover-5 .collage-photos .collage-photo {
	width: 50%;
	padding:60px;
	height: auto;
	display: flex;
	justify-content: flex-start;
	opacity: 0;
	position: relative;
	animation: fadeInTop 1.5s forwards;
}
.cover-5 .collage-photos .collage-photo:before {
	padding-top: 100%;
	content: " ";
	display: block;
	width: 100%;
}

.cover-5 .collage-photos .collage-photo img {
	/*height: auto;
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);*/
	width: 100%;
	border-radius: 4px;
	position: absolute;
	top: 60px;
	left: 60px;
	width: calc(100% - 120px);
	height: calc(100% - 120px);
}

@media (max-width: 1050px) {
	 .cover-5 .collage-info {
	 	width: 50%;
	 }
	 .cover-5 .collage-photos {
	 	width: 50%;
	 }
}

.cover-5 .collage-photos-wrapper div:nth-child(2),
.cover-5 .collage-photos-wrapper div:nth-child(3),
.cover-5 .collage-photos-wrapper div:nth-child(6) {
	padding:20px;
}

.cover-5 .collage-photos-wrapper div:nth-child(2) img,
.cover-5 .collage-photos-wrapper div:nth-child(3) img,
.cover-5 .collage-photos-wrapper div:nth-child(6) img {
	width: calc(100% - 40px);
	height: calc(100% - 40px);
	top: 20px;
	left: 20px;
}


.cover-5 .collage-photos-wrapper div:nth-child(1) {
	animation-delay: 0s;
}
.cover-5 .collage-photos-wrapper div:nth-child(2) {
	animation-delay: 0.3s;
}
.cover-5 .collage-photos-wrapper div:nth-child(3) {
	animation-delay: 0.6s;
}
.cover-5 .collage-photos-wrapper div:nth-child(4) {
	animation-delay: 0.9s;
}
.cover-5 .collage-photos-wrapper div:nth-child(5) {
	animation-delay: 1.2s;
}	
.cover-5 .collage-photos-wrapper div:nth-child(6) {
	animation-delay: 1.5s;
}
.cover-5 .collage-photos-wrapper div:nth-child(7) {
	animation-delay:1.8s;
}

@keyframes collageIn {
	0% {opacity: 0; transform: scale(1)}
	100% {opacity: 1; transform: scale(1.05)}
}


.cover-6 #collagePage {
	flex-direction: column;
	align-items: center;
	padding-bottom: 80px;
}
.cover-6 .collage-info {
	width:100%;
	max-width: 500px;
	text-align: center;
	justify-content: center;
	padding:80px 30px;
	margin:0 auto;
	align-items: center;
}
.cover-6 .collage-photos {
	width: 100%;
	height:auto;
	max-width: 1100px;
	justify-content: center;
}

.cover-6 .collage-photos-wrapper {
	justify-content: flex-start;
	
}
.cover-6 .collage-photo {
	width: calc(33.333% - 20px);
	position: relative;
	margin:10px;
}
.cover-6 .collage-photo:before {
	width: 100%;
	padding-top: 100%;
	content: " ";
	display: block;
}
.cover-6 .collage-photo img {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.cover-7 #collagePage {
	width: 100%;
	height: calc(100vh - 80px);
	align-items: center;
	justify-content: flex-start;
}

.cover-7 #collagePage.design-page-align-center {
	justify-content: center;
}

.cover-7 #collagePage.design-page-align-center .collage-info {
	text-align: center;
}

.cover-7 #collagePage.design-page-align-right {
	justify-content: flex-end;
}
.cover-7 #collagePage.design-page-align-right .collage-info {
	text-align: right;
}
.cover-7.nav-2 #collagePage {
	height: calc(100vh - 0px);
}

.cover-7.nav-2 .collage-photos {
	height: 100vh
}

.cover-7 .collage-info {
	position: relative;
    z-index: 5;
    text-align: left;
    background: inherit;
    padding: 50px 40px;
    width: 33.333%;
    justify-content: center;
    align-items: center;
    min-width: 320px;
    opacity: 0;
    animation: fadeInTop 1s forwards;
    animation-delay: 0.5s;
}
.cover-7 .collage-info .collage-text {
	width: 100%;
}

.cover-7 .collage-info .collage-icon {
	display: none;
}

.cover-7 .collage-photos {
	width: 100%;
	height: calc(100vh - 80px);
	position: absolute;
	left: 0;
	top: 0;
}

.cover-7 .collage-photos-wrapper {

}
.cover-7 .collage-photos .collage-photos-wrapper {
	height: 100%;
}
.cover-7 .collage-photos .collage-photo {
	width: 33.333%;
	height: 50%;
}

.cover-7 .collage-photos-wrapper div:nth-child(7) {
	display: none;
}


@media (max-width: 779px) { 
	.cover-2 .collage-info {
		min-height: 400px!important;
	}
	.collage-photos {
		min-height: initial;
	}
	.cover-5 .collage-photos-wrapper div:nth-child(2),
	.cover-5 .collage-photos-wrapper div:nth-child(3),
	.cover-5 .collage-photos-wrapper div:nth-child(6) {
		padding: 15px;
	}
	.collage-icon {
		width: 70px;
		height: 70px;
	}
	.collage-info {
		width: 100%!important;
		height: auto!important;
		min-height: 400px!important;
		padding:30px!important;
	}
	.collage-photos {
		width: 100%!important;
	}

	.cover-1 .collage-photos .collage-photo {
		height: auto!important;
		position: relative;
	}

	.cover-1 .collage-photos .collage-photo:before {
		padding-top: 100%;
		content: " ";
		width: 100%;
		display: block;
	}

	.cover-1 .collage-photos .collage-photo img {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	.cover-1 .collage-photos .collage-photos-wrapper div:nth-child(3) {
		width: 33.333%;
	}
	.cover-1 .collage-photos .collage-photos-wrapper div:nth-child(7) {
		width: 100%;
	}
	.cover-4 .collage-info {
		height: calc(100vh - 80px)!important;
		justify-content: center;
    align-items: center;
    text-align: center;
	}
	.cover-4 .collage-photos {
		min-height: initial;
	}
	.cover-4 .collage-photos:before {
		background: #000;
		opacity: 0.3;
	}
	.cover-3 .collage-info {
		min-height: 45vh!important;
		justify-content: center;

	}
	.cover-5 .collage-photos .collage-photo {
		padding:30px;
	}
	.cover-5 .collage-photos .collage-photo img {
		width: calc(100% - 60px);
		height: calc(100% - 60px);
		top: 30px;
		left: 30px;
	}
	.cover-5 .collage-photos {
		height: auto;
		padding-left: 0;
	}
	.cover-6 .collage-photo {
		width: calc(33.333% - 2px);
		margin:1px;
	}
	.cover-6 .collage-photos .collage-photos-wrapper div:nth-child(7) {
		display: none;
	}
	.cover-7 .collage-photos {
		position: relative;
	}
	.cover-7 #collagePage {
		/*flex-direction: column-reverse!important;*/
		justify-content: flex-start;
		height: auto;
	}
	.cover-7 .collage-photos {
		height: calc(50vh - 80px);
	}
	.cover-7 .collage-info {
		align-items: flex-start;
		min-height: 50vh!important;
	}
	.cover-4 .slider-buttons {
		width: 150px;
	    top: auto;
	    bottom: 30px;
	    left: calc(50% - 75px);
	}
	.cover-4 .slider-buttons button,
	.cover-3 .slider-buttons button {
		transform: scale(0.9)!important;
	}
	.cover-1 .collage-info,
	.cover-1 .collage-photos-wrapper {
		min-height: initial;
		align-items: flex-start;
    align-content: flex-start;
	}

	.cover-1 .collage-photos {
		height: auto!important;
    min-height: initial!important;
	}
	.cover-6 .collage-photo:last-of-type:before {
		padding-top: 100%;
	}

	.cover-4 .collage-info h1 {
	font-size: 50px;
	}

	.cover-4 .collage-info h2 {
		font-size: 40px;
	}

	.cover-4 .collage-info h3 {
		font-size:30px;
	}

	.cover-4 .collage-info h4 {
		font-size: 20px;
	}
	.cover-5 .collage-info {
		position: relative;
	}
	.cover-3 .collage-photos {
		min-height: initial;
		height: 380px!important;
	}
	.cover-3 #collagePage .slider-buttons {
		padding:0;
		bottom: 20px;
	}
	.cover-1 .collage-info {
		min-height: 50vh!important;
	}
	.cover-8 .collage-info h1 {
		font-size: 40px;
		animation: none;
		opacity: 1;
		height: auto;
		white-space: wrap;
	}
	.cover-8 .collage-info h2 {
		font-size: 30px;
	}
	.cover-8 .collage-info h3 {
		font-size: 25px;
	}
	.cover-8 .collage-info h4 {
		font-size: 22px;
	}
	.cover-8 .collage-photos-wrapper {
		flex-wrap: nowrap;
		padding-left: 10px;
	}
	.cover-8 .collage-info {
		min-height: 50vh!important;
	}
	.cover-8 .collage-photos {
		min-height: initial;
	}
	 .cover-8  .flex-container .collage-photos .collage-photo {
		margin-left: 0;
    left: 0;
    width: 90%;
    position: relative;
    animation: none;
    opacity: 1;
    visibility: visible;
    border:solid 10px transparent;

	}
	#collagePage .change-image-btn {
		padding:8px 16px;
		top: 10px;
		right: 10px;
	}
}	


@media (max-width: 1024px) {
	.nav-4 .nav-bar .left .nav-links {
		position: relative;
	}
}
.slider-buttons {
	width: 100px;
	height: 50px;
	border-radius: 40px;
	position: absolute;
	left: calc(50% - 50px);
	bottom: 20px;
	background: #fff;
	box-shadow: 0 10px 15px rgba(0,0,0,0.1);
	overflow:hidden;
	display: flex;
	z-index: 2;
}


.slider-buttons button {
	width: 50%;
	background: transparent;
	border: none;
	outline: none;
	height: 100%;
	border-right: 1px solid #efefef;
	text-align: center;
	cursor: pointer;
	transition: all 0.2s ease;;

}
.slider-buttons button:hover {
	background: #efefef;
}
