.design-block-gallery .draggable:hover .hover-box {
	visibility: visible;
	opacity: 1;
}

.design-block-gallery:hover .add-btn {
	opacity: 1;
	visibility: visible;
}
 
.design-block-gallery {
	display: flex;
	width:100%;
	max-width: 1200px;
	margin:0px auto;
	justify-content: center;
	flex-wrap: wrap;
}

.design-block-gallery.layout-6  {
	max-width: initial;
	padding:0!important;
}

.design-block-align-center .design-block-gallery-thumbs  {
	justify-content: center;
}
.design-block-align-right .design-block-gallery-thumbs  {
	justify-content: flex-end;
}

.layout-6 .design-block-gallery-thumbs {
	flex-wrap: nowrap;
	width: 100%;
	overflow: auto;
}

.edit-body-mode .layout-6 .design-block-gallery-thumbs {
	overflow: auto;
}

.design-block-gallery.layout-6 .design-block-gallery-item {
	width: auto;
}

.layout-6 .design-block-gallery-thumbs {
	justify-content: flex-start!important;
}

 .design-block-gallery.layout-6 .List {
	flex-wrap: nowrap;
	justify-content: flex-start!important;
	width: 100%;
	overflow: auto;
	flex-shrink: 0;
}

 .design-block-gallery.layout-6 .List .draggable, 
 .design-block-gallery.layout-6 .List .draggable.placeholder {
	width: auto;
}
.design-block-gallery.layout-6 .List .draggable.placeholder {
	min-width: 250px;
}

 .design-block-gallery.layout-6 .List .design-block-gallery-item {
	width: auto;
}

.design-block-gallery.layout-6 .design-block-gallery-item:before {
	display: none;
}

.design-block-gallery.layout-6  .design-block-gallery-image,
.design-block-gallery.layout-6  .List .design-block-gallery-image {
	width: auto;
}

.design-block-gallery.layout-6  .design-block-gallery-image {
	height: 500px;
	position: relative;
	object-fit: initial;
	width: auto;
	flex-shrink: 0;
}

.design-block-gallery-thumbs {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.design-block-align-left .design-block-gallery {
	justify-content: flex-start;
}
.design-block-align-center .design-block-gallery {
	justify-content: center;
}
.design-block-align-right .design-block-gallery {
	justify-content: flex-end;
}


.design-block-gallery-nav {
	width: 100px;
	height: 50px;
	display: flex;
	position: absolute;
	left: calc(50% - 50px);
	bottom: 1px;
	z-index:11;
	background: inherit;
}
.design-block-gallery-nav span {
	width: 50px;
	height: 50px;
	background: #fff;
	margin-left: 1px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition:0.1s;
	background: inherit;
}

.design-block-gallery-nav span:hover {
	background: #333;
	color: #fff;
}


.design-block-gallery.layout-7 .title-set {
	
}
.design-block-gallery.layout-7 {
	width: 100%;
	max-width: 1200px;
	margin:0 auto;
	background: inherit;
}

.design-block-gallery.layout-7 .design-block-gallery-item {
	width: 100%;
	animation:fadeIn 0.3s forwards;
	display: none;
}

.design-block-gallery.layout-7 .design-block-gallery-item.active {
	display: block;
}

.design-block-gallery.layout-7 .design-block-gallery-item:before {
	display: none;
}

.design-block-gallery.layout-7 .design-block-gallery-image {
	object-fit: contain;
	position: relative;
    max-height: 700px;
}

.design-block-gallery.layout-7 .design-block-gallery-thumbs {
	position: relative;
	background: inherit;
	min-height: 300px;
	transition-delay: 0.2s;
}
.design-block-gallery.layout-7 .design-block-gallery-thumbs:hover {
	border:dashed 1px #efefef;
}

.design-block-align-center .design-block-gallery.layout-5 .title-set {
	align-items: center;
}

.design-block-align-right .design-block-gallery.layout-5 .title-set {
	align-items: flex-end;
}
.design-block-gallery.layout-8 .design-block-gallery-thumbs,
.design-block-gallery.layout-9 .design-block-gallery-thumbs {
	position: relative;
	height: 80vh;
	max-height: 800px;
	background: inherit;
}
.design-block-gallery-item:hover .hover-box {
	opacity: 1;
	visibility: visible;
}
.design-block-gallery.layout-8 .design-block-gallery-item,
.design-block-gallery.layout-9 .design-block-gallery-item {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	opacity: 0;
	visibility: hidden;
	transition:0.5s;
}

/*.design-block-gallery.layout-8 .design-block-gallery-item .design-block-gallery-image,
.design-block-gallery.layout-9 .design-block-gallery-item .design-block-gallery-image {
	object-fit: contain;
}*/
.design-block-gallery.layout-8,
.design-block-gallery.layout-9 {
	background: inherit;
}
.design-block-gallery.layout-8 .design-block-gallery-item.active,
.design-block-gallery.layout-9 .design-block-gallery-item.active {
	opacity: 1;
	visibility: visible;
}	

.design-block-gallery.layout-8 .design-block-gallery-item:before,
.design-block-gallery.layout-9 .design-block-gallery-item:before {
	display: none;
}
.hide-title-set .design-block-gallery.layout-5 .title-set,
.hide-title-set .design-block-gallery.layout-8 .title-set,
.hide-title-set .design-block-gallery.layout-9 .title-set {
	display: flex!important;
}
.design-block-gallery.layout-2,
.design-block-gallery.layout-4 {
	max-width: 1200px;
}

.design-block-gallery .design-block-gallery-item {
	width: 33.333%;
	position: relative;
}
.design-block-gallery .design-block-gallery-item:hover .hover-box {
	opacity: 1;
	visibility: visible;
	background: rgba(0,0,0,0.3);
}

.design-block-gallery.layout-4 .design-block-gallery-item {
	width: 50%;
}

.design-block-gallery .List .design-block-gallery-item {
	width: 100%!important;

}

.design-block-gallery .design-block-gallery-item:before {
	content: " ";
	display: block;
	padding-top: 100%;
}

.image-display-portrait .design-block-gallery .design-block-gallery-item:before {
	padding-top: 130%;
}

.image-display-landscape .design-block-gallery .design-block-gallery-item:before {
	padding-top: 66.666%;
}

.design-block-gallery .design-block-gallery-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: absolute;
	left: 0;
	top: 0;
}

.design-block-gallery.layout-2 .design-block-gallery-item {
	width: 25%;
}

.design-block-gallery.layout-3 .design-block-gallery-item {
	width: 16.666%;
}
.design-block-gallery.layout-3 .title-set {
	margin-bottom: 0;
}
.design-block-gallery-image {
	width: calc(50% - 0px);
	height: 330px;
	border: transparent 1px solid;
	transition: all 0.2s;
	display: block;
	object-fit: cover;
}
@media (min-width: 779px) {
	/*.design-block-gallery.layout-8 .design-block-gallery-thumbs,
	.design-block-gallery.layout-9 .design-block-gallery-thumbs {
		border:solid 40px transparent;
	}*/
	.design-block-padding-small .design-block-gallery.layout-5,
	.design-block-padding-small .design-block-gallery.layout-8,
	.design-block-padding-small .design-block-gallery.layout-9 {
		margin:0;
	}
	.design-block-padding-big .design-block-gallery.layout-5 {
		margin:0;
	}
	.design-block-gallery.layout-5,
	.design-block-gallery.layout-8,
	.design-block-gallery.layout-9 {
		flex-direction: row;
		flex-wrap: nowrap;
		max-width: 1700px;
		margin:-65px auto;
		padding:0;
	}
	.design-block-gallery.layout-8,
	.design-block-gallery.layout-9  {
		margin:0 auto;
	}
	.design-block-gallery.layout-8,
	.design-block-gallery.layout-9 {
		margin:-65px auto;
	}
	.design-block-padding-big .design-block-gallery.layout-8,
	.design-block-padding-big .design-block-gallery.layout-9,
	.design-block-padding-big .design-block-gallery.layout-5 {
		margin:-160px auto;
	}
	.design-block-padding-small .design-block-gallery.layout-8,
	.design-block-padding-small .design-block-gallery.layout-9,
	.design-block-padding-small .design-block-gallery.layout-5 {
		margin:-30px auto;
	}


	.design-block-gallery.layout-9 {
		flex-direction: row-reverse;
	}
	.design-block-gallery.layout-5 .design-block-gallery-thumbs,
	.design-block-gallery.layout-8 .design-block-gallery-thumbs,
	.design-block-gallery.layout-9 .design-block-gallery-thumbs {
		width: 50%;
	}


	.design-block-gallery.layout-5 .title-set,
	.design-block-gallery.layout-8 .title-set,
	.design-block-gallery.layout-9 .title-set {
		width: 50%;
		max-width: 580px;
		display: flex!important;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		margin-bottom: 0!important;
		padding:90px 30px;
	}

	.design-block-gallery.layout-5 .List .draggable, 
	.design-block-gallery.layout-5 .List .draggable.placeholder {
		width: 50%;
	}

	.design-block-gallery.layout-5 .design-block-gallery-item {
		width: 50%;
	}

	.design-block-gallery.layout-5 .List .draggable.dragged {
		max-width: 300px;
	}

	.design-block-gallery.layout-5 .List {
		
	}
}
@media (max-width: 779px) {
	.design-block-gallery-image {
		height: 150px;
	}
	.design-block-gallery.layout-2 .List .draggable, 
	.design-block-gallery.layout-2 .List .draggable.placeholder {
		width: calc(50% - 1px);
	}
	.design-block-gallery.layout-2 .design-block-gallery-item {
		width: 50%;
	}
	.design-block-gallery.layout-3 .design-block-gallery-item {
		width: 33.333%;
	}
	.hide-title-set .design-block-gallery.layout-5 .title-set,
	.hide-title-set .design-block-gallery.layout-8 .title-set,
	.hide-title-set .design-block-gallery.layout-9 .title-set {
		display: block!important;
	}
	.design-block-gallery.layout-8 .design-block-gallery-thumbs, 
	.design-block-gallery.layout-9 .design-block-gallery-thumbs {
		height: 50vh!important;
	}
	.design-block-gallery.layout-6 .design-block-gallery-image {
		height: 220px;
	}

	.design-block-gallery .title-set {
		padding-left: 20px;
		padding-right: 20px;
	}
}
