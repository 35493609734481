.design-block-products {
	width:100%;
	max-width: 1100px;
	margin:0px auto;
}
.block-products-container {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;
	position: relative;
	width: 100%;
}

.design-block-products.layout-2 {
	width: 100%;
	max-width: 1100px;
}
.design-block-products.layout-2 .title-set,
.design-block-products.layout-3 .title-set {
	width:100%;
	max-width: 1100px;
	margin:0px auto 30px auto;
}


.design-block-products.layout-3 .block-products-container {
	flex-wrap: nowrap;
	overflow: auto;
	margin-top: 70px;
}
.design-block-products.layout-3 {
	max-width: initial;
	padding-left: 0;
	padding-right: 0;
}

.design-block-products.layout-4 .title-set {
	width:100%;
	max-width: 1220px;
	margin:0px auto 30px auto;
}

.design-block-products.layout-4  {
	max-width: 1200px;
}
.design-block-products.layout-3 .block-product-item:first-child {
	margin-left: 50px;
}

.layout-3 .block-product-item {
	margin:0;
	width: 500px;
	flex-shrink: 0;
}

.layout-2 .block-product-item {
	position: relative;
	width: calc(33.333% - 2px);
	margin:1px;
	overflow: hidden;
}

.layout-4 .block-product-item {
	position: relative;
	width: 33.333%;
	margin:0px;
	overflow: hidden;
}

@media (min-width: 779px) {
	.layout-4 .block-product-item:nth-child(1) {
		width: 66.666%;
	}
	.layout-4 .block-product-item:nth-child(2),
	.layout-4 .block-product-item:nth-child(3) {
		width: 33.333%;
	}
	.layout-4 .block-product-item:nth-child(2) {
		position: absolute;
		right: 0;
		top: 0;
	}
	.layout-4 .block-products-container {
		align-items: flex-end;
	}
}	

.layout-4 .product-item-image:before {
	display: block;
	width: 100%;
	content: " ";
}

.layout-2 .block-product-item:after,
.layout-4 .block-product-item:after  {
	content: " ";
	height: 100%;
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 100%);
}
.layout-2 .product-item-info,
.layout-4 .product-item-info {
	position: absolute;
	width: 100%;
	bottom: 0;
	z-index: 2;
	left: 0;
	padding:20px;
	color: #fff;
}

.design-block-products h4 {
	width: 100%;
	/*font-size: 25px!important;*/
	font-weight: 800;
	margin:10px 0 20px 0;
}

.master-container .design-block-background-wrapper .design-block-products h4 {
	font-size: 25px;
}

.design-block-products.layout-2 .block-product-item h4,
.design-block-products.layout-4 .block-product-item h4,
.design-block-products.layout-2 .block-product-item span,
.design-block-products.layout-4 .block-product-item span  {
	color: #fff!important;
}
.block-product-item {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: calc(33.333% - 20px);
	cursor: pointer;
	margin:10px 10px 30px 10px;
}

.product-item-image img {
	height: 100%;
	object-fit: cover;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	margin-bottom: 0;
	transition: all 0.3s;
}
.product-item-image {
	width: 100%;
	position: relative;
	overflow: hidden;
}
.product-item-image:before {
	padding-top: 100%;
	display: block;
	width: 100%;
	content: " ";
}

.image-display-portrait .product-item-image:before {
	padding-top: 130%
}	
.image-display-landscape .product-item-image:before {
	padding-top: 66.666%
}	
.block-product-item:hover .product-item-image img {
	transform: scale(1.1,1.1);
}

.block-product-item h4 {
	margin:20px 0 0 0!important;
}
.block-product-item span {
	font-size: 16px;
}
@media (max-width: 779px) {
	.design-block-products.layout-3 .block-products-container {
		margin-top: 0;
	}

	.layout-3 .block-product-item:first-child {
		margin-left: 20px!important;
	}
	.layout-3 .block-product-item {
		width: 250px!important;
	}

	.design-block-products h4 {
		font-size: 18px!important;
	}
	.master-container .design-block-background-wrapper .design-block-products h4 {
		font-size: 25px!important;
	}
	.block-product-item .product-item-info span {
		font-size: 18px!important;	
	}

	.design-block-products {
		padding-right: 20px;
		padding-left:20px;
	}
	.block-product-item {
		margin-left: 0;
		margin-right: 0;
	}
	.design-block-products.layout-3 {
		padding-left:0;
		padding-right: 0;
	}
	.design-block-products.layout-3 .title-set {
		padding-right: 20px;
		padding-left:20px;
	}
	.master-container .design-block-background-wrapper .design-block-products h4 {
		font-size: 20px!important;
	}
	.master-container .design-block-background-wrapper .design-block-products .grid-price {
		font-size: 18px!important;
	}
}