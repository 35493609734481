* {
	box-sizing: border-box;
}
html, body {
	padding:0px;
	margin:0px;
	height: 100%;
}
body {
	font-family: Source Sans Pro;
	background: #fff;
	user-select: none;
	color: #333;
}

a {
	text-decoration: none;
	color: #333;
}

.clickai-color {
	background: #262E47;
}
.instagram-color {
	background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
}
.facebook-color {
	background: #4267b2;
}
.dribbble-color {
	background: #ea4c89;
}
.unsplash-color {
	background: #000;
}
.twitter-color {
	background: #0084b4;
}
.pinterest-color {
	background: #c8232c;
}
.linkedin-color {
	background: #0077B5;
}
.youtube-color {
	background: #c4302b;
}
.messenger-color {
	background: linear-gradient(45deg, #2E67FF 0%, #FF5769 100%);
}

.whatsapp-color{
	background: #25D366
}

.fa, .fas {
	font-weight: 400;
}

.design-block-background-wrapper p,
.flex-container p {
	margin:0;
	line-height: 1.4em;
}
@media (max-width: 779px) {
	.design-block-background-wrapper p span,
	.flex-container p span {
		font-size: inherit!important;
	}
}
.flex-container {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	position: relative;
	justify-content: space-between;
}

.flex-container.design-page-align-left {
	text-align: left;
	/*justify-content: flex-start;*/
}
.flex-container.design-page-align-center {
	text-align: center;
	/*justify-content: center;*/
}
.flex-container.design-page-align-right {
	text-align: right;
	/*justify-content: flex-end;*/
}

.flex-container h1,
.click-carousel-text h1 {
	font-size: 60px;
	font-weight: normal;
	margin-top: 0;
	margin-bottom: 0px;
}
.flex-container h2,
.click-carousel-text h2 {
	font-size: 50px;
	font-weight: normal;
	margin-top: 0;
	margin-bottom: 0px;
}

.flex-container h3,
.click-carousel-text h3 {
	font-size: 37px;
	font-weight: normal;
	margin-top: 0;
	margin-bottom: 0px;
}
.flex-container h4,
.click-carousel-text h4 {
	font-weight: normal;
	font-size: 23px;
	margin-top: 0;
	margin-bottom: 0px;
}

.flex-container h5,
.click-carousel-text h5 {
	font-weight: normal;
	font-size: 20px;
	margin-top: 0;
	margin-bottom: 0px;
}

.flex-container h6,
.click-carousel-text h6 {
	font-weight: normal;
	font-size: 15px;
	margin-top: 0;
	margin-bottom: 0px;
}
.flex-container .btn:hover {
	opacity: 1;
	transform: scale(1.05,1.05);
}
.flex-container h1 span,
.flex-container h2 span,
.flex-container h3 span,
.flex-container h4 span {
	font-size: inherit!important;
}
.blank-page-container {
	height: auto;
	min-height: calc(100vh - 200px)!important;
}

.gallery-title-wrapper {
	width: 100%;
}
@media (max-width: 779px) {
	.col-40, .col-50, .col-60 {
		width: 100%!important;
	}
	
	body #articleContent.blog-article {
		min-height: auto;
		height: auto;
	}
	body #articleContent.blog-article .article-content {
		padding-bottom: 0!important;
		flex-direction: column-reverse;
	}
	#articleContent.blog-article .article-content h1 {
		font-size: 26px;
		font-weight: bold;
		padding-bottom: 0;
	}

	.section-title:before {
		width: 300px;
		left: calc(50% - 150px);
	}
	
	
	.navigation-arrow-wrapper {
		display: none;
	}
	.master-container {
		overflow:unset;
		height: auto;
	}
	.flex-container h1 span,
	.flex-container h2 span,
	.flex-container h3 span,
	.flex-container h4 span {
		font-size: inherit!important;
	}
	
	
	
	.article-stats li:first-child {
		margin-bottom: 0;
	}
	.master-container {
		padding-top: 60px;
	}
	.flex-container {
		padding:0px 0px 0 0!important;
		flex-direction: column!important;
		justify-content: flex-start;
		margin-top: 0!important;
		min-height: calc(100vh - 120px)!important;
	}
	#faqContent {
		padding:0px 20px 20px 20px!important;
	}

	.faq-1 #faqContent.flex-container,
	.layout-1 #faqContent {
		padding-top: 60px!important;
	}
	.maintenance-page {
		background-size: 160%;
		padding:60px 40px;
		align-items: flex-start;
	}
	.maintenance-page h1 {
		font-size: 38px;
	}
	.maintenance-page .left {
		margin-left: 0;
		width: 100%;
	}
	.maintenance-page .left .home-btn {
		padding:15px;
		margin-left: 0!important;
	}
	.flex-container h1 {
		font-size: 40px!important;
	}
	.flex-container h2 {
		font-size: 34px!important;
	}

	.flex-container h3 {
		font-size: 24px!important;
	}
	.flex-container h4 {
		font-size: 20px!important;
	}
	.login-graphic.confirm {
		height: 100vh!important;
		padding:20px;
	}
	.login-graphic.confirm h1, 
	.login-graphic.confirm p {
		display: block;
	}
	.faq-image {
		height: 300px;
		margin-bottom: 30px;
	}
	#productList .empty-store-placeholder {
		margin: 70px auto 0 auto;
	}
	.generic-tabs.white-phone {
		color: #fff;
		border-bottom-color: rgba(255,255,255,0.3);
	}

	.generic-tabs.white-phone div:hover,
	.generic-tabs.white-phone div.active {
		border-bottom-color: #fff;
	}
	.subscribe-to-content-form input,
	.subscribe-to-content-form button {
		width: 100%;
		margin-top: 10px;
	}
	.card-dropdown-content {
		width: 200px;
	}

	.card-dropdown-content a {
		padding:10px 20px;
	}
	#storeProducts,
	#blogContainer {
		transform: none;
		animation: none;
		opacity: 1;
	}
	.header .cai-bot-input {
		background: transparent;
		padding:0;
		box-shadow: none;
		margin:0;
		padding-right: 20px;
		width: auto;
	}
	.header .cai-bot-input span {
		display: none;
	}
	.header .right {
		align-items: center;
		flex-direction: row-reverse;
	}
	.right .notifications-dropdown {
		padding:17px 20px 10px 20px;
	}
	.quick-nav {
		flex-direction: row-reverse!important;
		justify-content: space-between!important;
	}
	.quick-nav-close {
		position: absolute;
		bottom: 100%;
		display: none!important;
		right: 0;
	}
	.quick-nav-arrow {
		width: 40px!important;
		height: 40px!important;
	}
	.quick-nav-preview {
		display: none!important;
	}
	.blank-website-placeholder {
		height: 60vh;
    	min-height: initial;
    	margin-top: 20px;
	}
	.blank-page-placeholder h3, 
	.blank-website-placeholder h3 {
		display: none
	}
	
	
}

.maintenance-page {
	display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    background-image: url('/assets/img/maintenance.jpg');
    background-size: 80%;
    background-position: 100% 100%;
    background-repeat: no-repeat;
}
.maintenance-page .logo {
	position: fixed;
	width: 100px;
	top: 20px;
	left: 30px;
}

.maintenance-page .logo.bottom {
	top: auto;
	bottom: 20px;
	left: auto;
	right: 20px;
	width: 80px;
}
.maintenance-page h1 {
	margin:0;
	font-size: 48px;
	font-weight: 200;
}
.maintenance-page h1 span {
	font-weight: bold;
}

.maintenance-page .left {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: left;
	align-items: flex-start;
	width: 40%;
	max-width: 500px;
	margin-left: 100px;
}

.maintenance-page .left p {
}
.maintenance-page .left .home-btn {
	float: left;
	margin-top: 20px;
}
.maintenance-page .left .home-btn:not(.empty):hover {
	box-shadow: 0 6px 12px rgba(0,0,0,0.1), 0 0 0 10px rgba(0,0,0,0.03);
	transform: translateY(-2px);
}
.maintenance-page .left .home-btn.empty {
	background: transparent;
	box-shadow: none;
	color: #333;
	margin-left: 10px;
}
.maintenance-page .left .home-btn.empty:hover {
	background: #fafafa;
}
.flex-container#landingPage {
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: calc(100vh - 100px);
	text-align: center;
}

.master-container {
	height: 100%;
	padding-top: 80px;
	background:#fff; 
	transform-origin:0% 50%;
	transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
	transition-delay:0.1s;
	font-size: 18px;
	/*overflow-x: hidden;*/
}
.master-container.text-white .flex-container,
.flex-container.text-white,
.flex-container.text-white a,
.master-container.text-white .flex-container a {
	color: #fff;
}

.col-60 {
	width: 60%;
}
.col-40 {
	width: 40%;
}
.col-25 {
	width:25%;
}
.col-75 {
	width: 75%;
}
.col-50 {
	width: 50%;
}
.clickai-cookies-wrapper {
	position: fixed;
	bottom: 50px;
	left: 0px;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	padding:20px;
}

.clickai-cookies {
	padding:15px 28px;
	color: #333;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #fafafa;
	border-radius: 4px;
	z-index: 500000;
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
	animation:fadeInBottom 0.5s forwards;
	max-width: 1000px;
}
.cookies-message {
	margin:0;
	padding:0;
	width: calc(100% - 180px);
}

.cookies-message p,
.cookies-message h4 {
	margin:0;
}

.cookies-message h4 {
	font-size: 20px;
	margin-bottom: 4px;
}

.close-cookies {
	position: absolute;
    top: 9px;
    left: 9px;
    line-height: 0;
    cursor: pointer;
    font-size: 15px;
}
.clickai-cookies .right {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
}

.clickai-cookies a {
	text-decoration: underline;
	color: #fff;
	cursor: pointer;
}

.cookie-btn {
	padding:7px 14px;
	border-radius: 4px;
	background: #333;
	color: #fff;
	margin-left: 20px;
	cursor: pointer;
	flex-shrink: 0;
}
.cookie-btn.empty {
	background: none;
	color: #333;
}
@media (max-width: 779px) { 
	.clickai-cookies {
		flex-direction: column;
		text-align: center;
	}
	.cookies-message {
		margin-bottom: 10px;
		width: 100%;
	}

	.clickai-cookies .right {
		flex-direction: row;
		justify-content: center;
		margin-top: 10px;
	}
	.cookie-btn {
		margin:0 10px;
	}
}
.click-branding {
	width:100px;
	height: 100px;
	background: #333;
	position: fixed;
	bottom: 0px;
	left: 0px;
}
.click-branding:before {
	content: "POWERED BY clickClick";
    font-size: 11px;
    color: #fff;
    position: absolute;
    top: 5px;
    left: 43px;
}

.flex-container .accordion-toggle,
#faqContent .accordion-toggle,
.design-block-background-wrapper .accordion-toggle {
	border-bottom: solid 1px #efefef;
	padding:20px 0;
	margin-bottom: 0;
	margin-top: 0;
	position: relative;
	cursor: pointer;
	display: flex;
	font-size: 20px!important;
	align-items: center;
}
.accordion-toggle.active {
	border:none;
}
.accordion-toggle.active:hover {
	box-shadow: none;
}
.accordion-toggle:hover {
	box-shadow: 0 5px 10px rgba(0,0,0,0.0);

}
.accordion-toggle:hover:before {
	opacity: 1;
	transform: translateX(10px);
}
.active .accordion-toggle:after {
	transform: rotate(45deg);
}
.active .accordion-toggle:before,
.accordion-toggle.active:before {
	opacity: 1;
	height: 3px;
	line-height: 2px;
	transform: translateX(10px);
}
.accordion-content {
	font-size: 18px;
	line-height: 24px;
	padding-bottom: 40px;
}
.active .accordion-content {
	display: block;
}
.active .accordion-toggle {
	border-bottom:transparent;
}
/****ACCORDION*****/
.accordion-content {
	display: none;
}
.accordion-content.default {
	display: block;
}

.thank-you-page .fb_iframe_widget {
	opacity: 0;
	animation: fadeInBottom 1s forwards;
	box-shadow: 0 8px 16px rgba(0,0,0,0.3);
}

.thank-you-page {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #fff;
	background: linear-gradient(-45deg, #455173 0%, #21283F 100%);
}
.thanks-title {
	width: 100%;
	max-width: 400px;
	margin:30px auto;
	text-align: center;
	opacity: 0;
	position: relative;
	animation: fadeInTop 1s forwards;
}
.thanks-title img {
	position: absolute;
    right: -20px;
    width: 60px;
    filter: invert(1);
    bottom: -40px;
    transform: rotate(80deg);
    opacity: 0;
    animation: fadeIn 0.5s forwards;
    animation-delay: 2s;
}
.thanks-title h1 {
	margin:0;
	font-size: 45px;
	width: 100%;
}
.thanks-title h1 span {
	color: #9ea9c1;
}
.thanks-title p {
	font-size: 18px;
	font-weight: 500;
	margin:0;
	width: 100%;
}
.click-ai-floater-message {
	background: #fff;
	width: 214px;
	padding:20px;
	font-size: 18px;
	border-radius: 8px;
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
	opacity: 0;
	visibility: hidden;
	transition: 0.4s;
	position: absolute;
	bottom: 45px;
	right: 0;
	color: #333!important;
	transform: translateY(20px);
}

.click-ai-floater:hover .click-ai-floater-message {
	opacity: 1;
	visibility: visible;
	transform: none;
}

.click-ai-floater {
	height: 40px;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #333;
	box-shadow: 0 6px 12px rgba(0,0,0,0.2);
	border-radius: 6px;
	position: fixed;
	bottom: 10px;
	right: 10px;
	color: #fff;
	padding:0 20px;
	opacity: 0;
	animation:fadeInBottom 1s forwards;
	animation-delay: 3s;
	z-index: 5000;
}
.click-ai-floater a {
	color: #fff;
}
.click-ai-floater img {
	height: 13px;
	margin-left: 6px;
}
.click-ai-floater span {
	font-size: 18px;
    position: absolute;
    top: 3px;
    right: 3px;
    transform: rotate(45deg);
    line-height: 10px;
    opacity: 0.6;
}
.click-ai-floater span:hover {
	opacity: 1;
}

.accordion-toggle {
	border-top: solid 1px #efefef;
	padding:20px 0;
	margin-bottom: 0;
	font-weight: 600;
	margin-top: 0;
	position: relative;
	cursor: pointer;
}
.accordion-toggle:first-of-type {
	border-top: none;
}
.accordion-toggle:after {
	width: 6px;
	height: 6px;
	border-bottom: solid 1px #000;
	border-right:solid 1px #000;
	transform: rotate(-45deg);
	content: " ";
	position: absolute;
	transition: all 0.3s;
	right: 5px;
	top: 28px;
}
.accordion-toggle.active:after {
	transform: rotate(45deg)
}
.accordion-content {
	font-size: 18px;
	line-height: 24px;
	padding-bottom: 40px;
}
.accordion-content {
	display: none;
}
.accordion-content.default {
	display: flex;

}
