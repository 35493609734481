/****MAP***/
.design-block-text-map {
	width:100%;
	max-width: 1200px;
	padding:0 30px;
	margin:0px auto;
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	align-items: center;
	position: relative;
}
.layout-1.design-block-text-map {
	flex-direction: column;
	max-width: initial;
}

.design-block-padding-small .layout-1 .design-block-map {
	margin:-30px 0;
	height: 350px;
}

.design-block-padding-medium .layout-1 .design-block-map {
	margin:-65px 0;
	height: 550px;
}

.design-block-padding-big .layout-1 .design-block-map {
	margin:-160px 0;
	height: 700px;
}
.layout-1 .design-block-map {
	width:100%;
	height: 650px;
	margin-bottom: 0px;
	margin-left: 0;
}
.layout-3.design-block-text-map {
	flex-direction: row;
}

.layout-3.design-block-text-map .design-block-map {
	margin-left: 0;
	margin-right: 100px;
}
.layout-1 .design-block-map:before {
	display: none;
}
.design-block-map {
	width: 60%;
	margin-left: 50px;
	position: relative;
}
.design-block-map:before {
	width: 100%;
	content: " ";
	display: block;
	padding-bottom: 100%;
}
.design-block-map iframe {
	width: 100%;
	height: 100%;
	border:none;
	outline: none;
	position: absolute;
	top: 0;
	left: 0;
}

.layout-1 .design-block-map-text {
	display: none;
}

.layout-1.design-block-text-map {
	padding:0;
}
.design-block-map-text {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 40%;
}

@media (max-width: 779px) {
	.design-block-text-map {
		flex-direction: column!important;
	}
	.design-block-map {
		width: 100%;
		margin-left: 0!important;
		margin-right: 0!important;
	}
	.design-block-map-text {
		width: 100%;
	}
	.layout-1 .design-block-map {
		height: 500px;
	}
	.design-block-padding-small .layout-1 .design-block-map {
		margin:-10px 0;
		height: 300px;
	}

	.design-block-padding-medium .layout-1 .design-block-map {
		margin:-30px 0;
		height: 400px;
	}

	.design-block-padding-big .layout-1 .design-block-map {
		margin:-70px 0;
		height: 550px;
	}
	.layout-3 .design-block-map-text {
		margin-top:20px;
	}
}