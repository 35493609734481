.design-block-table {
	display: flex;
	flex-direction: column;
	border-radius: 6px;
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
	width: 100%;
	max-width: 1050px;
	margin:0px auto;
	position: relative;
}

.add-row-button {
	width: 30px;
	height: 30px;
	background:#21283F;
	padding:3px 10px;
	border-radius: 20px;
	color: #fff;
	font-size: 13px;
	text-transform: uppercase;
	font-weight: 500;
	white-space: nowrap;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	transition: all 0.2s;
	overflow: hidden;
	position: absolute;
	bottom: -40px;
	right: 0px;
}
.add-row-button.add-column {
	bottom: auto;
	right: -40px;
	top: 5px;
	z-index: 3;
}
.add-row-button:before {
	content: "+";
	font-size: 16px;
	display: inline-block;
	margin-right: 12px;
	margin-left: 1px;
}
.add-row-button:hover {
	width: 100px;
}
.add-row-button.add-column:hover {
	width: 120px;
}

.block-table-row div {
	padding:0 10px;
	display: flex;
	align-items: center;
}
.block-table-row div .delete-table-row {
	display: none;
}

.block-table-row.thead div {
	position: relative;
}
.block-table-row.thead div .delete-table-row {
	display: flex;
}

.cols-2 .block-table-row div {
	width: 50%
}
.cols-3 .block-table-row div {
	width: 33.333%
}
.cols-4 .block-table-row div {
	width: 25%;
}
.cols-5 .block-table-row div {
	width: 20%;
}
.cols-6 .block-table-row div {
	width: 16.666%;
}
.cols-7 .block-table-row div {
	width: 14.2%;
}
.block-table-row.thead {
	background: #fafafa;
	font-weight: bold;
}
.block-table-row {
	display: flex;
	justify-content: space-between;
	padding:10px 0;
	align-items: center;
	transition: all 0.1s;
	border-bottom: solid 1px #efefef;
	position: relative;
	background: #fff;
}
.edit-table-button {
	position: absolute!important;
	padding:3px 10px!important;
	color: #fff!important;
	font-weight: 500!important;
	top: 9px;
	z-index: 2;
	transition: all 0.2s;
	right: 2px;
	font-size: 13px;
	text-transform: uppercase;
	background: #21283F;
	border-radius: 20px;
	cursor: pointer;
	width: auto!important;
	opacity: 0;
	visibility: hidden;
}

.block-table-row:hover .edit-table-button {
	opacity: 1;
	visibility: visible;
}

.block-table-row:hover:not(.thead) {
	background: #fafafa;
}

.block-table-row:last-child {
	border:none;
}

.thead .delete-table-row {
	top: -40px;
	right: auto;
	left: 10px;
}
.thead .delete-table-row:before {
	height: 30px;
	width: 1px;
	border-left: dashed 1px #ccc;
	content: " ";
	position: absolute;
	left: -10px;
	top: 0;
}
.block-table-row.thead:hover .delete-table-row {
	display: none;
}

.block-table-row.thead div:hover .delete-table-row {
	display: flex;
}
.block-table-row input {
	width: calc(100% - 20px);
	border:none;
	height: calc(100% - 10px);
	outline: none;
	border:solid 1px #efefef;
	border-radius: 4px;
	font-size:16px;
}
.block-table-row div.table-input-wrapper {
	height: 100%;
}
.delete-table-row {
	width: 30px!important;
	height: 30px;
	border-radius: 50%;
	background: #21283F;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute!important;
	right: -40px;
	cursor: pointer;
	top: 5px;
	opacity: 0;
	transition: all 0.2s;
}
.delete-table-row img {
	filter: invert(100%);
	width: 11px;
}
.block-table-row:hover .delete-table-row {
	opacity: 1;
}
@media (max-width: 779px) {
	.design-block-table {
		padding:0 0 0 30px;
		overflow: auto;
	}
	.add-row-button {
		z-index: 2;
		bottom: auto;
		top: -25px;
		right: 120px;
		width: auto;
		font-size: 12px;
	}
	.add-row-button.add-column {
		bottom: auto;
	    right: 3px;
	    top: -25px;
	    z-index: 3;
	}

	.design-block-toolbar-background.edit-table-btn {
		display: flex!important;
		width: 60px;
		margin:0;
		opacity: 1;
		visibility: visible;
		transform: none;
		text-align: center;
		border-radius: 50%;
		margin-left: 0!important;
		top: 4px!important;
	    position: absolute;
	    left: 8px;
	    z-index: 23;
	}
	.delete-table-row {
		opacity: 0.7;
		right: 0;
	}

	.block-table-row div {
		flex-shrink: 0;
	}

	.thead .delete-table-row {
		top: 2px;
		left: 5px;
	}
}