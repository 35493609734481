.explore-btn.button-hidden {
  display: none;
}

.edit-body-mode .explore-btn.button-hidden,
.edit-body-mode .design-block-background-wrapper .explore-btn.button-hidden {
    background: transparent!important;
    color: transparent!important;
    border: dashed 1px rgba(155,168,194,0.4)!important;
    display: inline-block;

}
.edit-body-mode .explore-btn.button-hidden .fa {
  display: none!important;
}

.edit-body-mode .explore-btn.button-hidden:hover {
  opacity: 1;
}
.edit-body-mode .explore-btn.button-hidden:before {
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  content: "ADD BUTTON";
  text-align: center;
    left: 0;
    top: 0;
    color: rgba(155,168,194,0.4)!important

}
.explore-btn .fa {
  margin-left: 6px;
  display: none;

}
.explore-btn.button-arrow .fa {
  display: inline-block;
  font-weight: 300;
}
.explore-btn {
  box-shadow: none;
  margin-top: 20px;
  display: inline-block;
  align-items: center;
  cursor: pointer;
}
.explore-btn:not(.button-arrow) .arrow{
  display: none;
}

.explore-btn {
  padding:15px 30px 15px 30px;
}
.explore-btn.btn-small {
  padding:8px 16px;
}
.explore-btn.btn-large {
  font-size: 17px;
  padding:22px 44px;
}

.explore-btn.button-transparent {
  background: transparent!important;
  box-shadow: none!important;
  border:solid 1px #000;
}

.cover-4 .explore-btn.button-transparent,
.home-7 .explore-btn.button-transparent,
.home-4 .explore-btn.button-transparent,
.text-white .explore-btn.button-transparent {
  border-color: #fff;
}

.explore-btn.button-transparent .arrow {
  background: #fff;
}

.explore-btn.button-transparent .arrow:after {
  border-color: #fff;
}

.explore-btn.btn-circle {
  border-radius: 30px;
}

.explore-btn.btn-square {
  border-radius: 0px;
}
.explore-btn.btn-corner-rounded {
  border-radius: 6px;
}

.explore-btn.button-shadow {
  box-shadow: 0 6px 18px 0 rgba(0,0,0,0.1);
}

.explore-btn.button-center {
  display: table;
  margin-left:auto;
  margin-right: auto;
}

.explore-btn.button-right {
  display: table;
  margin-left: 0;
  margin-right:auto;
}
