.edit-body-mode.blog-modal-active .master-container {
	overflow: hidden;
}

.edit-body-mode.blog-modal-active .blog-hero-img {
	transition: all 0.2s;
}
.edit-body-mode.blog-modal-active .blog-modal-hero-image:hover .blog-hero-img {
	box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}
.blog-edit-container {
  width: 100%;
  margin-bottom: 20px;
  flex-direction: row-reverse;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blog-edit-container .title-badge {
	margin-left: 0;
	margin-top: 11px;
}

#blogPost {
	flex-direction: column;
	padding:0 0px;
	opacity: 0;
	animation: fadeIn 1s forwards;
}
.blog-post-hero {
	width: 100%;
	max-width: 1000px;
	margin:0 auto;
}
#blogPost .blog-post-title h1 {
	font-size: 65px;
}
#blogPost .blog-post-title h2 {
	font-size: 55px;
	margin:0;
}
#blogPost .blog-post-title h3 {
	font-size: 45px;
	margin:0;
}
#blogPost .blog-post-title h4 {
	font-size: 35px;
	margin:0;
}
#blogPost .blog-post-title  {
	margin-bottom: 50px;
	margin-top: 50px;
}
.blog-post-content {
	width: 100%;
	max-width: 800px;
	margin:50px auto;
	font-size: 21px;
	font-size: 20px!important;
	letter-spacing: -0.037rem;
	position: relative;
	-webkit-font-smoothing:antialiased;
}

.blog-post-content img {
	max-width: 100%;
	height: auto;
}

.blog-post-content p {
	margin: 20px 0px!important;
	line-height: 1.58!important;
}

.blog-post-content h2 {
	font-size: 30px!important;
}

#blogPost.blog-post-2 {
	padding:0;
}

#blogPost.blog-post-2 .blog-post-hero {
	height: 70vh;
	min-height: 400px;
	position: relative;
	max-height: inherit;
	display: flex;
	justify-content: flex-start;
	max-width: inherit;
	align-items: flex-end;
}

#blogPost.blog-post-3 .blog-post-hero {
	display: flex;
    justify-content: space-between;
    align-items: center;
}

#blogPost.blog-post-3 .blog-post-title {
    margin-bottom: 50px;
    margin-top: 50px;
    width: calc(100% - 300px);
}
#blogPost.blog-post-3 .blog-post-hero-image {
    width: 300px;
    height: 400px;
    position: relative;
    align-self: flex-start;
    margin-top: 50px;
}
#blogPost.blog-post-3 .blog-post-title h1 {
    font-size: 55px;
}

#blogPost.blog-post-2 .blog-post-hero-image {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
}
#blogPost.blog-post-2 .blog-post-hero-image:before {
	background: linear-gradient(to bottom left, transparent 20%, rgba(0,0,0,0.4) 100%);
	content: " ";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
#blogPost.blog-post-2 .MuiInputBase-root {
	color: #fff;
}
#blogPost.blog-post-2 .blog-post-title {
	justify-content: flex-end;
	color: #fff;
	margin: 0;
    position: relative;
    z-index: 20;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding:40px 80px;
}
#blogPost.blog-post-2 .blog-post-title h1 {
	color: #fff!important;
}
.blog-post-hero-image {
	width: 100%;
	height: 400px;
	position: relative;
}


.blog-post-hero-image:hover .hover-box {
	opacity: 1;
	visibility: visible;
}

.blog-post-hero-image .blog-hero-img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.blog-post-author {
	display: flex;
	align-items: center;
}
.blog-post-author .author-image {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	margin-right: 10px;
	overflow: hidden;
}
.blog-post-author {
	font-size: 14px;
}
.blog-post-author .MuiInputBase-input {
	font-size: 14px;
	padding:0;
	cursor: pointer;
}
.blog-post-author .MuiInputBase-input:hover {
	opacity: 0.7;
}
.blog-post-author strong {
	font-size: 17px;
}
.blog-post-author .author-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
@keyframes descIn {
	0% {opacity: 0; transform: translateX(-400px);}
	100% {opacity: 1; transform: translateX(0);}
}

@keyframes fadeOut {
	0% {opacity: 1;}
	100% {opacity: 0;}
}
.blog_image_small,
.blog_image_medium,
.blog_image_big {
	height: auto;
}

.blog_image_small,
.blog_video_small, 
.blog_map_small,
.blog_music_small {
	display: block;
	width: 50%;
	margin:0 auto;
}
.blog_image_medium,
.blog_video_medium,
.blog_map_medium,
.blog_music_medium {
	display: block;
	width: 70%;
	margin:0 auto;
}
.blog_image_big,
.blog_video_big,
.blog_map_big,
.blog_music_big {
	display: block;
	width: 100%;
	margin:0 auto;
}

.share-blog-post-icons {
	display: flex;
	flex-direction: row;
	margin-left: 0px;
}

 .share-blog-post-icons .SocialMediaShareButton {
	margin:0 4px;
	cursor: pointer;
	transition: 0.2s;
}
 .share-blog-post-icons .SocialMediaShareButton:hover {
 	transform: scale(1.1)
 }
@media (max-width: 779px) {
	.blog-post-author {
		flex-wrap: wrap;
	}
	.share-blog-post-icons {
		margin-left: 0;
		margin-top: 10px;
	}
	#blogPost .blog-post-title h1 {
		font-size: 35px!important;
	}
	#blogPost .blog-post-title h2 {
		font-size: 35px!important;
	}
	#blogPost .blog-post-title h3 {
		font-size: 30px!important;
	}
	#blogPost .blog-post-title h4 {
		font-size: 20px!important;
	}
	.blog-post-hero-image {
		height: auto;
	}
	.blog-post-content {
		margin:0;
		padding:20px;
	}
	#blogPost .blog-post-title {
		margin:20px 0 20px 0!important;
		padding:0 20px!important;
	}
	.blog-3 .blog-card .blog-card-description {
		padding:20px!important;
	}
	.blog-card .blog-card-description h1 {
		font-size: 21px!important;
	}
	.blog-3 .blog-card .blog-card-description p {
		display: none!important;
	}
	.blog-3 .blog-card,
	.blog-2 .blog-card,
	.blog-1 .blog-card {
		width: 100%;
		margin-bottom: 20px!important;
	}
	#blogPost.blogpost-2 .blog-post-title {
		padding:20px;
	}
	.megadraft-editor .sidebar__menu {
		left: -30px!important;
	}

	.blog_image_small,
	.blog_video_small, 
	.blog_map_small,
	.blog_music_small {
		width: 100%;
	}
	.blog_image_medium,
	.blog_video_medium,
	.blog_map_medium,
	.blog_music_medium {
		display: block;
		width: 100%;
		margin:0 auto;
	}
	.blog_image_big,
	.blog_video_big,
	.blog_map_big,
	.blog_music_big {
		width: 100%;
	}

	#blogPost .save-article-button {
		    margin-left: 0px;
    position: absolute;
    top: 39px;
		/*padding-top: 40px;*/
	}
	#blogPost .design-block-toolbar {
		top: 0;
		z-index: 22222!important;
	}
	.edit-body-mode #blogPost {
		padding-top: 50px!important;
	}
	.edit-body-mode #blogPost.blog-post-2 {
		padding-top: 0!important;
	}
	#blogPost.blog-post-3 .blog-post-hero {
	    flex-direction: column;
	}

	#blogPost.blog-post-3 .blog-post-title {
	    margin-bottom: 30px;
	    margin-top: 30px;
	    width: 100%;
	}
	#blogPost.blog-post-3 .blog-post-hero-image {
	    width: 100%;
	    height: 250px;
	    margin-top: 0px;
	}
	#blogPost.blog-post-3 .blog-post-title h1 {
	    font-size: 35px;
	}

}


