body:not(.edit-body-mode) .flex-container,
body:not(.edit-body-mode) .design-block-background-wrapper {
	animation:contentFadeIn 1s forwards;
}


@keyframes contentFadeIn {
	0% {opacity: 1;}
	100% {opacity: 1;}
}
/****************************TABLE OF CONTENTS******************************/

.col-50.map-container {
	opacity: 0;
	animation: mapIn 1s ease forwards;
	animation-delay: 0.3s;
}
.month-container h3 {
	opacity: 0;
	animation: fadeRight 1s ease forwards;
}
.month-container .month-row {
	opacity: 0;
	animation: fadeBottom 1s ease forwards;
	animation-delay: .6s;
}

.month-container:first-of-type .month-row {
	animation-delay: 0.3s;
}

.month-container:before {
		transform: scale(0,0);
		animation: scaleIn 0.3s ease forwards;
		animation-delay: 0.3s;

}

.month-container:after {
	transform: scale(1,0);
	animation: timeLine 0.7s ease forwards;
	animation-delay: 1.3s;
	transform-origin: 0% 0%;
}


@keyframes timeLine {
	0% {transform: scale(1,0);}
	100% {transform: scale(1,1);}
}

@keyframes scaleIn {
	0% {transform: scale(0,0);}
	100% {transform: scale(1,1);}
}


@keyframes mapIn{
	0% {transform: scale(0.95,0.95); opacity: 0;}
	100% {transform: scale(1,1); opacity: 1;}
}

@keyframes fadeRight {
	0% {transform: translateX(20px); opacity: 0}
	100% {transform: translateX(0px); opacity: 1}
}


@keyframes fadeBottom {
	0% {transform: translateY(30px); opacity: 0}
	100% {transform: translateY(0px); opacity: 1}
}

@keyframes fadeTop {
	0% {transform: translateY(-20px); opacity: 0}
	100% {transform: translateY(0px); opacity: 1}
}



/****************************COVER TEXT and LOGO******************************/
.cover-text {
	animation: coverText 0.8s ease forwards;
	animation-delay: 2s;
	opacity: 0;
}

.cover-info img.icon-image {
	opacity: 0;
	margin-left: calc(100% - 55px);
	animation: coverLogo 2s ease forwards;
}



@keyframes coverLogo {
	0% {transform: translateY(10px); opacity: 0;margin-left: calc(100% - 55px);}
	20% {transform: translateY(0px) rotate(0deg); opacity: 1;margin-left: calc(100% - 55px);}
	60% {transform: translateY(0px) rotate(20deg); margin-left: calc(100% - 55px);opacity: 1}
	100% {transform: translateY(0px) rotate(0deg);; margin-left: 0px; opacity: 1}
}


@keyframes coverText {

	0% {transform: translateY(20px); opacity: 0}
	100% {transform: translateY(0px); opacity: 1}
}



/****************************COVER PHOTOS******************************/
.cover-4.no-slideshow .cover-photos .photo-row img {
	animation:none!important;
	opacity: 1!important;
}
.cover-photos .photo-row img {
	opacity: 0;
	animation: coverImages 1.2s ease forwards;
}

/***FIRST ROW***/
.cover-photos .photo-row:first-of-type img:nth-child(1) {
	animation-delay:1.1s;
}
.cover-photos .photo-row:first-of-type img:nth-child(2) {
	animation-delay:1.2s;
}

/**MIDDLE ROW**/
.cover-photos .photo-row img:nth-child(1) {
	animation-delay:1.3s;
}
.cover-photos .photo-row img:nth-child(2) {
	animation-delay:1.4s;
}

/***LAST ROW***/
.cover-photos .photo-row:last-of-type img:nth-child(1) {
	animation-delay:1.5s;
}
.cover-photos .photo-row:last-of-type img:nth-child(2) {
	animation-delay:1.6s;
}
.cover-photos .photo-row:last-of-type img:nth-child(3) {
	animation-delay:1.7s;
}


.cover-2 .cover-photos {
	animation:coverImages2 10s ease forwards;
}
.cover-2 .cover-text {
	opacity: 0;
	animation: fadeBottom 1s ease forwards;
	animation-delay: 0.6s;
	max-width: 600px;
}
.cover-2 .cover-info img.icon-image {
	opacity: 0;
	animation: fadeTop 1s ease forwards;
	margin-left: 0px;
	margin-right: 0px;
}



@keyframes coverImages2 {
	0% {transform: translateX(0px);}
	100% {transform: translateX(-200px);}
}
@keyframes coverImages {
	0% {transform: translateX(30px) scale(0.9,0.9); opacity: 0}
	100% {transform: translateX(0px) scale(1,1); opacity: 1}
}


/******PAGE TRANSITIONS**********/


/****ANIMATIONS CONTROLLERS****/

	.article-title {
		opacity: 0;
		animation: fadeIn 1s ease forwards;
		animation-delay: 0.6s;
		padding-bottom: 20px;
	}

	.article-content,
	.contact-content,
	.contact-form,
	.blog-text-content {
		opacity: 0;
		animation: fadeIn 1s ease forwards;
	}

	.article-image {
		opacity: 0;
		animation: fadeIn 1s ease forwards;
		animation-delay: 0.2s;
	}
	.article-stats {
		opacity: 0;
		background: #fff;
		animation: fadeInTop 1s ease forwards;
	animation-delay: 0.4s;
	}

	.animate-out .page-loader {
		opacity: 1;
		visibility: visible;
		transform: scale(1.4,1.4) translateY(0px);
		transition-delay: 0.6s;
	}

	.animate-out .flex-container {
		animation: fadeOutDown 1s forwards;
	}


	.animate-out .article-content {
		animation: fadeOutUp .6s forwards;
	}
	.animate-out .article-image {
		animation: fadeOutDown .8s forwards;

	}

@keyframes fadeIn {
	0% {opacity: 0}
	100% {opacity: 1}
}
@keyframes fadeInNew {
	0% {opacity: 0}
	100% {opacity: 1}
}
@keyframes fadeInFromRight {
	0% {opacity: 0; transform:translateY(200px);}
	100% {opacity: 1; transform:translateY(0px);}
}

@keyframes fadeInLeft {
	0% {opacity: 0; transform:translateX(-50px);}
	100% {opacity: 1; transform:translateY(0px);}
}
@keyframes fadeInRight {
	0% {opacity: 0; transform:translateX(50px);}
	100% {opacity: 1; transform:translateY(0px);}
}
@keyframes fadeInTop {
	0% {transform: translateY(-20px); opacity: 0}
	100% {transform: translateY(0px); opacity: 1}
}
@keyframes fadeInBottom {
	0% {transform: translateY(20px); opacity: 0}
	100% {transform: translateY(0px); opacity: 1}
}

@keyframes fadeInPage {
	0% {transform: translateY(20px); opacity: 0}
	100% {transform: translateY(0px); opacity: 1}
}

@keyframes shrinkDown {
	0% {transform: scale(1,1);}
	50% {transform: scale(0.9,0.9);}
	100% {transform: scale(1,1);}
}

@keyframes pulseLogo {
	0% {box-shadow: 0 0 0 0 rgba(0,0,0,0)}
	50% {box-shadow: 0 0 0 10px rgba(0,0,0,0.1)}
	100% {box-shadow: 0 0 0 0px rgba(0,0,0,0)}
}


@keyframes imageIn {
	0% {transform: translateY(-20px) scale(1,1); opacity: 0;}
	100% {transform: translateY(0px) scale(1,1); opacity: 1;}
}

@keyframes fadeOutDown {
	0% {transform: translateY(0px); opacity: 1}
	100% {transform: translateY(100px); opacity: 0}
}
@keyframes fadeOutUp {
	0% {transform: translateY(0px); opacity: 1}
	100% {transform: translateY(-100px); opacity: 0}
}
@keyframes fadeOutRight {
	0% {transform: translateX(0px); opacity: 1}
	100% {transform: translateX(50px); opacity: 0}
}
@keyframes fadeOutLeft {
	0% {transform: translateX(0px); opacity: 1}
	100% {transform: translateX(-50px); opacity: 0}
}

.fadeOutDown {
	animation: fadeOutDown .6s forwards;
}
.fadeOutUp {
	animation: fadeOutUp .6s forwards;
}

.fadeOutRight {
	animation: fadeOutRight .6s forwards;
}
.article-content.fadeOutDown {
	animation-delay: 0.1s;
}
.cover-info.fadeOutDown {
	animation-delay: 0.2s;
}



























