@media (min-width: 779px) {
	body .nav-bar {
		transition:0.2s;
	}


	.home-4:not(.nav-2):not(.nav-6) .home-photos,
	.home-5:not(.nav-2):not(.nav-6) .home-photos,
	.home-7:not(.nav-2):not(.nav-6) .home-photos,
	.contact-4:not(.nav-2):not(.nav-6) .contact-image,
	.cover-4:not(.nav-2):not(.nav-6) .collage-photos {
		margin-top: -80px;
	    height: calc(100% + 80px);
	}

	.home-4.nav-bar-medium:not(.nav-2):not(.nav-6) .home-photos,
	.home-5.nav-bar-medium:not(.nav-2):not(.nav-6) .home-photos,
	.home-7.nav-bar-medium:not(.nav-2):not(.nav-6) .home-photos,
	.contact-4.nav-bar-medium:not(.nav-2):not(.nav-6) .contact-image,
	.cover-4.nav-bar-medium:not(.nav-2):not(.nav-6) .collage-photos  {
		margin-top: -110px;
	    height: calc(100% + 110px);
	}

	.home-4.nav-bar-large:not(.nav-2):not(.nav-6) .home-photos,
	.home-5.nav-bar-large:not(.nav-2):not(.nav-6) .home-photos,
	.home-7.nav-bar-large:not(.nav-2):not(.nav-6) .home-photos,
	.contact-4.nav-bar-large:not(.nav-2):not(.nav-6) .contact-image,
	.cover-4.nav-bar-large:not(.nav-2):not(.nav-6) .collage-photos {
		margin-top: -140px;
	    height: calc(100% + 140px);
	}

	.home-7 .home-info {
		padding-bottom: 80px;
	}
	.home-5 #homePage .home-info {
		margin-bottom: 80px;
	}


	.home-4:not(.nav-2):not(.nav-6) .nav-bar.nav-transparent,
	.home-5:not(.nav-2):not(.nav-6) .nav-bar.nav-transparent,
	.home-7:not(.nav-2):not(.nav-6) .nav-bar.nav-transparent,
	.article-3:not(.nav-2):not(.nav-6) .nav-bar.nav-transparent,
	.article-8:not(.nav-2):not(.nav-6) .nav-bar.nav-transparent,
	.contact-4:not(.nav-2):not(.nav-6) .nav-bar.nav-transparent,
	.contact-1:not(.nav-2):not(.nav-6) .nav-bar.nav-transparent,
	.menu-5:not(.nav-2):not(.nav-6) .nav-bar.nav-transparent,
	.cover-4:not(.nav-2):not(.nav-6) .nav-bar.nav-transparent {
		color: #fff!important;
		background: transparent!important;
	}

	.home-4.nav-3 .nav-bar.nav-transparent.active,
	.home-5.nav-3 .nav-bar.nav-transparent.active,
	.home-7.nav-3 .nav-bar.nav-transparent.active,
	.article-3.nav-3 .nav-bar.nav-transparent.active,
	.article-8.nav-3 .nav-bar.nav-transparent.active,
	.contact-4.nav-3 .nav-bar.nav-transparent.active,
	.contact-1.nav-3 .nav-bar.nav-transparent.active {
		color: #333!important;
		background: #fff!important;
	}

	.home-4:not(.nav-2):not(.nav-6) .home-photos:after,
	.home-5:not(.nav-2):not(.nav-6) .home-photos:after,
	.home-7:not(.nav-2):not(.nav-6) .home-photos:after {
		content: " ";
	    width: 100%;
	    height: 50%;
	    position: absolute;
	    top: 0;
	    left: 0;
	    z-index: 1;
		background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.2) 93%);
		mix-blend-mode: multiply;
	}
	.article-3.nav-bar-medium:not(.nav-2):not(.nav-6) #articleContent,
	.article-8.nav-bar-medium:not(.nav-2):not(.nav-6) #articleContent,
	.contact-1.nav-bar-medium:not(.nav-2):not(.nav-6) #contactContent,
	.menu-5.nav-bar-medium:not(.nav-2):not(.nav-6) .menu-title {
		margin-top: -110px;
	}
	.article-3.nav-bar-large:not(.nav-2):not(.nav-6) #articleContent,
	.article-8.nav-bar-large:not(.nav-2):not(.nav-6) #articleContent,
	.contact-1.nav-bar-large:not(.nav-2):not(.nav-6) #contactContent,
	.menu-5.nav-bar-large:not(.nav-2):not(.nav-6) .menu-title {
		margin-top: -140px;
	}
	.article-3:not(.nav-2):not(.nav-6) #articleContent,
	.article-8:not(.nav-2):not(.nav-6) #articleContent,
	.contact-1:not(.nav-2):not(.nav-6) #contactContent,
	.menu-5:not(.nav-2):not(.nav-6) .menu-title {
		margin-top: -80px;
	}
	.article-3:not(.nav-2):not(.nav-6) #articleContent .design-block-toolbar,
	.article-8:not(.nav-2):not(.nav-6) #articleContent .design-block-toolbar,
	.contact-1:not(.nav-2):not(.nav-6) #contactContent .design-block-toolbar {
		top: 90px;
	}
	.article-3.nav-bar-medium:not(.nav-2):not(.nav-6) #articleContent .design-block-toolbar,
	.article-8.nav-bar-medium:not(.nav-2):not(.nav-6) #articleContent .design-block-toolbar,
	.contact-1.nav-bar-medium:not(.nav-2):not(.nav-6) #contactContent .design-block-toolbar {
		top:120px;
	}
	.article-3.nav-bar-large:not(.nav-2):not(.nav-6) #articleContent .design-block-toolbar,
	.article-8.nav-bar-large:not(.nav-2):not(.nav-6) #articleContent .design-block-toolbar,
	.contact-1.nav-bar-large:not(.nav-2):not(.nav-6) #contactContent .design-block-toolbar {
		top: 150px;
	}

	.article-3:not(.nav-2):not(.nav-6) .article-image:after,
	.article-8:not(.nav-2):not(.nav-6) .article-image:after,
	.contact-1:not(.nav-2):not(.nav-6) .contact-image:after {
		content: " ";
	    width: 100%;
	    height: 50%;
	    position: absolute;
	    top: 0;
	    left: 0;
	    z-index: 1;
		background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.2) 93%);
		mix-blend-mode: multiply;
	}
}
.nav-logo-wrapper {
	display: flex;
	align-items: center;
	flex-direction: column;
	align-items: flex-start;
	position: relative;
	z-index: 50;
	cursor: pointer;
}

.nav-6 .nav-logo-wrapper,
.nav-7 .nav-logo-wrapper {
	align-items: center;
}
.nav-3 .profile-stats .socicon {
	font-size: 22px;
	margin:0 13px;
}
.profile-stats {
	display: flex;
	flex-direction: row;
}
.edit-body-mode .profile-stats:hover {
	cursor: pointer;
}
.profile-stats .socicon {
	height: 15px;
	margin:0 8px;
	font-size: inherit;
	position: relative;
	z-index: 2;
}

.profile-stats a {
	position: relative;
}

.profile-stats a:after {
	width: 20px;
    height: 20px;
    background: rgba(0,0,0,0.1);
    border-radius: 50%;
    content: " ";
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
    transition: 0.2s;
    opacity: 0;
    box-shadow: 0 0 0 0 rgba(0,0,0,0.1);
}

.profile-stats a:hover:after {
	opacity: 1;
	box-shadow: 0 0 0 10px rgba(0,0,0,0.1);
}
.edit-body-mode .profile-stats:before {
	width: 60px;
	text-align: center;
	position: absolute;
	left: calc(50% - 30px);
	background: linear-gradient(-45deg, #3c4665 0%, #21283F 100%);
	box-shadow: 0 4px 8px rgba(0,0,0,0.1);
	border-radius: 6px;
	content: "Edit links";
	padding:6px 0;
	bottom: -30px;
	font-family: Source Sans Pro!important;
	opacity: 0;
	visibility: hidden;
	transform: translateY(5px);
	font-size: 11px;
	color: #fff;
	transition: 0.2s;
}
.edit-body-mode .profile-stats:hover:before{
	transform: none;
	visibility: visible;
	opacity: 1;
}

.nav-2 #cartBtn {
	margin-top: 20px;
	margin-left: 0;
	width: 27px;
}

.nav-2 #cartBtn span {
	left: 15px;
	right: auto;
}
#cartBtn {
	position: relative;
	transform: translateY(2px);
	z-index: 4;
	cursor: pointer;
}
#cartBtn img {
	width: 18px;
	margin-right: 0;
}

.text-white #cartBtn img {
	filter: invert(1);
}

#cartBtn:hover img {
	transform:none;
}

#cartBtn span {
	background: #63CAA1;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    position: absolute;
    border: solid 2px transparent;
    right: -15px;
    top: -9px;
    font-size: 10px;
    opacity: 1!important;
    font-family: Source Sans Pro;
}

.nav-bar {
	padding:0px 30px;
	height: 80px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	z-index: 1000;
	background: #fff;
	position: absolute;
	top: 0;
}
.nav-bar a {
	color: inherit;
}
.nav-bar-fixed-top .nav-bar {
	position: fixed;
}
.nav-1 .nav-bar {
	/*animation: navOneIn 1s forwards;*/
}
@keyframes navOneIn {
	from {top: -80px; opacity: 0}
	to {top:0; opacity: 1}
}

.nav-bar .burger,
.nav-bar #burger  {
	display: none;
	z-index: 200;
}

.nav-bar.text-white a {
	color: #fff;
}
.nav-bar.text-white .nav-links a:before {
	background: #fff;
}
.nav-bar.text-white .burger {
	border-color: #fff;
}
.nav-bar.text-white .burger.close {
	border-color: transparent;
}
.nav-bar.text-white .profile-stats a {
	color: #fff;
}

.nav-3 .nav-bar.text-white .profile-stats a {
	color: #fff;
}
.nav-bar.text-white .burger:before,
.nav-bar.text-white .burger:after {
	background: #fff;
}
.nav-bar .nav-logo {
	margin-left: 0;
	object-fit: contain;
	height: 35px;
	z-index: 2;
	position: relative;
	transform-origin: 0 50%;
}

.nav-6 .nav-bar .nav-logo,
.nav-7 .nav-bar .nav-logo {
	transform-origin: 50% 50%;
}

.nav-bar .nav-logo.aspect-circle {
	width: 40px!important;
	height: 40px!important;
	object-fit: cover;
}
.nav-bar .nav-logo.aspect-ratio {
	width: auto;
	height: 35px;
	border-radius: 0;
}
.nav-bar .nav-logo.hidden {
	display: none;
}

.nav-bar-logo-hidden .nav-logo {
	display: none;
}

.montserrat-font-nav .master-container .nav-bar .nav-title,
.raleway-font-nav .master-container .nav-bar .nav-title,
.default-font-nav .master-container .nav-bar .nav-title {
	font-weight: 600;
}
.nav-bar .nav-title {
	font-weight: normal;
	position: relative;
	z-index: 2;

}
.nav-bar .nav-logo {
	height: 35px;
}

.nav-4 .nav-bar .nav-links {
	width: 100%;
	position: absolute;
	text-align: center;
	left: 0;
}

.nav-links-dropdown {
	position: absolute;
	top: 100%;
	font-size: 16px;
	background: inherit;
	box-shadow: 0 5px 10px rgba(0,0,0,0.1);
	left: -19px;
	width: 180px;
	display: none;
	z-index: 2000;
	padding:5px 0;
	transform: translateY(4px);
}

.nav-5 .nav-bar .nav-links a .nav-links-dropdown {
	top: 80%;
}

.nav-3 .nav-links-dropdown {
	top: 0;
	transform: none!important;
	animation:none!important;
	position: relative;
	display: block;
	margin-top: 10px;
}
.nav-3 .nav-bar .nav-links .nav-links-dropdown a {
	padding:6px 0 6px 19px!important;
	border:none!important;
}
.nav-3 .nav-bar .nav-links .nav-links-dropdown a:before {
	content: "- ";
    display: inline;
    position: relative;
    opacity: 1;
    height: auto;
    width: auto;
    left: auto;
    bottom: auto;
    margin-right: 10px;
}
.nav-bar .nav-links a:hover .nav-links-dropdown {
	display: block;
	animation:fadeIn .3s forwards;
	animation-delay: 0!important;
	transform: none;
}

.nav-bar .nav-links .nav-links-dropdown a {
	margin:0!important;
	padding:10px 20px!important;
	width: 100%;
	font-size: 16px!important;
	white-space: normal;
	font-weight: normal!important;
	float: left;
	text-align: left!important;
	animation-delay: 0!important;
	transition-delay: 0!important;
	border-bottom: solid 1px rgba(0,0,0,0.1);
}

.nav-bar .nav-links .nav-links-dropdown a:after {
	display: none!important;
}
.nav-bar .nav-links .nav-links-dropdown a:last-child {
	border-bottom: none;
}
.nav-bar .nav-links .nav-links-dropdown a:before {
	display: none;
}

.nav-bar .nav-links {
	font-size: 15px;
	text-transform: none;
	white-space: nowrap;
	background: inherit;
}
.nav-bar .nav-links a {
	margin:0 1.2vw;
	position: relative;
	opacity: 0.7;
	cursor: pointer;
	background: inherit;
}
.nav-bar .nav-links .fa-shopping-cart {
	font-size: 18px;
}
.nav-bar .nav-links a strong {
	font-weight: normal;
	position: relative;
}

.nav-bar .nav-links a strong .fa {
	font-size: 12px;
	margin-left: 6px;
}
.nav-bar .nav-links a.active strong {
	font-weight: 600;
}


.nav-bar .nav-links a.active {
	opacity: 1;
	font-weight: 600;
}
.nav-bar .nav-links a span {
	display: none;
	font-weight: bold;
	margin-right: 3px;
	opacity: 0.2;
}
.nav-bar.show-nav-border {
	border-bottom: solid 1px rgba(0,0,0,0.1);
}
.nav-bar.show-nav-shadow {
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
}
.nav-bar.show-nav-numbers .nav-links a span {
	display: inline-block;
}
.nav-bar .nav-links a:hover {
	opacity: 1;
}
.nav-3 .nav-bar .nav-links a:hover {
	z-index: 4;
}
.nav-bar .nav-links a.home {
	font-weight: 600;
	position: relative;
	margin-left: 20px;
}
.nav-bar .nav-links a:before {
	    height: 11px;
    width: 0%;
    bottom: -3px;
    transition: all 0.2s;
    white-space: nowrap;
    left: 50%;
    /* background: #000; */
    content: "______________________________________";
    position: absolute;
    overflow: hidden;
    line-height: 0;
    letter-spacing: -1px;
    font-weight: 300!important;
    font-family: Source Sans Pro!important;
}

.nav-bar .nav-links a.active:before {
	font-weight: bold!important;
}
.nav-bar .nav-links a:hover:before,
.nav-bar .nav-links a.active:before {
	left: 0%;
	width: 100%;
}
.nav-bar .left {
	display: flex;
	align-items: center;
	/*width: 100%;*/
	position: relative;
	justify-content: space-between;
}

.nav-bar .right {
	display: flex;
	align-items: center;
	position: relative;
	z-index: 10;
}


.nav-1 .nav-bar .nav-links {
	margin-left: auto;
}
.nav-1 .nav-bar .right {
	
}

.nav-bar .btn {
	background: #474646;
	color: #fff;
	padding:10px 15px;
	margin-left: 25px;
}
.nav-bar .btn .socicon {
	margin-right: 5px;
	transform: translateY(2px);
}

.nav-bar .btn .arrow {
	background: #fff;
}

.nav-bar .btn .arrow:after {
	    border-top: solid 1px #fff;
    border-right: solid 1px #fff;

}





.click-logo {
	width: 100px;
}

/***NAVBAR WITH BURGER***/
.nav-3 .nav-bar .burger,
.nav-3 .nav-bar #burger {
	display: block;
	margin-right: 30px;
}

.nav-3 .nav-bar .nav-links.active {
	opacity: 1;
	visibility: visible;
	transform: none;
}


.nav-3 .nav-bar .nav-links {
	position: fixed!important;
	top: 80px;
	left: 0;
	z-index: 44;
	background: #fff;
	height: calc(100% - 80px)!important;
	width: 320px;
	display: flex;
	flex-direction: column;
	font-size: 20px!important;
	text-align: left!important;
	align-items: flex-start;
	padding:100px 50px;
	text-transform: none;
	justify-content: flex-start;
	opacity: 0;
	visibility: hidden;
	transition: all 0.5s;
	overflow:auto;
	margin-left: 0;
    transform: translateX(-300px);
    background: inherit;
}

.nav-3 .nav-bar .nav-links a:before {
	display: none;
}
.nav-3 .nav-bar .nav-links a:hover,
.nav-3 .nav-bar .nav-links a.active {
	opacity: 1;
}
.nav-3 .nav-bar .nav-links.active a {
	transform: translateX(0);
}
.nav-3 .nav-bar .nav-links a {
	transition: all 0.6s;
	margin:0 0 20px 0;
	transform: translateX(-70px);
}

.nav-3 .nav-bar .nav-links a span {
	display: none;
}
.nav-3 .left {
	justify-content: flex-start!important;
	background: inherit;
}
/***NAVBAR WITH BURGER END***/


.burger {
	width: 22px;
	height: 16px;
	border-radius:0px;
	border-top: solid 2px #000;
	border-bottom: solid 2px #000;
	position: relative;
	cursor: pointer;
	transition: all 0.2s ease;
	overflow: visible;
}

.nav-bar .fa-bars {
	font-size: 24px;
    font-weight: 200;
    margin-right: 15px;
    cursor: pointer;
    z-index: 200;
    transition: 0.3s;
}

.nav-bar .fa-bars.close-burger-nav {
	transform:rotate(90deg);
}

.nav-bar .fa-bars.close-burger-nav:before {
	content: "\f00d";
}

.nav-bar .fa-bars:hover {
	opacity: 0.75;
}


.burger:after {
	width: 2px;
	height: 22px;
	transition: all 0.1s ease;
	content: " ";
	position: absolute;
	left: calc(50% - 1px);
	top: -5px;
	background: #000;
	opacity: 0;
}

.burger:before {
	position: absolute;
	left: 0px;
	transition: all 0.1s;
	width: 100%;
	height: 2px;
	content: " ";
	background: #000;
	top: 50%;
	margin-top: -1px;
}
.burger.close {
	transform: rotate(45deg) scale(1.1,1.1);
	border-color: transparent;
}
.burger.close:after {
	opacity: 1;
}

.arrow {
	height: 1px;
	width: 10px;
	display: inline-block;
	margin-right: 9px;
	background: #000;
	position: relative;
	transition: all 0.2s ease;
}
.arrow:after {
	width: 6px;
	height: 6px;
	content: " ";
	position: absolute;
	border-top:solid 1px #000;
	border-right: solid 1px #000;
	right: 0px;
	transform: rotate(45deg);
    top: -3px;

}

.nav-2 .nav-bar-edit-tools {
	    justify-content: flex-end;
    flex-direction: column;
    white-space: nowrap;
    align-items: flex-end;
}
.nav-2 .nav-bar-edit-tools button {
	border-radius: 20px;
	margin-bottom: 3px;
}
.nav-bar-edit-tools {
	width: 100%;
	position: absolute;
	bottom: 12px;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	z-index: 10;
	height: 1px;
	opacity: 0;
	transition: 0.2s;
	visibility: hidden;
	transform: translateY(-2px);
	left: 0;
}

.edit-body-mode .nav-bar:hover {
	box-shadow: 0 6px 12px rgba(0,0,0,0.1),  0 0 0 5px rgba(159,172,198,0.3);
	z-index: 4000;
}

.nav-bar:hover .nav-bar-edit-tools {
	opacity: 1;
	visibility: visible;
	transform: none;
}
.nav-bar-edit-tools button {
	padding:8px 12px;
	border-radius: 6px 0 0 6px;
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
	margin:0 0px;
	background:linear-gradient(0deg, #3c4665 0%, #21283F 100%);
	border:none;
	outline: none;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 12px;
	transition: 0.1s;
}

.nav-bar-edit-tools button:last-child {
	border-radius: 0px 6px 6px 0px;
	border-left: solid 1px #000;
}

.nav-bar-edit-tools button:hover {
	background:linear-gradient(0deg, #515F8A 0%, #515F8A 100%);
}

.nav-bar-edit-tools button img {
	width: 14px;
	height: 14px;
	object-fit: contain;
	filter: invert(1);
	margin-right: 4px;
}
@media (min-width: 779px) {
	.profile-stats {
		margin-left: 10px;
	}
	.nav-7 .nav-bar  {
		padding:0 15px;
	}
	.nav-7 .nav-bar .nav-logo-wrapper {
		flex-direction: column;
	}
	.nav-7 .nav-bar .nav-title {
		margin:0 5px;
	}
	.nav-7 .nav-bar .nav-logo {
		margin: 0 5px;
	}
	.nav-7 .nav-bar .left {
		width: 100%;
	    position: absolute;
	    text-align: center;
	    justify-content: center;
	    left: 0;
	}
	.nav-7 .nav-bar .nav-links a {
		margin:0 10px 0 10px;
	}
	.nav-7 .nav-bar .nav-links {
		position: relative;
		z-index: 6;
	}
	.nav-7 .nav-bar .nav-links {
		margin-left: 0;
	}
	.nav-7 .nav-bar .right {
	}
	.nav-5 .nav-bar .left {
		
	}

	.nav-5 .nav-bar .nav-links {
		height: 100%;
		display: flex;
		margin-left: auto;

	}

	.nav-5 .nav-bar .nav-links a {
		height: 100%;
		display: flex;
		align-items: center;
		flex-shrink: 0;
		margin:0;
		padding:0 20px;
		border-right:solid 1px rgba(0,0,0,0.05);
	}
	.nav-5 #cartBtn span {
		top: 13px;
    	right: 4px;
	}

	.nav-5 .nav-bar.text-white .nav-links a {
		border-right:solid 1px rgba(255,255,255,0.1);
	}
	.nav-5 .nav-bar.text-white .nav-links a:first-of-type {
		border-left:solid 1px rgba(255,255,255,0.1);
	}
	.nav-5 .nav-bar .right,
	.nav-5 .nav-bar .right .profile-stats {
		height: 100%;
	}
	.nav-5 .nav-bar .right .profile-stats {
		padding:0 10px;
	}
	.nav-5 .nav-bar .right #cartBtn {
		height: 100%;
	    display: flex;
	    justify-content: center;
	    align-items: center;
	    margin: 0;
	    padding: 0 20px;
	    border-right: solid 1px rgba(0,0,0,0.1);
	}
	.nav-5 .nav-bar.text-white .right #cartBtn {
		border-right: solid 1px rgba(255,255,255,0.1);
	}
	.nav-5 .nav-bar .right .profile-stats a {
		height: 100%;
	}
	.nav-5 .nav-bar .right .profile-stats a:hover {
		opacity: 0.9;
	}
	.nav-5 .nav-bar .right .profile-stats a .socicon {
		display: flex;
		justify-content: center;
		align-items: center;
		padding:0 8px;
		margin:0;
		height: 100%;
	}
	/*.nav-5 .nav-bar .right .profile-stats a .socicon.socicon-facebook {
		background: #3b5998;
	}
	.nav-5 .nav-bar .right .profile-stats a .socicon.socicon-instagram {
		background: #C13584;
	}
	.nav-5 .nav-bar .right .profile-stats a .socicon.socicon-dribbble {
		background: #ea4c89;
	}*/
	.nav-5 .nav-bar {
		border-bottom:solid 1px rgba(0,0,0,0.05);
		padding:0 0 0 20px;
	}
	.nav-5 .nav-bar .nav-links a:before {
		display: none;
	}
	.nav-bar.show-home-icon .nav-links a .fa-home {
		font-size: 16px;
		padding:0 0px;
	}
	.nav-2 .nav-bar.show-home-icon .nav-links a .fa-home {
		padding:0;
	}
	.nav-5 .nav-bar .nav-links a:first-of-type,
	.nav-bar.show-home-icon .nav-links a:first-of-type {
		/*border-left:solid 1px rgba(0,0,0,0.05);
		font-size: 0;
		padding:0 30px;*/
	}
	
	
	.nav-2 .master-container {
		padding-top: 0;
	}
	.nav-2 .nav-bar .nav-logo {
		height: auto!important;
		width: 100%!important;
		max-width: 100px;
		margin-left: 0;
		transition: all 0.3s;
	}
	.nav-2 .nav-bar .nav-logo-wrapper {
		width: 100%;
		padding:0 15px 0 15px;
		flex-direction: column;
		justify-content: flex-start;
		transition: all 0.3s;
	}
	.nav-2 .nav-bar .nav-logo-wrapper {
		opacity: 0;
	}
	.nav-2 .nav-bar:hover .nav-logo-wrapper {
		margin-left: 0px;
		padding-left: 0;
		opacity: 1;
	}
	.nav-2 .nav-bar:hover .nav-logo {
		margin-bottom: 10px;
	}
	.nav-2-visible .master-container {
		padding-left: 200px;
	}
	.nav-2-visible .cover-info {
		padding-left: 0;
	}
	.nav-2 .profile-stats,
	.nav-2-visible .profile-stats {
		flex-direction: column;
		    width: 80px;
	    justify-content: center;
	    align-items: center;
	    margin-left: 0;
	}
	.nav-2 .nav-bar .fa-bars.close-burger-nav:before {
		content: "\f0c9"!important;
	}
	.nav-2 .nav-bar .fa-bars.close-burger-nav {
		transform: none!important;
	}
	.nav-2 .profile-stats .socicon,
	.nav-2-visible .profile-stats .socicon {
		margin:8px 0;
		display: block;
	}
	.nav-2 #coverPage,
	.nav-2-visible #coverPage {
		height: 100%;
		margin-top: 0;
	}
	.nav-2.cover-1 .cover-photos,
	.nav-2-visible.cover-1 .cover-photos {
		height: 100vh;
	}
	.nav-2.cover-5 .flex-container.hero-image,
	.nav-2-visible.cover-5 .flex-container.hero-image {
		margin-top: 0;
	}
	.nav-2 .nav-bar .burger, 
	.nav-2 .nav-bar #burger {
		display: block;
		position: absolute;
		top: 0;
	}

	.nav-2 .nav-bar:hover #burger {
		opacity: 0;
		visibility: hidden;
	}
	.nav-2 .master-container {
		padding-left: 80px;
	}
	.nav-2 .nav-bar .nav-logo-wrapper,
	.nav-2-visible .nav-bar .nav-logo-wrapper {
		align-self: flex-start;
	}
	.nav-2 .nav-bar,
	.nav-2-visible .nav-bar {
		padding:20px 0;
		width: 80px;
		height: 100%;
		border-right: solid 1px rgba(0,0,0,0.05);
		left: 0;
		flex-direction: column;
		align-items: flex-start;
		transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
		position: fixed!important;
		animation: navTwoIn 1s forwards;
		box-shadow: 8px 0 16px rgba(0,0,0,0);
	}
	.nav-2.edit-body-mode .nav-bar {
		height: calc(100% - 50px);
		position: fixed!important;
		top: 50px!important;
	}

	.nav-2-visible .nav-bar {
		width: 200px;
		border:none;
	}
	@keyframes navTwoIn {
		0% {transform: translateX(-80px);}
		100% {transform: none;}
	}
	.nav-2 .nav-bar .nav-title,
	.nav-2-visible .nav-bar .nav-title {
		margin-left: 0;
		font-size: 100%!important;
		transition: all 0.2s;
		width: 200px;
	}
	.nav-2 .nav-bar:hover .nav-title {
		opacity: 1;
		margin-left: 0px;
	}
	
	.nav-2 .nav-bar .left,
	.nav-2-visible .nav-bar .left {
		flex-direction: column;
		width: 100%;
		/*overflow: hidden;*/
		align-items: flex-start;
		padding-left: 30px;
	}
	.nav-2 .nav-bar .right,
	.nav-2-visible .nav-bar .right {
		flex-direction: column;
	}
	.nav-2 .nav-bar .nav-links,
	.nav-2-visible .nav-bar .nav-links {
		width: calc(100% - 35px);
		margin:20px 0 0 0;
		transform: translateX(30px);
	}
	.nav-2 .nav-bar .nav-links a,
	.nav-2-visible .nav-bar .nav-links a {
		margin:10px 0;
		padding:0px 0;
		float: left;
		width: 100%;
		white-space: nowrap;
	}

	.nav-2 .nav-bar .nav-links a {
		transition: all 0.2s;
		opacity: 0.5;
		display: flex;
	}
	.nav-2 .nav-bar .nav-links a:hover,
	.nav-2 .nav-bar .nav-links a.active {
		opacity: 1;
	}
	.nav-2 .nav-bar:hover .nav-links a {
		color: inherit;
	}
	.nav-2 .nav-bar .nav-links a span{
		color: inherit;
		opacity: 0.6;
	}
	.nav-2-visible .nav-bar .nav-links a span {
		display: none;
	}
	.nav-2 .nav-bar .nav-links a:before,
	.nav-2-visible .nav-bar .nav-links a:before {
		display: none;
	}

	.nav-2 .nav-bar .nav-links a span {
		margin-right: 40px;
		display: block;
		transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
		font-size: inherit!important;
		transform: none!important;
	}

	.nav-2 .nav-bar .nav-links a strong {
		display: block;
		white-space: nowrap;
		width:135px;
		opacity: 0;
		flex-shrink: 0;
	}

	.nav-2 .nav-bar:hover .nav-links a strong {
		white-space: normal;
		opacity: 1;
	}
	.nav-2 .nav-bar.text-white .nav-links a span {
		color: #fff;
	}

	.nav-2 .nav-bar.text-white:hover .nav-links a {
		color: #fff;
	}
	.nav-2 .nav-bar {
		z-index: 2000;
		overflow: hidden;
	}
	.nav-2 .nav-bar:hover {
		width: 200px;
		/*box-shadow: 8px 0 16px rgba(0,0,0,0.1);*/
	}

	.nav-2 #homePage {
		height: calc(100vh - 0px);
	}
	.nav-2.edit-body-mode #homePage {
		height: calc(100vh - 50px);
	}

	.home-8.nav-2 .home-photos {
		border:none;
	}

	.nav-2 .nav-bar:hover .nav-links a span {
		margin-right: 10px;
	}
	
	.nav-2 .nav-bar:hover .account-button,
	.nav-2 .nav-bar:hover .btn {
		font-size: 12px;
	}
	.nav-2 .nav-bar .btn .socicon {
		font-size: 12px;
	}

	.nav-2.article-6 #articleContent {
		padding:0px 80px 0px 80px;
		margin-top: 0;
	}
	.nav-2.article-5 #articleContent {
		padding:0 0 0 30px;
	}
	.nav-2.article-4 #articleContent {
		padding:0 30px 0 0px;
	}
	.nav-2 .navigation-arrow-wrapper {
		left: 80px;
		z-index: 4;
	}

	.nav-3 .nav-bar .nav-links {
		    position: absolute;
		    width: 100%;
		    max-width: 320px;
		    left: 0;
		    margin: 0;
		    text-align: center;
	}

	.nav-big .nav-bar .nav-links {
		font-size: 15px;
		font-weight: 600;
	}

	.nav-6 .nav-bar {
		height: 140px;
		position: absolute;
		flex-wrap: wrap;
		align-content: center;
		padding-bottom: 35px;

	}
		.nav-6 .nav-bar .nav-title {
		margin-left: 0;
	}
	.nav-6 .nav-logo-wrapper {
		flex-direction: column;
		justify-content: center;
	}



	.nav-6 .nav-bar .left {
		flex-direction: column;
		margin:0 auto;
	}
	.nav-6 .nav-bar .nav-links {
		margin:0;
		width: 100%;
		text-align: center;
		position: absolute;
		bottom: 0;
		padding-bottom: 20px;
		left: 0;
	}

	.nav-6 .nav-bar .nav-logo.aspect-ratio {
		width: auto;
	}
	.nav-6 .nav-bar .right {
		position: absolute;
		top: 20px;
		right: 20px;
	}
	.edit-body-mode.nav-6:not(.nav-bar-fixed-top) .master-container {
		padding-top: 0!important;
	}
	.nav-6 .master-container {
		padding-top: 140px;
	}

	.nav-bar-medium:not(.nav-2) .nav-bar {
		height: 110px;
	}

	.nav-bar-large:not(.nav-2) .nav-bar {
		height: 140px;
	}

	.nav-bar-fixed-top #app .nav-bar {
		position: fixed;
	}

	#app .master-container {
		padding-top: 80px;
	}

	.nav-2 #app .master-container {
		padding-top: 0px;
	}

	.nav-bar-medium:not(.nav-2) #app .master-container {
		padding-top: 110px!important;
	}
	.nav-bar-large:not(.nav-2) #app .master-container {
		padding-top: 140px!important;
	}

	.nav-6 #app .master-container {
		padding-top: 140px!important;
	}
	.nav-6.nav-bar-medium #app .master-container {
		padding-top: 160px!important;
	}

	.nav-6.nav-bar-large #app .master-container {
		padding-top: 200px!important;
	}
	.nav-6.nav-bar-medium .nav-bar {
		height: 160px;
	}
	.nav-6.nav-bar-large .nav-bar {
		height: 200px;
	}

	.nav-bar-medium:not(.nav-2)  .nav-bar .nav-logo {
		transform: scale(1.3);
		margin-right: 10px;
	}

	.nav-bar-large:not(.nav-2)  .nav-bar .nav-logo {
		transform: scale(1.5);
		margin-right: 20px;
	}

	.nav-bar-large:not(.nav-2) .nav-bar .nav-title {
		
	}

	.nav-bar-medium:not(.nav-2) .nav-bar .nav-title {
		
	}

	.nav-bar-fixed-top .nav-bar {
		position: fixed!important;
	}

}

@media (max-width: 779px) {
	.nav-logo-wrapper {
		align-items: flex-start!important;
		justify-content: center;
	}
	.nav-bar .nav-title {
		font-size: 24px!important;
	}
	.nav-bar-edit-tools  {
		display: none;
	}
	.nav-bar {
		padding: 0 20px;
		background: #fff;
		z-index: 2001;
		height: 60px;
		position: fixed;
	}
	.nav-bar .toc-filter{
		display: flex;
		flex-direction: row;
	}
	.nav-bar .toc-filter img {
		width: 20px;
		height: 20px;
	}
	.nav-bar .toc-filter .filter-item.active {
		box-shadow: none;
	}
	.nav-bar .nav-logo {
		margin-left: 5px;
		height: 35px;
	}
	.nav-bar .left {
		justify-content: flex-start!important;
		width: 100%!important;
		background: inherit;
		z-index: 400;
		height: 100%;
	}

	.nav-bar .left .nav-logo-wrapper {
		height: 100%;
	}
	.nav-bar .left .nav-links {
		background:inherit!important;
	}
	.nav-bar .account-button {
		font-size: 0px;
	}
	.nav-bar .account-button .dropdown {
		font-size: 12px;
	}
	.nav-bar .burger,
	.nav-bar #burger {
		display: block;
		position: relative;
		z-index: 400;
		margin-right: 0px!important;
		position: fixed;
		right: 20px;
		top: 22px;
	}

	.edit-body-mode .nav-bar .burger {
		top: 25px;
	}

	.nav-bar .left .nav-logo-wrapper {
		transition: 0.2s;
	}
	.nav-bar .right {
		position: fixed;
		top: 90px;
		left: auto;
		right: -10px;
		opacity: 0;
		visibility: hidden;
		z-index: 3000;
		transition: all 0.3s;
	}
	.nav-bar.active .right {
		top: 90px;
	    opacity: 1;
	    visibility: visible;
	    left: auto;
	    height: calc(100vh - 90px);
	    right: 0px;
	    background: inherit;
	    width: 50px;
	    align-items: flex-start;
	}
	.nav-bar .right .profile-stats {
		flex-direction: column;
		width: 100%;
	}
	.nav-bar .right .profile-stats a {
		margin-bottom: 20px;
	}
	.nav-bar .nav-logo {
		max-height: 50px!important;
	}
	#cartBtn img {
		width: 28px!important;
	}
	.nav-2 .profile-stats {
		flex-direction: row;
		justify-content: space-around;
	}
	.nav-3 .nav-bar .nav-links,
	.nav-bar .nav-links {
		position: fixed!important;
		width: calc(100vw - 0px);
		height: calc(100vh - 0px)!important;
		font-size: 20px;
		left: -40px!important;
		background: inherit;
		display: flex;
		flex-direction: column;
		margin-left: 0;
		padding:90px 0px 40px 0px;
		z-index: 3;
		justify-content:flex-start;
		visibility: hidden;
		align-items: flex-start;
		text-align: left!important;
		opacity: 0;
		transition: all 0.3s;
		top: 0px!important;

	}
	.profile-stats .socicon {
		font-size: 20px;
	}
	.nav-bar .nav-links.active {
		opacity: 1;
		visibility: visible;
		top: 0px!important;
		left: 0!important;
		z-index: 40;
		overflow: auto;
		padding-bottom: 90px;
	}
	.nav-bar .nav-links a {
		margin: 0!important;
	    width: 100%;
	    padding: 12px 30px;
	    font-size: 20px!important;
	}
	.nav-bar .nav-links a strong {
		display: inline-block;
		width: 100%;
	}
	.nav-bar .nav-links a:before {
		display: none;
	}
	.nav-bar .nav-links a:first-child:after {
		display: none;
	}
	.nav-links-dropdown {
		position: relative;
		box-shadow: none;
		top:auto!important;
		width: 100%;
		margin: 0;
		padding-bottom: 0;
		display: block;
		float: left;
		padding-left: 18px;
		font-size: inherit;
		text-align: inherit;
		left: auto;
	}
	.nav-links-dropdown a {
		opacity: 1!important;
		border-bottom:none!important;
	}

	.nav-bar .nav-links .nav-links-dropdown a {
		font-size: inherit!important;
		padding:10px 0px!important;
		text-align: inherit!important;
	}
	.edit-body-mode #app {
		padding-top: 0;
	}
	.master-container {
		padding-top: 60px!important;
	}

}

