
#experiencePage {
	min-height: 90vh;
}
.experience-2 #experiencePage {
	flex-direction: row;
    flex: auto;
    justify-content: flex-start;
    overflow:auto;
    padding-bottom: 80px;
    padding-top: 120px;
    padding-left: 50px;
    align-items: flex-end;
    flex-wrap: wrap;
    height: 100%;
}

.nav-2.experience-2 #experiencePage {
	min-height: 100vh;
}
.experience-blocks {
	position: relative;
}
.experience-2 .experience-blocks {
	display: flex;
	flex-wrap: nowrap;
	padding-top: 40px;
	width: 100%;
	overflow:auto;
}
.experience-1 .experience-blocks,
.experience-3 .experience-blocks {
	margin-top: 50px;
	text-align: left;
}
.experience-2 #experiencePage h1 {
	width: 100%;
}
.experience-2 #experiencePage .experience-block {
	flex-direction: column;
	padding:0 20px;
	flex-shrink: 0;
	width: 100%;
	max-width: 350px;
}
.experience-2 .experience-block:last-of-type:after {
	display: none;
}
.experience-2 #experiencePage .experience-block:before {
	left: 22px;
    top: -30px;
}

.experience-2 #experiencePage .experience-block:after {
	left: 22px;
	height: 1px;
	width: 100%;
	top: -18px;
}
.experience-2 #experiencePage .experience-block .date,
.experience-2 #experiencePage .experience-block .description {
	width: 100%;
	padding:0;
	text-align: left!important;
	margin:0;
	margin-top: 18px;
}


#experiencePage {
	justify-content: center;
	flex-direction: column;
	transition: all 0.3s ease;
	align-items: center;
	padding-top: 70px;
	padding-bottom: 70px;
}
#experiencePage .title-set {
	margin-bottom: 0!important;
	max-width: 700px;
}

.experience-2 #experiencePage .title-set {
	margin-bottom: 80px!important;
	max-width: initial;
}
#experiencePage.education-page {
	align-items: flex-start;
	padding-left: 200px;
	overflow:hidden;
	height: calc(100vh - 50px);
	justify-content: space-between;
}
#experiencePage.animate {
	transform:translateY(-50px);
	opacity: 0;
}
h1.title {
	font-weight: 100;
	margin:0 0 40px 0;
	padding:0;
}

h1.title span {
	font-weight: bold;
	color: #C4BDAC;
}

#experiencePage .gallery-filter {
	justify-content: center;
}
.experience-2 #experiencePage .gallery-filter {
	justify-content: flex-start;
}
.experience-blocks:hover .add-experience {
	opacity: 1;
	visibility: visible;
}

.experience-block {
	animation: fadeIn 1s forwards;
}

.experience-block:after {
	animation: lineGrow 1s forwards;
}
.experience-block .date {
	animation: fromLeft 1s forwards ease;
	animation-delay: 0.5s;
}

.experience-block .description {
	animation: fromRight 1s forwards ease;
	animation-delay: .8s;
}

.experience-block:nth-of-type(2) .date {
	animation: fromRight 1s forwards ease;
	animation-delay: 1s;
}

.experience-block:nth-of-type(2) .description {
	animation: fromLeft 1s forwards ease;
	animation-delay: 1.2s;
}

.experience-block:nth-of-type(3) .date {
	animation: fromLeft 1s forwards ease;
	animation-delay: 1.5s;
}

.experience-block:nth-of-type(3) .description {
	animation: fromRight 1s forwards ease;
	animation-delay: 1.8s;
}

.experience-block .date, .experience-block .description {
	opacity: 0;
}


.experience-block:nth-of-type(2) {
	animation-delay: 0s;
}

.experience-block:nth-of-type(2):after {
	animation-delay: 0.8s;
}

.experience-block:nth-of-type(3):after {
	animation-delay: 1.6s;
}

@keyframes lineGrow {
	0% {transform:scale(1,0);}
	100% {transform:scale(1,1);}
}

@keyframes fromLeft {
	0% {transform:translateX(-40px); opacity: 0}
	100% {transform:translateX(0px); opacity: 1}
}

@keyframes fromRight {
	0% {transform:translateX(40px); opacity: 0}
	100% {transform:translateX(0px); opacity: 1}
}

.experience-block {
	width: 100%;
	opacity: 0;
	transition: all 0.5s ease;;
	max-width: 700px;
	display: flex;
	flex-direction: row;
	padding:50px 0;
	position: relative;
}
.experience-block.add:before {
	background: #5FBF80;
	border: none;
	transform: scale(1.9,1.9);
	animation:pulse 1.7s infinite;
	content: "+";
	color: #fff;
	font-weight: bold;
	text-align: center;
	line-height: 25px;
	cursor: pointer;
}


.experience-block.add .job-title {
	font-weight: 100;
}

@keyframes pulse {
	0% {box-shadow:0 0 0 0 rgba(0,0,0,0.2);}
	100% {box-shadow:0 0 0 10px rgba(0,0,0,0);}
}
.experience-block.reverse {
	flex-direction: row-reverse;
}

.experience-3 .experience-block.reverse {
	flex-direction: row;
}

.experience-3 .experience-block.reverse .date {
	padding-right: 50px;
	text-align: right;
	padding-left: 0;
}

.experience-3 .experience-block.reverse .description {
	text-align: left;
	padding-right: 0px;
	padding-left: 50px;
}

.experience-block.reverse .date {
	text-align: left;
	padding-right: 0;
	padding-left: 50px;
}
.experience-block.reverse .description {
	text-align: right;
	padding-right: 50px;
	padding-left: 0px;
}
.experience-block:before {
	width: 25px;
	height: 25px;
	position: absolute;
	content: " ";
	background: #333;
	border-radius: 50%;
	left: calc(50% - 12px);
	top: 50px;
	border: solid 4px #fff;
	box-sizing: border-box;
	box-shadow: 0 0 0 1px rgba(0,0,0,0.1);
	z-index: 1;
}

.experience-block:after {
	width: 1px;
	height: 100%;
	left: 50%;
	top: 0;
	content: " ";
	background: rgba(0,0,0,0.25);
	position: absolute;
	transform-origin: 0% 0%;
	transform:scale(1,0);
}

.experience-block:last-of-type:after {
	height: calc(50% - 50px);
}

.experience-block .date {
	padding-right: 50px;
	font-size: 16px;
}

.experience-block .date, .experience-block .description {
	width: 50%;

}
.experience-block:hover .hover-box {
	visibility: visible;
	opacity: 1;
}
.experience-block .description {
	display: flex;
	flex-direction: column;
	padding-left: 50px;
	justify-content: flex-start;
    align-items: flex-start;
    text-align: left;

}
.experience-block .date {
	text-align: right;
}
.experience-block .description .job-title {
	margin:0;
	font-size:30px;
	font-weight: 600;
	padding:0;
}

.experience-block .description span {
	font-size: 14px;
	font-weight: normal;
}

.experience-block .description p {
	font-size: 16px;
    line-height: 21px;
}

@media (max-width: 779px) {
	.experience-block {
		flex-direction: column!important;
		padding:50px 50px!important;
		text-align: left!important;
	}

	.experience-2 .experience-block {
		padding:0px 20px 20px 20px!important;
	}
	.experience-block .date, .experience-block .description {
		width: 100%;
		text-align: left!important;
		padding-left:0!important;
		padding-right: 0!important;
	}
	.experience-block:before {
		width: 20px;
		height:20px;
		left: 15px;
		top: 22px;
	}

	.experience-block:after {
		left: 25px;
		top: 43px;
		height: calc(100% - 20px);
	}
	#experiencePage {
		padding-top:60px!important;
		justify-content: flex-start;
	}
	.experience-2 #experiencePage {
		justify-content: space-between;
		padding-bottom: 80px!important;
		align-items: flex-start!important;
		height: auto!important;
    	min-height: 600px!important;
	}
	#experiencePage .title-set {
		padding:0 20px;
	}
	.experience-2 #experiencePage .title-set {
		margin-bottom: 0!important;
		padding:60px 20px;
	}
	
}