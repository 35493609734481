#startupContent {
	min-height: 100%;
	align-items: center;
}
.nav-2 #startupContent {
	padding-top: 0;
}

.hero-app-buttons:hover .edit-popup-circle {
	opacity: 1;
	visibility: visible;
	top: 0;
}
.hero-app-buttons {
	position: relative;
	margin-top: 20px;
	align-items: center;
	flex-wrap: wrap;
	transition: all 0.2s;
}
.hero-app-buttons div {
	margin-right: 10px;
	display: inline;
}
.hero-app-buttons div img {
	width: 130px;
	border-radius: 5px;
	box-shadow: 0 4px 8px rgba(0,0,0,0.1);
	cursor: pointer;
	transition: all 0.2s;

}

.modal .hero-app-buttons {
	margin-top: 0px;
	margin-bottom: 10px;
	flex-wrap: nowrap;
}

.modal .hero-app-buttons div img {
	display: block;
	width: 100px;
}
.modal .hero-app-buttons:hover {
	background:rgba(255,255,255,0.1);
	border-radius: 6px;
}

.modal .hero-app-buttons:hover div img {
	transform: scale(1.1,1.1);
}

.hero-app-buttons .input-field {
	align-items: center;
	display: flex;
	margin-bottom: 0px;
}
.hero-app-buttons div img:hover {
	transform: translateY(-2px) scale(1.05,1.05);
	box-shadow: 0 4px 8px rgba(0,0,0,0.1), 0 0 0 4px rgba(0,0,0,0.05);
}
.startup-hero {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	min-height: 667px;
	max-width: 1400px;
	margin:0 auto;
}
.startup-hero-image {
	display: none;
	position: absolute;
	left: 0;
	top: 0;
	bottom:0;
	right: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.startup-text {
	width: 45%;
	padding:50px 70px 50px 70px;
	position: relative;
	z-index: 3;
}
.startup-text h1 {
	font-size: 50px;
	margin:0;
	padding:0;
}

.startup-text p {
	margin:0px 0 0 0;
	padding:0;
	font-size: 18px;
	font-weight: 400;
}

.startup-screens {
	width: 55%;
	height: 100%;
	padding:0 50px;
	display: flex;
	justify-content: center;
	position: relative;
	align-items: center;
}

.startup-screens img {
	width: 100%;
	box-shadow: 0 14px 28px rgba(0,0,0,0.02), 0 10px 10px rgba(0,0,0,0.05);
	display: block;
}
.desktop-scree:hover .hover-box,
.phone-screen-startup:hover .hover-box {
	opacity: 1;
	visibility: visible;
}

.desktop-screen {
	width: 100%;
	padding-top: 25px;
	position: relative;
	background: #efefef;
	border-radius: 5px ;
	z-index: 1;
	box-shadow: 0px 0px 0px 11px #1f1f1f, 0px 0px 0px 13px #191919, 0px 0px 0px 20px #111;
}

.desktop-screen:before {
	position: absolute;
    left: 10px;
    top: -5px;
    color: #D8D8D8;
    content: "...";
    font-size: 60px;
    width: 100%;
    line-height: 0;
}

.startup-screens .desktop-screen:before {
	display: none;
}

.startup-screens .desktop-screen {
	padding-top: 0;
	background: #111;
	transform: scale(1.1);
    transform-origin: 0 100%;
    box-shadow: 0px 0px 0px 5px #1f1f1f, 0px 0px 0px 6px #191919, 0px 0px 0px 10px #111;
}

.startup-screens .desktop-screen img {
	border-radius: 8px;
}

.edit-body-mode .startup-screens {
	height: auto;
}

.phone-screen-startup {
	width: 150px;
	bottom:0px;
	position: absolute;
	left: -30px;
	padding-top: 0px;
	background: #efefef;
	border-radius: 12px;
	overflow:hidden;
	box-shadow: 0px 0px 0px 5px #1f1f1f, 0px 0px 0px 8px #191919, 0px 0px 0px 13px #111;
	z-index: 2;
}

.phone-screen-startup:before {
	top: 0px;
    content: " ";
    width: 46%;
    height: 20px;
    background-color: #1f1f1f;
    border-radius: 0px 0px 40px 40px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.phone-screen-startup:before,
.phone-screen-startup:after {
	display: none;
}

.phone-screen-startup:after {
	content: " ";
    top: -1px;
    left: 50%;
    transform: translate(-50%, 6px);
    height: 4px;
    width: 12%;
    background-color: #101010;
    border-radius: 8px;
    box-shadow: inset 0px -1px 1px 0px rgba(255, 255, 255, 0.2);
    position: absolute;
}

.startup-1 .phone-screen-startup {
	box-shadow: 0px 0px 0px 3px #1f1f1f, 0px 0px 0px 5px #191919, 0px 0px 0px 10px #111, -20px -20px 50px rgba(0,0,0,0.2);
	transform: translateY(20px);
}
.startup-1 .phone-screen-startup:before {
	height: 10px;
}
.phone-screen-startup.second {
	display: none;
}

.startup-2 .phone-screen-startup {
	display: none;
}
.startup-3 .desktop-screen-startup {
	display: none;
}
.startup-3 .phone-screen-startup {
	position: relative;
	left: auto;
	bottom: auto;
	width: 260px;
	border-radius: 20px;
	position: relative;
	z-index: 3;
	margin-left: 0px;
}
.startup-3 .phone-screen-startup.second {
	width: 240px;
	min-height: 500px;
	background: #333;
	display: block;
	margin-left: -50px;
	z-index: 2;
}
.startup-3 .startup-text {
	width: 50%;
	padding:50px 50px 50px 100px;
}
.startup-3 .startup-screens {
	width: 50%;
	padding:0;
}

/*.flex-container .startup-text h1 {
	font-size: 75px;
	margin-bottom: 0;
}
.flex-container .startup-text h2 {
	font-size: 55px;
	margin-bottom: 0;
}
.flex-container .startup-text h3 {
	font-size: 45px;
	margin-bottom: 0;
}
.flex-container .startup-text h4 {
	font-size: 30px;
	margin-bottom: 0;
}*/
.startup-3 .desktop-screen {
	display: none;
}

.startup-4 .startup-screens {
	display: none;
}

.startup-4 .startup-text {
	width: 100%;
	max-width: 600px;
	text-align: center;
	justify-content: center;
	align-items: center;
	margin:0 auto;
	padding:0 20px;
	color: #fff;
}
.startup-4 .startup-hero-image {
	display: block;
}
.startup-4 .startup-hero:before {
	content: " ";
	width: 100%;
	height: 100%;
	opacity: 0.3;
	background: #000;
	position: absolute;
	z-index: 3;
}

@media (max-width: 779px) {
	#startupContent {
		min-height: initial!important;
	}
	.startup-hero {
		flex-direction: column;
		height: auto!important;
		padding-bottom: 40px!important;
		min-height: initial;
	}
	.startup-text {
		width: 100%!important;
		padding:30px!important;
		min-height: 30vh;
	}
	.startup-screens {
		width: 100%!important;
		padding:0 30px!important;
	}
	.phone-screen-startup {
		width: 120px!important;
		box-shadow: 0px 0px 0px 3px #1f1f1f, 0px 0px 0px 4px #191919, 0px 0px 0px 7px #111;
	}
	.desktop-screen {
		box-shadow: 0px 0px 0px 5px #1f1f1f, 0px 0px 0px 7px #191919, 0px 0px 0px 13px #111
	}
	.startup-screens .desktop-screen {
	padding-top: 0;
	background: #111;
	transform: none;
    transform-origin: 0 100%;
}
 
	.phone-screen-startup:before {
		height: 10px;
	}
	.phone-screen-startup:after {
		top: -3px;
	}
	.startup-1 .phone-screen-startup {
		width: 90px!important;
		bottom: 0;
		left: 20px;
		box-shadow: 0px 0px 0px 3px #1f1f1f, 0px 0px 0px 4px #191919, 0px 0px 0px 7px #111!important;
	}
	.startup-3 .phone-screen-startup {
		width: 150px!important;
		margin:0 10px!important;
		min-height: initial!important;
		border-radius: 10px!important;

	}
	.startup-4 .startup-hero {
		min-height: initial;
		height: calc(100vh - 60px)!important;
	}
	.startup-4 .startup-text {
		height: 100%;
		align-items: center;
	}
	.flex-container .startup-text h1 {
	font-size: 40px;
}
.flex-container .startup-text h2 {
	font-size: 30px;
}
.flex-container .startup-text h3 {
	font-size: 20px;
}
}