@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700|Martel|Audiowide|Viaoda+Libre|Special+Elite|Frijole|Amatic+SC|Langar|Bungee+Outline|Cormorant+Garamond:300,400,500,600,700|Lobster|Lora:400,700|Montserrat:400,700|Oswald:400,700|Playfair+Display:400,700,900|Raleway:400,700,900|Roboto+Condensed:400,700|Roboto+Slab:400,700|Slabo+27px|Source+Serif+Pro:400,700&display=swap);
@import url(https://www.myclick.me/assets/css/socicon.css);
@import url(https://kit-pro.fontawesome.com/releases/v5.15.1/css/pro.min.css);
.langar-font .master-container h1,
.langar-font .master-container h2,
.langar-font .master-container h3,
.langar-font .master-container h4,
.langar-font .master-container h5,
.langar-font .master-container h6,
.langar-font .master-container h1 span,
.langar-font .master-container h2 span,
.langar-font .master-container h3 span,
.langar-font .master-container h4 span,
.langar-font .master-container h5 span,
.langar-font .master-container h6 span,
.langar-font-body .master-container p,
.langar-font-body .master-container ul,
.langar-font-body .master-container span,
.langar-font-body .master-container .blog-post-content,
.langar-font-nav .master-container .nav-bar,
.langar-font-body .font-group div:after,
.font-group .langar-font,
.font-group .langar-font-body,
.font-group .langar-font-nav,
.font-group .langar-font-footer,

.font-group .langar-font-button,
.explore-btn.langar-font-button,

.langar-font-footer .site-footer {
	font-family: 'Langar';
}

.bungee-outline-font .master-container h1,
.bungee-outline-font .master-container h2,
.bungee-outline-font .master-container h3,
.bungee-outline-font .master-container h4,
.bungee-outline-font .master-container h5,
.bungee-outline-font .master-container h6,
.bungee-outline-font .master-container h1 span,
.bungee-outline-font .master-container h2 span,
.bungee-outline-font .master-container h3 span,
.bungee-outline-font .master-container h4 span,
.bungee-outline-font .master-container h5 span,
.bungee-outline-font .master-container h6 span,
.bungee-outline-font-body .master-container p,
.bungee-outline-font-body .master-container ul,
.bungee-outline-font-body .master-container span,
.bungee-outline-font-body .master-container .blog-post-content,
.bungee-outline-font-nav .master-container .nav-bar,
.bungee-outline-font-body .font-group div:after,
.font-group .bungee-outline-font,
.font-group .bungee-outline-font-body,
.font-group .bungee-outline-font-nav,
.font-group .bungee-outline-font-footer,

.font-group .bungee-outline-font-button,
.explore-btn.bungee-outline-font-button,

.bungee-outline-font-footer .site-footer {
	font-family: 'Bungee Outline';
}

.amaticsc-font .master-container h1,
.amaticsc-font .master-container h2,
.amaticsc-font .master-container h3,
.amaticsc-font .master-container h4,
.amaticsc-font .master-container h5,
.amaticsc-font .master-container h6,
.amaticsc-font .master-container h1 span,
.amaticsc-font .master-container h2 span,
.amaticsc-font .master-container h3 span,
.amaticsc-font .master-container h4 span,
.amaticsc-font .master-container h5 span,
.amaticsc-font .master-container h6 span,
.amaticsc-font-body .master-container p,
.amaticsc-font-body .master-container ul,
.amaticsc-font-body .master-container span,
.amaticsc-font-body .master-container .blog-post-content,
.amaticsc-font-nav .master-container .nav-bar,
.amaticsc-font-body .font-group div:after,
.font-group .amaticsc-font,
.font-group .amaticsc-font-body,
.font-group .amaticsc-font-nav,
.font-group .amaticsc-font-footer,

.font-group .amaticsc-font-button,
.explore-btn.amaticsc-font-button,

.amaticsc-font-footer .site-footer {
	font-family: 'Amatic SC';
}

.audiowide-font .master-container h1,
.audiowide-font .master-container h2,
.audiowide-font .master-container h3,
.audiowide-font .master-container h4,
.audiowide-font .master-container h5,
.audiowide-font .master-container h6,
.audiowide-font .master-container h1 span,
.audiowide-font .master-container h2 span,
.audiowide-font .master-container h3 span,
.audiowide-font .master-container h4 span,
.audiowide-font .master-container h5 span,
.audiowide-font .master-container h6 span,
.audiowide-font-body .master-container p,
.audiowide-font-body .master-container ul,
.audiowide-font-body .master-container span,
.audiowide-font-body .master-container .blog-post-content,
.audiowide-font-nav .master-container .nav-bar,
.audiowide-font-body .font-group div:after,
.font-group .audiowide-font,
.font-group .audiowide-font-body,
.font-group .audiowide-font-nav,
.font-group .audiowide-font-footer,

.font-group .audiowide-font-button,
.explore-btn.audiowide-font-button,

.audiowide-font-footer .site-footer {
	font-family: 'Audiowide';
}


.frijole-font .master-container h1,
.frijole-font .master-container h2,
.frijole-font .master-container h3,
.frijole-font .master-container h4,
.frijole-font .master-container h5,
.frijole-font .master-container h6,
.frijole-font .master-container h1 span,
.frijole-font .master-container h2 span,
.frijole-font .master-container h3 span,
.frijole-font .master-container h4 span,
.frijole-font .master-container h5 span,
.frijole-font .master-container h6 span,
.frijole-font-body .master-container p,
.frijole-font-body .master-container ul,
.frijole-font-body .master-container span,
.frijole-font-body .master-container .blog-post-content,
.frijole-font-nav .master-container .nav-bar,
.frijole-font-body .font-group div:after,
.font-group .frijole-font,
.font-group .frijole-font-body,
.font-group .frijole-font-nav,
.font-group .frijole-font-footer,

.font-group .frijole-font-button,
.explore-btn.frijole-font-button,

.frijole-font-footer .site-footer {
	font-family: 'Frijole';
}

.special-elite-font .master-container h1,
.special-elite-font .master-container h2,
.special-elite-font .master-container h3,
.special-elite-font .master-container h4,
.special-elite-font .master-container h5,
.special-elite-font .master-container h6,
.special-elite-font .master-container h1 span,
.special-elite-font .master-container h2 span,
.special-elite-font .master-container h3 span,
.special-elite-font .master-container h4 span,
.special-elite-font .master-container h5 span,
.special-elite-font .master-container h6 span,
.special-elite-font-body .master-container p,
.special-elite-font-body .master-container ul,
.special-elite-font-body .master-container span,
.special-elite-font-body .master-container .blog-post-content,
.special-elite-font-nav .master-container .nav-bar,
.special-elite-font-body .font-group div:after,
.font-group .special-elite-font,
.font-group .special-elite-font-body,
.font-group .special-elite-font-nav,
.font-group .special-elite-font-footer,

.font-group .special-elite-font-button,
.explore-btn.special-elite-font-button,

.special-elite-font-footer .site-footer {
	font-family: 'Special Elite';
}

.viaoda-libre-font .master-container h1,
.viaoda-libre-font .master-container h2,
.viaoda-libre-font .master-container h3,
.viaoda-libre-font .master-container h4,
.viaoda-libre-font .master-container h5,
.viaoda-libre-font .master-container h6,
.viaoda-libre-font .master-container h1 span,
.viaoda-libre-font .master-container h2 span,
.viaoda-libre-font .master-container h3 span,
.viaoda-libre-font .master-container h4 span,
.viaoda-libre-font .master-container h5 span,
.viaoda-libre-font .master-container h6 span,
.viaoda-libre-font-body .master-container p,
.viaoda-libre-font-body .master-container ul,
.viaoda-libre-font-body .master-container span,
.viaoda-libre-font-body .master-container .blog-post-content,
.viaoda-libre-font-nav .master-container .nav-bar,
.viaoda-libre-font-body .font-group div:after,
.font-group .viaoda-libre-font,
.font-group .viaoda-libre-font-body,
.font-group .viaoda-libre-font-nav,
.font-group .viaoda-libre-font-footer,

.font-group .viaoda-libre-font-button,
.explore-btn.viaoda-libre-font-button,

.viaoda-libre-font-footer .site-footer {
	font-family: 'Viaoda Libre';
}

/* Original */

.montserrat-font .master-container h1,
.montserrat-font .master-container h2,
.montserrat-font .master-container h3,
.montserrat-font .master-container h4,
.montserrat-font .master-container h5,
.montserrat-font .master-container h6,
.montserrat-font .master-container h1 span,
.montserrat-font .master-container h2 span,
.montserrat-font .master-container h3 span,
.montserrat-font .master-container h4 span,
.montserrat-font .master-container h5 span,
.montserrat-font .master-container h6 span,
.montserrat-font-body .master-container p,
.montserrat-font-body .master-container ul,
.montserrat-font-body .master-container span,
.montserrat-font-body .master-container .blog-post-content,
.montserrat-font-nav .master-container .nav-bar,
.montserrat-font-body .font-group div:after,
.font-group .montserrat-font,
.font-group .montserrat-font-body,
.font-group .montserrat-font-nav,
.font-group .montserrat-font-footer,

.font-group .montserrat-font-button,
.explore-btn.montserrat-font-button,

.montserrat-font-footer .site-footer {
	font-family: 'Montserrat';
}
.lato-font .master-container h1,
.lato-font .master-container h2,
.lato-font .master-container h3,
.lato-font .master-container h4,
.lato-font .master-container h5,
.lato-font .master-container h6,
.lato-font .master-container h1 span,
.lato-font .master-container h2 span,
.lato-font .master-container h3 span,
.lato-font .master-container h4 span,
.lato-font .master-container h5 span,
.lato-font .master-container h6 span,
.lato-font-body .master-container p,
.lato-font-body .master-container ul,
.lato-font-body .master-container span,
.lato-font-body .master-container .blog-post-content,
.lato-font-nav .nav-bar,
.lato-font-body .font-group div:after,
.font-group .lato-font,
.font-group .lato-font-body,
.font-group .lato-font-footer,
.font-group .lato-font-nav,

.font-group .lato-font-button,
.explore-btn.lato-font-button,

.lato-font-footer .site-footer {
	font-family: 'Lato';
}
.oswald-font .master-container h1,
.oswald-font .master-container h2,
.oswald-font .master-container h3,
.oswald-font .master-container h4,
.oswald-font .master-container h5,
.oswald-font .master-container h6,
.oswald-font .master-container h1 span,
.oswald-font .master-container h2 span,
.oswald-font .master-container h3 span,
.oswald-font .master-container h4 span,
.oswald-font .master-container h5 span,
.oswald-font .master-container h6 span,
.oswald-font-body .master-container p,
.oswald-font-body .master-container ul,
.oswald-font-body .master-container span,
.oswald-font-body .master-container .blog-post-content,
.oswald-font-body .font-group div:after,
.oswald-font-nav .nav-bar,
.font-group .oswald-font,
.font-group .oswald-font-body,
.font-group .oswald-font-nav,
.font-group .oswald-font-footer,

.font-group .oswald-font-button,
.explore-btn.oswald-font-button,

.oswald-font-footer .site-footer {
	font-family: 'Oswald';
}
.roboto-condensed-font .master-container h1,
.roboto-condensed-font .master-container h2,
.roboto-condensed-font .master-container h3,
.roboto-condensed-font .master-container h4,
.roboto-condensed-font .master-container h5,
.roboto-condensed-font .master-container h6,
.roboto-condensed-font .master-container h1 span,
.roboto-condensed-font .master-container h2 span,
.roboto-condensed-font .master-container h3 span,
.roboto-condensed-font .master-container h4 span,
.roboto-condensed-font .master-container h5 span,
.roboto-condensed-font .master-container h6 span,
.roboto-condensed-font-body .master-container p,
.roboto-condensed-font-body .master-container ul,
.roboto-condensed-font-body .master-container span,
.roboto-condensed-font-body .master-container .blog-post-content,
.roboto-condnsed-font-body .font-group div:after,
.roboto-condensed-font-nav .master-container .nav-bar,
.font-group .roboto-condensed-font,
.font-group .roboto-condensed-font-body,
.font-group .roboto-condensed-font-nav,
.font-group .roboto-condensed-font-footer,

.font-group .roboto-font-button,
.explore-btn.roboto-font-button,

.roboto-font-footer .site-footer {
	font-family: 'Roboto Condensed';
}
.roboto-slab-font .master-container h1,
.roboto-slab-font .master-container h2,
.roboto-slab-font .master-container h3,
.roboto-slab-font .master-container h4,
.roboto-slab-font .master-container h5,
.roboto-slab-font .master-container h6,
.roboto-slab-font .master-container h1 span,
.roboto-slab-font .master-container h2 span,
.roboto-slab-font .master-container h3 span,
.roboto-slab-font .master-container h4 span,
.roboto-slab-font .master-container h5 span,
.roboto-slab-font .master-container h6 span,
.roboto-slab-font-body .master-container p,
.roboto-slab-font-body .master-container ul,
.roboto-slab-font-body .master-container span,
.roboto-slab-font-body .master-container .blog-post-content,
.roboto-slab-font-body .font-group div:after,
.roboto-slab-font-nav .master-container .nav-bar,
.font-group .roboto-slab-font,
.font-group .roboto-slab-font-body,
.font-group .roboto-slab-font-nav,
.font-group .roboto-slab-font-footer,

.font-group .roboto-font-button,
.explore-btn.roboto-font-button,

.roboto-slab-font-footer .site-footer   {
	font-family: 'Roboto Slab';
}
.slabo-font .master-container h1,
.slabo-font .master-container h2,
.slabo-font .master-container h3,
.slabo-font .master-container h4,
.slabo-font .master-container h5,
.slabo-font .master-container h6,
.slabo-font .master-container h1 span,
.slabo-font .master-container h2 span,
.slabo-font .master-container h3 span,
.slabo-font .master-container h4 span,
.slabo-font .master-container h5 span,
.slabo-font .master-container h6 span,
.slabo-font-nav .master-container .nav-bar,
.slabo-font-body .master-container p,
.slabo-font-body .master-container ul,
.slabo-font-body .master-container span,
.slabo-font-body .master-container .blog-post-content,
.slabo-font-body .font-group div:after,
.font-group .slabo-font,
.font-group .slabo-font-body,
.font-group .slabo-font-nav,
.font-group .slabo-font-footer,

.font-group .slabo-font-button,
.explore-btn.slabo-font-button,

.slabo-font-footer .site-footer {
	font-family: 'Slabo 27px', serif;
}
.playfair-display-font .master-container h1,
.playfair-display-font .master-container h2,
.playfair-display-font .master-container h3,
.playfair-display-font .master-container h4,
.playfair-display-font .master-container h5,
.playfair-display-font .master-container h6,
.playfair-display-font .master-container h1 span,
.playfair-display-font .master-container h2 span,
.playfair-display-font .master-container h3 span,
.playfair-display-font .master-container h4 span,
.playfair-display-font .master-container h5 span,
.playfair-display-font .master-container h6 span,
.playfair-display-font-body .master-container p,
.playfair-display-font-body .master-container ul,
.playfair-display-font-body .master-container span,
.playfair-display-font-body .master-container .blog-post-content,
.playfair-display-font-body .font-group div:after,
.playfair-display-font-nav .master-container .nav-bar,
.font-group .playfair-display-font,
.font-group .playfair-display-font-body,
.font-group .playfair-display-font-nav,
.font-group .playfair-display-font-footer,

.font-group .playfair-display-font-button,
.explore-btn.playfair-display-font-button,

.playfair-display-font-footer .site-footer {
	font-family: 'Playfair Display';
}
.lora-font .master-container h1,
.lora-font .master-container h2,
.lora-font .master-container h3,
.lora-font .master-container h4,
.lora-font .master-container h5,
.lora-font .master-container h6,
.lora-font .master-container h1 span,
.lora-font .master-container h2 span,
.lora-font .master-container h3 span,
.lora-font .master-container h4 span,
.lora-font .master-container h5 span,
.lora-font .master-container h6 span,
.lora-font-nav .master-container .nav-bar,
.lora-font-body .master-container p,
.lora-font-body .master-container ul,
.lora-font-body .master-container span,
.lora-font-body .master-container .blog-post-content,
.lora-font-body .font-group div:after,
.font-group .lora-font,
.font-group .lora-font-body,
.font-group .lora-font-nav,
.font-group .lora-font-footer,

.font-group .lora-font-button,
.explore-btn.lora-font-button,

.lora-font-footer .site-footer {
	font-family: 'Lora';
}
.lobster-font .master-container h1,
.lobster-font .master-container h2,
.lobster-font .master-container h3,
.lobster-font .master-container h4,
.lobster-font .master-container h5,
.lobster-font .master-container h6,
.lobster-font .master-container h1 span,
.lobster-font .master-container h2 span,
.lobster-font .master-container h3 span,
.lobster-font .master-container h4 span,
.lobster-font .master-container h5 span,
.lobster-font .master-container h6 span,
.lobster-font-body .master-container p,
.lobster-font-body .master-container ul,
.lobster-font-body .master-container span,
.lobster-font-body .master-container .blog-post-content,
.lobster-font-nav .master-container .nav-bar,
.lobster-font-body .font-group div:after,
.font-group .lobster-font,
.font-group .lobster-font-body,
.font-group .lobster-font-footer,
.font-group .lobster-font-nav,

.font-group .lobster-font-button,
.explore-btn.lobster-font-button,

.lobster-font-footer .site-footer {
	font-family: 'Lobster';
}
.source-serif-pro-font .master-container h1,
.source-serif-pro-font .master-container h2,
.source-serif-pro-font .master-container h3,
.source-serif-pro-font .master-container h4,
.source-serif-pro-font .master-container h5,
.source-serif-pro-font .master-container h6,
.source-serif-pro-font .master-container h1 span,
.source-serif-pro-font .master-container h2 span,
.source-serif-pro-font .master-container h3 span,
.source-serif-pro-font .master-container h4 span,
.source-serif-pro-font .master-container h5 span,
.source-serif-pro-font .master-container h6 span,
.source-serif-pro-font-body .master-container p,
.source-serif-pro-font-body .master-container ul,
.source-serif-pro-font-body .master-container span,
.source-serif-pro-font-body .master-container .blog-post-content,
.source-serif-profont-body .font-group div:after,
.source-serif-pro-font-nav .master-container .nav-bar,
.font-group .source-serif-pro-font,
.font-group .source-serif-pro-font-body,
.font-group .source-serif-pro-font-nav,
.font-group .source-serif-pro-font-footer,

.font-group .source-serif-pro-font-button,
.explore-btn.source-serif-pro-font-button,

.source-serif-pro-font-footer .site-footer {
	font-family: 'Source Serif Pro';
}
.raleway-font .master-container h1,
.raleway-font .master-container h2,
.raleway-font .master-container h3,
.raleway-font .master-container h4,
.raleway-font .master-container h5,
.raleway-font .master-container h6,
.raleway-font .master-container h1 span,
.raleway-font .master-container h2 span,
.raleway-font .master-container h3 span,
.raleway-font .master-container h4 span,
.raleway-font .master-container h5 span,
.raleway-font .master-container h6 span,
.raleway-font-body .master-container p,
.raleway-font-body .master-container ul,
.raleway-font-body .master-container span,
.raleway-font-body .master-container .blog-post-content,
.raleway-font-body .font-group div:after,
.raleway-font-nav .master-container .nav-bar,
.font-group .raleway-font,
.font-group .raleway-font-body,
.font-group .raleway-font-nav,
.font-group .raleway-font-footer,

.font-group .raleway-font-button,
.explore-btn.raleway-font-button,

.raleway-font-footer .site-footer {
	font-family: 'Raleway';
}

.cormorant-garamond-font .master-container h1,
.cormorant-garamond-font .master-container h2,
.cormorant-garamond-font .master-container h3,
.cormorant-garamond-font .master-container h4,
.cormorant-garamond-font .master-container h5,
.cormorant-garamond-font .master-container h6,
.cormorant-garamond-font .master-container h1 span,
.cormorant-garamond-font .master-container h2 span,
.cormorant-garamond-font .master-container h3 span,
.cormorant-garamond-font .master-container h4 span,
.cormorant-garamond-font .master-container h5 span,
.cormorant-garamond-font .master-container h6 span,
.cormorant-garamond-font-body .master-container p,
.cormorant-garamond-font-body .master-container ul,
.cormorant-garamond-font-body .master-container span,
.cormorant-garamond-font-body .master-container .blog-post-content,
.cormorant-garamond-font-body .font-group div:after,
.cormorant-garamond-font-nav .master-container .nav-bar,
.font-group .cormorant-garamond-font,
.font-group .cormorant-garamond-font-body,
.font-group .cormorant-garamond-font-nav,
.font-group .cormorant-garamond-font-footer,

.font-group .cormorant-garamond-font-button,
.explore-btn.cormorant-garamond-font-button,

.cormorant-garamond-font-footer .site-footer {
	font-family: 'Cormorant Garamond';
}
 

.martel-font .master-container h1,
.martel-font .master-container h2,
.martel-font .master-container h3,
.martel-font .master-container h4,
.martel-font .master-container h5,
.martel-font .master-container h6,
.martel-font .master-container h1 span,
.martel-font .master-container h2 span,
.martel-font .master-container h3 span,
.martel-font .master-container h4 span,
.martel-font .master-container h5 span,
.martel-font .master-container h6 span,
.martel-font-body .master-container p,
.martel-font-body .master-container ul,
.martel-font-body .master-container span,
.martel-font-body .master-container .blog-post-content,
.martel-font-body .font-group div:after,
.martel-font-nav .master-container .nav-bar,
.font-group .martel-font,
.font-group .martel-font-body,
.font-group .martel-font-nav,
.font-group .martel-font-footer,

.font-group .martel-font-button,
.explore-btn.martel-font-button,

.martel-font-footer .site-footer {
	font-family: 'Martel';
}

/* WEB SAFE FONTS */

.arial-font .master-container h1,
.arial-font .master-container h2,
.arial-font .master-container h3,
.arial-font .master-container h4,
.arial-font .master-container h5,
.arial-font .master-container h6,
.arial-font .master-container h1 span,
.arial-font .master-container h2 span,
.arial-font .master-container h3 span,
.arial-font .master-container h4 span,
.arial-font .master-container h5 span,
.arial-font .master-container h6 span,
.arial-font-body .master-container p,
.arial-font-body .master-container ul,
.arial-font-body .master-container span,
.arial-font-body .master-container .blog-post-content,
.arial-font-body .font-group div:after,
.arial-font-nav .master-container .nav-bar,
.font-group .arial-font,
.font-group .arial-font-body,
.font-group .arial-font-nav,
.font-group .arial-font-footer,

.font-group .arial-font-button,
.explore-btn.arial-font-button,

.arial-font-footer .site-footer {
	font-family: 'Arial';
}

.comic-sans-ms-font .master-container h1,
.comic-sans-ms-font .master-container h2,
.comic-sans-ms-font .master-container h3,
.comic-sans-ms-font .master-container h4,
.comic-sans-ms-font .master-container h5,
.comic-sans-ms-font .master-container h6,
.comic-sans-ms-font .master-container h1 span,
.comic-sans-ms-font .master-container h2 span,
.comic-sans-ms-font .master-container h3 span,
.comic-sans-ms-font .master-container h4 span,
.comic-sans-ms-font .master-container h5 span,
.comic-sans-ms-font .master-container h6 span,
.comic-sans-ms-font-body .master-container p,
.comic-sans-ms-font-body .master-container ul,
.comic-sans-ms-font-body .master-container span,
.comic-sans-ms-font-body .master-container .blog-post-content,
.comic-sans-ms-font-body .font-group div:after,
.comic-sans-ms-font-nav .master-container .nav-bar,
.font-group .comic-sans-ms-font,
.font-group .comic-sans-ms-font-body,
.font-group .comic-sans-ms-font-nav,
.font-group .comic-sans-ms-font-footer,

.font-group .comic-sans-ms-font-button,
.explore-btn.comic-sans-ms-font-button,

.comic-sans-ms-font-footer .site-footer {
	font-family: 'Comic Sans MS';
}

.courier-new-font .master-container h1,
.courier-new-font .master-container h2,
.courier-new-font .master-container h3,
.courier-new-font .master-container h4,
.courier-new-font .master-container h5,
.courier-new-font .master-container h6,
.courier-new-font .master-container h1 span,
.courier-new-font .master-container h2 span,
.courier-new-font .master-container h3 span,
.courier-new-font .master-container h4 span,
.courier-new-font .master-container h5 span,
.courier-new-font .master-container h6 span,
.courier-new-font-body .master-container p,
.courier-new-font-body .master-container ul,
.courier-new-font-body .master-container span,
.courier-new-font-body .master-container .blog-post-content,
.courier-new-font-body .font-group div:after,
.courier-new-font-nav .master-container .nav-bar,
.font-group .courier-new-font,
.font-group .courier-new-font-body,
.font-group .courier-new-font-nav,
.font-group .courier-new-font-footer,

.font-group .courier-new-font-button,
.explore-btn.courier-new-font-button,

.courier-new-font-footer .site-footer {
	font-family: 'Courier New';
}

.georgia-font .master-container h1,
.georgia-font .master-container h2,
.georgia-font .master-container h3,
.georgia-font .master-container h4,
.georgia-font .master-container h5,
.georgia-font .master-container h6,
.georgia-font .master-container h1 span,
.georgia-font .master-container h2 span,
.georgia-font .master-container h3 span,
.georgia-font .master-container h4 span,
.georgia-font .master-container h5 span,
.georgia-font .master-container h6 span,
.georgia-font-body .master-container p,
.georgia-font-body .master-container ul,
.georgia-font-body .master-container span,
.georgia-font-body .master-container .blog-post-content,
.georgia-font-body .font-group div:after,
.georgia-font-nav .master-container .nav-bar,
.font-group .georgia-font,
.font-group .georgia-font-body,
.font-group .georgia-font-nav,
.font-group .georgia-font-footer,

.font-group .georgia-font-button,
.explore-btn.georgia-font-button,

.georgia-font-footer .site-footer {
	font-family: 'Georgia';
}

.impact-font .master-container h1,
.impact-font .master-container h2,
.impact-font .master-container h3,
.impact-font .master-container h4,
.impact-font .master-container h5,
.impact-font .master-container h6,
.impact-font .master-container h1 span,
.impact-font .master-container h2 span,
.impact-font .master-container h3 span,
.impact-font .master-container h4 span,
.impact-font .master-container h5 span,
.impact-font .master-container h6 span,
.impact-font-body .master-container p,
.impact-font-body .master-container ul,
.impact-font-body .master-container span,
.impact-font-body .master-container .blog-post-content,
.impact-font-body .font-group div:after,
.impact-font-nav .master-container .nav-bar,
.font-group .impact-font,
.font-group .impact-font-body,
.font-group .impact-font-nav,
.font-group .impact-font-footer,

.font-group .impact-font-button,
.explore-btn.impact-font-button,

.impact-font-footer .site-footer {
	font-family: 'Impact';
}

.lucida-console-font .master-container h1,
.lucida-console-font .master-container h2,
.lucida-console-font .master-container h3,
.lucida-console-font .master-container h4,
.lucida-console-font .master-container h5,
.lucida-console-font .master-container h6,
.lucida-console-font .master-container h1 span,
.lucida-console-font .master-container h2 span,
.lucida-console-font .master-container h3 span,
.lucida-console-font .master-container h4 span,
.lucida-console-font .master-container h5 span,
.lucida-console-font .master-container h6 span,
.lucida-console-font-body .master-container p,
.lucida-console-font-body .master-container ul,
.lucida-console-font-body .master-container span,
.lucida-console-font-body .master-container .blog-post-content,
.lucida-console-font-body .font-group div:after,
.lucida-console-font-nav .master-container .nav-bar,
.font-group .lucida-console-font,
.font-group .lucida-console-font-body,
.font-group .lucida-console-font-nav,
.font-group .lucida-console-font-footer,

.font-group .lucida-console-font-button,
.explore-btn.lucida-console-font-button,

.lucida-console-font-footer .site-footer {
	font-family: 'Lucida Console';
}

.palatino-font .master-container h1,
.palatino-font .master-container h2,
.palatino-font .master-container h3,
.palatino-font .master-container h4,
.palatino-font .master-container h5,
.palatino-font .master-container h6,
.palatino-font .master-container h1 span,
.palatino-font .master-container h2 span,
.palatino-font .master-container h3 span,
.palatino-font .master-container h4 span,
.palatino-font .master-container h5 span,
.palatino-font .master-container h6 span,
.palatino-font-body .master-container p,
.palatino-font-body .master-container ul,
.palatino-font-body .master-container span,
.palatino-font-body .master-container .blog-post-content,
.palatino-font-body .font-group div:after,
.palatino-font-nav .master-container .nav-bar,
.font-group .palatino-font,
.font-group .palatino-font-body,
.font-group .palatino-font-nav,
.font-group .palatino-font-footer,

.font-group .palatino-font-button,
.explore-btn.palatino-font-button,

.palatino-font-footer .site-footer {
	font-family: 'Palatino';
}

.tahoma-font .master-container h1,
.tahoma-font .master-container h2,
.tahoma-font .master-container h3,
.tahoma-font .master-container h4,
.tahoma-font .master-container h5,
.tahoma-font .master-container h6,
.tahoma-font .master-container h1 span,
.tahoma-font .master-container h2 span,
.tahoma-font .master-container h3 span,
.tahoma-font .master-container h4 span,
.tahoma-font .master-container h5 span,
.tahoma-font .master-container h6 span,
.tahoma-font-body .master-container p,
.tahoma-font-body .master-container ul,
.tahoma-font-body .master-container span,
.tahoma-font-body .master-container .blog-post-content,
.tahoma-font-body .font-group div:after,
.tahoma-font-nav .master-container .nav-bar,
.font-group .tahoma-font,
.font-group .tahoma-font-body,
.font-group .tahoma-font-nav,
.font-group .tahoma-font-footer,

.font-group .tahoma-font-button,
.explore-btn.tahoma-font-button,

.tahoma-font-footer .site-footer {
	font-family: 'Tahoma';
}

.times-new-roman-font .master-container h1,
.times-new-roman-font .master-container h2,
.times-new-roman-font .master-container h3,
.times-new-roman-font .master-container h4,
.times-new-roman-font .master-container h5,
.times-new-roman-font .master-container h6,
.times-new-roman-font .master-container h1 span,
.times-new-roman-font .master-container h2 span,
.times-new-roman-font .master-container h3 span,
.times-new-roman-font .master-container h4 span,
.times-new-roman-font .master-container h5 span,
.times-new-roman-font .master-container h6 span,
.times-new-roman-font-body .master-container p,
.times-new-roman-font-body .master-container ul,
.times-new-roman-font-body .master-container span,
.times-new-roman-font-body .master-container .blog-post-content,
.times-new-roman-font-body .font-group div:after,
.times-new-roman-font-nav .master-container .nav-bar,
.font-group .times-new-roman-font,
.font-group .times-new-roman-font-body,
.font-group .times-new-roman-font-nav,
.font-group .times-new-roman-font-footer,

.font-group .times-new-roman-font-button,
.explore-btn.times-new-roman-font-button,

.times-new-roman-font-footer .site-footer {
	font-family: 'Times New Roman';
}

.trebuchet-ms-font .master-container h1,
.trebuchet-ms-font .master-container h2,
.trebuchet-ms-font .master-container h3,
.trebuchet-ms-font .master-container h4,
.trebuchet-ms-font .master-container h5,
.trebuchet-ms-font .master-container h6,
.trebuchet-ms-font .master-container h1 span,
.trebuchet-ms-font .master-container h2 span,
.trebuchet-ms-font .master-container h3 span,
.trebuchet-ms-font .master-container h4 span,
.trebuchet-ms-font .master-container h5 span,
.trebuchet-ms-font .master-container h6 span,
.trebuchet-ms-font-body .master-container p,
.trebuchet-ms-font-body .master-container ul,
.trebuchet-ms-font-body .master-container span,
.trebuchet-ms-font-body .master-container .blog-post-content,
.trebuchet-ms-font-body .font-group div:after,
.trebuchet-ms-font-nav .master-container .nav-bar,
.font-group .trebuchet-ms-font,
.font-group .trebuchet-ms-font-body,
.font-group .trebuchet-ms-font-nav,
.font-group .trebuchet-ms-font-footer,

.font-group .trebuchet-ms-font-button,
.explore-btn.trebuchet-ms-font-button,

.trebuchet-ms-font-footer .site-footer {
	font-family: 'Trebuchet MS';
}

.verdana-font .master-container h1,
.verdana-font .master-container h2,
.verdana-font .master-container h3,
.verdana-font .master-container h4,
.verdana-font .master-container h5,
.verdana-font .master-container h6,
.verdana-font .master-container h1 span,
.verdana-font .master-container h2 span,
.verdana-font .master-container h3 span,
.verdana-font .master-container h4 span,
.verdana-font .master-container h5 span,
.verdana-font .master-container h6 span,
.verdana-font-body .master-container p,
.verdana-font-body .master-container ul,
.verdana-font-body .master-container span,
.verdana-font-body .master-container .blog-post-content,
.verdana-font-body .font-group div:after,
.verdana-font-nav .master-container .nav-bar,
.font-group .verdana-font,
.font-group .verdana-font-body,
.font-group .verdana-font-nav,
.font-group .verdana-font-footer,

.font-group .verdana-font-button,
.explore-btn.verdana-font-button,

.verdana-font-footer .site-footer {
	font-family: 'Verdana';
}


				
/* FONTS END */
body:not(.edit-body-mode) .flex-container,
body:not(.edit-body-mode) .design-block-background-wrapper {
	-webkit-animation:contentFadeIn 1s forwards;
	        animation:contentFadeIn 1s forwards;
}


@-webkit-keyframes contentFadeIn {
	0% {opacity: 1;}
	100% {opacity: 1;}
}


@keyframes contentFadeIn {
	0% {opacity: 1;}
	100% {opacity: 1;}
}
/****************************TABLE OF CONTENTS******************************/

.col-50.map-container {
	opacity: 0;
	-webkit-animation: mapIn 1s ease forwards;
	        animation: mapIn 1s ease forwards;
	-webkit-animation-delay: 0.3s;
	        animation-delay: 0.3s;
}
.month-container h3 {
	opacity: 0;
	-webkit-animation: fadeRight 1s ease forwards;
	        animation: fadeRight 1s ease forwards;
}
.month-container .month-row {
	opacity: 0;
	-webkit-animation: fadeBottom 1s ease forwards;
	        animation: fadeBottom 1s ease forwards;
	-webkit-animation-delay: .6s;
	        animation-delay: .6s;
}

.month-container:first-of-type .month-row {
	-webkit-animation-delay: 0.3s;
	        animation-delay: 0.3s;
}

.month-container:before {
		-webkit-transform: scale(0,0);
		        transform: scale(0,0);
		-webkit-animation: scaleIn 0.3s ease forwards;
		        animation: scaleIn 0.3s ease forwards;
		-webkit-animation-delay: 0.3s;
		        animation-delay: 0.3s;

}

.month-container:after {
	-webkit-transform: scale(1,0);
	        transform: scale(1,0);
	-webkit-animation: timeLine 0.7s ease forwards;
	        animation: timeLine 0.7s ease forwards;
	-webkit-animation-delay: 1.3s;
	        animation-delay: 1.3s;
	-webkit-transform-origin: 0% 0%;
	        transform-origin: 0% 0%;
}


@-webkit-keyframes timeLine {
	0% {-webkit-transform: scale(1,0);transform: scale(1,0);}
	100% {-webkit-transform: scale(1,1);transform: scale(1,1);}
}


@keyframes timeLine {
	0% {-webkit-transform: scale(1,0);transform: scale(1,0);}
	100% {-webkit-transform: scale(1,1);transform: scale(1,1);}
}

@-webkit-keyframes scaleIn {
	0% {-webkit-transform: scale(0,0);transform: scale(0,0);}
	100% {-webkit-transform: scale(1,1);transform: scale(1,1);}
}

@keyframes scaleIn {
	0% {-webkit-transform: scale(0,0);transform: scale(0,0);}
	100% {-webkit-transform: scale(1,1);transform: scale(1,1);}
}


@-webkit-keyframes mapIn{
	0% {-webkit-transform: scale(0.95,0.95);transform: scale(0.95,0.95); opacity: 0;}
	100% {-webkit-transform: scale(1,1);transform: scale(1,1); opacity: 1;}
}


@keyframes mapIn{
	0% {-webkit-transform: scale(0.95,0.95);transform: scale(0.95,0.95); opacity: 0;}
	100% {-webkit-transform: scale(1,1);transform: scale(1,1); opacity: 1;}
}

@-webkit-keyframes fadeRight {
	0% {-webkit-transform: translateX(20px);transform: translateX(20px); opacity: 0}
	100% {-webkit-transform: translateX(0px);transform: translateX(0px); opacity: 1}
}

@keyframes fadeRight {
	0% {-webkit-transform: translateX(20px);transform: translateX(20px); opacity: 0}
	100% {-webkit-transform: translateX(0px);transform: translateX(0px); opacity: 1}
}


@-webkit-keyframes fadeBottom {
	0% {-webkit-transform: translateY(30px);transform: translateY(30px); opacity: 0}
	100% {-webkit-transform: translateY(0px);transform: translateY(0px); opacity: 1}
}


@keyframes fadeBottom {
	0% {-webkit-transform: translateY(30px);transform: translateY(30px); opacity: 0}
	100% {-webkit-transform: translateY(0px);transform: translateY(0px); opacity: 1}
}

@-webkit-keyframes fadeTop {
	0% {-webkit-transform: translateY(-20px);transform: translateY(-20px); opacity: 0}
	100% {-webkit-transform: translateY(0px);transform: translateY(0px); opacity: 1}
}

@keyframes fadeTop {
	0% {-webkit-transform: translateY(-20px);transform: translateY(-20px); opacity: 0}
	100% {-webkit-transform: translateY(0px);transform: translateY(0px); opacity: 1}
}



/****************************COVER TEXT and LOGO******************************/
.cover-text {
	-webkit-animation: coverText 0.8s ease forwards;
	        animation: coverText 0.8s ease forwards;
	-webkit-animation-delay: 2s;
	        animation-delay: 2s;
	opacity: 0;
}

.cover-info img.icon-image {
	opacity: 0;
	margin-left: calc(100% - 55px);
	-webkit-animation: coverLogo 2s ease forwards;
	        animation: coverLogo 2s ease forwards;
}



@-webkit-keyframes coverLogo {
	0% {-webkit-transform: translateY(10px);transform: translateY(10px); opacity: 0;margin-left: calc(100% - 55px);}
	20% {-webkit-transform: translateY(0px) rotate(0deg);transform: translateY(0px) rotate(0deg); opacity: 1;margin-left: calc(100% - 55px);}
	60% {-webkit-transform: translateY(0px) rotate(20deg);transform: translateY(0px) rotate(20deg); margin-left: calc(100% - 55px);opacity: 1}
	100% {-webkit-transform: translateY(0px) rotate(0deg);transform: translateY(0px) rotate(0deg);; margin-left: 0px; opacity: 1}
}



@keyframes coverLogo {
	0% {-webkit-transform: translateY(10px);transform: translateY(10px); opacity: 0;margin-left: calc(100% - 55px);}
	20% {-webkit-transform: translateY(0px) rotate(0deg);transform: translateY(0px) rotate(0deg); opacity: 1;margin-left: calc(100% - 55px);}
	60% {-webkit-transform: translateY(0px) rotate(20deg);transform: translateY(0px) rotate(20deg); margin-left: calc(100% - 55px);opacity: 1}
	100% {-webkit-transform: translateY(0px) rotate(0deg);transform: translateY(0px) rotate(0deg);; margin-left: 0px; opacity: 1}
}


@-webkit-keyframes coverText {

	0% {-webkit-transform: translateY(20px);transform: translateY(20px); opacity: 0}
	100% {-webkit-transform: translateY(0px);transform: translateY(0px); opacity: 1}
}


@keyframes coverText {

	0% {-webkit-transform: translateY(20px);transform: translateY(20px); opacity: 0}
	100% {-webkit-transform: translateY(0px);transform: translateY(0px); opacity: 1}
}



/****************************COVER PHOTOS******************************/
.cover-4.no-slideshow .cover-photos .photo-row img {
	-webkit-animation:none!important;
	        animation:none!important;
	opacity: 1!important;
}
.cover-photos .photo-row img {
	opacity: 0;
	-webkit-animation: coverImages 1.2s ease forwards;
	        animation: coverImages 1.2s ease forwards;
}

/***FIRST ROW***/
.cover-photos .photo-row:first-of-type img:nth-child(1) {
	-webkit-animation-delay:1.1s;
	        animation-delay:1.1s;
}
.cover-photos .photo-row:first-of-type img:nth-child(2) {
	-webkit-animation-delay:1.2s;
	        animation-delay:1.2s;
}

/**MIDDLE ROW**/
.cover-photos .photo-row img:nth-child(1) {
	-webkit-animation-delay:1.3s;
	        animation-delay:1.3s;
}
.cover-photos .photo-row img:nth-child(2) {
	-webkit-animation-delay:1.4s;
	        animation-delay:1.4s;
}

/***LAST ROW***/
.cover-photos .photo-row:last-of-type img:nth-child(1) {
	-webkit-animation-delay:1.5s;
	        animation-delay:1.5s;
}
.cover-photos .photo-row:last-of-type img:nth-child(2) {
	-webkit-animation-delay:1.6s;
	        animation-delay:1.6s;
}
.cover-photos .photo-row:last-of-type img:nth-child(3) {
	-webkit-animation-delay:1.7s;
	        animation-delay:1.7s;
}


.cover-2 .cover-photos {
	-webkit-animation:coverImages2 10s ease forwards;
	        animation:coverImages2 10s ease forwards;
}
.cover-2 .cover-text {
	opacity: 0;
	-webkit-animation: fadeBottom 1s ease forwards;
	        animation: fadeBottom 1s ease forwards;
	-webkit-animation-delay: 0.6s;
	        animation-delay: 0.6s;
	max-width: 600px;
}
.cover-2 .cover-info img.icon-image {
	opacity: 0;
	-webkit-animation: fadeTop 1s ease forwards;
	        animation: fadeTop 1s ease forwards;
	margin-left: 0px;
	margin-right: 0px;
}



@-webkit-keyframes coverImages2 {
	0% {-webkit-transform: translateX(0px);transform: translateX(0px);}
	100% {-webkit-transform: translateX(-200px);transform: translateX(-200px);}
}



@keyframes coverImages2 {
	0% {-webkit-transform: translateX(0px);transform: translateX(0px);}
	100% {-webkit-transform: translateX(-200px);transform: translateX(-200px);}
}
@-webkit-keyframes coverImages {
	0% {-webkit-transform: translateX(30px) scale(0.9,0.9);transform: translateX(30px) scale(0.9,0.9); opacity: 0}
	100% {-webkit-transform: translateX(0px) scale(1,1);transform: translateX(0px) scale(1,1); opacity: 1}
}
@keyframes coverImages {
	0% {-webkit-transform: translateX(30px) scale(0.9,0.9);transform: translateX(30px) scale(0.9,0.9); opacity: 0}
	100% {-webkit-transform: translateX(0px) scale(1,1);transform: translateX(0px) scale(1,1); opacity: 1}
}


/******PAGE TRANSITIONS**********/


/****ANIMATIONS CONTROLLERS****/

	.article-title {
		opacity: 0;
		-webkit-animation: fadeIn 1s ease forwards;
		        animation: fadeIn 1s ease forwards;
		-webkit-animation-delay: 0.6s;
		        animation-delay: 0.6s;
		padding-bottom: 20px;
	}

	.article-content,
	.contact-content,
	.contact-form,
	.blog-text-content {
		opacity: 0;
		-webkit-animation: fadeIn 1s ease forwards;
		        animation: fadeIn 1s ease forwards;
	}

	.article-image {
		opacity: 0;
		-webkit-animation: fadeIn 1s ease forwards;
		        animation: fadeIn 1s ease forwards;
		-webkit-animation-delay: 0.2s;
		        animation-delay: 0.2s;
	}
	.article-stats {
		opacity: 0;
		background: #fff;
		-webkit-animation: fadeInTop 1s ease forwards;
		        animation: fadeInTop 1s ease forwards;
	-webkit-animation-delay: 0.4s;
	        animation-delay: 0.4s;
	}

	.animate-out .page-loader {
		opacity: 1;
		visibility: visible;
		-webkit-transform: scale(1.4,1.4) translateY(0px);
		        transform: scale(1.4,1.4) translateY(0px);
		transition-delay: 0.6s;
	}

	.animate-out .flex-container {
		-webkit-animation: fadeOutDown 1s forwards;
		        animation: fadeOutDown 1s forwards;
	}


	.animate-out .article-content {
		-webkit-animation: fadeOutUp .6s forwards;
		        animation: fadeOutUp .6s forwards;
	}
	.animate-out .article-image {
		-webkit-animation: fadeOutDown .8s forwards;
		        animation: fadeOutDown .8s forwards;

	}

@-webkit-keyframes fadeIn {
	0% {opacity: 0}
	100% {opacity: 1}
}

@keyframes fadeIn {
	0% {opacity: 0}
	100% {opacity: 1}
}
@-webkit-keyframes fadeInNew {
	0% {opacity: 0}
	100% {opacity: 1}
}
@keyframes fadeInNew {
	0% {opacity: 0}
	100% {opacity: 1}
}
@-webkit-keyframes fadeInFromRight {
	0% {opacity: 0; -webkit-transform:translateY(200px); transform:translateY(200px);}
	100% {opacity: 1; -webkit-transform:translateY(0px); transform:translateY(0px);}
}
@keyframes fadeInFromRight {
	0% {opacity: 0; -webkit-transform:translateY(200px); transform:translateY(200px);}
	100% {opacity: 1; -webkit-transform:translateY(0px); transform:translateY(0px);}
}

@-webkit-keyframes fadeInLeft {
	0% {opacity: 0; -webkit-transform:translateX(-50px); transform:translateX(-50px);}
	100% {opacity: 1; -webkit-transform:translateY(0px); transform:translateY(0px);}
}

@keyframes fadeInLeft {
	0% {opacity: 0; -webkit-transform:translateX(-50px); transform:translateX(-50px);}
	100% {opacity: 1; -webkit-transform:translateY(0px); transform:translateY(0px);}
}
@-webkit-keyframes fadeInRight {
	0% {opacity: 0; -webkit-transform:translateX(50px); transform:translateX(50px);}
	100% {opacity: 1; -webkit-transform:translateY(0px); transform:translateY(0px);}
}
@keyframes fadeInRight {
	0% {opacity: 0; -webkit-transform:translateX(50px); transform:translateX(50px);}
	100% {opacity: 1; -webkit-transform:translateY(0px); transform:translateY(0px);}
}
@-webkit-keyframes fadeInTop {
	0% {-webkit-transform: translateY(-20px);transform: translateY(-20px); opacity: 0}
	100% {-webkit-transform: translateY(0px);transform: translateY(0px); opacity: 1}
}
@keyframes fadeInTop {
	0% {-webkit-transform: translateY(-20px);transform: translateY(-20px); opacity: 0}
	100% {-webkit-transform: translateY(0px);transform: translateY(0px); opacity: 1}
}
@-webkit-keyframes fadeInBottom {
	0% {-webkit-transform: translateY(20px);transform: translateY(20px); opacity: 0}
	100% {-webkit-transform: translateY(0px);transform: translateY(0px); opacity: 1}
}
@keyframes fadeInBottom {
	0% {-webkit-transform: translateY(20px);transform: translateY(20px); opacity: 0}
	100% {-webkit-transform: translateY(0px);transform: translateY(0px); opacity: 1}
}

@-webkit-keyframes fadeInPage {
	0% {-webkit-transform: translateY(20px);transform: translateY(20px); opacity: 0}
	100% {-webkit-transform: translateY(0px);transform: translateY(0px); opacity: 1}
}

@keyframes fadeInPage {
	0% {-webkit-transform: translateY(20px);transform: translateY(20px); opacity: 0}
	100% {-webkit-transform: translateY(0px);transform: translateY(0px); opacity: 1}
}

@-webkit-keyframes shrinkDown {
	0% {-webkit-transform: scale(1,1);transform: scale(1,1);}
	50% {-webkit-transform: scale(0.9,0.9);transform: scale(0.9,0.9);}
	100% {-webkit-transform: scale(1,1);transform: scale(1,1);}
}

@keyframes shrinkDown {
	0% {-webkit-transform: scale(1,1);transform: scale(1,1);}
	50% {-webkit-transform: scale(0.9,0.9);transform: scale(0.9,0.9);}
	100% {-webkit-transform: scale(1,1);transform: scale(1,1);}
}

@-webkit-keyframes pulseLogo {
	0% {box-shadow: 0 0 0 0 rgba(0,0,0,0)}
	50% {box-shadow: 0 0 0 10px rgba(0,0,0,0.1)}
	100% {box-shadow: 0 0 0 0px rgba(0,0,0,0)}
}

@keyframes pulseLogo {
	0% {box-shadow: 0 0 0 0 rgba(0,0,0,0)}
	50% {box-shadow: 0 0 0 10px rgba(0,0,0,0.1)}
	100% {box-shadow: 0 0 0 0px rgba(0,0,0,0)}
}


@-webkit-keyframes imageIn {
	0% {-webkit-transform: translateY(-20px) scale(1,1);transform: translateY(-20px) scale(1,1); opacity: 0;}
	100% {-webkit-transform: translateY(0px) scale(1,1);transform: translateY(0px) scale(1,1); opacity: 1;}
}


@keyframes imageIn {
	0% {-webkit-transform: translateY(-20px) scale(1,1);transform: translateY(-20px) scale(1,1); opacity: 0;}
	100% {-webkit-transform: translateY(0px) scale(1,1);transform: translateY(0px) scale(1,1); opacity: 1;}
}

@-webkit-keyframes fadeOutDown {
	0% {-webkit-transform: translateY(0px);transform: translateY(0px); opacity: 1}
	100% {-webkit-transform: translateY(100px);transform: translateY(100px); opacity: 0}
}

@keyframes fadeOutDown {
	0% {-webkit-transform: translateY(0px);transform: translateY(0px); opacity: 1}
	100% {-webkit-transform: translateY(100px);transform: translateY(100px); opacity: 0}
}
@-webkit-keyframes fadeOutUp {
	0% {-webkit-transform: translateY(0px);transform: translateY(0px); opacity: 1}
	100% {-webkit-transform: translateY(-100px);transform: translateY(-100px); opacity: 0}
}
@keyframes fadeOutUp {
	0% {-webkit-transform: translateY(0px);transform: translateY(0px); opacity: 1}
	100% {-webkit-transform: translateY(-100px);transform: translateY(-100px); opacity: 0}
}
@-webkit-keyframes fadeOutRight {
	0% {-webkit-transform: translateX(0px);transform: translateX(0px); opacity: 1}
	100% {-webkit-transform: translateX(50px);transform: translateX(50px); opacity: 0}
}
@keyframes fadeOutRight {
	0% {-webkit-transform: translateX(0px);transform: translateX(0px); opacity: 1}
	100% {-webkit-transform: translateX(50px);transform: translateX(50px); opacity: 0}
}
@-webkit-keyframes fadeOutLeft {
	0% {-webkit-transform: translateX(0px);transform: translateX(0px); opacity: 1}
	100% {-webkit-transform: translateX(-50px);transform: translateX(-50px); opacity: 0}
}
@keyframes fadeOutLeft {
	0% {-webkit-transform: translateX(0px);transform: translateX(0px); opacity: 1}
	100% {-webkit-transform: translateX(-50px);transform: translateX(-50px); opacity: 0}
}

.fadeOutDown {
	-webkit-animation: fadeOutDown .6s forwards;
	        animation: fadeOutDown .6s forwards;
}
.fadeOutUp {
	-webkit-animation: fadeOutUp .6s forwards;
	        animation: fadeOutUp .6s forwards;
}

.fadeOutRight {
	-webkit-animation: fadeOutRight .6s forwards;
	        animation: fadeOutRight .6s forwards;
}
.article-content.fadeOutDown {
	-webkit-animation-delay: 0.1s;
	        animation-delay: 0.1s;
}
.cover-info.fadeOutDown {
	-webkit-animation-delay: 0.2s;
	        animation-delay: 0.2s;
}




























.flex-container#loadingFlex {
  min-height: 80vh;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-animation:fadeTop 1s forwards;
          animation:fadeTop 1s forwards;
}
.website-loader-wrapper strong {
  font-weight: 600;
  font-size: 14px;
  margin-top: 10px;
  color: rgba(0,0,0,0.3);
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 30px;
  left: 0;
  text-transform: uppercase;
}
.website-loader-wrapper .cai-bot-icon {
  width: 80px;
  height: 80px;
  /*animation: botIn 1s forwards;*/
  -webkit-animation: botIconPulse 2s ease infinite, loaderShade 1s infinite;
          animation: botIconPulse 2s ease infinite, loaderShade 1s infinite;
  -webkit-animation-timing-function: none;
          animation-timing-function: none;
  border-radius: 50%;
  box-shadow: 0 0 16px rgba(237,0,170,0);
  margin:0;
  position: absolute;
  left: calc(50% - 40px);
  top:calc(50% - 40px);
}
@-webkit-keyframes loaderShade {
  0% {box-shadow: 0 0 0 0 rgba(0,0,0,0.2)}
  100% {box-shadow: 0 0 0 10px rgba(0,0,0,0)}
}
@keyframes loaderShade {
  0% {box-shadow: 0 0 0 0 rgba(0,0,0,0.2)}
  100% {box-shadow: 0 0 0 10px rgba(0,0,0,0)}
}
.website-loader-wrapper .loader-logo {
  position: absolute;
  object-fit: contain;
  z-index: 3;
}

.website-loader-wrapper .cai-bot-icon {
  /*background: rgba(237,0,170,0.1);*/
  background: rgba(237,0,170,0.1);
  mix-blend-mode: multiply;
}
.website-loader-wrapper .cai-bot-icon:after {
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  top: 3px;
  left: 3px;
  background: #fff;
  box-shadow:inset 0 0 16px rgba(237,0,170,0);
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation: none;
          animation: none;
  opacity: 1;
}
.website-loader-wrapper .cai-bot-icon:before {
  /*height: 50%;
  width: 50%;
  border-radius: 0;
  transform-origin: 100% 100%;
  box-shadow: none;*/
  /*background: #99A9C5;
  box-shadow: 0 0 10px #99A9C5;*/
  -webkit-animation-duration: 1.2s;
          animation-duration: 1.2s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  /*background: linear-gradient(45deg, rgba(30,87,153,0) 1%,rgba(100,57,159,0) 10%,rgba(233,2,170,1) 50%,rgba(237,0,170,0.97) 51%,rgba(166,117,209,0) 90%,rgba(125,185,232,0) 100%);*/
}
.website-loader-wrapper {
  width: 100vw;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.website-loader-wrapper h4 {
  margin:10px 0 0 0;
  font-size: 16px;
}
.website-loader-wrapper span {
  font-size: 12px;
  text-transform: uppercase;
  opacity: 0.2;
}

.website-loader-wrapper .branded-loading h5 {
  margin:0;
  font-weight: normal;
  font-family: Montserrat;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 15px;
}
.website-loader-wrapper .branded-loading h5:before {
  width: 30px;
  height: 30px;
  content: " ";
  display: block;
  border:solid 3px #333;
  border-radius: 50%;
  margin-bottom: 15px;
  -webkit-animation: pulsateLoader 1s ease-out infinite;
          animation: pulsateLoader 1s ease-out infinite;
  opacity: 1;
  margin:15px auto;
}
@-webkit-keyframes pulsateLoader {
    0% {-webkit-transform: scale(0.1, 0.1);transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(1.2, 1.2);transform: scale(1.2, 1.2); opacity: 0.0;}
}
@keyframes pulsateLoader {
    0% {-webkit-transform: scale(0.1, 0.1);transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(1.2, 1.2);transform: scale(1.2, 1.2); opacity: 0.0;}
}
@-webkit-keyframes loading {
  from {-webkit-transform: rotate(0deg);transform: rotate(0deg)}
  from {-webkit-transform: rotate(-360deg);transform: rotate(-360deg)}
}
@keyframes loading {
  from {-webkit-transform: rotate(0deg);transform: rotate(0deg)}
  from {-webkit-transform: rotate(-360deg);transform: rotate(-360deg)}
}
.website-loader-wrapper .branded-loading h4 {
  font-family: Montserrat;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 15px;
  letter-spacing: 2px;
  margin:0;
}


.website-loader-wrapper .branded-loading h4:before,
.website-loader-wrapper .branded-loading h4:after {
  height: 1px;
  background: #efefef;
  width: calc(100% + 40px);
  position: absolute;
  bottom: -10px;
  left: -20px;
  content: " ";
}

.website-loader-wrapper .branded-loading h4:after {
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(51,51,51,1) 100%);
  /*background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(51,51,51,1) 50%, rgba(255,255,255,0) 100%);*/
  -webkit-animation:brandedLoader 1.5s infinite ease;
          animation:brandedLoader 1.5s infinite ease;
}
.page-loader img {
  width: 70px;
  height: 70px;
  position: absolute;
  border-radius: 50%;
  object-fit: contain;
  left: 5px;
  top: 5px;
  z-index: 4;
  -webkit-animation: shrinkDown 1s infinite /*, pulseLogo 1s infinite*/;
          animation: shrinkDown 1s infinite /*, pulseLogo 1s infinite*/;
}
.page-loader {
  width: 80px;
  height: 80px;
  position: relative;
  z-index: 4;
  border-radius: 50%;
  background:#ccc;
  opacity: 1;
  transition: all 0.3s;
  margin-bottom: 10px;
  -webkit-animation: fadeInTop 1s forwards;
          animation: fadeInTop 1s forwards;
}

.page-loader .socicon {
  font-size: 25px;
    width: 20px;
    height: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    position: absolute;
    left: calc(50% - 10px);
    top: calc(50% - 10px);
    color: #000;
    z-index: 5;
}
.page-loader .socicon.socicon-facebook {
  color: #4267b2;
}
.page-loader .socicon.socicon-google {
  color: #cc3a31;
} 
.page-loader .socicon.socicon-youtube {
  color: #cc3a31;
}

.page-loader .socicon.socicon-linkedin {
  color: #0077B5;
}

.page-loader .socicon.socicon-instagram {
  color:#bc1888;
}

.page-loader.toc-loader {
  position: absolute;
}

.page-loader:after {
  content: " ";
    width: 78px;
    height: 78px;
    position: absolute;
    left: 1px;
    top: 1px;
    border-radius: 50%;
    background: #fff;
}

.page-loader:before {
  content: " ";
    background: #fff;
    position: absolute;
    width: 40px;
    height: 40px;
    right: 0px;
    top: 0px;
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    transition: all 0.5s ease;
    -webkit-animation: loading 1s infinite;
            animation: loading 1s infinite;
}
@-webkit-keyframes brandedLoader {
  0% {width: 0}
  50% {width: calc(100% + 40px); left: -20px}
  100% {width: calc(0px); left: calc(100% + 20px); }
}
@keyframes brandedLoader {
  0% {width: 0}
  50% {width: calc(100% + 40px); left: -20px}
  100% {width: calc(0px); left: calc(100% + 20px); }
}

.website-loader-wrapper span,
.website-loader-wrapper h4 {
  -webkit-animation: fadeInBottom 1s forwards;
          animation: fadeInBottom 1s forwards;
}
.loader {
  width: 150px;
  height: 100px;
  position: absolute;
  left: 50%;
  margin-left:-75px;
  top: 50%;
  margin-top: -50px;
  border: solid 1px #efefef;
  border-radius: 4px;
  background: #fff;
  box-shadow: 13px 13px 46px 0 rgba(0,0,0,0.08);
  padding:15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
/*.loader:after {
  position: absolute;
  content: "Creating your click";
  font-size: 12px;
  bottom: -25px;
  color: #ccc;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  left: 0px;
}*/

.loader .square {
  width: 45%;
  height: 100%; 
  box-shadow:1px 1px 0px 1px #4eb170;
  background: #5FBF80;
  -webkit-animation: loader 1.3s infinite;
          animation: loader 1.3s infinite;
}

.loader .text-square {
  width: 45%;
  height: 7px;  
  position: relative;
  margin-top: 10px;
  box-shadow:1px 1px 0px 1px #4eb170;
  background: #5FBF80;
  -webkit-animation: loaderText 1.2s infinite;
          animation: loaderText 1.2s infinite;
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;  
}
.loader .text-square:before, .loader .text-square:after {
  width: 70%;
  height: 2px;
  background: #5FBF80;
  box-shadow:1px 1px 0px 1px #4eb170;
  position: absolute;
  bottom: -7px;
  content: " ";
}
.loader .text-square:after {
  width: 50%;
  bottom: -14px;
}
@-webkit-keyframes loader {
  0% {-webkit-transform: scale(1,1);transform: scale(1,1)}
  50% {-webkit-transform: scale(0.95,0.95);transform: scale(0.95,0.95)}
  100% {-webkit-transform: scale(1,1);transform: scale(1,1)}
}
@keyframes loader {
  0% {-webkit-transform: scale(1,1);transform: scale(1,1)}
  50% {-webkit-transform: scale(0.95,0.95);transform: scale(0.95,0.95)}
  100% {-webkit-transform: scale(1,1);transform: scale(1,1)}
}

@-webkit-keyframes loaderText {
  0% {opacity:1;-webkit-transform: scale(0,1);transform: scale(0,1)}
  50% {opacity:1;-webkit-transform: scale(1,1);transform: scale(1,1)}
  90% {opacity:1;-webkit-transform: scale(1,1);transform: scale(1,1)}
  100% {opacity: 0;}
}

@keyframes loaderText {
  0% {opacity:1;-webkit-transform: scale(0,1);transform: scale(0,1)}
  50% {opacity:1;-webkit-transform: scale(1,1);transform: scale(1,1)}
  90% {opacity:1;-webkit-transform: scale(1,1);transform: scale(1,1)}
  100% {opacity: 0;}
}

.click-loading-container {
	width: 100vw;
	height: 100vh;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-content: center;
	        align-content: center;
	-webkit-align-items: center;
	        align-items: center;
}
.click-loading-container img {
	width: 100px;
	-webkit-animation:loadPulse 1s infinite;
	        animation:loadPulse 1s infinite;
}

.master_container .click-loading-container img {
	position: fixed;
	left: calc(50% - 50px);
	top: calc(50% - 10px);
}

@-webkit-keyframes loadPulse {
	0% {opacity: 1; -webkit-transform: scale(1,1); transform: scale(1,1)}
	50% {opacity: 0.7; -webkit-transform: scale(1.1,1.1); transform: scale(1.1,1.1)}
	100% {opacity: 1; -webkit-transform: scale(1,1); transform: scale(1,1)}
}

@keyframes loadPulse {
	0% {opacity: 1; -webkit-transform: scale(1,1); transform: scale(1,1)}
	50% {opacity: 0.7; -webkit-transform: scale(1.1,1.1); transform: scale(1.1,1.1)}
	100% {opacity: 1; -webkit-transform: scale(1,1); transform: scale(1,1)}
}

/***FOLDING LOADER***/
.sk-folding-cube-container {
	width: 100vw;
	height: 100vh;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-content: center;
	        align-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.sk-folding-cube-wrapper {
	height: 100vh;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-content: center;
	        align-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.sk-folding-cube {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
          -webkit-transform: rotateZ(45deg);
                  transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
          -webkit-transform: scale(1.1);
                  transform: scale(1.1); 
}
.sk-folding-cube .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:linear-gradient(-45deg, #455173 0%, #21283F 100%);
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
          animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
          -webkit-transform: scale(1.1) rotateZ(90deg);
                  transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
          -webkit-transform: scale(1.1) rotateZ(180deg);
                  transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
          -webkit-transform: scale(1.1) rotateZ(270deg);
                  transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
	background: #9BA8C3;
          -webkit-animation-delay: 0.3s;
                  animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3:before {
          -webkit-animation-delay: 0.6s;
                  animation-delay: 0.6s; 
}
.sk-folding-cube .sk-cube4:before {
          -webkit-animation-delay: 0.9s;
                  animation-delay: 0.9s;
}

@-webkit-keyframes sk-foldCubeAngle {
  0%, 10% {
            -webkit-transform: perspective(140px) rotateX(-180deg);
                    transform: perspective(140px) rotateX(-180deg);
    opacity: 0; 
  } 25%, 75% {
            -webkit-transform: perspective(140px) rotateX(0deg);
                    transform: perspective(140px) rotateX(0deg);
    opacity: 1; 
  } 90%, 100% {
            -webkit-transform: perspective(140px) rotateY(180deg);
                    transform: perspective(140px) rotateY(180deg);
    opacity: 0; 
  }
}

@keyframes sk-foldCubeAngle {
  0%, 10% {
            -webkit-transform: perspective(140px) rotateX(-180deg);
                    transform: perspective(140px) rotateX(-180deg);
    opacity: 0; 
  } 25%, 75% {
            -webkit-transform: perspective(140px) rotateX(0deg);
                    transform: perspective(140px) rotateX(0deg);
    opacity: 1; 
  } 90%, 100% {
            -webkit-transform: perspective(140px) rotateY(180deg);
                    transform: perspective(140px) rotateY(180deg);
    opacity: 0; 
  }
}


/***ADDING BLOCK LOADER***/

.adding-block-loader-wrapper {
	height: 75%;
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-animation: 0.3s addingBlockLoaderIn forwards;
	        animation: 0.3s addingBlockLoaderIn forwards;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.adding-block-loader-wrapper.edit-loader {
	width: 100vw;
	height: 100vh;
	background: #fff;
}

.adding-block-loader-wrapper.edit-loader div,
.adding-block-loader-wrapper.image-loader div {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	background: #fff;
}

.adding-block-loader-wrapper span {
	font-size: 15px;
}
.adding-block-loader-wrapper.image-loader {
	margin:100px auto;
}

.adding-block-loader-wrapper.edit-loader .adding-block-loader {
	white-space: nowrap;
	box-shadow: 0 14px 28px rgba(0,0,0,0.02), 0 10px 10px rgba(0,0,0,0.05);
}

.adding-block-loader-wrapper.edit-loader .adding-block-loader span,
.adding-block-loader-wrapper.image-loader .adding-block-loader span {
	
}

.adding-block-loader {
	width: 100px;
	height: 80px;
	position: relative;
	border-radius: 5px 5px 0 0;
	border:#21283F solid 2px;
	background: #fff;
}

.adding-block-loader:before {
	width: 100%;
	position: absolute;
	top: 10px;
	height: 2px;
	background: #21283F;
	content: " ";
	left: 0;
}

.adding-block-loader:after {
	position: absolute;
	left: 5px;
	top: -11px;
	content: "...";
	color: #21283F;
}
.adding-block-loader div {
	width: calc(100% - 20px);
    height: calc(100% - 30px);
    position: absolute;
    bottom: 10px;
    left: 10px;
    background: #21283F;
    -webkit-animation: 1s addingBlockProcess infinite;
            animation: 1s addingBlockProcess infinite;
    opacity: 0.3;
    border-radius: 6px;
}

.adding-block-loader div img {
	width: 22px;
	margin:auto;
}
.adding-block-loader span {
	position: absolute;
	bottom: -43px;
	font-size: 13px;
	text-align: center;
	width: 100%;
	left: 0;
	opacity: 0.5;
}

.adding-block-loader-wrapper strong {
	font-weight: normal;
	font-size: 15px;
	margin-top: -30px;
}

@-webkit-keyframes addingBlockLoaderIn {
	from {-webkit-transform: translateY(-20px);transform: translateY(-20px); opacity: 0;}
	to {-webkit-transform: translateY(0px);transform: translateY(0px); opacity: 1;}
}

@keyframes addingBlockLoaderIn {
	from {-webkit-transform: translateY(-20px);transform: translateY(-20px); opacity: 0;}
	to {-webkit-transform: translateY(0px);transform: translateY(0px); opacity: 1;}
}
@-webkit-keyframes addingBlockProcess {
	0% {opacity: 0.3; -webkit-transform: scale(1,1); transform: scale(1,1)}
	50% {opacity: 0.6; -webkit-transform: scale(1.05,1.05); transform: scale(1.05,1.05)}
	0% {opacity: 0.3; -webkit-transform: scale(1,1); transform: scale(1,1)}
}
@keyframes addingBlockProcess {
	0% {opacity: 0.3; -webkit-transform: scale(1,1); transform: scale(1,1)}
	50% {opacity: 0.6; -webkit-transform: scale(1.05,1.05); transform: scale(1.05,1.05)}
	0% {opacity: 0.3; -webkit-transform: scale(1,1); transform: scale(1,1)}
}


.loading-ghost {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	margin-top: 50px;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.loading-ghost div {
	width: calc(50% - 5px);
	height: 140px;
	border-radius: 6px;
	display: block;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	margin-bottom: 10px;
	background: #f6f7f8;
	background: linear-gradient(to right, #21283F 8%, #3c4665 18%, #21283F 33%);
	background-size: 800px 104px;
	opacity: 0.4;
	-webkit-animation-duration: 1.5s;
	        animation-duration: 1.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-name: placeHolderShimmer;
          animation-name: placeHolderShimmer;
}


@-webkit-keyframes placeHolderShimmer {
  0%{ background-position: -468px 0; }
  100%{ background-position: 468px 0; }
}


@keyframes placeHolderShimmer {
  0%{ background-position: -468px 0; }
  100%{ background-position: 468px 0; }
}

.infinite-loader {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	font-size: 16px;
	font-weight: normal;
	margin:15px 0 0 0;
}
.infinite-loader i {
	margin-right: 8px;
}



.adding-block-loader-wrapper svg {
	 overflow: visible;
	 width: 100px;
	 height: 150px;
	 -webkit-transform:scale(0.5);
	         transform:scale(0.5);
}
 .adding-block-loader-wrapper svg g {
	 -webkit-animation: slideSvg 2s linear infinite;
	         animation: slideSvg 2s linear infinite;
}
 .adding-block-loader-wrapper svg g:nth-child(2) {
	 -webkit-animation-delay: 0.5s;
	         animation-delay: 0.5s;
}
 .adding-block-loader-wrapper svg g:nth-child(2) path {
	 -webkit-animation-delay: 0.5s;
	         animation-delay: 0.5s;
	 stroke-dasharray: 0px 108px;
	 stroke-dashoffset: 1px;
}
.adding-block-loader-wrapper  svg path {
	 stroke: url(#gradient);
	 stroke-width: 20px;
	 stroke-linecap: round;
	 fill: none;
	 stroke-dasharray: 0 157px;
	 stroke-dashoffset: 0;
	 -webkit-animation: escalade 2s cubic-bezier(0.8, 0, 0.2, 1) infinite;
	         animation: escalade 2s cubic-bezier(0.8, 0, 0.2, 1) infinite;
}
 @-webkit-keyframes slideSvg {
	 0% {
		 -webkit-transform: translateY(-50px);
		         transform: translateY(-50px);
	}
	 100% {
		 -webkit-transform: translateY(50px);
		         transform: translateY(50px);
	}
}
 @keyframes slideSvg {
	 0% {
		 -webkit-transform: translateY(-50px);
		         transform: translateY(-50px);
	}
	 100% {
		 -webkit-transform: translateY(50px);
		         transform: translateY(50px);
	}
}
 @-webkit-keyframes escalade {
	 0% {
		 stroke-dasharray: 0 157px;
		 stroke-dashoffset: 0;
	}
	 50% {
		 stroke-dasharray: 156px 157px;
		 stroke-dashoffset: 0;
	}
	 100% {
		 stroke-dasharray: 156px 157px;
		 stroke-dashoffset: -156px;
	}
}
 @keyframes escalade {
	 0% {
		 stroke-dasharray: 0 157px;
		 stroke-dashoffset: 0;
	}
	 50% {
		 stroke-dasharray: 156px 157px;
		 stroke-dashoffset: 0;
	}
	 100% {
		 stroke-dasharray: 156px 157px;
		 stroke-dashoffset: -156px;
	}
}


/***ADD BLOCK/PAGE***/
.image-loader .block-loader-wrap {
	-webkit-transform: scale(0.3);
	        transform: scale(0.3);
}

.image-loader .block-loader-wrap .box1,
.image-loader .block-loader-wrap .box2,
.image-loader .block-loader-wrap .box3 {
	border-color: #fff;
}

.block-loader-wrap {
  width: 112px;
  height: 112px;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  background: transparent!important;
}
.block-loader-wrap .box1,
.block-loader-wrap .box2,
.block-loader-wrap .box3 {
  border: 16px solid #3c4665;
  box-sizing: border-box;
  position: absolute;
  display: block;
  background: transparent!important;
}
.block-loader-wrap .box1 {
  width: 112px;
  height: 48px;
  margin-top: 64px;
  margin-left: 0px;
  -webkit-animation: anime1 2s 0s forwards ease-in-out infinite;
          animation: anime1 2s 0s forwards ease-in-out infinite;
}
.block-loader-wrap .box2 {
  width: 48px;
  height: 48px;
  margin-top: 0px;
  margin-left: 0px;
  -webkit-animation: anime2 2s 0s forwards ease-in-out infinite;
          animation: anime2 2s 0s forwards ease-in-out infinite;
}
.block-loader-wrap .box3 {
  width: 48px;
  height: 48px;
  margin-top: 0px;
  margin-left: 64px;
  -webkit-animation: anime3 2s 0s forwards ease-in-out infinite;
          animation: anime3 2s 0s forwards ease-in-out infinite;
}
.generic-loading-spin {
  display: -webkit-flex;
  display: flex; 
  height: 400px;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center
}
@-webkit-keyframes anime1 {
  0% {
    width: 112px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
  12.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
  25% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
  37.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
  50% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
  62.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
  75% {
    width: 48px;
    height: 112px;
    margin-top: 0px;
    margin-left: 0px;
  }
  87.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
  100% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
}
@keyframes anime1 {
  0% {
    width: 112px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
  12.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
  25% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
  37.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
  50% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
  62.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
  75% {
    width: 48px;
    height: 112px;
    margin-top: 0px;
    margin-left: 0px;
  }
  87.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
  100% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
}


@-webkit-keyframes anime2 {
  0% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
  12.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
  25% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
  37.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
  50% {
    width: 112px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
  62.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }
  75% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }
  87.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }
  100% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }
}


@keyframes anime2 {
  0% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
  12.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
  25% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
  37.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
  50% {
    width: 112px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
  62.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }
  75% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }
  87.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }
  100% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }
}


@-webkit-keyframes anime3 {
  0% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }
  12.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }
  25% {
    width: 48px;
    height: 112px;
    margin-top: 0px;
    margin-left: 64px;
  }
  37.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }
  50% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }
  62.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }
  75% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }
  87.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }
  100% {
    width: 112px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
}


@keyframes anime3 {
  0% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }
  12.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }
  25% {
    width: 48px;
    height: 112px;
    margin-top: 0px;
    margin-left: 64px;
  }
  37.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }
  50% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }
  62.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }
  75% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }
  87.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }
  100% {
    width: 112px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
}
















* {
	box-sizing: border-box;
}
html, body {
	padding:0px;
	margin:0px;
	height: 100%;
}
body {
	font-family: Source Sans Pro;
	background: #fff;
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	color: #333;
}

a {
	text-decoration: none;
	color: #333;
}

.clickai-color {
	background: #262E47;
}
.instagram-color {
	background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
}
.facebook-color {
	background: #4267b2;
}
.dribbble-color {
	background: #ea4c89;
}
.unsplash-color {
	background: #000;
}
.twitter-color {
	background: #0084b4;
}
.pinterest-color {
	background: #c8232c;
}
.linkedin-color {
	background: #0077B5;
}
.youtube-color {
	background: #c4302b;
}
.messenger-color {
	background: linear-gradient(45deg, #2E67FF 0%, #FF5769 100%);
}

.whatsapp-color{
	background: #25D366
}

.fa, .fas {
	font-weight: 400;
}

.design-block-background-wrapper p,
.flex-container p {
	margin:0;
	line-height: 1.4em;
}
@media (max-width: 779px) {
	.design-block-background-wrapper p span,
	.flex-container p span {
		font-size: inherit!important;
	}
}
.flex-container {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-flex-direction: row;
	        flex-direction: row;
	position: relative;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.flex-container.design-page-align-left {
	text-align: left;
	/*justify-content: flex-start;*/
}
.flex-container.design-page-align-center {
	text-align: center;
	/*justify-content: center;*/
}
.flex-container.design-page-align-right {
	text-align: right;
	/*justify-content: flex-end;*/
}

.flex-container h1,
.click-carousel-text h1 {
	font-size: 60px;
	font-weight: normal;
	margin-top: 0;
	margin-bottom: 0px;
}
.flex-container h2,
.click-carousel-text h2 {
	font-size: 50px;
	font-weight: normal;
	margin-top: 0;
	margin-bottom: 0px;
}

.flex-container h3,
.click-carousel-text h3 {
	font-size: 37px;
	font-weight: normal;
	margin-top: 0;
	margin-bottom: 0px;
}
.flex-container h4,
.click-carousel-text h4 {
	font-weight: normal;
	font-size: 23px;
	margin-top: 0;
	margin-bottom: 0px;
}

.flex-container h5,
.click-carousel-text h5 {
	font-weight: normal;
	font-size: 20px;
	margin-top: 0;
	margin-bottom: 0px;
}

.flex-container h6,
.click-carousel-text h6 {
	font-weight: normal;
	font-size: 15px;
	margin-top: 0;
	margin-bottom: 0px;
}
.flex-container .btn:hover {
	opacity: 1;
	-webkit-transform: scale(1.05,1.05);
	        transform: scale(1.05,1.05);
}
.flex-container h1 span,
.flex-container h2 span,
.flex-container h3 span,
.flex-container h4 span {
	font-size: inherit!important;
}
.blank-page-container {
	height: auto;
	min-height: calc(100vh - 200px)!important;
}

.gallery-title-wrapper {
	width: 100%;
}
@media (max-width: 779px) {
	.col-40, .col-50, .col-60 {
		width: 100%!important;
	}
	
	body #articleContent.blog-article {
		min-height: auto;
		height: auto;
	}
	body #articleContent.blog-article .article-content {
		padding-bottom: 0!important;
		-webkit-flex-direction: column-reverse;
		        flex-direction: column-reverse;
	}
	#articleContent.blog-article .article-content h1 {
		font-size: 26px;
		font-weight: bold;
		padding-bottom: 0;
	}

	.section-title:before {
		width: 300px;
		left: calc(50% - 150px);
	}
	
	
	.navigation-arrow-wrapper {
		display: none;
	}
	.master-container {
		overflow:unset;
		height: auto;
	}
	.flex-container h1 span,
	.flex-container h2 span,
	.flex-container h3 span,
	.flex-container h4 span {
		font-size: inherit!important;
	}
	
	
	
	.article-stats li:first-child {
		margin-bottom: 0;
	}
	.master-container {
		padding-top: 60px;
	}
	.flex-container {
		padding:0px 0px 0 0!important;
		-webkit-flex-direction: column!important;
		        flex-direction: column!important;
		-webkit-justify-content: flex-start;
		        justify-content: flex-start;
		margin-top: 0!important;
		min-height: calc(100vh - 120px)!important;
	}
	#faqContent {
		padding:0px 20px 20px 20px!important;
	}

	.faq-1 #faqContent.flex-container,
	.layout-1 #faqContent {
		padding-top: 60px!important;
	}
	.maintenance-page {
		background-size: 160%;
		padding:60px 40px;
		-webkit-align-items: flex-start;
		        align-items: flex-start;
	}
	.maintenance-page h1 {
		font-size: 38px;
	}
	.maintenance-page .left {
		margin-left: 0;
		width: 100%;
	}
	.maintenance-page .left .home-btn {
		padding:15px;
		margin-left: 0!important;
	}
	.flex-container h1 {
		font-size: 40px!important;
	}
	.flex-container h2 {
		font-size: 34px!important;
	}

	.flex-container h3 {
		font-size: 24px!important;
	}
	.flex-container h4 {
		font-size: 20px!important;
	}
	.login-graphic.confirm {
		height: 100vh!important;
		padding:20px;
	}
	.login-graphic.confirm h1, 
	.login-graphic.confirm p {
		display: block;
	}
	.faq-image {
		height: 300px;
		margin-bottom: 30px;
	}
	#productList .empty-store-placeholder {
		margin: 70px auto 0 auto;
	}
	.generic-tabs.white-phone {
		color: #fff;
		border-bottom-color: rgba(255,255,255,0.3);
	}

	.generic-tabs.white-phone div:hover,
	.generic-tabs.white-phone div.active {
		border-bottom-color: #fff;
	}
	.subscribe-to-content-form input,
	.subscribe-to-content-form button {
		width: 100%;
		margin-top: 10px;
	}
	.card-dropdown-content {
		width: 200px;
	}

	.card-dropdown-content a {
		padding:10px 20px;
	}
	#storeProducts,
	#blogContainer {
		-webkit-transform: none;
		        transform: none;
		-webkit-animation: none;
		        animation: none;
		opacity: 1;
	}
	.header .cai-bot-input {
		background: transparent;
		padding:0;
		box-shadow: none;
		margin:0;
		padding-right: 20px;
		width: auto;
	}
	.header .cai-bot-input span {
		display: none;
	}
	.header .right {
		-webkit-align-items: center;
		        align-items: center;
		-webkit-flex-direction: row-reverse;
		        flex-direction: row-reverse;
	}
	.right .notifications-dropdown {
		padding:17px 20px 10px 20px;
	}
	.quick-nav {
		-webkit-flex-direction: row-reverse!important;
		        flex-direction: row-reverse!important;
		-webkit-justify-content: space-between!important;
		        justify-content: space-between!important;
	}
	.quick-nav-close {
		position: absolute;
		bottom: 100%;
		display: none!important;
		right: 0;
	}
	.quick-nav-arrow {
		width: 40px!important;
		height: 40px!important;
	}
	.quick-nav-preview {
		display: none!important;
	}
	.blank-website-placeholder {
		height: 60vh;
    	min-height: 0;
    	min-height: initial;
    	margin-top: 20px;
	}
	.blank-page-placeholder h3, 
	.blank-website-placeholder h3 {
		display: none
	}
	
	
}

.maintenance-page {
	display: -webkit-flex;
	display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    height: 100vh;
    background-image: url('/assets/img/maintenance.jpg');
    background-size: 80%;
    background-position: 100% 100%;
    background-repeat: no-repeat;
}
.maintenance-page .logo {
	position: fixed;
	width: 100px;
	top: 20px;
	left: 30px;
}

.maintenance-page .logo.bottom {
	top: auto;
	bottom: 20px;
	left: auto;
	right: 20px;
	width: 80px;
}
.maintenance-page h1 {
	margin:0;
	font-size: 48px;
	font-weight: 200;
}
.maintenance-page h1 span {
	font-weight: bold;
}

.maintenance-page .left {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	text-align: left;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	width: 40%;
	max-width: 500px;
	margin-left: 100px;
}

.maintenance-page .left p {
}
.maintenance-page .left .home-btn {
	float: left;
	margin-top: 20px;
}
.maintenance-page .left .home-btn:not(.empty):hover {
	box-shadow: 0 6px 12px rgba(0,0,0,0.1), 0 0 0 10px rgba(0,0,0,0.03);
	-webkit-transform: translateY(-2px);
	        transform: translateY(-2px);
}
.maintenance-page .left .home-btn.empty {
	background: transparent;
	box-shadow: none;
	color: #333;
	margin-left: 10px;
}
.maintenance-page .left .home-btn.empty:hover {
	background: #fafafa;
}
.flex-container#landingPage {
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	height: calc(100vh - 100px);
	text-align: center;
}

.master-container {
	height: 100%;
	padding-top: 80px;
	background:#fff; 
	-webkit-transform-origin:0% 50%; 
	        transform-origin:0% 50%;
	transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
	transition-delay:0.1s;
	font-size: 18px;
	/*overflow-x: hidden;*/
}
.master-container.text-white .flex-container,
.flex-container.text-white,
.flex-container.text-white a,
.master-container.text-white .flex-container a {
	color: #fff;
}

.col-60 {
	width: 60%;
}
.col-40 {
	width: 40%;
}
.col-25 {
	width:25%;
}
.col-75 {
	width: 75%;
}
.col-50 {
	width: 50%;
}
.clickai-cookies-wrapper {
	position: fixed;
	bottom: 50px;
	left: 0px;
	width: 100vw;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	padding:20px;
}

.clickai-cookies {
	padding:15px 28px;
	color: #333;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	background: #fafafa;
	border-radius: 4px;
	z-index: 500000;
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
	-webkit-animation:fadeInBottom 0.5s forwards;
	        animation:fadeInBottom 0.5s forwards;
	max-width: 1000px;
}
.cookies-message {
	margin:0;
	padding:0;
	width: calc(100% - 180px);
}

.cookies-message p,
.cookies-message h4 {
	margin:0;
}

.cookies-message h4 {
	font-size: 20px;
	margin-bottom: 4px;
}

.close-cookies {
	position: absolute;
    top: 9px;
    left: 9px;
    line-height: 0;
    cursor: pointer;
    font-size: 15px;
}
.clickai-cookies .right {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.clickai-cookies a {
	text-decoration: underline;
	color: #fff;
	cursor: pointer;
}

.cookie-btn {
	padding:7px 14px;
	border-radius: 4px;
	background: #333;
	color: #fff;
	margin-left: 20px;
	cursor: pointer;
	-webkit-flex-shrink: 0;
	        flex-shrink: 0;
}
.cookie-btn.empty {
	background: none;
	color: #333;
}
@media (max-width: 779px) { 
	.clickai-cookies {
		-webkit-flex-direction: column;
		        flex-direction: column;
		text-align: center;
	}
	.cookies-message {
		margin-bottom: 10px;
		width: 100%;
	}

	.clickai-cookies .right {
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-justify-content: center;
		        justify-content: center;
		margin-top: 10px;
	}
	.cookie-btn {
		margin:0 10px;
	}
}
.click-branding {
	width:100px;
	height: 100px;
	background: #333;
	position: fixed;
	bottom: 0px;
	left: 0px;
}
.click-branding:before {
	content: "POWERED BY clickClick";
    font-size: 11px;
    color: #fff;
    position: absolute;
    top: 5px;
    left: 43px;
}

.flex-container .accordion-toggle,
#faqContent .accordion-toggle,
.design-block-background-wrapper .accordion-toggle {
	border-bottom: solid 1px #efefef;
	padding:20px 0;
	margin-bottom: 0;
	margin-top: 0;
	position: relative;
	cursor: pointer;
	display: -webkit-flex;
	display: flex;
	font-size: 20px!important;
	-webkit-align-items: center;
	        align-items: center;
}
.accordion-toggle.active {
	border:none;
}
.accordion-toggle.active:hover {
	box-shadow: none;
}
.accordion-toggle:hover {
	box-shadow: 0 5px 10px rgba(0,0,0,0.0);

}
.accordion-toggle:hover:before {
	opacity: 1;
	-webkit-transform: translateX(10px);
	        transform: translateX(10px);
}
.active .accordion-toggle:after {
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
}
.active .accordion-toggle:before,
.accordion-toggle.active:before {
	opacity: 1;
	height: 3px;
	line-height: 2px;
	-webkit-transform: translateX(10px);
	        transform: translateX(10px);
}
.accordion-content {
	font-size: 18px;
	line-height: 24px;
	padding-bottom: 40px;
}
.active .accordion-content {
	display: block;
}
.active .accordion-toggle {
	border-bottom:transparent;
}
/****ACCORDION*****/
.accordion-content {
	display: none;
}
.accordion-content.default {
	display: block;
}

.thank-you-page .fb_iframe_widget {
	opacity: 0;
	-webkit-animation: fadeInBottom 1s forwards;
	        animation: fadeInBottom 1s forwards;
	box-shadow: 0 8px 16px rgba(0,0,0,0.3);
}

.thank-you-page {
	width: 100vw;
	height: 100vh;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	color: #fff;
	background: linear-gradient(-45deg, #455173 0%, #21283F 100%);
}
.thanks-title {
	width: 100%;
	max-width: 400px;
	margin:30px auto;
	text-align: center;
	opacity: 0;
	position: relative;
	-webkit-animation: fadeInTop 1s forwards;
	        animation: fadeInTop 1s forwards;
}
.thanks-title img {
	position: absolute;
    right: -20px;
    width: 60px;
    -webkit-filter: invert(1);
            filter: invert(1);
    bottom: -40px;
    -webkit-transform: rotate(80deg);
            transform: rotate(80deg);
    opacity: 0;
    -webkit-animation: fadeIn 0.5s forwards;
            animation: fadeIn 0.5s forwards;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
}
.thanks-title h1 {
	margin:0;
	font-size: 45px;
	width: 100%;
}
.thanks-title h1 span {
	color: #9ea9c1;
}
.thanks-title p {
	font-size: 18px;
	font-weight: 500;
	margin:0;
	width: 100%;
}
.click-ai-floater-message {
	background: #fff;
	width: 214px;
	padding:20px;
	font-size: 18px;
	border-radius: 8px;
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
	opacity: 0;
	visibility: hidden;
	transition: 0.4s;
	position: absolute;
	bottom: 45px;
	right: 0;
	color: #333!important;
	-webkit-transform: translateY(20px);
	        transform: translateY(20px);
}

.click-ai-floater:hover .click-ai-floater-message {
	opacity: 1;
	visibility: visible;
	-webkit-transform: none;
	        transform: none;
}

.click-ai-floater {
	height: 40px;
	font-size: 14px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	background: #333;
	box-shadow: 0 6px 12px rgba(0,0,0,0.2);
	border-radius: 6px;
	position: fixed;
	bottom: 10px;
	right: 10px;
	color: #fff;
	padding:0 20px;
	opacity: 0;
	-webkit-animation:fadeInBottom 1s forwards;
	        animation:fadeInBottom 1s forwards;
	-webkit-animation-delay: 3s;
	        animation-delay: 3s;
	z-index: 5000;
}
.click-ai-floater a {
	color: #fff;
}
.click-ai-floater img {
	height: 13px;
	margin-left: 6px;
}
.click-ai-floater span {
	font-size: 18px;
    position: absolute;
    top: 3px;
    right: 3px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    line-height: 10px;
    opacity: 0.6;
}
.click-ai-floater span:hover {
	opacity: 1;
}

.accordion-toggle {
	border-top: solid 1px #efefef;
	padding:20px 0;
	margin-bottom: 0;
	font-weight: 600;
	margin-top: 0;
	position: relative;
	cursor: pointer;
}
.accordion-toggle:first-of-type {
	border-top: none;
}
.accordion-toggle:after {
	width: 6px;
	height: 6px;
	border-bottom: solid 1px #000;
	border-right:solid 1px #000;
	-webkit-transform: rotate(-45deg);
	        transform: rotate(-45deg);
	content: " ";
	position: absolute;
	transition: all 0.3s;
	right: 5px;
	top: 28px;
}
.accordion-toggle.active:after {
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg)
}
.accordion-content {
	font-size: 18px;
	line-height: 24px;
	padding-bottom: 40px;
}
.accordion-content {
	display: none;
}
.accordion-content.default {
	display: -webkit-flex;
	display: flex;

}

.faq-1 .faq-edit-wrapper,
.faq-2 .faq-edit-wrapper,
.faq-3 .faq-edit-wrapper,
.faq-4 .faq-edit-wrapper,
.faq-5 .faq-edit-wrapper {
	margin:0;
}
.faq-image {
	position: relative;
}
.faq-image:hover .hover-box {
	opacity: 1;
	visibility: visible;
}
.edit-body-mode .design-block-table {
	padding:0;
}
.faq-edit-wrapper {
	position: relative;
}

.nav-1 .faq-image {
	margin-top: 0px;
}

.faq-2 .faq-image,
.faq-3 .faq-image,
.faq-4 .faq-image,
.layout-2 .faq-image,
.layout-3 .faq-image,
.layout-4 .faq-image  {
	display: block;
}

.faq-3 .faq-image,
.faq-4 .faq-image,
.layout-3 .faq-image,
.layout-4 .faq-image {
	height: 400px;
}
.faq-image {
	width: 100%;
	height: 450px;
	display: none;
}
.faq-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.faq-1 #faqContent {
	padding-top: 80px;
	padding-bottom: 80px;
}
#faqContent {
	padding:20px 20px 20px 20px;
	width:100%;
	max-width: 800px;
	margin:0 auto;
	-webkit-flex-direction: column;
	        flex-direction: column;
	display: block;
}
.faq-container {
	padding:0 0px;
}
.faq-container .draggable.placeholder {
	width: 100%;
	height: 71px;
	background: #efefef;
	border:dashed 2px #ccc;
	border-radius: 6px;
	color: #ccc;
	text-transform: uppercase;
	font-weight: 600;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}
#faqContent .faq-title {
	position: relative;
	padding-bottom: 40px;
}
#faqContent .faq-title p {
	margin:10px 0 0 0;
	padding:0;
	opacity: 0.5;
}

#faqContent .accordion-content {
	padding:20px 0;
	position: relative;
}
#faqContent .accordion-content:before {
	content: " ";
	width: 1px;
	height: 100%;
	background: #efefef;
	position: absolute;
	left: 30px;
	top: 0;
	display: none;
}
.faq-buttons {
	position: absolute;
	display: -webkit-flex;
	display: flex;
	font-size: 14px;
	right: 0;
	transition: 0.2s all;
	opacity: 0;
	visibility: hidden;
	z-index: 2;
}
.faq-buttons .btn {
	margin-left: 5px;
	font-size: 14px!important;
}
.accordion-toggle:hover .faq-buttons {
	opacity: 1;
	visibility: visible;
}
.faq-buttons .edit-faq {
	padding:5px 10px;
	background: #21283F;
	border-radius: 20px;
	color: #fff;
	margin-left: 10px;
}
.faq-buttons .edit-faq.remove {
	background: transparent;
	color: #CE6C6C;
}
#faqContent .green-add-btn {
	    margin-top: 20px;
    margin-bottom: 20px;
}
@media (min-width: 779px) {
	.faq-2 #faqContent,
	.layout-2 #faqContent {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-align-items: flex-start;
		        align-items: flex-start;
		max-width: 1100px;
		padding-top: 50px;
	}
	.faq-2 #faqContent .faq-title,
	.layout-2 #faqContent .faq-title {
		width: 300px;
	}
	.faq-2 #faqContent .faq-title:before,
	.layout-2 #faqContent .faq-title:before {
		display: none;
	}

	.faq-2 #faqContent #accordion,
	.layout-2 #faqContent #accordion {
		width: calc(100% - 400px);
	}
	.faq-3 #faqContent,
	.layout-3 #faqContent {
		width: 50%;
		float: right;
		padding:80px 80px 20px 80px;
	}
	.faq-3 .faq-image,
	.layout-3 .faq-image,
	.faq-4 .faq-image,
	.layout-4 .faq-image {
		width: 50%;
		height: 80vh!important;
	}
	.faq-3 .faq-edit-wrapper,
	.layout-3 .faq-edit-wrapper,
	.faq-4 .faq-edit-wrapper,
	.layout-4 .faq-edit-wrapper {
		display: -webkit-flex;
		display: flex;
	}
	.faq-4 .faq-edit-wrapper,
	.layout-4 .faq-edit-wrapper {
		-webkit-flex-direction: row-reverse;
		        flex-direction: row-reverse;
	}
	.faq-4 .faq-image,
	.layout-4 .faq-image {
		left: auto;
		right: 0;
	}
	.faq-4 #faqContent,
	.layout-4 #faqContent {
		width: 50%;
		float: left;
		padding:80px 80px 20px 80px;
	}

}

@media (max-width: 779px) {
	.faq-image {
		height: 240px!important;
	}
}
.collage-icon {
	position: relative;
	display: none;
}
.collage-icon .edit-image {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	border: dashed 1px #ccc;
	background:#fff;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	opacity: 0;
	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;
	box-shadow: 0 0 0 10px rgba(255,255,255,1);
}

.collage-icon:hover .edit-image {
	opacity: 1;
}

.collage-icon .edit-image img {
	width: 15px;
	opacity: 0.4;
	-webkit-filter: invert(1);
	        filter: invert(1);
}

#collagePage {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	background: inherit;
}

#collagePage .slider-buttons {
	display: none!important;
}

.cover-3 #collagePage .slider-buttons,
.cover-4 #collagePage .slider-buttons {
	display: -webkit-flex!important;
	display: flex!important;
	z-index: 3;
}
.cover-3 #collagePage .slider-buttons {
	bottom: 50px;
	top:auto;
	-webkit-justify-content: center;
	        justify-content: center;
	padding-right: 40px;
	padding-left: 0;
}
.collage-photos {
	position: relative;
}

#collagePage .change-image-btn {
	opacity: 1;
	visibility: visible;
	position: absolute;
	top: 20px;
	right: 20px;
	font-size: 15px;
	font-weight: 600;
	padding:12px 24px;
	z-index: 200;
}
.collage-photos:hover .change-image-btn {
	opacity: 1;
	visibility: visible;
}

.collage-photos .change-image-btn {
	position: absolute;
	top: 20px;
	right: 20px;
	font-weight: 500;
	opacity: 0;
	visibility: hidden;
	width: 110px;
	z-index: 20;
	height: 37px;
}

.collage-info {
	width: 35%;
	padding:30px 80px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
}
.collage-info .explore-btn {
	display: inline-block;
	margin-top: 20px;
}
.collage-info p,
.collage-info h1,
.collage-info h2,
.collage-info h3,
.collage-info h4 {
	margin:0!important;
}
.collage-icon {
	width: 80px;
	height: 80px;
	border-radius: 50%;
	overflow: hidden;
	margin-bottom: 20px;
}
.collage-icon .icon-image {
	width:100%;
	height: 100%;
	object-fit: cover;
}

.cover-1 .collage-photos {
	width: 65%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	
}

.cover-1 .collage-photos .collage-photo {
	width: 33.333%;
	height: 33.333%;
}

.collage-photos .collage-photo img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.collage-info .collage-text h1 span,
.collage-info .collage-text h2 span,
.collage-info .collage-text h3 span,
.collage-info .collage-text h4 span {
	display: inline-block;
}

.cover-1 .collage-icon,
.cover-3 .collage-icon,
.cover-5 .collage-icon {
	display: none;
}
.cover-1 .collage-photos,
.cover-1 .collage-info,
.cover-3 .collage-photos,
.cover-3 .collage-info {
	height: calc(100vh - 80px);
}
.cover-1.nav-2 .collage-photos,
.cover-1.nav-2 .collage-info,
.cover-3.nav-2 .collage-photos,
.cover-3.nav-2 .collage-info {
	height: 100vh;
}

.edit-body-mode.cover-1 .collage-photos,
.edit-body-mode.cover-1 .collage-info,
.edit-body-mode.cover-3 .collage-photos,
.edit-body-mode.cover-3 .collage-info,
.edit-body-mode.cover-5 .collage-info  {
	height: calc(100vh - 130px);
}
.edit-body-mode.nav-2.cover-1 .collage-photos,
.edit-body-mode.nav-2.cover-1 .collage-info,
.edit-body-mode.nav-2.cover-3 .collage-photos,
.edit-body-mode.nav-2.cover-3 .collage-info,
.edit-body-mode.nav-2.cover-5 .collage-info {
	height: calc(100vh - 50px);
}

.cover-1 .collage-photos,
.cover-1 .collage-info,
.cover-3 .collage-photos,
.cover-3 .collage-info,
.cover-5 .collage-info,
.cover-1 .collage-info,
.cover-1 .collage-photos-wrapper {
	min-height: 550px;
}

.cover-8 .collage-icon {
	display: none;
}

.cover-8 .collage-info {
	width: 100%;
	padding:90px 30px;
	position: relative;
	z-index: 20;
}
.cover-8.edit-body-mode .collage-info h1 {
	height: auto;
	-webkit-animation: none;
	        animation: none;
}
.cover-8 .collage-info h1 {
	font-size: 65px;
}
@-webkit-keyframes titleBlind {
	0% {height: 0; overflow: hidden;}
	99% {height: 160px; overflow: hidden;}
	100% {height: auto; overflow: visible;}
}
@keyframes titleBlind {
	0% {height: 0; overflow: hidden;}
	99% {height: 160px; overflow: hidden;}
	100% {height: auto; overflow: visible;}
}
.cover-8 .collage-info h2 {
	font-size: 50px;
}
.cover-8 .collage-text {
	opacity: 0;
	-webkit-animation: fadeInTop 0.4s forwards;
	        animation: fadeInTop 0.4s forwards;
	width: 100%;
}
.cover-8 .collage-info h3 {
	font-size: 40px;
}
.cover-8 .collage-info h4 {
	font-size: 22px;
}
.cover-8 .collage-photos {
	width: 100%;
	display: block;
}
.cover-8 .collage-info p {
	font-size: 18px;
}

.cover-8 .collage-photos .collage-photo:before {
	display: block;
	content: " ";
	padding-top: 75%;
}

.cover-8 .collage-photos .collage-photo img {
	width: 100%;
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	object-fit: cover;
}
.cover-8 .collage-photos:hover .collage-photo img {
	/*transform: scale(1.1);*/
}
@-webkit-keyframes coverImgZoom {
	0% {-webkit-transform: scale(1);transform: scale(1); -webkit-transform-origin: 0 50%; transform-origin: 0 50%;}
	50% {-webkit-transform: scale(1.15);transform: scale(1.15); -webkit-transform-origin: 50% 50%; transform-origin: 50% 50%;}
	100% {-webkit-transform: scale(1);transform: scale(1); -webkit-transform-origin: 0 50%; transform-origin: 0 50%;}
}
@keyframes coverImgZoom {
	0% {-webkit-transform: scale(1);transform: scale(1); -webkit-transform-origin: 0 50%; transform-origin: 0 50%;}
	50% {-webkit-transform: scale(1.15);transform: scale(1.15); -webkit-transform-origin: 50% 50%; transform-origin: 50% 50%;}
	100% {-webkit-transform: scale(1);transform: scale(1); -webkit-transform-origin: 0 50%; transform-origin: 0 50%;}
}


@-webkit-keyframes imgFloat {
	from {margin-top: 20px; opacity: 0;}
	to {margin-top: 0px; opacity: 1;}
}


@keyframes imgFloat {
	from {margin-top: 20px; opacity: 0;}
	to {margin-top: 0px; opacity: 1;}
}
.cover-8 .collage-photos .collage-photo {
	width: calc(50% - 30px);
	position: relative;
	z-index: 2;
	-webkit-flex-shrink: 0;
	        flex-shrink: 0;
	margin-left: 20px;
	transition: 0.2s ease;
	height: auto;
}

.cover-8 .collage-photos .collage-photo:nth-child(3),
.cover-8 .collage-photos .collage-photo:nth-child(5) {
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
}
.cover-8 .collage-photos .collage-photo:nth-child(4),
.cover-8 .collage-photos .collage-photo:nth-child(6) {
	position: absolute;
	right: 20px;
	top: 0px;
	opacity: 0;
}

.cover-8 .collage-photos .collage-photo:nth-child(3),
.cover-8 .collage-photos .collage-photo:nth-child(4) {
	opacity: 0;
	-webkit-animation: dribbbleFade 8s infinite;
	        animation: dribbbleFade 8s infinite;
	-webkit-animation-delay: 4s!important;
	        animation-delay: 4s!important;
	z-index: 40;
}

.cover-8 .collage-photos .collage-photo:nth-child(5),
.cover-8 .collage-photos .collage-photo:nth-child(6) {
	opacity: 0;
	-webkit-animation: dribbbleFadeTwo 8s infinite;
	        animation: dribbbleFadeTwo 8s infinite;
	-webkit-animation-delay: 4s!important;
	        animation-delay: 4s!important;
	z-index: 50;
}


@-webkit-keyframes dribbbleFade {
	0% {opacity: 0}
	8% {opacity: 1}
	41% {opacity: 1}
	48% {opacity: 0;}
	100% {opacity: 0}
}


@keyframes dribbbleFade {
	0% {opacity: 0}
	8% {opacity: 1}
	41% {opacity: 1}
	48% {opacity: 0;}
	100% {opacity: 0}
}

@-webkit-keyframes dribbbleFadeTwo {
	0% {opacity: 0}
	33% {opacity: 0;}
	41% {opacity: 1;}
	62% {opacity: 1;}
	70% {opacity: 0}
	100% {opacity: 0}
}

@keyframes dribbbleFadeTwo {
	0% {opacity: 0}
	33% {opacity: 0;}
	41% {opacity: 1;}
	62% {opacity: 1;}
	70% {opacity: 0}
	100% {opacity: 0}
}

.cover-8 .collage-photos .collage-photo:nth-child(7) {
	display: none;
}
.cover-8 .collage-photos:hover .collage-photo:not(:first-of-type) {
	
}
.cover-8 .collage-photos-wrapper {
	-webkit-flex-wrap: nowrap;
	        flex-wrap: nowrap;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	padding-bottom: 20px;
	overflow: auto;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
}

.cover-8 .collage-photos-wrapper {

}
@media (min-width:779px) {
	.cover-8 .collage-text {
		display: -webkit-flex;
		display: flex;
		max-width: 1300px;
		margin:0 auto;
	}
	.cover-8 .collage-text-title {
		width: 60%;
		-webkit-flex-shrink: 0;
		        flex-shrink: 0;
		padding-right: 50px;
	}
	.cover-8 .collage-text-description {
		width: 40%;
		-webkit-flex-shrink: 0;
		        flex-shrink: 0;
		padding-left: 50px;
		padding-right: 50px;
	}
}

.cover-3 .collage-photos-wrapper {
	/*background: #000;*/
	overflow: hidden;

}
.collage-photos .design-block-gallery-nav {
	display: none;
}
.cover-3 .design-block-gallery-nav {
	display: -webkit-flex!important;
	display: flex!important;
	bottom: 40px;
	background: inherit;
}


.cover-4 .design-block-gallery-nav {
		display: -webkit-flex!important;
		display: flex!important;
		background: transparent!important;
		color: #fff!important;
	}

@media (min-width: 779px) {
	.cover-4 .design-block-gallery-nav span {
		position: absolute;
		bottom: 50vh;
	}

	.cover-3 .design-block-gallery-nav {
		-webkit-transform: translateX(-20px);
		        transform: translateX(-20px);
	}

	.cover-4 .design-block-gallery-nav span.next {
		right: 0;
	}

	.cover-4 .design-block-gallery-nav {
		width:100%;
		left: 0;
		background: transparent!important;
		color: #fff!important;
		-webkit-justify-content: space-between;
		        justify-content: space-between;
	}
	.cover-3 .collage-photos-wrapper {
		border-bottom:solid 40px transparent;
		border-right:solid 40px transparent;
	}

	.cover-3.nav-2 .collage-photos-wrapper {
		border-top:solid 40px transparent;
	}
}

.cover-1.nav-2 .collage-photos {
	height: 100vh;
}
.cover-1 .collage-photos .collage-photo img {
	border:solid 1px transparent;
}
.cover-1 .collage-photos .collage-photo:first-of-type {
	/*margin-left: 33.333%;*/
}

.cover-1 .collage-photos .collage-photos-wrapper div:nth-child(3) {
	width: 40%;
}

.cover-1 .collage-info,
.cover-5 .collage-info {
	padding:30px 0px 30px 50px;
}

.cover-2 #collagePage {
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.cover-2 .collage-info {
	height: auto;
	min-height: 50vh;
	width: 100%;
	-webkit-justify-content: center;
	        justify-content: center;
	text-align: center;
	-webkit-align-items: center;
	        align-items: center;
}
.cover-2 .collage-photos {
	width: 100%;
	height: 350px;
	-webkit-flex-wrap:nowrap;
	        flex-wrap:nowrap;
	display: block;
	overflow: auto;
	margin-top: 0px;
	white-space: nowrap;
}
.cover-2 .collage-photos-wrapper {
	display: block;
	white-space: nowrap;
	position: relative;
}
.collage-photos-wrapper {
	width: 100%;
	height: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	transition: all 0.3s;
	position: relative;
}
.edit-body-mode .collage-photos:hover .collage-photos-wrapper {
	cursor: pointer;
}
.cover-2 .collage-photos .collage-photo {
	width: auto;
	display: inline-block;
	margin-right: 2px;
	height: 100%;
}
.cover-2 .collage-photos .collage-photo img {
	height: 100%;
	margin-bottom: -10px;
	width: auto;
}

.cover-2 .collage-icon {
	margin-top: 20px;
	margin-bottom: 15px;
	display: none;
}
.cover-3 .collage-info {
	width: 40%;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	padding:60px;
}
.cover-3 .collage-photos {
	width: 60%;
	background: inherit;
	position: relative;
}

.cover-3 .collage-photos .collage-photo {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	opacity: 1;
	transition:0.5s;
}

@-webkit-keyframes imageScaleIn {
	0% {opacity: 0; -webkit-transform: scale(1); transform: scale(1);}
	100% {opacity: 1; -webkit-transform: scale(1.1); transform: scale(1.1);}
}

@keyframes imageScaleIn {
	0% {opacity: 0; -webkit-transform: scale(1); transform: scale(1);}
	100% {opacity: 1; -webkit-transform: scale(1.1); transform: scale(1.1);}
}

.cover-4 .collage-photos {
	width: 100%;
	height: calc(100vh - 80px);
	position: absolute;
	left: 0;
	top: 0;
	min-height: 600px;
	overflow: hidden;
	background:inherit;
}
.cover-4 .design-block-gallery-nav span {
	font-size: 25px;
}
.cover-4 .collage-photos:before {
	content: " ";
	width: 100%;
	height: 100%;
	background:rgba(0,0,0,0.2);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
}

.cover-4.nav-2 .collage-photos {
	height: 100vh;
}

.cover-4 .collage-photos .collage-photo {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	opacity: 1;
	transition:.5s;
}

.cover-4.nav-2 .collage-info {
	height: 100vh;
}
.cover-4 .collage-info {
	color: #fff;
    height: calc(100vh - 80px);
    width: 100%;
    padding: 100px;
    text-align: left;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    position: relative;
    z-index: 5;
    min-height: 600px;
}

.cover-4 .collage-info .collage-text {
	width: 100%;
}

.cover-4 .design-page-align-right .collage-info {
	text-align: right;
}

.cover-4 .design-page-align-center .collage-info {
	text-align: center;
	-webkit-justify-content: center;
	        justify-content: center;
	padding-top: 0;
}

.cover-6 .design-page-align-right .collage-info {
	margin-left: auto;
	margin-right: 0;
	text-align: right;
}

.cover-6 .design-page-align-left .collage-info {
	margin-left: 0;
	margin-right: 0;
	text-align: left;
	-webkit-align-self: flex-start;
	        align-self: flex-start;
}
.cover-4 .slider-buttons,
.cover-3 .slider-buttons  {
	top: calc(50% - 25px);
    width: 100%;
    left: 0;
    background:transparent;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding:0 20px;
    box-shadow: none;
    z-index: auto!important;
}
.cover-4 .slider-buttons button,
.cover-3 .slider-buttons button {
	width: 50px;
	height: 100%;
	border-radius: 0;
	font-size: 0;
	background: transparent;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	border:none;
	z-index: 6;
}
.cover-4 .slider-buttons button:hover,
.cover-3 .slider-buttons button:hover {
	-webkit-transform: translateX(-3px);
	        transform: translateX(-3px);
	background: transparent;
}
.cover-4 .slider-buttons button:last-child:hover,
.cover-3 .slider-buttons button:last-child:hover {
	-webkit-transform: translateX(3px);
	        transform: translateX(3px);
}
.cover-4 .slider-buttons button:before,
.cover-3 .slider-buttons button:before {
	content: " ";
	width: 30px;
	height: 30px;
	border-bottom: solid 2px #fff;
	border-left:solid 2px #fff;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
}
.cover-4 .slider-buttons button:last-child:before,
.cover-3 .slider-buttons button:last-child:before {
	-webkit-transform: rotate(225deg);
	        transform: rotate(225deg);
}
.cover-4 .collage-icon {
	display: none;
}


.cover-5 .collage-info {
	width: 35%;
	-webkit-justify-content: center;
	        justify-content: center;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	height: calc(100vh - 100px);
}
.cover-5.nav-2 .collage-info {
	height: 100vh;
}
.cover-5 .collage-photos {
	padding-left: 50px;
	width: 65%;
}
.cover-5 .collage-photos .collage-photo {
	width: 50%;
	padding:60px;
	height: auto;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	opacity: 0;
	position: relative;
	-webkit-animation: fadeInTop 1.5s forwards;
	        animation: fadeInTop 1.5s forwards;
}
.cover-5 .collage-photos .collage-photo:before {
	padding-top: 100%;
	content: " ";
	display: block;
	width: 100%;
}

.cover-5 .collage-photos .collage-photo img {
	/*height: auto;
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);*/
	width: 100%;
	border-radius: 4px;
	position: absolute;
	top: 60px;
	left: 60px;
	width: calc(100% - 120px);
	height: calc(100% - 120px);
}

@media (max-width: 1050px) {
	 .cover-5 .collage-info {
	 	width: 50%;
	 }
	 .cover-5 .collage-photos {
	 	width: 50%;
	 }
}

.cover-5 .collage-photos-wrapper div:nth-child(2),
.cover-5 .collage-photos-wrapper div:nth-child(3),
.cover-5 .collage-photos-wrapper div:nth-child(6) {
	padding:20px;
}

.cover-5 .collage-photos-wrapper div:nth-child(2) img,
.cover-5 .collage-photos-wrapper div:nth-child(3) img,
.cover-5 .collage-photos-wrapper div:nth-child(6) img {
	width: calc(100% - 40px);
	height: calc(100% - 40px);
	top: 20px;
	left: 20px;
}


.cover-5 .collage-photos-wrapper div:nth-child(1) {
	-webkit-animation-delay: 0s;
	        animation-delay: 0s;
}
.cover-5 .collage-photos-wrapper div:nth-child(2) {
	-webkit-animation-delay: 0.3s;
	        animation-delay: 0.3s;
}
.cover-5 .collage-photos-wrapper div:nth-child(3) {
	-webkit-animation-delay: 0.6s;
	        animation-delay: 0.6s;
}
.cover-5 .collage-photos-wrapper div:nth-child(4) {
	-webkit-animation-delay: 0.9s;
	        animation-delay: 0.9s;
}
.cover-5 .collage-photos-wrapper div:nth-child(5) {
	-webkit-animation-delay: 1.2s;
	        animation-delay: 1.2s;
}	
.cover-5 .collage-photos-wrapper div:nth-child(6) {
	-webkit-animation-delay: 1.5s;
	        animation-delay: 1.5s;
}
.cover-5 .collage-photos-wrapper div:nth-child(7) {
	-webkit-animation-delay:1.8s;
	        animation-delay:1.8s;
}

@-webkit-keyframes collageIn {
	0% {opacity: 0; -webkit-transform: scale(1); transform: scale(1)}
	100% {opacity: 1; -webkit-transform: scale(1.05); transform: scale(1.05)}
}

@keyframes collageIn {
	0% {opacity: 0; -webkit-transform: scale(1); transform: scale(1)}
	100% {opacity: 1; -webkit-transform: scale(1.05); transform: scale(1.05)}
}


.cover-6 #collagePage {
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	padding-bottom: 80px;
}
.cover-6 .collage-info {
	width:100%;
	max-width: 500px;
	text-align: center;
	-webkit-justify-content: center;
	        justify-content: center;
	padding:80px 30px;
	margin:0 auto;
	-webkit-align-items: center;
	        align-items: center;
}
.cover-6 .collage-photos {
	width: 100%;
	height:auto;
	max-width: 1100px;
	-webkit-justify-content: center;
	        justify-content: center;
}

.cover-6 .collage-photos-wrapper {
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	
}
.cover-6 .collage-photo {
	width: calc(33.333% - 20px);
	position: relative;
	margin:10px;
}
.cover-6 .collage-photo:before {
	width: 100%;
	padding-top: 100%;
	content: " ";
	display: block;
}
.cover-6 .collage-photo img {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.cover-7 #collagePage {
	width: 100%;
	height: calc(100vh - 80px);
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
}

.cover-7 #collagePage.design-page-align-center {
	-webkit-justify-content: center;
	        justify-content: center;
}

.cover-7 #collagePage.design-page-align-center .collage-info {
	text-align: center;
}

.cover-7 #collagePage.design-page-align-right {
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
}
.cover-7 #collagePage.design-page-align-right .collage-info {
	text-align: right;
}
.cover-7.nav-2 #collagePage {
	height: calc(100vh - 0px);
}

.cover-7.nav-2 .collage-photos {
	height: 100vh
}

.cover-7 .collage-info {
	position: relative;
    z-index: 5;
    text-align: left;
    background: inherit;
    padding: 50px 40px;
    width: 33.333%;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    min-width: 320px;
    opacity: 0;
    -webkit-animation: fadeInTop 1s forwards;
            animation: fadeInTop 1s forwards;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
}
.cover-7 .collage-info .collage-text {
	width: 100%;
}

.cover-7 .collage-info .collage-icon {
	display: none;
}

.cover-7 .collage-photos {
	width: 100%;
	height: calc(100vh - 80px);
	position: absolute;
	left: 0;
	top: 0;
}

.cover-7 .collage-photos-wrapper {

}
.cover-7 .collage-photos .collage-photos-wrapper {
	height: 100%;
}
.cover-7 .collage-photos .collage-photo {
	width: 33.333%;
	height: 50%;
}

.cover-7 .collage-photos-wrapper div:nth-child(7) {
	display: none;
}


@media (max-width: 779px) { 
	.cover-2 .collage-info {
		min-height: 400px!important;
	}
	.collage-photos {
		min-height: 0;
		min-height: initial;
	}
	.cover-5 .collage-photos-wrapper div:nth-child(2),
	.cover-5 .collage-photos-wrapper div:nth-child(3),
	.cover-5 .collage-photos-wrapper div:nth-child(6) {
		padding: 15px;
	}
	.collage-icon {
		width: 70px;
		height: 70px;
	}
	.collage-info {
		width: 100%!important;
		height: auto!important;
		min-height: 400px!important;
		padding:30px!important;
	}
	.collage-photos {
		width: 100%!important;
	}

	.cover-1 .collage-photos .collage-photo {
		height: auto!important;
		position: relative;
	}

	.cover-1 .collage-photos .collage-photo:before {
		padding-top: 100%;
		content: " ";
		width: 100%;
		display: block;
	}

	.cover-1 .collage-photos .collage-photo img {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	.cover-1 .collage-photos .collage-photos-wrapper div:nth-child(3) {
		width: 33.333%;
	}
	.cover-1 .collage-photos .collage-photos-wrapper div:nth-child(7) {
		width: 100%;
	}
	.cover-4 .collage-info {
		height: calc(100vh - 80px)!important;
		-webkit-justify-content: center;
		        justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
	}
	.cover-4 .collage-photos {
		min-height: 0;
		min-height: initial;
	}
	.cover-4 .collage-photos:before {
		background: #000;
		opacity: 0.3;
	}
	.cover-3 .collage-info {
		min-height: 45vh!important;
		-webkit-justify-content: center;
		        justify-content: center;

	}
	.cover-5 .collage-photos .collage-photo {
		padding:30px;
	}
	.cover-5 .collage-photos .collage-photo img {
		width: calc(100% - 60px);
		height: calc(100% - 60px);
		top: 30px;
		left: 30px;
	}
	.cover-5 .collage-photos {
		height: auto;
		padding-left: 0;
	}
	.cover-6 .collage-photo {
		width: calc(33.333% - 2px);
		margin:1px;
	}
	.cover-6 .collage-photos .collage-photos-wrapper div:nth-child(7) {
		display: none;
	}
	.cover-7 .collage-photos {
		position: relative;
	}
	.cover-7 #collagePage {
		/*flex-direction: column-reverse!important;*/
		-webkit-justify-content: flex-start;
		        justify-content: flex-start;
		height: auto;
	}
	.cover-7 .collage-photos {
		height: calc(50vh - 80px);
	}
	.cover-7 .collage-info {
		-webkit-align-items: flex-start;
		        align-items: flex-start;
		min-height: 50vh!important;
	}
	.cover-4 .slider-buttons {
		width: 150px;
	    top: auto;
	    bottom: 30px;
	    left: calc(50% - 75px);
	}
	.cover-4 .slider-buttons button,
	.cover-3 .slider-buttons button {
		-webkit-transform: scale(0.9)!important;
		        transform: scale(0.9)!important;
	}
	.cover-1 .collage-info,
	.cover-1 .collage-photos-wrapper {
		min-height: 0;
		min-height: initial;
		-webkit-align-items: flex-start;
		        align-items: flex-start;
    -webkit-align-content: flex-start;
            align-content: flex-start;
	}

	.cover-1 .collage-photos {
		height: auto!important;
    min-height: 0!important;
    min-height: initial!important;
	}
	.cover-6 .collage-photo:last-of-type:before {
		padding-top: 100%;
	}

	.cover-4 .collage-info h1 {
	font-size: 50px;
	}

	.cover-4 .collage-info h2 {
		font-size: 40px;
	}

	.cover-4 .collage-info h3 {
		font-size:30px;
	}

	.cover-4 .collage-info h4 {
		font-size: 20px;
	}
	.cover-5 .collage-info {
		position: relative;
	}
	.cover-3 .collage-photos {
		min-height: 0;
		min-height: initial;
		height: 380px!important;
	}
	.cover-3 #collagePage .slider-buttons {
		padding:0;
		bottom: 20px;
	}
	.cover-1 .collage-info {
		min-height: 50vh!important;
	}
	.cover-8 .collage-info h1 {
		font-size: 40px;
		-webkit-animation: none;
		        animation: none;
		opacity: 1;
		height: auto;
		white-space: wrap;
	}
	.cover-8 .collage-info h2 {
		font-size: 30px;
	}
	.cover-8 .collage-info h3 {
		font-size: 25px;
	}
	.cover-8 .collage-info h4 {
		font-size: 22px;
	}
	.cover-8 .collage-photos-wrapper {
		-webkit-flex-wrap: nowrap;
		        flex-wrap: nowrap;
		padding-left: 10px;
	}
	.cover-8 .collage-info {
		min-height: 50vh!important;
	}
	.cover-8 .collage-photos {
		min-height: 0;
		min-height: initial;
	}
	 .cover-8  .flex-container .collage-photos .collage-photo {
		margin-left: 0;
    left: 0;
    width: 90%;
    position: relative;
    -webkit-animation: none;
            animation: none;
    opacity: 1;
    visibility: visible;
    border:solid 10px transparent;

	}
	#collagePage .change-image-btn {
		padding:8px 16px;
		top: 10px;
		right: 10px;
	}
}	


@media (max-width: 1024px) {
	.nav-4 .nav-bar .left .nav-links {
		position: relative;
	}
}
.slider-buttons {
	width: 100px;
	height: 50px;
	border-radius: 40px;
	position: absolute;
	left: calc(50% - 50px);
	bottom: 20px;
	background: #fff;
	box-shadow: 0 10px 15px rgba(0,0,0,0.1);
	overflow:hidden;
	display: -webkit-flex;
	display: flex;
	z-index: 2;
}


.slider-buttons button {
	width: 50%;
	background: transparent;
	border: none;
	outline: none;
	height: 100%;
	border-right: 1px solid #efefef;
	text-align: center;
	cursor: pointer;
	transition: all 0.2s ease;;

}
.slider-buttons button:hover {
	background: #efefef;
}

.home-icon .edit-image {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	border: dashed 1px #ccc;
	background:#fff;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	opacity: 0;
	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;
	box-shadow: 0 0 0 10px rgba(255,255,255,1);
}

.home-icon:hover .edit-image {
	opacity: 1;
}

.home-icon .edit-image img {
	width: 15px;
	opacity: 0.4;
	-webkit-filter: invert(1);
	        filter: invert(1);
}

.home-info {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
}
#homePage {
	height: calc(100vh - 80px);
	min-height: 600px;
}

@media (min-width: 779px) {
	.edit-body-mode #homePage {
		height: calc(100vh - 130px)
	}
	.nav-bar-medium #homePage {
		height: calc(100vh - 110px)
	}
	.edit-body-mode.nav-bar-medium #homePage {
		height: calc(100vh - 170px)
	}
	.edit-body-mode.nav-bar-large #homePage {
		height: calc(100vh - 250px)
	}
	.nav-bar-large #homePage {
		height: calc(100vh - 200px)
	}
}
.edit-body-mode.nav-1 #homePage ,
.edit-body-mode.nav-3 #homePage ,
.edit-body-mode.nav-4 #homePage ,
.edit-body-mode.nav-5 #homePage ,
.edit-body-mode.nav-6 #homePage {
	height: calc(100vh - 120px);
}

.body-edit-mode #homePage {
	height: 100%;
}
.home-photos,
.home-video {
	display: none;
}

#homePage:hover .hover-box {
	opacity: 1;
	visibility: visible;
}

.home-info h1,
.home-info h2,
.home-info h3,
.home-info h4,
.home-info p {
	margin:0;
	/*text-align: inherit!important;*/
}
.home-icon {
	width: 80px;
	height: 80px;
	border-radius: 50%;
	margin-bottom: 20px;
	overflow: hidden;
	position: relative;
	z-index: 4;
}

.home-icon .icon-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
#homePage {
	background: #fff;
}
.home-text {
	width: 100%;
}
.home-1 .home-info {
	width: 100%;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	height: 100%;
	padding:0 70px 30px 70px;
	position: relative;
	max-width: 1400px;
	margin:0 auto;
}
.home-1 .home-text {
	opacity: 0;
    -webkit-animation: fadeIn 0.6s forwards;
            animation: fadeIn 0.6s forwards;
    padding-left: 0px;
    height: 100%;
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.home-1 .home-text .home-text-title {
	width: 100%;
}
.home-1 #homePage .home-icon {
	display: none;
}
.home-1 .home-description {
	opacity: 0;
	-webkit-animation: fadeRight 0.6s forwards;
	        animation: fadeRight 0.6s forwards;
	-webkit-animation-delay: 0.6s;
	        animation-delay: 0.6s;
}

.home-info h1 {
	margin:0!important;
}
.home-1 .home-info h1,
.home-1 .home-info h2,
.home-1 .home-info h3,
.home-1 .home-info h4 {
	width: 100%;
	margin:0;
}

.home-1 #homePage {
	-webkit-transform: scale(1,1);
	        transform: scale(1,1);
}


.home-1 .home-icon {
	position: absolute;
	left: 80px;
	bottom: 80px;
}
.home-1 .home-info .home-description {
	-webkit-flex-shrink: 0;
	        flex-shrink: 0;
	width: 400px;
	padding:0 30px;
	margin-left: 30px;
	border-left: solid 2px #efefef
}

.home-2 #homePage {
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	min-height: 700px;
	height: auto!important;
	padding:30px;
	max-width: 1600px;
	margin:auto;
}
.home-2 .home-photos {
	height: 600px;
	display: block;
	width: 100%;
	position: relative;
	opacity: 0;
	-webkit-animation: fadeIn 1.2s forwards;
	        animation: fadeIn 1.2s forwards;
}
.home-2 .home-info {
	width: 100%;
	padding:30px 60px 60px 60px;
	opacity: 0;
	-webkit-animation:fadeIn 1s forwards;
	        animation:fadeIn 1s forwards;
	min-height: 60%;
	-webkit-justify-content: center;
	        justify-content: center;
	max-width: 900px;
}

.home-2 .design-page-align-center .home-info {
	margin:0 auto;
}

.home-2 .design-page-align-right .home-info {
	margin:0;
}
.home-2 .design-page-align-right#homePage {
	-webkit-align-items: flex-end;
	        align-items: flex-end;

}
.home-2 .home-info .home-icon {
	margin:auto;
	display: none;
}

.home-2 .home-photos img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.home-photos iframe {
	width: 100%;
	height: 100%;
	border:none;
	outline: none;
	position: relative;
}

.home-3 .home-info .home-icon {
	display: none;
}

.home-3 #homePage {
	/*max-width: 1440px;
	margin:0 auto;*/
}

.home-3 .home-info {
	width: 40%;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	padding:60px;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	opacity: 0;
	-webkit-animation:fadeRight 1s forwards;
	        animation:fadeRight 1s forwards;
	position: relative;
	z-index: 2;
}
.home-3 .home-photos {
	height: 100%;
	padding:0px;
	display: block;
	width: 60%;
	position: relative;
	opacity: 0;
	-webkit-animation:fadeRight 1s forwards;
	        animation:fadeRight 1s forwards;
	-webkit-animation-delay: 0.5s;
	        animation-delay: 0.5s;
}

@media (min-width: 779px) {
	.home-3 .home-photos {
		border-bottom:solid 40px transparent;
		border-right:solid 40px transparent;
	}
}
.nav-2.home-3 .home-photos {
	padding:30px;
}

.home-3 .home-photos img {
	width: 100%;
	height: 100%;
	position: relative;
	object-fit: cover;
}

.home-4 .home-info .home-icon {
	display: none;
}

.home-4 #homePage {
	margin:0 auto;
}

.home-4 .home-info {
	width: 100%;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	padding:0 70px 70px 70px;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	color: #fff!important;
	position: relative;
	z-index: 13;
	opacity: 0;
	-webkit-animation:fadeRight 1s forwards;
	        animation:fadeRight 1s forwards;
}
.home-4 .home-info .home-description {
	margin-top: 10px;
}
.home-3 .home-info .home-description {

}
.home-4 .home-photos {
	height: 100%;
	padding:0;
	display: block;
	width: 100%;
	position: absolute;
	background:#000;;
}

.home-4 .home-photos img {
	width: 100%;
	height: 100%;
	position: relative;
	object-fit: cover;
	opacity: 1;
}

.home-4 .home-photos:before {
	content: " ";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	background: linear-gradient(to bottom left, transparent 20%, rgba(0,0,0,0.3) 100%);
}



#homePage h1 {
	/*font-size: 85px;*/
	margin:0;
	/*text-align: inherit!important;*/
}
#homePage h2 {
	/*font-size: 60px;*/
	margin:0;
	/*text-align: inherit!important;*/
}
#homePage h3 {
	/*font-size: 45px;*/
	margin:0;
	/*text-align: inherit!important;*/
}
#homePage h4 {
	/*font-size: 20px;*/
	margin:0;
	/*text-align: inherit!important;*/
}

.flex-container h1 span,
.flex-container h2 span,
.flex-container h3 span,
.flex-container h4 span {
	/*font-size: inherit!important;*/
}

.home-5 .home-icon {
	display: none;
}
.home-5 #homePage {
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}
.home-5 .home-info {
	min-width: 400px;
	max-width: 500px;
	-webkit-align-items: center;
	        align-items: center;
	padding:30px;
	background: #fff;
	background: inherit;
	text-align: center;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	position: relative;
	z-index: 13;
	opacity: 0;
	-webkit-animation:fadeRight 1s forwards;
	        animation:fadeRight 1s forwards;
}

.home-5 .home-info .explore-btn {
	margin-top: 10px;
}
.home-5 #homePage .home-info h1 {
	font-size: 45px;
	margin-bottom: 0;
}
.home-5 #homePage .home-info h2 {
	font-size: 43px;
}
.home-5 #homePage .home-info h3 {
	font-size: 40px;
}
.home-5 #homePage .home-info h4 {
	font-size: 20px;
}
.home-5 #homePage .home-info {
	text-align: center;
}

.home-1 #homePage .home-info h1,
.home-1 #homePage .home-info h2,
.home-1 #homePage .home-info h3,
.home-1 #homePage .home-info h4 {
	/*text-align: left;*/
}
.home-5 .home-info h4,
.home-5 .home-info h2,
.home-5 .home-info h3 {
	margin-bottom: 0!important;
	
}
/*.home-1 #homePage .home-info h1,
.home-4 #homePage .home-info h1,
.home-7 #homePage .home-info h1 {
	font-size: 70px;
}
.home-1 #homePage .home-info h2,
.home-4 #homePage .home-info h2,
.home-7 #homePage .home-info h2 {
	font-size: 60px;
}
.home-1 #homePage .home-info h3,
.home-4 #homePage .home-info h3,
.home-7 #homePage .home-info h3 {
	font-size: 50px;
}
.home-1 #homePage .home-info h4,
.home-4 #homePage .home-info h4,
.home-7 #homePage .home-info h4 {
	font-size: 40px;
}*/
.home-5 .home-photos {
	height: 100%;
	padding:0;
	display: block;
	width: 100%;
	position: absolute;
	background:#000;;
}

.home-5 .home-photos img {
	width: 100%;
	height: 100%;
	position: relative;
	object-fit: cover;
	opacity: 1;
}


.home-6 .home-icon {
	display: none;
}
.home-6 #homePage {
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	-webkit-align-items: center;
	        align-items: center;
	background: #fff;
}
.home-6 .home-info {
	width: 500px;
	-webkit-align-items: center;
	        align-items: center;
	padding:30px 30px;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	background: inherit;
	position: relative;
	z-index: 13;
	-webkit-transform: translateX(-100px);
	        transform: translateX(-100px);
}

.home-6 .home-photos .hover-box .change-image-btn,
.home-5 .home-photos .hover-box .change-image-btn {
	-webkit-align-self: flex-start;
	        align-self: flex-start;
	margin: 5px 0 0 20px;
}

.home-6 .home-info .home-text div {
	
}
.home-6 .home-photos {
	height: 100%;
	padding:0px 400px 0px 0px;
	display: block;
	width: 100%;
	position: absolute;
	background:transparent;
}


.home-6 .home-photos img {
	width: 100%;
	height: 100%;
	position: relative;
	object-fit: cover;
	opacity: 1;
}

.home-7 .home-info .home-icon {
	display: none;
}

.home-7 #homePage {
	max-width: none;
	max-width: initial;
	margin:0 auto;
}

.home-7 .home-info {
	width: 100%;
	max-width: 800px;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	padding:0 40px 0px 40px;
	-webkit-justify-content: center;
	        justify-content: center;
	color: #fff!important;
	position: relative;
	z-index: 13;
	text-align: center;
	margin:0 auto;
	opacity: 0;
	-webkit-animation:fadeInTop 1s forwards;
	        animation:fadeInTop 1s forwards;
}
.home-7 .home-info .home-text {
	margin:0 auto;
}
.home-7 .home-info .home-description {
	margin-top: 10px;
}
.home-7 .home-photos {
	height: 100%;
	padding:0;
	display: block;
	width: 100%;
	position: absolute;
	background:#000;;
}
.home-8 #homePage .home-info h1 {
	font-size:50px;
}
.home-8 #homePage .home-info h2 {
	font-size:40px;
}
.home-8 #homePage .home-info h3 {
	font-size:30px;
}
.home-8 #homePage .home-info h4 {
	font-size:20px;
}
.home-8 .home-info {
	max-width: 500px;
	background: inherit;
	padding: 40px 50px 70px 50px;
	position: relative;
	z-index: 2;
	opacity: 0;
	-webkit-animation: fadeInTop 1s forwards;
	        animation: fadeInTop 1s forwards;
}
.home-8 .home-info .explore-btn {
	position: absolute;
	bottom: 5px;
	right: 5px;
}
.home-8 .home-photos {
    height: 100%;
    padding: 0;
    display: block;
    width: 100%;
    position: absolute;
    background: inherit;
    left: 0;
    top: 0;
    border:solid 30px transparent;
	border-top:none;
}
.home-8 #homePage {
	-webkit-align-items: flex-end;
	        align-items: flex-end;
	padding:0 60px 60px 60px;
}
.home-8 .home-icon {
	width: 100px;
	height: 100px;
	position: absolute;
	top: -100px;
	left: 0;
	box-shadow: 0 -8px 16px rgba(0,0,0,0.1);
	border-radius: 0;
	object-fit: cover;
	display: none;
}
.home-8 .home-photos img.home-page-hero-image {
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: cover;
    opacity: 1;
}
.home-photos .hover-box {
	height: 40px!important;
	right: 20px!important;
	bottom: 80px!important;
	top: auto;
	left: auto!important;
	visibility: visible;
	opacity: 1;
	-webkit-flex-direction: row;
	        flex-direction: row;
	z-index: 20;

}

.home-6 .home-photos .hover-box {
	right: auto!important;
    left: 20px;
    bottom: 20px!important;
    border:none;
}

.home-photos .hover-box .change-image-btn {
	margin-left: 5px;
	background: linear-gradient(-45deg, #3c4665 0%, #21283F 100%);
	box-shadow: 0 3px 6px rgba(0,0,0,0.3);
}

.home-7 .home-photos img {
	width: 100%;
	height: 100%;
	position: relative;
	object-fit: cover;
	opacity: 1;
}

.home-7 .home-photos:before {
	content: " ";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	background:#000;
	opacity: 0.1;
}

/*.home-7 #homePage h1 {
	font-size: 85px;
}

.home-7 #homePage h2 {
	font-size: 65px;
}
.home-7 #homePage h3 {
	font-size: 45px;
}
.home-7 #homePage h4 {
	opacity: 1;
}*/



.home-photos:hover .cover-images-button {
	opacity: 1;
}

@media (min-width: 779px)  {

	/*.home-7:not(.nav-2) .master-container,
	.home-5:not(.nav-2) .master-container,
	.home-4:not(.nav-2) .master-container {
		padding-top: 0;
	}
	
	.home-7:not(.nav-2) .master-container .nav-bar.text-black,
	.home-5:not(.nav-2) .master-container .nav-bar.text-black,
	.home-4:not(.nav-2) .master-container .nav-bar.text-black {
		background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%)!important;
		box-shadow: 0 0 0 1px rgba(255,255,255,0.2);
	}
	
	.home-7:not(.nav-2) .master-container .nav-bar.text-black:hover,
	.home-5:not(.nav-2) .master-container .nav-bar.text-black:hover,
	.home-4:not(.nav-2) .master-container .nav-bar.text-black:hover {
		box-shadow: 0 0 0 4px rgba(255,255,255,0.3);
	}
	
	.home-7:not(.nav-2) .master-container .nav-bar.text-black a,
	.home-5:not(.nav-2) .master-container .nav-bar.text-black a,
	.home-4:not(.nav-2) .master-container .nav-bar.text-black a {
		color: #fff;
	}
	
	.home-7:not(.nav-2) .master-container #homePage .design-block-toolbar,
	.home-5:not(.nav-2) .master-container #homePage .design-block-toolbar,
	.home-4:not(.nav-2) .master-container #homePage .design-block-toolbar {
		margin-top: 80px!important;
	}
	
	.home-7:not(.nav-2) .master-container #homePage,
	.home-5:not(.nav-2) .master-container #homePage,
	.home-4:not(.nav-2) .master-container #homePage {
		height: calc(100% - 30px);
	}
	
	.home-7:not(.nav-2) .master-container .home-photos .hover-box,
	.home-5:not(.nav-2) .master-container .home-photos .hover-box,
	.home-4:not(.nav-2) .master-container .home-photos .hover-box {
		top: 90px!important;
	}





	.home-7:not(.nav-3) .master-container,
	.home-5:not(.nav-3) .master-container,
	.home-4:not(.nav-3) .master-container {
		padding-top: 0;
	}
	
	.home-7:not(.nav-3) .master-container .nav-bar.text-black,
	.home-5:not(.nav-3) .master-container .nav-bar.text-black,
	.home-4:not(.nav-3) .master-container .nav-bar.text-black {
		background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%)!important;
		box-shadow: 0 0 0 1px rgba(255,255,255,0.2);
	}
	
	.home-7:not(.nav-3) .master-container .nav-bar.text-black:hover,
	.home-5:not(.nav-3) .master-container .nav-bar.text-black:hover,
	.home-4:not(.nav-3) .master-container .nav-bar.text-black:hover {
		box-shadow: 0 0 0 4px rgba(255,255,255,0.3);
	}
	
	.home-7:not(.nav-3) .master-container .nav-bar.text-black a,
	.home-5:not(.nav-3) .master-container .nav-bar.text-black a,
	.home-4:not(.nav-3) .master-container .nav-bar.text-black a {
		color: #fff;
	}
	
	.home-7:not(.nav-3) .master-container #homePage .design-block-toolbar,
	.home-5:not(.nav-3) .master-container #homePage .design-block-toolbar,
	.home-4:not(.nav-3) .master-container #homePage .design-block-toolbar {
		margin-top: 80px!important;
	}
	
	.home-7:not(.nav-3) .master-container #homePage,
	.home-5:not(.nav-3) .master-container #homePage,
	.home-4:not(.nav-3) .master-container #homePage {
		height: calc(100% - 30px);
	}
	
	.home-7:not(.nav-3) .master-container .home-photos .hover-box,
	.home-5:not(.nav-3) .master-container .home-photos .hover-box,
	.home-4:not(.nav-3) .master-container .home-photos .hover-box {
		top: 90px!important;
	}*/
}
@media (max-width: 779px) {
	.home-8 .home-photos {
		height: 50vh;
		position: relative;
		border-width: 20px;
	}
	.home-8 .home-info {
		padding:80px 30px;
		box-shadow: none;
		width: 100%;
		max-width: none;
		max-width: initial;
	}
	.home-8 .home-info .explore-btn {
		position: relative;
	}
	#homePage {
		/*height: calc(100vh - 100px);
		max-height: 650px;*/
		height: auto!important;
		max-height: none!important;
		max-height: initial!important;
		overflow: hidden;
	}
	/*.home-1 #homePage h1 {
		font-size: 65px!important;
	}*/
	.home-6 #homePage .home-info h1 {
		line-height: normal;
	}
	#homePage .home-info .home-icon {
		position: relative;
		bottom:auto;
		left: auto;
	}
	#homePage .home-info .home-description {
		border:none;
		width: 100%;
		margin-left: 0;
		padding:0;
	}
	#homePage .home-info .home-description:before {
		display: none;
	}
	#homePage .home-text {
		width: 100%;
		display: -webkit-flex;
		display: flex;
		padding:0;
		-webkit-flex-direction: column;
		        flex-direction: column;
	}
	.home-5 .home-info .home-description {
		padding:10px 0 10px 0!important;
	}
	.home-1 .home-info {
		padding:30px;
	}
	.home-3 #homePage {
		padding:0px!important;
		-webkit-flex-direction: column;
		        flex-direction: column;
	}
	.home-3 .home-info,
	.home-3 .home-photos {
		width: 100%;
		padding:0!important;
	}

	.home-3 .home-photos {
		height: 50vh;
		margin-top: 50px;
	}

	.home-3 .home-info {
		padding:30px 30px 0 30px!important;
		min-height: 45vh;
		-webkit-justify-content: center;
		        justify-content: center;
	}

	.home-4 .home-info {
		width: 100%!important;
		padding:40px!important;
		margin:auto;
		z-index: 6;
	}
	.home-4 .home-info .home-description {
		margin-top: 15px!important;
	}
	.home-4 .home-photos {
		min-height: 550px;
	}
	.home-6 #homePage {
		height: auto;
	}
	.home-6 .home-photos {
		padding:0;
		position: relative;
		height: 400px;
	}
	.home-6 .home-info {
		-webkit-transform: none;
		        transform: none;
		width: 100%;
		z-index: 6;
		padding:30px;
		-webkit-justify-content: center;
		        justify-content: center;
		min-height: 45vh;
	}

	.home-6 .home-info h1 {
		line-height: normal;
		background: transparent;
		padding:0;
	}
	.home-6 .home-info h4 {
		font-size: 16px;
		background: transparent;
		padding:0
	}
	.home-5 .home-info {
		width: 90%;
		z-index: 6;
		min-width: 0;
		min-width: initial;
	}

	.home-1 #homePage {
		height: auto;
		padding:100px 0!important;
		min-height: 550px!important;
	}
	.home-2 #homePage {
		height: auto;
		min-height: 0;
		min-height: initial;
		padding:20px!important;
	}
	.home-2 .home-info {
		min-height: 40vh;
		padding:20px 0px 50px 0px;
	}
	.home-2 .home-photos {
		height: 45vh;
	}
	.home-7 .home-info {
		margin: auto!important;
	}
	.home-7 #homePage,
	.home-5 #homePage,
	.home-4 #homePage {
		min-height: calc(100vh - 80px)!important;
	}
}

.home-4 .design-page-align-center .home-info {
	-webkit-align-items: center;
	        align-items: center;
}

.home-4 .design-page-align-right .home-info {
	-webkit-align-items: flex-end;
	        align-items: flex-end;
}

.home-5 .design-page-align-left .home-info {
	text-align: left!important;
}

.home-5 .design-page-align-right .home-info {
	text-align: right!important;
}

.home-5 .design-page-align-left#homePage {
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
}

.home-5 .design-page-align-right#homePage {
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
}

.home-7 .design-page-align-left .home-info {
	text-align: left;
	margin:0;
}

.home-7 .design-page-align-right .home-info {
	text-align: right;
	margin-left: auto;
	margin-right: 0;
}
@media (max-width: 778px) {
	.home-7 #homePage, .home-5 #homePage, .home-4 #homePage {
		overflow: visible;
	}
	.home-photos .hover-box {
		bottom: auto!important;
		top: 20px;
		border:none!important;
	}
	/*.home-4 .home-photos .hover-box ,
	.home-5 .home-photos .hover-box,
	.home-7 .home-photos .hover-box {
		top: 80px;
	}*/
	.home-photos .hover-box .change-image-btn {
		font-size: 0;
		padding:10px;
		width: 45px;
		height: 45px;
		display: -webkit-flex;
		display: flex;
	    -webkit-justify-content: center;
	            justify-content: center;
	    -webkit-align-items: center;
	            align-items: center;
	}
	.home-photos .hover-box .change-image-btn img {
		    margin-right: 0!important;
    		width: 13px!important;
    		-webkit-transform: none!important;
    		        transform: none!important;
	}
	.home-5 #homePage .home-info h1 {
		font-size: 35px!important;
		margin-bottom: 0;
	}
	.home-5 #homePage .home-info h2 {
		font-size: 30px!important;
	}
	.home-5 #homePage .home-info h3 {
		font-size: 25px!important;
	}
	.home-5 #homePage .home-info h4 {
		font-size: 20px!important;
	}
	.home-5 #homePage .home-info {
		text-align: center;
	}
}
@media (min-width: 779px) {
	/*.flex-container.design-page-padding-small {
		padding:0;
	}
	.flex-container.design-page-padding-medium {
		padding: 40px 0
	}
	.flex-container.design-page-padding-big {
	 	padding:80px 0;
	}*/
}





.flex-container#tableOfContents {
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	padding:0 20px;
}
.toc-6 .flex-container#tableOfContents,
.toc-7 .flex-container#tableOfContents,
.toc-5 .flex-container#tableOfContents {
	padding:0;
}
.toc-6 .gallery-settings {
	position: absolute;
	width: 600px;
	top: auto;
}
.gallery-settings {
	display: -webkit-flex;
	display: flex;
	text-transform: uppercase;
	width: 100%;
	font-size: 13px;
	padding:10px 20px;
	margin-top: -70px;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	box-shadow: 0 -5px 10px rgba(0,0,0,0.05);
	opacity: 0;
	visibility: hidden;
	transition:all 0.3s;
	-webkit-transform: translateY(10px);
	        transform: translateY(10px);
	position: -webkit-sticky;
	position: sticky;
	top: -80px;
	z-index: 4;
	background: #fff;
}
.grid-container:hover .gallery-settings {
	opacity: 1;
	visibility: visible;
	-webkit-transform: none;
	        transform: none;
}
.add-photo {
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	color:#fff;
	padding:15px 30px;
	border-radius: 50px;
	cursor: pointer;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}
.add-photo:hover {
	background: linear-gradient(-135deg, #28C76F 0%, #28C76F 100%);
}
.add-photo img {
	width: 12px;
	display: inline-block;
	margin-right: 15px;
}


.design-block-background-wrapper .gallery-title-wrapper {
	position: relative;
	z-index: 3;
}
.gallery-title-wrapper p {
	margin:0;
}
.gallery-title {
	width: 100%;
	max-width: 1300px;
	margin:0 auto;
	padding:60px;
}
.gallery-title h1,
.gallery-title h2,
.gallery-title h3,
.gallery-title h4 {
	margin:0 0 0px 0!important;
	padding:0;
}
.gallery-title h1 {
	font-size: 55px
}
.gallery-title h2 {
	font-size: 35px;
}
.gallery-title h3 {
	font-size: 25px;
}
#tableOfContents .grid-container {
	width: calc(100% - 360px);
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-align-content: flex-start;
	        align-content: flex-start;
	padding-top: 30px;

}
#tableOfContents .grid-container.full-width {
	width: 100%;
	padding:0 30px;
	max-width: 1200px;
	margin:0 auto;
}
#tableOfContents .grid-container.full-width a.larger:not(.filter-item) img {
	border-radius: 6px;
}
#tableOfContents .grid-container.full-width a.larger:not(.filter-item) {
	border:solid 10px transparent;
}

.grid-container.full-width .gallery-image-wrapper:before {
	padding-bottom: 80%;
}

.grid-container a.larger:not(.filter-item) {
	width: 33.333%;
	color: inherit;
	position: relative;
	overflow-y: hidden;
	opacity: 0;
	cursor: pointer;
	-webkit-animation: fadeIn .7s forwards;
	        animation: fadeIn .7s forwards;
	-webkit-animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
	        animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
.grid-container a:nth-child(2){
	-webkit-animation-delay: 0.1s;
	        animation-delay: 0.1s;
}
.grid-container a:nth-child(3){
	-webkit-animation-delay: 0.2s;
	        animation-delay: 0.2s;
}
.grid-container a:nth-child(4){
	-webkit-animation-delay: 0.1s;
	        animation-delay: 0.1s;
}
.grid-container a:nth-child(5){
	-webkit-animation-delay: 0.2s;
	        animation-delay: 0.2s;
}
.grid-container a:nth-child(6){
	-webkit-animation-delay: 0.3s;
	        animation-delay: 0.3s;
}
.grid-container a:nth-child(7){
	-webkit-animation-delay: 0.3s;
	        animation-delay: 0.3s;
}
.grid-container a:nth-child(8){
	-webkit-animation-delay: 0.4s;
	        animation-delay: 0.4s;
}
.grid-container a:nth-child(9){
	-webkit-animation-delay: 0.5s;
	        animation-delay: 0.5s;
}
.grid-container.smaller a {
	width: 25%;
}
.grid-container.smaller .month-title {
	width: 25%;
	margin-right: -25%;
	
}

.grid-container .month-title {
	width: 33.333%;
	margin-right: -33.333%;
	position: relative;
	padding:10px;
	z-index: 2;
}

.grid-container .month-title span {
	padding:5px 10px;
	border-radius: 20px;
	background: #fff;
	font-size: 13px;
	text-transform: uppercase;
}
.grid-container a:hover img:not(.like-icon) {
	opacity: 0.73;
}
.grid-container a img:not(.like-icon) {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	object-fit: cover;
	transition: all 0.3s ease;
}
.grid-container a .like-icon {
	width: 10px;

	height: 10px;
	 margin-top: 4px;
    margin-right: 5px;

}
.grid-container a .details {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background: rgba(255,255,255,1);
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	z-index: 3;
	opacity: 0;
	transition: 0.3s all;
	font-size: 23px;
	font-weight: 400;
}
.grid-container a:hover .details {
	opacity: 1;
}
.grid-container a .details i {
	width: 30px;
	height: 30px;
	font-style: normal;
	border-radius: 50%;
	border:solid 1px #000;
	text-align: center;
	line-height: 30px;
	margin-top: 80px;
	opacity: 0;
	font-size: 18px;
	font-weight: 400;
	transition:all 0.3s;
}
.grid-container a:hover .details i {
	opacity: 1;
	margin-top: 10px;
}
.grid-container a img:hover {
	opacity: 0.6;
}
.grid-container a span {
	position: absolute;
	display: -webkit-flex;
	display: flex;
	color: #fff;
	bottom: 20px;
	left: 20px;
	z-index: 4;
	font-size: 13px;
}
.grid-container .gallery-image-wrapper:before {
	content: "";
  display: block;
  padding-bottom: 100%;
  /*background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);*/
  position: relative;
  z-index: 2;
  opacity: 0.2;
}

.image-display-square .grid-container .gallery-image-wrapper:before {
	padding-bottom: 100%!important;
}

.image-display-landscape .grid-container .gallery-image-wrapper:before {
	padding-bottom: 66.666%!important;
}

.image-display-portrait .grid-container .gallery-image-wrapper:before {
	padding-bottom: 130%!important;
}



.grid-container .duration {
	font-size: 11px;
	color: #fff;
	position: absolute;
	bottom: 70px;
	right: 10px;
	background: #000;
	padding:3px 6px;
	font-style: normal;
	border-radius: 4px;
	z-index: 2;
}

#tableOfContents .info-container {
	width: 260px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: left;
    height: calc(100vh - 80px);
    position: relative;
    padding: 80px;
    opacity: 0;
	-webkit-animation: fadeIn .7s forwards;
	        animation: fadeIn .7s forwards;
	-webkit-animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
	        animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
.toc-1 .flex-container#tableOfContents .gallery-title-wrapper,
.toc-7 .flex-container#tableOfContents .gallery-title-wrapper,
.toc-4 .flex-container#tableOfContents .gallery-title-wrapper {
	max-width: 1100px;
	margin:0 auto;
}
.toc-1 .flex-container#tableOfContents .gallery-title-wrapper .gallery-title,
.toc-7 .flex-container#tableOfContents .gallery-title-wrapper .gallery-title,
.toc-4 .flex-container#tableOfContents .gallery-title-wrapper .gallery-title {
	padding:60px 0;
}
.toc-1 .flex-container#tableOfContents .grid-container,
.toc-7 .flex-container#tableOfContents .grid-container,
.toc-4 .flex-container#tableOfContents .grid-container {
	width: 100%;
	max-width: 1100px;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	margin:0 auto 30px auto;
}

.toc-7 .flex-container#tableOfContents .grid-container {
	max-width: 1440px;
}
.toc-4 .flex-container#tableOfContents .grid-container {
	max-width: 1300px;
}
.toc-7 .grid-container .gallery-image-wrapper:before,
.toc-4 .grid-container .gallery-image-wrapper:before {
	padding-bottom: 75%;
	/*background: rgba(0,0,0,0.3);*/
}	
.toc-7 .grid-container .gallery-image-wrapper:hover:before,
.toc-4 .grid-container .gallery-image-wrapper:hover:before {
	opacity: 1;
}
.toc-4 .grid-container a.larger .gallery-item-title {
	display: none;
}
.toc-7 .grid-container a.larger .gallery-item-title {
	position: absolute;
	bottom: 30px;
	left: 0;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #fff!important;
    opacity: 0;
    margin: 0;
    transition: all 0.3s;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    z-index: 2;
}

.toc-7 .grid-container a:hover img:not(.like-icon),
.toc-4 .grid-container a:hover img:not(.like-icon) {
	opacity: 1;
}

.toc-7 .grid-container a.larger:hover .gallery-item-title {
	opacity: 1;
	-webkit-transform: none;
	        transform: none;
}
.toc-4 .grid-container a.larger {
	border:solid 3px transparent;
}
.toc-4.edit-body-mode .grid-container a.larger:nth-child(2)
/*.toc-4.edit-body-mode .grid-container a.larger:nth-child(3)*/ {
	width: 50%;
}

.toc-4 .grid-container a.larger:nth-child(1),
.toc-4 .grid-container a.larger:nth-child(2) {
	width: 50%;
}

.toc-1 .flex-container#tableOfContents .grid-container a .gallery-item-title {
	display: none;
}

.toc-1 .flex-container#tableOfContents .info-container {
	display: none;
}

.toc-2 .flex-container#tableOfContents,
.toc-3 .flex-container#tableOfContents {
	-webkit-flex-direction: row;
	        flex-direction: row;
}
.toc-2 .flex-container#tableOfContents .info-container,
.toc-3 .flex-container#tableOfContents .info-container,
.toc-5 .flex-container#tableOfContents .info-container {

	display: none;
}	
.toc-2 .flex-container#tableOfContents .grid-container,
.toc-5 .flex-container#tableOfContents .grid-container {
	width: 100%;
	max-width: 700px;
	margin:0 auto;
}

.toc-3 .flex-container#tableOfContents .grid-container {
	max-width: 1300px!important;
	width: 100%;
	margin:0 auto;
	padding:30px;
}
.gallery-image-wrapper {
	width: 100%;
	position: relative;
}
.gallery-image-wrapper img {
	display: block;
}
.toc-2 .flex-container#tableOfContents .grid-container a,
.toc-3 .flex-container#tableOfContents .grid-container a,
.toc-5 .flex-container#tableOfContents .grid-container a{
	width: 100%;
	height: auto;
	padding-bottom:20px;
	margin-bottom: 0px;
}
.toc-2 .flex-container#tableOfContents .grid-container a:hover img,
.toc-3 .flex-container#tableOfContents .grid-container a:hover img,
.toc-5 .flex-container#tableOfContents .grid-container a:hover img {
	opacity: 1;
}
.toc-2 .flex-container#tableOfContents .grid-container a .gallery-item-title,
.toc-5 .flex-container#tableOfContents .grid-container a .gallery-item-title {
	margin:0;
	padding:20px 0px 0 0px;
	font-size: 16px;
}

.toc-5 .flex-container#tableOfContents .grid-container a .gallery-item-title {
	font-size: 20px;
}
.gallery-image-wrapper {
	width: 100%;
	position: relative;
}
.toc-2 .flex-container#tableOfContents .grid-container .gallery-image-wrapper:before,
.toc-5 .flex-container#tableOfContents .grid-container .gallery-image-wrapper:before  {
	display: none;
}
.toc-2 .flex-container#tableOfContents .grid-container a  img,
.toc-5 .flex-container#tableOfContents .grid-container a  img {
	position: relative;
	height: auto;
}

.toc-3 .flex-container#tableOfContents .grid-container,
.toc-5 .flex-container#tableOfContents .grid-container {
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	max-width: 1000px;
}
.toc-3 .flex-container#tableOfContents .grid-container a {
	width: calc(50% - 20px);
	padding-bottom: 0;
	border-bottom:none;
	overflow: visible;
	margin-bottom: 40px;
}

.toc-3 .flex-container#tableOfContents .grid-container a .gallery-item-title {
	font-weight: normal;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-top: 10px;
}

.toc-5 .flex-container#tableOfContents .grid-container {
	max-width: 1440px;
}
.toc-5 .flex-container#tableOfContents .grid-container a {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	    -webkit-justify-content: space-between;
	            justify-content: space-between;
    height: auto;
    -webkit-align-items: center;
            align-items: center;
    min-height: auto;
    border-bottom: none;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0;
}
.toc-5 .flex-container#tableOfContents .grid-container a:nth-child(even) {
	-webkit-flex-direction: row-reverse;
	        flex-direction: row-reverse;
}
.toc-5 .flex-container#tableOfContents .grid-container a .gallery-image-wrapper {
	width: 50%;
	object-fit: contain;
	background: transparent;
}
.toc-5 .flex-container#tableOfContents .grid-container a .gallery-item-title {
	width: 50%;
	padding: 0px 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.toc-6 .gallery-title {
	width: 100%;
	max-width: 100%;
	padding-left: 105px;
	text-align: left;
	margin:50px 0;
}
.toc-6 .gallery-title h1 {
	margin-bottom: 0;
}
.toc-6 #tableOfContents .grid-container {
	-webkit-flex-wrap: nowrap;
	        flex-wrap: nowrap;
	padding-left: 100px;
	overflow:auto;
	width: 100%;
}
.toc-6 .grid-container {
	margin-bottom: 50px;
}
.toc-6 .grid-container a.larger:not(.filter-item) {
	width: auto;
	overflow-y: visible;
	overflow-y: initial;
}

.toc-6 .grid-container .gallery-image-wrapper:before {
	display: none;
}

.toc-6 .grid-container a img:not(.like-icon) {
	position: relative;
	height: 450px;
	width: auto;
	margin-right: 5px;
}

.toc-6 .grid-container .gallery-item-title {
	display: none;
}

.toc-1 .flex-container#tableOfContents .grid-container a {
	width: calc(33.333% - 10px);
	margin: 5px;
}


/***RESPONSIVE***/
@media (max-width: 779px) {
	#tableOfContents .info-container {
		padding: 10px 30px 30px 30px;
		width: 100%;
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-flex-wrap: wrap;
		        flex-wrap: wrap;
		height: auto;
	}

	.toc-7 .flex-container#tableOfContents .grid-container a.larger, 
	.toc-4 .flex-container#tableOfContents .grid-container a.larger {
		width: 50%;
	}

	.toc-4 .flex-container#tableOfContents .grid-container a.larger {
		width: 50%;
		margin:0;
	}
	.gallery-title {
		padding:10px 20px;
	}
	#tableOfContents .info-container .toc-filter {
		display: none;
	}
	#tableOfContents .info-container .toc-logo {
		margin-right: 30px;
		width: 55px;
		height: 55px;
	}
	#tableOfContents .info-container .info-wrapper {
		width: calc(100% - 90px);
	}
	.info-wrapper .profile-stats {
		position: relative;
		left: auto;
		bottom: auto;
		margin-left: -10px;
		margin-bottom: 20px;
	}
	#tableOfContents .grid-container {
		width: 100%;
		height: auto;
		padding-top: 0;
	}
	.toc-1 .flex-container#tableOfContents .grid-container a {
		width: calc(50% - 2px);
		margin: 1px;
	}
	.grid-container.smaller a {
		width: 33.333%;
	}
	.grid-container.smaller .month-title {
		display: none;
	}
	#tableOfContents .grid-container a span {
		left: 10px;
		bottom: 10px;
	}
	#tableOfContents .grid-container.full-width {
		padding: 0 10px;
	}
	#tableOfContents .grid-container.full-width a:not(.filter-item) {
		border-width: 3px;
		width: 50%;
	}
	#tableOfContents .grid-container.full-width a:not(.filter-item) img {
		border-radius: 3px;
	}
	.toc-filter.fixed {
		top: 70px;
		left: 20px;
		-webkit-transform: scale(0.7,0.7);
		        transform: scale(0.7,0.7);
    	-webkit-transform-origin: 0 0;
    	        transform-origin: 0 0;
    	z-index: 3;
	}
	.toc-4 #tableOfContents .info-container {
		padding:0;
	}
	.toc-4 .grid-container a.larger {
		width: calc(50% - 10px);
		margin-left:5px;
		margin-right: 5px;
	}
	
	.toc-2 .flex-container#tableOfContents .grid-container a,
	.toc-3 .flex-container#tableOfContents .grid-container a,
	.toc-5 .flex-container#tableOfContents .grid-container a {
		width: 100%!important;
		display: block;
	}
	.toc-5 .flex-container#tableOfContents .grid-container a {
		margin-bottom: 20px;
		padding-bottom:0px;
		padding-top: 0;
	}
	.toc-5 .flex-container#tableOfContents .grid-container a .gallery-image-wrapper,
	.toc-5 .flex-container#tableOfContents .grid-container a .gallery-item-title {
		width: 100%;
	}
	.toc-5 .flex-container#tableOfContents .grid-container a .gallery-item-title {
		margin-top: 20px;
	}

	.toc-6 #tableOfContents .grid-container {
		padding-left: 30px;
	}
	.toc-6 .gallery-title {
		padding-left: 30px;
	}
	.toc-6 .gallery-settings {
		-webkit-justify-content: flex-start;
		        justify-content: flex-start;
		margin-top: -60px;
		padding-left: 0;
	}
	.toc-6 .gallery-settings .gallery-filter {
		display: none;
	}
	.toc-6 .add-photo {
		margin:0;
	}
	.toc-6 #tableOfContents .grid-container {
		padding-top: 60px;
	}
	.toc-6 .grid-container a img:not(.like-icon) {
		height: 300px;
	}

	.edit-body-mode .gallery-title {
		padding-top: 40px;
	}
	.gallery-title {
		margin-bottom: 00px;
		margin-top: 0px;
	}
	.flex-container#tableOfContents .gallery-title-wrapper .gallery-title {
		padding:40px 20px;
	}
	.toc-7 .flex-container#tableOfContents .gallery-title-wrapper .gallery-title,
	.toc-4 .flex-container#tableOfContents .gallery-title-wrapper .gallery-title,
	.toc-1 .flex-container#tableOfContents .gallery-title-wrapper .gallery-title {
		padding:40px 20px;
	}
	.flex-container#tableOfContents .grid-container a .gallery-item-title {
		padding-left: 20px!important;
		padding-right: 20px!important;
	}
}

#articleContent  {
	width: 100%;
	/*max-width: 1440px;*/
	margin:0 auto;
	padding:0px 83px 0px 83px;
	-webkit-justify-content: center;
	        justify-content: center;
	height: auto;
	min-height: 600px;
}

.nav-2 #articleContent .article-image {
	height: 100vh;
}

#articleContent .article-image {
	padding-right: 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: space-around;
            align-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
    height: calc(100vh - 80px);
    min-height: 600px;
    max-height: 800px;
}
#articleContent .video-frame {
	width: 100%;
	height: 100%;
	position: relative;
}

.article-1 #articleContent .video-frame,
.article-2 #articleContent .video-frame {
	height: 300px;
}

.article-1 #articleContent .video-frame:before,
.article-2 #articleContent .video-frame:before {
	content: " ";
	padding-top: 66.666%;
	display: block;
}

.article-1 #articleContent .video-frame iframe,
.article-2 #articleContent .video-frame iframe {
	position: absolute;
	left: 0;
	top: 0;
}

.article-8 .flex-container#articleContent {
	padding:0;
	height: 600px;
	min-height: 0;
	min-height: initial;
	width: 100vw;
	display: -webkit-flex!important;
	display: flex!important;
	padding:0!important;
}

.article-8 #articleContent .article-content {
	position: relative;
	color: #fff;
	z-index: 3;
	padding:0;
	min-height: 0;
	min-height: initial;
	width: 100vw;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	-webkit-flex-direction: column;
	        flex-direction: column;
	padding:50px;
	max-width: 1200px;
	margin:auto;
}

.article-8 #articleContent.design-page-align-center .article-content {
	-webkit-align-items: center;
	        align-items: center;
}
.article-8 #articleContent.design-page-align-right .article-content {
	-webkit-align-items: flex-end;
	        align-items: flex-end;
}

.article-8 .article-content h1 {
	margin-bottom: 0;
}

.article-8 #articleContent .article-image {
	position: absolute;
	width: 100%;
	height: 100%!important	;
	min-height: 0;
	min-height: initial;
	padding:0!important;
}

.article-8 #articleContent .article-image .hover-box {
	width: 100%;
    border: none;
    -webkit-flex-direction: row;
            flex-direction: row;
    height: 40px;
    z-index: 40;
    top: auto;
    bottom: 20px;
    padding:20px;
}

.article-8 #articleContent:hover .article-image .hover-box {
	visibility: visible;
	opacity: 1;

}
.article-8 #articleContent .article-image .hover-box .change-image-btn {
	margin:0 5px;
}

.article-8 #articleContent .article-image img.mySlides,
.article-8 #articleContent .article-image iframe {
	width: 100%;
	height: 100%;
	object-fit: cover;
	max-height: none!important;
	max-height: initial!important;
}

#articleContent .video-frame iframe {
	width: 100%;
	height: 100%;
}

#articleContent .article-image.music-embed .cover-video:before {
	padding-bottom: 100%;
}

#articleContent .article-image iframe {
	width: 100%;
}
#articleContent .article-image.video {
	/*height: auto;*/
	position: relative;
}

#articleContent .article-image.video .cover-video {
	-webkit-animation-delay: 0.5s;
	        animation-delay: 0.5s;
}

#articleContent .article-image.video iframe {
	z-index: 4;
}
#articleContent .article-image img {
	opacity: 0;
    -webkit-animation: imageIn 1s ease forwards;
            animation: imageIn 1s ease forwards;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    /*box-shadow: 0px 13px 50px 0 rgba(0,0,0,0.1);*/
    max-height: 100%;
    max-width: 100%;
    height: auto;
    /*border-radius: 6px;*/
}
.article-content {
	/*display: flex;
	flex-direction: column;*/
	padding: 10px 0px 30px 30px;
	/*justify-content: center;
	align-items: flex-start;*/
}
.article-content img {
	width: auto;
	height: auto;
	max-width: 100%;
	-webkit-align-self: center;
	        align-self: center;
}
.article-content h1,
.article-content h2,
.article-content h3,
.article-content h4 {
    padding-bottom: 0px;
    margin-bottom: 0px;
    position: relative;
    overflow: scroll;
    margin-bottom: 0;
    margin-top: 0;
}

.article-content.with-description {
	margin:auto;
}

.article-content.with-description h1 {
	overflow:inherit;
}

.article-content.with-description .btn {
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
}

.article-content h1.article-big-title {
	margin-bottom: 20px;
}
.article-content .article-description {
	font-size: 18px;
	margin-top: 0;

	line-height: 25px;
}


.article-content h1 a {
	position: relative;
	margin:0px;
	padding:0;
}

.article-content h1 a:after {
	position: absolute;
	width: 100%;
	bottom: 0px;
	content: " ";
	border-bottom: dashed 1px #000;
	left: 0px;
}

.article-2 #articleContent {
	-webkit-flex-direction: row-reverse;
	        flex-direction: row-reverse;
}

.article-2 #articleContent .article-image {
 	padding-left: 30px;
    display: -webkit-flex;
    display: flex;
    padding-right: 0;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: space-around;
            align-content: space-around;
}

.article-2 #articleContent .article-content {
	padding-left: 30px;
	padding-right: 50px;
}

.article-2 #articleContent .article-content .article-stats {
	text-align: right;
}
.article-3 .nav-bar {
	background:#fff;
}
.article-3 #articleContent {
	-webkit-flex-direction: column;
	        flex-direction: column;
	padding:0px 0px 0px 0px;
	display: block;
	height: auto;
}
.article-3 #articleContent .article-image {
	width: calc(100% - 0px);
	margin:0 auto;
	padding:0;
	height: 450px;
	min-height: 0;
	min-height: initial;
}
.article-3 #articleContent .article-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 0;
	box-shadow: none;
	object-position: 50% 50%;
}
.article-3 #articleContent .article-content {
	margin:0 auto;
	width: 100%;
	max-width: 700px;
	margin-top: 40px;
	padding-bottom: 60px;
}
.article-3 #articleContent .article-image.video {
	height: 550px;
	max-height: 700px;
}
.article-3 #articleContent .article-image.video iframe,
.article-3 #articleContent .article-image.video .cover-video {
	height: 100%;
	border-radius: 0;
}

.article-4 #articleContent {
	padding:0px 0px 0px 0px;
}
.article-5 #articleContent {
	padding:0px 0px 0px 0px;
	-webkit-flex-direction: row-reverse;
	        flex-direction: row-reverse;
}
.article-4 #articleContent .article-content {
	padding-left: 60px;
	padding-right: 60px;
	max-width: 600px;
}
.article-5 #articleContent .article-content {
	padding-right: 50px;
}
.article-4 #articleContent .article-image,
.article-5 #articleContent .article-image {
	padding-right: 0;
	max-height: none;
	max-height: initial;
	padding-bottom:0;
}

.edit-body-mode.article-4 #articleContent .article-image,
.edit-body-mode.article-5 #articleContent .article-image  {
	height: calc(100vh - 130px);
}

.nav-2.edit-body-mode.article-4 #articleContent .article-image,
.nav-2.edit-body-mode.article-5 #articleContent .article-image  {
	height: calc(100vh - 50px);
}
.article-4 #articleContent .article-image img,
.article-5 #articleContent .article-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 0;
	box-shadow: none;
}

.article-5 #articleContent .article-content.with-description {
	padding:50px;
	max-width: 600px;
}

.article-6 #articleContent {
	padding:0;
	position: relative;
	min-height: 500px;
}

.article-6 #articleContent .article-content {
	padding:50px 0;
	margin-top: 50px;
	margin-bottom: 50px;
}

.article-6 #articleContent .article-image,
.article-7 #articleContent .article-image {
	display: none;
}

.article-7 #articleContent {
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.article-7 #articleContent .article-image {
	width: 100%;
	padding:15px 0;
}
.article-7 #articleContent .article-content {
	width: 100%;
	max-width: 600px;
}
#about_map,
#about_music,
#about_video {
	width: 100%;
	height: 100%;
}
#about_map iframe,
#about_music iframe,
#about_video iframe {
	width: 100%;
	height: 100%;
	border:none;
}
@media (min-width: 769px) {
	.article-8.nav-2 .flex-container#articleContent {
		width: calc(100vw - 80px);
	}
}
@media (max-width: 1110px) {
	#articleContent .article-image {
		width: 50%;
	}

	#articleContent .article-content {
		width: 50%;
	}

	.article-6 #articleContent .article-content {
		width: 60%;
	} 
}

@media (max-width: 779px) {
	.flex-container h1 {
		font-size: 28px;
	}
	#articleContent {
		min-height: 0!important;
		min-height: initial!important;
	}
	.article-1 #articleContent .article-image,
	.article-2 #articleContent .article-image {
		height: auto!important;
		padding-top: 20px;
		padding-bottom: 0!important;
		margin-bottom: 20px!important;
	}
	.article-1 #articleContent .article-image,
	.article-2 #articleContent .article-image {
		max-height: 500px;
	}
	.flex-container#articleContent {
		padding:0px 0px 15px 0px!important;
		display: block;
	}
	.article-6 .article-content h1.article-big-title {
		-webkit-justify-content: flex-start;
		        justify-content: flex-start;
	}
	#articleContent .article-image {
		width: 100%!important;
		padding:0 0px 30px 0!important;
		max-height: none;
		max-height: initial;
		height: 350px!important;
		min-height: 0;
		min-height: initial;
	}
	#articleContent .article-image img {
		border-radius: 0;
		box-shadow: none;
		max-height: 350px;
	}

	#articleContent .article-content {
		width: 100%;
		padding: 0px 30px 30px 30px!important;
		height: auto!important;
		margin-top: 0!important;
	}
	#articleContent .article-content h1 {
		margin-top: 0;
		overflow:hidden!important;
		width: 100%;
	}
	#articleContent .article-content .article-stats {
		margin-bottom: 0px;
		padding-top: 0;
		box-shadow: none;
		background: transparent;
	}

		.article-6 #articleContent .article-content {
		padding:50px 30px!important;
	}

	.article-6 #articleContent {
		min-height: 0!important;
		min-height: initial!important;
		height: auto!important;
	}

	.article-6 #articleContent .article-content {
		width: 100%;
	}
	.article-8 #articleContent .article-content {
		margin-top: auto!important;
	}
}

#reviews h1,
#reviews .medium-editor-element {
	width: 100%;
}
#reviews {
	display: block;
	padding:50px 100px;
}
#reviews .reviews-title {
	width: 100%;
	text-align: center;
	padding:0 50px;
}

#reviews .reviews-title p {
	margin:0;
	font-weight: normal;
}

#reviews.design-page-align-left .reviews-title {
	text-align: left;
}
#reviews.design-page-align-right .reviews-title {
	text-align: right;
}
#reviews .slider-buttons {
	width: 100%;
	bottom: 0;
	left: 0px;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	border:none;
	box-shadow: none;
	bottom: calc(50% - 105px);
	background: transparent;
}
#reviews .slider-buttons button {
	width: 50px;
	height: 50px;
	border:none;
	font-size: 20px;
}

#reviews .green-add-btn {
	width: 153px;
	position: absolute;
	top: 10px;
	opacity: 0;
	z-index: 3;
	right: 0;
	z-index: 51;
}

#reviews:hover .green-add-btn {
	opacity: 1;
}
.input-group .rating {
	margin:0px 0 10px 0;
}
.input-group .rating img {
	width: 15px;
	height: 15px;
}
.review .button-set {
	position: absolute;
	top: 0;
	left: 0;
	bottom:0;
	right: 0;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-flex-direction: column;
	        flex-direction: column;
	z-index: 2;
	opacity: 0;
	transition: all 0.3s;
	-webkit-transform: translateY(-10px);
	        transform: translateY(-10px);
}
.edit-body-mode .review:hover .review-profile,
.edit-body-mode .review:hover h4,
.edit-body-mode .review:hover .rating,
.edit-body-mode .review:hover p {
	opacity: 0.4;
}
.review:hover .button-set {
	opacity: 1;
	-webkit-transform: translateY(0px);
	        transform: translateY(0px);
}
.review .button-set .btn {
	border:none;
	box-shadow: 0 5px 10px rgba(0,0,0,0.1);
	font-size: 14px;
	margin:5px 0;
	cursor: pointer;
	min-width: 80px;
	background: #21283F;
	color: #fff;
}
.review .button-set .btn.remove {
	color: #000;
	background: #fff;
}

.recommendations-3 .slider-buttons,
.layout-3 .slider-buttons {
	display: none!important;
}

.recommendations-3 .review,
.layout-3 .review {
	display: block!important;
	width: 100%;
	text-align: left;
	min-height: auto;
	border-bottom: solid 1px #efefef;
	padding:60px 60px 60px 100px;
	max-width: 600px;
	margin:0 auto;
}
.recommendations-3 .review .review-profile,
.layout-3 .review .review-profile {
	width: 70px;
	height: 70px;
	position: absolute;
	top: 60px;
	left: 0;
}


.edit-body-mode.recommendations-2 #recom_items_box,
.edit-body-mode .layout-2 #recom_items_box {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}
#reviews #page_title {
	width: 100%;
}

.recommendations-2 #reviews,
.layout-2 #reviews {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}
.recommendations-2 .review,
.layout-2 .review {
	display: block!important;
	width: 50%;
	text-align: left;
	min-height: auto;
	padding:60px;
}
.recommendations-2 .review .review-profile,
.layout-2 .review .review-profile {
	width: 60px;
	height: 60px;
	float: right;
}
.recommendations-2 .rating,
.layout-2 .rating {
	margin:4px 0 40px 0;
}
.recommendations-2 .review,
.layout-2 .review {
	border:none;
}
.recommendations-2 .review:nth-child(even),
.layout-2 .review:nth-child(even) {
	border-right: none;
}
.recommendations-2 .slider-buttons,
.layout-2 .slider-buttons {
	display: none!important;
}

.recommendations-1 .review,
.layout-1 .review {
	width: calc(100% - 100px);
	margin:0 auto;
	position: relative;
	z-index: 3;
}
.review-placeholder {
	width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: relative;
    -webkit-align-items: center;
            align-items: center;
    max-width: 500px;
    padding: 20px;
    margin: 0 auto;
    margin-bottom: 150px;
}

#reviews .review-placeholder .green-add-btn {
	right: calc(50% - 76px);
    top: calc(50% - 25px);
}
.review-placeholder strong {
	width: 100px;
	height: 100px;
	background:#ccc;
	border-radius: 50%;
	display: block;
}

.review-placeholder .rating {
	opacity: 0.3;
	margin:20px 0;
	-webkit-transform: scale(1.1,1.1);
	        transform: scale(1.1,1.1);
}

.review-placeholder p {
	width: 100%;
	height: 30px;
	border-top:  solid 10px #ccc;
	border-bottom:  solid 10px #ccc;
	position: relative;
	margin-top: 0;
}

.review-placeholder p:after {
	width: 50%;
	height: 10px;
	display:  block;
	background: #ccc;
	content: " ";
	position: absolute;
	bottom: -30px;
	left: calc(50% - 25%);
}

.review {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	text-align: center;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-align-content: center;
	        align-content: center;
	padding:50px 0;
	position: relative;
	min-height: 400px;
	opacity: 0;
	-webkit-animation: fadeIn 0.6s forwards;
	        animation: fadeIn 0.6s forwards;
}

.review .review-profile {
	width: 100px;
	height: 100px;
	object-fit: cover;
	border-radius: 50%;
	margin-bottom: 10px;
}

.review h4 {
	font-size: 22px;
	margin:0;
	padding:0;
}

.review p {
	max-width: 500px;
	line-height: 25px;
	margin:0;
}

.rating {
	display: -webkit-flex;
	display: flex;
	margin:14px 0;
}


.rating .fa {
	font-weight: bold;
}


.rating.star-0 .fa:nth-child(5),
.rating.star-0 .fa:nth-child(4),
.rating.star-0 .fa:nth-child(3),
.rating.star-0 .fa:nth-child(2),
.rating.star-0 .fa:nth-child(1) {
	opacity: 0.2;
}


.rating.star-1 .fa:nth-child(5),
.rating.star-1 .fa:nth-child(4),
.rating.star-1 .fa:nth-child(3),
.rating.star-1 .fa:nth-child(2) {
	opacity: 0.2;
}


.rating.star-2 .fa:nth-child(5),
.rating.star-2 .fa:nth-child(4),
.rating.star-2 .fa:nth-child(3) {
	opacity: 0.2;
}

.rating.star-3 .fa:nth-child(5),
.rating.star-3 .fa:nth-child(4) {
	opacity: 0.2;
}
.rating.star-4 .fa:nth-child(5) {
	opacity: 0.2;
}

.rating .fa {
	margin:0 2px;
}

.rating .modal .fa:hover {
	opacity: 1!important;
}
.green-add-btn#addReview {
	width: 154px;
	position: absolute;
	top: 0px;
	left: calc(50% - 77px);
	margin:0px auto;
	display: block;
}

#reviewsBlock {
	width: 100%;
    max-width: 1200px;
    padding: 0 20px;
    position: relative;
    margin: 0 auto;
}
#reviewsBlock .slider-buttons {
	bottom: 0;
}
.layout-1 #reviewsBlock {
	max-width: 600px;
	padding-bottom: 40px;
}
.layout-2 #reviewsBlock {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}
@media (max-width: 779px) {
	.recommendations-2 .review,
	.layout-2 .review {
		width: 100%;
		padding:50px 20px;
		margin-bottom: 50px;
		border-bottom: solid 1px #efefef;
	}
	.recommendations-1 .review,
	.layout-1 .review {
		width: calc(100% - 50px);
	}
	.recommendations-3 .review,
	.layout-3 .review  {
		padding:60px 20px 60px 80px;
	}
	.recommendations-3 .review .review-profile,
	.layout-3 .review .review-profile {
		width: 50px;
		height: 50px;
	}
	.review {
		padding:30px 0px;
	}
	#reviews .green-add-btn {
		position: relative;
		margin:10px auto;
		display: block;
		opacity: 1;
	}
	#reviews {
		padding:20px!important;
	}
	#reviews h1 {
		font-size: 28px;
	}
	.edit-body-mode #reviews h1 {
		margin-top: 20px;
	}
	#reviews .reviews-title {
		padding-left:0;
		padding-right: 0;
	}
}
@media (min-width: 779px) {
	body .nav-bar {
		transition:0.2s;
	}


	.home-4:not(.nav-2):not(.nav-6) .home-photos,
	.home-5:not(.nav-2):not(.nav-6) .home-photos,
	.home-7:not(.nav-2):not(.nav-6) .home-photos,
	.contact-4:not(.nav-2):not(.nav-6) .contact-image,
	.cover-4:not(.nav-2):not(.nav-6) .collage-photos {
		margin-top: -80px;
	    height: calc(100% + 80px);
	}

	.home-4.nav-bar-medium:not(.nav-2):not(.nav-6) .home-photos,
	.home-5.nav-bar-medium:not(.nav-2):not(.nav-6) .home-photos,
	.home-7.nav-bar-medium:not(.nav-2):not(.nav-6) .home-photos,
	.contact-4.nav-bar-medium:not(.nav-2):not(.nav-6) .contact-image,
	.cover-4.nav-bar-medium:not(.nav-2):not(.nav-6) .collage-photos  {
		margin-top: -110px;
	    height: calc(100% + 110px);
	}

	.home-4.nav-bar-large:not(.nav-2):not(.nav-6) .home-photos,
	.home-5.nav-bar-large:not(.nav-2):not(.nav-6) .home-photos,
	.home-7.nav-bar-large:not(.nav-2):not(.nav-6) .home-photos,
	.contact-4.nav-bar-large:not(.nav-2):not(.nav-6) .contact-image,
	.cover-4.nav-bar-large:not(.nav-2):not(.nav-6) .collage-photos {
		margin-top: -140px;
	    height: calc(100% + 140px);
	}

	.home-7 .home-info {
		padding-bottom: 80px;
	}
	.home-5 #homePage .home-info {
		margin-bottom: 80px;
	}


	.home-4:not(.nav-2):not(.nav-6) .nav-bar.nav-transparent,
	.home-5:not(.nav-2):not(.nav-6) .nav-bar.nav-transparent,
	.home-7:not(.nav-2):not(.nav-6) .nav-bar.nav-transparent,
	.article-3:not(.nav-2):not(.nav-6) .nav-bar.nav-transparent,
	.article-8:not(.nav-2):not(.nav-6) .nav-bar.nav-transparent,
	.contact-4:not(.nav-2):not(.nav-6) .nav-bar.nav-transparent,
	.contact-1:not(.nav-2):not(.nav-6) .nav-bar.nav-transparent,
	.menu-5:not(.nav-2):not(.nav-6) .nav-bar.nav-transparent,
	.cover-4:not(.nav-2):not(.nav-6) .nav-bar.nav-transparent {
		color: #fff!important;
		background: transparent!important;
	}

	.home-4.nav-3 .nav-bar.nav-transparent.active,
	.home-5.nav-3 .nav-bar.nav-transparent.active,
	.home-7.nav-3 .nav-bar.nav-transparent.active,
	.article-3.nav-3 .nav-bar.nav-transparent.active,
	.article-8.nav-3 .nav-bar.nav-transparent.active,
	.contact-4.nav-3 .nav-bar.nav-transparent.active,
	.contact-1.nav-3 .nav-bar.nav-transparent.active {
		color: #333!important;
		background: #fff!important;
	}

	.home-4:not(.nav-2):not(.nav-6) .home-photos:after,
	.home-5:not(.nav-2):not(.nav-6) .home-photos:after,
	.home-7:not(.nav-2):not(.nav-6) .home-photos:after {
		content: " ";
	    width: 100%;
	    height: 50%;
	    position: absolute;
	    top: 0;
	    left: 0;
	    z-index: 1;
		background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.2) 93%);
		mix-blend-mode: multiply;
	}
	.article-3.nav-bar-medium:not(.nav-2):not(.nav-6) #articleContent,
	.article-8.nav-bar-medium:not(.nav-2):not(.nav-6) #articleContent,
	.contact-1.nav-bar-medium:not(.nav-2):not(.nav-6) #contactContent,
	.menu-5.nav-bar-medium:not(.nav-2):not(.nav-6) .menu-title {
		margin-top: -110px;
	}
	.article-3.nav-bar-large:not(.nav-2):not(.nav-6) #articleContent,
	.article-8.nav-bar-large:not(.nav-2):not(.nav-6) #articleContent,
	.contact-1.nav-bar-large:not(.nav-2):not(.nav-6) #contactContent,
	.menu-5.nav-bar-large:not(.nav-2):not(.nav-6) .menu-title {
		margin-top: -140px;
	}
	.article-3:not(.nav-2):not(.nav-6) #articleContent,
	.article-8:not(.nav-2):not(.nav-6) #articleContent,
	.contact-1:not(.nav-2):not(.nav-6) #contactContent,
	.menu-5:not(.nav-2):not(.nav-6) .menu-title {
		margin-top: -80px;
	}
	.article-3:not(.nav-2):not(.nav-6) #articleContent .design-block-toolbar,
	.article-8:not(.nav-2):not(.nav-6) #articleContent .design-block-toolbar,
	.contact-1:not(.nav-2):not(.nav-6) #contactContent .design-block-toolbar {
		top: 90px;
	}
	.article-3.nav-bar-medium:not(.nav-2):not(.nav-6) #articleContent .design-block-toolbar,
	.article-8.nav-bar-medium:not(.nav-2):not(.nav-6) #articleContent .design-block-toolbar,
	.contact-1.nav-bar-medium:not(.nav-2):not(.nav-6) #contactContent .design-block-toolbar {
		top:120px;
	}
	.article-3.nav-bar-large:not(.nav-2):not(.nav-6) #articleContent .design-block-toolbar,
	.article-8.nav-bar-large:not(.nav-2):not(.nav-6) #articleContent .design-block-toolbar,
	.contact-1.nav-bar-large:not(.nav-2):not(.nav-6) #contactContent .design-block-toolbar {
		top: 150px;
	}

	.article-3:not(.nav-2):not(.nav-6) .article-image:after,
	.article-8:not(.nav-2):not(.nav-6) .article-image:after,
	.contact-1:not(.nav-2):not(.nav-6) .contact-image:after {
		content: " ";
	    width: 100%;
	    height: 50%;
	    position: absolute;
	    top: 0;
	    left: 0;
	    z-index: 1;
		background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.2) 93%);
		mix-blend-mode: multiply;
	}
}
.nav-logo-wrapper {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	position: relative;
	z-index: 50;
	cursor: pointer;
}

.nav-6 .nav-logo-wrapper,
.nav-7 .nav-logo-wrapper {
	-webkit-align-items: center;
	        align-items: center;
}
.nav-3 .profile-stats .socicon {
	font-size: 22px;
	margin:0 13px;
}
.profile-stats {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
}
.edit-body-mode .profile-stats:hover {
	cursor: pointer;
}
.profile-stats .socicon {
	height: 15px;
	margin:0 8px;
	font-size: inherit;
	position: relative;
	z-index: 2;
}

.profile-stats a {
	position: relative;
}

.profile-stats a:after {
	width: 20px;
    height: 20px;
    background: rgba(0,0,0,0.1);
    border-radius: 50%;
    content: " ";
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
    transition: 0.2s;
    opacity: 0;
    box-shadow: 0 0 0 0 rgba(0,0,0,0.1);
}

.profile-stats a:hover:after {
	opacity: 1;
	box-shadow: 0 0 0 10px rgba(0,0,0,0.1);
}
.edit-body-mode .profile-stats:before {
	width: 60px;
	text-align: center;
	position: absolute;
	left: calc(50% - 30px);
	background: linear-gradient(-45deg, #3c4665 0%, #21283F 100%);
	box-shadow: 0 4px 8px rgba(0,0,0,0.1);
	border-radius: 6px;
	content: "Edit links";
	padding:6px 0;
	bottom: -30px;
	font-family: Source Sans Pro!important;
	opacity: 0;
	visibility: hidden;
	-webkit-transform: translateY(5px);
	        transform: translateY(5px);
	font-size: 11px;
	color: #fff;
	transition: 0.2s;
}
.edit-body-mode .profile-stats:hover:before{
	-webkit-transform: none;
	        transform: none;
	visibility: visible;
	opacity: 1;
}

.nav-2 #cartBtn {
	margin-top: 20px;
	margin-left: 0;
	width: 27px;
}

.nav-2 #cartBtn span {
	left: 15px;
	right: auto;
}
#cartBtn {
	position: relative;
	-webkit-transform: translateY(2px);
	        transform: translateY(2px);
	z-index: 4;
	cursor: pointer;
}
#cartBtn img {
	width: 18px;
	margin-right: 0;
}

.text-white #cartBtn img {
	-webkit-filter: invert(1);
	        filter: invert(1);
}

#cartBtn:hover img {
	-webkit-transform:none;
	        transform:none;
}

#cartBtn span {
	background: #63CAA1;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    color: #fff;
    position: absolute;
    border: solid 2px transparent;
    right: -15px;
    top: -9px;
    font-size: 10px;
    opacity: 1!important;
    font-family: Source Sans Pro;
}

.nav-bar {
	padding:0px 30px;
	height: 80px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	width: 100%;
	z-index: 1000;
	background: #fff;
	position: absolute;
	top: 0;
}
.nav-bar a {
	color: inherit;
}
.nav-bar-fixed-top .nav-bar {
	position: fixed;
}
.nav-1 .nav-bar {
	/*animation: navOneIn 1s forwards;*/
}
@-webkit-keyframes navOneIn {
	from {top: -80px; opacity: 0}
	to {top:0; opacity: 1}
}
@keyframes navOneIn {
	from {top: -80px; opacity: 0}
	to {top:0; opacity: 1}
}

.nav-bar .burger,
.nav-bar #burger  {
	display: none;
	z-index: 200;
}

.nav-bar.text-white a {
	color: #fff;
}
.nav-bar.text-white .nav-links a:before {
	background: #fff;
}
.nav-bar.text-white .burger {
	border-color: #fff;
}
.nav-bar.text-white .burger.close {
	border-color: transparent;
}
.nav-bar.text-white .profile-stats a {
	color: #fff;
}

.nav-3 .nav-bar.text-white .profile-stats a {
	color: #fff;
}
.nav-bar.text-white .burger:before,
.nav-bar.text-white .burger:after {
	background: #fff;
}
.nav-bar .nav-logo {
	margin-left: 0;
	object-fit: contain;
	height: 35px;
	z-index: 2;
	position: relative;
	-webkit-transform-origin: 0 50%;
	        transform-origin: 0 50%;
}

.nav-6 .nav-bar .nav-logo,
.nav-7 .nav-bar .nav-logo {
	-webkit-transform-origin: 50% 50%;
	        transform-origin: 50% 50%;
}

.nav-bar .nav-logo.aspect-circle {
	width: 40px!important;
	height: 40px!important;
	object-fit: cover;
}
.nav-bar .nav-logo.aspect-ratio {
	width: auto;
	height: 35px;
	border-radius: 0;
}
.nav-bar .nav-logo.hidden {
	display: none;
}

.nav-bar-logo-hidden .nav-logo {
	display: none;
}

.montserrat-font-nav .master-container .nav-bar .nav-title,
.raleway-font-nav .master-container .nav-bar .nav-title,
.default-font-nav .master-container .nav-bar .nav-title {
	font-weight: 600;
}
.nav-bar .nav-title {
	font-weight: normal;
	position: relative;
	z-index: 2;

}
.nav-bar .nav-logo {
	height: 35px;
}

.nav-4 .nav-bar .nav-links {
	width: 100%;
	position: absolute;
	text-align: center;
	left: 0;
}

.nav-links-dropdown {
	position: absolute;
	top: 100%;
	font-size: 16px;
	background: inherit;
	box-shadow: 0 5px 10px rgba(0,0,0,0.1);
	left: -19px;
	width: 180px;
	display: none;
	z-index: 2000;
	padding:5px 0;
	-webkit-transform: translateY(4px);
	        transform: translateY(4px);
}

.nav-5 .nav-bar .nav-links a .nav-links-dropdown {
	top: 80%;
}

.nav-3 .nav-links-dropdown {
	top: 0;
	-webkit-transform: none!important;
	        transform: none!important;
	-webkit-animation:none!important;
	        animation:none!important;
	position: relative;
	display: block;
	margin-top: 10px;
}
.nav-3 .nav-bar .nav-links .nav-links-dropdown a {
	padding:6px 0 6px 19px!important;
	border:none!important;
}
.nav-3 .nav-bar .nav-links .nav-links-dropdown a:before {
	content: "- ";
    display: inline;
    position: relative;
    opacity: 1;
    height: auto;
    width: auto;
    left: auto;
    bottom: auto;
    margin-right: 10px;
}
.nav-bar .nav-links a:hover .nav-links-dropdown {
	display: block;
	-webkit-animation:fadeIn .3s forwards;
	        animation:fadeIn .3s forwards;
	-webkit-animation-delay: 0!important;
	        animation-delay: 0!important;
	-webkit-transform: none;
	        transform: none;
}

.nav-bar .nav-links .nav-links-dropdown a {
	margin:0!important;
	padding:10px 20px!important;
	width: 100%;
	font-size: 16px!important;
	white-space: normal;
	font-weight: normal!important;
	float: left;
	text-align: left!important;
	-webkit-animation-delay: 0!important;
	        animation-delay: 0!important;
	transition-delay: 0!important;
	border-bottom: solid 1px rgba(0,0,0,0.1);
}

.nav-bar .nav-links .nav-links-dropdown a:after {
	display: none!important;
}
.nav-bar .nav-links .nav-links-dropdown a:last-child {
	border-bottom: none;
}
.nav-bar .nav-links .nav-links-dropdown a:before {
	display: none;
}

.nav-bar .nav-links {
	font-size: 15px;
	text-transform: none;
	white-space: nowrap;
	background: inherit;
}
.nav-bar .nav-links a {
	margin:0 1.2vw;
	position: relative;
	opacity: 0.7;
	cursor: pointer;
	background: inherit;
}
.nav-bar .nav-links .fa-shopping-cart {
	font-size: 18px;
}
.nav-bar .nav-links a strong {
	font-weight: normal;
	position: relative;
}

.nav-bar .nav-links a strong .fa {
	font-size: 12px;
	margin-left: 6px;
}
.nav-bar .nav-links a.active strong {
	font-weight: 600;
}


.nav-bar .nav-links a.active {
	opacity: 1;
	font-weight: 600;
}
.nav-bar .nav-links a span {
	display: none;
	font-weight: bold;
	margin-right: 3px;
	opacity: 0.2;
}
.nav-bar.show-nav-border {
	border-bottom: solid 1px rgba(0,0,0,0.1);
}
.nav-bar.show-nav-shadow {
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
}
.nav-bar.show-nav-numbers .nav-links a span {
	display: inline-block;
}
.nav-bar .nav-links a:hover {
	opacity: 1;
}
.nav-3 .nav-bar .nav-links a:hover {
	z-index: 4;
}
.nav-bar .nav-links a.home {
	font-weight: 600;
	position: relative;
	margin-left: 20px;
}
.nav-bar .nav-links a:before {
	    height: 11px;
    width: 0%;
    bottom: -3px;
    transition: all 0.2s;
    white-space: nowrap;
    left: 50%;
    /* background: #000; */
    content: "______________________________________";
    position: absolute;
    overflow: hidden;
    line-height: 0;
    letter-spacing: -1px;
    font-weight: 300!important;
    font-family: Source Sans Pro!important;
}

.nav-bar .nav-links a.active:before {
	font-weight: bold!important;
}
.nav-bar .nav-links a:hover:before,
.nav-bar .nav-links a.active:before {
	left: 0%;
	width: 100%;
}
.nav-bar .left {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	/*width: 100%;*/
	position: relative;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.nav-bar .right {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	position: relative;
	z-index: 10;
}


.nav-1 .nav-bar .nav-links {
	margin-left: auto;
}
.nav-1 .nav-bar .right {
	
}

.nav-bar .btn {
	background: #474646;
	color: #fff;
	padding:10px 15px;
	margin-left: 25px;
}
.nav-bar .btn .socicon {
	margin-right: 5px;
	-webkit-transform: translateY(2px);
	        transform: translateY(2px);
}

.nav-bar .btn .arrow {
	background: #fff;
}

.nav-bar .btn .arrow:after {
	    border-top: solid 1px #fff;
    border-right: solid 1px #fff;

}





.click-logo {
	width: 100px;
}

/***NAVBAR WITH BURGER***/
.nav-3 .nav-bar .burger,
.nav-3 .nav-bar #burger {
	display: block;
	margin-right: 30px;
}

.nav-3 .nav-bar .nav-links.active {
	opacity: 1;
	visibility: visible;
	-webkit-transform: none;
	        transform: none;
}


.nav-3 .nav-bar .nav-links {
	position: fixed!important;
	top: 80px;
	left: 0;
	z-index: 44;
	background: #fff;
	height: calc(100% - 80px)!important;
	width: 320px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	font-size: 20px!important;
	text-align: left!important;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	padding:100px 50px;
	text-transform: none;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	opacity: 0;
	visibility: hidden;
	transition: all 0.5s;
	overflow:auto;
	margin-left: 0;
    -webkit-transform: translateX(-300px);
            transform: translateX(-300px);
    background: inherit;
}

.nav-3 .nav-bar .nav-links a:before {
	display: none;
}
.nav-3 .nav-bar .nav-links a:hover,
.nav-3 .nav-bar .nav-links a.active {
	opacity: 1;
}
.nav-3 .nav-bar .nav-links.active a {
	-webkit-transform: translateX(0);
	        transform: translateX(0);
}
.nav-3 .nav-bar .nav-links a {
	transition: all 0.6s;
	margin:0 0 20px 0;
	-webkit-transform: translateX(-70px);
	        transform: translateX(-70px);
}

.nav-3 .nav-bar .nav-links a span {
	display: none;
}
.nav-3 .left {
	-webkit-justify-content: flex-start!important;
	        justify-content: flex-start!important;
	background: inherit;
}
/***NAVBAR WITH BURGER END***/


.burger {
	width: 22px;
	height: 16px;
	border-radius:0px;
	border-top: solid 2px #000;
	border-bottom: solid 2px #000;
	position: relative;
	cursor: pointer;
	transition: all 0.2s ease;
	overflow: visible;
}

.nav-bar .fa-bars {
	font-size: 24px;
    font-weight: 200;
    margin-right: 15px;
    cursor: pointer;
    z-index: 200;
    transition: 0.3s;
}

.nav-bar .fa-bars.close-burger-nav {
	-webkit-transform:rotate(90deg);
	        transform:rotate(90deg);
}

.nav-bar .fa-bars.close-burger-nav:before {
	content: "\f00d";
}

.nav-bar .fa-bars:hover {
	opacity: 0.75;
}


.burger:after {
	width: 2px;
	height: 22px;
	transition: all 0.1s ease;
	content: " ";
	position: absolute;
	left: calc(50% - 1px);
	top: -5px;
	background: #000;
	opacity: 0;
}

.burger:before {
	position: absolute;
	left: 0px;
	transition: all 0.1s;
	width: 100%;
	height: 2px;
	content: " ";
	background: #000;
	top: 50%;
	margin-top: -1px;
}
.burger.close {
	-webkit-transform: rotate(45deg) scale(1.1,1.1);
	        transform: rotate(45deg) scale(1.1,1.1);
	border-color: transparent;
}
.burger.close:after {
	opacity: 1;
}

.arrow {
	height: 1px;
	width: 10px;
	display: inline-block;
	margin-right: 9px;
	background: #000;
	position: relative;
	transition: all 0.2s ease;
}
.arrow:after {
	width: 6px;
	height: 6px;
	content: " ";
	position: absolute;
	border-top:solid 1px #000;
	border-right: solid 1px #000;
	right: 0px;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
    top: -3px;

}

.nav-2 .nav-bar-edit-tools {
	    -webkit-justify-content: flex-end;
	            justify-content: flex-end;
    -webkit-flex-direction: column;
            flex-direction: column;
    white-space: nowrap;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}
.nav-2 .nav-bar-edit-tools button {
	border-radius: 20px;
	margin-bottom: 3px;
}
.nav-bar-edit-tools {
	width: 100%;
	position: absolute;
	bottom: 12px;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	-webkit-justify-content: center;
	        justify-content: center;
	z-index: 10;
	height: 1px;
	opacity: 0;
	transition: 0.2s;
	visibility: hidden;
	-webkit-transform: translateY(-2px);
	        transform: translateY(-2px);
	left: 0;
}

.edit-body-mode .nav-bar:hover {
	box-shadow: 0 6px 12px rgba(0,0,0,0.1),  0 0 0 5px rgba(159,172,198,0.3);
	z-index: 4000;
}

.nav-bar:hover .nav-bar-edit-tools {
	opacity: 1;
	visibility: visible;
	-webkit-transform: none;
	        transform: none;
}
.nav-bar-edit-tools button {
	padding:8px 12px;
	border-radius: 6px 0 0 6px;
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
	margin:0 0px;
	background:linear-gradient(0deg, #3c4665 0%, #21283F 100%);
	border:none;
	outline: none;
	cursor: pointer;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	color: #fff;
	font-size: 12px;
	transition: 0.1s;
}

.nav-bar-edit-tools button:last-child {
	border-radius: 0px 6px 6px 0px;
	border-left: solid 1px #000;
}

.nav-bar-edit-tools button:hover {
	background:linear-gradient(0deg, #515F8A 0%, #515F8A 100%);
}

.nav-bar-edit-tools button img {
	width: 14px;
	height: 14px;
	object-fit: contain;
	-webkit-filter: invert(1);
	        filter: invert(1);
	margin-right: 4px;
}
@media (min-width: 779px) {
	.profile-stats {
		margin-left: 10px;
	}
	.nav-7 .nav-bar  {
		padding:0 15px;
	}
	.nav-7 .nav-bar .nav-logo-wrapper {
		-webkit-flex-direction: column;
		        flex-direction: column;
	}
	.nav-7 .nav-bar .nav-title {
		margin:0 5px;
	}
	.nav-7 .nav-bar .nav-logo {
		margin: 0 5px;
	}
	.nav-7 .nav-bar .left {
		width: 100%;
	    position: absolute;
	    text-align: center;
	    -webkit-justify-content: center;
	            justify-content: center;
	    left: 0;
	}
	.nav-7 .nav-bar .nav-links a {
		margin:0 10px 0 10px;
	}
	.nav-7 .nav-bar .nav-links {
		position: relative;
		z-index: 6;
	}
	.nav-7 .nav-bar .nav-links {
		margin-left: 0;
	}
	.nav-7 .nav-bar .right {
	}
	.nav-5 .nav-bar .left {
		
	}

	.nav-5 .nav-bar .nav-links {
		height: 100%;
		display: -webkit-flex;
		display: flex;
		margin-left: auto;

	}

	.nav-5 .nav-bar .nav-links a {
		height: 100%;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
		-webkit-flex-shrink: 0;
		        flex-shrink: 0;
		margin:0;
		padding:0 20px;
		border-right:solid 1px rgba(0,0,0,0.05);
	}
	.nav-5 #cartBtn span {
		top: 13px;
    	right: 4px;
	}

	.nav-5 .nav-bar.text-white .nav-links a {
		border-right:solid 1px rgba(255,255,255,0.1);
	}
	.nav-5 .nav-bar.text-white .nav-links a:first-of-type {
		border-left:solid 1px rgba(255,255,255,0.1);
	}
	.nav-5 .nav-bar .right,
	.nav-5 .nav-bar .right .profile-stats {
		height: 100%;
	}
	.nav-5 .nav-bar .right .profile-stats {
		padding:0 10px;
	}
	.nav-5 .nav-bar .right #cartBtn {
		height: 100%;
	    display: -webkit-flex;
	    display: flex;
	    -webkit-justify-content: center;
	            justify-content: center;
	    -webkit-align-items: center;
	            align-items: center;
	    margin: 0;
	    padding: 0 20px;
	    border-right: solid 1px rgba(0,0,0,0.1);
	}
	.nav-5 .nav-bar.text-white .right #cartBtn {
		border-right: solid 1px rgba(255,255,255,0.1);
	}
	.nav-5 .nav-bar .right .profile-stats a {
		height: 100%;
	}
	.nav-5 .nav-bar .right .profile-stats a:hover {
		opacity: 0.9;
	}
	.nav-5 .nav-bar .right .profile-stats a .socicon {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-align-items: center;
		        align-items: center;
		padding:0 8px;
		margin:0;
		height: 100%;
	}
	/*.nav-5 .nav-bar .right .profile-stats a .socicon.socicon-facebook {
		background: #3b5998;
	}
	.nav-5 .nav-bar .right .profile-stats a .socicon.socicon-instagram {
		background: #C13584;
	}
	.nav-5 .nav-bar .right .profile-stats a .socicon.socicon-dribbble {
		background: #ea4c89;
	}*/
	.nav-5 .nav-bar {
		border-bottom:solid 1px rgba(0,0,0,0.05);
		padding:0 0 0 20px;
	}
	.nav-5 .nav-bar .nav-links a:before {
		display: none;
	}
	.nav-bar.show-home-icon .nav-links a .fa-home {
		font-size: 16px;
		padding:0 0px;
	}
	.nav-2 .nav-bar.show-home-icon .nav-links a .fa-home {
		padding:0;
	}
	.nav-5 .nav-bar .nav-links a:first-of-type,
	.nav-bar.show-home-icon .nav-links a:first-of-type {
		/*border-left:solid 1px rgba(0,0,0,0.05);
		font-size: 0;
		padding:0 30px;*/
	}
	
	
	.nav-2 .master-container {
		padding-top: 0;
	}
	.nav-2 .nav-bar .nav-logo {
		height: auto!important;
		width: 100%!important;
		max-width: 100px;
		margin-left: 0;
		transition: all 0.3s;
	}
	.nav-2 .nav-bar .nav-logo-wrapper {
		width: 100%;
		padding:0 15px 0 15px;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-justify-content: flex-start;
		        justify-content: flex-start;
		transition: all 0.3s;
	}
	.nav-2 .nav-bar .nav-logo-wrapper {
		opacity: 0;
	}
	.nav-2 .nav-bar:hover .nav-logo-wrapper {
		margin-left: 0px;
		padding-left: 0;
		opacity: 1;
	}
	.nav-2 .nav-bar:hover .nav-logo {
		margin-bottom: 10px;
	}
	.nav-2-visible .master-container {
		padding-left: 200px;
	}
	.nav-2-visible .cover-info {
		padding-left: 0;
	}
	.nav-2 .profile-stats,
	.nav-2-visible .profile-stats {
		-webkit-flex-direction: column;
		        flex-direction: column;
		    width: 80px;
	    -webkit-justify-content: center;
	            justify-content: center;
	    -webkit-align-items: center;
	            align-items: center;
	    margin-left: 0;
	}
	.nav-2 .nav-bar .fa-bars.close-burger-nav:before {
		content: "\f0c9"!important;
	}
	.nav-2 .nav-bar .fa-bars.close-burger-nav {
		-webkit-transform: none!important;
		        transform: none!important;
	}
	.nav-2 .profile-stats .socicon,
	.nav-2-visible .profile-stats .socicon {
		margin:8px 0;
		display: block;
	}
	.nav-2 #coverPage,
	.nav-2-visible #coverPage {
		height: 100%;
		margin-top: 0;
	}
	.nav-2.cover-1 .cover-photos,
	.nav-2-visible.cover-1 .cover-photos {
		height: 100vh;
	}
	.nav-2.cover-5 .flex-container.hero-image,
	.nav-2-visible.cover-5 .flex-container.hero-image {
		margin-top: 0;
	}
	.nav-2 .nav-bar .burger, 
	.nav-2 .nav-bar #burger {
		display: block;
		position: absolute;
		top: 0;
	}

	.nav-2 .nav-bar:hover #burger {
		opacity: 0;
		visibility: hidden;
	}
	.nav-2 .master-container {
		padding-left: 80px;
	}
	.nav-2 .nav-bar .nav-logo-wrapper,
	.nav-2-visible .nav-bar .nav-logo-wrapper {
		-webkit-align-self: flex-start;
		        align-self: flex-start;
	}
	.nav-2 .nav-bar,
	.nav-2-visible .nav-bar {
		padding:20px 0;
		width: 80px;
		height: 100%;
		border-right: solid 1px rgba(0,0,0,0.05);
		left: 0;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-align-items: flex-start;
		        align-items: flex-start;
		transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
		position: fixed!important;
		-webkit-animation: navTwoIn 1s forwards;
		        animation: navTwoIn 1s forwards;
		box-shadow: 8px 0 16px rgba(0,0,0,0);
	}
	.nav-2.edit-body-mode .nav-bar {
		height: calc(100% - 50px);
		position: fixed!important;
		top: 50px!important;
	}

	.nav-2-visible .nav-bar {
		width: 200px;
		border:none;
	}
	@-webkit-keyframes navTwoIn {
		0% {-webkit-transform: translateX(-80px);transform: translateX(-80px);}
		100% {-webkit-transform: none;transform: none;}
	}
	@keyframes navTwoIn {
		0% {-webkit-transform: translateX(-80px);transform: translateX(-80px);}
		100% {-webkit-transform: none;transform: none;}
	}
	.nav-2 .nav-bar .nav-title,
	.nav-2-visible .nav-bar .nav-title {
		margin-left: 0;
		font-size: 100%!important;
		transition: all 0.2s;
		width: 200px;
	}
	.nav-2 .nav-bar:hover .nav-title {
		opacity: 1;
		margin-left: 0px;
	}
	
	.nav-2 .nav-bar .left,
	.nav-2-visible .nav-bar .left {
		-webkit-flex-direction: column;
		        flex-direction: column;
		width: 100%;
		/*overflow: hidden;*/
		-webkit-align-items: flex-start;
		        align-items: flex-start;
		padding-left: 30px;
	}
	.nav-2 .nav-bar .right,
	.nav-2-visible .nav-bar .right {
		-webkit-flex-direction: column;
		        flex-direction: column;
	}
	.nav-2 .nav-bar .nav-links,
	.nav-2-visible .nav-bar .nav-links {
		width: calc(100% - 35px);
		margin:20px 0 0 0;
		-webkit-transform: translateX(30px);
		        transform: translateX(30px);
	}
	.nav-2 .nav-bar .nav-links a,
	.nav-2-visible .nav-bar .nav-links a {
		margin:10px 0;
		padding:0px 0;
		float: left;
		width: 100%;
		white-space: nowrap;
	}

	.nav-2 .nav-bar .nav-links a {
		transition: all 0.2s;
		opacity: 0.5;
		display: -webkit-flex;
		display: flex;
	}
	.nav-2 .nav-bar .nav-links a:hover,
	.nav-2 .nav-bar .nav-links a.active {
		opacity: 1;
	}
	.nav-2 .nav-bar:hover .nav-links a {
		color: inherit;
	}
	.nav-2 .nav-bar .nav-links a span{
		color: inherit;
		opacity: 0.6;
	}
	.nav-2-visible .nav-bar .nav-links a span {
		display: none;
	}
	.nav-2 .nav-bar .nav-links a:before,
	.nav-2-visible .nav-bar .nav-links a:before {
		display: none;
	}

	.nav-2 .nav-bar .nav-links a span {
		margin-right: 40px;
		display: block;
		transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
		font-size: inherit!important;
		-webkit-transform: none!important;
		        transform: none!important;
	}

	.nav-2 .nav-bar .nav-links a strong {
		display: block;
		white-space: nowrap;
		width:135px;
		opacity: 0;
		-webkit-flex-shrink: 0;
		        flex-shrink: 0;
	}

	.nav-2 .nav-bar:hover .nav-links a strong {
		white-space: normal;
		opacity: 1;
	}
	.nav-2 .nav-bar.text-white .nav-links a span {
		color: #fff;
	}

	.nav-2 .nav-bar.text-white:hover .nav-links a {
		color: #fff;
	}
	.nav-2 .nav-bar {
		z-index: 2000;
		overflow: hidden;
	}
	.nav-2 .nav-bar:hover {
		width: 200px;
		/*box-shadow: 8px 0 16px rgba(0,0,0,0.1);*/
	}

	.nav-2 #homePage {
		height: calc(100vh - 0px);
	}
	.nav-2.edit-body-mode #homePage {
		height: calc(100vh - 50px);
	}

	.home-8.nav-2 .home-photos {
		border:none;
	}

	.nav-2 .nav-bar:hover .nav-links a span {
		margin-right: 10px;
	}
	
	.nav-2 .nav-bar:hover .account-button,
	.nav-2 .nav-bar:hover .btn {
		font-size: 12px;
	}
	.nav-2 .nav-bar .btn .socicon {
		font-size: 12px;
	}

	.nav-2.article-6 #articleContent {
		padding:0px 80px 0px 80px;
		margin-top: 0;
	}
	.nav-2.article-5 #articleContent {
		padding:0 0 0 30px;
	}
	.nav-2.article-4 #articleContent {
		padding:0 30px 0 0px;
	}
	.nav-2 .navigation-arrow-wrapper {
		left: 80px;
		z-index: 4;
	}

	.nav-3 .nav-bar .nav-links {
		    position: absolute;
		    width: 100%;
		    max-width: 320px;
		    left: 0;
		    margin: 0;
		    text-align: center;
	}

	.nav-big .nav-bar .nav-links {
		font-size: 15px;
		font-weight: 600;
	}

	.nav-6 .nav-bar {
		height: 140px;
		position: absolute;
		-webkit-flex-wrap: wrap;
		        flex-wrap: wrap;
		-webkit-align-content: center;
		        align-content: center;
		padding-bottom: 35px;

	}
		.nav-6 .nav-bar .nav-title {
		margin-left: 0;
	}
	.nav-6 .nav-logo-wrapper {
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-justify-content: center;
		        justify-content: center;
	}



	.nav-6 .nav-bar .left {
		-webkit-flex-direction: column;
		        flex-direction: column;
		margin:0 auto;
	}
	.nav-6 .nav-bar .nav-links {
		margin:0;
		width: 100%;
		text-align: center;
		position: absolute;
		bottom: 0;
		padding-bottom: 20px;
		left: 0;
	}

	.nav-6 .nav-bar .nav-logo.aspect-ratio {
		width: auto;
	}
	.nav-6 .nav-bar .right {
		position: absolute;
		top: 20px;
		right: 20px;
	}
	.edit-body-mode.nav-6:not(.nav-bar-fixed-top) .master-container {
		padding-top: 0!important;
	}
	.nav-6 .master-container {
		padding-top: 140px;
	}

	.nav-bar-medium:not(.nav-2) .nav-bar {
		height: 110px;
	}

	.nav-bar-large:not(.nav-2) .nav-bar {
		height: 140px;
	}

	.nav-bar-fixed-top #app .nav-bar {
		position: fixed;
	}

	#app .master-container {
		padding-top: 80px;
	}

	.nav-2 #app .master-container {
		padding-top: 0px;
	}

	.nav-bar-medium:not(.nav-2) #app .master-container {
		padding-top: 110px!important;
	}
	.nav-bar-large:not(.nav-2) #app .master-container {
		padding-top: 140px!important;
	}

	.nav-6 #app .master-container {
		padding-top: 140px!important;
	}
	.nav-6.nav-bar-medium #app .master-container {
		padding-top: 160px!important;
	}

	.nav-6.nav-bar-large #app .master-container {
		padding-top: 200px!important;
	}
	.nav-6.nav-bar-medium .nav-bar {
		height: 160px;
	}
	.nav-6.nav-bar-large .nav-bar {
		height: 200px;
	}

	.nav-bar-medium:not(.nav-2)  .nav-bar .nav-logo {
		-webkit-transform: scale(1.3);
		        transform: scale(1.3);
		margin-right: 10px;
	}

	.nav-bar-large:not(.nav-2)  .nav-bar .nav-logo {
		-webkit-transform: scale(1.5);
		        transform: scale(1.5);
		margin-right: 20px;
	}

	.nav-bar-large:not(.nav-2) .nav-bar .nav-title {
		
	}

	.nav-bar-medium:not(.nav-2) .nav-bar .nav-title {
		
	}

	.nav-bar-fixed-top .nav-bar {
		position: fixed!important;
	}

}

@media (max-width: 779px) {
	.nav-logo-wrapper {
		-webkit-align-items: flex-start!important;
		        align-items: flex-start!important;
		-webkit-justify-content: center;
		        justify-content: center;
	}
	.nav-bar .nav-title {
		font-size: 24px!important;
	}
	.nav-bar-edit-tools  {
		display: none;
	}
	.nav-bar {
		padding: 0 20px;
		background: #fff;
		z-index: 2001;
		height: 60px;
		position: fixed;
	}
	.nav-bar .toc-filter{
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
	}
	.nav-bar .toc-filter img {
		width: 20px;
		height: 20px;
	}
	.nav-bar .toc-filter .filter-item.active {
		box-shadow: none;
	}
	.nav-bar .nav-logo {
		margin-left: 5px;
		height: 35px;
	}
	.nav-bar .left {
		-webkit-justify-content: flex-start!important;
		        justify-content: flex-start!important;
		width: 100%!important;
		background: inherit;
		z-index: 400;
		height: 100%;
	}

	.nav-bar .left .nav-logo-wrapper {
		height: 100%;
	}
	.nav-bar .left .nav-links {
		background:inherit!important;
	}
	.nav-bar .account-button {
		font-size: 0px;
	}
	.nav-bar .account-button .dropdown {
		font-size: 12px;
	}
	.nav-bar .burger,
	.nav-bar #burger {
		display: block;
		position: relative;
		z-index: 400;
		margin-right: 0px!important;
		position: fixed;
		right: 20px;
		top: 22px;
	}

	.edit-body-mode .nav-bar .burger {
		top: 25px;
	}

	.nav-bar .left .nav-logo-wrapper {
		transition: 0.2s;
	}
	.nav-bar .right {
		position: fixed;
		top: 90px;
		left: auto;
		right: -10px;
		opacity: 0;
		visibility: hidden;
		z-index: 3000;
		transition: all 0.3s;
	}
	.nav-bar.active .right {
		top: 90px;
	    opacity: 1;
	    visibility: visible;
	    left: auto;
	    height: calc(100vh - 90px);
	    right: 0px;
	    background: inherit;
	    width: 50px;
	    -webkit-align-items: flex-start;
	            align-items: flex-start;
	}
	.nav-bar .right .profile-stats {
		-webkit-flex-direction: column;
		        flex-direction: column;
		width: 100%;
	}
	.nav-bar .right .profile-stats a {
		margin-bottom: 20px;
	}
	.nav-bar .nav-logo {
		max-height: 50px!important;
	}
	#cartBtn img {
		width: 28px!important;
	}
	.nav-2 .profile-stats {
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-justify-content: space-around;
		        justify-content: space-around;
	}
	.nav-3 .nav-bar .nav-links,
	.nav-bar .nav-links {
		position: fixed!important;
		width: calc(100vw - 0px);
		height: calc(100vh - 0px)!important;
		font-size: 20px;
		left: -40px!important;
		background: inherit;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		margin-left: 0;
		padding:90px 0px 40px 0px;
		z-index: 3;
		-webkit-justify-content:flex-start;
		        justify-content:flex-start;
		visibility: hidden;
		-webkit-align-items: flex-start;
		        align-items: flex-start;
		text-align: left!important;
		opacity: 0;
		transition: all 0.3s;
		top: 0px!important;

	}
	.profile-stats .socicon {
		font-size: 20px;
	}
	.nav-bar .nav-links.active {
		opacity: 1;
		visibility: visible;
		top: 0px!important;
		left: 0!important;
		z-index: 40;
		overflow: auto;
		padding-bottom: 90px;
	}
	.nav-bar .nav-links a {
		margin: 0!important;
	    width: 100%;
	    padding: 12px 30px;
	    font-size: 20px!important;
	}
	.nav-bar .nav-links a strong {
		display: inline-block;
		width: 100%;
	}
	.nav-bar .nav-links a:before {
		display: none;
	}
	.nav-bar .nav-links a:first-child:after {
		display: none;
	}
	.nav-links-dropdown {
		position: relative;
		box-shadow: none;
		top:auto!important;
		width: 100%;
		margin: 0;
		padding-bottom: 0;
		display: block;
		float: left;
		padding-left: 18px;
		font-size: inherit;
		text-align: inherit;
		left: auto;
	}
	.nav-links-dropdown a {
		opacity: 1!important;
		border-bottom:none!important;
	}

	.nav-bar .nav-links .nav-links-dropdown a {
		font-size: inherit!important;
		padding:10px 0px!important;
		text-align: inherit!important;
	}
	.edit-body-mode #app {
		padding-top: 0;
	}
	.master-container {
		padding-top: 60px!important;
	}

}


.site-footer {
	width: 100%;
	font-size: 14px;
	padding:60px;
	margin-top: 0px;
	display: -webkit-flex;
	display: flex;
	font-size: 14px;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	background: #fafafa;
	font-weight: 500;
	position: relative;
	overflow: hidden;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}
.click-creation {
	margin-top: 20px;
	opacity: 0.5;
}
.site-footer a {
	color: inherit;
}
.site-footer a strong {
	font-weight: normal;
}
.site-footer.text-white a,
.site-footer.text-white {
	color: #fff;
}
.site-footer-section a,
.site-footer-section span {
	margin-bottom: 6px;
}
.site-footer-section a:hover {
	opacity: 0.7
}
.site-footer-section {
	/*width: 28%;*/
	max-width: 28%;
	padding:0 10px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-align-content: flex-start;
	        align-content: flex-start;
}
.site-footer-section.social-media-footer-section {
	-webkit-flex-direction: row;
	        flex-direction: row;
	width: 16%;
}

.site-footer-section.social-media-footer-section a {
	margin-right: 10px;
	cursor: pointer;
}
.site-footer-section h4 {
	width: 100%;
	margin:0 0 10px 0;
	font-size: 12px!important;
    text-transform: uppercase;
}
.site-footer-legal {
	width: 100%;
	text-align: center;
	padding-top: 20px;
	margin-top: 40px;
	border-top: solid 1px rgba(0,0,0,0.1);
}

.site-footer-legal a {
	margin:0 15px;
	cursor: pointer;
}
.site-footer-legal a:hover {
	opacity: 0.7;
}
@media (min-width: 779px) {
	.site-footer-2 .site-footer {
		padding:30px;
	}
	.site-footer-2 .site-footer-section h4 {
		display: none;
	}
	.site-footer-2 .site-footer-section {
		width: 100%!important;
		-webkit-flex-direction: row;
		        flex-direction: row;
		text-align: center;
		margin:8px 0;
		max-width: none;
		max-width: initial;
		-webkit-justify-content: center;
		        justify-content: center;
	}

	.site-footer-2 .site-footer-section a,
	.site-footer-2 .site-footer-section span {
		margin:0 7px;
	}

	.site-footer-2 .site-footer-legal {
		margin-top: 20px;
	}

	.site-footer-3 .site-footer-section h4 {
		display: none;
	}
	.site-footer-3 .site-footer-section a {
		margin:0 15px 4px 15px;
	}
	.site-footer-3 .site-footer-section  {
		max-width: none;
		max-width: initial;
		-webkit-order: 1;
		        order: 1;
		-webkit-flex-direction: row;
		        flex-direction: row;
	}
	.site-footer-3 .site-footer-section.contact-footer-section {
		-webkit-order: 3;
		        order: 3;
		width: 100%;
		-webkit-flex-direction: column;
		        flex-direction: column;
		margin-top: 18px;
	}

	.site-footer-3 .site-footer-legal {
		-webkit-order: 4;
		        order: 4;
	}
	.site-footer-3 .site-footer-section.social-media-footer-section {
		width: 230px!important;
		-webkit-justify-content: flex-end;
		        justify-content: flex-end;
	}
	.site-footer-3 .site-footer-section.copyright-footer-section {
		width: 230px;
	}
	.site-footer-3 .site-footer-section.site-map-footer-section {
		width: calc(100% - 460px);
		-webkit-justify-content: center;
		        justify-content: center;
	}
	.site-footer-4 .site-footer-section h4 {
		display: none;
	}

	.site-footer-4 .site-footer {
		display: block;
	}
	.site-footer-4 .site-footer-section {
		width: auto;
		max-width: none;
		max-width: initial;
	}
	.site-footer-4 .site-footer-section.social-media-footer-section,
	.site-footer-4 .site-footer-section.contact-footer-section {
		float: right;
		-webkit-align-items: flex-end;
		        align-items: flex-end;
		text-align: right;
	}
	.site-footer-4 .site-footer-section.social-media-footer-section {
		clear: right;
	}
	.site-footer-4 .site-footer-section.site-map-footer-section {
		clear: left;
		display: block;
		margin-top: 10px;
	}

	.site-footer-4 .site-footer-section.site-map-footer-section a {
		display: inline-block;
		margin-right: 14px;
	}
	.site-footer-4 .site-footer-section.site-map-footer-section,
	.site-footer-4 .site-footer-section.copyright-footer-section {
		float: left;
		text-align: left;
	}
	.site-footer-4 .site-footer-legal {
		float: left;
		text-align: left;
		width: 100%;
	}
}

@media (max-width: 779px) {
	.site-footer {
		padding:20px 30px;
		-webkit-flex-direction: column;
		        flex-direction: column;
		text-align: left!important;
	}
	.site-footer-section {
		width: 100%!important;
		margin:18px 0;
		padding:0;
		max-width: none;
		max-width: initial;
	}
	.site-footer-legal {
		padding-top: 20px;
		margin-top: 20px;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		text-align: left;
	}
	.site-footer-legal a {
		margin:0 0 6px 0;
	}
	.site-footer-2 .site-footer-section h4 {
		display: none;
	}
	.site-footer-2 .site-footer-section {
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-align-content: center;
		        align-content: center;
		text-align: center;
		align-content: center;
		-webkit-align-items: center;
		        align-items: center;
	}
	.site-footer-2 .site-footer-legal { 
		text-align: center;
	}
	.site-footer-2 .site-footer-section.social-media-footer-section a {
		margin:0 5px;
	}

}

.click-ai-footer {
	width: 100vw;
	position: relative;
	z-index: 5001;
	height: 50px;
	background: #333;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	padding:0 20px;
	-webkit-align-items: center;
	        align-items: center;
	font-size: 16px;
	font-weight: 500;
	color: #fff;
	border-top:dashed 1px rgba(0,0,0,0.1);
}

.click-ai-footer a {
	color: #fff;
	text-decoration: underline;
	font-weight: bold;
}

.click-ai-footer span {
	margin-right: 20px;
}

.click-ai-footer img {
	height: 24px;
	margin-left: 5px;
}
.click-ai-footer .left,
.click-ai-footer .right {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}

.click-ai-footer .publish-btn {
	padding:8px 16px;
	text-decoration: none;
	font-weight: normal;
	font-size: 14px;
	margin:0;
}



.nav-2 .click-ai-footer {
		width: calc(100vw - 80px);
	}

@media (max-width: 779px) {
	.click-ai-footer {
		-webkit-flex-direction: column;
		        flex-direction: column;
		height: 100px;
	}
	.click-ai-footer .publish-btn {
		margin-top: 8px;
	}
	.nav-2 .click-ai-footer {
		width: 100vw;
	}
}


.empty-store-product-picker {
	width: 100%;
	max-width: 400px;
	margin:0 auto;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	padding:0 30px;
}

.empty-store-product-picker h4 {
	margin:30px 0;
	text-align: center;
	width: 100%;
}
.empty-store-product-picker img {
	width: 100%;
}

body.click-store .nav-bar {
	position: fixed;
	top: 0;
	background: rgba(255,255,255,0.95);
	z-index: 4;
}
body.click-store .master-container {
	/*padding-top: 100px;*/
}

body.click-store {
    background-repeat: repeat;
    background-size: 6px 6px;
}

body.click-store .nav-bar .nav-logo {
	width: auto;
	margin:0;
	height: 30px;
	box-shadow:none;
}

.blank-btn {
	padding:12px 24px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	background: #262E47;
	border-radius: 6px;
	color: #fff;
	cursor: pointer;
	margin-top: 20px;
	outline: none;
	border:none;
}

.product-inventory .blank-btn {
	padding: 10px 20px;
    margin-top: 0;
}

.blank-btn:hover {
	background: #465379;
}

.blank-btn .fa {
	margin-right: 10px;
}
.btn.green img {
	width: 15px;
	margin-right: 15px;
}

.btn.green:focus {
	outline: none;
}


.product-options select {
	width: calc(100% - 120px);
	padding-left: 20px;
	padding-right: 20px;
	height: 60px;
	border:solid 1px #efefef;
	border-bottom: solid 2px #000;
}
.product-options label {
	position: absolute;
	font-size: 12px;
	font-weight: 600;
	left: 0px;
	top: -19px;
	z-index: 2;
	opacity: 0.5;
}

.text-white .product-options label {
	color: #fff;
}
.product-options select {
	background-image: linear-gradient(45deg, transparent 50%, #333 50%),
    linear-gradient(135deg, #333 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(2em + 0px), calc(100% - 15px) calc(2em + 0px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 45px;
    background-repeat: no-repeat;
    background-color: #fff;
    appearance: none;
    -webkit-appearance: none;
  	-moz-appearance: none;
}
.product-options input {
	height: 60px;	
	width: 100px;
	margin-right: 20px;
	margin-bottom: 20px;
	background: #fff;
	padding:10px;
	transition: all 0.2s;
	text-align: center;
	color: #333;
	border:solid 1px #efefef;
	border-bottom: solid 2px #000;
	font-size: 16px;
}
.product-options select:hover,
.product-options input:hover,
.product-options select:focus,
.product-options input:focus{
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
}

#productContent {
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
}

.product-1#productContent,
.product-2#productContent {
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	padding:30px;
}
.product-1#productContent .product-image-wrapper,
.product-2#productContent .product-image-wrapper {
	position: relative;
	display: block;
	min-height: 0;
	min-height: initial;
	height: auto!important;
	max-height: none;
	max-height: initial;
	margin-bottom: 200px;
}
.product-1#productContent .product-image-wrapper,
.product-1#productContent .product-details-wrapper,
.product-2#productContent .product-image-wrapper,
.product-2#productContent .product-details-wrapper {
	max-width: 600px;
}
.product-1#productContent .product-image-wrapper:before ,
.product-2#productContent .product-image-wrapper:before {
	padding-top: 100%;
	content: " ";
	display: block;
}

.product-1 .product-image-wrapper .product-image-thumbs,
.product-2 .product-image-wrapper .product-image-thumbs {
	z-index: 3;
    width: 100%;
    height: 20%;
    -webkit-flex-direction: row;
            flex-direction: row;
    left: 0;
    bottom: calc(-20% - 3px);
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

.product-1#productContent .product-image-wrapper .product-image-thumbs img,
.product-2#productContent .product-image-wrapper .product-image-thumbs img	{
	width: 20%;
	height: 100%;
	border-radius: 0;
	border:none;
	margin:0px;
	border-right:solid 3px transparent;
	-webkit-transform: none;
	        transform: none;
	box-shadow: none;
	opacity: 0.5;
}
.product-1#productContent .product-image-wrapper .product-image-thumbs img.active,
.product-2#productContent .product-image-wrapper .product-image-thumbs img.active,
.product-1#productContent .product-image-wrapper .product-image-thumbs img:hover,
.product-2#productContent .product-image-wrapper .product-image-thumbs img:hover {
	opacity: 1;
}
.product-1#productContent .product-image-wrapper .product-image-thumbs img:last-child,
.product-2#productContent .product-image-wrapper .product-image-thumbs img:last-child {
	border:none;
}

.product-1 .product-image-wrapper img.active ,
.product-2 .product-image-wrapper img.active {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
}

@media (max-width: 779px) {
	.product-1#productContent,
	.product-2#productContent {
		-webkit-justify-content: flex-start;
		        justify-content: flex-start;
		-webkit-align-items: center;
		        align-items: center;
		padding-top: 0px!important;
	}
	.product-1#productContent .product-image-wrapper, 
	.product-2#productContent .product-image-wrapper {
		margin-bottom: 0;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		height: auto;
	}
	.product-1#productContent .product-image-wrapper img.active,
	.product-2#productContent .product-image-wrapper img.active {
		max-height: 520px;
		position: relative;
	} 
	.product-1#productContent .product-image-wrapper:before,
	.product-2#productContent .product-image-wrapper:before {
		display: none;
	}
	.product-1 .product-image-wrapper .product-image-thumbs,
	.product-2 .product-image-wrapper .product-image-thumbs  {
		position: relative;
		bottom: auto;
		left: auto;
		-webkit-order: 2;
		        order: 2;
		margin-top: 3px;
	}
	.product-1#productContent .product-image-wrapper .product-image-thumbs img,
	.product-2#productContent .product-image-wrapper .product-image-thumbs img {
		width: 20%;
		height: 80px!important;
	}
}
#productContent .design-block-toolbar {
	opacity: 1;
    visibility: visible;
    -webkit-transform: none;
            transform: none;
}
.product-details-title {
	position: relative;
}
#productContent.product-2 {
	-webkit-flex-direction: row-reverse;
	        flex-direction: row-reverse;
}

@media (min-width: 779px) { 
#productContent.product-3 {
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

@media (min-width: 779px) { 
	#productContent.product-3 .product-options {
		-webkit-flex-wrap: nowrap;
		        flex-wrap: nowrap;
	}
	#productContent.product-3 .product-options select {
		margin-right:20px;
	}
	#productContent.product-4 {
		-webkit-justify-content: center;
		        justify-content: center;
	}
	#productContent.product-4 .product-image-wrapper {
		max-width: 550px;
	}
	#productContent.product-4 .product-image-wrapper img {
		object-fit: contain;
		object-position: 50%;
	}

	#productContent.product-4 .product-details-title h1 {
		font-size: 40px;
	}

	#productContent.product-4 .product-details-wrapper {
		padding:50px;
		max-width: 550px;
	}
	#productContent.product-4 .product-image-wrapper .product-image-thumbs img{
		object-fit: cover;
		object-position: 50% 50%;
	}

	#productContent.product-4 .product-image-wrapper .product-image-thumbs {
		margin-left: 20px;
	}
}


#productContent.product-3 .product-image-wrapper {
	width: 100%;
	border-left:solid 0px transparent;
	border-right:solid 0px transparent;
	max-width: 1400px;
	margin:0 auto;
}
#productContent.product-3 .product-image-wrapper .product-image-thumbs {
	left: auto;
	right: 30px;
	z-index: 45;
}
#productContent.product-3 .product-image-detail {
	position: absolute;
	top: 0;
	left: 0;
}
#productContent.product-3 .product-details-wrapper {
	position: relative;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	max-width: 900px;
	margin:-140px auto 100px auto;
	background: #fff;
	width: 100%;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	padding:30px;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	background: inherit;
}

#productContent.product-3 .product-details-title h1 {
	font-size: 45px;
}

#productContent.product-3 .product-details-title {
	-webkit-order:1;
	        order:1;
	width: calc(100% - 350px);
}
}

#productContent.product-3 .price-wrapper {
	-webkit-order:2;
	        order:2;
	width: 100%;
	border-bottom: none;
	padding:0;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}
#productContent.product-1 .price-wrapper,
#productContent.product-2 .price-wrapper,
#productContent.product-4 .price-wrapper {
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
}

#productContent.product-1 .price-wrapper .cart-button,
#productContent.product-2 .price-wrapper .cart-button,
#productContent.product-4 .price-wrapper .cart-button {
	margin-top: 15px;
}
#productContent.product-3 .product-options {
	-webkit-order:3;
	        order:3;
	width: 100%;
}

#productContent.product-3 .product-description {
	-webkit-order:4;
	        order:4;
	width: 100%;
	max-width: 700px;
	margin:50px auto;
}
.edit-body-mode .product-image-wrapper {
	height: calc(100vh - 120px);
}
.product-image-wrapper .product-image-thumbs {
	position: absolute;
	left: 10px;
	bottom: 0px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	width: 60px;
	height: calc(100% - 0px);
	-webkit-justify-content: center;
	        justify-content: center;
}
.product-image-wrapper .product-image-thumbs img,
.product-image-wrapper .product-image-thumbs img.active {
	width: 60px;
	height: 60px;
	transition: all 0.2s;
	object-fit: cover;
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
	border-radius: 4px;
	margin-bottom: 5px;
	border:solid 2px #fff;
	cursor: pointer;
	position: relative;
	opacity:1;
	-webkit-transform: scale(0.9,0.9);
	        transform: scale(0.9,0.9);
}

.product-image-wrapper .product-image-thumbs img:hover,
.product-image-wrapper .product-image-thumbs img.active  {
	-webkit-transform: none;
	        transform: none;
}
.product-image-wrapper:hover .change-block-image-btn {
	opacity: 1;
	visibility: visible;
}
.product-image-wrapper {
	width: 50%;
	position: relative;
    /*padding: 100px 0px 0px 60px;*/
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    left: 0;
    top: 0;
    height: calc(100vh - 80px);
    min-height: 550px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-align-content: center;
            align-content: center;
    padding-top: 0px;
}

.product-image-wrapper img {
	/*max-height: 100%;
    max-width: 100%;*/
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.product-image-wrapper img.active {
	display: block;
}

.product-details-wrapper {
	width: 50%;
	padding:80px;
}
.price-wrapper {
	width: 100%;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: center;
	        align-items: center;
	font-size: 35px;
	position: relative;
	display: -webkit-flex;
	display: flex;
	padding:20px 0;
	/*border-bottom: solid 1px #efefef;*/
	margin-bottom: 20px;
}
.cart-button {
	background: #63CAA1;
	height: 60px;
	border-radius: 8px;
	padding:15px 30px;
	box-shadow: inset 0 -2px 0 rgba(0,0,0,0.2);
	color: #fff;
	font-size: 14px;
	position: relative;
	font-weight: bold;
	text-transform: uppercase;
	/*margin-left: 40px;*/
	cursor: pointer;
	min-width: 161px;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	transition: all 0.2s;
	-webkit-flex-shrink: 0;
	        flex-shrink: 0; 
	-webkit-justify-content: center; 
	        justify-content: center;
}

.cart-button.sold-out-button {
	background: #ccc;
	color: #333;
	opacity: 0.4;
	box-shadow: none;
	cursor: not-allowed;
}

.cart-button .cart-icon {
	width: 20px;
	margin-right: 10px;
	-webkit-filter: invert(100%);
	        filter: invert(100%);
	margin-left: -10px;
	transition: all 0.2s;
}
.cart-button:hover .cart-icon {
	-webkit-transform: scale(1.15,1.15);
	        transform: scale(1.15,1.15);
}
.cart-button:hover {
	-webkit-filter: grayscale(0.3);
	        filter: grayscale(0.3);
}
#productContent .product-details-title h1 {
	margin:0;
	padding:0;
}

#productContent .product-details-title h2 {
	margin:0;
}

#productContent .product-details-title h3 {
	margin:0;
}

#productContent .product-details-title h4 {
	margin:0;
}

.product-options {
	width: 100%;
	position: relative;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	margin: 20px 0;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.product-options .rc-input-number {
	height: 52px;
	width: 73px!important;
	-webkit-flex-shrink: 0;
	        flex-shrink: 0;
}
.product-options .rc-input-number-handler-wrap {
	position: absolute;
	height: 100%;
	width: 73px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.product-options .rc-input-number-handler {
	width: 20px;
	height: 100%;
	cursor: pointer;
	position: relative;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	border-left: solid 1px #efefef;
}

.product-options .rc-input-number-handler div {
	width: 7px;
	height: 7px;
	border-bottom: solid 1px #333;
	border-right: solid 1px #333;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
}
.product-options .rc-input-number-handler.rc-input-number-handler-up div {
	border-bottom: none;
	border-right: none;
	border-top: solid 1px #333;
	border-left: solid 1px #333;
	margin-top: 7px;
}

.product-options .rc-input-number-handler.rc-input-number-handler-up {
	border-right: solid 1px #efefef;
	border-left: none;
}

.product-options .rc-input-number-input-wrap {
	height: 100%;
}
.product-options .rc-input-number-input-wrap input {
	height: 100%;
	text-align: center;
	padding: 0;
	width: 100%;
}
.flex-container .product-description h2 {
	font-size: 27px;
	font-weight: 600;
	margin:15px 0 5px 0;
}

.flex-container .product-description li {
	margin-bottom: 15px;
}

#productCategoryContent {
	-webkit-flex-direction: column;
	        flex-direction: column;
}

#cartModal h1 {
	font-size: 25px;
	font-weight: 200;
	padding-bottom: 20px;
}

#cartModal h4 i {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
}
#cartModal h4 p {
	font-weight: normal;
    font-size: 12px;
    margin: 6px 0;
    padding: 3px 6px;
    border-radius: 4px;
    border: solid 1px #efefef;
    display: inline-block;
}

.cart-item {
	display: -webkit-flex;
	display: flex;
	position: relative;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: center;
	        justify-content: center;
	padding:20px 0;
	border-bottom: solid 1px #efefef;
	-webkit-align-items: center;
	        align-items: center;
	cursor: pointer;
	transition: all 0.4s ease;
}

.cart-item h4 i {
	font-style: normal;
	font-weight: 400;
}

.cart-item:before {
	height: 100%;
	width: 5px;
	background: #63CAA1;
	left: -50px;
	top: 0;
	position: absolute;
	content: " ";
	transition: all 0.2s;
}
.cart-item:hover:before {
	width: 10px;
}

.cart-item .remove {
	position: absolute;
	background: #63CAA1;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	box-shadow: 0 5px 10px rgba(0,0,0,0.1);
	top: 10px;
	right: 0px;
	color: #fff;
	text-align: center;
	line-height: 17px;
	font-size: 12px;
}
.cart-modal-close-overlay {
	background: rgba(0,0,0,0.3);
	width: 100vw;
	height: 100vh;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
	-webkit-animation:fadeIn 0.3s forwards;
	        animation:fadeIn 0.3s forwards;
}
#cartModal.modal .modal-content {
	padding:0px;
	-webkit-animation: cartIn .5s forwards;
	        animation: cartIn .5s forwards;
	height: 100%;
	right: 0;
	top: 0;
	border-radius: 0;
}
.cart-total-flex,
.saved-amount-row {
	width:100%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
}
.cart-checkout-button {
	width: 100%;
	text-align: center;
	padding:18px 36px;
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	border-radius: 6px;
	box-shadow: inset 0 0 5px rgba(0,0,0,0.1);
	margin-top: 20px;
	cursor: pointer;
	font-size: 18px;
	transition:0.2s;
}

.cart-checkout-button:hover {
	opacity: 0.9;
	box-shadow: 0 6px 18px rgba(0,0,0,0.2);
}
.cart-modal-header {
	padding:20px;
	background: #fff;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 2;
	width: 100%;
	box-shadow: 0 6px 20px rgba(0,0,0,0.03);
}
#cartModal .cart-modal-header h1 {
	width: 100%;
    margin: 0;
    padding: 10px;
    font-size: 21px;
}
.cart-modal-scroll {
	padding:30px 30px 30px 20px;
	background: #fbfbfb;
	width: 100%;
}
.empty-cart-placeholder {
	width: 100%;
	min-height: 180px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	opacity: 0.5;
	-webkit-flex-direction: column;
	        flex-direction: column;
}
.empty-cart-placeholder img {
	width: 90px;
	height: 90px;
	object-fit: contain;
}

.empty-cart-placeholder h4 {
	margin:0;
}

.cart-modal-footer {
	width: 100%;
	background: #fff;
	position: -webkit-sticky;
	position: sticky;
	bottom: 0;
	z-index: 2;
	padding:20px 30px;
	box-shadow: 0 -6px 20px rgba(0,0,0,0.03);
}
.cart-product-price i {
	font-style: normal;
}
.cart-product-option {
	position: absolute;
	bottom: 20px;
}
.cart-product-footer {
	width: calc(100% - 128px);
	padding-right: 20px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	font-size: 12px;
	opacity: 0.4;
	position: absolute;
	bottom: 16px;
	right: 0;
}
.cart-product {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	border-radius: 6px;
	background: #fff;
	padding:8px;
	margin-bottom: 15px;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	position: relative;
	box-shadow: 0 6px 20px rgba(0,0,0,0.03);
}
.cart-product:last-of-type {
	margin-bottom: 0;
}
.cart-product-old-price {
	text-decoration: line-through;
	color: red;
	margin-left: 8px;
}
.cart-product-price {
	font-size: 14px;
}
.cart-product-right {
	position: relative;
	width: calc(100% - 120px);
	margin-top: 10px;
}

.cart-product-remove {
	position: absolute;
    top: 9px;
    right: 9px;
    font-size: 10px;
    opacity: 0.5;
    cursor: pointer;
}
.cart-product-remove:hover {
	opacity: 1;
}
.cart-product img {
	width: 100px;
	height: 100px;
	border-radius: 4px;
	object-fit: cover;
	margin-right: 20px;
}
.cart-product h4 {
	font-weight: 600;
	margin:0;
	font-size: 18px;
	width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}
#orderDetail.modal.side .modal-content {
	background: #fff;
	color: #333;
	opacity: 0;
	-webkit-animation: cartIn .5s forwards;
	        animation: cartIn .5s forwards;
}

#orderDetail.modal.side .modal-content .close {
	-webkit-filter: invert(1);
	        filter: invert(1);
}

.stripe-secure-payment {
	width: 100%;
	margin-top: 30px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
}
.stripe-secure-payment img {
	max-width: 360px;
}

#checkoutModal.modal.side .modal-content {
	max-width: none;
	max-width: initial;
	width: 100vw;
	background: #fafafa;
	opacity: 0;
	-webkit-animation: fadeIn .5s forwards;
	        animation: fadeIn .5s forwards;
	color: #333;
	padding:0;
	left: 0;
	top: 0;
	border-radius: 0;
	height: 100%;
	-webkit-justify-content: center;
	        justify-content: center;
}

#cartModal #closeModal:before,
#cartModal #closeModal:after {
	background: #333;
}

@-webkit-keyframes cartIn {
	0% {opacity: 0; -webkit-transform: translateX(200px); transform: translateX(200px);}
	100% {opacity: 1; -webkit-transform: translateX(0px); transform: translateX(0px);}
}

@keyframes cartIn {
	0% {opacity: 0; -webkit-transform: translateX(200px); transform: translateX(200px);}
	100% {opacity: 1; -webkit-transform: translateX(0px); transform: translateX(0px);}
}

#cartModal .cart-item {
	opacity: 0;
	-webkit-transform: translateY(20px);
	        transform: translateY(20px);
	border:solid 1px #efefef;
	cursor: default;
}

#cartModal .cart-item:hover {
	-webkit-transform: scale(1.03,1.03);
	        transform: scale(1.03,1.03);
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
}

#cartModal.active .cart-item {
	opacity: 1;
    transition-delay: 0.3s;
    -webkit-transform: none;
            transform: none;
    width: 100%;
    border-radius: 6px;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    box-shadow: 0 6px 12px rgba(0,0,0,0.05);
    padding: 0;
    overflow: hidden;
    margin-bottom: 10px;
}

.cart-item h4 {
	font-size: 16px;
	margin:10px 0;
}

.cart-item h4 strong {
	font-weight: normal;
}

#cartModal.active .cart-item img {
	border-radius: 3px;
	margin:0 14px 0 7px;
	width: 80px;
	height: 80px;
	object-fit: cover;
}

#cartModal.active .cart-item .remove {
	opacity: 0;
	-webkit-transform: scale(0.8,0.8);
	        transform: scale(0.8,0.8);
	transition: 0.2s all;
}

#cartModal.active .cart-item:hover .remove {
	opacity: 1;
	-webkit-transform: scale(1,1);
	        transform: scale(1,1);
}

#removeCategory {
	opacity: 0;
	visibility: hidden;
	transition: all 0.2s;
}

#categoryTable  {
	box-shadow: none;
	background: transparent;
}
#categoryTable .table-row {
	background: #fff;
	border:solid 1px #efefef;
	margin-bottom: 10px;
}
#categoryTable .table-row:hover {
	-webkit-transform: none;
	        transform: none;
}
#categoryTable .table-row:hover #removeCategory {
	opacity: 1;
	visibility: visible;
}
#removeCategory span {
	padding:5px 10px;
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	color: #fff;
	border-radius: 20px;
}

.cart-item span {
	padding:0 20px;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-content: center;
	        align-content: center;
	height: 100%;
}

.cart-item img {
	width: 80px;
	transition: all 0.3s ease;
}

.cart-item:hover img {
}
.checkout-button {
	background: #63CAA1;
	box-shadow: 0 10px 15px rgba(0,0,0,0.1), 0 0 0 0 rgba(0,0,0,0);
	border-radius: 40px;
	padding:20px 40px;
	color: #fff;
	font-size: 14px;
	font-weight: bold;
	text-transform: uppercase;
	cursor: pointer;
	margin-top: 30px;
	transition: all 0.2s;
	min-width: 161px;
}
.checkout-button:hover {
	box-shadow: 0 10px 15px rgba(0,0,0,0.1), 0 0 0 10px rgba(0,0,0,0.05);
	-webkit-transform: translateY(-3px);
	        transform: translateY(-3px);
}
.total-amount {
	width: 100%;
	text-align: center;
	font-size: 25px;
	font-weight: bold;
	left: 0;
}
.total-amount span {
	display: block;
	font-weight: normal;
	font-size: 14px;
	opacity: 0.8;
}

.saved-amount-row {
	font-size: 17px;
    font-weight: normal;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: solid 1px #efefef;
}

.saved-amount-row strong {
	font-weight: 600;
	color: #4ac94a;
}


#cartContent {
	-webkit-flex-direction: row;
	        flex-direction: row;
	position: relative;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
}

.checkout-cart h1 {
	font-size: 19px;
	margin: 30px 0 20px 0!important;
	font-weight: bold;
}

.checkout-cart .cart-item {
	border-radius: 5px;
	background: #fff;
	border:solid 1px #efefef;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	margin-bottom: 10px;
	padding:0px ;
	box-shadow: 0 5px 25px rgba(0,0,0,0.05);
}

.shipping-well {
	border: solid 1px #efefef;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: normal;
    box-shadow: 0 4px 8px rgba(0,0,0,0.05);
    margin-top: 20px;
}

.shipping-well h4,
.shipping-well p {
	margin:0;
}
.shipping-well h4 {
	margin-bottom: 5px;
}
.checkout-cart .cart-item h4 strong {
	font-size: 16px;
	font-weight: normal;
	opacity: 0.5;
	padding-left: 6px;
	margin-left: 6px;
	border-left: solid 1px #efefef;
}
.checkout-cart .cart-totals-table .row {
	font-size: 17px;
}

.checkout-cart .cart-item img {
	width: 120px;
	height: 120px;
	object-fit: cover;
}
.checkout-cart .cart-item:before {
	display: none;
}
.checkout-cart .cart-item span {
	width: 60px;
}

.checkout-cart .cart-item .remove {
	top: -5px;
	right: -5px;
}


.checkout-cart {
	width: 50%;
	max-width: 700px;
	padding:60px;
	height: 100vh;
	overflow: auto;
	background: #fafafa;
	right: 0;
	bottom: 0;
}
.cart-totals-table {
	display: -webkit-flex;
	display: flex;
	padding: 10px 20px;
	-webkit-flex-direction: column;
	        flex-direction: column;
}
.cart-totals-table .row {
	font-size: 14px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	margin:3px 0;
}

.cart-totals-table .row.large {
	font-size: 24px;
	border-top: solid 1px #efefef;
	padding-top: 20px;
	margin-top: 20px;
}
.cart-totals-table .row.large .amount {
	font-weight: bold;
}


.checkout-form {
	width: 50%;
	background: #fff;
	height: 100vh;
	padding: 60px 100px;
	overflow: auto;
	display: none;
	opacity: 0;
	-webkit-animation: fadeIn 1s forwards;
	        animation: fadeIn 1s forwards;
	-webkit-transform: translateX(0px);
	        transform: translateX(0px);
	transition: all 0.4s ease;
	max-width: 700px
}

.checkout-form.active {
	display: block;
	opacity: 1;
}

@-webkit-keyframes fadeInCheckout {
	0% {opacity: 0; -webkit-transform:translateX(100px); transform:translateX(100px);}
	100% {opacity: 1; -webkit-transform:translateY(0px); transform:translateY(0px);}
}

@keyframes fadeInCheckout {
	0% {opacity: 0; -webkit-transform:translateX(100px); transform:translateX(100px);}
	100% {opacity: 1; -webkit-transform:translateY(0px); transform:translateY(0px);}
}

.checkout-form h2 {
	font-size: 19px;
	font-weight: bold;
	margin: 30px 0 10px 0;
}
.checkout-form h2.separator {
	margin-top: 50px;
}
.checkout-terms {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	font-size: 14px;
	width: 100%!important;
	margin-top: 10px;
}
.checkout-terms a {
	text-decoration: underline;
}
.checkout-terms div {
	width: 100%!important;
	margin-left: 5px;
}
.checkout-form-group {
	width: 100%;
	margin-bottom: 10px;
	position: relative;
}
.checkout-form-group .edit-checkout-form-group {
	width: 30px;
    height: 30px;
    color: #fff;
    font-size: 12px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 6px;
    top: 10px;
    position: absolute;
    z-index: 2;
    background: #262E47;
    right: 10px;
    transition:0.1s;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
}

.checkout-form-group .edit-checkout-form-group:hover {
	background: #2f3d67;
}
.checkout-form-group:hover .edit-checkout-form-group {
	opacity: 1;
	visibility: visible;
}
.checkout-form-group .dd-group {
	width:calc(50% - 2px);
}
.checkout-form-group .dd-group div,
.checkout-form-group .dd-group div select {
	width: 100%;
}
.checkout-form-group.row, 
.checkout-form-group.credit-card-name,
.checkout-form-group.credit-card-number {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.checkout-form-group.credit-card-name input:first-child {
	width: calc(70% - 5px);
}	
.checkout-form-group.credit-card-name input:last-child {
	width: calc(30% - 5px);
}	

.checkout-form-group.credit-card-number input {
	width: calc(25% - 5px);
}

.checkout-form-group.row input {
	width: calc(50% - 5px);
}

.checkout-form-group.row select {
	width: calc(100% - 10px);
	background: transparent;
}

.checkout-form-group select {
	height: 45px;
}
.checkout-form-group input,
.checkout-form-group select,
.checkout-form-group textarea {
	border: 1px #d9d9d9 solid;
    background-clip: padding-box;
    border-radius: 5px;
    display: block;
    outline: none!important;
    width: 100%;
    padding: 0.92857em 0.78571em;
    word-break: normal;
    font-size: 14px;
	height: 45px;
	font-family: inherit;
}
.checkout-form-group textarea {
	height: 100px;
}
.checkout-form-group input:focus,
.checkout-form-group textarea:focus {
	box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}
.checkout-form .checkmark {
	top: 15px;
	left: 15px;
}
 .checkout-form .checkmark:after {
 	display: none!important;
 }
.checkout-form .cart-button {
	float: right;
	padding:20px 30px;
	min-width: auto;
	margin:0;
	-webkit-justify-content: center;
	        justify-content: center;
}
.checkout-form .cart-button {
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
}
.checkout-form .cart-button.paypal {
	background: #003087;
}

.checkout-form .cart-button.paypal img {
	width: 60%;
	margin:auto;
	max-width: 65px;
}
.checkout-form .button-set {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.checkout-form .button-set .cart-button {
	width: calc(50% - 10px);
	text-align: center;
}

.checkout-form .button-set div:not(.cart-button) {
	width: calc(50% - 10px)
}
.checkout-form .button-set div .cart-button {
	width: 100%;
}

.checkout-form .cart-button.empty {
	float: left;
	background: transparent;
	box-shadow: none;
	color: #000;
	margin-left: 0;
	padding-left: 0;
}

.radio-button-container {
	padding:0px;
	border: 1px #d9d9d9 solid;
	border-radius: 6px;
}
.radio-button {
  display: block;
  position: relative;
  cursor: pointer;
 height: 45px;
  font-size: 16px;
      padding: 12px 40px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-bottom: 1px #d9d9d9 solid;
}

/* Hide the browser's default radio button */
.radio-button input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.radio-button:last-of-type {
	border-bottom: none;
}
/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 12px;
  left: 10px;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}
.radio-button .add-price {
	float: right;
	font-size: 14px;
	opacity: 0.5;
	margin-right: -10px;
}

/* On mouse-over, add a grey background color */
.radio-button:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio-button input:checked ~ .checkmark {
  background-color: #63CAA1;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-button input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-button .checkmark:after {
 	top: 6px;
	left: 6px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}


.summary-table {
	width: 100%;
	margin-bottom: 50px;
	border-radius: 5px;
	box-shadow: 0 8px 16px rgba(0,0,0,0.05);
	background-color: #fff;
	border:solid 1px #efefef;
}

.summary-table .row {
	display: -webkit-flex;
	display: flex;
	padding:10px;
	border-bottom: solid 1px #efefef;
	-webkit-flex-direction: row;
	        flex-direction: row;
	font-size: 14px;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
}

.summary-table .row a {
	color: #63CAA1;
	width: 50px;
}
.summary-table .row div {
	width: calc(100% - 130px);
}
.summary-table .row span {
	font-size: 14px;
	font-weight: bold;
	width: 80px;
}





@media screen and (max-width: 992px) {
 .product-grid-item {
 	width: 33.333%;
 }
}

@media screen and (max-width: 600px) {
 .product-grid-item {
 	width: 50%;
 }


}

#manageStoreContent {
	height: 100vh;
	padding-top: 0;
}

.manage-store-navbar {
	width: 250px;
	border-right: 1px solid #efefef;
	padding: 0 40px;
	background: linear-gradient(-45deg, #3c4665 0%, #21283F 100%);
	color: #fff;
	height: calc(100vh - 40px);
	margin-top: 20px;
	margin-left: 20px;
	padding-top: 36px;
	box-shadow: 0 14px 28px rgba(0,0,0,0.05), 0 10px 10px rgba(0,0,0,0.1);
	border-radius: 8px;
	position: relative;

}

.manage-store-navbar .clickai-logo {
	width: 60px;
	position: absolute;
	bottom: 20px;
	left: 40px;
}
.manage-store-navbar .clickai-logo img {
	width: 100%;
}

.manage-store-navbar ul {
	margin:50px 0 0 0;
	padding:0;
}

.manage-store-navbar ul li {
	list-style-type: none;
	margin:0;
	float: left;
	padding:0;
	width: 100%;
}

.manage-store-navbar ul li a {
	padding:10px 0px 10px 0px;
	color: #fff;
	float: left;
	opacity: 0.4;
	width: 100%;
	position: relative;
}
.manage-store-navbar ul li a:hover,
.manage-store-navbar ul li a.active {
	opacity: 1;
}


.manage-store-navbar ul li a.active:after {
  width: 0; 
  height: 0; 
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #323a53;
  content: " ";
  position: absolute;
  right: -48px;
  top: 10px;
}
.manage-store-navbar ul li a:hover img {
	-webkit-transform:translateY(3px) scale(1.15,1.15);
	        transform:translateY(3px) scale(1.15,1.15);
}

.manage-store-navbar ul li img {
	height: 15px;
	margin-right: 20px;
	width: 15px;
	-webkit-transform:translateY(3px);
	        transform:translateY(3px);
	object-fit: contain;
	transition: all 0.3s;
	-webkit-filter: invert(1);
	        filter: invert(1);
}

.store-info {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-flex-direction: column;
	        flex-direction: column;
}
.flex-container .store-info h4 {
	font-size: 25px;
	margin:0;
}

.store-info h1 {
	font-weight: 400;
	margin:0;
	font-size:24px;
	padding:0;
}

.manage-store-cards {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	margin-bottom: 50px;
	margin-top: -50px;
}

.manage-store-cards div {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	padding: 20px 40px 20px 40px;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	text-align: left;
	font-size: 30px;
	border-radius: 8px;
	width: calc(33.33% - 20px);
	position: relative;
	border:solid 1px #efefef;
	font-weight: 600;
	overflow: hidden;
	box-shadow: 0 8px 16px rgba(0,0,0,0.05);
	background: #fff;
}

.manage-store-cards div .recharts-wrapper {
	/*width: calc(100% + 80px)!important;
	margin-left: -40px!important;*/
}
.manage-store-cards div.pending:before {
	position: absolute;
	top: 0px;
	left: 0px;
	content: " ";
	width: 9px;
	height: 100%;
	background:linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
}

.manage-store-cards div span {
	font-size: 14px;
	text-transform: uppercase;
	margin-top: 0px;
	opacity: 0.6;
	font-weight: normal;
}

.manage-store-title {
	width: 100%;
	position: relative;
	margin-bottom: 50px;
}

.manage-store-title h1 {
	font-size: 35px!important;
	font-weight: 700;
	margin:0 0 0px 0!important;
	line-height: normal;
}

.manage-store-title p {
	margin:0;
	opacity: 0.7;
	font-size: 16px;
}

.manage-store-subtitle  {
	margin:0;
	padding:0;
	width: 100%;
}

.payment-options-info {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}
.payment-options-info div {
	width: 50%;
	margin-bottom: 20px;
}

.payment-options-info div.credit-cards-options {
	width: 100%;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	display: -webkit-flex;
	display: flex;
}
.payment-options-info div.credit-cards-options img {
	width: 45px;
	height: 30px;
	border-radius: 6px;
	margin-right: 5px;
	margin-top: 5px;
	border:solid 1px #efefef;
}
.payment-options-info div h4,
.payment-options-info div p {
	margin:0;
}
.payment-options-info div h4 {
	font-size: 19px;
	width: 100%;
}
.payment-options-info div p {
	opacity: 0.6;
	font-weight: normal;
	font-size: 17px;
}

.currency-dropdown.input-group {
	margin-top: 0;
	margin-bottom: 20px;
}
.currency-dropdown.input-group select,
.currency-dropdown.input-group select:focus {
	background: #fff;
	transition: 0.2s;
	box-shadow: 0 6px 12px rgba(0,0,0,0.05);
	cursor: pointer;
	background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat #fff;
    background-position: right 10px top!important; /* Change the px value here to change the distance */ 
    -webkit-appearance: none; 
    appearance: none;
    font-size: 18px;
    font-weight: 600;
}
.currency-dropdown.input-group select:hover {
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
}


.payment-option-accordion  .p-o-content {
	border-top-color: #fff;
}
.p-o-footer {
	width: calc(100% + 60px);
	margin-left: -30px;
	background: #fff;
	border-top:dashed 1px #efefef;
	padding:20px;
}
.p-o-footer .connect-button {
	margin-right: 10px;
}
.p-o-footer .connect-button.empty {
	background: #fff;
	border:solid 1px #efefef;
	color: #333;
}
.p-o-footer .connect-button.empty:hover {
	background: #efefef;
}
.payment-option-accordion.active  .p-o-content {
	padding:30px 30px 0 30px;
	background: #fafafa;
	border-top: dashed 1px #efefef;
}
#productList,
#productEdit {
	display: none;
}
#productList.active,
#productEdit.active {
	display: block;
}
.add-product-button {
	position: absolute;
	right: 0;
	top: 0;
	padding:15px 20px;
	box-shadow: 0 5px 15px rgba(0,0,0,0.1);
	border-radius: 40px;
	color: #fff;
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	text-transform: uppercase;
	font-size: 14px;
	cursor: pointer;
	font-weight: 600;
}

.add-product-button.empty {
	color: #333;
	border:solid 1px #efefef;
	background: #fff;
}
.add-product-button.empty:hover {
	background: #efefef;
}
.add-product-button.empty:before {
	display: none;
}
.add-product-button:hover {
	background: #28C76F;
}

.add-product-button:before {
	width: 15px;
	height: 15px;
	content: "+";
	line-height: 15px;
	border: solid 1px #fff;
	border-radius: 50%;
	display: inline-block;
	text-align: center;
	margin-right: 10px;
}

.add-option-button:hover {
	border-color: #000;
}
.add-option-button {
	width: auto;
	display: inline-block;
	padding:15px 20px;
	box-shadow: 0 5px 15px rgba(0,0,0,0.1);
	border-radius: 40px;
	color: #fff;
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	font-size: 14px;
	font-weight: 600;
	cursor: pointer;
	transition: all 0.2s;
}

.add-option-button:before {
	width: 15px;
	height: 15px;
	content: "+";
	line-height: 15px;
	border: solid 1px #fff;
	border-radius: 50%;
	display: inline-block;
	text-align: center;
	margin-right: 10px;
}

.section-actions {
	position: fixed;
	bottom: 0;
	right: 0;
	padding:30px;
	display: -webkit-flex;
	display: flex;
}


.section-actions .button {
	width: 150px;
	text-align: center;
	padding:15px 20px;
	
	border-radius: 40px;
	color: #000;
	background: #fff;
	border: solid 1px #efefef;
	text-transform: uppercase;
	font-size: 14px;
	margin-left: 20px;
	font-weight: 600;
}

.section-actions .button.save-section {
	background:  #63CAA1;
	color: #fff;
	box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.store-info p, .section-title p {
	font-size: 13px;
	opacity: 0.4;
	font-weight: 400;
	margin:0;
}

.manage-store-sections {
	width: 100%;
	padding:100px 20px 60px 20px;
	overflow:auto;
	min-height: 600px;
}

.manage-store-sections .section {
	width: 100%;
	max-width: 768px;
	display: block;
	-webkit-flex-direction: column;
	        flex-direction: column;
	margin:0 auto;
	-webkit-animation:sectionIn 0.8s ease forwards;
	        animation:sectionIn 0.8s ease forwards;
}

@-webkit-keyframes sectionIn {
	from {opacity: 0; margin-top: 20px;}
	to {opacity: 1; margin-top: 0}
}

@keyframes sectionIn {
	from {opacity: 0; margin-top: 20px;}
	to {opacity: 1; margin-top: 0}
}
.section input, .section textarea {
	    border: 1px #d9d9d9 solid;
    background-clip: padding-box;
    border-radius: 5px;
    display: block;
    outline: none!important;
    width: 100%;
    padding: 0.92857em 0.78571em;
    word-break: normal;
    font-size: 14px;
}
.section textarea {
	height: 210px;
}
.product-input-group {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
}
.product-input-wrapper {
	margin-bottom: 20px;
}

.product-input-wrapper input {
	width: 100%;
}

.product-input-group .product-input-wrapper {
	margin-right: 20px;
}

.section label {
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 500;
	margin-bottom: 5px;
}
.section .product-title input,
.section .product-price-wrapper input {
	font-size: 18px;
}
.manage-store-sections .section.active {
	display: block;
}

.cart-item-trigger {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 10;
	cursor: pointer;
}

.manage-store-sections .section .cart-item {
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	overflow: visible;
}
.manage-store-sections .section .cart-item:before {
	display: none;
}
.manage-store-sections .section .cart-item img {
	min-width: 160px;
	width: 160px;
	height: 160px;
	border-radius: 6px 0 0 6px;
	object-fit: cover;
	margin-right: 40px;
}
.manage-store-sections .section .cart-item .card-dropdown {
	right: 210px;
	z-index: 20;
}
.action-buttons  {
	margin-left: auto;
	-webkit-align-self: center;
	        align-self: center;
	-webkit-transform: scale(0.9,0.9);
	        transform: scale(0.9,0.9);
	opacity: 0;
	transition:0.2s ease all;
}
.manage-store-sections .section .cart-item:hover .action-buttons {
	opacity: 1;
	-webkit-transform: none;
	        transform: none;
}
.manage-store-sections .section .cart-item .action-buttons {
	position: absolute;
	left: 47px;
}
.placing-order-loader {
	width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
}
.action-buttons .button {
	width: 60px;
	font-size: 12px;
	text-transform: uppercase;
	background:linear-gradient(-45deg, rgb(60, 70, 101) 0%, rgb(33, 40, 63) 100%);
	color: #fff;
	text-align: center;
	font-weight: normal;
	margin:4px 0;
	padding:5px 0;
	border-radius: 4px;
}
.action-buttons .button.delete {
	background: linear-gradient(-135deg, rgb(245, 106, 106) 0%, rgb(199, 40, 40) 100%);
}
.store-info img {
	margin-right: 20px;
	margin-bottom: 15px;
	border-radius: 50%;
	width: 70px;
	height: 70px;
	object-fit: contain;
	background: #fff;
}

.section .two-row {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
}
.section .two-row .product-input-wrapper {
	width: 50%;
}

.section .two-row .product-images {
	width: 50%;
	display: -webkit-flex;
	display: flex;
	padding:0 0 0 20px;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-align-content: flex-start;
	        align-content: flex-start;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
}
.section .two-row .product-images label {
	width: 100%;
}
.section .two-row .product-images .product-image {
	width: calc(33.333% - 5px);
    height: 100px;
    padding: 0px;
    margin-bottom: 10px;
    border: solid 1px #efefef;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}
.section .two-row .product-images .product-image.add-image {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	text-align: center;
	font-weight: 500;
	-webkit-align-content: center;
	        align-content: center;
	-webkit-align-items: center;
	        align-items: center;
	border: 2px dashed #ccc;
}
.section .two-row .product-images .product-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.flex-container h3.manage-store-subtitle {
	font-size: 30px;
}
.flex-table {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-content: flex-start;
	        align-content: flex-start;
	font-size: 14px;
	background:#fff;
	border-radius: 6px;
	box-shadow: 0 6px 16px rgba(0,0,0,0.05);
}
.flex-table .table-row {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	border-bottom: solid 1px #efefef;
	transition: all 0.2s;
	cursor: pointer;
}
.flex-table .table-row.placeholder-row {
	padding:20px;
	cursor: auto;
	cursor: initial;
}
.flex-table .table-row.placeholder-row:hover {
	-webkit-transform: none!important;
	        transform: none!important;
}
.flex-table .table-row.placeholder-row h1 {
	font-size: 25px;
	width: 100%;
	padding:40px;
	text-align: center;
	border:dashed 1px #efefef;
	color: #ccc;
	border-radius: 6px;
}
.flex-table .table-row div {
	padding:15px;
}

.flex-table .table-row .status {
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 600;
}
.flex-table .table-row .status:before {
	width: 8px;
	height: 8px;
	margin-right: 5px;
	border-radius: 50%;
	display: inline-block;
	background: #000;
	content: " ";
}

.flex-table .table-row .status.complete {
	color: #63CAA1;
}

.flex-table .table-row .status.complete:before {
	background: #63CAA1;
}

.flex-table .table-row .status.pending {
	color: #DAA96B;
}

.flex-table .table-row .status.pending:before {
	background: #DAA96B;
	}
.flex-table .table-row .status.canceled {
	color: #ccc;
}

.flex-table .table-row .status.canceled:before {
	background: #ccc;
}

.flex-table .table-row.thead {
	font-weight: bold;
	background: #fafafa;
}

.flex-table .table-row:not(.thead):hover {
	box-shadow:0 10px 15px rgba(0,0,0,0.1);
	background: #fff;
	-webkit-transform: scale(1.02,1.02);
	        transform: scale(1.02,1.02);
}

.flex-table .draggable.placeholder {
	width: 100%;
	height: 77px;
	background: #fafafa;
	border:dashed 1px #efefef;
	border-radius: 8px;
	margin:10px 0;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.flex-table .draggable {
	cursor: -webkit-grab;
	cursor: grab;
}
#categoryTable {
	font-weight: 500;
}
#categoryTable .table-row img {
	width: 15px;
}

#categoryTable .table-row {
	-webkit-align-items: center;
	        align-items: center;
	cursor: pointer;
	border:solid 1px #fafafa;
	box-shadow:0 10px 15px rgba(0,0,0,0.05);
}

#categoryTable .List .table-row  {
	box-shadow:0 10px 15px rgba(0,0,0,0.1);
}

#addCategoryRow {
	display: none;
}

#addCategoryRow.active {
	display: -webkit-flex;
	display: flex;
}

#categoryTable .table-row.add-category {
	border:none;
	margin-bottom: 40px;
	box-shadow: none!important;
	background: transparent;
}
#categoryTable .table-row.add-category  div:nth-child(3) {
	display: none;
}
#categoryTable .table-row.add-category div:nth-child(2) {
	padding:0;
	width: 100%;
	margin-right: 10px;
}
#categoryTable .table-row.add-category .btn {
	margin: 0;
}
#categoryTable .table-row div:nth-child(1) {
	width: 40px;
}

.manage-store-sections #categoryTable .table-row div:nth-child(1) {
	width: 0;
	padding:15px 0;
}

#categoryTable .table-row div:nth-child(2) {
	width: calc(100% - 200px);
}

#categoryTable .table-row div:nth-child(4) {
	width: 40px;
	margin-right: 15px;
}

#categoryTable .table-row input {
	border: none;
	background: #f3f2f2;
	font-size: 15px;
}

#categoryTable .table-row.add-category input {

}
#categoryTable .table-row.add-category div:nth-child(4) {
	width: 54px;
	font-weight: bold;
	border-radius: 50px;
}
#categoryTable .table-row div:nth-child(3) {
	width: 120px;
	font-size: 12px;
	opacity: 0.4;
}
.flex-table.row-3 .table-row div {
	width: 33.333%;

}

.flex-table.row-3 .table-row div:last-child {
	text-align: right;
}
.flex-table.row-5 .table-row div:nth-child(1) {
	width: 20%
}
.flex-table.row-5 .table-row div:nth-child(2) {
	width: 15%
}
.flex-table.row-5 .table-row div:nth-child(4) {
	width:28%
}

.flex-table.row-5 .table-row div.flag {
	width: 40px;
	position: relative;
}

.flex-table.row-5 .table-row div.flag .tooltip {
	position: absolute;
	width: 200px;
	padding:10px;
	font-size: 14px;
	font-weight: normal;
	color: #fff;
	background: rgba(0,0,0,0.9);
	border-radius: 10px;
	bottom: 32px;
	left: calc(50% - 100px);
	visibility: hidden;
	opacity: 0;
	-webkit-transform:translateY(-5px);
	        transform:translateY(-5px);
	transition: 0.2s;
	z-index: 20000;
}
.flex-table.row-5 .table-row div.flag:hover .tooltip {
	opacity: 1;
	visibility: visible;
	-webkit-transform: none;
	        transform: none;
}
.flex-table.row-5 .table-row div.flag img {
	width: 12px;
	height: 12px;
}
.flex-table.row-5 .table-row div:nth-child(5) {
	font-size: 15px;
	width: 15%;
	font-weight: bold;
}
.flex-table.row-5 .table-row div:nth-child(6) {
	width: 15%
}


.payment-option-accordion {
	width: 100%;
	background: #fff;
	border-radius: 6px;
	box-shadow: 0 8px 16px rgba(0,0,0,0.05);
	margin-bottom: 10px;
	transition: all 0.2s;
	border:solid 1px #efefef;
}
.flex-container .payment-option-accordion h3 {
	font-size: 23px;
	margin:0;
}
.payment-option-accordion:hover {
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}
.connect-button {
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	color: #fff;
	border-radius: 10px;
	padding:10px 20px;
	text-transform: uppercase;
	font-weight: 400;
	font-size: 14px;
	text-align: center;
	transition:all 0.3s;
	display: inline-block;
	cursor: pointer;
	width: auto!important;
}

.connect-button:hover {
	background: linear-gradient(-135deg, #28C76F 0%, #28C76F 100%);
}
.p-o-content .connect-button {
	width: 114px;
}
.p-o-content {
	padding:0 30px 0px 30px;
	height: 0;
	min-height: 0;
	transition: all 0.4s;
	overflow:hidden;
}
.active .p-o-content {
	padding:0 30px 30px 30px;
	height: auto;
	min-height: 90px;
}
.p-o-content a {
	color: #9ea9c2;
}
.p-o-title {
	margin:0;
	padding:0;
	position: relative;
}
.p-o-title {
	padding:20px 20px 20px 30px;
	height: 80px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	cursor: pointer;
}
.p-o-title .left {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: center;
	        align-items: center;
}

.p-o-title .option-switch {
	width: 50px;
	height: 25px;
	border-radius: 50px;
	background: #efefef;
	margin-right: 10px;
	position: relative;
	border:solid 1px #efefef;
	transition: all 0.2s;
}
.p-o-title .option-switch:before {
	width: calc(25px - 2px);
	height: calc(25px - 2px);
	border-radius: 50%;
	background: #fff;
	position: absolute;
	left: 0;
	top: 0;
	content: " ";
	transition: all 0.2s;
}
.active .p-o-title .option-switch {
 	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
 }
.active .p-o-title .option-switch:before {
	left: calc(100% - 23px);
}

.p-o-title span {
	font-size: 13px;
	text-transform: uppercase;
	color: #ccc;
}
.p-o-title span:before {
	content: " ";
	width: 8px;
	height: 8px;
	display: inline-block;
	margin-right: 5px;
	border-radius: 50%;
	background: #ccc;
}

.p-o-title span.enabled:before {
	background: #489742;
}
.p-o-title span.enabled {
	color: #489742;
}
.p-o-title img {
	height: 25px;
}

.shipping-table {
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	    display: -webkit-flex;
	    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
}

.shipping-table .row {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	margin-bottom: 10px;
	position: relative;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	 padding: 20px;
    box-shadow: 0 8px 16px rgba(0,0,0,0.05);
    background: #fff;
    border-radius: 6px;
    margin-bottom: 15px;
}
.shipping-table .row span {
	position: absolute!important;
	top: 15px!important;
	right: 20px!important;
}

.shipping-table .row .add-shipping-button {
	border:transparent;
	box-shadow: none;
	padding:0;
	opacity: 0.5;
	margin-left: 10px;
}
.shipping-table .row .add-shipping-button:hover {
	opacity: 1;
}
.shipping-table .row .col {
	width: calc(33.333% - 20px);
	position: relative;
	margin-bottom: 15px;
}

.shipping-table .row .col strong {
	margin-bottom: 5px;
	display: block;
}

.shipping-table .row .col input,
.shipping-table .row .col select {
	width: 100%;
	height: 50px;
	outline: none;
	border-radius: 4px;
	border:1px #d9d9d9 solid;
	transition: all 0.2s;
	padding:0 20px;
}

.shipping-table .row .col input:focus,
.shipping-table .row .col select:focus {
	border-color: #ccc;

}

.shipping-table .row .col.countries {
	width: 100%;
}
.shipping-table .row .col.currency-input {
	position: relative;
	width: calc(50% - 10px);
}
.currency-input input,
.shipping-table .row .col.currency-input input {
	padding-left: 52px;
}

.currency-input i {
	font-style: normal;
	font-size: 11px;
	text-transform: uppercase;
	padding:3px 6px;
	border-radius: 4px;
	border:solid 1px #ccc;
	position: absolute;
	margin-left: 10px;
	bottom: 14px;
}

.delete-shipping-btn {
	width: 40px;
	height: 40px;
	position: absolute;
	right: 5px;
	top: 5px;
	z-index: 2;
	cursor: pointer;
	border-radius: 6px;
	background: #fff;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	border:solid 1px #efefef;
	transition: all 0.2s;
	opacity: 0;
	-webkit-transform: translateX(-3px);
	        transform: translateX(-3px);
}

.col .delete-shipping-btn {
	bottom: 5px;
	top: auto;
	right: 5px;
}

.row:hover .delete-shipping-btn {
	opacity: 1;
	background: #fafafa;
	-webkit-transform: none;
	        transform: none;
}
.delete-shipping-btn img {
	width: 12px;
	height: 12px;
}
.add-shipping-button {
	padding:10px 20px;
	border:solid 1px #efefef;
	border-radius: 30px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	text-transform: uppercase;
	display: inline-block;
	font-size: 14px;
	box-shadow: 0 8px 16px rgba(0,0,0,0.05);
	transition: all 0.2s;
	background: #fff;
	cursor: pointer;
	margin-right: 10px;
}
.add-shipping-button:hover {
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}
.add-shipping-button:before {
	content: "+";
	font-size: 18px;
	width: 25px;
	height: 25px;
	text-align: center;
	border:solid 1px #000;
	border-radius: 50%;
	margin-right: 10px;
	display: inline-block;
	margin-left: -10px;
}
.manage-store-sections hr {
	opacity: 0.2;
	margin:40px 0;
}
#manageCoupons .flex-table .table-row.thead div {
	padding:10px;
}
#manageCoupons .flex-table .table-row div {
	padding:30px 10px;
}


.add-coupon-button {
	padding:15px 20px;
	box-shadow: 0 5px 15px rgba(0,0,0,0.1);
	border-radius: 40px;
	color: #fff;
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	text-transform: uppercase;
	cursor: pointer;
	font-size: 14px;
	font-weight: 600;
	display: inline-block;
	margin-bottom: 20px;
}

.manage-store-title .add-coupon-button {
	position: absolute;
	right: 0;
	top: 0;
}

.add-coupon-button:hover {
	background: #28C76F;
}

.add-coupon-button:before {
	width: 15px;
	height: 15px;
	content: "+";
	line-height: 15px;
	border: solid 1px #fff;
	border-radius: 50%;
	display: inline-block;
	text-align: center;
	margin-right: 10px;
}

.coupon-tabs {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	font-size: 14px;
	text-transform: uppercase;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.coupon-tabs {
	padding:15px 0 0 0;
	color: #ccc;
	font-size: 12px;
	font-weight: bold;
	margin:10px 0 0 0;
}
.coupon-tabs div {
	border-bottom: solid 1px #efefef;
	padding:20px 0;
	width: 33.333%;
	text-align: center;
	cursor: pointer;
}
.coupon-tabs .active,
.coupon-tabs div:hover {
	color: #000;
	border-bottom: solid 1px #000;
}
.coupon-tab-content {
	width: 100%;
	display: none;
}

.coupon-tab-content.active {
	display: block;
}

.option {
  font-size: 14px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  cursor: pointer;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 10px;
}
.option:hover:before, 
.option.active:before{
  background: #7FCA76;
}

.option:before {
    content: " ";
    display: inline-block;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    border:solid 2px #efefef;
    margin-right: 7px;
    font-size: 13px
}
 .option.square:before {
  border-radius: 5px;
}

.coupon-note {
	width: 100%;
	padding:20px;
	font-size: 14px;
	border-radius: 6px;
	background: #ede9c6;
	border:solid 1px #dbd6aa;
	margin:10px 0;
}
.manage-store-sections .cart-item {
	padding:0px 20px 0 0;
	overflow: hidden;
	background: #fff;
	box-shadow: 0 8px 16px rgba(115,135,150,0.1);
	border-bottom: none;
	border-radius: 6px;
	margin-bottom: 10px;
	height: 100%;
	border:solid 1px #efefef;
}

.manage-store-sections .cart-item .product-inventory {
	position: absolute;
	right: 0px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	height: 100%;
	width: 200px;
	border-left:solid 1px #efefef;
}

.manage-store-sections .cart-item .product-inventory div {
	width: 50%;
	 font-size: 12px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -webkit-justify-content: center;
            justify-content: center;
    background: #fafafa;
    transition: 0.2s;
    height: 0%;
    opacity: 0;
}
.manage-store-sections .cart-item .product-inventory div:first-child {
	width: 100%;
	border-bottom:solid 1px #efefef;
	opacity: 1;
	height: 100%;
}

.manage-store-sections .cart-item:hover .product-inventory div:first-child {
	height: 50%;
}
.manage-store-sections .cart-item:hover .product-inventory div {
	height: 50%;
	opacity: 1;
}

.manage-store-sections.cart-item .product-inventory div:last-child {
	border-left:solid 1px #efefef;
}

.cart-item .product-inventory {
	font-weight: normal;
	font-size: 14px;
}

.cart-item .product-inventory strong {
	font-size: 18px;
}

.manage-store-sections .cart-item:hover {
	box-shadow: 0 8px 16px rgba(115,135,150,0.2);
}
.manage-store-sections .cart-item h4 {
	font-size: 21px;
	line-height: 24px;
	margin:0;
}

.manage-store-sections .cart-item h4 p {
	font-weight: normal;
        font-size: 12px;
        margin: 10px 0;
        padding: 3px 6px;
        border-radius: 4px;
        border: solid 1px #efefef;
        margin:5px 4px 0 0px;
}
.manage-store-sections .cart-item h4 i {
	font-size: 18px;
}
.order-summary-content {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}
.order-summary-block.col-50 {
	width: calc(50% - 10px);
}
.order-summary-block {
	width: 100%;
	padding:15px;
	border: solid 1px #efefef;
	margin-bottom: 20px;
	border-radius: 6px;
	font-size: 15px;
	font-weight: 500;
	position: relative;
}
.order-summary-block label {
	font-size: 13px;
	font-weight: bold;
	color: #333;
	margin-right: 10px;
	text-transform: uppercase;
	display: inline-block;
	width: 40px;
}
.order-summary-block h4 {
	margin:0;
	padding:0;
	width: 100%;
	font-weight: 400;
	font-size: 14px!important;
	text-transform: uppercase;
	opacity: 0.4;
	margin-bottom: 4px;
}
.order-item {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: center;
	        align-items: center;
	overflow:hidden;
	border-radius: 6px;
	box-shadow: 0 4px 16px rgba(0,0,0,0.05);
	margin-bottom: 5px;
}
.order-item img {
	width: 80px;
	height: 80px;
	object-fit: cover;
	margin-right: 20px;
}

.order-item sub {
	padding:5px;
	border:solid 1px #efefef;
	font-size: 11px;
	text-transform: uppercase;
	-webkit-transform: none;
	        transform: none;
	margin:3px 0;
	border-radius: 6px;
	display: inline-block;
}

.order-item sub.order-ref {
	opacity: 0;
	transition: 0.2s;
}
.order-item:hover sub.order-ref {
	opacity: 1;
}
.order-item sub i {
	font-size: inherit!important;
}
.order-item h5 {
	font-size: 15px;
	margin:0;
}
.order-item h5 i {
	font-weight: normal;
	font-size: 13px;
	font-style: normal;
	opacity: 0.5;
}

.order-item h5 i.sale-price {
	color: red;
	margin-left: 10px;
	text-decoration: line-through;
}

.order-summary-footer {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	width: 100%;
}

.order-button {
	color: #fff;
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	padding:15px 30px;
	border-radius: 40px;
	font-size: 14px;
	text-transform: uppercase;
	cursor: pointer;
}
.order-button:hover {
	opacity: 0.7;
}

.order-button.empty {
	background: transparent;
	color: red;
}

.order-status-dropdown {
	position: absolute;
	width: 100%;
	left: 0;
	top: 40px;
	z-index: 2;
	background: #fff;
	border-radius: 6px;
	overflow: hidden;
}
.order-status-dropdown:after {
	position: absolute;
	right: 15px;
	top: 16px;
	width: 4px;
	height: 4px;
	border-left: solid 2px #ccc;
	border-bottom: solid 2px #ccc;
	-webkit-transform: rotate(-45deg);
	        transform: rotate(-45deg);
	content: " ";
}

.order-status-dropdown div {
	display: none;
	font-size: 13px;
	width: 100%;
	padding:10px 15px;
	text-transform: uppercase;
	font-weight: bold;
	cursor: pointer;
	background: #fff;
}
.order-status-dropdown:hover div {
	display: block;
}

.order-status-dropdown div:hover {
	background-color: #efefef;
}

.order-status-dropdown:hover div.active {
	background: #fafafa;
}
.order-status-dropdown:hover {
	z-index: 10;
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
}
.order-status-dropdown div:hover {
	box-shadow: 0 4px 8px rgba(0,0,0,0.05);
}
.order-status-dropdown div:before {
	width: 8px;
	height: 8px;
	margin-right: 5px;
	border-radius: 50%;
	background: #ccc;
	content: " ";
	display: inline-block;
}
.order-status-dropdown div.active {
	display: block;
}

.order-status-dropdown div.pending:before {
	background: #DAA96B;
}

.order-status-dropdown div.complete:before {
	background: #63CAA1;
}

.order-status-dropdown div.complete {
	color: #63CAA1;
}
.order-status-dropdown div.pending {
	color: #DAA96B;
}
.order-status-dropdown div.cancel {
	color: #ccc;
}

.flex-table.payouts-table {
	font-size: 16px; 
	font-weight: normal;
	color: #8c8b8b;
	margin-bottom: 80px;
}
.flex-table.payouts-table strong {
	color: #333;
}
.flex-container h4.table-title {
	font-size: 18px;
	text-transform: uppercase;
}
.currency-selector {
	width:100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	margin-bottom: 40px;
}
.currency-selector div {
	text-align: center;
	padding:6px 18px;
	border-radius: 30px;
	border:solid 1px #efefef;
	font-weight: normal;
	background: #fff;
	cursor: pointer;
	margin-right: 7px;
	margin-bottom: 7px;
	color: #333;
}
.currency-selector div:not(.active):hover {
	border-color: #ccc;
}
.currency-selector div.active {
	background: linear-gradient(-45deg, #3c4665 0%, #21283F 100%);
	color: #fff;
}
.payouts-dashboard {
	font-weight: normal;
	display: -webkit-flex;
	display: flex;	
	-webkit-justify-content: space-between;	
	        justify-content: space-between;
	padding:30px;
	font-size: 14px;
	background: #fff;
    border: solid 1px #efefef;
    border-radius: 6px;
    margin: 0px 0 30px 0;
    -webkit-align-items: center;
            align-items: center;
    box-shadow: 0 8px 16px rgba(0,0,0,0.05);
}
.payouts-dashboard .btn {
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	padding:15px 30px;
	font-size: 15px;
    border-radius: 50px;
    margin-bottom: 5px;
}
.payouts-dashboard .payouts-cta {
	text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.payouts-dashboard i {
	font-style: normal;
}
.payouts-dashboard strong {
	font-weight: normal;
	color: #8c8b8b;
}
.payouts-dashboard a {
	color: #28C76F;
	cursor: pointer;
}
.payouts-dashboard a:hover {
	text-decoration: underline;
}
.payouts-dashboard h3 {
	font-size: 24px!important;
	margin:0px 0!important;
}
.manage-store-navbar .burger {
	display: none;
	-webkit-filter: invert(1);
	        filter: invert(1);
}

.edit-product-form {
	max-width: 900px;
	margin:0 auto;
}

.left-cards {
	float: left;
	width: calc(100% - 320px);
}

.right-cards {
	float: right;
	width: 300px
}

.live-layout-dropdown {
	border:solid 1px #efefef;
	border-radius: 6px;
	position: relative;
	width: 100%;
}
.live-layout-dropdown-item {
	padding:10px 10px 10px 10px;
	background: #fff;
	transition: 0.2s;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	height: 100%;
	border-radius: 6px;
}
.live-layout-dropdown-item:hover {
	background: #fafafa;
}
.live-layout-dropdown-item img {
	height: 90px;
	box-shadow: 0 4px 8px rgba(0,0,0,0.05);
	border-radius:6px;
	border:solid 1px #efefef;
}

.live-layout-dropdown-item:after {
	width: 8px;
	height: 8px;
	margin-right: 15px;
	border-bottom:solid 2px #333;
	border-right:solid 2px #333;
	content: " ";
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
}

.live-layout-numbers {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.live-layout-numbers span {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	line-height: 0;
	font-size: 14px;
	border:solid 1px #efefef;
}

.email-edit-header,
.edit-product-card {
	width: 100%;
    padding: 20px;
    /*box-shadow: 0 8px 16px rgba(0,0,0,0.05);*/
    box-shadow: 0 8px 16px rgba(115,135,150,0.15);
    min-height: 100px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-bottom: 20px;
    border-radius: 6px;
    background: #fff;
    position: relative;
}
.email-edit-header {
	min-height: 0;
	min-height: initial;
}

.email-edit-header .btn {
	padding:13px 30px!important;
	text-transform: uppercase;
	border-radius: 10px;
}
.email-edit-header .btn .fa {
	-webkit-transform: translateY(1px);
	        transform: translateY(1px);
}
.edit-product-card .autofill-dropdown-item {
	padding:0;
	margin-top: 0px;
	border:none;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.edit-product-card .autofill-dropdown-item .btn {
	padding:5px 15px;
	margin-left: 5px;
}

.edit-product-card legend {
	color: #8895a0;
	font-size: 14px;
	margin-top: 5px;
}

.edit-product-card .input-group label {
	font-weight: normal;
	font-size: 16px;
	margin-bottom: 5px;
	text-transform: none;
}
.edit-product-card-separator {
	width: calc(100% + 40px);
	margin-left: -20px;
	height: 1px;
	background: #efefef;
	margin:30px 0;
}
.edit-product-card .input-group {
	margin-top: 10px;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
}

.edit-product-card h4 {
	margin:0 0 15px 0;
	font-size: 18px;
	width: 100%;
	color: #333;
}

.edit-product-card h4 legend {
	font-weight: normal;
	margin-top: 0;
}

.edit-product-card .product-switch-wrapper {
	    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}
.edit-product-card .product-switch-wrapper .publish-switch {
	width: 100%;
	height: 50px;
    border-radius: 6px;
    background: transparent;
    border: solid 1px #efefef;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding-left: 15px;
}

.edit-product-card .product-switch-wrapper .publish-switch:hover {
	background: #fafafa;
}

.edit-product-card .product-switch-wrapper .publish-switch:after {
	width: 45px;
    height: 20px;
    content: " ";
    background: #ccc;
    position: absolute;
    right: 10px;
    top: calc(25px - 10px);
    border-radius: 40px;
}

.edit-product-card .product-switch-wrapper .publish-switch.active:after {
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
}

.edit-product-card .product-switch-wrapper .publish-switch:before  {
	left: auto;
	right: 37px;
	top: calc(25px - 8px);
	z-index: 2;
	width: 16px;
    height: 16px;
}
.edit-product-card .product-switch-wrapper .publish-switch.active:before {
	right: 12px;
}
.input-group.big .product-switch-wrapper {
	right: 10px;
	top: 20px;
	position: absolute;
}

.input-group.big input {
	/*background: #fff;
	border:none;
	border-bottom: solid 1px rgba(0,0,0,0.2);
	border-radius: 0;
	padding:0;*/
	font-size: 25px;
	height: 60px;
	/*line-height: 70px;
	font-weight: bold;*/
	padding-right: 50px;
}
.input-group.big label {
	margin-bottom: 0;
}
.input-group.big input:focus {
	/*border-bottom-color: #333;
	box-shadow: 6px 6px 12px rgba(0,0,0,0.1);*/
}

.product-variant-upload {
	display: -webkit-flex;
	display: flex;
	width: 100%;
	margin-bottom: 10px;
	padding:20px;
	border:dashed 1px #ccc;
	font-weight: 600;
	background: #f3f2f2;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	font-size: 16px;
	box-shadow: inset 1px 1px 1px rgba(0,0,0,0.1), inset -1px -1px 1px rgba(255,255,255,0.1);
	border-radius: 6px;
	transition: 0.2s;
	cursor: pointer;
	position: relative;
}

.product-variant-upload .btn {
	position: absolute;
	top: 16px;
	right: 20px;
	padding:6px 12px;
	border-radius: 10px;
}
.product-variant-upload .btn.remove {
	background: #D93939;
	color: #fff;
	font-size: 13px;
}
.product-variant-upload:hover {
	background: #fff;
	box-shadow: 0 5px 10px rgba(0,0,0,0.1);
}

.product-variant-upload.active {
	border:solid 1px #efefef;
	background: #fff;
	box-shadow: 0 5px 10px rgba(0,0,0,0.1);
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
}
.product-variant-upload img {
	height: 16px;
	object-fit: contain;
	margin-right: 10px;
}

.edit-product-variant .input-group {
	margin-top: 10px;
}

.edit-product-variant .input-group:first-of-type {
	margin-top: 0;
}

.edit-product-variant .input-group label {
	margin-bottom: 5px;
}
.edit-product-variant.description {
	padding:30px 0 0 0;
	overflow: hidden;
}
.edit-product-options .separator {
	margin:40px 0;
	width: 100%;
	height: 1px;
	background: #efefef;
	border:none;
}
.edit-product-variant .input-group textarea {
	min-height: 171px;
}

.input-group.third {
	width: calc(33.333% - 10px);
}

.input-group.half {
	width: calc(50% - 10px);
}

.edit-product-columns {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: nowrap;
	        flex-wrap: nowrap;
	width: 100%;
	margin-top: 60px;
}


.edit-product-variant-footer {
	display: -webkit-flex;
	display: flex;
	text-transform: uppercase;
	font-size: 14px;
	border-top: solid 1px #efefef;
	padding-top: 20px;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	margin-bottom: 0px;
	margin-top: 20px;
	width: 100%;
}

.edit-product-variant-footer div {
	padding:10px 20px;
	cursor: pointer;
	border-radius: 40px;
	margin-left: 0px;
	color: rgba(0,0,0,0.5);
}

.edit-product-variant-footer div:hover {
	color: #333;
}

.edit-product-variant-footer div:last-child {
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	color: #fff;
}

.edit-product-variant-footer div:last-child:hover {
	background: #28C76F;
	color: #fff;
}

.edit-product-images-scroll {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-content: center;
	        align-content: center;
	-webkit-justify-content: center;
	        justify-content: center;
	width: calc(100% + 0px);
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}
.edit-product-images-drag {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-flex-direction: column;
	        flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.edit-product-images-drag .edit-product-images-item {
	width: 100%;
}

.edit-product-images-drag [data-rbd-drag-handle-context-id="0"] {
    margin-bottom: 10px;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    width: 100%;
    border:solid 1px #efefef;
    border-radius: 6px;
    overflow: hidden;
}
.edit-product-images-item:after {
	font-size: 15px;
}
.edit-product-images-drag [data-rbd-drag-handle-context-id="0"]:nth-child(1) .edit-product-images-item:after  {
	content: "Main Image";
}
.edit-product-images-drag [data-rbd-drag-handle-context-id="0"]:nth-child(2) .edit-product-images-item:after  {
	content: "Image order: 2";
}
.edit-product-images-drag [data-rbd-drag-handle-context-id="0"]:nth-child(3) .edit-product-images-item:after  {
	content: "Image order: 3";
}
.edit-product-images-drag [data-rbd-drag-handle-context-id="0"]:nth-child(4) .edit-product-images-item:after  {
	content: "Image order: 4";
}
.edit-product-images-drag [data-rbd-drag-handle-context-id="0"]:nth-child(5) .edit-product-images-item:after  {
	content: "Image order: 5";
}
.edit-product-images {
	width: 100%;
	margin-right: 0px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-content: center;
	        align-content: center;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}
.edit-product-images {
	width: 100%;
}
.edit-product-images .action-buttons {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-flex-direction: column;
	        flex-direction: column;
	z-index: 2;
	-webkit-align-items: center;
	        align-items: center;
}

.edit-product-images-item:hover .action-buttons {
	opacity: 1;
}

.edit-product-images .add-shipping-button {
	width: 100%;
	text-align: center;
	-webkit-justify-content: center;
	        justify-content: center;
}
.edit-product-images-item {
	position: relative;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-flex-shrink: 0;
	        flex-shrink: 0;
	margin-bottom: 10px;
}
.edit-product-images-item:hover .hover-box {
	opacity: 1;
	visibility: visible;
}
.edit-product-images-item img {
	width: 80px;
	height: 80px;
	object-fit: cover;
	border:solid 1px #efefef;
	display: block;
	border-radius: 6px;
}

.edit-product-options {
	width: 100%;
}
.edit-product-options h4,
.edit-product-images h4 {
	font-size: 18px;
	margin:0 0 20px 0;
}
.edit-product-variant {
	width: 100%;
	min-height: 100px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	margin-bottom: 40px;
}

.product-variant-complete .edit-block-btn {
	padding: 6px 20px;
	margin-right: 4px;
	padding-left: 32px;
}
.product-variant-complete {
	box-shadow: 0 8px 16px rgba(0,0,0,0.1), 0 0 0 5px rgba(0,0,0,0.1);
	background: #fff;
	border: solid 1px #efefef;
	border-radius: 6px;
	margin-bottom: 20px;
	min-height: 100px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	cursor: pointer;
	transition: 0.2s;
}
.product-variant-complete:hover {
	box-shadow: 0 8px 16px rgba(0,0,0,0.1), 0 0 0 10px rgba(0,0,0,0.1);
}
.product-variant-complete .left,
.product-variant-complete .right {
	padding:20px 30px;
}

.product-variant-complete .hover-box {
	opacity: 1;
	visibility: visible;
}

.product-variant-complete .action-buttons {
	display: -webkit-flex;
	display: flex;
	opacity: 1;
}

.product-variant-complete .left {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}

.product-variant-complete .left strong {
	text-transform: uppercase;
	padding:5px 10px;
	border:solid 1px #efefef;
	border-radius: 6px;
	font-size: 16px;
	margin-right: 10px;
}

.product-variant-complete h2 {
	font-size: 16px;
	font-weight: normal;
	margin: 0;
}
.form-sub-title {
	font-size: 12px;
	text-transform: uppercase;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: center;
	        align-items: center;
	opacity: 0.5;
	width: 100%;
	font-weight: bold;
	margin:30px 0 20px 0;
}
.form-sub-title:after {
	height: 1px;
	width: 100%;
	margin-left: 10px;
	border-top: dashed 1px #ccc;
	content: " ";
}
.icon-btn {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	color: #fff;
	padding:9px 18px;
	font-size: 16px;
	cursor: pointer;
	border-radius: 6px;
	margin-right: 8px;
}
.icon-btn .fa {
	font-size: 13px;
	-webkit-transform:translateY(1px);
	        transform:translateY(1px);
	margin-right: 6px;
}
.icon-btn:hover {
	background: rgba(255,255,255,0.1);
}
.edit-product-form-footer {
	display: -webkit-flex;
	display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 0px;
    padding: 0 10px;
    margin-bottom: 50px;
    /* border-top: solid 1px #efefef; */
    width: calc(100% - 80px);
    position: fixed;
    top: 0px;
    right: 0px;
    background: rgba(38,46,71,1);
    height: 65px;
    z-index: 200;
    border-radius: 0px;
    margin: 0px;
    -webkit-animation:fadeInTop 0.4s forwards;
            animation:fadeInTop 0.4s forwards;
    -webkit-align-items: center;
            align-items: center;
    box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}

.edit-product-action-wrapper {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	max-width: 900px;
	margin:0 auto;
	width: 100%;
}

.edit-product-form-footer .left,
.edit-product-form-footer .right {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}
@media (min-width: 1050px) {

}
@media (min-width: 900px) {
	.edit-product-form-footer .left,
	.edit-product-form-footer .right {
		width: 450px;
	}

	.edit-product-form-footer .right {
		-webkit-justify-content: flex-end;
		        justify-content: flex-end;
	}
}


.edit-product-form-footer .btn {
	font-size: 16px;
	border-radius: 10px;
	padding:10px 15px!important;
}

.edit-product-form-footer .btn.empty {
	border:none;
	color: #fff;
	background: transparent;
}

.product-type-select,
.multiple-products-cards {
	width: 100%;
	max-width: 600px;
	padding:20px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	margin:40px auto;
}
.product-type-select  {
	max-width: 1000px;
}
.product-type-select h1,
.multiple-products-cards h1 {
	font-size: 28px;
	text-align: center;
	margin:20px 0;
	width: 100%;
}

.multiple-products-cards-item {
	width: 100%;
	border-radius: 8px;
	border:solid 1px #efefef;
	transition: 0.2s;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	padding:5px 10px;
	margin:10px 0;
	background: #fff;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	position: relative;
	padding-left: 132px;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.12);
}

.multiple-products-cards-item .delete-item {
	opacity: 0.2;
	position: absolute;
	width: 18px;
	height: 18px;
	object-fit: contain;
	top: calc(50% - 9px);
	right: -30px;
	cursor: pointer;
}

.multiple-products-cards-item .delete-item:hover {
	opacity: 0.8;
}

.multiple-products-cards-item-img {
	width: 112px;
	height: 112px;
	position: absolute;
	left: 10px;
	top: 10px;
	border-radius: 6px;
	border:solid 1px #efefef;
	cursor: pointer;
	transition: 0.2s;
}

.multiple-products-cards-item-img:hover {
	box-shadow: 0 0 0 3px #B2A7A7;
}

.multiple-products-cards-item-img strong,
.multiple-products-cards-item-img span {
	font-weight: normal;
	font-size: 11px;
	position: absolute;
}

.multiple-products-cards-item-img span {
	font-size: 11px;
	font-weight: 600;
	top: 3px;
	right: 3px;
	color: #fff;
	background: #333;
	border-radius: 4px;
	padding:2px 5px;
}

.multiple-products-cards-item-img strong {
	bottom: 0px;
	color: #B2A7A7;
	width: 100%;
	padding:20px 10px;
	text-align: center;
	left: 0;

}

.multiple-products-cards-item-img.empty {
	border:dashed 1px #B2A7A7;
}

.multiple-products-cards-item-img img:not(.icon) {
	width: 100%;
	height: 100%;
	object-fit: contain;
	border-radius: 6px;
}

.multiple-products-cards-item .input-group {
	margin:5px 0px;
}

.product-type-select-item {
	width: calc(33.333% - 20px);
	border-radius: 8px;
	border:solid 1px #efefef;
	transition: 0.2s;
	cursor: pointer;
	text-align: center;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	padding:50px;
	margin:0 10px;
	background: #fff;
}

.product-type-select-item img {
	width: 70px;
	height: 70px;
	object-fit: contain;
	margin-bottom: 20px;
	opacity: 0.5;
	transition: 0.2s;
}
.product-type-select-item h4 {
	font-size: 20px;
}
.product-type-select-item p {
	font-size: 15px;
}
.product-type-select-item h4,
.product-type-select-item p {
	margin:0;
}
.product-type-select-item:hover {
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
	border-color: #333;
}
.product-type-select-item:hover img {
	opacity: 1;
	-webkit-transform: scale(1.05);
	        transform: scale(1.05);
}
@media (max-width: 779px) { 
	.email-edit-header .btn {
		padding:12px 22px!important;
	}
	.edit-product-form-footer {
	    width: 100%;
	    left: 0;
	    margin: 0;
	    bottom: 0;
	    border-radius: 6px 6px 0 0;
	}
	.edit-product-form-footer .btn {
		padding:8px 16px!important;
	}
	.edit-product-form-footer .btn:not(.green) {
		background: #fff;
		color: #333;
		margin-right: 5px;
		box-shadow: 0 4px 8px rgba(0,0,0,0.1);
	}
	.product-type-select {
		margin-top: 0;
	}
	.product-type-select-item {
		width: 100%;
		margin:0 0 20px 0;
	}
	.edit-product-columns {
		-webkit-flex-wrap: wrap;
		        flex-wrap: wrap;
		margin-top: 20px;
	}
	.edit-product-form .input-group.big {
		color: #fff;
	}
	.edit-product-form .input-group.big input {
		font-size: 22px;
		padding-right: 65px;
		/*background: transparent;
		color: #fff;
		border-bottom: solid 1px rgba(255,255,255,0.4);*/
	}

	.edit-product-form .input-group.big .product-switch-wrapper:after {
		height: 70px;
	    width: 1px;
	    background: #ccc;
	    content: " ";
	    position: absolute;
	    left: -7px;
	    top: -20px;
	}
	.edit-product-form .input-group.big input::-webkit-input-placeholder {
		/*color: rgba(255,255,255,0.6);*/
	}
	.edit-product-form .input-group.big input:-ms-input-placeholder {
		/*color: rgba(255,255,255,0.6);*/
	}
	.edit-product-form .input-group.big input::placeholder {
		/*color: rgba(255,255,255,0.6);*/
	}
	.edit-product-form .input-group.big input:focus {
		/*box-shadow: 0;
		border-bottom-color: #fff;*/
	}


	.edit-product-images-scroll {
		-webkit-flex-wrap: nowrap;
		        flex-wrap: nowrap;
		overflow: auto;
	}
	.edit-product-images {
		width: 100%;
		margin-right: 0;
		margin-bottom: 30px;
		-webkit-justify-content: center;
		        justify-content: center;
	}
	.edit-product-images .add-shipping-button {
		margin-top: 20px;
	}
	.payouts-dashboard {
		-webkit-flex-wrap: wrap;
		        flex-wrap: wrap;
	}
	#emailSettings .modal-content {
		max-width: 300px!important;
	}
	.payouts-user {
		width: 100%;
	    margin-bottom: 15px;
	    text-align: center;
	    border-bottom: solid 1px #efefef;
	    padding-bottom: 15px;
	}
	.payouts-dashboard .payouts-cta {
		width: 100%;
		margin-top: 15px;
	    text-align: center;
	    border-top: solid 1px #efefef;
	    padding-top: 15px;
	}
	.payouts-dashboard .payouts-value {
		width: 100%;
		text-align: center;
	}
	.product-image-wrapper {
		height: 380px!important;
		width: 100vw;
		min-height: 0;
		min-height: initial;
	}
	.product-details-wrapper {
		width: 100vw;
		padding:30px;
	}
	.price-wrapper span {
		font-size: 24px!important;
	}
	#productContent .product-details-title h1 {
		font-size: 35px!important;
	}
	#productContent .product-details-title h2 {
		font-size: 30px;
	}
	#productContent .product-details-title h3 {
		font-size: 30px;
	}
	#productContent .product-details-title h4 {
		font-size: 30px;
	}
	.cart-button {
		padding:15px 20px;
	}
	.cart-button .cart-icon {
		margin-left: 0;
	}
	.price-wrapper {
		padding-top: 0;
	}
	#productContent.product-3 .price-wrapper {
		-webkit-flex-direction: column;
		        flex-direction: column;
		margin-bottom: 30px;
		-webkit-align-items: flex-start;
		        align-items: flex-start;
	}
	#productContent.product-3 .product-description {
		margin-top: 10px;
	}
	#productContent.product-3 .price-wrapper .cart-button {
		margin-top: 15px;
	}
	.manage-store-navbar {
		position: fixed;
		overflow: visible;
		width: 102%;
		height: 60px;
		top: 0;
		left: 0;
		overflow: auto;
		margin:0;
		border-radius: 0;
		padding:10px;
		z-index: 10;
		overflow: visible;
	}

	.add-product-button {
		position: relative;
		display: inline-block;
		margin-top: 20px;
		width: 100%;
		text-align: center;
	}
	.master_container .manage-store-title {
		margin-bottom: 20px;
	}
	.manage-store-navbar ul {
		width: 100vw;
		height: 70px;
		bottom: 0;
		position: fixed;
		left: 0;
		background: linear-gradient(-45deg, #3c4665 0%, #21283F 100%);
		display: -webkit-flex;
		display: flex;
	    -webkit-flex-direction: row;
	            flex-direction: row;
	    -webkit-justify-content: center;
	            justify-content: center;
	    padding:0;
	    transition: all 0.4s;
	    font-size: 12px;
	    font-weight: normal;
	    left: 0;
	    padding-bottom: 10px;
	    z-index: 20;
	}
	.manage-store-navbar ul li {
		width: 20%;
	}
	.manage-store-navbar ul li.bar-dropdown {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		height: 60px;
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-align-items: center;
		        align-items: center;
		-webkit-align-content: center;
		        align-content: center;
		font-size: 12px;
		opacity: 0.4;
	}
	.manage-store-navbar ul li.bar-dropdown:hover {
		opacity: 1;
		color: #fff;
	}
	.manage-store-navbar ul li.bar-dropdown .bar-dropdown-content {
		position: fixed;
	    bottom: 70px;
	    top: auto;
	    box-shadow: 0 0 16px rgba(0,0,0,0.2);
	    width: 100%;
	    color: #333!important;
	}
	.manage-store-navbar .bar-dropdown-content .bar-dropdown-content-item h3 span {
		font-size: 12px!important;
	}
	.manage-store-navbar .bar-dropdown-content .bar-dropdown-content-item img {
		-webkit-filter: none!important;
		        filter: none!important;
	}
	.manage-store-navbar ul li:nth-child(3),
	.manage-store-navbar ul li:nth-child(4) {
		display: none;
	}
	.manage-store-navbar ul li a {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		height: 60px;
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-align-items: center;
		        align-items: center;
		-webkit-align-content: center;
		        align-content: center;
		width: 100%;
	}
	.manage-store-navbar .hide-desktop {
		display: -webkit-flex!important;
		display: flex!important;
	}
	.manage-store-navbar ul li a:hover img,
	.manage-store-navbar ul li a.active img {
		-webkit-transform: none!important;
		        transform: none!important;
	}
	.manage-store-navbar ul li img {
		width: 14px;
		height: 14px;
		margin:0 0 4px 0;
	}
	.manage-store-navbar.active ul {
		left: 0;
	}
	.flex-container .store-info h4 {
		font-size: 16px;
	}
	.nav-2 #cartBtn {
		margin-top: 0;
		margin-left: 20px;
	}
	.store-info {
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-align-items: center;
		        align-items: center;
		padding:0;
	}
	.stripe-secure-payment img {
		max-width: none;
		max-width: initial;
		width: 100%;
		object-fit: contain;
	}
	.store-info img {
		width: 40px;
		height: 40px;
		object-fit: cover;
		margin-bottom: 0;
		margin-right: 10px;
	}
	.store-info div p {
		display: none;
	}
	.manage-store-navbar .clickai-logo {
		display: none;
	}

	.manage-store-sections {
		width: 100%;
		padding:100px 0px 20px 0px;
		overflow: visible;
	}
	h4.table-title {
		color: #fff;
		margin-top: 20px;	
	}
	.shipping-table .row .col input, .shipping-table .row .col select {
		padding:10px;
	}
	#manageStoreContent .flex-container {
		padding-bottom: 60px!important;
	}
	.checkout-cart {
		width: 100%;
		padding:30px;
		height: auto;
	}
	.checkout-form {
		width: 100%;
		padding:30px;
		height: auto;
	}

	.product-image-wrapper .product-image-thumbs img {
		width: 50px;
		height: 50px;
		border:none;
	}

	.back-to-store-btn {
		width: 100%;
		padding:10px 0;
	}
	#productContent .design-block-toolbar,
	#blogPost .design-block-toolbar {
		    opacity: 1;
		    visibility: visible;
		    -webkit-transform: none;
		            transform: none;
	}
	#productContent .design-block-toolbar .left .design-block-toolbar-background.green,
	#blogPost .design-block-toolbar .left .design-block-toolbar-background.green  {
		margin:0;
	}
	.manage-store-cards {
		-webkit-flex-wrap: wrap;
		        flex-wrap: wrap;
		margin-top: 0;
		margin-bottom: 20px;
	} 
	.manage-store-cards div:first-child {
		width: 100%;
	}
	.manage-store-cards div {
		width: calc(50% - 10px);
		margin-bottom: 15px;
		padding:20px 30px;
	}

	.manage-store-sections .section .cart-item img {
		width: 100px;
		height: 100px;
		margin-right: 20px;
	}
	#manageStoreContent .cart-item h4 {
		font-size: 20px;
	}

	#manageStoreContent .cart-item .action-buttons {
		position: absolute;
		right: auto;
		left: 40px;
	}

	#manageStoreContent .cart-item .product-inventory {
		right: 20px;
	}
	#manageStoreContent .cart-item {
		padding-right: 20px;
	}

	.payment-options-info div {
		width: 100%;
	}

	#manageStoreContent .cart-item .product-inventory {
		display: none;
	}
	.flex-table.row-5 .table-row div {
		padding:10px;
	}
	.flex-table.row-5 .table-row div:nth-child(1),
	.flex-table.row-5 .table-row div:nth-child(6) {
		display: none;
	}
	.flex-table.row-5 .table-row div.flag {
		display: none;
	}
	.flex-table.row-5 .table-row div:nth-child(2),
	.flex-table.row-5 .table-row div:nth-child(5) {
		width:auto;
	}
	.flex-table.row-5 .table-row div:nth-child(5) {

	}
	.manage-store-sections .section .cart-item .card-dropdown {
		right: 10px;
	}
	.manage-store-sections .section .cart-item {
		height: auto;
		-webkit-flex-direction: column;
		        flex-direction: column;
		padding-right: 0;
		margin-bottom: 20px;
	}
	.manage-store-sections .cart-item h4 {
		padding:20px;
		width: 100%;
	}

	.manage-store-sections .section .cart-item img {
		position: relative;
		width: 100%;
		height: 100%;
		margin-right: 0;
		border-radius: 6px 6px 0 0;
	}

	.manage-store-sections .cart-item .product-inventory {
		position: relative;
		width: 100%;
	}

	.manage-store-sections .cart-item .product-inventory div {
		opacity: 1!important;
		text-align: left;
		padding:10px;
		-webkit-justify-content: flex-start;
		        justify-content: flex-start;
	}
	.cart-item .product-inventory strong {
		font-size: 14px;
	}

	.edit-product-images .action-buttons {
		opacity: 1;
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-align-items: flex-end;
		        align-items: flex-end;
	}
	.product-variant-complete .left {
		padding:10px;
		width: 100%;
		    display: -webkit-flex;
		    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding-bottom: 50px;
	}
	.product-variant-complete {
		position: relative;
	}
	.product-variant-complete .left strong {
		/*position: absolute;
		left: 10px;
		top: 10px;*/
	}
	.product-variant-complete .right {
		padding:0;
	}
	.product-variant-complete .action-buttons {
		position: absolute;
		-webkit-flex-direction: row-reverse;
		        flex-direction: row-reverse;
		bottom: 5px;
		right: 5px;
		display: -webkit-flex;
		display: flex;
	}
	.product-variant-complete h2 {
		position: absolute;
		bottom: 10px;
		left: 14px;
		font-size: 14px;
	}
	.product-variant-complete .left h2 {
		position: relative;
		left: auto;
		bottom: auto;
		font-size: 16px;
	}

	.product-variant-complete .action-buttons .button {
		margin-left: 5px;
	}
	.product-variant-complete .action-buttons .button.delete {
		background: transparent;
		color: red;
	}

	#cartBtn.hide-desktop {
		display: block!important;
		position: absolute;
		right: 46px;
		font-size: 23px;
	}
	#cartBtn.hide-desktop .fa {
		font-weight: 300;
	}
	#cartBtn.hide-desktop img {
		width: 23px!important;
	}

	#cartBtn.hide-desktop span {
		top: 0;
		right: -6px;
	}

	.left-cards,
	.right-cards {
		width: 100%;
	}
	.right-cards {
		margin-top: 20px;
	}
	.input-group.half,
	.input-group.third {
		width: 100%;
	}
}

/**CONTACT PAGE**/
#contactContent  {
	width: 100%;
	/*max-width: 1440px;*/
	margin:0 auto;
	padding:40px 40px 0px 40px;
	min-height: 100%;
	font-size: 18px;
	font-weight: 400;
	display: block;
	-webkit-justify-content: center;
	        justify-content: center;
}

.contact-content-wrapper {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
}
#contactContent .contact-form {
	padding:0px 0;
}
.contact-form {
	position: relative;
}
.contact-form .hover-form {
	position: absolute;
	width: 100%;
	height: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    z-index: 3;
    opacity: 0;
}
.contact-form:hover .hover-form  {
	opacity: 1;
}
.contact-form.hide .hover-form {
	opacity: 1;
}
.contact-form .hover-form .btn {
	background: #21283F;
	border:none;
	color:#fff;
}
.contact-form .hover-form .btn:before {
	content: "Hide";
	margin-right:3px;
}

.contact-form .hover-form .btn.active:before {
	content: "Show";
}

.edit-body-mode .contact-form.hide {
	display: block;
}
.edit-body-mode .contact-form.hide form {
	display: none;
}
.contact-form.hide {
	display: none;
}
#contactContent .contact-form button {
	border:none;
	outline:none!important;
	border-radius: 0;
	margin-top: 10px;
	background: #333;
	font-size: 15 px;
	text-transform: uppercase;
	color: #fff;
	padding:15px 30px;
	transition: 0.2s;
}
#contactContent .contact-form button:hover {
	-webkit-transform: scale(1.1);
	        transform: scale(1.1);
}
#contactContent .contact-form button:focus {
	-webkit-transform: scale(1);
	        transform: scale(1);
}
.text-white #contactContent .contact-form button {
	background: #fff;
	color: #333;
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
}
.contact-form form {
	width: 100%;
}

.contact-form .contact-input {
	width: 100%;
	margin-bottom: 10px;
	float: left;
}
.contact-form .contact-input label {
	width: 100%;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 800;
}

.contact-form .contact-input input,
.contact-form .contact-input textarea  {
	width: 100%;
	float: left;
	font-size: 14px;
	height: 50px;
	border-radius: 0px;
	border:solid #efefef 1px;
	outline:none!important;
	padding:0 15px;
	transition: all 0.2s;
	font-size: 15px;
	background: #f3f2f2;
	font-family: Source Sans Pro;
}

.contact-form .contact-input input::-webkit-input-placeholder, .contact-form .contact-input textarea::-webkit-input-placeholder {
	font-size: 15px;
}

.contact-form .contact-input input:-ms-input-placeholder, .contact-form .contact-input textarea:-ms-input-placeholder {
	font-size: 15px;
}

.contact-form .contact-input input::placeholder,
.contact-form .contact-input textarea::placeholder {
	font-size: 15px;
}
.contact-content {
	position: relative;
}
.contact-form .contact-input input:focus,
.contact-form .contact-input textarea:focus {
	border-bottom-color: #000;
}
.contact-form .contact-input textarea {
	height: 130px;
	padding-top: 15px;
}
.contact-content .profile-stats {
	position: relative;
	left:auto;
	bottom:auto;
}
.contact-content h1 {
	width: 100%;
}

.flex-container .contact-content h1,
.flex-container .contact-content h2,
.flex-container .contact-content h3,
.flex-container .contact-content h4 {
	margin:0 0 10px 0;
}
.contact-content p {
	margin-top: 0;
}
.social-contacts {
	margin-bottom: 20px;
}
.social-contacts a {
	margin:0 10px;
	font-size: 21px;
}
.social-contacts a:first-child {
	margin-left: 0;
}
.contact-content {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	padding-top: 50px;
	padding-right: 50px;
}
.contact-1 .contact-image {
	width: 100%;
}
.contact-1 .contact-content-wrapper {
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
}
.contact-content .contact-points {
	-webkit-flex-wrap:wrap;
	        flex-wrap:wrap;
	margin:30px 0;
}
.contact-content .contact-points img {
	max-width: 14px;
	max-height: 14px;
	-webkit-transform: translateY(2px);
	        transform: translateY(2px);
	margin-right: 15px;
}

.contact-points .fa {
	width: 18px;
	height: 18px;
	text-align: center;
	margin-right: 8px;
}

.contact-points a {
	color: inherit;
}

.text-white .contact-content .contact-points img {
	-webkit-filter: invert(1);
	        filter: invert(1);
}
.contact-content .contact-points div {
	margin-bottom: 8px;
}
.contact-1 #contactContent  {
	padding:0;
}
.contact-1 .contact-content-wrapper {
	padding:0 30px;
}
.contact-2 #contactContent {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	padding:0;
}
.contact-2 .contact-content-wrapper {
	-webkit-flex-direction: column;
	        flex-direction: column;
	width: 50%;
	padding:70px;
}
.edit-body-mode.contact-2 .contact-image {
	height: calc(100vh - 130px);
}
.edit-body-mode.contact-2.nav-2 .contact-image {
	height: calc(100vh - 50px);
}
.contact-2 .contact-content-wrapper .col-40 {
	width: 100%;
	padding:0;
}
.contact-2 .contact-image {
	width: 50%;
	height: calc(100vh - 0px);
}

.contact-2 #contactContent .contact-form {
	padding-left: 0;
	padding-top: 20px;
}

.contact-1 #contactContent .contact-form {
	padding-top: 60px;
	padding-bottom: 60px;
}

.contact-3 .social-contacts {
	-webkit-justify-content: center;
	        justify-content: center;
}

.contact-3 .contact-content-wrapper {
	padding-top: 50px;
	padding-bottom: 50px;
}
.contact-3 .contact-content-wrapper {
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
}
.contact-3 .contact-image,
.contact-5 .contact-image {
	display: none;
}

@media (min-width: 779px) {
	.contact-5 #contactContent {
		padding-top: 0;
		min-height: 0;
		min-height: initial;
	}
	.contact-5 #contactContent .contact-content-wrapper {
		max-width: 1000px;
		padding-top:100px;
		padding-bottom: 100px;
		margin:0px auto;
	}
	.contact-5 .contact-content-wrapper {
		-webkit-flex-direction: row;
		        flex-direction: row;
	}
	.contact-5 .contact-content {
		width: 40%;
		padding:0 20px;
	}
	.contact-5 .contact-form {
		width: 60%;
		padding:0 0px 0 40px!important;
	}

	.contact-5 .contact-form .contact-input:nth-child(1),
	.contact-5 .contact-form .contact-input:nth-child(2) {
		width: calc(50% - 5px);
	}
	.contact-5 .contact-form form {
		display: -webkit-flex;
		display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between
	}
}

.contact-3 #contactContent {
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
}
.contact-3 #contactContent .col-40 {
	width: 100%;
	max-width: 600px;
}

.contact-3 .contact-content {
	padding-top: 0;
	padding-right: 0;
}

.contact-3 .contact-content .contact-points {
	margin:20px 0 20px 0;
}

.contact-3 .contact-content .contact-points div {
	margin:10px 0px;
}
.contact-3 .contact-content .contact-points  img {
	margin-right: 5px;
}
.contact-3 .contact-content .profile-stats {
	-webkit-justify-content: center;
	        justify-content: center;
}
.contact-3 #contactContent .contact-form {
	padding-top: 20px;
}


@media (min-width: 779px) {
	.contact-4 #contactContent .contact-form {
		
	}
	.contact-4 .contact-image {
		width: 100%;
		position: absolute;
		height: 100%;
		left: 0;
		top: 0;
	}
	.contact-4 .contact-image:before {
		content: " ";
		position: absolute;
		left: 0;
		top: 0;
		background: #000;
		opacity: 0.2;
		z-index: 2;
		width: 100%;
		height: 100%;
	}
	.contact-4 .flex-container .hover-box {
		height: 60px;
	    display: -webkit-flex;
	    display: flex;
	    -webkit-flex-direction: row;
	            flex-direction: row;
	    -webkit-align-items: flex-end;
	            align-items: flex-end;
	    -webkit-justify-content: flex-start;
	            justify-content: flex-start;
	    padding-left: 20px;
	    opacity: 1;
	    visibility: visible;
	    bottom: 40px;
	    top: auto;
	}
	.contact-4 .hover-box .change-image-btn {
		margin:5px;
	}


	.contact-4 .contact-content-wrapper {
		width: 100%;
		display: -webkit-flex;
		display: flex;
		color: #fff;
		z-index: 4;
	}

	.contact-4 .contact-content-wrapper a img {
		-webkit-filter: invert(1);
		        filter: invert(1);
	}

	.contact-4 .contact-content {
		padding-top: 0;
	}
	.contact-4 .contact-content-wrapper a {
		color: #fff;
	}
	.contact-4 .contact-content-wrapper .socicon {
		color: #fff;
		margin: 0 10px 0 0;
	}

	.contact-4 #contactContent {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
		min-height: 650px;
		padding-top: 0;
	} 
}
.contact-image:hover .hover-box {
	visibility: visible;
	opacity: 1;
}

.contact-image {
	width: 100%;
	height: 400px;
	padding:0px;
	position: relative;
}
.contact-image img {
	width:100%;
	height: 100%;
	object-fit: cover;
}
.edit-contacts {
	    background-image: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
    box-shadow: 0 6px 18px 0 rgba(0,0,0,0.11);
    padding: 10px 20px;
    border-radius: 40px;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.3s;
    display: inline-block;
    visibility: hidden;
    transition:0.3s all;
    width: 127px;
    position: relative;
    z-index: 4;
    margin-bottom: -40px;
}
.contact-content:hover .edit-contacts {
	opacity: 1;
	visibility: visible;
}

.contact-3 .edit-contacts {

}
#editContactsModal .input-group {
	margin-top: 10px;
}
#editContactsModal .input-group.with-icon img {
	width:auto;
	height: auto;
	max-width: 15px;
	max-height: 15px;
	top: 18px;
}
#editContactsModal .input-group input {
	margin-bottom: 0;
	padding-left: 40px;
}

@media (max-width: 779px) {
	.contact-2 #contactContent {
		-webkit-flex-direction: column;
		        flex-direction: column;
	}
	.contact-image {
		height: 350px!important;
	}

	.contact-2 .contact-image {
		width: 100%;
	}
	.contact-2 .contact-content-wrapper {
		width: 100%;
		padding:30px;
	}

	.contact-1 .contact-content-wrapper {
		-webkit-flex-direction: column;
		        flex-direction: column;
		padding:0 30px;
	}
	#contactContent .contact-form {
		width: 100%;
	}
	.contact-3 .contact-content-wrapper {
		padding:30px;
	}

	.contact-content-wrapper {
		-webkit-flex-direction: column;
		        flex-direction: column;
		padding:30px;
	}
	.contact-content {
		padding:0;
		padding-top: 20px;
		padding-bottom: 20px;
	}
}




#menuContent {
	padding-bottom: 150px;
}

.nav-2 #menuContent {
	padding-top: 0px;
}

#menuContent .article-image {
	height: 100%;
	display: none;
}
#menuContent .article-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.menu-content {
	width: 100%;
	padding:20px;
	max-width: 900px;
	margin:0 auto;
}

.menu-title {
	width: 100%;
	padding:50px;
	padding-bottom: 100px;
}

.menu-title .change-block-image-btn {
	display: none;
	position: absolute;
	bottom: 10px;
	right: 10px;
	left: auto;
	top: auto;
}
.menu-5 .menu-title .change-block-image-btn {
	visibility: visible;
	display: -webkit-flex;
	display: flex;
	opacity: 1;
}
.menu-title h1 {
    margin:0 auto;
    width: 100%;
    padding:0;
}
.menu-title-description {
	font-size: 18px;
	width: 100%;
	max-width: 500px;
	margin:0 auto;
}
#menuContent .article-content {
	padding:60px;
}
.menu-1 .menu-block {
	margin-left: auto;
	margin-right: auto;
}
.menu-2 .menu-blocks,
.menu-4 .menu-blocks {
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
}
.menu-2 .menu-block,
.menu-4 .menu-block  {
	width: 70%;
}
.menu-4 .menu-image {
	display: -webkit-flex;
	display: flex;
	width: 100%;
}

.menu-blocks {
	text-align: left;
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}
.menu-blocks .List {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}
.menu-blocks .List .draggable {
	width: calc(50% - 40px);
	margin:0 20px;
}
.menu-2 .menu-blocks .List {
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	width: 100%;
	max-width: 600px;
}
.menu-2 .menu-blocks .List .draggable,
.menu-4 .menu-blocks .List .draggable  {
	width: 100%;
}
.menu-4 .menu-blocks .List .menu-block {
	width: 100%;
	max-width: 500px;
	margin:0 auto 80px auto;
}
.menu-4 .menu-blocks .List .draggable .menu-image {
	width: 100%;
}
.menu-5 .menu-blocks .List .draggable .menu-image {
	display: none
}

.menu-5 .click-custom-editor-style {
	z-index: 5;
	color: #fff;
}
.menu-list-hero {
	display: none;
}

.menu-blocks .List .draggable.placeholder {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	background: #efefef;
	border:dashed 2px #ccc;
	border-radius: 6px;
	color: #ccc;
	font-weight: 600;
}
.menu-3 .menu-blocks .List .draggable {
	width: 300%;
	display: -webkit-flex;
	display: flex;	
	-webkit-justify-content: space-between;	
	        justify-content: space-between;
	margin-bottom: 50px;
}
.menu-3 .menu-blocks .List .draggable .menu-block {
	width: 40%;
}
.menu-3 .menu-blocks .List .draggable .menu-image {
	width: 50%;
}
.menu-blocks .List .draggable .menu-block  {
	width: 100%;
}
.menu-blocks .List .draggable .menu-image,
.menu-blocks .List .draggable .menu-block {
	width: 100%;
}

.menu-5 .menu-content {
	max-width: none;
	max-width: initial;
	padding:0;
}
.menu-5 .menu-blocks {
	max-width: 900px;
	margin:0 auto;
}

.menu-5 .menu-image {
	display: none;
}

.menu-5 .menu-title {
	padding: 0px;
	width: 100%;
	height: 400px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	position: relative;
	-webkit-flex-direction: column;
	        flex-direction: column;
	margin-bottom: 50px;
	padding:30px;
}
.menu-5 .menu-title:before {
	content: " ";
	position: absolute;
	left: 0;
	top: 0;
	background: #000;
	opacity: 0.3;
	z-index: 2;
	width: 100%;
	height: 100%;
}
.menu-title .carousel-slide-buttons {
	display: -webkit-flex;
	display: flex;
}
.menu-title .carousel-slide-buttons .change-block-image-btn {
	    opacity: 1;
    visibility: visible;
    right: 0;
    bottom: 0px;
    top: auto;
}
.menu-title:hover .carousel-slide-buttons {
   opacity: 1;
   visibility: visible;
   width: 200px;
}

.menu-5 .menu-title h1,
.menu-5 .menu-title h2,
.menu-5 .menu-title h3,
.menu-5 .menu-title h4,
.menu-5 .menu-title p {
	position: relative;
	color: #fff;
	z-index: 4;
	margin:0;
}

.menu-5 .menu-list-hero {
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
	display: block;
}
.menu-5 .menu-block {
	text-align: left;
}
.menu-5 .menu-block-title:before {
	left: calc(50% - 50px);
}

.menu-5 .menu-block .menu-table .row {
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
}

.menu-3 .menu-image {
	display: -webkit-flex;
	display: flex;
}
.menu-image {
	width: calc(50% - 0px);
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	display: none;
	margin-bottom: 30px;
	position: relative;
}
.menu-image:hover .hover-box {
	opacity: 1;
	visibility: visible;
}
.menu-image img {
	width: 100%;
	height: 300px;
	object-fit: cover;
}
.menu-block {
	width: calc(50% - 40px);
	margin-bottom: 50px;
}
.menu-block-title {
	position: relative;
	font-size: 32px;
	margin-top: 0;
	width: 100%;
	margin-bottom: 0!important;
}
.menu-block-description {
	width: 100%;
	opacity: 0.8;
	margin-bottom: 25px;
}
.menu-block-title:before {
	display: none;
	content: " ";
	height: 3px;
	width: 100px;
	position: absolute;
	bottom: -10px;
	left: 0;
	background: #efefef;
}

.menu-table {
	display: -webkit-flex;
	display: flex;
	width: 100%;
	-webkit-flex-direction: column;
	        flex-direction: column;
}
.menu-table .row {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	padding:6px 4px;
	-webkit-align-items: flex-end;
	        align-items: flex-end;
	transition:all 0.2s;
}
.menu-table .row:hover {
	box-shadow: 0 2px 6px rgba(0,0,0,0.1);
}
.menu-table .row strong {
	font-weight: 600;
}
.menu-table .row span {
	opacity: 0.9;
}
.menu-block {
	position: relative;
}
.menu-block .button-set {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	opacity: 0;
	visibility: hidden;
	-webkit-align-items: center;
	        align-items: center;
	z-index: 4;
	background: rgba(255,255,255,0.6);
}

.menu-content .draggable {
	transition: all 0.3s;
}


.menu-content .draggable.dragged {
	background:#fff;
	width: 320px;
}
.menu-content .draggable.dragged .menu-image {
	display: none;

}

.menu-block:hover .button-set {
	opacity: 1;
	visibility: visible;
}

.body-edit-mode .menu-block:hover .menu-block-title,
.body-edit-mode .menu-block:hover .menu-table {
	opacity: 0.4;
}

.menu-block .button-set .btn {
	margin:0 5px;
}

#addMenuItem {
	display: block;
	margin:20px auto 30px auto;
	width: 176px;
}

@media (max-width: 779px) {
	.menu-title {
		padding:10px 0px 50px 0;
	}
	.menu-image {
		width: 100%!important;
	}
	.menu-block{
		width: 100%!important;
	}
	.menu-blocks .List,
	.menu-blocks {
		-webkit-flex-direction: column!important;
		        flex-direction: column!important;
	}
	.menu-blocks .List .draggable .menu-block,
	.menu-blocks .List .draggable .menu-image,
	.menu-blocks .menu-block,
	.menu-blocks .menu-image {
		width: 100%!important;
	}
	.menu-blocks .List .draggable {
		margin:0;
		width: 100%!important;
		-webkit-flex-direction: column!important;
		        flex-direction: column!important;
	}
	.menu-image img {
		height: 200px!important;
	}
	.menu-5 .menu-blocks {
		padding:0 20px;
	}
}
.gallery-modal {
	position: fixed;
	top: 0px;
	left: 0;
	height: calc(100% - 0px);
	width:100%;
	background: rgba(255,255,255,1);
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	z-index: 44444;
	opacity: 0;
	visibility: hidden;
	transition: all 0.4s;
}
.gallery-modal.video-gallery-modal {
	background: #111;
}

.gallery-modal.video-gallery-modal .gallery-nav,
.gallery-modal.video-gallery-modal .gallery-number,
.gallery-modal.video-gallery-modal .close-gallery-modal {
	-webkit-filter: invert(1);
	        filter: invert(1);
}

/***MODAL ANIMATIONS***/
.gallery-modal-active .gallery-title {
	-webkit-animation:fadeOut .5s forwards;
	        animation:fadeOut .5s forwards;
}
.gallery-modal-active .grid-container a.larger {
	-webkit-animation: fadeOutDown 1s forwards;
	        animation: fadeOutDown 1s forwards;
}
.gallery-modal-active .gallery-modal-image img {
	opacity: 0;
	-webkit-animation: fadeIn 1s forwards;
	        animation: fadeIn 1s forwards;
	-webkit-animation-delay: 0s;
	        animation-delay: 0s;
}
.gallery-modal-active .gallery-modal {
	opacity: 1;
	transition-delay: 0.2s;
	visibility: visible;
}

.gallery-modal-description {
	opacity: 0;
}
.gallery-modal-active .gallery-modal-description {
	-webkit-animation: descIn forwards .8s;
	        animation: descIn forwards .8s;
	-webkit-animation-delay: 0.6s;
	        animation-delay: 0.6s;
}

.gallery-modal-active .close-gallery-modal {
	-webkit-animation: closeModal 1s forwards;
	        animation: closeModal 1s forwards;
	-webkit-animation-delay: 0.6s;
	        animation-delay: 0.6s;
}

.gallery-number {
	transition:0.7s all;
}
.gallery-modal-active .gallery-number {
	left: 0;
	opacity: 0.1;
	transition-delay: .6s;

}

.gallery-modal-active .gallery-nav {
	right: 0;
	opacity: 1;
	transition-delay: .6s;

}

@-webkit-keyframes descIn {
	0% {opacity: 0; -webkit-transform: translateX(-400px); transform: translateX(-400px);}
	100% {opacity: 1; -webkit-transform: translateX(0); transform: translateX(0);}
}

@keyframes descIn {
	0% {opacity: 0; -webkit-transform: translateX(-400px); transform: translateX(-400px);}
	100% {opacity: 1; -webkit-transform: translateX(0); transform: translateX(0);}
}

@-webkit-keyframes fadeOut {
	0% {opacity: 1;}
	100% {opacity: 0;}
}

@keyframes fadeOut {
	0% {opacity: 1;}
	100% {opacity: 0;}
}


/***MODAL ANIMATIONS END***/
.gallery-modal-content {
	width:calc(100% - 0px);
	height: calc(100% - 0px);
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	overflow-y: auto;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	box-shadow: 0 15px 30px rgba(0,0,0,0);
}

.close-gallery-modal {
	width: 30px;
	height: 30px;
	position: fixed;
	top: 20px;
	right: 20px;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
	opacity: 0;
	-webkit-animation-delay: 0.3s;
	        animation-delay: 0.3s;
	cursor: pointer;
	z-index: 4;
}

.close-gallery-modal:before {
	position: absolute;
	width: 2px;
	height: 100%;
	background: #000;
	content: " ";
	top: 0;
	left: calc(50% - 1px);
}
.close-gallery-modal:after {
	position: absolute;
	width: 100%;
	height: 2px;
	background: #000;
	content: " ";
	left: 0;
	top: calc(50% - 1px);
}

@-webkit-keyframes closeModal {
	0% {opacity: 0; -webkit-transform: rotate(-20deg); transform: rotate(-20deg)}
	100% {opacity: 1; -webkit-transform: rotate(45deg); transform: rotate(45deg)}
}

@keyframes closeModal {
	0% {opacity: 0; -webkit-transform: rotate(-20deg); transform: rotate(-20deg)}
	100% {opacity: 1; -webkit-transform: rotate(45deg); transform: rotate(45deg)}
}

.gallery-modal-image {
	height: 100%;
	width: 100%;
	padding:50px 100px;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	/*align-items: center;
	align-content: center;*/
}

.gallery-modal-image img {
	max-height: 100%;
	max-width: 100%;
	margin:auto;
	/*box-shadow:0 14px 28px rgba(0,0,0,0.06), 0 10px 10px rgba(0,0,0,0.05);*/
	display: block;
	cursor: -webkit-zoom-in;
	cursor: zoom-in;
	object-fit: contain;
}

.gallery-modal-image.zoomed img {
	max-height: none;
	max-height: initial;
	cursor: -webkit-zoom-out;
	cursor: zoom-out;
	margin-bottom: 40px;
}
.close-description {
	width: 50px;
	height: 50px;
	position: absolute;
	right: 0px;
	top: 0px;
	cursor: pointer;
	z-index: 4;
	transition: all 0.5s;
}


.gallery-modal-description:not(.hide) .close-description:after {
	height: 0;
}
.gallery-modal-description:not(.hide) .close-description:hover:after {
	background: #000;
	height: calc(100% - 30px);
}
.gallery-modal-description:not(.hide) .close-description:hover {
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
}
.gallery-number {
	font-size: 80px;
	opacity: 0.1;
	padding-left: 30px;
	position: fixed;
	left: -50px;
	top: 0;
	height: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	width: 100px;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	opacity: 0;
	right: -20px;
}
.gallery-number span {
	font-size: 22px;
	-webkit-transform: translateY(19px);
	        transform: translateY(19px);
}
.close-description:before {
	width: calc(100% - 30px);
	height: 2px;
	background: #000;
	position: absolute;
	content: " ";
	right: 15px;
	top: calc(50% - 1px);
	z-index: 2;
}

.close-description:after {
	height: calc(100% - 30px);
	width: 2px;
	background: #fff;
	position: absolute;
	content: " ";
	top: 15px;
	transition:all 0.2s;
	left: calc(50% - 1px);
}
.gallery-modal-description.hide {
	left: -340px;
	background: transparent;
}
.gallery-modal-description.hide h1 {
	opacity: 0;
}

.gallery-modal-description.hide .close-description {
	top: calc(100% - 60px);
	background: #000;
}
.gallery-modal-description.hide .close-description:before {
	background:#fff;
}
.gallery-modal-description {
	width: 400px;
	padding:0px;
	margin:0 auto;
	position: fixed;
	transition: all 0.5s;
	bottom: 0px;
	left: 0px;
	box-shadow: 0 10px 20px rgba(0,0,0,0);
	background: #fff;
}
.gallery-modal-description h1 {
	font-size: 18px;
	transition: all 0.3s;
	font-weight: 300;
	line-height: 26px;
	margin:0;
	padding:40px;
	overflow:auto;
	max-height: 100vh;
}

.gallery-nav {
	position: fixed;
	bottom: 20px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	right: -20px;
	padding-right: 30px;
	transition:all 0.3s;
	opacity: 0;
}

.gallery-arrow {
	display: -webkit-flex;
	display: flex;
	color:#000;
	width: 100%;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	-webkit-align-items: center;
	        align-items: center;
	font-size: 11px;
	text-transform: uppercase;
	padding:15px 0px;
	transition: all 0.2s;
	cursor: pointer;
	color: transparent;
	text-shadow: none;
}
.gallery-arrow:hover {
	color: #000;
}
.gallery-arrow:hover span  {
	margin-left: 10px;
}
.gallery-arrow span {
	width: 15px;
	height: 15px;
	border-right: solid 2px #000;
	border-top: solid 2px #000;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
	transition:all 0.2s;
	margin-left: 0px;
}

.gallery-map {
	width: calc(100% - 200px);
    height: calc(100% - 200px);
    margin: auto;
}
.gallery-map iframe {
	width: 100%;
	height: 100%;
	box-shadow:0 14px 28px rgba(0,0,0,0.06), 0 10px 10px rgba(0,0,0,0.05);
}

.gallery-music {
	margin: auto;
    width: 500px;
    height: 500px;

}
.gallery-music iframe {
	width: 100%;
	height: 100%;
	box-shadow:0 14px 28px rgba(0,0,0,0.06), 0 10px 10px rgba(0,0,0,0.05);
}
.gallery-arrow:last-of-type span {
	border-right: none;
	border-top: none;
	border-left: solid 2px #000;
	margin-right: -10px;
	border-bottom: solid 2px #000;
}
.gallery-video iframe {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	border:none;
	box-shadow:0 14px 28px rgba(0,0,0,0.06), 0 10px 10px rgba(0,0,0,0.05);
	opacity: 0;
	-webkit-animation: scaleInVideo 0.5s forwards;
	        animation: scaleInVideo 0.5s forwards;
	-webkit-animation-delay: 0.4s;
	        animation-delay: 0.4s;
}
@-webkit-keyframes scaleInVideo {
   0% {-webkit-transform: scale(0.8);transform: scale(0.8); opacity: 0;}
   100% {-webkit-transform: scale(1);transform: scale(1); opacity: 1} 
  }
@keyframes scaleInVideo {
   0% {-webkit-transform: scale(0.8);transform: scale(0.8); opacity: 0;}
   100% {-webkit-transform: scale(1);transform: scale(1); opacity: 1} 
  }
.gallery-modal-content.video {
	padding:0px 120px 0 120px;
}
#videoModal .gallery-modal-description {
	padding:30px 50px;
}
#videoModal .gallery-modal-description h2 {
	margin:0;
	padding:0;
	font-weight: 600;
}
.gallery-video {
	width: 100%;
	max-width: 1000px;
	position: relative;
	margin:auto;
}
.gallery-video:before {
	display: block;
	content: " ";
	padding-bottom: 56.33%;
}
.gallery-video-title h1 {
	font-size: 45px;
	margin-bottom: 10px;
	margin-top: 0;
	padding:0 0px;
}
.gallery-video-description h1 {
	font-size: 45px;
	margin-bottom: 10px;
	margin-top: 0;
	padding:0 0px;
}
.gallery-video-title {
	width: 100%;
	max-width: 1000px;
	margin:30px auto;
}
.gallery-video-description {
	width: 100%;
	padding:50px 20px;
	max-width: 700px;
	margin:0 auto;
	font-size: 21px;
	line-height: 1.58;
}

@media (max-width: 779px) {
	.gallery-modal-description.hide {
		left: 0;
	}
	.gallery-modal-description.hide h1 {
		opacity: 1;
	}
	.gallery-map,
	.gallery-music {
		width: 100%;
		height: 400px;
	}
	.gallery-number {
		font-size: 22px;
		padding-left: 20px;
		padding-top: 20px;
	}
	.gallery-number span {
		-webkit-transform: none;
		        transform: none;
	}
	.gallery-modal-image {
		padding:63px 0 20px 0;
		display: block;
	}

	.gallery-modal-image {
		width: 100%;
		height: 100%;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-align-items: center;
		        align-items: center;
	}
	.close-description {
		display: none
	}
	.gallery-modal-description {
		position: relative;
	}
	.gallery-modal-description h1 {
		    max-height: none;
		    max-height: initial;
    padding: 0px 30px 60px 30px;
	}
	.gallery-modal-image.zoomed img {
		margin-bottom: 0;
	}
	.gallery-nav {
		width: 100%;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    z-index: 4;
    bottom: 0px;
    padding-bottom: 10px;
    background: rgba(255,255,255,0.9);
    font-size: 0!important;
	}
	.gallery-arrow {
		font-size: 0!important;
	}

	.gallery-arrow:last-of-type {
		-webkit-justify-content: flex-start;
		        justify-content: flex-start;
		padding-left: 30px;
	}





	}
.edit-body-mode.blog-modal-active .master-container {
	overflow: hidden;
}

.edit-body-mode.blog-modal-active .blog-hero-img {
	transition: all 0.2s;
}
.edit-body-mode.blog-modal-active .blog-modal-hero-image:hover .blog-hero-img {
	box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}
.blog-edit-container {
  width: 100%;
  margin-bottom: 20px;
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.blog-edit-container .title-badge {
	margin-left: 0;
	margin-top: 11px;
}

#blogPost {
	-webkit-flex-direction: column;
	        flex-direction: column;
	padding:0 0px;
	opacity: 0;
	-webkit-animation: fadeIn 1s forwards;
	        animation: fadeIn 1s forwards;
}
.blog-post-hero {
	width: 100%;
	max-width: 1000px;
	margin:0 auto;
}
#blogPost .blog-post-title h1 {
	font-size: 65px;
}
#blogPost .blog-post-title h2 {
	font-size: 55px;
	margin:0;
}
#blogPost .blog-post-title h3 {
	font-size: 45px;
	margin:0;
}
#blogPost .blog-post-title h4 {
	font-size: 35px;
	margin:0;
}
#blogPost .blog-post-title  {
	margin-bottom: 50px;
	margin-top: 50px;
}
.blog-post-content {
	width: 100%;
	max-width: 800px;
	margin:50px auto;
	font-size: 21px;
	font-size: 20px!important;
	letter-spacing: -0.037rem;
	position: relative;
	-webkit-font-smoothing:antialiased;
}

.blog-post-content img {
	max-width: 100%;
	height: auto;
}

.blog-post-content p {
	margin: 20px 0px!important;
	line-height: 1.58!important;
}

.blog-post-content h2 {
	font-size: 30px!important;
}

#blogPost.blog-post-2 {
	padding:0;
}

#blogPost.blog-post-2 .blog-post-hero {
	height: 70vh;
	min-height: 400px;
	position: relative;
	max-height: inherit;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	max-width: inherit;
	-webkit-align-items: flex-end;
	        align-items: flex-end;
}

#blogPost.blog-post-3 .blog-post-hero {
	display: -webkit-flex;
	display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

#blogPost.blog-post-3 .blog-post-title {
    margin-bottom: 50px;
    margin-top: 50px;
    width: calc(100% - 300px);
}
#blogPost.blog-post-3 .blog-post-hero-image {
    width: 300px;
    height: 400px;
    position: relative;
    -webkit-align-self: flex-start;
            align-self: flex-start;
    margin-top: 50px;
}
#blogPost.blog-post-3 .blog-post-title h1 {
    font-size: 55px;
}

#blogPost.blog-post-2 .blog-post-hero-image {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
}
#blogPost.blog-post-2 .blog-post-hero-image:before {
	background: linear-gradient(to bottom left, transparent 20%, rgba(0,0,0,0.4) 100%);
	content: " ";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
#blogPost.blog-post-2 .MuiInputBase-root {
	color: #fff;
}
#blogPost.blog-post-2 .blog-post-title {
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	color: #fff;
	margin: 0;
    position: relative;
    z-index: 20;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding:40px 80px;
}
#blogPost.blog-post-2 .blog-post-title h1 {
	color: #fff!important;
}
.blog-post-hero-image {
	width: 100%;
	height: 400px;
	position: relative;
}


.blog-post-hero-image:hover .hover-box {
	opacity: 1;
	visibility: visible;
}

.blog-post-hero-image .blog-hero-img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.blog-post-author {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}
.blog-post-author .author-image {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	margin-right: 10px;
	overflow: hidden;
}
.blog-post-author {
	font-size: 14px;
}
.blog-post-author .MuiInputBase-input {
	font-size: 14px;
	padding:0;
	cursor: pointer;
}
.blog-post-author .MuiInputBase-input:hover {
	opacity: 0.7;
}
.blog-post-author strong {
	font-size: 17px;
}
.blog-post-author .author-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
@-webkit-keyframes descIn {
	0% {opacity: 0; -webkit-transform: translateX(-400px); transform: translateX(-400px);}
	100% {opacity: 1; -webkit-transform: translateX(0); transform: translateX(0);}
}
@keyframes descIn {
	0% {opacity: 0; -webkit-transform: translateX(-400px); transform: translateX(-400px);}
	100% {opacity: 1; -webkit-transform: translateX(0); transform: translateX(0);}
}

@-webkit-keyframes fadeOut {
	0% {opacity: 1;}
	100% {opacity: 0;}
}

@keyframes fadeOut {
	0% {opacity: 1;}
	100% {opacity: 0;}
}
.blog_image_small,
.blog_image_medium,
.blog_image_big {
	height: auto;
}

.blog_image_small,
.blog_video_small, 
.blog_map_small,
.blog_music_small {
	display: block;
	width: 50%;
	margin:0 auto;
}
.blog_image_medium,
.blog_video_medium,
.blog_map_medium,
.blog_music_medium {
	display: block;
	width: 70%;
	margin:0 auto;
}
.blog_image_big,
.blog_video_big,
.blog_map_big,
.blog_music_big {
	display: block;
	width: 100%;
	margin:0 auto;
}

.share-blog-post-icons {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	margin-left: 0px;
}

 .share-blog-post-icons .SocialMediaShareButton {
	margin:0 4px;
	cursor: pointer;
	transition: 0.2s;
}
 .share-blog-post-icons .SocialMediaShareButton:hover {
 	-webkit-transform: scale(1.1);
 	        transform: scale(1.1)
 }
@media (max-width: 779px) {
	.blog-post-author {
		-webkit-flex-wrap: wrap;
		        flex-wrap: wrap;
	}
	.share-blog-post-icons {
		margin-left: 0;
		margin-top: 10px;
	}
	#blogPost .blog-post-title h1 {
		font-size: 35px!important;
	}
	#blogPost .blog-post-title h2 {
		font-size: 35px!important;
	}
	#blogPost .blog-post-title h3 {
		font-size: 30px!important;
	}
	#blogPost .blog-post-title h4 {
		font-size: 20px!important;
	}
	.blog-post-hero-image {
		height: auto;
	}
	.blog-post-content {
		margin:0;
		padding:20px;
	}
	#blogPost .blog-post-title {
		margin:20px 0 20px 0!important;
		padding:0 20px!important;
	}
	.blog-3 .blog-card .blog-card-description {
		padding:20px!important;
	}
	.blog-card .blog-card-description h1 {
		font-size: 21px!important;
	}
	.blog-3 .blog-card .blog-card-description p {
		display: none!important;
	}
	.blog-3 .blog-card,
	.blog-2 .blog-card,
	.blog-1 .blog-card {
		width: 100%;
		margin-bottom: 20px!important;
	}
	#blogPost.blogpost-2 .blog-post-title {
		padding:20px;
	}
	.megadraft-editor .sidebar__menu {
		left: -30px!important;
	}

	.blog_image_small,
	.blog_video_small, 
	.blog_map_small,
	.blog_music_small {
		width: 100%;
	}
	.blog_image_medium,
	.blog_video_medium,
	.blog_map_medium,
	.blog_music_medium {
		display: block;
		width: 100%;
		margin:0 auto;
	}
	.blog_image_big,
	.blog_video_big,
	.blog_map_big,
	.blog_music_big {
		width: 100%;
	}

	#blogPost .save-article-button {
		    margin-left: 0px;
    position: absolute;
    top: 39px;
		/*padding-top: 40px;*/
	}
	#blogPost .design-block-toolbar {
		top: 0;
		z-index: 22222!important;
	}
	.edit-body-mode #blogPost {
		padding-top: 50px!important;
	}
	.edit-body-mode #blogPost.blog-post-2 {
		padding-top: 0!important;
	}
	#blogPost.blog-post-3 .blog-post-hero {
	    -webkit-flex-direction: column;
	            flex-direction: column;
	}

	#blogPost.blog-post-3 .blog-post-title {
	    margin-bottom: 30px;
	    margin-top: 30px;
	    width: 100%;
	}
	#blogPost.blog-post-3 .blog-post-hero-image {
	    width: 100%;
	    height: 250px;
	    margin-top: 0px;
	}
	#blogPost.blog-post-3 .blog-post-title h1 {
	    font-size: 35px;
	}

}



.blank-page-container,
#blogContainer {
	min-height: calc(100vh - 120px);
}
/**BLOG PAGE**/
.nav-2 #blogContainer {
	/*padding-top: 20px;*/
}
#blogContainer {
	display: block;
}
#blogContainer .cat-filter-nav {
	margin:50px 0;
}
.text-white .subscribe-to-content {
	color: #fff;
	background: rgba(0,0,0,0.4);
	border: solid 1px rgba(255,255,255,0.4);
	border-left: solid 3px rgba(255,255,255,0.4);
}

.text-white .subscribe-to-content h1,
.text-white .subscribe-to-content p {
	color: #fff!important;
}
.subscribe-to-content {
	width: 100%;
	margin:60px auto;
	padding:30px;
	color: #333;
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
	background: rgba(255,255,255,0.4);
	border-left: solid 3px #333;
}

.subscribe-to-content h1 {
	font-size: 34px;
	margin:0;
}
.subscribe-to-content p {
	font-size: 18px;
	margin:10px 0!important;
}

.subscribe-to-content-form {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	margin-top: 20px;
}

.subscribe-to-content-form input {
	width: calc(50% - 80px);
	height: 60px;
	background: #f3f2f2;
	border:none;
	font-size: 16px;
	padding:0 20px;
	outline: none!important;
	transition: 0.2s;
}
.subscribe-to-content-form input:focus {
	background: #fff;
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}
.subscribe-to-content-form input::-webkit-input-placeholder {
	font-size: 16px;
	color: #333;
}
.subscribe-to-content-form input:-ms-input-placeholder {
	font-size: 16px;
	color: #333;
}
.subscribe-to-content-form input::placeholder {
	font-size: 16px;
	color: #333;
}
.subscribe-to-content-form button {
	border:none;
	text-transform: uppercase;
	font-size: 15px;
	outline: none;
	width: 120px;
	background: #333;
	height: 60px;
	color: #fff;
	cursor: pointer;
	line-height: 60px;
	transition: all 0.2s;
}
.subscribe-to-content-form button:hover {
	-webkit-transform: scale(1.05);
	        transform: scale(1.05);
}
.subscribe-to-content-form button:focus {
	-webkit-transform: scale(1);
	        transform: scale(1);
}
.text-white .subscribe-to-content-form button {
	background: #333;
	color: #fff;
}

.sub-comments {
	width: 100%;
	max-width: 1400px;
	margin:50px auto;
	padding-top: 50px;
	padding-bottom: 50px;
}

.sub-comments h1 {
	font-size: 18px;
	margin:20px 0;
	padding:20px 0;
	border-bottom: solid 1px rgba(0,0,0,0.3);
}

.sub-comment-avatar,
.sub-comment-item .initials-box {
	width: 60px;
	height: 60px;
	object-fit: contain;
	border-radius: 50%;
	position: absolute;
	left: 0;
	top: 20px;
}

.sub-comment-content {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	padding:0 20px;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
}

.sub-comment-content h4 {
	font-size: 18px;
	margin:0;
}

.sub-comment-content span {
	font-size: 14px;
	opacity: 0.5;
}

.sub-comment-content p {
	font-size: 16px;
	margin:10px 0;
}
.comment-reply {
	font-weight: bold;
	font-size: 14px;
	margin-bottom: 10px;
	cursor: pointer;
}
.comment-reply:hover {
	text-decoration: underline;
}
.text-white .comment-reply {
	color: #fff;
}
.sub-comment-item {
	width: 100%;
	position: relative;
	margin:20px 0;
	padding:20px 0;
	padding-left: 60px;
	border-bottom: solid 1px #efefef;
}

.sub-comment-item textarea {
	width: 100%;
	min-height: 60px;
	border-radius: 10px;
	background: #f3f2f2;
	border:none;
	outline: none;
	padding: 17px;
	font-size: 16px;
}
.sub-comment-item textarea::-webkit-input-placeholder {
	font-size: 16px;
	color: #333;
}
.sub-comment-item textarea:-ms-input-placeholder {
	font-size: 16px;
	color: #333;
}
.sub-comment-item textarea::placeholder {
	font-size: 16px;
	color: #333;
}
.sub-comment-item textarea:focus {
	background: #fff;
	border:solid 1px #333;
}

.sub-comment-item button {
	border:none;
	outline: none;
	font-size: 14px;
	padding:10px 20px;
	background: #333;
	color: #fff;
	text-transform: uppercase;
	border-radius: 6px;
	margin-top: 10px;
	transition: 0.2s;
}
.sub-comment-item button:hover {
	-webkit-transform: scale(1.05);
	        transform: scale(1.05);
}
.sub-comment-item .sub-comment-item {
	border-top: solid 1px #efefef;
}

.sub-comment-item .sub-comment-item:last-of-type {
	border-bottom: none;
	padding-bottom: 0;
	margin-bottom: 0;
}

.sub-comment-item.reply-box {
	border-bottom: none;
}

@media (min-width: 779px) {
	.blog-2 .blog-grid-container {
		-webkit-flex-direction: column;
		        flex-direction: column;
	}
	.blog-2 .blog-card {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		width: 100%;
		margin-bottom: 60px;
		border-color: transparent;
		border-radius: 0;
	}
	.blog-2 .blog-card .blog-card-image {
		width: 300px;
		height: 300px;
	}
	.blog-2 .blog-card .blog-card-description {
		width: calc(100% - 300px);
	    display: -webkit-flex;
	    display: flex;
	    -webkit-flex-direction: column;
	            flex-direction: column;
	    -webkit-justify-content: center;
	            justify-content: center;
	}
	.blog-2 .blog-card .blog-card-description h1 {
		font-size: 30px;
	}
	.blog-2 .blog-card .blog-card-image:before {
		padding-top: 100%;
	}
	.blog-2 .blog-card .blog-card-image img {
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
	}
	.blog-9 .blog-grid-container {
		max-width: 1200px;
	}
	.blog-9 .blog-card:first-of-type {
		width: 100%;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
		padding-bottom: 50px;
		margin-bottom: 50px;
		border-bottom: solid 1px rgba(0,0,0,0.1)!important;
	}
	.blog-9 .blog-card:first-of-type .blog-card-image {
		width: 50%;
		-webkit-flex-shrink: 0;
		        flex-shrink: 0;
	}
	.blog-9 .blog-card:first-of-type .blog-card-description {
		padding:30px;
	}
	.blog-9 .blog-card:first-of-type .blog-card-description h1 {
		font-size: 40px;
	}

}
.blog-9 .blog-card {
	border:none;
	border-radius: 0;
	box-shadow: none!important;
}
.blog-9 .blog-card:first-of-type .blog-card-image:before {
	background: transparent;
	padding-top: 100%;
}

.blog-9 .blog-card .blog-card-image:before {
	background: transparent;
	padding-top: 66.666%;
}

.blog-9 .blog-card .blog-card-description {
	padding:30px 0;
}
.blog-3 .blog-card:first-of-type {
	width: 100%;
}
.blog-3 .blog-card:first-of-type .blog-card-description {
	padding:60px;
}
.blog-3 .blog-card:first-of-type .blog-card-description h1 {
	font-size: 40px;
}
.blog-3 .blog-card:first-of-type .blog-card-description p {
	font-size: 18px;
	opacity: 0.7;
}
.blog-3 .blog-card {
	position: relative;
}
.blog-3 .blog-card .blog-card-image:before {
	background: linear-gradient(to bottom left, transparent 20%, rgba(0,0,0,0.2) 100%);
	padding-top: 66.666%;
}
.blog-3 .blog-card-description {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 100%;
	left: 0;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	color:#fff;
	z-index: 4;
}
.blog-3 .blog-card-description p {
	display: none!important;
}
.blog-3 .blog-card .blog-card-description span {
	position: absolute;
	top: 30px;
	left: 30px;
}
.blog-3 .blog-card .blog-card-description p {
	margin:0;
}
.blog-4.nav-2 #blogContainer {
	padding-top: 0;
}
.blog-4 .blog-card:nth-child(1),
.blog-4 .blog-card:nth-child(2) {
	width: 50%;
}
.blog-4 .blog-card .blog-card-image:before {
	padding-top: 80%;
}
.blog-4 .blog-grid-container {
	width: 100%;
	max-width: 1350px;
	padding:0;
}
.blog-4 .blog-card {
	width: 33.33%;
	border-radius: 0;
	margin-bottom: 1px;
}
.blog-4 .blog-card .blog-card-description {
	padding:30px;
}
.blog-4 .blog-card .blog-card-description h1 {
	font-size: 30px;
}
.blog-4 .blog-card .blog-card-description p {
	font-size: 18px;
}
.blog-4 .blog-card {
	position: relative;
}
.blog-4 .blog-card .blog-card-image:before {
	background: linear-gradient(to bottom left, transparent 20%, rgba(0,0,0,0.4) 100%);
}
.blog-4 .blog-card-description {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 100%;
	left: 0;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	color:#fff;
	z-index: 4;
}
.blog-4 .blog-card .blog-card-description span {
	position: absolute;
	top: 30px;
	left: 30px;
}
.blog-4 .blog-card .blog-card-description p {
	margin:0;
	width: 95%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}
.blog-card:hover .hover-box {
	opacity: 1;
	visibility: visible;
	background: rgba(255,255,255,0.3);
}


.blog-grid-container {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	width: 100%;
	max-width: 1000px;
	margin:0 auto;
	margin-top: 0px;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	padding:50px 40px;
}
.blog-card {
	width: calc(50% - 20px);
	border:solid 1px #efefef;
	border-radius: 6px;
	overflow:hidden;
	margin-bottom: 40px;
	transition: all 0.3s;
	box-shadow: 0 10px 20px rgba(0,0,0,0.0);
	cursor: pointer;
	position: relative;
	opacity: 0;
	-webkit-animation: blogCardIn 0.6s forwards ease;
	        animation: blogCardIn 0.6s forwards ease;
}
.blog-card:nth-child(2) {
	-webkit-animation-delay: 0.2s;
	        animation-delay: 0.2s;
}
.blog-card:nth-child(3) {
	-webkit-animation-delay: 0.4s;
	        animation-delay: 0.4s;
}
@-webkit-keyframes blogCardIn {
	0% {-webkit-transform:scale(0.9);transform:scale(0.9); opacity: 0;}
	100% {-webkit-transform:scale(1);transform:scale(1); opacity: 1;}
}
@keyframes blogCardIn {
	0% {-webkit-transform:scale(0.9);transform:scale(0.9); opacity: 0;}
	100% {-webkit-transform:scale(1);transform:scale(1); opacity: 1;}
}
.blog-5 .blog-grid-container {
	max-width: 1200px;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
}
.blog-5 .blog-card .blog-card-image:before {
	padding-top: 100%;
	background: none;
}
.blog-5 .blog-card {
	width: calc(33.333% - 10px);
	border:none;
	padding:20px;
	background: #fff;
	font-size: 15px;
	margin-bottom: 20px;
	margin-left: 5px;
	margin-right: 5px;
	border-radius: 0;
}
.blog-5 .text-white .blog-card {
	background: rgba(255,255,255,0.15)
}
.blog-5 .blog-card .blog-card-description {
	padding:20px 0 0 0;
}
.blog-5 .blog-card .blog-card-description p {
	opacity: 0.5;
	width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}

.blog-6.edit-body-mode #blogContainer {
	overflow: auto;
}
.blog-6 .cat-filter-nav {
    width: auto;
    padding: 0;
    background: #fff;
    border-radius: 0px;
    box-shadow: 6px 6px 12px rgba(0,0,0,0.1);
    margin: 0 0 0 30px!important;
}
.blog-6 .cat-filter-nav div {
	padding:10px 20px;
}

.blog-6 #blogContainer {
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-flex-wrap: nowrap;
	        flex-wrap: nowrap;
	display: -webkit-flex;
	display: flex;
	height: 100%;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-animation: none;
	        animation: none;
	opacity: 1;
}
.blog-6 .gallery-title-wrapper {
	width: 400px;
	-webkit-flex-shrink: 0;
	        flex-shrink: 0;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-align-content: center;
	        align-content: center;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}
.blog-6 .gallery-title {
    text-align: left;
    padding: 40px;
    width: 100%;
}
.blog-6 .blog-grid-container {
	-webkit-flex-wrap: nowrap;
	        flex-wrap: nowrap;
	padding:0;
	-webkit-flex-shrink: 0;
	        flex-shrink: 0;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	margin:0;
}
.blog-6 .blog-card {
	-webkit-flex-shrink: 0;
	        flex-shrink: 0;
	width: 33.333%;
	max-width: 500px;
	min-width: 300px;
	margin:0;
	border-radius: 0;
	height: 100%;
	border:none;
	transition: 0.4s;
	-webkit-animation: fadeInRight 1s forwards;
	        animation: fadeInRight 1s forwards;
}
.blog-6 .blog-card:nth-child(2) {
	-webkit-animation-delay: 0.2s;
	        animation-delay: 0.2s;
}
.blog-6 .blog-card:nth-child(3) {
	-webkit-animation-delay: 0.4s;
	        animation-delay: 0.4s;
}
.blog-6 .blog-card:nth-child(4) {
	-webkit-animation-delay: 0.6s;
	        animation-delay: 0.6s;
}
.blog-6 .blog-card .blog-card-image img {
	transition: 0.9s;
}
.blog-6 .blog-card:hover .blog-card-image img  {
	-webkit-transform: scale(1.1);
	        transform: scale(1.1);
}
.blog-6 .blog-card .blog-card-image {
	height: 100%;
}
.blog-6 .blog-card .blog-card-image:before {
	height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: linear-gradient(135deg, rgba(0,0,0,0) 0%,rgba(0,0,0,0.2) 100%);
}

.blog-8 .layout-preview-footer,
.blog-6 .layout-preview-footer {
	position: fixed;
}
.blog-6 .blog-card .blog-card-image:after {
	z-index: 30;
}
.blog-6 .blog-card .blog-card-description {
	padding: 30px;
    position: absolute;
    z-index: 23;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    display: -webkit-flex;
    display: flex;
}

.blog-6 .blog-card .blog-card-description h1 {
    padding: 0px;
    color: #fff;
}
.blog-6 .blog-card:hover .blog-card-description:before {
	font-size: 90px;
	opacity: 0.1;
}
.blog-6 .blog-card .blog-card-description:before {
	content: "01";
    position: absolute;
    right: 23px;
    bottom: 45px;
    font-size: 80px;
    color: #fff;
    opacity: 0.2;
    font-weight: bold;
    color: #fff;
    font-family: Oswald;
    transition: 0.3s;
}
.blog-6 .blog-card:nth-child(2) .blog-card-description:before {
	content: "02"
}
.blog-6 .blog-card:nth-child(3) .blog-card-description:before {
	content: "03"
}
.blog-6 .blog-card:nth-child(4) .blog-card-description:before {
	content: "04"
}
.blog-6 .blog-card:nth-child(5) .blog-card-description:before {
	content: "05"
}
.blog-6 .blog-card:nth-child(6) .blog-card-description:before {
	content: "06"
}
.blog-6 .blog-card:nth-child(7) .blog-card-description:before {
	content: "07"
}
.blog-6 .blog-card:nth-child(8) .blog-card-description:before {
	content: "08"
}
.blog-6 .blog-card:nth-child(9) .blog-card-description:before {
	content: "09"
}
.blog-6 .blog-card:nth-child(10) .blog-card-description:before {
	content: "10"
}
.blog-6 .blog-card:nth-child11 .blog-card-description:before {
	content: "11"
}
.blog-6 .blog-card:nth-child(12) .blog-card-description:before {
	content: "12"
}
.blog-6 .blog-card:nth-child(13) .blog-card-description:before {
	content: "13"
}
.blog-6 .blog-card:nth-child(14) .blog-card-description:before {
	content: "14"
}
.blog-6 .blog-card:nth-child(15) .blog-card-description:before {
	content: "15"
}
.blog-6 .blog-card:nth-child(16) .blog-card-description:before {
	content: "16"
}

.blog-6:not(.edit-body-mode) #blogContainer,
.blog-7:not(.edit-body-mode) #blogContainer {
	height: calc(100vh - 80px);
	overflow: auto;
}

.blog-6.nav-2:not(.edit-body-mode) #blogContainer,
.blog-7.nav-2:not(.edit-body-mode) #blogContainer {
	height: calc(100vh - 0px);
}



.blog-7 #blogContainer {
	height: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}
.blog-7.edit-body-mode #blogContainer {
	overflow: auto;
}
.blog-7 .gallery-title-wrapper {
	width: 100%;
	text-align: left;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	padding-bottom: 80px;
	padding-top: 40px;
}
.blog-7 .gallery-title {
    text-align: left;
    padding: 40px;
    width: 100%;
    position: relative;
    z-index: 20;
    padding: 50px 80px;
    margin: 0;
}
.blog-7 .blog-grid-container {
	-webkit-flex-wrap: nowrap;
	        flex-wrap: nowrap;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
    max-width: none;
    max-width: initial;
    margin: 0;
    padding: 0 0 0 60px;
    width: 100%;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}
.blog-7 #blogContainer .cat-filter-nav {
	    margin: 0;
    width: auto;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    padding-left: 80px;
}
.blog-7 .blog-card .blog-card-image:before {
	display: none;
}
.blog-7 .blog-card .blog-card-image {
	width: 100%;
	height: 100%;
	transition: 0.4s;
}
.blog-7 .blog-card:hover .blog-card-image {
	-webkit-filter: none;
	        filter: none;
}
.blog-7 .blog-card:hover .blog-card-image {
	-webkit-transform: scale(0.9);
	        transform: scale(0.9)
}
.blog-7 .blog-card .blog-card-description {
	position: absolute;
    bottom: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    padding: 10px;
}

.blog-7 .blog-card .blog-card-description p {
	opacity: 0;
	visibility: hidden;
	-webkit-transform: translateY(-20px);
	        transform: translateY(-20px);
	transition: 0.4s;
	font-size: 14px;
	padding:0 10px;
}
.blog-7 .blog-card .blog-card-description h1 {
	font-size: 20px;
	transition: 0.4s;
}

.blog-7 .blog-card:hover .blog-card-description p {
	-webkit-transform: none;
	        transform: none;
	opacity: 0.6;
	visibility: visible;
}

.blog-7 .blog-card:hover .blog-card-description h1 {
	-webkit-transform: translateX(10px);
	        transform: translateX(10px);
}

.blog-7 .blog-card .blog-card-description span {
	display: none;
}
.blog-7 .blog-card {
	width: 250px;
	height: 300px;
	margin-bottom: 0;
	-webkit-flex-shrink: 0;
	        flex-shrink: 0;
	margin-right: 50px;
    border-radius: 0;
    overflow: visible;
    border: none;
}

.blog-7 .blog-card:after {
	width: 100%;
	height: 100vh;
	position: absolute;
	bottom:0;
	left: 0;
	content: " ";
	border-left: solid 1px rgba(0,0,0,0.05);
	border-right: solid 1px rgba(0,0,0,0.05);
}

.blog-7 .blog-card:nth-child(odd) {
	height: 250px;
}

.blog-8 #blogContainer {
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-flex-wrap: nowrap;
	        flex-wrap: nowrap;
	display: -webkit-flex;
	display: flex;
	height: auto;
	-webkit-justify-content:center;
	        justify-content:center;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	-webkit-animation: none;
	        animation: none;
	opacity: 1;
}
.blog-8 .gallery-title-wrapper {
	width: 400px;
	-webkit-flex-shrink: 0;
	        flex-shrink: 0;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-align-content: center;
	        align-content: center;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	position: -webkit-sticky;
	position: sticky;
	top: 80px;
	height: calc(100vh - 80px);
}
.blog-8.nav-2 .gallery-title-wrapper,
.blog-8.nav-2 .blog-card .blog-card-image {
	height: 100vh;
}


.blog-8.edit-body-mode .gallery-title-wrapper,
.blog-8.edit-body-mode .blog-card .blog-card-image {
	height: calc(100vh - 130px);
}
.blog-8.edit-body-mode.nav-2 .gallery-title-wrapper,
.blog-8.edit-body-mode.nav-2 .blog-card .blog-card-image {
	height: calc(100vh - 50px);
}

.blog-8.edit-body-mode .gallery-title-wrapper {
	-webkit-transform: translateY(-80px);
	        transform: translateY(-80px);
}

.blog-8 .blog-card .blog-card-image:after {
	left: auto;
	top: auto;
	bottom: 0;
	right: 0;
	width: 120px;
	height: 120px;
	-webkit-transform: none;
	        transform: none;
	opacity: 1;
	line-height: 120px;
	background: #333;
	color: #fff;
	border-radius: 0;
	transition: 0.3s;
}


.blog-8 #blogContainer .cat-filter-nav div {
	padding:5px 10px;
	border:solid 1px rgba(255,255,255,0.4);
	border-radius: 20px;
	margin:0 3px 3px 3px;
}

.blog-8 #blogContainer .cat-filter-nav div:after {
	display: none;
}

.blog-8 #blogContainer .cat-filter-nav div.active,
.blog-8 #blogContainer .cat-filter-nav div:hover {
	border:solid 1px rgba(255,255,255,1);
}

.blog-8 .blog-card:hover .blog-card-image:after {
	font-size: 30px;
	width: 100px;
	height: 100px;
	line-height: 100px;
	bottom: 10px;
	right: 10px;
}

.blog-8 .gallery-title {
    text-align: left;
    padding: 40px;
    width: 100%;
}
.blog-8 #blogContainer .cat-filter-nav {
	height: 163px;
    position: absolute;
    bottom: 0;
    background: rgba(0,0,0,0.8);
    color: #fff;
    margin: 0;
}
.blog-8 .blog-card .blog-card-description {
	position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 200px);
    max-width: 500px;
    background: #fff;
    color: #333;
    padding:50px;
}
.blog-8 .blog-card .blog-card-description p {
	width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    display: block;
}
.blog-8 .blog-card .blog-card-description span {
	display: none;
}
.blog-8 .blog-card .blog-card-image {
	height: calc(100vh - 80px);
	min-height: 500px;
	width: 100%;
	background: inherit;
	color:inherit;
}
.blog-8 .blog-card .blog-card-image:before {
	display: none;
}
.blog-8 .blog-card .blog-card-image img {
	height: 100%;
	transition: 0.4s;
}
.blog-8 .blog-card:hover .blog-card-image img {
	-webkit-transform:scale(1.1);
	        transform:scale(1.1);
}
.blog-8 .blog-grid-container {
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	padding:0;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	-webkit-flex-shrink: 0;
	        flex-shrink: 0;
	margin:0;
	width: calc(100% - 400px);
	margin:0;
	background: inherit;
	max-width: none;
	max-width: initial;
}

.blog-8 .blog-card {
	width: 100%;
	border:none;
	border-radius: 0;
	margin:0;
	background: inherit;
	color: inherit;
	box-shadow: none!important;
}

.text-white .blog-card {
	border-color: rgba(255,255,255,0.3);
}
.blog-card:hover {
	box-shadow: 0 10px 20px rgba(0,0,0,0.05);
}

.blog-card .blog-card-image {
	width: 100%;
	position: relative;
}
.blog-card .blog-card-image img {
	position: absolute;
	width: calc(100% - 0px);
	height: calc(100% - 0px);
	left: 0px;
	object-fit: cover;
	top: 0px;
}
.blog-card .blog-card-image:before {
  content: "";
  display: block;
  padding-top: 90%;
  position: relative;
  z-index: 2;
  transition:all 0.2s;
  background: linear-gradient(to bottom left, transparent 20%, rgba(0,0,0,0) 100%);
}
.blog-card .blog-card-image:after {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	content: "+";
	text-align: center;
	line-height: 40px;
	color: #333;
	background: #fff;
	position: absolute;
	top: calc(50% - 20px);
	left: calc(50% - 20px);
	font-size: 22px;
	z-index: 4;
	-webkit-transform: translateY(30px);
	        transform: translateY(30px);
	opacity: 0;
	transition: all 0.3s;
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
}
.blog-card:hover .blog-card-image:after {
	-webkit-transform: none;
	        transform: none;
	opacity: 1;
}
.blog-card .blog-card-description {
	padding:30px;
	text-align: left;
}
.blog-card .blog-card-description h1 {
	margin:0;
	padding:0;
	font-weight: 700;
	font-size: 22px;
	overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.blog-card .blog-card-description p {
	margin-top: 5px;
	overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical
}
.blog-card .blog-card-description span {
	font-size: 12px;
	text-transform: uppercase;
	opacity: 1;
}
.no-padding .blog-card {
	box-shadow: 0 10px 20px rgba(0,0,0,0.05);
}
.no-padding .blog-card:hover {
	box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}

.no-padding .blog-card .blog-card-image img {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0px;
	object-fit: cover;
	top: 0px;
}
.blog-card.col-30 {
	width: calc(33.333% - 20px);
}
body #articleContent.blog-article {
	height: 60vh;
	min-height: 600px;
}
body #articleContent.blog-article .article-content {
	height: 100%;
	-webkit-justify-content: center;
	        justify-content: center;
	padding:0 60px;
}
body #articleContent.blog-article .article-content .article-stats {
	margin-bottom: 0;
	-webkit-flex-direction: row;
	        flex-direction: row;
}
body #articleContent.blog-article .article-image {
	padding: 0 80px 0px 40px;
}
.blog-text-content {
	font-size: 19px;
	line-height: 30px;
	width: 100%;
	max-width: 800px;
	padding:30px;
	margin:0 auto;
}
.blog-text-content img {
	width: 100%;
	max-width: 500px;
	margin:50px auto;
	display: inherit;
}
.blog-text-content p {
	margin-bottom: 40px;
}
.blog-text-content:first-letter {
	
}
.quote {
	width: 100%;
	max-width: 400px;
	margin:20px auto;
	text-align: center;
	font-size: 26px;
	border-top: solid 1px #efefef;
	border-bottom: solid 1px #efefef;
	padding:40px 0;
	font-style: italic;
}
#blogPage {
	/*height: 70vh;*/
	height: calc(100% - 180px);
	margin-top: 80px;
	min-height: 600px;
	position: relative;
}
.blog-grid-container.no-padding {
	margin-top: 50px;
}
#blogPage .nav-bar {
	background: #fff;
}
.cover-4 #blogPage .cover-photos {
	height: 100%;
}
.section-title {
	width: 100%;
	text-align: center;
	font-size: 20px;
	position: relative;
	font-weight: 700;
	margin-bottom: 50px;
	margin-top: 0;
}
.section-title:before {
	height: 1px;
	width: 500px;
	position: absolute;
	background: #efefef;
	content: " ";
	left: calc(50% - 250px);
	top: 14px;
}
.section-title .see-all {
	font-size: 15px;
	text-transform: uppercase;
	position: relative;
	font-weight: 400;
}
.section-title span {
	position: relative;
	z-index: 2;
	background: #fff;
	padding:0 20px;
}
.section-title .see-all:before {
	height: 1px;
    width: 0%;
    bottom: 0px;
    transition: all 0.2s ease;
    left: 50%;
    background: #000;
    content: " ";
    position: absolute;
}
.section-title .see-all:hover:before {
	left: 0%;
    width: 100%;
}

.blog-6 .blog-card .blog-card-description p,
.blog-6 .blog-card .blog-card-description span {
	display: none;
}
/**BLOG PAGE END**/

.audio-article-wrapper {
	width: 100%;
	margin:40px 0 0 0;
}
.audio-article-wrapper h4 {
	font-size: 16px!important;
	font-weight: 600!important;
	margin:0 0 5px 0;
	width: 100%;
	text-align: center;
}
.audio-article-container {
	width: 100%;
	max-width: 500px;
	margin:0 auto;
	height: 50px;
	border:solid 1px #efefef;
	display: -webkit-flex;
	display: flex;
	border-radius: 40px;
	box-shadow: 0 8px 16px rgba(0,0,0,0.05);
	transition: 0.3s;
	overflow: hidden;
}
.audio-article-container:hover {
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}
.audio-article-controls {
	display: -webkit-flex;
	display: flex;
	width:50%;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	border-right:solid 1px #efefef;
	-webkit-align-items: center;
	        align-items: center;
	padding:0 40px;
}

.audio-article-controls img {
	width: 20px;
	height: 20px;
	object-fit: contain;
	cursor: pointer;
	transition: 0.1s;
}
.audio-article-controls img:hover {
	-webkit-transform: scale(1.15);
	        transform: scale(1.15);
}

.audio-article-timing {
	width: 50%;
	display: -webkit-flex;
	display: flex;
}

.audio-article-timing div {
	width: 50%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	font-size: 20px;
	border-right: solid 1px #efefef;
}
.audio-article-timing div:last-child {
	border-right: none;
	cursor: pointer;
}
.audio-article-timing div:last-child:hover {
	background: #fafafa;
}
.audio-article-timing div span {
	font-size: 12px;
	opacity: 0.5;
	text-transform: uppercase;
}
@media (min-width: 779px) {
	
	.blog-8 .floating-dropdown-content:before {
		right: auto;
		left: 26px;
	}
	.blog-6 .floating-dropdown-flex {
		position: absolute;
	    right: auto;
	    left: 270px;
	    width: auto;
	}
	.blog-8 .floating-dropdown-flex {
	    position: absolute;
	    top: calc(100vh - 250px);
	    z-index: 200;
	    -webkit-justify-content: flex-start;
	            justify-content: flex-start;
	}
	.blog-8 .floating-dropdown-content {
		right: auto;
		left: 0;
	}
}

@media (max-width: 779px) {
	.blog-grid-container {
		margin-top: 0;
		padding:0 20px;
	}
	.blog-card {
		width: 100%!important;
	}
	.blog-text-content {
		padding-top: 0;
	}
	#blogPage {
		height: auto;
		display: block;
	}
	.blog-6 .blog-grid-container {
		height: 70vh;
		width: 100%;
		overflow: auto;
	}
	.blog-6 .blog-card {
		width: 80%!important;
	}
	.blog-6 .cat-filter-nav {
		box-shadow: none;
	}
	.blog-6 #blogContainer {
		overflow: hidden;
	}
	.blog-7 .blog-grid-container {
		padding-left: 30px;
	}
	.blog-7 .gallery-title {
		padding:30px;
	}
	.blog-7 #blogContainer .cat-filter-nav {
		padding-left: 30px;
	}
	.blog-7 .blog-grid-container {
		-webkit-flex-wrap: wrap;
		        flex-wrap: wrap;
	}
	.blog-7 .blog-card {
		width: calc(100% - 30px)!important;
		margin-bottom: 100px;
	}
	.blog-8 #blogContainer {
		-webkit-flex-wrap: wrap;
		        flex-wrap: wrap;
	}
	.blog-8 .gallery-title-wrapper {
		height: auto!important;
		-webkit-transform:none!important;
		        transform:none!important;
		position: relative;
		top: auto;
		padding-bottom: 0;
	}
	.blog-8 .blog-grid-container {
		width: 100%;
	}
	.blog-8 #blogContainer .cat-filter-nav {
		    position: relative;
    height: auto;
    padding: 20px 0;
	}
	.blog-8 .blog-card .blog-card-description {
		width: calc(100% - 50px);
		padding:30px;
	}
	.blog-8 .blog-card .blog-card-image:after {
		display: none;
	}
	.blog-8 .blog-card .blog-card-image {
		height: 400px!important;
	}
	.blog-6 #blogContainer, .blog-7 #blogContainer, .blog-8 #blogContainer {
		height: auto!important;
	}
	.subscribe-to-content-form {
		-webkit-flex-direction: column;
		        flex-direction: column;
	}
	.subscribe-to-content-form input {
		width: 100%;
	}
}
#startupContent {
	min-height: 100%;
	-webkit-align-items: center;
	        align-items: center;
}
.nav-2 #startupContent {
	padding-top: 0;
}

.hero-app-buttons:hover .edit-popup-circle {
	opacity: 1;
	visibility: visible;
	top: 0;
}
.hero-app-buttons {
	position: relative;
	margin-top: 20px;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	transition: all 0.2s;
}
.hero-app-buttons div {
	margin-right: 10px;
	display: inline;
}
.hero-app-buttons div img {
	width: 130px;
	border-radius: 5px;
	box-shadow: 0 4px 8px rgba(0,0,0,0.1);
	cursor: pointer;
	transition: all 0.2s;

}

.modal .hero-app-buttons {
	margin-top: 0px;
	margin-bottom: 10px;
	-webkit-flex-wrap: nowrap;
	        flex-wrap: nowrap;
}

.modal .hero-app-buttons div img {
	display: block;
	width: 100px;
}
.modal .hero-app-buttons:hover {
	background:rgba(255,255,255,0.1);
	border-radius: 6px;
}

.modal .hero-app-buttons:hover div img {
	-webkit-transform: scale(1.1,1.1);
	        transform: scale(1.1,1.1);
}

.hero-app-buttons .input-field {
	-webkit-align-items: center;
	        align-items: center;
	display: -webkit-flex;
	display: flex;
	margin-bottom: 0px;
}
.hero-app-buttons div img:hover {
	-webkit-transform: translateY(-2px) scale(1.05,1.05);
	        transform: translateY(-2px) scale(1.05,1.05);
	box-shadow: 0 4px 8px rgba(0,0,0,0.1), 0 0 0 4px rgba(0,0,0,0.05);
}
.startup-hero {
	width: 100%;
	height: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	position: relative;
	min-height: 667px;
	max-width: 1400px;
	margin:0 auto;
}
.startup-hero-image {
	display: none;
	position: absolute;
	left: 0;
	top: 0;
	bottom:0;
	right: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.startup-text {
	width: 45%;
	padding:50px 70px 50px 70px;
	position: relative;
	z-index: 3;
}
.startup-text h1 {
	font-size: 50px;
	margin:0;
	padding:0;
}

.startup-text p {
	margin:0px 0 0 0;
	padding:0;
	font-size: 18px;
	font-weight: 400;
}

.startup-screens {
	width: 55%;
	height: 100%;
	padding:0 50px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	position: relative;
	-webkit-align-items: center;
	        align-items: center;
}

.startup-screens img {
	width: 100%;
	box-shadow: 0 14px 28px rgba(0,0,0,0.02), 0 10px 10px rgba(0,0,0,0.05);
	display: block;
}
.desktop-scree:hover .hover-box,
.phone-screen-startup:hover .hover-box {
	opacity: 1;
	visibility: visible;
}

.desktop-screen {
	width: 100%;
	padding-top: 25px;
	position: relative;
	background: #efefef;
	border-radius: 5px ;
	z-index: 1;
	box-shadow: 0px 0px 0px 11px #1f1f1f, 0px 0px 0px 13px #191919, 0px 0px 0px 20px #111;
}

.desktop-screen:before {
	position: absolute;
    left: 10px;
    top: -5px;
    color: #D8D8D8;
    content: "...";
    font-size: 60px;
    width: 100%;
    line-height: 0;
}

.startup-screens .desktop-screen:before {
	display: none;
}

.startup-screens .desktop-screen {
	padding-top: 0;
	background: #111;
	-webkit-transform: scale(1.1);
	        transform: scale(1.1);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    box-shadow: 0px 0px 0px 5px #1f1f1f, 0px 0px 0px 6px #191919, 0px 0px 0px 10px #111;
}

.startup-screens .desktop-screen img {
	border-radius: 8px;
}

.edit-body-mode .startup-screens {
	height: auto;
}

.phone-screen-startup {
	width: 150px;
	bottom:0px;
	position: absolute;
	left: -30px;
	padding-top: 0px;
	background: #efefef;
	border-radius: 12px;
	overflow:hidden;
	box-shadow: 0px 0px 0px 5px #1f1f1f, 0px 0px 0px 8px #191919, 0px 0px 0px 13px #111;
	z-index: 2;
}

.phone-screen-startup:before {
	top: 0px;
    content: " ";
    width: 46%;
    height: 20px;
    background-color: #1f1f1f;
    border-radius: 0px 0px 40px 40px;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.phone-screen-startup:before,
.phone-screen-startup:after {
	display: none;
}

.phone-screen-startup:after {
	content: " ";
    top: -1px;
    left: 50%;
    -webkit-transform: translate(-50%, 6px);
            transform: translate(-50%, 6px);
    height: 4px;
    width: 12%;
    background-color: #101010;
    border-radius: 8px;
    box-shadow: inset 0px -1px 1px 0px rgba(255, 255, 255, 0.2);
    position: absolute;
}

.startup-1 .phone-screen-startup {
	box-shadow: 0px 0px 0px 3px #1f1f1f, 0px 0px 0px 5px #191919, 0px 0px 0px 10px #111, -20px -20px 50px rgba(0,0,0,0.2);
	-webkit-transform: translateY(20px);
	        transform: translateY(20px);
}
.startup-1 .phone-screen-startup:before {
	height: 10px;
}
.phone-screen-startup.second {
	display: none;
}

.startup-2 .phone-screen-startup {
	display: none;
}
.startup-3 .desktop-screen-startup {
	display: none;
}
.startup-3 .phone-screen-startup {
	position: relative;
	left: auto;
	bottom: auto;
	width: 260px;
	border-radius: 20px;
	position: relative;
	z-index: 3;
	margin-left: 0px;
}
.startup-3 .phone-screen-startup.second {
	width: 240px;
	min-height: 500px;
	background: #333;
	display: block;
	margin-left: -50px;
	z-index: 2;
}
.startup-3 .startup-text {
	width: 50%;
	padding:50px 50px 50px 100px;
}
.startup-3 .startup-screens {
	width: 50%;
	padding:0;
}

/*.flex-container .startup-text h1 {
	font-size: 75px;
	margin-bottom: 0;
}
.flex-container .startup-text h2 {
	font-size: 55px;
	margin-bottom: 0;
}
.flex-container .startup-text h3 {
	font-size: 45px;
	margin-bottom: 0;
}
.flex-container .startup-text h4 {
	font-size: 30px;
	margin-bottom: 0;
}*/
.startup-3 .desktop-screen {
	display: none;
}

.startup-4 .startup-screens {
	display: none;
}

.startup-4 .startup-text {
	width: 100%;
	max-width: 600px;
	text-align: center;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	margin:0 auto;
	padding:0 20px;
	color: #fff;
}
.startup-4 .startup-hero-image {
	display: block;
}
.startup-4 .startup-hero:before {
	content: " ";
	width: 100%;
	height: 100%;
	opacity: 0.3;
	background: #000;
	position: absolute;
	z-index: 3;
}

@media (max-width: 779px) {
	#startupContent {
		min-height: 0!important;
		min-height: initial!important;
	}
	.startup-hero {
		-webkit-flex-direction: column;
		        flex-direction: column;
		height: auto!important;
		padding-bottom: 40px!important;
		min-height: 0;
		min-height: initial;
	}
	.startup-text {
		width: 100%!important;
		padding:30px!important;
		min-height: 30vh;
	}
	.startup-screens {
		width: 100%!important;
		padding:0 30px!important;
	}
	.phone-screen-startup {
		width: 120px!important;
		box-shadow: 0px 0px 0px 3px #1f1f1f, 0px 0px 0px 4px #191919, 0px 0px 0px 7px #111;
	}
	.desktop-screen {
		box-shadow: 0px 0px 0px 5px #1f1f1f, 0px 0px 0px 7px #191919, 0px 0px 0px 13px #111
	}
	.startup-screens .desktop-screen {
	padding-top: 0;
	background: #111;
	-webkit-transform: none;
	        transform: none;
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
}
 
	.phone-screen-startup:before {
		height: 10px;
	}
	.phone-screen-startup:after {
		top: -3px;
	}
	.startup-1 .phone-screen-startup {
		width: 90px!important;
		bottom: 0;
		left: 20px;
		box-shadow: 0px 0px 0px 3px #1f1f1f, 0px 0px 0px 4px #191919, 0px 0px 0px 7px #111!important;
	}
	.startup-3 .phone-screen-startup {
		width: 150px!important;
		margin:0 10px!important;
		min-height: 0!important;
		min-height: initial!important;
		border-radius: 10px!important;

	}
	.startup-4 .startup-hero {
		min-height: 0;
		min-height: initial;
		height: calc(100vh - 60px)!important;
	}
	.startup-4 .startup-text {
		height: 100%;
		-webkit-align-items: center;
		        align-items: center;
	}
	.flex-container .startup-text h1 {
	font-size: 40px;
}
.flex-container .startup-text h2 {
	font-size: 30px;
}
.flex-container .startup-text h3 {
	font-size: 20px;
}
}

#experiencePage {
	min-height: 90vh;
}
.experience-2 #experiencePage {
	-webkit-flex-direction: row;
	        flex-direction: row;
    -webkit-flex: auto;
            flex: auto;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    overflow:auto;
    padding-bottom: 80px;
    padding-top: 120px;
    padding-left: 50px;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    height: 100%;
}

.nav-2.experience-2 #experiencePage {
	min-height: 100vh;
}
.experience-blocks {
	position: relative;
}
.experience-2 .experience-blocks {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: nowrap;
	        flex-wrap: nowrap;
	padding-top: 40px;
	width: 100%;
	overflow:auto;
}
.experience-1 .experience-blocks,
.experience-3 .experience-blocks {
	margin-top: 50px;
	text-align: left;
}
.experience-2 #experiencePage h1 {
	width: 100%;
}
.experience-2 #experiencePage .experience-block {
	-webkit-flex-direction: column;
	        flex-direction: column;
	padding:0 20px;
	-webkit-flex-shrink: 0;
	        flex-shrink: 0;
	width: 100%;
	max-width: 350px;
}
.experience-2 .experience-block:last-of-type:after {
	display: none;
}
.experience-2 #experiencePage .experience-block:before {
	left: 22px;
    top: -30px;
}

.experience-2 #experiencePage .experience-block:after {
	left: 22px;
	height: 1px;
	width: 100%;
	top: -18px;
}
.experience-2 #experiencePage .experience-block .date,
.experience-2 #experiencePage .experience-block .description {
	width: 100%;
	padding:0;
	text-align: left!important;
	margin:0;
	margin-top: 18px;
}


#experiencePage {
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-flex-direction: column;
	        flex-direction: column;
	transition: all 0.3s ease;
	-webkit-align-items: center;
	        align-items: center;
	padding-top: 70px;
	padding-bottom: 70px;
}
#experiencePage .title-set {
	margin-bottom: 0!important;
	max-width: 700px;
}

.experience-2 #experiencePage .title-set {
	margin-bottom: 80px!important;
	max-width: none;
	max-width: initial;
}
#experiencePage.education-page {
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	padding-left: 200px;
	overflow:hidden;
	height: calc(100vh - 50px);
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}
#experiencePage.animate {
	-webkit-transform:translateY(-50px);
	        transform:translateY(-50px);
	opacity: 0;
}
h1.title {
	font-weight: 100;
	margin:0 0 40px 0;
	padding:0;
}

h1.title span {
	font-weight: bold;
	color: #C4BDAC;
}

#experiencePage .gallery-filter {
	-webkit-justify-content: center;
	        justify-content: center;
}
.experience-2 #experiencePage .gallery-filter {
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
}
.experience-blocks:hover .add-experience {
	opacity: 1;
	visibility: visible;
}

.experience-block {
	-webkit-animation: fadeIn 1s forwards;
	        animation: fadeIn 1s forwards;
}

.experience-block:after {
	-webkit-animation: lineGrow 1s forwards;
	        animation: lineGrow 1s forwards;
}
.experience-block .date {
	-webkit-animation: fromLeft 1s forwards ease;
	        animation: fromLeft 1s forwards ease;
	-webkit-animation-delay: 0.5s;
	        animation-delay: 0.5s;
}

.experience-block .description {
	-webkit-animation: fromRight 1s forwards ease;
	        animation: fromRight 1s forwards ease;
	-webkit-animation-delay: .8s;
	        animation-delay: .8s;
}

.experience-block:nth-of-type(2) .date {
	-webkit-animation: fromRight 1s forwards ease;
	        animation: fromRight 1s forwards ease;
	-webkit-animation-delay: 1s;
	        animation-delay: 1s;
}

.experience-block:nth-of-type(2) .description {
	-webkit-animation: fromLeft 1s forwards ease;
	        animation: fromLeft 1s forwards ease;
	-webkit-animation-delay: 1.2s;
	        animation-delay: 1.2s;
}

.experience-block:nth-of-type(3) .date {
	-webkit-animation: fromLeft 1s forwards ease;
	        animation: fromLeft 1s forwards ease;
	-webkit-animation-delay: 1.5s;
	        animation-delay: 1.5s;
}

.experience-block:nth-of-type(3) .description {
	-webkit-animation: fromRight 1s forwards ease;
	        animation: fromRight 1s forwards ease;
	-webkit-animation-delay: 1.8s;
	        animation-delay: 1.8s;
}

.experience-block .date, .experience-block .description {
	opacity: 0;
}


.experience-block:nth-of-type(2) {
	-webkit-animation-delay: 0s;
	        animation-delay: 0s;
}

.experience-block:nth-of-type(2):after {
	-webkit-animation-delay: 0.8s;
	        animation-delay: 0.8s;
}

.experience-block:nth-of-type(3):after {
	-webkit-animation-delay: 1.6s;
	        animation-delay: 1.6s;
}

@-webkit-keyframes lineGrow {
	0% {-webkit-transform:scale(1,0);transform:scale(1,0);}
	100% {-webkit-transform:scale(1,1);transform:scale(1,1);}
}

@keyframes lineGrow {
	0% {-webkit-transform:scale(1,0);transform:scale(1,0);}
	100% {-webkit-transform:scale(1,1);transform:scale(1,1);}
}

@-webkit-keyframes fromLeft {
	0% {-webkit-transform:translateX(-40px);transform:translateX(-40px); opacity: 0}
	100% {-webkit-transform:translateX(0px);transform:translateX(0px); opacity: 1}
}

@keyframes fromLeft {
	0% {-webkit-transform:translateX(-40px);transform:translateX(-40px); opacity: 0}
	100% {-webkit-transform:translateX(0px);transform:translateX(0px); opacity: 1}
}

@-webkit-keyframes fromRight {
	0% {-webkit-transform:translateX(40px);transform:translateX(40px); opacity: 0}
	100% {-webkit-transform:translateX(0px);transform:translateX(0px); opacity: 1}
}

@keyframes fromRight {
	0% {-webkit-transform:translateX(40px);transform:translateX(40px); opacity: 0}
	100% {-webkit-transform:translateX(0px);transform:translateX(0px); opacity: 1}
}

.experience-block {
	width: 100%;
	opacity: 0;
	transition: all 0.5s ease;;
	max-width: 700px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	padding:50px 0;
	position: relative;
}
.experience-block.add:before {
	background: #5FBF80;
	border: none;
	-webkit-transform: scale(1.9,1.9);
	        transform: scale(1.9,1.9);
	-webkit-animation:pulse 1.7s infinite;
	        animation:pulse 1.7s infinite;
	content: "+";
	color: #fff;
	font-weight: bold;
	text-align: center;
	line-height: 25px;
	cursor: pointer;
}


.experience-block.add .job-title {
	font-weight: 100;
}

@-webkit-keyframes pulse {
	0% {box-shadow:0 0 0 0 rgba(0,0,0,0.2);}
	100% {box-shadow:0 0 0 10px rgba(0,0,0,0);}
}

@keyframes pulse {
	0% {box-shadow:0 0 0 0 rgba(0,0,0,0.2);}
	100% {box-shadow:0 0 0 10px rgba(0,0,0,0);}
}
.experience-block.reverse {
	-webkit-flex-direction: row-reverse;
	        flex-direction: row-reverse;
}

.experience-3 .experience-block.reverse {
	-webkit-flex-direction: row;
	        flex-direction: row;
}

.experience-3 .experience-block.reverse .date {
	padding-right: 50px;
	text-align: right;
	padding-left: 0;
}

.experience-3 .experience-block.reverse .description {
	text-align: left;
	padding-right: 0px;
	padding-left: 50px;
}

.experience-block.reverse .date {
	text-align: left;
	padding-right: 0;
	padding-left: 50px;
}
.experience-block.reverse .description {
	text-align: right;
	padding-right: 50px;
	padding-left: 0px;
}
.experience-block:before {
	width: 25px;
	height: 25px;
	position: absolute;
	content: " ";
	background: #333;
	border-radius: 50%;
	left: calc(50% - 12px);
	top: 50px;
	border: solid 4px #fff;
	box-sizing: border-box;
	box-shadow: 0 0 0 1px rgba(0,0,0,0.1);
	z-index: 1;
}

.experience-block:after {
	width: 1px;
	height: 100%;
	left: 50%;
	top: 0;
	content: " ";
	background: rgba(0,0,0,0.25);
	position: absolute;
	-webkit-transform-origin: 0% 0%;
	        transform-origin: 0% 0%;
	-webkit-transform:scale(1,0);
	        transform:scale(1,0);
}

.experience-block:last-of-type:after {
	height: calc(50% - 50px);
}

.experience-block .date {
	padding-right: 50px;
	font-size: 16px;
}

.experience-block .date, .experience-block .description {
	width: 50%;

}
.experience-block:hover .hover-box {
	visibility: visible;
	opacity: 1;
}
.experience-block .description {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	padding-left: 50px;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    text-align: left;

}
.experience-block .date {
	text-align: right;
}
.experience-block .description .job-title {
	margin:0;
	font-size:30px;
	font-weight: 600;
	padding:0;
}

.experience-block .description span {
	font-size: 14px;
	font-weight: normal;
}

.experience-block .description p {
	font-size: 16px;
    line-height: 21px;
}

@media (max-width: 779px) {
	.experience-block {
		-webkit-flex-direction: column!important;
		        flex-direction: column!important;
		padding:50px 50px!important;
		text-align: left!important;
	}

	.experience-2 .experience-block {
		padding:0px 20px 20px 20px!important;
	}
	.experience-block .date, .experience-block .description {
		width: 100%;
		text-align: left!important;
		padding-left:0!important;
		padding-right: 0!important;
	}
	.experience-block:before {
		width: 20px;
		height:20px;
		left: 15px;
		top: 22px;
	}

	.experience-block:after {
		left: 25px;
		top: 43px;
		height: calc(100% - 20px);
	}
	#experiencePage {
		padding-top:60px!important;
		-webkit-justify-content: flex-start;
		        justify-content: flex-start;
	}
	.experience-2 #experiencePage {
		-webkit-justify-content: space-between;
		        justify-content: space-between;
		padding-bottom: 80px!important;
		-webkit-align-items: flex-start!important;
		        align-items: flex-start!important;
		height: auto!important;
    	min-height: 600px!important;
	}
	#experiencePage .title-set {
		padding:0 20px;
	}
	.experience-2 #experiencePage .title-set {
		margin-bottom: 0!important;
		padding:60px 20px;
	}
	
}
#storeProducts,
#productContent{
	-webkit-animation:fadeIn 0.6s forwards;
	        animation:fadeIn 0.6s forwards;
}

@media (min-width:1025px) {
	#storeProducts.cat-left .cat-filter-nav {
		width: 200px;
		display: block;
		padding-left: 35px;
	}

	#storeProducts.cat-left .product-grid {
		width: calc(100% - 200px);
		margin:0;
	}

	#storeProducts.cat-left {
		-webkit-justify-content: center;
		        justify-content: center;
	}

	#storeProducts.cat-left .cat-filter-nav div:after {
		display: none;
	}
}

.cat-filter-nav-position {
	width: 100%;
	position: absolute;
	top: -30px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	border:none;
	transition: 0.2s;
	-webkit-transform: translateY(5px);
	        transform: translateY(5px);
	opacity: 0;
}

.layout-preview-footer {
	    position: -webkit-sticky;
	    position: sticky;
    bottom: 0px;
    border-radius: 6px 6px 0 0;
    left: 10px;
    width: calc(100vw - 20px);
    height: 50px;
    display: -webkit-flex;
    display: flex;
    padding: 10px;
    background: linear-gradient(0deg, #3c4665 0%, #21283F 100%);
    box-shadow: 0 8px 16px rgba(0,0,0,0.08);
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
    opacity: 0;
    -webkit-animation: previewFooter 0.6s forwards;
            animation: previewFooter 0.6s forwards;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
    z-index: 20;
    -webkit-align-items: center;
            align-items: center;
}

.layout-preview-footer .btn {
	font-size: 14px;
    margin: 0 auto;
    padding: 6px 12px!important;
    border-radius: 6px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    text-transform: uppercase;
}

.layout-preview-footer:before {
	/*width: calc(100% - 8px);
    height: calc(100% - 8px);
    border: dashed 1px #fff;
    position: absolute;
    left: 3px;
    top: 3px;
    content: " ";
    border-radius: 4px;
    opacity: 0.3;*/
}

@-webkit-keyframes previewFooter {
	0% {opacity: 0; -webkit-transform: translateY(20px); transform: translateY(20px);}
	100% {opacity: 1; -webkit-transform: translateY(0px); transform: translateY(0px);}
}

@keyframes previewFooter {
	0% {opacity: 0; -webkit-transform: translateY(20px); transform: translateY(20px);}
	100% {opacity: 1; -webkit-transform: translateY(0px); transform: translateY(0px);}
}

.cat-filter-nav:hover .cat-filter-nav-position {
	opacity: 1;
	-webkit-transform:none;
	        transform:none;
}
.cat-filter-nav-position:after {
	display: none!important;
}
.cat-filter-nav-position .pos-selector {
	width: 30px;
    height: 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 6px;
    color: #333;
    background: #fff;
    font-size: 30px;
    transition: 0.2s;
    border: solid 1px #efefef;
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);
    z-index: 10;
    cursor: pointer;
    margin:0 2px;
}

.cat-filter-nav-position .pos-selector img {
	width: 16px;
    height: 16px;
    object-fit: contain;
    border-radius: 0;
    object-position: unset;
    opacity: 0.5;
}
.cat-filter-nav-position .pos-selector:after {
	display: none!important;
}

.cat-filter-nav-position .pos-selector.active img,
.cat-filter-nav-position .pos-selector:hover img {
	opacity: 1;
}

.cat-filter-nav-position .pos-selector.active {
	background: linear-gradient(-45deg, #3c4665 0%, #21283F 100%);
}
.cat-filter-nav-position .pos-selector.active img {
	-webkit-filter: invert(1);
	        filter: invert(1);
}

.edit-body-mode .cat-filter-nav {
	transition: 0.2s;
	border:dashed 1px transparent;
}

.edit-body-mode .cat-filter-nav:hover {
	border:dashed 1px #ccc;
}

.cat-filter-nav {
	width: 100%;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	overflow: auto;
	display: -webkit-flex;
	display: flex;
	margin-bottom: 20px;
	position: relative;
	z-index: 2;
	overflow: visible;
}

#storeProducts:not(.cat-left) .cat-filter-nav {
	margin-left: auto;
	margin-right: auto;
	max-width: 1200px;
	padding:0 50px;
}

#storeProducts .cat-filter-nav.cat-left {
	padding:0;
	margin-left: 0;
	margin-right: 0;
}
#storeProducts.design-page-align-left .cat-filter-nav:not(.cat-left) {
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
}
#storeProducts.design-page-align-right .cat-filter-nav:not(.cat-left) {
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
}

.cat-filter-nav span {
	opacity: 0.4;
}

.cat-filter-nav div:not(.pos-selector):not(.cat-filter-nav-position) {
	position: relative;
	padding:3px 8px;
	margin:3px 3px;
	transition: 0.2s;
	cursor: pointer;
	opacity: 0.6;
	-webkit-flex-shrink: 0;
	        flex-shrink: 0;
}

.text-white .cat-filter-nav div:after {
	background: #fff;
}

.cat-filter-nav div:after {
	width: 0%;
	bottom: 0px;
	left: 0;
	position: absolute;
	height: 2px;
	background: #333;
	transition: 0.2s;
	content: " ";
}
.cat-filter-nav div:hover,
.cat-filter-nav div.active {
	opacity: 1!important;
}
.cat-filter-nav div.active:after,
.cat-filter-nav div:hover:after {
	width: 100%;
}

.store-button {
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
    color: #fff;
    padding: 10px 20px;
    border-radius: 50px;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    border: none;
    outline: none;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 15px;
}
.store-button:hover {
	background: linear-gradient(-135deg, #28C76F 0%, #28C76F 100%);
}
.store-button img {
	width: 12px;
	display: inline-block;
	margin-right: 15px;
}

.product-item:hover .hover-box {
	opacity: 1;
	visibility: visible;
	transition-delay: 0.3s;
}


#storeProducts {
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	padding:0px 0 50px 0;
}

#storeProducts .gallery-title-wrapper  {
	width: 100%;
	height: 420px;
	max-height: none;
	max-height: initial;
	position: relative;
	margin-bottom: 50px;
	transition: 0.3s;
}

#storeProducts .gallery-title-wrapper.no-image .store-hero-image {
	display: none;
}
#storeProducts .gallery-title-wrapper.no-image  {
	height: auto;
	min-height: 150px;
}
.edit-body-mode #storeProducts .gallery-title-wrapper.no-image:hover {
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}

#storeProducts .gallery-title-wrapper.no-image:before {
	opacity: 0;
}
#storeProducts .gallery-title-wrapper.no-image .gallery-title {
	color: inherit;
}

#storeProducts .gallery-title-wrapper:before {
	position: absolute;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: 0.1;
	content: " ";
	top: 0;
	left: 0;
	z-index: 2;
}

#storeProducts .gallery-title-wrapper .store-hero-image {
	display: none;
}
#storeProducts .gallery-title-wrapper .store-hero-image {
	width: 100%;
	display: block;
	position: absolute;
	height: 100%;
	top: 0;
	left: 0;
	object-fit: cover;
}
#storeProducts .gallery-title-wrapper .gallery-title {
	position: relative;
	z-index: 3;
	color: #fff;
	height: 100%;
	max-width: 1200px;
	-webkit-justify-content: center;
	        justify-content: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

#storeProducts.cat-left .gallery-title-wrapper .gallery-title {
	max-width: 1440px;
}
.category-navigation {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	padding:10px 50px;
	width: 100%;
	max-width: 1200px;
	margin:0 auto;
}
.category-item {
	text-transform: uppercase;
	margin-right: 35px;
	font-size: 14px;
	opacity: 0.3;
	cursor: pointer;
	border-bottom: solid 1px transparent;
}
.category-item:hover,
.category-item.active {
	opacity: 1;
	border-bottom: solid 1px #000;
}
.product-grid {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	width: 100%;
	/*max-width: 1000px;*/
	max-width: 1200px;
	padding:0 40px;
	margin:0 auto;
	position: relative;
}

.product-grid  .add-product-btn-set {
	margin-top: -50px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    top: -60px;
    z-index: 50;

}
.add-product-btn-set .title-badge {
	margin-right: 10px;
	margin-top: 12px;
}
.product-grid .add-product-btn-set .add-photo {
	padding:10px 20px;
	font-weight: normal;
	cursor: pointer;
}

.product-grid .add-product-btn-set .add-photo.empty {
	background: #fff;
	color: #333;
	border:solid 1px #efefef;
	box-shadow: 0 8px 16px rgba(0,0,0,0.05);
	margin-right: 10px;
}
.product-grid .add-product-btn-set .add-photo.empty:hover {
	background: #fafafa;
}

.product-grid .product-item {
	width: calc(33.333% - 20px);
	margin:20px 10px;
	position: relative;
}

.product-grid .product-item-info h4 {
	margin-bottom: 0;
	margin-top: 10px;
	font-size: 23px;
}

.product-grid .product-item-info .product-price .old-price {
	text-decoration: line-through;
	margin-left: 20px;
	font-size: 14px;
}

.product-grid .product-image {
	width: 100%;
	position: relative;
}

.product-grid .product-image:after {
	content: "+";
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border:solid 1px #fff;
	position: absolute;
	left: calc(50% - 15px);
	top: calc(50% - 15px);
	font-size: 20px;
	text-align: center;
	line-height: 40px;
	color: #fff;
	transition: all 0.3s;
	-webkit-transform: translateY(10px);
	        transform: translateY(10px);
	opacity: 0;
	z-index: 4;
}

.special-offer-label {
	position: absolute;
	top: 10px;
	left: 10px;
	font-size: 12px;
	border-radius: 6px;
	z-index: 4;
	text-transform: uppercase;
	padding:5px 10px;
	color: #fff;
	background: #38964c;
}
.product-grid .product-image:before {
	content: " ";
	padding-top: 100%;
	display: block;
	width: 100%;
	transition: all 0.3s;
	background: rgba(0,0,0,0.25);
	position: relative;
	z-index: 3;
	opacity: 0;
}

.image-display-portrait .product-grid .product-image:before {
	padding-top: 140%;
}
.image-display-landscape .product-grid .product-image:before {
	padding-top: 66.666%;
}
.product-grid .product-item:hover .product-image:before,
.product-grid .product-item:hover .product-image:after {
	opacity: 1;
	-webkit-transform:none;
	        transform:none;
}
.product-grid .product-image img {
	position: absolute;
	top: 0;
	left: 0;
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.store-1 .product-grid .product-image img {
	border-radius: 4px;
}
.store-3 .product-grid .product-item  {
	margin:10px;
}
.store-3 .product-grid .product-item-info {
		position: absolute;
		width: 100%;
		height: 100%;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: flex-end;
		        justify-content: flex-end;
		-webkit-align-items: flex-start;
		        align-items: flex-start;
		color: #fff;
		text-align: center;
		z-index: 5;
		top: 0;
		left: 0;
		-webkit-flex-direction: column;
		        flex-direction: column;
		transition: all 0.4s;
		opacity: 1;
		padding:20px;
}

.store-3 .product-grid .product-item-info .grid-price {
	transition: all 0.3s;
	opacity: 0;
	height: 0px;
	font-size: 23px;
}

.store-3 .product-grid .product-item:hover .product-item-info .grid-price {
	transition: all 0.3s;
	opacity: 1;
	height: 25px
}

.store-3 .product-grid .product-image:before {
	opacity: 1;
	background: linear-gradient(to bottom left, transparent 20%, rgba(0,0,0,0.2) 100%)
}

.store-3 .product-grid .product-item:first-of-type {
	width:100%;
}

.store-3 .product-grid .product-item:first-of-type .product-item-info {
	padding:50px;
}

.store-3 .product-grid .product-item:first-of-type .product-item-info h4 {
	font-size: 45px;
}


.store-4 .product-grid .product-item  {
	width: calc(25% - 20px);
	border-radius: 6px;
    overflow: hidden;
    transition: all 0.2s;
    background: #fff;
    box-shadow: 0 5px 10px rgba(0,0,0,0.08);
}
.store-4 .product-grid .product-item:hover {
	box-shadow: 0 5px 10px rgba(0,0,0,0.15);
	-webkit-transform: scale(1.02,1.02);
	        transform: scale(1.02,1.02);
}

.store-4 .product-item-info h4 {
	 margin: 0;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
}
.store-4 .product-item-info .grid-price {
	font-size: 14px;
}

.store-4 .product-item-info {
	padding:20px;
	color: #333;
}

.store-5 .product-grid .product-item  {
	width: calc(20% - 20px);
}

.store-5 .product-grid .product-image img {
	border-radius: 6px;
}
.store-5 .product-grid .product-item-info h4 {
	margin-top: 10px;
	font-size: 16px;
}

.product-grid .product-item-info .grid-price .old-price,
.block-product-item .old-price {
	text-decoration: line-through;
	opacity: 0.6;
	-webkit-transform: scale(0.8);
	        transform: scale(0.8);
	margin-left: 10px;
	color: red;
}



.price-wrapper .grid-price {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column-reverse;
	        flex-direction: column-reverse;
}

.price-wrapper .grid-price  .old-price {
	text-decoration: line-through;
	opacity: 0.6;
	font-size: 20px;
	color: red;
}

.dashboard-image-placeholder {
	width: calc(100% - 40px);
	max-width: 900px;
	margin:20px auto 100px auto;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}
.dashboard-image-placeholder img {
	width: 100%;
	max-width: 400px;
	margin:auto;
}
.empty-store-placeholder {
	width: calc(100% - 40px);
	max-width: 900px;
	margin:20px auto 100px auto;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}
.store-placeholder-title {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	margin-bottom: 20px;
}
.store-placeholder-title h1,
.dashboard-image-placeholder h1 {
	font-size: 25px!important;
	font-weight: 700;
	margin:0!important;
}
.empty-store-placeholder-item {
	width: calc(33.333% - 20px);
	background: #fff;
	box-shadow: 0 8px 16px rgba(0,0,0,0.07);
	border:dashed 1px #efefef;
	border-radius: 6px;
	position: relative;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.empty-store-placeholder-item .add-photo {
	padding:10px 20px;
	font-size: 14px;
	position: absolute;
	transition: all 0.2s;
	opacity: 0;
	-webkit-transform: translateY(10px);
	        transform: translateY(10px);
}
.empty-store-placeholder-item:hover .add-photo {
	opacity: 1;
	-webkit-transform: none;
	        transform: none;
}
.empty-store-placeholder-item:before {
	content: " ";
	padding-top: 100%;
	display: block;
	width: 100%;
}
.music-grid .empty-store-placeholder-item:after {
	display: none;
}
.empty-store-placeholder-item:after {
	width: 70%;
	height: 20px;
	background:#efefef;
	content: " ";
	position: absolute;
	bottom: 20px;
	left: 20px;
	z-index: 2;
}
@-webkit-keyframes productPlaceholder {
	0% {opacity: 0.4};
	25% {opacity: 0.2};
	50% {opacity: 0.4};
	75% {opacity: 0.2};
	100% {opacity: 0.4};
}
@keyframes productPlaceholder {
	0% {opacity: 0.4};
	25% {opacity: 0.2};
	50% {opacity: 0.4};
	75% {opacity: 0.2};
	100% {opacity: 0.4};
}

	.store-2 .product-grid .product-image:after {
		display: none;
	}

	.store-2 .product-grid .product-item {
		width: calc(50% - 20px);
	}
	.store-2 .product-grid .product-item {
		margin:10px;
	}
	.store-2 .product-grid .product-item-info {
		position: absolute;
		width: 100%;
		height: 100%;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-align-items: center;
		        align-items: center;
		color: #fff;
		text-align: center;
		z-index: 5;
		top: 0;left: 0;
		-webkit-flex-direction: column;
		        flex-direction: column;
		transition: all 0.4s;
		opacity: 0;
		-webkit-transform: translateY(20px);
		        transform: translateY(20px);
	}

	.store-2 .product-grid .product-item:hover .product-item-info {
		opacity: 1;
		-webkit-transform: none;
		        transform: none;
	}

	.store-2 .product-grid .product-item-info h4,
	.store-2 .product-grid .product-item-info span,
	.store-3 .product-grid .product-item-info h4,
	.store-3 .product-grid .product-item-info span {
		color: #fff!important;
	}

	.store-2 .product-grid .product-item-info span.old-price,
	.store-3 .product-grid .product-item-info span.old-price {
		color: red!important;
	}

.flex-container#storeProducts .product-item {
	text-align: left;
}
@media (min-width: 778px) {
	.store-3 .product-grid .product-item:first-of-type .product-image:before {
		padding-top: 50%;
	}
}
@media (max-width: 778px) {
	.product-grid .product-item {
		width: 100%!important;
		margin:20px 25px 0px 25px;
	}
	.product-grid {
		padding:0 10px;
		margin-bottom: 50px;
	}
	.product-grid .product-item-info h4 {
		font-size: 21px!important;
		font-weight: 600;
	}
	.product-grid .product-item-info .grid-price {
		font-size: 18px!important;
	}
	.category-navigation {
		padding:10px 0px 10px 20px;
	}
	#storeProducts .gallery-title-wrapper {
		margin-bottom: 20px;
		height: 300px;
	}
	
	.store-3 .product-grid .product-item {
		margin:5px;
	}
	.store-3 .product-grid .product-item-info h4,
	.store-3 .product-grid .product-item:first-of-type .product-item-info h4 {
		font-size: 20px;
	}
	.store-3 .product-grid .product-item .product-item-info {
		padding:15px!important;
	}
	.store-3 .product-grid .product-item .product-item-info .grid-price {
		transition: all 0.3s;
	    /*opacity: 1;
	    height: 25px;*/
	}
	.store-4 .product-grid .product-item,
	.store-5 .product-grid .product-item {
		width: calc(50% - 10px);
	}
	.store-4 .product-item-info {
		padding:20px;
	}
	.store-4 .product-item-info h4 {
		font-size: 16px;
		margin-bottom: 0;
	}
	.store-2 .product-grid .product-item {
		width: calc(50% - 2px);
		margin:1px!important;
	}
	.store-2 .product-grid .product-item .product-item-info {
		/*opacity: 1;
    	transform: none;*/
	}

	.store-2 .product-grid .product-item .product-image:before {
		/*opacity: 1;*/
	}
	.store-3 .product-grid .product-item .product-item-info .grid-price {
		opacity: 1;
    	height: 25px;
	}
	.product-grid .add-product-btn-set {
			width: 60px;
		    height: 50px;
		    position: absolute;
		    top: -40px;
		    right: 0;
		    overflow: visible;
		    -webkit-flex-direction: column;
		            flex-direction: column;
		    -webkit-justify-content: flex-start;
		            justify-content: flex-start;
		    -webkit-align-items: flex-start;
		            align-items: flex-start;
		    -webkit-align-content: flex-start;
		            align-content: flex-start;
		    padding-top: 50px;
		    background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
		    border-radius: 4px 0 0 4px;
	}
	.product-grid .add-product-btn-set:before {
		content: "+";
		width: 100%;
		height: 100%;
		position: absolute;
		right: 0;
		top: 0;
		font-size: 25px;
		color: #fff;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
		-webkit-justify-content: center;
		        justify-content: center;
		font-weight: bold;
	}

	.edit-body-mode .product-grid .add-photo {
		position: relative;
		top: auto;
		right: auto;
		width: 144px;
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-flex-shrink: 0;
		        flex-shrink: 0;
		font-size: 15px;
		border-radius: 0;
		margin:0!important;
		margin-left: -82px!important;
		text-transform: none;
		display: none;
	}
	.edit-body-mode .product-grid .add-product-btn-set:hover .add-photo {
		display: block;
		-webkit-animation: addProductBtn 0.4s forwards;
		        animation: addProductBtn 0.4s forwards;
	}
	.edit-body-mode .product-grid .add-photo img {
		display: none;
	}
	.add-product-btn-set .title-badge {
		display: none;
	}
	#storeProducts .gallery-title-wrapper .gallery-title {
		padding:20px 30px;
		margin-top: 0;
		margin-bottom: 0;
	}
	.product-grid .add-product-btn-set {
		margin-top: 0;
	}
	.flex-container#storeProducts .design-block-toolbar {
		z-index: 2000!important;
	}

	.save-article-button {
		margin-left: 0px;
		position: absolute;
		top: 39px;
	}

	.blog-grid-container .title-badge {
		display: none;
	}
}

@-webkit-keyframes addProductBtn {
	0% {opacity:0; -webkit-transform: translateX(150px); transform: translateX(150px);}
	100% {opacity:1; -webkit-transform: translateX(0px); transform: translateX(0px);}
}

@keyframes addProductBtn {
	0% {opacity:0; -webkit-transform: translateX(150px); transform: translateX(150px);}
	100% {opacity:1; -webkit-transform: translateX(0px); transform: translateX(0px);}
}





#videoContents {
	width: 100%;
	padding:0px 20px;
	margin:0 auto;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

#videoContents .gallery-title-wrapper {
	width: 100%;
	max-width: none;
	max-width: initial;
}

.video-categories {
	width: 200px;
}

.videos-grid {
	width: calc(100% - 200px);
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	margin-bottom: 40px;
}

.videos-grid .video-box {
	width: calc(33.333% - 20px);
    margin: 0 10px 30px 10px;
	cursor: pointer;
	position: relative;
}

.videos-2 .videos-grid .video-box {
	width: calc(50% - 40px);
}

.video-box h4 {
	margin:10px 0 0 0!important;
	font-weight: 600!important;
	font-size: 16px!important;
    width: 100%;
}

.video-box-image {
	width: 100%;
	position: relative;
	overflow:hidden;
	border-radius: 6px;
}

.video-box-image .duration {
	padding:3px 6px;
	background: rgba(0,0,0,0.8);
	font-size: 12px;
	text-transform: none;
	font-style: normal;
	position: absolute;
	bottom: 10px;
	right: 10px;
	border-radius: 4px;
	color: #fff;
	z-index: 7;
}

.video-box-image .play-icon {
	width: 40px;
	position: absolute;
	left: calc(50% - 20px);
	top: calc(50% - 20px);
	z-index: 5;
	opacity: 0;
	-webkit-transform: translateY(10px);
	        transform: translateY(10px);
	transition:all 0.3s;
}

.video-box-image:before {
	padding-bottom: 56.25%;
	content: " ";
	display: block;
	width: 100%;
	position: relative;
	z-index: 4;
	background: #000;
	opacity: 0;
	transition: all 0.3s;
}

.video-box:hover .video-box-image .play-icon {
	opacity: 1;
	-webkit-transform: none;
	        transform: none;
}
.video-box:hover .video-box-image:before {
	opacity: 0.3;
}
.video-box-image .video-thumb {
	width: 100%;
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	border-radius: 6px;
}
.video-categories .add-photo {
	display: inline-block;
	padding:10px 20px;
	font-size: 14px;
	margin-top: 20px;
	opacity: 0.2;

	transition: all 0.2s;
	text-transform: uppercase;
}
.video-categories:hover .add-photo {
	opacity: 1;
}
.empty-store-placeholder-item.video-ph .add-photo {
	z-index: 3;
}
.empty-store-placeholder-item.video-ph:before {
	padding-top: 60%;

}
.empty-store-placeholder-item.video-ph:after {
	 width: 0;
      height: 0;
      border-top: 20px solid transparent;
      border-left: 30px solid #ccc;
      background: transparent;
      border-bottom: 20px solid transparent;
      left: calc(50% - 15px);
      bottom: calc(50% - 20px);
}
.video-categories {
	font-size: 18px;
}

.video-categories .playlist-item {
	padding:5px 20px;
	margin:5px 2px;
	cursor: pointer;
	position: relative;
	opacity: 0.5;
	background: #fff;
	border-radius: 4px;
	box-shadow: 0 4px 8px rgba(0,0,0,0.0);
	border-left: solid 2px transparent;
}

.text-white .video-categories .playlist-item {
	color: #333;
}

.playlist-item .edit-playlist-buttons {
	position: absolute;
	right: 0;
	top: 0;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	-webkit-align-items: center;
	        align-items: center;
	padding-right: 10px;
	opacity: 0;
	visibility: hidden;
	transition: all 0.2s;
	-webkit-transform: scale(0.8,0.8);
	        transform: scale(0.8,0.8);
}

.video-3 .playlist-item .edit-playlist-buttons,
.video-4 .playlist-item .edit-playlist-buttons
.video-5 .playlist-item .edit-playlist-buttons {
	top: -30px;
}
.playlist-item:hover .edit-playlist-buttons {
	opacity: 1;
	visibility: visible;
	-webkit-transform: none;
	        transform: none;
}

.playlist-item .edit-playlist-buttons div {
	width: 30px;
	height: 30px;
	transition: all 0.2s;
	border-radius: 50%;
	background: linear-gradient(-45deg, #3c4665 0%, #21283F 100%);
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	margin-left: 5px;
}

.playlist-item .edit-playlist-buttons div:hover {
	-webkit-transform: scale(1.1,1.1);
	        transform: scale(1.1,1.1);
}

.playlist-item .edit-playlist-buttons div img {
	width: 13px;
}

.playlist-item .edit-playlist-buttons div:last-child {
	background: #fff;
	border:solid 1px #ccc;
	-webkit-transform: scale(0.8,0.8);
	        transform: scale(0.8,0.8);
}


.playlist-item .edit-playlist-buttons div:last-child:hover {
	border-color: #000;
}

.video-categories .playlist-item.active,
.video-categories .playlist-item:hover {
	opacity: 1;
	box-shadow: 0 4px 8px rgba(0,0,0,0.1);
	border-left: solid 2px #000;

}

.new-category-input {
	width: 100%;
}

.new-category-input input {
	height: 33px;
	border: solid 1px #efefef;
	font-size: 16px;
	border-left: solid 2px #333;
	padding-left: 20px;
	margin-bottom: 5px;
	outline: none;
}


.new-category-input button {
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	border:none;
	color: #fff;
	padding:5px 10px;
	font-size: 12px;
	text-transform: uppercase;
	border-radius: 20px;
}

.new-category-input button.cancel {
	background: transparent;
	color: #ccc;
}


.video-2 .videos-grid .video-box {
	width: calc(50% - 5px);
	margin: 0 2px 4px 2px
}
.video-2 .video-box-image {
	border-radius: 0;
}

.video-2 .video-box-image .video-thumb {
	border-radius: 0;
}

.video-2 .video-box h4 {
	position: absolute;
	bottom: 0;
	left: 0;
	padding:20px;
	color: #fff;
	font-size: 21px;
	font-weight: 400;
	height: auto;
	max-height: none;
	max-height: initial;
	z-index: 4;
}

.video-2 .video-box-image:before {
	opacity: 1!important;
	background: linear-gradient(to bottom left, transparent 20%, rgba(0,0,0,0.4) 100%);
}


.video-3 .videos-grid .video-box {
	width: calc(33.333% - 5px);
	margin: 0 2px 4px 2px
}
.video-3 .video-box-image {
	border-radius: 0;
}

.video-3 .video-box-image .video-thumb {
	border-radius: 0;
}

.video-3 .video-box h4 {
	position: absolute;
	bottom: 0;
	left: 0;
	padding:20px;
	color: #fff;
	font-size: 21px;
	font-weight: 400;
	height: auto;
	max-height: none;
	max-height: initial;
	z-index: 4;
}

.video-3 .video-box-image:before {
	opacity: 1!important;
	background: linear-gradient(to bottom left, transparent 20%, rgba(0,0,0,0.4) 100%);
}


.video-3 .videos-grid,
.video-4 .videos-grid,
.video-5 .videos-grid  {
	width: 100%;
}
.video-3 .new-category-input,
.video-4 .new-category-input,
.video-5 .new-category-input {
	width: auto;
}
.video-3 .new-category-input input,
.video-4 .new-category-input input,
.video-5 .new-category-input input {
	border-left-color: #efefef;
	border-bottom: solid 2px #000;
	background: #fff;
	margin-right: 10px;
	padding:5px;
}
.video-3 .video-categories,
.video-4 .video-categories,
.video-5 .video-categories {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: nowrap;
	        flex-wrap: nowrap;
	-webkit-justify-content: center;
	        justify-content: center;
	margin-bottom: 20px;
}
.video-3 .video-categories .add-photo,
.video-4 .video-categories .add-photo,
.video-5 .video-categories .add-photo {
	display: -webkit-flex;
	display: flex;
	margin-top: 0;
}
.video-3 .video-categories .playlist-item,
.video-4 .video-categories .playlist-item,
.video-5 .video-categories .playlist-item {
	border-left: none!important;
	border-bottom: solid 2px transparent;
}
.video-3 .video-categories .playlist-item.active,
.video-4 .video-categories .playlist-item.active,
.video-5 .video-categories .playlist-item.active {
	border-bottom: solid 2px #000;
}


.video-4 .videos-grid .video-box:nth-child(1),
.video-4 .videos-grid .video-box:nth-child(2) {
	width: calc(50% - 5px);
}

.video-4 .videos-grid .video-box {
	width: calc(33.333% - 5px);
	margin: 0 2px 4px 2px
}
.video-4 .video-box-image {
	border-radius: 0;
}

.video-4 .video-box-image .video-thumb {
	border-radius: 0;
}

.video-4 .video-box h4 {
	position: absolute;
	bottom: 0;
	left: 0;
	padding:20px;
	color: #fff;
	font-size: 21px;
	font-weight: 400;
	height: auto;
	max-height: none;
	max-height: initial;
	z-index: 4;
}

.video-4 .video-box-image:before {
	opacity: 1!important;
	background: linear-gradient(to bottom left, transparent 20%, rgba(0,0,0,0.4) 100%);
}


.video-5 .videos-grid .video-box {
	width: calc(25% - 10px);
	margin:0 5px 30px 5px;
}

@media (max-width: 779px) {
	.videos-grid .video-box {
		width: 100%!important;
		margin:0 20px 35px 20px!important;
	}

	.videos-grid  {
		padding:0 10px;
		width: 100%;
		margin-top: 20px;
	}
	.new-category-input {
		width: auto;
	}
	.new-category-input input {
		border-left-color: #efefef;
		border-bottom: solid 2px #000;
		background: #fff;
		margin-right: 10px;
		padding:5px;
	}
	.video-categories {
		width: 100%;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-wrap: nowrap;
		        flex-wrap: nowrap;
		-webkit-justify-content: center;
		        justify-content: center;
		margin-bottom: 0px!important;
		overflow: auto;
	}
	.video-categories .add-photo{
		display: -webkit-flex;
		display: flex;
		margin-top: 0;
		white-space: nowrap;
		margin:0;
	}
	.video-categories .playlist-item{
		border-left: none!important;
		border-bottom: solid 2px transparent;
		white-space: nowrap;
	}
	.video-categories .playlist-item.active{
		border-bottom: solid 2px #000;
	}
	.video-2 .video-box h4,
	.video-3 .video-box h4,
	.video-4 .video-box h4 {
		padding:10px;
		font-size: 15px!important;
	}

	.video-box h4 {
		font-size: 16px!important;
		font-weight: bold!important;
		margin-top: 10px!important;
		margin-bottom: 20px;
	}
	.videos-grid .gallery-settings {
		padding:0;
		margin:0;
	}

	.videos-grid .gallery-settings .add-photo {
		margin:5px auto;
		padding:8px 16px;
	}

	.video-1 .video-box h4,
	.video-5 .video-box h4 {
		margin:5px 0 20px 0;
	}
}
















.design-block-features {
	width:100%;
	max-width: 1200px;
	padding:0 30px;
	margin:0px auto;
}
.features-block-boxes {
	margin:0 auto;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-align-content: center;
	        align-content: center;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
}
.layout-1 .features-block-boxes {
	margin-top: 80px;
}

/**EDIT MODE EXCEPTION**/
.features-block-boxes .List {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	width: 100%;
}

.features-block-boxes .List .draggable {
	width: calc(33.333% - 40px);
	margin:0 20px 20px 20px;
	cursor: move;
}

.design-block-features.layout-5 .features-block-boxes .List .draggable,
.design-block-features.layout-6 .features-block-boxes .List .draggable  {
	width: calc(50% - 40px);
	margin-bottom: 80px;
}


.features-block-boxes .List .draggable.placeholder {
	border-radius: 6px;
	border:dashed 1px #ccc;
	background:#fafafa;
	height: 220px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.features-block-boxes .List .features-block-box {
	width: 100%;
	margin:0;
}
/**EDIT MODE EXCEPTION END**/
@-webkit-keyframes featuresPlaceholder {
	0% {opacity: 0.3}
	50% {opacity: 0.6}
	100% {opacity: 0.3;}
}
@keyframes featuresPlaceholder {
	0% {opacity: 0.3}
	50% {opacity: 0.6}
	100% {opacity: 0.3;}
}
.design-block-feature-placeholder-wrapper {
	width:100%;
	display: -webkit-flex;
	display: flex;
}
.design-block-feature-placeholder {
	width: calc(33.333% - 40px);
	margin:0 20px 80px 20px;
	height: 220px;
	border-top: solid 200px #ccc;
	position: relative;
	opacity: 0.3;
	-webkit-animation: featuresPlaceholder 1s infinite;
	        animation: featuresPlaceholder 1s infinite;
}

.design-block-feature-placeholder:before {
	width: 100%;
    content: " ";
    margin-top: 10px;
    border-top: 20px solid #ccc;
    border-bottom: solid #ccc 20px;
    height: 10px;
    position: absolute;
    top: 0px;
    left: 0;
}

.design-block-feature-placeholder:after {
	width: 60%;
    content: " ";
    margin-top: 10px;
    background: #ccc;
    height: 20px;
    position: absolute;
    top: 60px;
    left: 0;
}
.features-block-box {
	width: calc(33.333% - 40px);
	padding:0px;
	display: -webkit-flex;
	display: flex;
	margin:0 20px 80px 20px;
	position: relative;
	text-align: left;
	cursor: pointer;
}

.layout-5 .features-block-box,
.layout-6 .features-block-box,
.layout-9 .features-block-box {
	width: calc(50% - 40px);
	margin-bottom: 40px;
}
.layout-7 .features-block-box {
	width: 100%;
	margin-bottom: 80px;
}

.layout-5 .List .features-block-box,
.layout-6 .List .features-block-box {
	width: 100%;
}

.master-container .design-block-background-wrapper .layout-5 .features-block-box h4 {
	font-size: 28px;
	margin-top: 10px;
}
.design-block-features.layout-5 .design-block-title,
.design-block-features.layout-6 .design-block-title,
.design-block-features.layout-9 .design-block-title {
	padding:0 20px;
}	

.design-block-features.layout-5,
.design-block-features.layout-6 {
	max-width: 1400px;
}


.design-block-features.layout-7 {
	max-width: 800px;
}

.design-block-features.layout-7 .features-block-boxes {
	width: 100%;
	-webkit-flex-direction: column;
	        flex-direction: column;
	padding:0 50px;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.design-block-features.layout-7 .features-block-box {
	width: 100%!important;	
}

.design-block-features.layout-7 .features-block-boxes .List {
	-webkit-flex-direction: column;
	        flex-direction: column;
}
.design-block-features.layout-7 .features-block-image {
	display: block;
	width: 120px;
	margin-right: 30px;
	height: 120px;
}
.design-block-features.layout-7 .features-block-image img {
	object-fit: contain;
	width: 100%;
	height: 100%;
}
.design-block-features.layout-7 .features-block-boxes .List .draggable {
	width:100%;
}
.layout-7 .List .features-block-box {
	width:100%;
	margin-bottom: 50px;
}
.features-block-box:hover .hover-box {
	opacity: 1;
	visibility: visible;
	background: rgba(255,255,255,0.7);
}
.features-block-description {
	position: relative;
	text-align: left!important;
	width: 100%;
}

.features-block-description h4 {
	font-weight: bold!important;
}
.features-block-box .number {
	font-size: 40px;
	font-weight: bold;
	opacity: 0.1;
	line-height: 40px;
	margin-right: 10px;
}
.features-block-box h4,
.features-block-box p {
	margin:0;
	padding:0;
}


.master-container .design-block-background-wrapper .features-block-box h4 {
	font-size: 28px;
	font-weight: bold;
	margin-bottom: 0px;
	margin-top: 20px;
}
.master-container .design-block-background-wrapper .features-block-box p {
	/*font-size: 14px;*/
	line-height: 25px;
}
.features-block-image {
	display: none;
	position: relative;
	width: 100%;
}

.features-block-image .fa,
.features-block-image .far,
.features-block-image .fas {
	width:100%;
	height: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	padding-top: 14px;
	position: absolute;
	left: 0;
	font-weight: 100;
	top: 0;
}

.layout-4 .features-block-image .far,
.layout-4 .features-block-image .fas {
	position: relative;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
}

.layout-2 .features-block-image .far,
.layout-5 .features-block-image .far,
.layout-5 .features-block-image .fas {
	-webkit-align-items: center;
	        align-items: center;
}
.design-block-features.layout-3 .features-block-image .fas {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}
.design-block-features.layout-3 .features-block-image:before,
.design-block-features.layout-5 .features-block-image:before,
.design-block-features.layout-9 .features-block-image:before {
	content: " ";
	padding-top: 100%;
	display: block;
}

.design-block-features.layout-5 .features-block-image:before {
	padding-top: 80%;
}

.design-block-features.layout-6 .features-block-image {
	display: none!important;
}

.design-block-features.layout-3 .features-block-image:after {
	width: 100%;
	height: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	content: " ";
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 100%);
	z-index: 2;
}

.design-block-features.layout-3 .features-block-image:before {

}

.design-block-features.layout-2 .features-block-image,
.design-block-features.layout-9 .features-block-image  {
	display: block;
	width: 100%;
	position: relative;
}
.design-block-features.layout-2 .features-block-image:before,
.design-block-features.layout-9 .features-block-image:before {
	content: " ";
	padding-top: 100%;
	display: block;
}
.design-block-features.layout-2 .features-block-image img {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	object-fit: cover;
	left: 0;
}

.design-block-features.layout-3,
.design-block-features.layout-2   {
	max-width: 1200px;
}
.design-block-features.layout-3 .features-block-image,
.design-block-features.layout-4 .features-block-image,
.design-block-features.layout-5 .features-block-image,
.design-block-features.layout-9 .features-block-image {
	display: block;
}

.design-block-features.layout-3 .features-block-description,
.design-block-features.layout-9 .features-block-description {
	position: absolute;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 20px;
    color: #fff;
    z-index: 3;
}

.design-block-features.layout-9 .features-block-description {
	transition: 0.3s;
	background: rgba(0,0,0,0.4);
	opacity: 0;
	padding-top: 60px;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	color: #fff!important;
}

.design-block-features.layout-9 .features-block-box .features-block-description {
	opacity: 1;
	padding-top: 20px;
}
.design-block-features.layout-9 .features-block-description h4,
.design-block-features.layout-9 .features-block-description p {
	color: #fff!important;
	text-align: center;
}
.design-block-features.layout-9 {
	max-width: 1400px;
}

.design-block-align-center .features-block-description {
	text-align: center;
}

.design-block-align-right .features-block-description {
	text-align: right;
}

.design-block-features.layout-9 .features-block-box .hover-box {
 background: transparent;
 -webkit-justify-content: flex-start;
         justify-content: flex-start;
 -webkit-align-items: flex-start;
         align-items: flex-start;
 padding:15px;
}


.design-block-features.layout-3 .features-block-description h4,
.design-block-features.layout-3 .features-block-description p {
	color: #fff!important;
}

.design-block-features .design-block-title {
	width: 100%; 
	margin-bottom: 50px;
}

.design-block-features.layout-8  {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
}

.design-block-features.layout-8 .features-block-boxes .List .draggable {
	width: 50%;
	margin:0;
	padding:20px;
}
.design-block-features.layout-8 .features-block-boxes .List .features-block-box {
	margin:0;
	padding:0;
	width: 100%;
}
.design-block-features.layout-8 .features-block-boxes .features-block-box {
	width: 50%;
	-webkit-flex-direction: column;
	        flex-direction: column;
	margin:0;
	padding:20px;
}

.design-block-features.layout-8 .features-block-box .number {
	font-size: 23px;
	line-height: normal;
	margin-right: 0;
	border-bottom:solid 1px rgba(0,0,0,0.3);
	padding-bottom: 6px;
	font-weight: normal;
	opacity: 1;
}

.design-block-features.layout-8 .design-block-title {
	width: 40%;
	padding-right: 70px;
	margin-top: 10px;
}

.design-block-features.layout-8 .features-block-boxes {
	width: 60%;
}

.design-block-features.layout-3 .features-block-image img,
.design-block-features.layout-5 .features-block-image img,
.design-block-features.layout-9 .features-block-image img {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	object-fit: cover;
	left: 0;
}

.design-block-features.layout-4 .features-block-image {
	display: -webkit-flex;
	display: flex;
	/*justify-content: center;*/
	-webkit-align-items: center;
	        align-items: center;
}
.design-block-features.layout-4 .features-block-image img {
	height: 70px;
}
.design-block-features.layout-2 .features-block-box .number,
.design-block-features.layout-3 .features-block-box .number,
.design-block-features.layout-4 .features-block-box .number,
.design-block-features.layout-5 .features-block-box .number,
.design-block-features.layout-6 .features-block-box .number,
.design-block-features.layout-7 .features-block-box .number,
.design-block-features.layout-9 .features-block-box .number  {
	display: none;
}
.design-block-features.layout-4 .features-block-box {
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	text-align: center;
	margin:35px 20px;
}
.design-block-features.layout-2 .features-block-box,
.design-block-features.layout-5 .features-block-box,
.design-block-features.layout-6 .features-block-box {
	-webkit-flex-direction: column;
	        flex-direction: column;
}
@media (min-width: 779px) {
	.row-1 .features-block-box {
		width: calc(100% - 0px)!important;
	}
	.row-2 .features-block-box {
		width: calc(50% - 40px)!important;
	}
	.row-3 .features-block-box {
		width: calc(33.333% - 40px)!important;
	}
	.row-4 .features-block-box {
		width: calc(25% - 20px)!important;
		margin:0 10px 40px 10px!important;
	}
	.row-5 .features-block-box {
		width: calc(20% - 20px)!important;
		margin:0 10px 40px 10px!important;
	}		
}
@media (max-width: 779px) {
	.design-block-features {
		padding:0 20px;
		margin-top: 20px;
	}
	.design-block-features.layout-8 .design-block-title {
		width: 100%!important;
		padding-right: 30px;
	}

	.design-block-features.layout-8 .features-block-boxes {
		width: 100%;
	}
	.design-block-features.layout-8 .features-block-box .number {
		margin-bottom: 0;
	}

	.design-block-features.layout-8 .features-block-box h4 {
		margin-top: 10px!important;
	}
	.features-block-boxes .List .draggable {
		width: 100%!important;
		margin-bottom: 0;
	}
	.features-block-boxes .features-block-box {
		width: 100%!important;
		margin-bottom: 30px!important;
	}
	.layout-4 .features-block-boxes {
		padding:0 30px;
	}
	.master-container .design-block-background-wrapper .layout-5 .features-block-box h4 {
 		font-size: 24px;
 		margin-bottom: 0;
 	}
 	.layout-1 .features-block-boxes {
		margin-top: 0;
	}
	.design-block-features.layout-7 .features-block-boxes {
		padding:0;
	}
	.design-block-features.layout-9 .features-block-description {
		opacity: 1;
	}
	.master-container .design-block-background-wrapper .features-block-box h4 {
		font-size: 26px!important;
	}
	.master-container .design-block-background-wrapper .features-block-box p {
		font-size: 18px!important;
	}
	.design-block-features .design-block-title {
		padding-left: 0px!important;
		padding-right: 0px!important;
	}
	.features-block-box {
		margin:0 0px 60px 0px!important;
	}
}
.design-block-text {
	width: 100%;
	max-width: 550px;
	margin:0px auto;
	position: relative;
}

.design-block-text.layout-1 {
	max-width: 700px;
}

.design-block-text.layout-2 {
	max-width: 1200px;
	padding-left: 20px;
	padding-right: 20px;
}

.design-block-text.layout-3 {
	max-width: 1100px;
	padding-left: 20px;
	padding-right: 20px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
}

.design-block-text.layout-3 .design-block-text-left,
.design-block-text.layout-3 .design-block-text-right {
	width: 50%;
}

.design-block-text.layout-3 .design-block-text-right {
	margin-top: 30px;
}

.design-block-text.layout-3 .design-block-text-left {
	padding-right: 50px;
}
.design-block-text h1 {
	font-weight: 800;
	font-size: 35px;
	margin:0;
}

@media (max-width: 779px) {
	.design-block-text {
		padding:20px;
	}
	.design-block-text.layout-2,
	.design-block-text.layout-3 {
		-webkit-flex-direction: column;
		        flex-direction: column;
	}
	.design-block-text.layout-3 .design-block-text-left,
	.design-block-text.layout-3 .design-block-text-right {
		width: 100%;
		padding:0;
	}
	.edit-body-mode .design-block-text.layout-2 div {
		width: 100%!important;
		margin-left: 0!important;
	}
	.design-block-text.layout-2 p {
		width: 100%;
		padding:0;
	}
}
.design-block-text-image {
	width:100%;
	max-width: 1200px;
	padding:0 30px;
	margin:0px auto;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	position: relative;
}
.design-block-text-image.layout-6 {
	-webkit-align-items: center;
	        align-items: center;
}

.design-block-text-image.layout-6 {
	-webkit-flex-direction: column-reverse;
	        flex-direction: column-reverse;
}

.design-block-text-image.layout-6 .design-block-image {
	margin:30px 0 0 0!important;
	height: auto!important;
}


.design-block-text-image.layout-6 .design-block-image,
.design-block-text-image.layout-6 .design-block-image-text {
	width: 100%;
	max-width: 700px;
	padding:0;
} 
.design-block-text-image.layout-6 .design-block-image img {
	    width: 100%;
    height: auto;
    max-height: none;
    max-height: initial;
    object-fit: contain;
}

.design-block-text-image.layout-2 {
	-webkit-flex-direction: row-reverse;
	        flex-direction: row-reverse;
}

.design-block-text-image.layout-3,
.design-block-text-image.layout-7 {
	max-width: none;
	max-width: initial;
	position: relative;
	height: 500px;
	overflow: hidden;
	padding:0;
	margin:-66px 0;
}

.design-block-text-image.layout-3 .design-block-image img,
.design-block-text-image.layout-7 .design-block-image img {
	max-height: none;
	max-height: initial;
	height: 100%!important;
}
.design-block-padding-small .design-block-text-image.layout-3,
.design-block-padding-small .design-block-text-image.layout-7 {
	margin:-31px 0;
	height: 350px;
}

.design-block-padding-medium .design-block-text-image.layout-3,
.design-block-padding-medium .design-block-text-image.layout-7  {
	margin:-67px 0;
	height: 550px;
}

.design-block-padding-big .design-block-text-image.layout-3,
.design-block-padding-big .design-block-text-image.layout-7  {
	margin:-161px 0;
	height: 700px;
}
.design-block-text-image.layout-3 .design-block-image,
.design-block-text-image.layout-7 .design-block-image {
	position: absolute;
	width: 100%;
	padding:0;
	height: 100%;
	margin-right: 0;
}
.design-block-text-image.layout-3 .design-block-image:before {
	content: " ";
	background: #333;
	opacity: 0.3;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
.design-block-text-image.layout-3 .design-block-image .change-block-image-btn {
	opacity: 1;
    visibility: visible;
    left: auto;
    right: 20px;
    bottom: 20px;
    top: auto;
}
.design-block-text-image.layout-3 .design-block-image-text {
	padding:0 0 0 80px;
	color: #fff;
	z-index: 4;
}

.design-block-text-image.layout-7 .design-block-image-text {
	display: none!important;
}
.design-block-text-image.layout-3 .design-block-image,
.design-block-text-image.layout-7 .design-block-image  {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.design-block-text-image.layout-2 .design-block-image {
	margin-left: 50px;
	margin-right: 0;
}
.design-block-text-image .design-block-image:hover .change-block-image-btn {
	opacity: 1;
	visibility: visible
}
.design-block-text-image .design-block-image {
	width: 50%;
	position: relative;
	margin-right: 50px;
}
.design-block-text-image .design-block-image-text {
	width: 50%;
	padding:0 20px;
	background: inherit;
}
.design-block-text-image .design-block-image img {
	width: 100%;
	display: block;
	max-height: 500px;
	object-fit: cover;
}

.design-block-text-image.layout-2 .design-block-image img,
.design-block-text-image.layout-1 .design-block-image img {
	object-fit: contain;
}	
.design-block-text-image h1 {
	font-weight: 800;
	margin:0;
}
.design-block-text-image.layout-3 .design-block-image-text {
	width: 100%;
	padding:0 60px;
}

@media (max-width: 779px) {
	.design-block-background-wrapper.design-block-padding-big .design-block-text-image.layout-5,
	.design-block-background-wrapper.design-block-padding-big .design-block-text-image.layout-4 {
		margin-top: -70px;
	}
	.design-block-background-wrapper.design-block-padding-medium .design-block-text-image.layout-5,
	.design-block-background-wrapper.design-block-padding-medium .design-block-text-image.layout-4 {
		margin-top: -30px;
	}
	.design-block-background-wrapper.design-block-padding-small .design-block-text-image.layout-5,
	.design-block-background-wrapper.design-block-padding-small .design-block-text-image.layout-4 {
		margin-top: -10px;
	}

	.design-block-text-image.layout-4,
	.design-block-text-image.layout-5 {
		padding-top: 0;
	}
	.design-block-text-image {
		-webkit-flex-direction: column;
		        flex-direction: column;
		padding:20px;
	}
	.design-block-text-image.layout-2 {
		-webkit-flex-direction: column;
		        flex-direction: column;
	}
	.design-block-text-image.layout-2 .design-block-image-text {
		margin-bottom: 20px!important;
	}
	.design-block-text-image .design-block-image,
	.design-block-text-image .design-block-image-text {
		width: 100%!important;
		margin:0!important;
		padding:0;
	}
	.design-block-text-image .design-block-image {
		margin-bottom: 30px!important;
	}

	.design-block-text-image.layout-2 .design-block-image,
	.design-block-text-image.layout-1 .design-block-image {
		height: auto!important;
	}
	.design-block-text-image.layout-2 .design-block-image img,
	.design-block-text-image.layout-1 .design-block-image img {
		height: auto;
	}
	.design-block-text-image .design-block-image img {
		height: 100%;
	}
	.design-block-text-image .design-block-image-text {
		height: auto!important;
	}

	.design-block-text-image.layout-5,
	.design-block-text-image.layout-4 {
		padding-left: 0;
		padding-right: 0;

	}
	.design-block-text-image.layout-5 .design-block-image-text,
	.design-block-text-image.layout-4 .design-block-image-text {
		padding:0 30px;
	}
	.layout-3.design-block-text-image .design-block-image img {
		max-height: none!important;
		max-height: initial!important;
	}
	.design-block-text-image.layout-3,
	.design-block-text-image.layout-7 {
		margin:-18px 0;
		border-radius: 0;
		height: 400px;
	}
	.design-block-text-image.layout-3,
	.design-block-text-image.layout-7 {
		height: 400px!important;
	}
	.design-block-text-image.layout-3 .design-block-image,
	.design-block-text-image.layout-7 .design-block-image {
		height: 100%!important;
	}
	.design-block-padding-small .design-block-text-image.layout-3,
	.design-block-padding-small .design-block-text-image.layout-7  {
		height: 350px!important;
		margin:-11px 0;
	}

	.design-block-padding-medium .design-block-text-image.layout-3,
	.design-block-padding-medium .design-block-text-image.layout-7 {
		margin:-31px 0;
	}
	.design-block-padding-big .design-block-text-image.layout-3,
	.design-block-padding-big .design-block-text-image.layout-7 {
		height: 600px!important;
		margin:-71px 0;
	}

	.design-block-text-image.layout-3 .design-block-image img,
	.design-block-text-image.layout-7 .design-block-image img {
		height: 100%;
		object-fit: cover;
		max-height: none;
		max-height: initial;
	}
	.design-block-text-image.layout-3 .design-block-image-text {
		padding:30px;
		margin: auto!important;
	}
	.master-container .design-block-background-wrapper .design-block-text-image.layout-3 h1 {
		font-size: 55px;
	}

	.master-container .design-block-background-wrapper .design-block-text-image.layout-3 h2 {
		font-size: 45px;
	}
	.master-container .design-block-background-wrapper .design-block-text-image.layout-3 h3 {
		font-size: 35px;
	}
	.master-container .design-block-background-wrapper .design-block-text-image.layout-3 h4 {
		font-size: 25px;
	}
	.design-block-text-image.layout-3 .design-block-image-text {
		padding:30px;
		height: 100%;
		overflow: auto;
	}
	.design-block-text-image.layout-3,
	.design-block-text-image.layout-7 {
		padding:0!important;
	}
}

@media (min-width: 779px) {
	.design-block-text-image.layout-4,
	.design-block-text-image.layout-5 {
		margin:-65px 0;
		/*min-height: 600px;*/
		max-width: none;
		max-width: initial;
		overflow: auto;
		padding:0;
		/*background: inherit;*/
		border-radius: 0;
		border:none;
	}
	.design-block-padding-big .design-block-text-image.layout-4,
	.design-block-padding-big .design-block-text-image.layout-5 {
		margin:-160px 0;
	}
	.design-block-padding-small .design-block-text-image.layout-4,
	.design-block-padding-small .design-block-text-image.layout-5 {
		margin:-30px 0;
	}
	
	.design-block-text-image.layout-4 .design-block-image,
	.design-block-text-image.layout-5 .design-block-image {
		width: 50%;
		margin-right: 0;
		height: 100%;
	}

	.design-block-padding-small .design-block-text-image.layout-4 .design-block-image,
	.design-block-padding-small .design-block-text-image.layout-5 .design-block-image {
		height: 450px;
	}

	.design-block-padding-big .design-block-text-image.layout-4 .design-block-image,
	.design-block-padding-big .design-block-text-image.layout-5 .design-block-image {
		height: 750px;
	}

	.design-block-text-image.layout-4 .design-block-image,
	.design-block-text-image.layout-5 .design-block-image {
		height: 600px;
	}
	.design-block-text-image.layout-4 .design-block-image img,
	.design-block-text-image.layout-5 .design-block-image img {
		height: 100%;
		max-height: none;
		max-height: initial;
		object-fit: cover;
	}
	.design-block-text-image.layout-4 .design-block-image-text,
	.design-block-text-image.layout-5 .design-block-image-text {
		width: 50%;
		max-width: 550px;
		margin-left: auto;
		margin-right: auto;
		padding:70px 40px;
		background: inherit;
		position: relative;
	}
	.design-block-text-image.layout-5 {
		-webkit-flex-direction: row-reverse;
		        flex-direction: row-reverse;
	}

	.design-block-text-image .design-block-image-text {
		margin:auto;
	}
}
.design-block-products {
	width:100%;
	max-width: 1100px;
	margin:0px auto;
}
.block-products-container {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	position: relative;
	width: 100%;
}

.design-block-products.layout-2 {
	width: 100%;
	max-width: 1100px;
}
.design-block-products.layout-2 .title-set,
.design-block-products.layout-3 .title-set {
	width:100%;
	max-width: 1100px;
	margin:0px auto 30px auto;
}


.design-block-products.layout-3 .block-products-container {
	-webkit-flex-wrap: nowrap;
	        flex-wrap: nowrap;
	overflow: auto;
	margin-top: 70px;
}
.design-block-products.layout-3 {
	max-width: none;
	max-width: initial;
	padding-left: 0;
	padding-right: 0;
}

.design-block-products.layout-4 .title-set {
	width:100%;
	max-width: 1220px;
	margin:0px auto 30px auto;
}

.design-block-products.layout-4  {
	max-width: 1200px;
}
.design-block-products.layout-3 .block-product-item:first-child {
	margin-left: 50px;
}

.layout-3 .block-product-item {
	margin:0;
	width: 500px;
	-webkit-flex-shrink: 0;
	        flex-shrink: 0;
}

.layout-2 .block-product-item {
	position: relative;
	width: calc(33.333% - 2px);
	margin:1px;
	overflow: hidden;
}

.layout-4 .block-product-item {
	position: relative;
	width: 33.333%;
	margin:0px;
	overflow: hidden;
}

@media (min-width: 779px) {
	.layout-4 .block-product-item:nth-child(1) {
		width: 66.666%;
	}
	.layout-4 .block-product-item:nth-child(2),
	.layout-4 .block-product-item:nth-child(3) {
		width: 33.333%;
	}
	.layout-4 .block-product-item:nth-child(2) {
		position: absolute;
		right: 0;
		top: 0;
	}
	.layout-4 .block-products-container {
		-webkit-align-items: flex-end;
		        align-items: flex-end;
	}
}	

.layout-4 .product-item-image:before {
	display: block;
	width: 100%;
	content: " ";
}

.layout-2 .block-product-item:after,
.layout-4 .block-product-item:after  {
	content: " ";
	height: 100%;
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 100%);
}
.layout-2 .product-item-info,
.layout-4 .product-item-info {
	position: absolute;
	width: 100%;
	bottom: 0;
	z-index: 2;
	left: 0;
	padding:20px;
	color: #fff;
}

.design-block-products h4 {
	width: 100%;
	/*font-size: 25px!important;*/
	font-weight: 800;
	margin:10px 0 20px 0;
}

.master-container .design-block-background-wrapper .design-block-products h4 {
	font-size: 25px;
}

.design-block-products.layout-2 .block-product-item h4,
.design-block-products.layout-4 .block-product-item h4,
.design-block-products.layout-2 .block-product-item span,
.design-block-products.layout-4 .block-product-item span  {
	color: #fff!important;
}
.block-product-item {
	position: relative;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	width: calc(33.333% - 20px);
	cursor: pointer;
	margin:10px 10px 30px 10px;
}

.product-item-image img {
	height: 100%;
	object-fit: cover;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	margin-bottom: 0;
	transition: all 0.3s;
}
.product-item-image {
	width: 100%;
	position: relative;
	overflow: hidden;
}
.product-item-image:before {
	padding-top: 100%;
	display: block;
	width: 100%;
	content: " ";
}

.image-display-portrait .product-item-image:before {
	padding-top: 130%
}	
.image-display-landscape .product-item-image:before {
	padding-top: 66.666%
}	
.block-product-item:hover .product-item-image img {
	-webkit-transform: scale(1.1,1.1);
	        transform: scale(1.1,1.1);
}

.block-product-item h4 {
	margin:20px 0 0 0!important;
}
.block-product-item span {
	font-size: 16px;
}
@media (max-width: 779px) {
	.design-block-products.layout-3 .block-products-container {
		margin-top: 0;
	}

	.layout-3 .block-product-item:first-child {
		margin-left: 20px!important;
	}
	.layout-3 .block-product-item {
		width: 250px!important;
	}

	.design-block-products h4 {
		font-size: 18px!important;
	}
	.master-container .design-block-background-wrapper .design-block-products h4 {
		font-size: 25px!important;
	}
	.block-product-item .product-item-info span {
		font-size: 18px!important;	
	}

	.design-block-products {
		padding-right: 20px;
		padding-left:20px;
	}
	.block-product-item {
		margin-left: 0;
		margin-right: 0;
	}
	.design-block-products.layout-3 {
		padding-left:0;
		padding-right: 0;
	}
	.design-block-products.layout-3 .title-set {
		padding-right: 20px;
		padding-left:20px;
	}
	.master-container .design-block-background-wrapper .design-block-products h4 {
		font-size: 20px!important;
	}
	.master-container .design-block-background-wrapper .design-block-products .grid-price {
		font-size: 18px!important;
	}
}
.design-block-gallery .draggable:hover .hover-box {
	visibility: visible;
	opacity: 1;
}

.design-block-gallery:hover .add-btn {
	opacity: 1;
	visibility: visible;
}
 
.design-block-gallery {
	display: -webkit-flex;
	display: flex;
	width:100%;
	max-width: 1200px;
	margin:0px auto;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.design-block-gallery.layout-6  {
	max-width: none;
	max-width: initial;
	padding:0!important;
}

.design-block-align-center .design-block-gallery-thumbs  {
	-webkit-justify-content: center;
	        justify-content: center;
}
.design-block-align-right .design-block-gallery-thumbs  {
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
}

.layout-6 .design-block-gallery-thumbs {
	-webkit-flex-wrap: nowrap;
	        flex-wrap: nowrap;
	width: 100%;
	overflow: auto;
}

.edit-body-mode .layout-6 .design-block-gallery-thumbs {
	overflow: auto;
}

.design-block-gallery.layout-6 .design-block-gallery-item {
	width: auto;
}

.layout-6 .design-block-gallery-thumbs {
	-webkit-justify-content: flex-start!important;
	        justify-content: flex-start!important;
}

 .design-block-gallery.layout-6 .List {
	-webkit-flex-wrap: nowrap;
	        flex-wrap: nowrap;
	-webkit-justify-content: flex-start!important;
	        justify-content: flex-start!important;
	width: 100%;
	overflow: auto;
	-webkit-flex-shrink: 0;
	        flex-shrink: 0;
}

 .design-block-gallery.layout-6 .List .draggable, 
 .design-block-gallery.layout-6 .List .draggable.placeholder {
	width: auto;
}
.design-block-gallery.layout-6 .List .draggable.placeholder {
	min-width: 250px;
}

 .design-block-gallery.layout-6 .List .design-block-gallery-item {
	width: auto;
}

.design-block-gallery.layout-6 .design-block-gallery-item:before {
	display: none;
}

.design-block-gallery.layout-6  .design-block-gallery-image,
.design-block-gallery.layout-6  .List .design-block-gallery-image {
	width: auto;
}

.design-block-gallery.layout-6  .design-block-gallery-image {
	height: 500px;
	position: relative;
	object-fit: initial;
	width: auto;
	-webkit-flex-shrink: 0;
	        flex-shrink: 0;
}

.design-block-gallery-thumbs {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.design-block-align-left .design-block-gallery {
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
}
.design-block-align-center .design-block-gallery {
	-webkit-justify-content: center;
	        justify-content: center;
}
.design-block-align-right .design-block-gallery {
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
}


.design-block-gallery-nav {
	width: 100px;
	height: 50px;
	display: -webkit-flex;
	display: flex;
	position: absolute;
	left: calc(50% - 50px);
	bottom: 1px;
	z-index:11;
	background: inherit;
}
.design-block-gallery-nav span {
	width: 50px;
	height: 50px;
	background: #fff;
	margin-left: 1px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	cursor: pointer;
	transition:0.1s;
	background: inherit;
}

.design-block-gallery-nav span:hover {
	background: #333;
	color: #fff;
}


.design-block-gallery.layout-7 .title-set {
	
}
.design-block-gallery.layout-7 {
	width: 100%;
	max-width: 1200px;
	margin:0 auto;
	background: inherit;
}

.design-block-gallery.layout-7 .design-block-gallery-item {
	width: 100%;
	-webkit-animation:fadeIn 0.3s forwards;
	        animation:fadeIn 0.3s forwards;
	display: none;
}

.design-block-gallery.layout-7 .design-block-gallery-item.active {
	display: block;
}

.design-block-gallery.layout-7 .design-block-gallery-item:before {
	display: none;
}

.design-block-gallery.layout-7 .design-block-gallery-image {
	object-fit: contain;
	position: relative;
    max-height: 700px;
}

.design-block-gallery.layout-7 .design-block-gallery-thumbs {
	position: relative;
	background: inherit;
	min-height: 300px;
	transition-delay: 0.2s;
}
.design-block-gallery.layout-7 .design-block-gallery-thumbs:hover {
	border:dashed 1px #efefef;
}

.design-block-align-center .design-block-gallery.layout-5 .title-set {
	-webkit-align-items: center;
	        align-items: center;
}

.design-block-align-right .design-block-gallery.layout-5 .title-set {
	-webkit-align-items: flex-end;
	        align-items: flex-end;
}
.design-block-gallery.layout-8 .design-block-gallery-thumbs,
.design-block-gallery.layout-9 .design-block-gallery-thumbs {
	position: relative;
	height: 80vh;
	max-height: 800px;
	background: inherit;
}
.design-block-gallery-item:hover .hover-box {
	opacity: 1;
	visibility: visible;
}
.design-block-gallery.layout-8 .design-block-gallery-item,
.design-block-gallery.layout-9 .design-block-gallery-item {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	opacity: 0;
	visibility: hidden;
	transition:0.5s;
}

/*.design-block-gallery.layout-8 .design-block-gallery-item .design-block-gallery-image,
.design-block-gallery.layout-9 .design-block-gallery-item .design-block-gallery-image {
	object-fit: contain;
}*/
.design-block-gallery.layout-8,
.design-block-gallery.layout-9 {
	background: inherit;
}
.design-block-gallery.layout-8 .design-block-gallery-item.active,
.design-block-gallery.layout-9 .design-block-gallery-item.active {
	opacity: 1;
	visibility: visible;
}	

.design-block-gallery.layout-8 .design-block-gallery-item:before,
.design-block-gallery.layout-9 .design-block-gallery-item:before {
	display: none;
}
.hide-title-set .design-block-gallery.layout-5 .title-set,
.hide-title-set .design-block-gallery.layout-8 .title-set,
.hide-title-set .design-block-gallery.layout-9 .title-set {
	display: -webkit-flex!important;
	display: flex!important;
}
.design-block-gallery.layout-2,
.design-block-gallery.layout-4 {
	max-width: 1200px;
}

.design-block-gallery .design-block-gallery-item {
	width: 33.333%;
	position: relative;
}
.design-block-gallery .design-block-gallery-item:hover .hover-box {
	opacity: 1;
	visibility: visible;
	background: rgba(0,0,0,0.3);
}

.design-block-gallery.layout-4 .design-block-gallery-item {
	width: 50%;
}

.design-block-gallery .List .design-block-gallery-item {
	width: 100%!important;

}

.design-block-gallery .design-block-gallery-item:before {
	content: " ";
	display: block;
	padding-top: 100%;
}

.image-display-portrait .design-block-gallery .design-block-gallery-item:before {
	padding-top: 130%;
}

.image-display-landscape .design-block-gallery .design-block-gallery-item:before {
	padding-top: 66.666%;
}

.design-block-gallery .design-block-gallery-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: absolute;
	left: 0;
	top: 0;
}

.design-block-gallery.layout-2 .design-block-gallery-item {
	width: 25%;
}

.design-block-gallery.layout-3 .design-block-gallery-item {
	width: 16.666%;
}
.design-block-gallery.layout-3 .title-set {
	margin-bottom: 0;
}
.design-block-gallery-image {
	width: calc(50% - 0px);
	height: 330px;
	border: transparent 1px solid;
	transition: all 0.2s;
	display: block;
	object-fit: cover;
}
@media (min-width: 779px) {
	/*.design-block-gallery.layout-8 .design-block-gallery-thumbs,
	.design-block-gallery.layout-9 .design-block-gallery-thumbs {
		border:solid 40px transparent;
	}*/
	.design-block-padding-small .design-block-gallery.layout-5,
	.design-block-padding-small .design-block-gallery.layout-8,
	.design-block-padding-small .design-block-gallery.layout-9 {
		margin:0;
	}
	.design-block-padding-big .design-block-gallery.layout-5 {
		margin:0;
	}
	.design-block-gallery.layout-5,
	.design-block-gallery.layout-8,
	.design-block-gallery.layout-9 {
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-flex-wrap: nowrap;
		        flex-wrap: nowrap;
		max-width: 1700px;
		margin:-65px auto;
		padding:0;
	}
	.design-block-gallery.layout-8,
	.design-block-gallery.layout-9  {
		margin:0 auto;
	}
	.design-block-gallery.layout-8,
	.design-block-gallery.layout-9 {
		margin:-65px auto;
	}
	.design-block-padding-big .design-block-gallery.layout-8,
	.design-block-padding-big .design-block-gallery.layout-9,
	.design-block-padding-big .design-block-gallery.layout-5 {
		margin:-160px auto;
	}
	.design-block-padding-small .design-block-gallery.layout-8,
	.design-block-padding-small .design-block-gallery.layout-9,
	.design-block-padding-small .design-block-gallery.layout-5 {
		margin:-30px auto;
	}


	.design-block-gallery.layout-9 {
		-webkit-flex-direction: row-reverse;
		        flex-direction: row-reverse;
	}
	.design-block-gallery.layout-5 .design-block-gallery-thumbs,
	.design-block-gallery.layout-8 .design-block-gallery-thumbs,
	.design-block-gallery.layout-9 .design-block-gallery-thumbs {
		width: 50%;
	}


	.design-block-gallery.layout-5 .title-set,
	.design-block-gallery.layout-8 .title-set,
	.design-block-gallery.layout-9 .title-set {
		width: 50%;
		max-width: 580px;
		display: -webkit-flex!important;
		display: flex!important;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-align-items: flex-start;
		        align-items: flex-start;
		-webkit-justify-content: center;
		        justify-content: center;
		margin-bottom: 0!important;
		padding:90px 30px;
	}

	.design-block-gallery.layout-5 .List .draggable, 
	.design-block-gallery.layout-5 .List .draggable.placeholder {
		width: 50%;
	}

	.design-block-gallery.layout-5 .design-block-gallery-item {
		width: 50%;
	}

	.design-block-gallery.layout-5 .List .draggable.dragged {
		max-width: 300px;
	}

	.design-block-gallery.layout-5 .List {
		
	}
}
@media (max-width: 779px) {
	.design-block-gallery-image {
		height: 150px;
	}
	.design-block-gallery.layout-2 .List .draggable, 
	.design-block-gallery.layout-2 .List .draggable.placeholder {
		width: calc(50% - 1px);
	}
	.design-block-gallery.layout-2 .design-block-gallery-item {
		width: 50%;
	}
	.design-block-gallery.layout-3 .design-block-gallery-item {
		width: 33.333%;
	}
	.hide-title-set .design-block-gallery.layout-5 .title-set,
	.hide-title-set .design-block-gallery.layout-8 .title-set,
	.hide-title-set .design-block-gallery.layout-9 .title-set {
		display: block!important;
	}
	.design-block-gallery.layout-8 .design-block-gallery-thumbs, 
	.design-block-gallery.layout-9 .design-block-gallery-thumbs {
		height: 50vh!important;
	}
	.design-block-gallery.layout-6 .design-block-gallery-image {
		height: 220px;
	}

	.design-block-gallery .title-set {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.design-block-blog {
	width:100%;
	max-width: 1100px;
	margin:0px auto;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	padding:30px;
}

.blog-cards-container {
	width: 100%;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	display: -webkit-flex;
	display: flex;

}

.design-block-blog.layout-2 {
	max-width: 1100px;
}

.design-block-blog.layout-2 .design-block-blog-card {
	position: relative;
}


.design-block-blog.layout-3 .design-block-blog-card {
	width: calc(50% - 50px);
	box-shadow: none;
	background: transparent;
	border-radius: 0;
	margin:25px;
}

.design-block-blog.layout-3 {
	max-width: 1250px;
}
.design-block-blog.layout-3 .design-block-blog-card:nth-child(1) {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	margin:0;
	margin-bottom: 40px;
	padding-bottom: 60px;
	border-bottom: solid 1px rgba(0,0,0,0.1);
}
.design-block-blog.layout-3 .design-block-blog-card:nth-child(1) .design-block-blog-card-image {
	width: 50%;
	-webkit-flex-shrink: 0;
	        flex-shrink: 0;
}
.design-block-blog.layout-3 .design-block-blog-card:nth-child(1) .design-block-blog-card-description {
	padding:40px;
	-webkit-flex-shrink: 0;
	        flex-shrink: 0;
	width: 50%;
}
.design-block-blog.layout-3 .design-block-blog-card:nth-child(1) .design-block-blog-card-description h3 {
	font-size: 45px!important;
}

.design-block-blog.layout-3 .design-block-blog-card-description {
	padding:20px 0;
}
.design-block-blog.layout-2 .design-block-blog-card-image:after {
	content: " ";
	height: 100%;
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 100%);
}
.design-block-blog.layout-2 .design-block-blog-card-description {
	position: absolute;
	bottom:0;
	z-index: 2;
	left: 0;
	color: #fff;
	padding:30px;
	transition: all 0.2s;
	width: 100%;
}

.design-block-blog.layout-2 .design-block-blog-card-description h4,
.design-block-blog.layout-2 .design-block-blog-card-description p,
.design-block-blog.layout-2 .design-block-blog-card-description span {
	color: #fff!important;
}
.design-block-blog.layout-2 .design-block-blog-card:hover .design-block-blog-card-description {
	padding:30px 30px 40px 30px;
}

.design-block-blog.layout-2 .design-block-blog-card-description p {
	opacity: 1;
	display: none;
}
.design-block-blog.layout-2 .design-block-blog-card-image:before  {
	padding-top: 100%;
}
.design-block-blog-card {
	width: calc(33.333% - 10px);
	border-radius: 6px;
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
	overflow: hidden;
	cursor: pointer;
	margin:5px;
}

.design-block-blog-card-image {
	width: 100%;
	position: relative;
	overflow: hidden;
}

.design-block-blog-card-image:before {
	content: " ";
	padding-top: 70%;
	width: 100%;
	display: block;
}

.design-block-blog-card-image img {
	width: 100%;
	position: absolute;
	top: 0;
	height: 100%;
	left: 0;
	object-fit: cover;
	transition: 0.3s;
}

.design-block-blog-card-image:hover img {
	-webkit-transform: scale(1.1);
	        transform: scale(1.1);
}

.design-block-blog-card-description {
	padding:20px;
}

.design-block-blog-card-description h3 {
	margin:0;
	font-size: 25px!important;
	font-weight: bold!important;
}

.design-block-blog-card-description p {
	font-size: 15px;
	opacity: 0.5;
	margin:0;
}

.design-block-blog-card-description span {
	font-size: 13px;
	opacity: 0.5;
}
@media (max-width: 779px) {
	.design-block-blog {
		margin:0 auto;
	}
	.design-block-blog-card {
		width: 100%!important;
		margin-bottom: 20px;
	}
	.design-block-blog-card-image:before {
		padding-top: 66%;
	}
	#blogPost .design-block-toolbar {
		visibility: visible;
		opacity: 1;
		-webkit-transform: none;
		        transform: none;
	}
	.design-block-blog-card-description h3 {
		font-size: 26px!important
	}
	.design-block-blog-card-description p {
		font-size: 16px!important;
	}
	.design-block-blog .design-block-blog-card-image,
	.design-block-blog .design-block-blog-card-description {
		width: 100%!important;
	}
	.design-block-blog.layout-3 .design-block-blog-card {
		-webkit-flex-wrap: wrap;
		        flex-wrap: wrap;
		margin:20px 0!important;
		padding-bottom: 0!important;
		border-bottom: none!important;
	}
	.design-block-blog.layout-3 .design-block-blog-card-description {
		padding:20px 0!important;
	}
}
img.signup-bg-image {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	object-fit: cover;
}
.design-block-signup {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	padding:60px;
	margin:0px auto;
	position: relative;
	color: inherit;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}
.design-block-signup.layout-1 {
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	max-width: 1000px;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
}

.design-block-signup.layout-1 .design-block-signup-form {
	margin-left: 20px;
}
.design-block-signup {
	background: transparent;
	display: -webkit-flex;
	display: flex;
}

.design-block-signup.layout-2 {
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.design-block-signup {
	position: relative;
	z-index: 3;
}

.design-block-signup.layout-2 .design-block-signup-wrapper-description {
	margin-bottom: 30px;
}

.design-block-signup.layout-2 .design-block-signup-form {
	margin-left: 0;
}


.design-block-signup.layout-3 {
	color: #333;
}
.design-block-signup.layout-3:before {
	display:none;
}
.design-block-signup.layout-3 {
	-webkit-flex-direction: column;
	        flex-direction: column;
	max-width: 900px;
	padding:30px;
	background: #fff;
	border-radius: 0;
	box-shadow: 0 6px 12px rgba(0,0,0,0.05);
	z-index: 2;
	min-height: 0;
	min-height: initial;
}

.design-block-signup.layout-3 .explore-btn {
	margin-top: 0;
	height: 60px!important;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}
.design-block-signup.layout-3 .design-block-signup-wrapper-description {
	width: 100%;
	margin-left: 0;
}
.design-block-signup.layout-3 .design-block-signup-form {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	margin:20px 0 0 0;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
}
.design-block-signup.layout-3 .design-block-signup-form input {
	width: calc(50% - 80px);
    height: 60px;
    background: #f3f2f2;
    border: none;
    font-size: 16px;
    padding: 0 20px;
    outline: none!important;
    transition: 0.2s;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 0;
}

.design-block-signup.layout-3 .design-block-signup-form input:focus {
	background: #fff;
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
	border-bottom: solid 2px #333;
}

.design-block-signup.layout-3 .design-block-signup-form .design-block-signup-button {
	border: none;
    text-transform: uppercase;
    font-size: 15px;
    outline: none;
    width: 120px;
    background: #333;
    height: 60px;
    line-height: 60px;
    color: #fff;
    cursor: pointer;
    line-height: 60px;
    transition: all 0.2s;
    border-radius: 0;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.design-block-signup .signup-bg-image {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	object-fit: cover;
}

.design-block-signup-wrapper {
	display: -webkit-flex;
	display: flex;
	position: relative;
	z-index: 4;
	width:100%;
	max-width: 800px;
	-webkit-align-items: center;
	        align-items: center;
	margin:0 auto;
}

.design-block-signup-wrapper h1 {
	margin:0;
	font-size: 40px;
}
.design-block-signup-wrapper h2 {
	margin:0;
	font-size: 30px;
}
.design-block-signup-wrapper h3 {
	margin:0;
	font-size: 20px;
}

.design-block-signup-wrapper p {
	margin:0;
	font-size: 18px;
}

.design-block-signup-wrapper-description {
	width: 60%;
}

.layout-3 .design-block-signup-wrapper-description {
	color: #333;
}

.design-block-signup-form {
	width: 40%;
	margin-left: 80px;
}

.design-block-signup-form input {
	width: 100%;
	height: 50px;
	font-size: 16px;
	padding:0 20px;
	border-radius: 6px;
	outline: none;
	border:none;
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
	margin-bottom: 10px;
	border:solid 1px #efefef;
}

.design-block-signup-button {
	padding:13px 26px;
	color: #fff;
	font-size: 15px;
	font-weight: 500;
	border-radius: 40px;
	background: #63CAA1;
	display: inline-block;
	text-transform: uppercase;
	margin-top: 0px;
	cursor: pointer;
	transition: all 0.2s;
}
.design-block-signup-button:hover {
	-webkit-transform: scale(1.05,1.05);
	        transform: scale(1.05,1.05);
}

@media (max-width: 779px) {
	.design-block-signup {
		padding:60px 30px;
		-webkit-flex-wrap: wrap;
		        flex-wrap: wrap;
	}
	.design-block-signup.layout-1 .design-block-signup-form {
		margin-left: 0;
	}
	.design-block-signup-wrapper {
		-webkit-flex-direction: column;
		        flex-direction: column;
	}
	.design-block-signup-wrapper h1 {
		font-size: 30px;
	}
	.design-block-signup-wrapper p {
		font-size: 16px;
		text-align: left;
	}
	.design-block-signup-wrapper-description {
		width: 100%!important;
		margin-bottom: 40px;
	}
	.layout-3 .design-block-signup-wrapper-description {
		margin-bottom: 0;
	}

	.design-block-signup.layout-3 {
		width: calc(100% - 40px);
	}
	.design-block-signup-form {
		width: 100%!important;
		margin:0;
	}
	.design-block-signup.layout-3 .design-block-signup-form {
		-webkit-flex-direction: column;
		        flex-direction: column;
	}
	.design-block-signup.layout-3 .design-block-signup-form input {
		width: 100%;
		margin-bottom: 10px;
	}
	.design-block-signup .design-block-toolbar-background {
		display: -webkit-flex!important;
		display: flex!important;
	    width: 70px;
	    height: 50px;
	    margin-top: 0;
	    border-radius: 0 0 6px 6px;
	    margin-left: 2px;
	}
	.design-block-signup .design-block-toolbar .left {
		-webkit-flex-direction: row;
		        flex-direction: row;
	}
	.design-block-signup.layout-3 .design-block-background-wrapper {
		padding:20px!important;
	}
	.design-block-signup .design-block-background-wrapper {
		-webkit-flex-wrap: wrap;
		        flex-wrap: wrap;
	}
}
.design-block-text-video {
	width:100%;
	max-width: 1100px;
	padding:0 20px;
	margin:0px auto;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row-reverse;
	        flex-direction: row-reverse;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	position: relative;
}
.layout-3.design-block-text-video {
	-webkit-flex-direction: row;
	        flex-direction: row;
}
.layout-3.design-block-text-video .design-block-video {
	margin-left: 0;
	margin-right: 50px;
}
.layout-2.design-block-text-video {
	-webkit-flex-direction: column;
	        flex-direction: column;
	max-width: none;
	max-width: initial;
	margin:-50px 0 0px 0;
	padding:0;
}

.layout-2.design-block-text-video iframe {
	border-radius: 0;
}
.layout-2 .design-block-video {
	width:100%;
	margin-bottom: 0px;
	margin-left: 0;
}
.layout-2 .design-block-video-text {
	display: none;
}
.design-block-video {
	width: 60%;
	margin-left: 50px;
	position: relative;
}
.design-block-video:before {
	width: 100%;
	content: " ";
	display: block;
	padding-bottom: 56.25%;
}
.design-block-video iframe {
	width: 100%;
	height: 100%;
	border:none;
	outline: none;
	position: absolute;
	top: 0;
	left: 0;
}

.design-block-video-text {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	width: 40%;
}
.design-block-video-text h1 {
	margin:0;
}

.design-block-video-text p {
	margin:15px 0 0 0;
}
.design-block-padding-small .layout-2.design-block-text-video {
	margin:-30px 0;
}

.design-block-padding-medium .layout-2.design-block-text-video {
	margin:-66px 0;
}
.design-block-padding-big .layout-2.design-block-text-video {
	margin:-161px 0;
}
@media (max-width: 779px) {
	.design-block-video:before {
		padding-bottom:67.66%;
	}
	.design-block-video-text p {
		margin:0;
	}
	.layout-2 .design-block-video {
		margin-bottom: 0;
	}
	.edit-body-mode .layout-2.design-block-text-video {
		margin:0px 0 0px 0;
	}
	.design-block-text-video {
		-webkit-flex-direction: column!important;
		        flex-direction: column!important;
	}
	.layout-3.design-block-text-video .design-block-video {
		margin-right: 0;
	}
	.design-block-video-text,
	.design-block-video {
		width: 100%;
		padding:0 30px;
		margin:0;
	}

	.design-block-video-text {
		padding:0;
	}
	.design-block-video {
		width: calc(100% - 0px);
		margin:0 auto;
	}
	.layout-2.design-block-text-video {
		margin:-30px 0 30px 0;
	}
	.design-block-video {
		margin-bottom: 20px;
	}
}
.design-block-table {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	border-radius: 6px;
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
	width: 100%;
	max-width: 1050px;
	margin:0px auto;
	position: relative;
}

.add-row-button {
	width: 30px;
	height: 30px;
	background:#21283F;
	padding:3px 10px;
	border-radius: 20px;
	color: #fff;
	font-size: 13px;
	text-transform: uppercase;
	font-weight: 500;
	white-space: nowrap;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: center;
	        align-items: center;
	transition: all 0.2s;
	overflow: hidden;
	position: absolute;
	bottom: -40px;
	right: 0px;
}
.add-row-button.add-column {
	bottom: auto;
	right: -40px;
	top: 5px;
	z-index: 3;
}
.add-row-button:before {
	content: "+";
	font-size: 16px;
	display: inline-block;
	margin-right: 12px;
	margin-left: 1px;
}
.add-row-button:hover {
	width: 100px;
}
.add-row-button.add-column:hover {
	width: 120px;
}

.block-table-row div {
	padding:0 10px;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}
.block-table-row div .delete-table-row {
	display: none;
}

.block-table-row.thead div {
	position: relative;
}
.block-table-row.thead div .delete-table-row {
	display: -webkit-flex;
	display: flex;
}

.cols-2 .block-table-row div {
	width: 50%
}
.cols-3 .block-table-row div {
	width: 33.333%
}
.cols-4 .block-table-row div {
	width: 25%;
}
.cols-5 .block-table-row div {
	width: 20%;
}
.cols-6 .block-table-row div {
	width: 16.666%;
}
.cols-7 .block-table-row div {
	width: 14.2%;
}
.block-table-row.thead {
	background: #fafafa;
	font-weight: bold;
}
.block-table-row {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	padding:10px 0;
	-webkit-align-items: center;
	        align-items: center;
	transition: all 0.1s;
	border-bottom: solid 1px #efefef;
	position: relative;
	background: #fff;
}
.edit-table-button {
	position: absolute!important;
	padding:3px 10px!important;
	color: #fff!important;
	font-weight: 500!important;
	top: 9px;
	z-index: 2;
	transition: all 0.2s;
	right: 2px;
	font-size: 13px;
	text-transform: uppercase;
	background: #21283F;
	border-radius: 20px;
	cursor: pointer;
	width: auto!important;
	opacity: 0;
	visibility: hidden;
}

.block-table-row:hover .edit-table-button {
	opacity: 1;
	visibility: visible;
}

.block-table-row:hover:not(.thead) {
	background: #fafafa;
}

.block-table-row:last-child {
	border:none;
}

.thead .delete-table-row {
	top: -40px;
	right: auto;
	left: 10px;
}
.thead .delete-table-row:before {
	height: 30px;
	width: 1px;
	border-left: dashed 1px #ccc;
	content: " ";
	position: absolute;
	left: -10px;
	top: 0;
}
.block-table-row.thead:hover .delete-table-row {
	display: none;
}

.block-table-row.thead div:hover .delete-table-row {
	display: -webkit-flex;
	display: flex;
}
.block-table-row input {
	width: calc(100% - 20px);
	border:none;
	height: calc(100% - 10px);
	outline: none;
	border:solid 1px #efefef;
	border-radius: 4px;
	font-size:16px;
}
.block-table-row div.table-input-wrapper {
	height: 100%;
}
.delete-table-row {
	width: 30px!important;
	height: 30px;
	border-radius: 50%;
	background: #21283F;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	position: absolute!important;
	right: -40px;
	cursor: pointer;
	top: 5px;
	opacity: 0;
	transition: all 0.2s;
}
.delete-table-row img {
	-webkit-filter: invert(100%);
	        filter: invert(100%);
	width: 11px;
}
.block-table-row:hover .delete-table-row {
	opacity: 1;
}
@media (max-width: 779px) {
	.design-block-table {
		padding:0 0 0 30px;
		overflow: auto;
	}
	.add-row-button {
		z-index: 2;
		bottom: auto;
		top: -25px;
		right: 120px;
		width: auto;
		font-size: 12px;
	}
	.add-row-button.add-column {
		bottom: auto;
	    right: 3px;
	    top: -25px;
	    z-index: 3;
	}

	.design-block-toolbar-background.edit-table-btn {
		display: -webkit-flex!important;
		display: flex!important;
		width: 60px;
		margin:0;
		opacity: 1;
		visibility: visible;
		-webkit-transform: none;
		        transform: none;
		text-align: center;
		border-radius: 50%;
		margin-left: 0!important;
		top: 4px!important;
	    position: absolute;
	    left: 8px;
	    z-index: 23;
	}
	.delete-table-row {
		opacity: 0.7;
		right: 0;
	}

	.block-table-row div {
		-webkit-flex-shrink: 0;
		        flex-shrink: 0;
	}

	.thead .delete-table-row {
		top: 2px;
		left: 5px;
	}
}
.design-block-contact {
	width: 100%;
	max-width: 1200px;
	margin:0 auto;
	display: -webkit-flex;
	display: flex;
	padding:0 20px;
}
.design-block-contact-image {
	width: 50%;
	margin-right: 100px;
	position: relative;
	height: 400px;
	background: #efefef;
}
.layout-2 .design-block-contact-image {
	display: none;
}

.design-block-contact-image:hover .hover-box {
	visibility: visible;
	opacity: 1;
}
.design-block-contact-image img,
.design-block-contact-image iframe {
	width: 100%;
	height: 100%;
}

.design-block-contact-image img {
	object-fit: cover;
}

.design-block-contact-image iframe {
	height: 400px;
	border:none;
	border-radius: 6px;
	outline: none;
}

.design-block-contact-form {
	width: 50%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
}
.design-block-contact-form .contact-points {
	margin:10px 0;
}
.layout-2 .design-block-contact-form .contact-points {
	-webkit-flex-direction: column;
	        flex-direction: column;
}
.design-block-contact-form .contact-points img {
	max-width: 14px;
	max-height: 14px;
	-webkit-transform: translateY(2px);
	        transform: translateY(2px);
	margin-right: 8px;
}
.design-block-contact-form .contact-points div {
	margin-right: 20px;
	margin-bottom: 15px;
}

.layout-2 .design-block-contact-form {
	width: 100%;
	-webkit-flex-direction: row;
	        flex-direction: row;
}
.layout-2 .design-block-contact-form form {
	width: 50%;
	margin-top: 0;
}
.layout-2 .design-block-contact-form-info {
	width: 50%;
	padding-right: 50px;
}
.design-block-contact-form h1,
.design-block-contact-form p {
	margin:0;
}

.design-block-contact-form h1 {
	font-size: 35px;
	font-weight: 800;
}

.design-block-contact-form form {
	width: 100%;
	margin-top: 20px;
}
.design-block-contact-form .input-wrapper {
	margin-bottom: 10px;
}
.design-block-contact-form .input-wrapper,
.design-block-contact-form input,
.design-block-contact-form textarea,
.design-block-contact-form label,
.design-block-contact-form select {
	width: 100%;
}
.design-block-contact-form label {
	font-size: 14px;
	font-weight: bold;
}

.design-block-contact-form input,
.design-block-contact-form textarea,
.design-block-contact-form select {
	width: 100%;
    padding: 12px 20px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    height: 45px;
    font-family: inherit;
    font-size: 16px;
    color: Source Sans Pro;
}
.design-block-contact-form textarea {
	height: 100px;
}

.design-block-contact-form input:focus,
.design-block-contact-form textarea:focus {
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
	border-color: #333;
}
.design-block-contact-social  {
	display: -webkit-flex;
	display: flex;
	margin-top: 30px;
	margin-bottom: 30px;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	width: 100%;
}
.design-block-contact-social .socicon,
.design-block-contact-social span {
	width: 50px;
	height: 50px;
	margin-right: 5px;
	border-radius: 50%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	font-size: 23px;
	cursor: pointer;
	transition: all 0.2s;
}

.design-block-contact-social span {
	background: linear-gradient(-45deg, #3c4665 0%, #21283F 100%);
	transition: all 0.2s;
	-webkit-transform: scale(0.8,0.8);
	        transform: scale(0.8,0.8);
	opacity: 0;
	visibility: hidden;
}

.design-block-contact-social:hover span {
	opacity: 1;
	visibility: visible;
	-webkit-transform: scale(0.9,0.9);
	        transform: scale(0.9,0.9);
}
.design-block-contact-social span img {
	width: 11px;
}
.design-block-contact-social .socicon:hover {
	-webkit-transform: scale(1.2,1.2);
	        transform: scale(1.2,1.2);
}

@media (max-width: 779px) {
	.design-block-contact-form .contact-points div {
		width: 100%;
	}
	.design-block-contact {
		-webkit-flex-direction: column;
		        flex-direction: column;
	}
	.design-block-contact-image {
		width: 100%;
		margin-right: 0!important;
		margin-left: 0!important;
		position: relative;
	}
	.design-block-contact-image iframe {
	    width: 100%;
	    height: 100%;
	    border: none;
	    outline: none;
	    box-shadow: 0 8px 16px rgba(0,0,0,0.1);
	}
	.layout-2 .design-block-contact-form {
		-webkit-flex-direction: column;
		        flex-direction: column;
		width: 100%;
	}
	.layout-2 .design-block-contact-form-info {
		width: 100%;
		padding-right: 0;
	}
	.design-block-contact-form {
		width: 100%;
	}
	.layout-2 .design-block-contact-form form {
		width: 100%;
		margin:20px 0;
	}
	.layout-1 .design-block-contact-form ,
	.layout-3 .design-block-contact-form {
		margin-top: 20px;
	}
}
/****MAP***/
.design-block-text-map {
	width:100%;
	max-width: 1200px;
	padding:0 30px;
	margin:0px auto;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row-reverse;
	        flex-direction: row-reverse;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	position: relative;
}
.layout-1.design-block-text-map {
	-webkit-flex-direction: column;
	        flex-direction: column;
	max-width: none;
	max-width: initial;
}

.design-block-padding-small .layout-1 .design-block-map {
	margin:-30px 0;
	height: 350px;
}

.design-block-padding-medium .layout-1 .design-block-map {
	margin:-65px 0;
	height: 550px;
}

.design-block-padding-big .layout-1 .design-block-map {
	margin:-160px 0;
	height: 700px;
}
.layout-1 .design-block-map {
	width:100%;
	height: 650px;
	margin-bottom: 0px;
	margin-left: 0;
}
.layout-3.design-block-text-map {
	-webkit-flex-direction: row;
	        flex-direction: row;
}

.layout-3.design-block-text-map .design-block-map {
	margin-left: 0;
	margin-right: 100px;
}
.layout-1 .design-block-map:before {
	display: none;
}
.design-block-map {
	width: 60%;
	margin-left: 50px;
	position: relative;
}
.design-block-map:before {
	width: 100%;
	content: " ";
	display: block;
	padding-bottom: 100%;
}
.design-block-map iframe {
	width: 100%;
	height: 100%;
	border:none;
	outline: none;
	position: absolute;
	top: 0;
	left: 0;
}

.layout-1 .design-block-map-text {
	display: none;
}

.layout-1.design-block-text-map {
	padding:0;
}
.design-block-map-text {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	width: 40%;
}

@media (max-width: 779px) {
	.design-block-text-map {
		-webkit-flex-direction: column!important;
		        flex-direction: column!important;
	}
	.design-block-map {
		width: 100%;
		margin-left: 0!important;
		margin-right: 0!important;
	}
	.design-block-map-text {
		width: 100%;
	}
	.layout-1 .design-block-map {
		height: 500px;
	}
	.design-block-padding-small .layout-1 .design-block-map {
		margin:-10px 0;
		height: 300px;
	}

	.design-block-padding-medium .layout-1 .design-block-map {
		margin:-30px 0;
		height: 400px;
	}

	.design-block-padding-big .layout-1 .design-block-map {
		margin:-70px 0;
		height: 550px;
	}
	.layout-3 .design-block-map-text {
		margin-top:20px;
	}
}
/**MUSIC**/
.design-block-music {
	width:100%;
	max-width: 1200px;
	padding:0 30px;
	margin:0px auto;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-align-items: center;
	        align-items: center;
	position: relative;
}

.design-block-music-title {
	width: 100%;
	margin-bottom: 20px;
}

.design-block-music-title h1 {
	margin:0;
	font-size: 35px;
	font-weight: 800;
}
.design-block-music-title p {
	margin:0;
}
.layout-2 .design-block-music-item {
	width: 100%;
	height: 150px;
	margin-bottom: 10px;
}
.layout-2 .design-block-music-item:before {
	display: none;
}
.design-block-music-item {
	width: calc(33.33% - 20px);
	margin:10px;
	position: relative;
}
.design-block-music-item:before {
	display: block;
	content: " ";
	padding-bottom: 100%;
}
.design-block-music-item iframe {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 6px;
	border:none;
	outline: none;
}

.design-block-music-item:hover .hover-box {
	opacity: 1;
	visibility: visible;
}
@media (max-width: 779px) {
	.design-block-music {
		padding:0 20px;
	}
	.design-block-music-item {
		width:100%;
		margin:10px 0;
	}
	.design-block-music .List .draggable {
		width: 100%;
	}
}
.custom-html-placeholder {
	width: 100%;
	max-width: 1100px;
	height: 400px;
	margin:0px auto;
	border-radius: 8px;
	border:dashed 1px #ccc;
	color: #ccc;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.custom-html-placeholder img {
	width: 300px;
}

.custom-html-placeholder h2 {
	margin:0 0 20px 0!important;
	font-size: 20px!important;
}

textarea.html-syntax {
	width: 100%;
	height: 500px;
	background: #333;
	padding:15px;
	color: #fff;
	font-size: 16px;
	border-radius: 6px;
	border:none;
	outline: none;
}
.design-block-custom-html iframe {
	width: 100%;
	border:none;
	/* height: auto!important; 
	min-height: 100vh;*/
	outline: none;
	box-shadow: none;
	background: transparent;
}

.rc-trigger-popup {
	position: fixed!important;
	top: calc(50% - 158px)!important;
	left: calc(50% - 155px)!important;
	z-index: 2000000;
}
@media (max-width: 779px) {
	.custom-html-placeholder {
		text-align: center;
	}
}
.design-block-carousel {
	position: relative;
	width: 100%;
}
.design-block-carousel.design-block-padding-small .click-carousel {
	height: 400px;
}

.design-block-carousel.design-block-padding-big .click-carousel {
	height: 700px;
}
.design-block-carousel.design-block-padding-big.layout-3 .click-carousel {
	height: 800px;
}

.design-block-carousel.design-block-padding-medium.layout-3 .click-carousel {
	height: 650px;
}

.design-block-carousel.design-block-padding-small.layout-3 .click-carousel {
	height: 500px;
}
.click-carousel {
	width: 100%;
	height: 550px;
	position: relative;
}

.layout-3 .click-carousel {
	border:50px solid transparent;
}


.layout-4 .click-carousel:before,
.layout-5 .click-carousel:before {
	display: none;
}
.layout-4 .click-carousel .click-carousel-img {
	display: none;
}

.layout-4 .click-carousel-text,
.layout-5 .click-carousel-text {
	color: #333;
	-webkit-animation-delay: 0s;
	        animation-delay: 0s;
}
.layout-4 .click-carousel-arrow,
.layout-5 .click-carousel-arrow {
	border-color: #333;
}

.layout-5 .click-carousel-arrow  {
	top:auto;
	left: 20px;
	bottom: 60px;
	height: 20px;
	width: 20px;
}
.layout-5 .click-carousel-arrow.next {
	right: auto;
	left: 40px;
}	
.layout-5 .click-carousel {
	max-width: none;
	max-width: initial;
	padding:0px 0px;
	margin:0px auto;
}

.layout-5 .click-carousel-img {
	width: 50%;
	position: relative;
	object-fit: cover;
	-webkit-animation:fadeIn 1s forwards;
	        animation:fadeIn 1s forwards;
}
.layout-5 .click-carousel-text {
	padding:70px 40px;
	max-width: 550px;
	margin:0 auto;
}
.layout-5 .click-carousel-item.active {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row-reverse;
	        flex-direction: row-reverse;
}

.click-carousel:before {
	position: absolute;
	z-index: 2;
	background: #000;
	opacity: 0.3;
	content: " ";
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}

.click-carousel-arrow {
	height: 40px;
	width: 40px;
	position: absolute;
	cursor: pointer;
	border-left:solid 2px #fff;
	border-bottom:solid 2px #fff;
	left: 40px;
	top: calc(50% - 20px);
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
	z-index: 4;
}
.click-carousel-arrow.next {
	-webkit-transform: rotate(225deg);
	        transform: rotate(225deg);
	left: auto;
	right: 40px;
}

.click-carousel-item {
	position: relative;
	width: 100%;
	height: 100%;
	display: none;
	overflow: hidden;
}
.click-carousel-item.active {
	display: block;
}

.click-carousel-img {
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	object-fit: cover;
	-webkit-animation: clickCarouselImg 1s forwards;
	        animation: clickCarouselImg 1s forwards;
}

.design-block-custom-html {
	position: relative;
	z-index: 3;
}

.click-carousel-text {
	height: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	width: 100%;
	margin:0 auto;
	color: #fff;
	position: relative;
	z-index: 3;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	opacity: 0;
	-webkit-animation: clickCarouselText 1s forwards;
	        animation: clickCarouselText 1s forwards;
	padding:30px;
	-webkit-animation-delay: 0.7s;
	        animation-delay: 0.7s;
	padding-left: 100px;
	padding-right: 100px;
}
.design-block-align-center .click-carousel-text {
	-webkit-align-items: center;
	        align-items: center;
	text-align: center;
}

.design-block-align-right .click-carousel-text {
	-webkit-align-items: flex-end;
	        align-items: flex-end;
	text-align: right;
}

.click-carousel-text button {
	outline: none;
	border: none;
	padding:15px 30px;
	color: #333;
	border-radius: 0;
	text-transform: uppercase;
	margin-top: 40px;
	cursor: pointer;
	font-size: 14px;
}

/*.click-carousel-text h1 {
	font-size: 60px;
	margin:0;
}
.click-carousel-text h2 {
	font-size: 50px;
	margin:0;
}
.click-carousel-text h3 {
	font-size: 40px;
	margin:0;
}
.click-carousel-text h4 {
	font-size: 30px;
	margin:0;
}*/

.click-carousel-text p {
	font-size:18px;
	margin:0;
}

@media (max-width: 779px) {
	.click-carousel-text {
		padding:20px;
	}
}

.layout-2 .click-carousel-arrow,
.layout-4 .click-carousel-arrow {
	left: calc(50% - 40px);
	top: auto;
	bottom: 30px;
	height: 20px;
	width: 20px;
}
.layout-2 .click-carousel-arrow.next,
.layout-4 .click-carousel-arrow.next {
	right: calc(50% - 40px);
	left: auto;
}

@-webkit-keyframes clickCarouselImg {
	0% {opacity: 0; -webkit-transform: scale(1); transform: scale(1);}
	100% {opacity: 1; -webkit-transform: scale(1.1); transform: scale(1.1);}
}

@keyframes clickCarouselImg {
	0% {opacity: 0; -webkit-transform: scale(1); transform: scale(1);}
	100% {opacity: 1; -webkit-transform: scale(1.1); transform: scale(1.1);}
}

@-webkit-keyframes clickCarouselText {
	0% {opacity: 0; -webkit-transform: translateY(50px); transform: translateY(50px);}
	100% {opacity: 1; -webkit-transform: translateY(0px); transform: translateY(0px);}
}

@keyframes clickCarouselText {
	0% {opacity: 0; -webkit-transform: translateY(50px); transform: translateY(50px);}
	100% {opacity: 1; -webkit-transform: translateY(0px); transform: translateY(0px);}
}

.block-title-hidden .click-carousel-text
.block-title-hidden .click-carousel:before  {
	display: none!important;
}

@media (max-width: 779px) {
	.layout-3 .click-carousel {
		border-width: 20px!important;
	}
	.layout-5 .click-carousel {
		-webkit-flex-direction: column!important;
		        flex-direction: column!important;
		height: auto!important;
	}
	.layout-5 .click-carousel-img {
		width: 100%;
		height: 320px!important;
	}
	.layout-5 .click-carousel-item.active {
		display: block!important;
	}

	.layout-5 .click-carousel-text {
		padding:60px 20px;
	}
	.design-block-align-center .click-carousel-text {
		width: 100%!important;
	}
	.layout-5 .click-carousel-arrow {
		top: calc(50% - 10px);
	}
	.layout-5 .click-carousel-arrow.next {
		left: auto;
		right: 20px;
	}
	.layout-2 .click-carousel-text  {
		padding-left: 40px;
		padding-right: 40px;
	}
	.click-carousel-arrow {
		height: 20px;
		width: 20px;
		left: 20px;
		top: auto;
		bottom: 30px;
	}
	.click-carousel-arrow.next {
		-webkit-transform: rotate(225deg);
		        transform: rotate(225deg);
		left: auto;
		right: 20px;
	}
	.click-carousel-text h1 {
		font-size: 40px;
		margin:0;
	}
	.click-carousel-text h2 {
		font-size: 35px;
		margin:0;
	}
	.click-carousel-text h3 {
		font-size: 28px;
		margin:0;
	}
	.click-carousel-text h4 {
		font-size: 23px;
		margin:0;
	}
}
.design-block-custom-form-info {
	width: 100%;
	max-width: 800px;
	margin:0 auto;
	padding:20px;
}
.custom-form-wrapper {
	width:100%;
	max-width: 800px;
	margin:20px auto;
	background: inherit!important;
	color: inherit!important;
	padding: 20px!important;
	position: relative;
}
.layout-1 .custom-form-wrapper,
.layout-3 .custom-form-wrapper,
.layout-4 .custom-form-wrapper {
	background: transparent;
}
.custom-form-label {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	font-size: 14px;
	color: #fff;
	background: #333;
	cursor: pointer;
	position: absolute;
	left: 0;
	top: -30px;
	padding:6px 12px;
	border-radius: 4px 4px 0 0;
}
.custom-form-label:hover {
	opacity: 0.8;
}
.custom-form-label img {
	width: 12px;
	height: 12px;
	object-fit: contain;
	margin-right: 8px;
	-webkit-filter: invert(1);
	        filter: invert(1);
}
.edit-body-mode .custom-form-wrapper  {
	background: none;
	padding:0!important;
}
.custom-form-item-wrapper {
	width: 100%;
	padding:10px;
	position: relative;
}

.custom-form-item-wrapper label {
	font-size: 16px;
    font-weight: 600;
    margin-bottom: 6px;
    display: block;
    float: left;
    width: 100%;
}
.custom-form-item-wrapper input[type=text],
.custom-form-item-wrapper input[type=tel], 
.custom-form-item-wrapper input[type=email], 
.custom-form-item-wrapper input[type=password], 
.custom-form-item-wrapper input[type=date],
.custom-form-item-wrapper input[type=datetime-local],
.custom-form-item-wrapper input[type=time], 
.custom-form-item-wrapper select,
.custom-form-item-wrapper textarea {
	  width: 100%;
	  padding: 12px;
	  display: inline-block;
	  border: 1px solid #ccc;
	  border-radius: 4px;
	  outline: none;
	  height: 45px;
	  font-family: inherit;
	  font-size: 16px;
	  background: #fff;
}


#customFormModal .input-group textarea {
	min-height: 90px;
}

#customFormModal h1.modal-title {
	font-family: Source Sans Pro!important;
	margin-top: 0;
}
.custom-form-item-wrapper input[type=range] {
	width: 100%;
}
.custom-form-checkbox,
.custom-form-radio {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	width: 100%;
}

.custom-form-checkbox input,
.custom-form-radio input {
	margin: 0;
    -webkit-transform: scale(1.15) translateY(4px);
            transform: scale(1.15) translateY(4px);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
}

.custom-form-checkbox label,
.custom-form-radio label {
	font-size: 18px;
	margin-left: 15px;
	font-weight: normal;
}

.custom-form-item-wrapper legend {
	font-size: 14px;
	margin: 8px;
	color: #333;
}

.custom-form-item-wrapper textarea {
	min-height: 150px;
}

.design-block-custom-form {
	width: 100%;
	max-width: 1440px;
	margin-left: auto!important;
	margin-right: auto!important;
	background: inherit;
}

.layout-1.design-block-custom-form:after,
.layout-4.design-block-custom-form:after {
    content: " ";
    clear: both;
    width: 100%;
    display: block;
}

.custom-form-image {
	width: 60%;
	float: left;
	height: calc(100vh - 0px);
	min-height: 600px;
	position: relative;
}
.custom-form-image:hover .hover-box {
	opacity: 1;
	visibility: visible;
}
.custom-form-image .custom-form-image-img,
.custom-form-image iframe {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border:none;
	outline: none;
	display: block;
}

.design-block-custom-form.layout-4,
.design-block-custom-form.layout-1,
.design-block-custom-form.layout-5 {
	padding:0 40px;
}

@media (max-width: 779px) {
	.design-block-custom-form.layout-4,
	.design-block-custom-form.layout-1,
	.design-block-custom-form.layout-5 {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
	}

}
.design-block-custom-form-info,
.custom-form-wrapper {
	float: right;
	width: 40%;
	padding:0 30px;
}
.layout-4 .custom-form-image {
	float: right;
}

.layout-4 .design-block-custom-form-info,
.layout-4 .custom-form-wrapper {
	float: left;
}
.layout-3 .custom-form-image,
.layout-5 .custom-form-image {
	display: none!important;
}


.layout-3.design-block-custom-form {
	max-width: 700px;
	margin:0 auto;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
}
.layout-3 .design-block-custom-form-info,
.layout-3 .custom-form-wrapper {
	width: 100%;
}
.layout-3 .custom-form-wrapper {
	margin-top: 50px;
	padding-top: 0;
}
@media (min-width: 779px) {
	
	.layout-1 .custom-form-image,
	.layout-4 .custom-form-image {
		padding:30px;
	}
	
	.layout-2.design-block-custom-form {
		min-height: 70vh;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		        justify-content: center;
	}
	.layout-2 .design-block-custom-form-info {
		width: 100%;
	    max-width: 600px;
	    margin: 0;
	    padding-top: 0;
	    margin-top: 0px;
	}

	.layout-2 .custom-form-wrapper {
		width: 100%;
		max-width: 400px;
		margin-right: 30px;
	    position: relative;
	    z-index: 20;
	    padding: 0;
	    float: left;
	    margin:80px 0 0 0;
	}
	.layout-2 .custom-form-image {
		width: 100%;
		height: auto;
		min-height: 400px;
		position: absolute;
		left: 0;
		bottom: 100px;
		top: 200px;
		right: 0;
	}

	.layout-2 .order-blocks-container {
		min-height: 70vh!important;
		padding:25px;
	}
	.layout-5 .design-block-custom-form-info,
	.layout-5 .custom-form-wrapper  {
		width: 50%;
		display: inline-block;
	}

	.layout-5 .design-block-custom-form-info {
		
	}
	.layout-5.design-block-custom-form {
		max-width: 1000px;
		display: -webkit-flex;
		display: flex;
	}
	.layout-5.design-block-custom-form .custom-form-wrapper {
		float: none;
	}

}

.layout-5.design-block-custom-form:after {
	display: none;
}

.layout-1 .custom-form-wrapper,
.layout-4 .custom-form-wrapper {
	padding-top: 0;
}
.form-elements-scroll {
	width: 100%;
}
@media (max-width: 779px) {
	.edit-custom-form-item.delete {
		bottom: auto;
		top: -1px;
		right: -28px;
		left: auto;
	}
	.design-block-custom-form-info,
	.custom-form-wrapper {
		width: 100%!important;
		padding:0px;
		float: none!important;
	}
	.design-block-custom-form-info {
		padding:30px 20px!important;
	}
	.layout-5 .design-block-custom-form-info,
	.layout-1 .design-block-custom-form-info,
	.layout-4 .design-block-custom-form-info {
		padding:20px 0!important;
	}
	.custom-form-wrapper {
		padding:20px!important;
		border:none!important;
	}
	.custom-form-image {
		width: 100%!important;
		height: 320px!important;
		min-height: 0!important;
		min-height: initial!important;
		float: none!important;
	}
	.layout-1 .custom-form-wrapper, 
	.layout-4 .custom-form-wrapper {
		padding-left: 0!important;
		padding-right: 0!important;
	}
	.design-block-custom-form.layout-4, 
	.design-block-custom-form.layout-1, 
	.design-block-custom-form.layout-5 {
		padding-left: 20px!important;
		padding-left: 20px!important;
	}
	.layout-3 .custom-form-wrapper {
		margin-top: 0!important;
	}
}


@-webkit-keyframes blankShimmer {
  0%{ background-position: -468px 0; }
  100%{ background-position: 468px 0; }
}

@keyframes blankShimmer {
  0%{ background-position: -468px 0; }
  100%{ background-position: 468px 0; }
}
.hide-title-set .design-block-title,
.hide-title-set .design-block-music-title {
	display: none!important;
}

.design-block-title {
	width: calc(100% - 100px);
	margin:30px auto;
}
.design-block-title h1 {
	margin:0;
	font-size: 45px;
	font-weight: 800;
}
.title-set h1,
.title-set h2,
.title-set h3,
.title-set h4,
.title-set p {
	width: 100%;
}
.hide-title-set .title-set {
	display: none!important;
}
.title-set {
	width: 100%;
	max-width: 1100px;
	margin:0px auto 20px auto;
	display: block;
	margin-bottom: 40px!important;
	padding: 0 20px;
}

.title-set h1,
.title-set h2,
.title-set h3,
.title-set h4,
.title-set p {
	margin:0;
}
.title-set .see-all-btn {
	font-size:15px;
	font-weight: 500;
	text-transform: uppercase;
	display: none;
}
.title-set .see-all-btn:after {
	width: 5px;
	height: 5px;
	border-top: solid 2px #000;
	border-right: solid 2px #000;
	content: " ";
	-webkit-transform: rotate(45deg) translateY(-2px);
	        transform: rotate(45deg) translateY(-2px);
	margin-left: 10px;
	display: inline-block;
}
.design-block-features,
.design-block-products,
.design-block-text,
.design-block-text-image,
.design-block-gallery,
.design-block-blog,
.design-block-table {
	z-index: 2;
}
.design-block-align-center .design-block-title {
	max-width: 900px;
	margin-left: auto;
	margin-right: auto;
}
.edit-body-mode .faq-edit-wrapper:hover .design-block-toolbar{
	opacity: 1;
	visibility: visible;
	-webkit-transform: none;
	        transform: none;
	width: 100%;
}
.master-container .design-block-background-wrapper h1 {
	font-size: 60px;
	font-weight: normal;
	margin:0;
}

.master-container .design-block-background-wrapper h2 {
	font-size: 50px;
	font-weight: normal;
	margin:0;
}

.master-container .design-block-background-wrapper h3 {
	font-weight: normal;
	font-size: 37px;
	margin:0;
}

.master-container .design-block-background-wrapper h4 {
	font-size: 23px;
	font-weight: normal;
	margin:0;
}

.master-container .design-block-background-wrapper h5,
.master-container .design-block-background-wrapper h6,
.flex-container h5,
.flex-container h6 {
	font-weight: normal;
	margin:0;
}

.master-container .design-block-background-wrapper h5 {
	font-size: 20px;
}

.master-container .design-block-background-wrapper h6 {
	font-size: 15px;
}

@media (max-width: 779px) {
	.design-block-background-wrapper.design-block-padding-big {
		padding:70px 0!important;
	}
	.design-block-background-wrapper.design-block-padding-medium {
		padding:30px 0!important;
	}
	.design-block-background-wrapper.design-block-padding-small {
		padding:10px 0!important;
	}
}

@media (min-width: 779px) {
	.design-block-background-wrapper.design-block-padding-big {
		padding:160px 0;
	}

	.design-block-background-wrapper.design-block-padding-small {
		padding:30px 0;
	}
	
}
.design-block-background-wrapper {
	padding: 65px 0;
	background: #fff;
	width: 100%;
	position: relative;
	transition: 0.3s all;
}

.design-block-background-wrapper.design-block-text-overlay,
.design-block-background-wrapper.design-block-text-light {
	color: #fff!important;
}

.block-background-overlay {
	z-index: 2;
	background: rgba(0,0,0,0.4);
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	transition: 0.2s;
}
.design-block-background-wrapper.design-block-text-overlay .block-background-overlay {
	opacity: 1;
}
.design-block-background-wrapper.design-block-align-center {
	text-align: center;
}

.design-block-background-wrapper.design-block-align-right {
	text-align: right;
}

.design-block-background-wrapper.block-title-hidden .title-set  {
	display: none!important;
}

.design-block-background-wrapper .text-white,
.design-block-background-wrapper .text-white a {
	color: #fff;
}
.design-block-background-image {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	object-fit: cover;
}

.design-block-background-wrapper .layout-1,
.design-block-background-wrapper .layout-2,
.design-block-background-wrapper .layout-3,
.design-block-background-wrapper .layout-4,
.design-block-background-wrapper .layout-5,
.design-block-background-wrapper .layout-6,
.design-block-background-wrapper .layout-7,
.design-block-background-wrapper .layout-8,
.design-block-background-wrapper .layout-9,
.design-block-background-wrapper .layout-10 {
	z-index: 4;
	position: relative;
}

@media (max-width: 779px) {
	.design-block-background-wrapper {
		padding:15px 0!important;
	}
	.block-product-item {
		width: 100%!important;
		margin-bottom: 30px!important;
	}
	.design-block-title {
		width: 100%;
		margin:0;
		padding:0 30px 30px 30px;
	}

 	.master-container .design-block-background-wrapper h1 span,
 	.master-container .design-block-background-wrapper h2 span,
 	.master-container .design-block-background-wrapper h3 span,
 	.master-container .design-block-background-wrapper h4 span,
 	.master-container .design-block-background-wrapper h5 span {
 		font-size: inherit!important
 	}

 	.design-block-text p span {
 		font-size: inherit!important;
 	}

	.master-container .design-block-background-wrapper h1 {
		font-size: 40px;
	}

	.master-container .design-block-background-wrapper h2 {
		font-size: 34px;
	}

	.master-container .design-block-background-wrapper h3 {
		font-size: 24px;
	}

	.master-container .design-block-background-wrapper h4 {
		font-size: 20px;
	}
	.order-blocks-container {
		padding:30px;
	}
	.menu-links-tree .order-blocks-container {
		padding:30px 0;
	}
	.title-set {
		padding-left: 0;
		padding-right: 0;
	}
}

.MuiInputBase-input {
	box-sizing: border-box!important;
}
.MuiFormControl-root,
.MuiInputBase-root {
	width: 100%;
}
.explore-btn.button-hidden {
  display: none;
}

.edit-body-mode .explore-btn.button-hidden,
.edit-body-mode .design-block-background-wrapper .explore-btn.button-hidden {
    background: transparent!important;
    color: transparent!important;
    border: dashed 1px rgba(155,168,194,0.4)!important;
    display: inline-block;

}
.edit-body-mode .explore-btn.button-hidden .fa {
  display: none!important;
}

.edit-body-mode .explore-btn.button-hidden:hover {
  opacity: 1;
}
.edit-body-mode .explore-btn.button-hidden:before {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  content: "ADD BUTTON";
  text-align: center;
    left: 0;
    top: 0;
    color: rgba(155,168,194,0.4)!important

}
.explore-btn .fa {
  margin-left: 6px;
  display: none;

}
.explore-btn.button-arrow .fa {
  display: inline-block;
  font-weight: 300;
}
.explore-btn {
  box-shadow: none;
  margin-top: 20px;
  display: inline-block;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}
.explore-btn:not(.button-arrow) .arrow{
  display: none;
}

.explore-btn {
  padding:15px 30px 15px 30px;
}
.explore-btn.btn-small {
  padding:8px 16px;
}
.explore-btn.btn-large {
  font-size: 17px;
  padding:22px 44px;
}

.explore-btn.button-transparent {
  background: transparent!important;
  box-shadow: none!important;
  border:solid 1px #000;
}

.cover-4 .explore-btn.button-transparent,
.home-7 .explore-btn.button-transparent,
.home-4 .explore-btn.button-transparent,
.text-white .explore-btn.button-transparent {
  border-color: #fff;
}

.explore-btn.button-transparent .arrow {
  background: #fff;
}

.explore-btn.button-transparent .arrow:after {
  border-color: #fff;
}

.explore-btn.btn-circle {
  border-radius: 30px;
}

.explore-btn.btn-square {
  border-radius: 0px;
}
.explore-btn.btn-corner-rounded {
  border-radius: 6px;
}

.explore-btn.button-shadow {
  box-shadow: 0 6px 18px 0 rgba(0,0,0,0.1);
}

.explore-btn.button-center {
  display: table;
  margin-left:auto;
  margin-right: auto;
}

.explore-btn.button-right {
  display: table;
  margin-left: 0;
  margin-right:auto;
}

.close:not(.burger) {
	width: 20px;
	height: 20px;
	position: absolute;
	left: 20px;
	top:20px;
	cursor: pointer;
}
.close:not(.burger):before, .close:not(.burger):after {
	width: 100%;
	height: 1px;
	position: absolute;
	top: 50%;
	left: 0px;
	content: " ";
	background: #000;
	-webkit-transform: rotate(-45deg);
	        transform: rotate(-45deg);
}
.close:not(.burger):before {
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
}
.modal.modal-custom-alert .modal-content {
    width: 275px;
    height: auto;
    border-radius: 10px;
    text-align: center;
    padding:0;
    -webkit-flex-direction: column;
            flex-direction: column;
 }

.modal.modal-custom-alert .modal-content .swal-footer {
	border-top: solid 1px #efefef;
    padding: 10px;
    background: #fafafa;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
}

.modal.modal-custom-alert .modal-content .fa {
	font-size: 40px;
	color: #f8bb86;
    font-weight: 100;
    margin: 35px 0 10px 0;
}

.modal.modal-custom-alert .modal-content .swal-text {
	margin:0 auto 30px auto;
}

 .modal.modal-custom-alert.active .modal-content {
	-webkit-animation: swalModalIn .3s forwards;
	        animation: swalModalIn .3s forwards;
 }

@-webkit-keyframes swalModalIn {
	0% {opacity: 0; -webkit-transform: scale(0.8); transform: scale(0.8);}
	50% {opacity: 1; -webkit-transform: scale(1.1); transform: scale(1.1);}
	100% {opacity: 1; -webkit-transform: scale(1); transform: scale(1);}
}

@keyframes swalModalIn {
	0% {opacity: 0; -webkit-transform: scale(0.8); transform: scale(0.8);}
	50% {opacity: 1; -webkit-transform: scale(1.1); transform: scale(1.1);}
	100% {opacity: 1; -webkit-transform: scale(1); transform: scale(1);}
}

.swal-modal .swal-text {
	font-size: 14px;
    margin: 0 auto;
}
.close-modal-hotspot {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: rgba(0,0,0,0.1);
	box-shadow: 0 0 0 500px rgba(0,0,0,0.1);
}
.modal-content .close {
	width: 26px!important;
    height: 26px!important;
    right: 2px!important;
    top: 2px!important;
    left: auto!important;
    background: #333!important;
    border-radius: 6px!important;
    border: solid 5px #333;
}

.modal-content .close:hover {
	background: #000!important;
	border-color:#000!important;
}

.modal-content .close:before,
.modal-content .close:after {
	background: #fff!important;
	height: 2px!important;
	transition: 0.2s;
}

#navBarModal .close-modal-hotspot {
	opacity: 0;
}
.modal#menuLinksModal {
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
}
.modal#menuLinksModal .modal-content {
	max-width: 550px;
	border-radius: 6px 6px 0 0;
	padding-bottom: 0;
	-webkit-animation:fadeIn 0.6s forwards;
	        animation:fadeIn 0.6s forwards;
	-webkit-transform: none!important;
	        transform: none!important;
	height: calc(100vh - 70px);
}

.modal#menuLinksModal .modal-content:before {
	position: absolute;
	left: calc(50% - 10px);
	top: -10px;
	content: " ";
	background: #fff;
	width: 20px;
	height: 20px;
	tranform:rotate(45deg);
}

#legalModal .modal-content {
	border-radius: 6px;
	font-size: 18px;
}

#legalModal .modal-content h2 {
	font-size: 24px;
	margin: 0;
}
#legalModal .modal-bottom-footer .btn {
	border-radius: 6px;
}
.legal-modal-placeholder {
	width:100%;
	height: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	font-size: 16px;
	text-transform: uppercase;
}
.legal-modal-placeholder img {
	max-width: 200px;
}

.modal-steps-title h2 {
	margin: 0;
	font-size: 20px;
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
}
.modal#menuLinksModal .new-page-overlay {
	background: rgba(0,0,0,0);
}
.modal#editButtonModal .input-group {
	margin-top: 12px;
}
.modal#editButtonModal .modal-content {
	width: 350px;
}
.button-shape-selector {
	width:100%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.button-shape-selector div {
	width: calc(33.333% - 10px);
	max-width: 150px;
	opacity: 0.3;
	border:solid 2px #000;	
	padding:8px;
	cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
}
.button-shape-selector div.active,
.button-shape-selector div:hover {
	opacity: 1;
}
.button-shape-selector div:first-child {
	border-radius: 20px;
}
.button-shape-selector div:last-child {
	border-radius: 6px;
}



.modal-empty-placeholder {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	margin-bottom: 20px;
	-webkit-justify-content: center;
	        justify-content: center;
	background: #fff;
    margin-top: -54px;
    padding-top: 15px;
    position: relative;
}
.modal-empty-placeholder:after {
	width: 100%;
	height: 100px;
	background: inherit;
	position: absolute;
	left: 0;
	bottom: -100px;
	content: " ";
	z-index: 2;
}

.modal-empty-placeholder .modal-empty-placeholder-img {
	width: 200px;
	margin:30px auto;
}
.modal-empty-placeholder h2,
.modal-empty-placeholder p {
	margin:0;
}
.modal {
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.4);
	position: fixed;
	left: 0px;
	top:0px;
	z-index: 22222;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    visibility: hidden;
	opacity: 0;
	transition: all 0.3s ease;
}
.modal#typePageName {
	display: none;
}

.modal.active#typePageName {
	display: -webkit-flex;
	display: flex;
	-webkit-animation:fadeInBottom 0.5s forwards;
	        animation:fadeInBottom 0.5s forwards;
}
.modal .profile-image-edit:hover {
	cursor: pointer;
	opacity: 0.4;
}
.MuiInput-underline:before,
.MuiInput-underline:after {
	display: none!important;
}
.MuiPickersToolbar-toolbar {
	background: linear-gradient(-45deg, #3c4665 0%, #21283F 100%)!important;
}
.MuiPickersDay-daySelected {
	background: #21283F!important;
}
.MuiPickersDay-current {
	color: #9CA9C3!important;
}
.MuiButton-textPrimary {
	color: #21283F!important;
}
.MuiDialog-root {
	z-index: 400000!important;
}
input[type="file"] {
    display: none;
}

.fu_displaynone{
	display: none !important;
}
.custom-file-upload {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 300px;
    background: #f9f9fa;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    /*box-shadow: 10px 10px 30px rgba(0,0,0,0.08);*/
    cursor: pointer;
    z-index: 2;
}

#orderConfirm .modal-content {
	max-width: 450px;
	min-height: 450px;
	height: auto;
	font-weight: normal;
	border-radius: 10px;
	font-size: 16px;
	padding:0px;
	overflow: hidden;
	width: calc(100vw - 20px);
}

#typePageName .modal-content {
	max-width: 380px!important;
	max-height: 300px!important;
	height: auto;
	font-weight: normal;
	border-radius: 10px;
	font-size: 16px;
	padding:30px!important;
	overflow: hidden;
	width: calc(100vw - 20px)!important;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-animation: fadeInBottom 0.4s forwards;
	        animation: fadeInBottom 0.4s forwards;
	position: relative;
}
#typePageName .modal-content .modal-title {
	margin-bottom: 0;
}

#typePageName .modal-content h1 {
	font-size: 25px;
	font-weight: bold;
	margin:0;
}

#typePageName .modal-content {
	font-size: 14px;
	margin:0;
}

#typePageName .modal-content .btn {
	margin:0;
	border-radius: 50px;
}

#typePageName .close:not(.burger) {
	left: auto;
	right: 20px;
}

#typePageName .modal-content .input-group {
	margin-top: 10px;
}


.placing-order-flex {
	width: 100%;
	height: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

#orderConfirm .modal-content {
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
}

#orderConfirm .modal-content .order-confirm {
	width: 100%;
	height: auto;
	margin-bottom: 20px;
}

#orderConfirm .modal-content .continue-btn {
	padding:15px 30px;
	border-radius: 10px;
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	font-size: 14px;
	text-transform: uppercase;
	margin:20px auto;
	color: #fff;
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
	cursor: pointer;
	transition: all 0.2s;
}

#orderConfirm .modal-content .close:before,
#orderConfirm .modal-content .close:after {
	background: #fff;
}

#orderConfirm .modal-content .continue-btn:hover {
	-webkit-transform:translateY(-5px);
	        transform:translateY(-5px);
}

#orderConfirm .modal-content h4,
#orderConfirm .modal-content p {
	margin:0;
	width: 100%;
	text-align: center;
}
#orderConfirm .modal-content p {
	padding:0 80px;
}
#orderConfirm .modal-content h4 {
	margin-bottom: 5px;
	font-size: 23px;
}
.opacityzero {
	opacity:0;
}

.custom-file-upload strong {
	margin-top: 140px;
	font-size: 16px;
	font-weight: normal;
	opacity: 0.4;
}


.custom-file-upload:hover .image-illustration:after {
	right: -10px;
}

.custom-file-upload:hover .image-illustration:before {
	right: 70px;
}

.custom-file-upload:hover .image-illustration span {
	top: 40px;
	left: 45px;
}

#addMemberModal .modal-content {
	max-width: 450px;
	height: auto;
	min-height: 450px;
	border-radius: 10px;
	width: 95%;
}

#addMemberModal .modal-content:before {
	position: absolute;
	left: 0;
	top: 0;
	background: linear-gradient(-45deg, #455173 0%, #21283F 100%);
	height: 167px;
	content: " ";
	width: 100%;
}

#addMemberModal .modal-title,
#addMemberModal .modal-description {
	color: #fff;
	position: relative;
	z-index: 2!important;
}

#addMemberModal .modal-content .close {
	-webkit-filter: invert(1);
	        filter: invert(1);
	left: auto;
	right: 20px;
}

.member-modal-form {
	width: 100%;
	position: relative;
	z-index: 2;
}
.member-modal-form .input-group {
	margin-top: 15px;
}
.member-modal-form .input-group input,
.member-modal-form .input-group select {
	box-shadow: 4px 4px 13px rgba(0,0,0,0.08);
	font-size: 16px;
	border:solid 1px rgba(0,0,0,0.1);
	height: 55px;
}

.select-chip {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	position: relative;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.unsplash-images .select-chip div {
	opacity: 0.8;
}

.unsplash-images .select-chip div:hover {
	color: #fff;
	border-color: #fff;
	opacity: 1;
}

.unsplash-images .cat-select {
	margin-top: 0;
}

.select-chip div {
	padding:5px 10px;
	border-radius: 10px;
	border:solid 1px #ccc;
	color: #ccc;
	cursor: pointer;
	margin:0 8px 8px 0;
	overflow: hidden;
	font-size: 14px;
}

.select-chip div:hover {
	border-color: #333;
	color: #333;
}

.select-chip div.active {
	border-color: transparent;
	background: linear-gradient(-45deg, #455173 0%, #21283F 100%);
	color: #fff;
}

.select-chip div span.active {
	color: #fff;
	background: #21283F;
	box-shadow: 0 0 0 15px #21283F;
}


@-webkit-keyframes mountainIn {
	0% {-webkit-transform: translateY(50px) rotate(45deg);transform: translateY(50px) rotate(45deg)}
	100% {-webkit-transform: translateY(0px) rotate(45deg);transform: translateY(0px) rotate(45deg)}
}


@keyframes mountainIn {
	0% {-webkit-transform: translateY(50px) rotate(45deg);transform: translateY(50px) rotate(45deg)}
	100% {-webkit-transform: translateY(0px) rotate(45deg);transform: translateY(0px) rotate(45deg)}
}

@-webkit-keyframes sunIn {
	0% {-webkit-transform: translateY(30px) translateX(-60px) ;transform: translateY(30px) translateX(-60px) ;}
	100% {-webkit-transform: translateY(0px) translateX(0px);transform: translateY(0px) translateX(0px)}
}

@keyframes sunIn {
	0% {-webkit-transform: translateY(30px) translateX(-60px) ;transform: translateY(30px) translateX(-60px) ;}
	100% {-webkit-transform: translateY(0px) translateX(0px);transform: translateY(0px) translateX(0px)}
}
.modal#contentsModal .modal-content {
	padding-bottom: 0px;
}

.modal-content .switch-table {
	border:solid 1px #efefef;
}
.modal-content .modal-footer {
	width: calc(100% + 70px);
	-webkit-justify-content: center;
	        justify-content: center;
	padding: 15px 0 15px 0;
	position: -webkit-sticky;
	position: sticky;
	margin-left: -35px;
	box-shadow: 0 -4px 8px rgba(255,255,255,1);
	bottom: -35px;
	background: #fff;
}
.modal-content .modal-footer a {
	margin-top: 0!important;
}

.modal.portfolio-modal {
	background: transparent;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	display: inline-block;
	overflow:auto;
	z-index: 4;
}
.modal.portfolio-modal .modal-content {
	box-shadow: none;
	min-height: calc(100vh - 80px);
	height: auto;
	width: 80%;
	margin-top: 80px;
	margin-left: auto;
	margin-right: auto;
	max-width: 750px;
}
.modal.image-modal {
	padding:10px 115px 0px 83px;
	background: rgba(255,255,255,0.9)!important;
}

.modal.image-modal .modal-content {
	height: 100%;
    width: 100%;
    max-width: 900px;
    padding: 0px;
    margin-top: 0px;
    box-shadow: 10px 10px 60px rgba(0,0,0,0.08);
    overflow: visible;
}

.modal-header {
	width: 100%;
	border-bottom: solid 1px #efefef;
	padding:20px;
}

.modal-header h2 {
	font-weight: 300;
	margin:0;
	padding:0;
	font-size: 22px;
}

.modal-footer {
	display: -webkit-flex;
	display: flex;
}
.modal-footer .btn {
	margin:0px 10px;
	border-radius: 30px!important;
}

.modal .modal-footer .btn-empty {
	color: #333;
	background: transparent;
	background: #fff;
}
.modal .modal-footer .btn-empty:hover {
	background:#fafafa;
}

#closeContents {
	-webkit-transform: scale(1.4,1.4);
	        transform: scale(1.4,1.4);
	top: 40px;
	right: 40px;
	left: auto;
}
#settingsModal .modal-content {
	max-width: 800px;
}

.promo-modal-container.live-mode {
	opacity: 0;
	position: fixed;
	bottom:0;
	z-index: 400;
	-webkit-animation: fadeInBottom 0.6s forwards;
	        animation: fadeInBottom 0.6s forwards;
	-webkit-animation-delay: 1s;
	        animation-delay: 1s;
	visibility: visible;
}
.promo-modal-container.live-mode .close {
	left: auto;
	top: 10px;
	right: 10px;
	-webkit-filter: invert(1);
	        filter: invert(1);
}
.promo-modal-container.live-mode .button-set img {
	position: relative;
	width: 80px;
	margin-left: 20px;
}


.promo-modal-container {
	position: absolute;
	bottom: 0px;
	right: calc(50% - 250px);
	padding:20px 20px 20px 220px;
	background:#fff;
	border-radius: 6px 6px 0 0;
	box-shadow: 0 -8px 16px rgba(0,0,0,0.2);
	width: 100%;
	max-width: 500px;
	font-size: 15px;
	display: -webkit-flex;
	display: flex;
	transition: all 0.5s;
	opacity: 0;
	bottom: -50px;
	visibility: hidden;
	color: #fff;
	background:linear-gradient(180deg, #3c4665 0%, #21283F 100%);
}
.promo-modal-container img {
	width: 215px;
	position: absolute;
	left: 0;
	bottom: 0;
}
.active .promo-modal-container {
	opacity: 1;
	bottom: 0;
	transition-delay: 1s;
}

.promo-modal-container h3 {
	margin:0;
	padding:0;
	font-size: 20px;
}

.promo-modal-container .button-set {
	display: -webkit-flex;
	display: flex; 
	-webkit-justify-content: flex-start; 
	        justify-content: flex-start;
	-webkit-align-items: center;
	        align-items: center;
	margin-top: 15px;
}

.promo-modal-container .button-set .btn,
.promo-modal-container .button-set .publish-btn {
	padding:10px 20px;
	margin: 0;
}

.promo-modal-container .button-set .btn-empty {
	background:transparent;
	color: #333;
	color: #fff;
}

.modal.image-modal .modal-form {
	width: 100%;
	padding:30px 50px;
	    display: -webkit-flex;
	    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
}
.modal.image-modal .modal-form input.title-input {
	font-size: 20px;
	font-weight: 300;
}
.modal.image-modal .modal-form input, .modal.image-modal .modal-form textarea {
	box-shadow: none;
	border: none;
	border-bottom: solid 1px rgba(0,0,0,0.1);
	border-radius: 0;
	padding-left: 0px;
}

.modal.image-modal .modal-form input:focus, .modal.image-modal .modal-form textarea:focus,
.modal.image-modal .modal-form input:hover, .modal.image-modal .modal-form textarea:hover {
	border-bottom-color: #000;
	padding-left: 0px;
	/*background: #f9f9fa;*/
}



.modal.image-modal .modal-form .input-group {
	margin-top: 0;
	margin-bottom: 10px;
}

.modal.active {
	visibility: visible;
	opacity: 1;
}

.modal.side {
	background: rgba(0,0,0,0);
}

.modal.side .modal-content.narrow {
	width: 300px;
}

#productsInCategory.modal.side .modal-content {
	-webkit-animation:fadeIn 0.3s forwards;
	        animation:fadeIn 0.3s forwards;
	-webkit-transform: none!important;
	        transform: none!important;
}

#navBarModal.modal.side .modal-content {
	height: calc(100vh - 140px);
    bottom: 10px;
    top: auto;
    padding: 0;
}

#navBarModal.modal.side .modal-content .input-group {
	padding:20px;
	border-bottom: solid 1px #efefef;
	margin:0;
}

#navBarModal.modal.side .modal-content .input-group label {
	margin-bottom: 5px;
	margin-top: 5px;
}

#navBarModal.modal.side .modal-content .close {
	left: auto;
	right: 10px;
	top: 10px;
}

.nav-layout-picker {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	border:solid 1px #efefef;
	border-radius: 6px;
	overflow: hidden;
	box-shadow: 0 6px 12px rgba(0,0,0,0.1);
}
.nav-layout-picker span {
	width: 14.2%;
	border-right:solid 1px #efefef;
	font-size: 15px;
	color: #ccc;
	padding:10px;
	cursor: pointer;
	text-align: center;
}
.nav-layout-picker span:last-child {
	border:none;
}
.nav-layout-picker span:hover {
	color: #333;
	background: #fafafa;
}
.nav-layout-picker span.active {
	background: linear-gradient(0deg, #3c4665 0%, #21283F 100%);
	color: #fff;
}

.modal.side .close:before,
.modal.side .close:after {
	background: #333;
}

#contentsModal .modal-content {
	width: 500px;
}
.modal-footer.new-page-footer {
	position: fixed;
	background: #fff;
	box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
	bottom:0;
	right: 0;
	-webkit-transform: translateY(80px);
	        transform: translateY(80px);
	opacity: 0;
	visibility: hidden;
	transition:all 0.3s;
}
.modal-footer.new-page-footer.active {
	opacity: 1;
	visibility: visible;
	-webkit-transform: none;
	        transform: none;
}

.page-type-title {
	font-size: 15px;
	font-weight: bold;
	width: 100%;
	margin:30px 0px 10px 0;
}
.page-type-title span {
	background: #21283F;
	color: #fff;
	font-weight: 600;
	padding:10px 20px;
	border-radius: 40px;
	margin-left: 10px;
}
.modal.side .modal-content {
	height: calc(100vh - 20px);
	width: 400px;
	position: absolute;
	right:10px;
	border-radius: 6px;
	top: 10px;
	box-shadow: -10px -10px 30px rgba(0,0,0,0.1);
	-webkit-transform: translateY(0) translateX(100px);
	        transform: translateY(0) translateX(100px);
	background: #fff;
	color: #333;
	-webkit-animation: modalContentIn 0.3s forwards;
	        animation: modalContentIn 0.3s forwards;
}

.modal.side#imagePickerModal .modal-content {
	width: 100vw;
	max-width: 600px;
}

#fontSettingsModal.modal.side .modal-content {
	width: 100vw;
	max-width: none;
	max-width: initial;
	height: 233px;
	top: auto;
	bottom: 0;
	right: 0;
	padding:0px;
	display: block;
	-webkit-animation:modalBottomIn 0.3s forwards;
	        animation:modalBottomIn 0.3s forwards;
}
@-webkit-keyframes modalBottomIn {
	0% {-webkit-transform: translateY(200px);transform: translateY(200px);}
	100% {-webkit-transform: translateY(0px);transform: translateY(0px);}
}
@keyframes modalBottomIn {
	0% {-webkit-transform: translateY(200px);transform: translateY(200px);}
	100% {-webkit-transform: translateY(0px);transform: translateY(0px);}
}
#fontSettingsModal .font-tabs {
	width: 100%;
	font-weight: bold;
	float: left;
	font-size: 16px;
	background: #f5f4f4;
}

#fontSettingsModal .font-tabs div {
	float: left;
	padding:20px 40px;
	cursor: pointer;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	color: rgba(0,0,0,0.5);
}

#fontSettingsModal .font-tabs div img {
	width: 15px;
	height: 15px;
	object-fit: contain;
	margin-right: 7px;
	opacity: 0.5;
}

#fontSettingsModal .font-tabs div:hover,
#fontSettingsModal .font-tabs div.active {
	opacity: 1;
	background: #fff;
	color: rgba(0,0,0,1);

}

#fontSettingsModal .close-modal-hotspot {
	background: transparent;
	box-shadow: 0;
}

#fontSettingsModal .font-tabs div:hover img,
#fontSettingsModal .font-tabs div.active img {
	opacity: 1;
}
.font-group {
	display: none;
	margin-top: 20px;
	white-space: nowrap;
	width: calc(100% + 40px);
	overflow: auto;
	padding-bottom: 16px;
}
.font-group.active {
	display: -webkit-flex;
	display: flex;
}

.font-group div.font-preview-card {
	font-size: 25px;
    font-weight: bold;
    display: inline-block;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    width: 180px;
    padding: 10px 15px;
    border: solid 1px #efefef;
    white-space: normal;
    margin-right: 20px;
    margin-top: 20px;
    border-radius: 10px;
    height: 130px;
    cursor: pointer;
    transition: 0.2s;
    overflow: hidden;
}

.font-group div.font-preview-card:first-child {
	margin-left: 30px;
}
.font-group.font-group-body div{
	font-size: 18px;
}

.font-group div.upload-custom-font:after {
	display: none;
}

.font-group div.upload-custom-font {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-flex-direction: column;
	        flex-direction: column;
	text-align: center;
	font-size: 16px!important;
	background: #F0F3F7;
	position: relative;
	border: none;
	font-weight: 600!important;
}

.font-group div.upload-custom-font .pro-badge {
	position: absolute;
	top: 8px;
	right: 8px;
}
.font-group div.upload-custom-font:hover {
	box-shadow: none;
	background: #fafafa;
}

.font-group div.upload-custom-font img {
	width: 17px;
	height: 17px;
	object-fit: contain;
	margin-bottom: 5px;
}


.font-group div.font-preview-card:after {
	display: block;
	content: "The quick brown fox jumps over the lazy dog.";
	font-size: 14px;
	opacity: 0.4;
	width: 100%;
	font-weight: normal;
	font-family: Source Sans pro;
	margin-top: 10px;
}
.font-group.font-group-body div.font-preview-card:after {
	opacity: 1;
    font-size: 17px;
    line-height: 27px;
    font-family: inherit!important;
}

.font-group.font-group-header div:after,
.font-group.font-group-footer div:after {
	display: none;
}

.font-group.font-group-header div,
.font-group.font-group-footer div {
	font-size: 16px;
}

.font-group.font-group-footer div strong {
	width: 100%;
	text-align: left;
	font-size: 16px;
}

.font-group.font-group-footer div {
	font-weight: normal;
	text-align: center;
	font-size: 14px;
}

.font-group.font-group-header div i {
	font-style: normal;
	font-size: 15px;
	width: 100%;
	display: block;
	margin-top: 7px;
	font-weight: normal;
}
.font-group div.font-preview-card.active,
.font-group div.font-preview-card:hover {
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
	opacity: 1;
}

.font-group div.font-preview-card.active {
	border-color: #333;
}

#fontSettingsModal.modal.side .modal-content .close:not(.burger) {
	left: auto;
	right: 20px;
}
#fontSettingsModal.modal.side .modal-content h1 {
	margin-top: 0;
}
@-webkit-keyframes modalContentIn {
	0% {opacity: 0; -webkit-transform: translateX(100px); transform: translateX(100px);}
	100% {opacity: 1; -webkit-transform: translateX(0); transform: translateX(0);}
}
@keyframes modalContentIn {
	0% {opacity: 0; -webkit-transform: translateX(100px); transform: translateX(100px);}
	100% {opacity: 1; -webkit-transform: translateX(0); transform: translateX(0);}
}

.modal.side#imagePickerModal .modal-content,
.modal.side#videoPickerModal .modal-content,
.modal.side#musicPickerModal .modal-content,
.modal.side#mapPickerModal .modal-content {
	background: linear-gradient(0deg, #3c4665 0%, #21283F 100%);
	color: #fff;
}

.modal.side#videoPickerModal .modal-content .close,
.modal.side#mapPickerModal .modal-content .close {
	-webkit-filter: invert(1);
	        filter: invert(1);
}

.modal.side.active .modal-content {
	-webkit-transform: translateY(0) translateX(0px);
	        transform: translateY(0) translateX(0px);
}

.modal-bottom-footer {
	position: absolute;
	bottom:0;
	left: 0;
	width: 100%;
	border-top:solid 1px #efefef;
	background: #fafafa;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	padding:10px;
}

.modal-bottom-footer .btn,
.modal-bottom-footer .save-btn {
	margin-top: 0!important;
}

.modal-sticky-footer {
	position: -webkit-sticky;
	position: sticky;
	bottom: 0;
	right: 0;
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	height: 50px;
	-webkit-align-items: center;
	        align-items: center;
}

.modal-sticky-footer.white {
	background: #fff;
	border-top:solid 1px rgba(0,0,0,0.1);
    z-index: 60;
    width: calc(100% + 70px);
    /* left: -35px; */
    margin-left: -35px;
    margin-right: -35px;
    padding: 0 35px;
    /*box-shadow: 0 -6px 12px rgba(0,0,0,0.1);*/
    height: 60px;
}

.modal-sticky-footer .save-btn {
	margin-top: 0;
}

.settings-content .save-btn {
	float: right;
}


.settings-content .modal-sticky-footer {
	width: calc(100% + 100px);
    margin-left: -50px;
    padding:0 50px;
    bottom: 0px;
    background:rgba(255,255,255,0.9);
    box-shadow: 0 -6px 12px rgba(0,0,0,0.05);
    opacity: 0;
    -webkit-animation:fadeInBottom 0.6s forwards;
            animation:fadeInBottom 0.6s forwards;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.tab-content.settings-content {
	padding-bottom: 0!important;
}


.modal-content {
	height: calc(100vh - 125px);
	width: 70%;
	max-width: 730px;
	background: #fff;
	box-shadow: -10px -10px 30px rgba(0,0,0,0.1);
	padding:35px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-content: start;
	        align-content: start;
	position: relative;
	overflow: scroll;
	visibility: hidden;
	opacity: 0;
	transition: all 0.3s ease;
	-webkit-transform:translateY(100px);
	        transform:translateY(100px);
}

#settingsModal .modal-content {
	height: calc(100vh - 60px);
	  padding:0;
	  border-radius: 10px 10px 0 0;
	  overflow: hidden;
}

.modal.active .modal-content {
	opacity: 1;
	visibility: visible;
	-webkit-transform:translateY(0);
	        transform:translateY(0);
}


.modal.side .extension {
	width:400px;
	height: 100%;
	position: absolute;
	background: #fff;
	padding:40px;
	top: 0;
	z-index: 4;
	transition: all 0.3s ease;
	right: -400px;
	border-left: solid 1px #efefef;
}

.modal.active.expand .extension {
	right: -10px;
	box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
}

.modal-content h1 {
	font-weight: 700;
	font-size: 26px;
	width: 100%;
	margin:20px 0 0 0;
	padding:0;
}
.modal-content .modal-description {
	font-size: 16px;
	margin:0 0 15px 0;
	opacity: 0.5;
	width: 100%;
}

#recommedationModal .custom-file-upload, #editCoverLetterModal .custom-file-upload {
	position: relative;
	width: 200px;
	height: 200px;
	border-radius: 50%;
	font-size: 12px;
	margin:0 auto;
	border: solid 1px rgba(0,0,0,0.05);
}
#recommedationModal .custom-file-upload strong, #editCoverLetterModal .custom-file-upload strong {
	font-size: 12px;
	margin-top: 110px;
	text-align: center;
}
#pageSeo.modal .modal-content {
	opacity: 0;
	-webkit-animation: fadeIn .5s forwards;
	        animation: fadeIn .5s forwards;
	right: 0px;
	height: calc(100vh - 0px);
	top: 0px;
	background: #fafafa;
	border-right:dashed 1px #efefef;
	width: 420px;
	border-radius: 0;
}
.input-group.addon .addon-line {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
}
.input-group.addon .addon-line input {
	width: 100%;
	margin-left: 20px;
}
.input-group.dates label {
	width: 100%;
}
.input-group.dates {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}
.input-group .date-input {
	position: relative;
	width: 45%;
}
.input-group .date-input img {
	position: absolute;
	width: 15px;
	top: 17px;
	left: 13px;
	-webkit-filter: invert(1);
	        filter: invert(1);
	z-index: 2;
}
.input-group .date-input input {
	padding-left: 35px;
	width: 100%;
}
.input-group.link-input {
	position: relative;
	cursor: pointer;
}
.load-more-messages {
	padding:10px 20px;
	border-radius: 20px;
	border:solid 1px #ccc;
	color: #ccc;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-content: center;
	        align-content: center;
	margin:20px auto;
	cursor: pointer;
	-webkit-align-items: center;
	        align-items: center;
	width: 220px;
	position: relative;
}

.load-more-messages:before,
.load-more-messages:after {
	width: 40px;
	height: 1px;
	content: " ";
	background: #efefef;
	position: absolute;
	left: -45px;
	top: 50%;
}
.load-more-messages:after {
	left: auto;
	right: -45px;
}
.load-more-messages i {
	margin-right: 8px;
	-webkit-transform: translateY(1px);
	        transform: translateY(1px);
}
.load-more-messages:hover {
	border-color: #333;
	color: #333;
}
.input-group.link-input .fa {
	position: absolute;
	bottom: 5px;
	left: 5px;
	border-radius: 6px 0 0 6px;
	background: #fff;
	border:solid 1px rgba(0,0,0,0.1);
	height: 40px;
	width: 40px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}
.input-group.link-input .fa:after {
	width: 6px;
	height: 6px;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
	border-top:solid 1px rgba(0,0,0,0.1);
	border-right:solid 1px rgba(0,0,0,0.1);
	position: absolute;
	top: calc(50% - 3px);
	right: -4px;
	background: #fff;
	content: " ";
}
.input-group.link-input input {
	padding-left: 60px;
	cursor: pointer;
}

.input-group.link-input:hover input {
	box-shadow: rgba(0, 123, 255, 0.5) 0px 0px 0px 1px, rgba(0, 123, 255, 0.25) 0px 0px 0px 0.2rem;
    background: #fff!important;
}
.inner-input-group {
	width: 100%;
	padding:0 15px 15px 15px;
	background: #fafafa;
	margin-top: -1px;
}
.input-group .inner-input-group .date-input {
	width:100%;
}
.inner-input-group select,
.inner-input-group input {
	width: 100%;
}
.input-group .inner-input-group label {
	margin-top: 15px!important;
	display: block;
	width: 100%;
}
.input-group .inner-input-group .date-input input {
	padding-left: 15px;
}

.input-group .inner-input-group label {
	margin-top: 15px;
}
.form-well .switch-table {
	margin-bottom: 15px;	
}
.input-group {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	margin-top: 15px;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.input-group legend {
	margin-top: 5px;
	color: #8895a0;
	font-size: 14px;
}

#addlistitem {
	outline: none;
	background: #21283F;
	border:none;
	color: #fff;
	padding:10px 20px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	border-radius: 30px;
	cursor: pointer;
	-webkit-align-items: center;
	        align-items: center;
	margin-top: 20px;
}
#addlistitem:hover {
	background: #313b5c;
}
#addlistitem:before {
	content: "+";
	font-size: 20px;
	margin-right: 10px;
	margin-left: -5px;
	-webkit-transform: translateY(-2px);
	        transform: translateY(-2px);
}
.input-group.menu-item {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	position: relative;
	margin-top: 10px;
}

.input-group.menu-item input {
	width: 100%;
	height: 40px;
}

.input-group.menu-item input:last-child {
	width: 100px;
	margin-left: 20px;
}

.input-group .remove-menu-item {
	position: absolute;
	left: -20px;
	top: 16px;
	opacity: 0;
	cursor: pointer;
	transition: all 0.2s;
	width: 15px;
	transition-delay: 0.2s;
}

.input-group:hover .remove-menu-item {
	opacity: 1;
	transition-delay: 0s;
}

.input-group.half-width label {
	width: 100%;
}
.input-group.half-width {
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-align-items: flex-end;
	        align-items: flex-end;
}
.input-group.half-width input, .input-group.half-width select {
	width: 48%;
	background: #fff;
}

.input-group.half-width select {
	margin-top: 10px;
	text-indent: 12px;
}
.input-group.half-width select option {
	padding-left: 20px;
}

.input-group label {
	font-size: 12px;
	text-transform: uppercase;
	font-weight: bold;
	margin-bottom: 4px;
	margin-top: 10px;
	position: relative;
}
.input-group label .publish-switch {
	top: -5px;
	right: 0;
	position: absolute;
}
.input-group input:focus, .input-group textarea:focus {
	/*box-shadow: 4px 4px 17px rgba(0,0,0,0.07);*/
}

.modal-content .input-group select {
	background: #f1f1f1;
}

.extension .input-group input {
	font-size: 18px;
}
.extension .input-group textarea {
	min-height: 400px;
	border: solid 1px rgba(0,0,0,0.1);
	padding:20px;
	border-radius: 6px;
	box-shadow: 0 10px 20px rgba(0,0,0,0.03);
}
.extension .input-group textarea:focus {
	border-color:#000;
}

.extension .modal-footer {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.extension .modal-footer .btn-empty {
	background: transparent;
	border: none;
	color: #333;
}

.input-group.size-set {
	width: calc(100% - 100px);
	padding-right: 10px!important;
}

.input-group.size-set h3 {
	margin: 0;
    height: 50px;
    width: 100%;
    padding: 13px 15px;
    border: solid 1px #efefef;
    border-radius: 6px;
    font-size: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
}

.input-group.size-set h3:hover {
	background: #fafafa;
	border-color: #333;
}

.input-group.size-set-smaller {
	width: 100px;
	padding-left: 0!important;
}
.input-group.size-set-smaller:before {
	content: " ";
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 1;
}
.input-group.size-set-smaller span {
	width: 20px;
    height: 20px;
    border-radius: 4px;
    border: solid 1px #ccc;
    background: #fff;
    position: absolute;
    z-index: 2;
    left: 3px;
    bottom: 35px;
    font-size: 14px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-size: 18px;
    cursor: pointer;
}
.input-group.size-set-smaller span:hover {
	background: #fafafa;
}
.input-group.size-set-smaller span.plus {
	left: auto;
	right: 23px;
}
.input-group.size-set-smaller input {
	font-size: 14px;
	text-align: center;
}
.input-group input, .input-group select, .input-group textarea {
	height: 50px;
	outline: none!important;
	transition: all 0.2s ease;
	line-height: 50px;
	font-size:16px;
	font-family: Source Sans Pro;
	border-radius: 0px;
	/*box-shadow: 4px 4px 13px rgba(0,0,0,0.04);*/
	border: none;
	padding:0 15px;
	border-radius: 6px;
	margin-bottom: 0px;
	background: #f1f1f1;
}

.input-group input[type="checkbox"],
.input-group input[type="radio"] {
	height: 15px;
    width: 15px;
    padding: 0;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}
.input-group input:focus, .input-group select:focus, .input-group textarea:focus {
		box-shadow: rgba(0, 123, 255, 0.5) 0px 0px 0px 1px, rgba(0, 123, 255, 0.25) 0px 0px 0px 0.2rem;
		background: #fff!important;
}

.input-group textarea {
	min-height: 150px;
	height: auto;
	line-height: normal;
	padding:20px;
}

button.btn {
	border:none;
	outline: none;
}

.btn {
	cursor: pointer;
	transition: all 0.2s ease;
}
.btn:hover {
	opacity: 0.8;
}

.modal .btn {
	font-size: 14px;
	text-transform: uppercase;
	border-radius: 30px;
	color: #fff;
	background: #333;
	padding:15px 30px;
	display: block;
	text-align: center;
	margin-top: 20px;
}
.btn.green {
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
    box-shadow: 0 6px 18px 0 rgba(0,0,0,0.11);
	color: #fff;
	border-radius: 20px;
}

.btn.red {
	background: linear-gradient(-135deg, #ff000085 0%, #ff0000 100%);
    box-shadow: 0 6px 18px 0 rgba(0,0,0,0.11);
	color: #fff;
	border-radius: 20px;
}

.close:not(.burger) {
	    width: 20px;
    height: 20px;
    position: absolute;
    left: 10px;
    z-index: 3;
    top: 10px;
    cursor: pointer;
}
.close:not(.burger):before, .close:not(.burger):after {
	width: 100%;
	height: 1px;
	position: absolute;
	top: 50%;
	left: 0px;
	content: " ";
	background: #000;
	-webkit-transform: rotate(-45deg);
	        transform: rotate(-45deg);
}

.with-tabs .close:not(.burger):before,
.with-tabs .close:not(.burger):after {
	background: #fff;
}
.close:not(.burger):before {
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
}



.swal-overlay {
	background: rgba(255,255,255,0.8);
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	z-index: 3333333;
}

.swal-title {
	margin-bottom: 0!important;
}

.swal-text {
	text-align: center;
}
.swal-button {
	border:none;
	border-radius: 20px;
	box-shadow: none!important;
}

.swal-button--confirm {
	background-color: #5FBF80;
}
.swal-button--confirm:hover  {
    background-color: #5FBF80 !important;
}

.swal-button--danger {
    background-color: #e64942;
}
.swal-button--danger:hover {
    background-color: #e64942 !important;
}

.swal-footer {
	text-align: center;
}
.swal-modal {
	box-shadow: none;
	border-radius: 15px;
	padding:50px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	width: 400px;
	height: 500px;
	text-align: center;
	box-shadow: 5px 10px 35px rgba(0,0,0,0.07);
	border: solid 1px #efefef;
	vertical-align: middle;
}

.modal-subtitle {
	font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 20px;
}
h3.modal-sub-title {
	margin-top: 30px;
    margin-bottom: 5px;
    width: 100%;
    font-size: 17px;
}

h1.modal-title,
h2.modal-title {
	font-family: Source Sans Pro!important;
	margin-top: 0!important;
	margin-bottom: 0!important;
}
.modal-title h2 {
	margin:0;
}
.title-badge {
	text-transform: uppercase;
	font-weight: 500;
	color: #fff;
	background: #9ea9c1;
	display: inline-block;
	padding:4px 8px;
	border-radius: 20px;
	font-size: 13px;
	margin-left: 10px;
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
}

.title-badge.limit-cleared {
	background: #89C2FF!important;
	color: white!important;
}

.title-badge.limit-approached {
	background: #ffce44!important;
	color: white!important;
}

.title-badge.limit-reached {
	background: #de5246!important;
	color: white!important;
}

.modal-title p {
	margin:0;
}

#contentsModal .modal-title h2 {
	margin:0;
}

#contentsModal .modal-title p {
	margin:10px 0 0 0;
	font-size: 14px;
	opacity: 0.8;
	padding:0;
	font-weight: 500;
}
#contentsModal .modal-title {
	margin-bottom: 30px;
}
.badge {
	background: #D7CF8C;
	border-radius: 10px;
	font-size: 12px;
	display: inline-block;
	font-style: normal;
	padding:2px 5px;
	margin-left: 5px;
	-webkit-transform: translateY(-8px);
	        transform: translateY(-8px);
	color: #fff;
}

h4.badge {
		display: block;
    float: left;
    font-size: 16px;
    padding: 6px;
    text-align: center;
}

.locked-state {
	font-size: 13px;
	text-transform: uppercase;
	padding:2px 7px;
	position: absolute;
	top: 10px;
	color: #fff;
	background: linear-gradient(to right, #4facfe 0%, #00f2fe 100%)!important;
	left: auto;
	-webkit-transform: none;
	        transform: none;
	right: 10px;
	border-radius: 6px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	z-index: 2;
}
.locked-state img {
	width: 12px;
    box-shadow: none;
    border-radius: 0;
    border: none;
}

/***SWITCH***/
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.switch-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .switch-slider {
  background-color: #5FBF80;
}

input:focus + .switch-slider {
  box-shadow: 0 0 1px #5FBF80;
}

input:checked + .switch-slider:before {
  -webkit-transform: translateX(16px);
          transform: translateX(16px);
}

/* Rounded sliders */
.switch-slider.round {
  border-radius: 34px;
}

.switch-slider.round:before {
  border-radius: 50%;
}
.spotify-tabs {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	margin-top: -20px;
	margin-bottom: 10px;
}
.spotify-tabs div {
	padding:10px 15px;
	color: #fff;
	border-radius: 40px;
	width: calc(33.333% - 5px);
	background: #21283F;
	text-align: center;
	font-size: 13px;
	cursor: pointer;
	transition: all 0.2s;
	text-transform: uppercase;
}
.spotify-tabs div.active,
.spotify-tabs div:hover {
	color: #21283F;
	background: #fff;
}
.unsplash-content {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	margin-top: 35px;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	width: 100%;
}
.infinite-scroll-component__outerdiv,
.unsplash-images {
	width: 100%;
}
#youtube-video-container {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
}
.video-card {
	width: 100%;
	margin:0 0px 30px 0px;
	position: relative;
	display: -webkit-flex;
	display: flex;
}
.video-card .video-card-description {
	width: calc(60% - 10px);
}

.video-card .video-card-description p {
	width: 100%;
	margin:0;
	font-size: 14px;
	opacity: 0.7;
}
.video-card .video-card-description span {
	margin-right: 10px;
	opacity: 0.5;
	font-size: 12px;
}
.video-card .video-card-duration {
	position: absolute;
	top: 6px;
	left: 6px;
	font-size: 12px;
	padding:3px 6px;
	color: #fff;
	background: #000;
	font-style: normal;
	cursor: pointer;
	z-index: 4;
	border-radius: 3px;
}
.video-card img {
	width: 40%!important;
	height: 100px!important;
	-webkit-flex-shrink: 0;
	        flex-shrink: 0;
	margin:0 10px 0 0!important;
}

.video-card.music img {
	height: 125px!important;
}
.video-card.music  {
	margin-bottom: 20px;
}
.video-card h4 {
	margin:10px 0 0 0;
	font-size: 16px;
	font-weight: 600;
}

.video-card p {
	display: none;
}

.confirm-upload-header {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	margin-bottom: 20px;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	left: 0;
	z-index: 3;
}

.wide .unsplash-images.upload-confirm .image-picker-thumb:hover img {
	border:none!important;
}

.image-picker-thumb .action-buttons {
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.image-picker-thumb:hover .action-buttons {
	opacity: 1;
}

.image-picker-thumb .image-upload-warning {
	font-size: 11px;
	color:#fff;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	border-radius: 3px;
	position: absolute;
	top: 3px;
	left: 3px;
	padding:3px;
	background: #E49758;
}

.side .unsplash-images .image-picker-thumb .image-upload-warning img {
	-webkit-filter: invert(1);
	        filter: invert(1);
	height: 12px;
	width: 12px;
	margin:0 3px 0 3px;
	cursor: default;
	background: transparent;
	border-radius: 0;
	object-fit: contain;
}

.upload-warning-well {
	background: #E49758;
	border-radius: 6px;
	font-size: 14px;
	padding:10px 10px 10px 34px;
	position: relative;
	margin-bottom: 15px;
}
.side .unsplash-images .upload-warning-well img {
	height: 16px;
	width: 16px;
	border-radius: 0;
	object-fit: contain;
	margin:0 8px 0 0;
	-webkit-filter: invert(1);
	        filter: invert(1);
	background: transparent;
	position: absolute;
	left: 10px;
	top: 14px;
}
.action-buttons .button {
	cursor: pointer;
}

.confirm-upload-header h1 {
	font-size: 18px;
	margin:0;
}

.confirm-upload-header .right {
	display: -webkit-flex;
	display: flex;
	width: 100%;
	-webkit-flex-direction: row-reverse;
	        flex-direction: row-reverse;
}

.confirm-upload-header .btn {
	font-size: 13px;
    padding: 10px 20px!important;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    display: inline-block;
    margin: 0;
}

.confirm-upload-header .btn.empty {
	border:none;
	color: #333;
	margin-right: 10px;
	color: #fff;
}


.unsplash-images .image-upload-loader {
	    width: calc(100% - 10px);
    background: rgba(0,0,0,0.8);
    height: 10px;
    position: absolute;
    bottom: 5px;
    border-radius: 20px;
    overflow: hidden;
    left: 5px;
}

.unsplash-images .image-upload-loader span {
	font-size: 10px;
    color: #fff;
    position: absolute;
    height: 100%;
    line-height: 10px;
    z-index: 4;
    padding: 0 3px;
}

.unsplash-images .image-upload-loader div {
	position: absolute;
	transition: 0.2s;
	z-index: 2;
	height: 100%;
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
}

.unsplash-images.upload-confirm {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 40;
	padding:30px;
	background: linear-gradient(0deg, #3c4665 0%, #21283F 100%);
}
.unsplash-images {
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	/*height: 523px;
	overflow:auto;*/
	-webkit-align-content: flex-start;
	        align-content: flex-start;
	padding-top: 6px;
}
.unsplash-images.full-height {
	/*height: calc(100vh - 200px);*/
}
#unsplashModal .input-group.with-icon,
#instagramModal .input-group.with-icon {
	margin-top: 0;
}
.modal .input-group.with-icon img {
	left: 15px;
}
.modal .input-group.with-icon .socicon,
.modal .input-group.with-icon i {
	left: 15px;
	position: absolute;
	top: 15px;
}
.modal .input-group.with-icon i {
	font-weight: bold;
	font-style: normal;
	color: #333;
}

.click-product-info .input-group.with-icon img {
	    position: absolute;
    left: 14px;
    top: 18px;
    width: 16px;
    height: 16px;
}

.modal .input-group.with-icon input {
	padding-left: 40px;
}
.unsplash-images img {
	width: calc(33.333% - 10px);
    height: 150px;
    margin-bottom: 20px;
    object-fit: cover;
    transition: all 0.2s ease;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    cursor: pointer;
}

 .side .unsplash-images img {
 	width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.2s ease;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    cursor: pointer;
    display: inline-block;
	border-radius: 6px;
	background: rgba(0,0,0,0.2);
 }

 .modal.side.wide .modal-content {
	width: 768px;
}

.wide .unsplash-images .image-picker-thumb {
	width: calc(33.333% - 10px);
}


.unsplash-images .image-picker-thumb.selected img {
	border:solid 7px #99A9C5;
	opacity: 0.7;
}

.wide .unsplash-images .image-picker-thumb:hover img {
	border:solid 7px #99A9C5;
}

.wide .unsplash-images .image-picker-thumb .insta-likes img {
	border: none!important;
	opacity: 1!important;
}

.selected-images {
	display: -webkit-flex;
	display: flex;
    font-size: 14px;
    width: 100%;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-top: 10px;
    position: -webkit-sticky;
    position: sticky;
	bottom: -20px;
	margin-right: 10px;
	margin-left: 10px;
}
.selected-images button {
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	padding:15px 30px;
	color: #fff;
	border: none;
	outline: none;
	border-radius: 40px;
    font-size: 14px;
    font-weight: bold;
    font-family: source sans pro;
	box-shadow: 0 6px 12px rgba(0,0,0,0.3);
	cursor: pointer;
}
.selected-images button.inactive {
	background: #ccc;
	cursor: no-drop;
}
.selected-images p {
	margin: 0;
	padding:5px 10px;
	border-radius: 20px;
	background: rgba(0,0,0,0.6);
}
.unsplash-images .image-picker-thumb.selected:before {
	width: 26px;
	height: 26px;
	border-radius: 50%;
	content: " ";
	position: absolute;
	top: calc(50% - 13px);
	z-index: 2;
	left: calc(50% - 13px);
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
}
.unsplash-images .image-picker-thumb.selected:after {
	width: 5px;
    height: 10px;
    content: " ";
    border-right: solid 1px #fff;
    position: absolute;
    left: calc(50% - 2px);
    top: calc(50% - 5px);
    border-bottom: solid 1px #fff;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    z-index: 2;
}
.unsplash-images .image-picker-thumb.selected:hover img {
	-webkit-transform: none;
	        transform: none;
}
.unsplash-images .image-picker-thumb {
	width: calc(33.333% - 10px);
    height: 150px;
    margin-bottom: 10px;
    position: relative;
    margin-right: 10px;
}

.unsplash-images .image-picker-thumb .insta-likes {
	box-sizing: border-box;
    position: absolute;
    z-index: 2;
    display: -webkit-flex;
    display: flex;
    bottom: 0;
    padding: 0;
    margin: 0;
    font-size: 16px;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    padding: 10px 10px 7px 10px;
    /*width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 100%);*/
}
.unsplash-images .image-picker-thumb .insta-likes img {
	width: 14px;
    height: 14px;
    object-fit: contain;
    background: transparent;
    box-shadow: none;
    border-radius: 0;
    margin-right: 5px;
    margin: 0 5px 0 0px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
}
.unsplash-images .image-picker-thumb .unsplash-credit {
	font-size: 10px;
	color: #fff;
	background: #333;
	padding:2px 4px;
	border-radius: 2px;
	position: absolute;
	bottom:5px;
	left:5px;
	opacity: 0;
	visibility: hidden;
	transition: all 0.2s;
}

.unsplash-images .image-picker-thumb:hover .unsplash-credit {
	opacity: 1;
	visibility: visible;
}
.side .unsplash-images img.current {
	box-shadow: 0 8px 16px rgba(0,0,0,0.05), 0 0 0 5px rgba(40, 199, 111, 0.4);
}
.unsplash-images img:hover {
	box-shadow: 0 5px 20px rgba(0,0,0,0.1);
	opacity: 0.8;
}

#unsplashModal .unsplash-categories {
	width: 140px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
}
#unsplashModal .unsplash-categories a {
	margin-bottom: 8px;
}
#unsplashModal .modal-content {
	max-width: 900px;
	padding-bottom: 0;
}

#instagramModal .unsplash-categories {
	width: 140px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
}
#instagramModal .unsplash-categories a {
	margin-bottom: 8px;
}
#instagramModal .modal-content {
	max-width: 900px;
	padding-bottom: 0;
}
.image-button-set {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	margin:15px auto 15px auto;
	-webkit-justify-content: center;
	        justify-content: center;
}
.profile-image-edit {
	width: 120px;
	height: 120px;
	border-radius: 50%;
	margin:0 auto;
	object-fit: cover;
}
.custom-file-upload.button {
	position: relative;
	height: auto;
	background: #000;
	color: #fff;
	width: auto;
	padding:10px 20px;
	border-radius: 20px;
	margin-bottom: 5px;
}
.custom-file-upload.button:hover {
	opacity: 0.8;
}
.custom-file-upload.button strong {
	margin-top: 0;
	opacity: 1;
	font-size: 13px;
	font-weight: 400;
	text-transform: uppercase;
}
.image-button-set .image-button  {
	opacity: 1;
	font-size: 13px;
	font-weight: 400;
	text-transform: uppercase;
	padding:10px 20px;
	cursor: pointer;
	border-radius: 30px;
	margin:5px 0;
}
.image-button-set .image-button:hover {
	opacity: 0.8;
}
.image-button-set .image-button.instagram-color {
	color: #fff;
}
.image-button-set .image-button.unsplash-color {
	color: #000;
	border:solid 1px #000;
	background: transparent;
}
.side .modal-content .modal-footer {
	margin-top: 20px;
}

.modal-content .image-container {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	margin-top: 10px;
}

.modal-content .image-container .List {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}
.modal-content .image-container .draggable {
	width: calc(50% - 5px)!important;
}
.modal-content .image-container .draggable.dragged {
	width: 160px!important;
}
.modal-content .image-container .draggable .img-thumb {
	width: 100%;
}
.modal-content .image-container .draggable.placeholder {
	height: 120px!important;
	width: calc(50% - 5px)!important;
	border:dashed 1px #ccc;
	background: #efefef;
	display: -webkit-flex!important;
	display: flex!important;
	-webkit-justify-content: center!important;
	        justify-content: center!important;
	-webkit-align-items: center!important;
	        align-items: center!important;
	font-size: 14px;
	text-align: center;
	line-height: 120px;
	font-weight: bold;
	color: #ccc;
	border-radius: 6px;
	margin-bottom: 10px;
}

.modal-content .image-container .img-thumb.add-image {
	border:dashed 1px #ccc;
	cursor: pointer;
	font-size: 12px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-content: center;
	        align-content: center;
	text-transform: uppercase;
	border-radius: 6px;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-flex-direction: column;
	        flex-direction: column;
	box-shadow: 0 6px 10px rgba(0,0,0,0);
	transition:all 0.3s;
	width: 160px;
}
.modal-content .image-container .img-thumb.add-image:before {
	display: block;
	content: "+";
	width: 30px;
	height: 30px;
	font-size: 20px;
	margin-bottom: 7px;
	color:#fff;
	text-align: center;
	line-height: 30px;
	border-radius: 50%;
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
}
.modal-content .image-container .img-thumb.add-image:hover {
	box-shadow: 0 6px 10px rgba(0,0,0,0.1);
}
.modal-content .image-container .img-thumb {
	width: calc(50% - 10px);
	height: 120px;
	margin-right: 10px;
	margin-bottom: 10px;
	position: relative;
	cursor: move;
}

.modal-content .image-container .img-thumb img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 4px;
}

.modal-content .image-container .img-thumb .button-set {
	position: absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    opacity: 0;
    visibility: hidden;
    transition:0.2s all;
}
.modal-content .image-container .img-thumb:hover .button-set {
	opacity: 1;
	visibility: visible;
}

.modal-content .image-container .img-thumb .button-set i {
	background: #fff;
	padding:5px 10px;
	font-size: 12px;
	color:#000;
	text-transform: uppercase;
	cursor: pointer;
	margin:2px 0;
	font-style: normal;
	border-radius: 20px;
	box-shadow: 0 4px 8px rgba(0,0,0,0.05);
	border:solid 1px #efefef;
}

.SearchInputBox {
	position: -webkit-sticky;
	position: sticky;
	z-index: 5;
	top: -30px;
}

.back-to-user {
	display: -webkit-flex;
	display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    color: #fff;
    font-size: 12px;
    margin-top: 20px;
    cursor: pointer;
    margin-bottom: -20px;
    text-transform: uppercase;
}

.back-to-user:hover {
	text-decoration: underline;
}

.back-to-user img {
	height: 11px;
	-webkit-filter: invert(1);
	        filter: invert(1);
	margin-right: 5px;
}

.SearchInputBox input {
	font-size: 14px;
    color: #000;
    text-transform: none;
    caret-color: #1db954;
    box-shadow: 0px 6px 12px rgba(0,0,0,0.1);
    border: none;
    width: 100%;
    padding-bottom: 10px;
    background-color: #fff;
    outline:none; 
    border-radius: 6px;
    padding:15px 10px 15px 40px;
}
.contentSpacing {
	position: relative;
}
.contentSpacing .socicon {
	position: absolute;
	color: #333;
	left: 15px;
	top: 15px;
}

.contentSpacing .imgicon {
	width: 16px;
	position: absolute;
	color: #333;
	left: 15px;
	top: 15px;
}

.contentSpacing .fa {
	position: absolute;
	color: #333;
	right: 15px;
	top: 15px;
}

.powered-by-api {
	font-size: 12px;
	text-transform: uppercase;
}

.SearchInputBox .socicon-youtube {
	color: #c4302b;
}
.SearchInputBox .socicon-instagram {
	color:  #fb3958;
}
.SearchInputBox .socicon-google {
	color: #db3236;
}
.SearchInputBox .socicon-soundcloud {
	color: #ff7700;
}
.SearchInputBox .socicon-spotify {
	color: #84bd00;
}

.SearchInputBox .socicon-vimeo {
	color: #86c9ef;
}

.SearchInputBox .socicon-twitch {
	color: #6441a5;
}

.add-page-btn {
	    background-image: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
    box-shadow: 0 6px 18px 0 rgba(0,0,0,0.11);
    padding: 15px 30px;
    border-radius: 40px;
    font-size: 14px;
    margin: 0 auto;
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.3s;
    display: inline-block;
    top: 50px;
    position: absolute;
    right: 50px;
}

.modal-content.with-tabs:before {
	height: 85px;
	width: 100%;
	left: 0;
	top: 0;
	position: absolute;
	background: #21283F;
	content: " ";
}

.modal-content.with-tabs.html-edit-tabs:before {
	height: 100px;
}
.modal-content.with-tabs.html-edit-tabs .save-btn {
	margin:auto;
}
.with-tabs .close:before,
.with-tabs .close:after {
	background:#fff;
}
.with-tabs .tabs .tab:hover, 
.with-tabs .tabs .tab.current {
	border-bottom-color: #fff;
}
.with-tabs .tabs .tab {
	border-bottom-color: #21283F;
}
.modal-content .tabs {
	z-index: 4;
}
.modal-content .tabs .tab {
	padding:15px 23px;	
	color: #fff;
}

.floating-plus-button {
	position: fixed;
    right: 40px;
    top: 35px;
    background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    font-size: 14px;
    text-transform: uppercase;
    border-radius: 20px;
    height: 40px;
    padding: 0 15px;
    cursor: pointer;
}

.floating-plus-button:hover {
	background: #28C76F;
}

.floating-plus-button img {
	margin-right: 10px;
}

.modal-content .tabs .tab.tabimage {
	    display: -webkit-flex;
	    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
}

.modal-content .tabs .tab.tabimage img {
	height: 16px;
	margin-right: 8px;
}

.modal-content .tabs .tab.tabimage .socicon {
	margin-right: 8px;
}
.modal-content .tab-content {
	width: 100%;
	padding:20px 0;
}

/**GENERAL TAB STYLES***/
.tabs {
	width: 100%;
	border-bottom: solid 1px #efefef;
	position: relative;
	display: -webkit-flex;
	display: flex;
}
.tabs .tab {
	padding:15px 30px;
	text-transform: uppercase;
	font-size: 14px;
	float: left;
	cursor: pointer;
	border-bottom: solid 2px #fff;
	opacity: 0.5;
}
.tabs .tab:hover,
.tabs .tab.current {
	opacity: 1;
	border-bottom: solid 2px #000;
}
.tab-content {
	display: none;
	padding:40px 20px;
	margin:0 auto;
	max-width: 700px;
}
.tab-content.current {
	display: block;
}
.upload-image-set {
	text-align: center;
}
#imagePickerModal .tab .react-file-reader-button {
	position: -webkit-sticky;
	position: sticky;
	bottom: 0;
}
.drop-images-here {
	width: 100%;
	height: 100%;
	min-height: 400px;
	padding:20px;
	color: #fff;
	border-radius: 6px;
	border:dashed 1px rgba(255,255,255,1);
	opacity: 0.5;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	font-size: 23px;
	font-weight: 600;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.drop-images-here img {
	-webkit-filter: invert(1);
	        filter: invert(1);
	width: 40px;
	height: 40px;
	margin-bottom: 10px;
	object-fit: contain;
}

#imagePickerModal .modal-content .tabs {
	overflow: auto;
	border-bottom-color: rgba(255,255,255,0.5);
}

#imagePickerModal .modal-content .tabs .tab {
	width: 33.333%;
	-webkit-justify-content: center;
	        justify-content: center;
	padding:15px 0;
}
#imagePickerModal .tab-content #floatingupload {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}
#imagePickerModal .upload-image-set {
	position: -webkit-sticky;
	position: sticky;
	top: -20px;
	z-index: 4;
	margin-bottom: -20px;
}
.upload-image-set .change-image-btn {
	background: #fff;
	color: #333;
}
.upload-image-set img {
	border-radius: 6px;
	box-shadow: 0 5px 15px rgba(0,0,0,0.1);
	margin-bottom: 10px;
	width: 100%;
}

.slide-in-modal.active {
	-webkit-animation: cartIn .3s forwards;
	        animation: cartIn .3s forwards;
}

.block-picker-container {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: nowrap;
	        flex-wrap: nowrap;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	overflow: auto;
	padding-left: 30px;
	padding-top: 20px;
}

.modal#contentsModal,
.modal#addSection  {
	-webkit-animation:fadeIn 0.3s forwards;
	        animation:fadeIn 0.3s forwards;
}

#addSection .modal-content {
	width: 100vw;
	max-width: none;
	max-width: initial;
	background: #fafafa;
	top: auto;
	bottom: 0;
	right: 0;
	border-radius: 0;
	height: auto;
	padding:25px 0;
	-webkit-animation:fadeInBottom 0.3s forwards;
	        animation:fadeInBottom 0.3s forwards;
}
#addSection .close-modal-hotspot {
	background: rgba(0,0,0,0.6);
}
#addSection .modal-content .modal-title {
	padding-left: 30px;
}
#addSection .modal-content .close {
	left: auto;
	right: 20px;
}
.block-picker-container h4 {
	width: 100%;
    margin: 30px 0 10px 0;
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: solid 1px rgba(255,255,255,0.1);
    padding-bottom: 5px;
}
.block-picker-container-item {
	width: 250px;
    margin-right: 40px;
    cursor: pointer;
    transition: 0.2s;
    border-radius: 4px;
    position: relative;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

/*.block-picker-container-item:before {
	width:30px;
	height: 30px;
	border-radius: 50%;
	box-shadow: 0 4px 8px rgba(0,0,0,0.1);
	content: "+";
	text-align: center;
	line-height: 30px;
	font-size: 18px;
	color: #fff;
	position: absolute;
	left: calc(50% - 15px);
	top: 40px;
	transition: all 0.2s;
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
	opacity: 0;
}
.block-picker-container-item:hover:before {
	opacity: 1;
	top: 34px;
}*/
.block-picker-container-item img {
	transition: 0.2s;
	height: 144px;
	object-position: 0 0;
    width:100%;
    object-fit: cover;
    margin-bottom: 5px;
    box-shadow: 0 8px 26px rgba(0,0,0,0.1);
    transition: 0.3s;
}

@-webkit-keyframes imgBlockSprite {
  0% {object-position: 0 0}
  1.9% {object-position: 0 0}
  2% {object-position: -255px 0}
  33% {object-position: -255px 0}
  33.1% {object-position: -500px 0}
  66% {object-position: -500px 0}
  66.1% {object-position: 0px 0}
  100% {object-position: 0px 0}
}

@keyframes imgBlockSprite {
  0% {object-position: 0 0}
  1.9% {object-position: 0 0}
  2% {object-position: -255px 0}
  33% {object-position: -255px 0}
  33.1% {object-position: -500px 0}
  66% {object-position: -500px 0}
  66.1% {object-position: 0px 0}
  100% {object-position: 0px 0}
}

.block-picker-container-item:hover img {
  box-shadow: 0 8px 36px rgba(0,0,0,0.15);
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  -webkit-animation: imgBlockSprite 3s infinite;
          animation: imgBlockSprite 3s infinite;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

.block-picker-container-item span {
	font-weight: bold;
	font-size: 16px;
	display: block;
}
.block-picker-container-item p {
	font-size: 14px;
    margin: 0;
    width: 100%;
}

.modal-main-image-preview,
.modal-main-video-preview {
	width: 100%;
	position: relative;
	margin-top: 0px;
	padding:0 0px;
}
.modal-main-video-preview {
	padding:0;
	height: 200px;
}

.modal-main-video-preview iframe {
	width: 100%;
	height: 100%;
	outline: none;
	border: none;
	border-radius: 6px;
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}

.modal-image-form .input-group:last-of-type {
	margin-bottom: 50px;
}
.modal-main-image-preview:hover .hover-box,
.modal-main-video-preview:hover .hover-box {
	opacity: 1;
	visibility: visible;
}
.modal-main-video-preview .hover-box {
	width:auto;
	height: auto;
	left: auto;
	top: auto;
	right: 20px;
	bottom: 20px;
}
.modal-main-image-preview img {
	width: 100%;
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
	max-height: 300px;
	object-fit: cover;
	border-radius: 10px;
}
.modal-image-form {
	width: 100%;
}
.create-product-form {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
}
.create-product-form .variants {
	padding-left: 30px;
	width: 80%;
}
.create-product-form .input-group.product-imgs {
	width: 20%;
	padding-right: 30px;
}
.react-tagsinput {
	height: auto!important;
}
.modal .react-tagsinput {
	border:none;
	padding:0;
	margin-bottom: 50px;
	background: transparent;
}
.input-group .react-tagsinput-input {
	height: auto;
	line-height: normal;
	min-width: 87px;
	padding:10px;
	border-radius: 20px;
	cursor: pointer;
}
.input-group .react-tagsinput-input:placeholder {
	color: #fff;
}
.input-group .react-tagsinput-input:focus {
	background:#fff;
	color: #000;
}
.modal .react-tagsinput-tag {
	background: #fff;
    border-radius: 20px;
    padding: 10px 20px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border: solid 1px #efefef;
    color: black;
}
.modal .react-tagsinput-remove {
	margin-left: 10px;
}
.side .modal-content .modal-image-form .modal-footer {
	/*position: absolute;
    top: 0px;
    right: 10px;
    margin: 0;
    width: auto;
    padding-top: 0;*/
}



.side .modal-footer .cancel-btn {
	color: #333;
}
.side .modal-footer .cancel-btn:hover {
	color: #000;
}
.modal-image-form textarea {
	min-height: 100px;
}
.block-slide-products-container {
	width: 100%;
}
.block-slide-products-container .separator {
	opacity: 0.1;
	margin:20px auto;
}
.block-slide-product {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	border-radius: 6px;
	box-shadow: 0 8px 16px rgba(0,0,0,0.05);
	-webkit-align-items: center;
	        align-items: center;
	margin-bottom: 10px;
	transition: all 0.2s;
	border:solid 1px #efefef;
	cursor: -webkit-grab;
	cursor: grab;
	position: relative;
	background: #fff;
	color: #000;
}
.block-slide-product.disabled {
	opacity: 0.7;
}
.block-slide-product.disabled:hover {
	opacity: 1;
}

.block-slide-product:hover {
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}

.block-slide-product img {
	width: 80px;
	height: 80px;
	object-fit: cover;
	margin-right: 20px;
	border-radius: 6px 0 0 6px;
}

#pageSeo .block-slide-product img {
	display: none;
}

#pageSeo .block-slide-product {
	padding:15px;
	cursor: pointer;
}



.block-slide-product h4 {
	margin:0;
	font-size: 16px;
}

.block-slide-product span {
	font-size: 14px;
	opacity: 0.5;
	display: block;
	margin-top: -2px;
}
.remove-slide-product {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #000;
	content: "+";
	color: #fff;
	line-height: 20px;
	text-align: center;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
	position: absolute;
	right: -5px;
	top: -5px;
	cursor: pointer;
}

.chart-type-dropdown-wrapper {
	width: 100%;
	height: 52px;
	position: relative;
	z-index: 4;
}

.chart-type-dropdown-wrapper .dropdown-arrow {
	position: absolute;
    right: 12px;
    top: 24px;
    width: 10px;
    z-index: 7;
}
.chart-type-dropdown-wrapper:hover,
.chart-type-dropdown-wrapper.active {
	z-index: 6;
}

.chart-type-dropdown {
	width: 100%;
	border:solid 1px #efefef;
	border-radius: 6px;
	font-size: 15px;
	font-weight: 500;
	position: absolute;
	top: 0;
	left: 0;
	transition: all 0.2s;
	z-index: 4;
	background: #fff;
	color: #21283F;
}
.chart-type-dropdown:hover {
	box-shadow: 0 8px 16px rgba(0,0,0,0.05);
	z-index: 5;
}
.chart-type-dropdown div {
	width: 100%;
	padding:10px 20px;
	cursor: pointer;
	height: 50px;
	display: none;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
}

.chart-type-dropdown:hover div,
.chart-type-dropdown div.active {
	display: -webkit-flex;
	display: flex;
    font-size: 21px;
}

#fontSettingsModal .chart-type-dropdown-wrapper {
	overflow: hidden;
	border-radius: 6px;
}

#fontSettingsModal .chart-type-dropdown-wrapper.active {
	overflow: visible;
}
.chart-type-dropdown:hover div.active,
.chart-type-dropdown:hover div:hover {
	background: #fafafa;
}

.chart-type-dropdown div img {
	width: 14px;
	margin-right: 10px;
}

.chart-form {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	width: 100%;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}
.chart-form.card {
	padding: 15px 15px 5px 15px;
    border-radius: 6px;
    position: relative;
    margin-bottom: 30px;
    border:solid 1px #efefef;
}
.chart-form.card:last-child {
	margin-bottom: 0;
}
.chart-form.card .input-group {
	margin-top: 0;
}
.chart-form h4.chart-label {
	font-size: 18px;
    margin: 0;
    position: absolute;
    left: 0px;
    top: -24px;
    opacity: 0.3;
}
.chart-form .color-select {
	margin-bottom: 0;
}
.chart-form .input-group.half {
	width: calc(50% - 10px);
}

.chart-form .input-group.half input {
	width: 100%;
	margin-bottom: 10px;
}
.chart-form-input {
	position: relative;
}

.chart-form-input.with-color-select {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.chart-form-input.with-color-select .color {
	width: 39px;
	-webkit-transform: translateY(-5px);
	        transform: translateY(-5px);
	height: 30px;
	border-radius: 50%;
	cursor: pointer;
	margin-left: 10px;
}
.remove-chart-value {
	position: absolute;
    left: -20px;
    top: 18px;
    transition: all 0.1s;
    opacity: 0.3;
    cursor: pointer;
    width: 13px;
}
.chart-form-input .remove-chart-value:hover {
	opacity: 1;
}
.modal .product-variables {
	display: -webkit-flex;
	display: flex;
	position: relative;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.modal .product-variables.wrap {
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
    transition: 0.2s;
}

#editProductModal .wrap .default-prices {
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

#editProductModal .wrap .default-prices .input-group {
	width: calc(11% - 10px)!important;
	margin:0 0 10px 0!important;
}
.product-variables .remove-product-img {
	position: absolute;
	left: -20px;
	bottom: 28px;
	transition: all 0.2s;
	opacity: 0;
	cursor: pointer;
	width: 13px;
}
.product-variables:hover .remove-product-img {
	opacity: 1;
}
#editProductModal .modal-content {
	padding-bottom: 0;
    width: calc(100vw - 40px);
    max-width: none;
    max-width: initial;
    -webkit-animation: productModalIn 0.5s forwards;
            animation: productModalIn 0.5s forwards;
    display: -webkit-flex;
    display: flex;
    top: auto;
    bottom: 0;
    border-radius: 0;
    height: calc(100vh - 50px);
    right: 20px;
}

@-webkit-keyframes productModalIn {
	from {-webkit-transform: translateY(100px);transform: translateY(100px); opacity: 0}
	to {-webkit-transform: translateY(0px);transform: translateY(0px); opacity: 1}
}

@keyframes productModalIn {
	from {-webkit-transform: translateY(100px);transform: translateY(100px); opacity: 0}
	to {-webkit-transform: translateY(0px);transform: translateY(0px); opacity: 1}
}
#editProductModal .modal-content .image-container .List {
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
}
#editProductModal .modal-content .image-container .draggable {
	width: calc(50% - 5px)!important;
	margin-left: 2px;
	margin-right: 2px;
	transition: 0.1s;
	margin-bottom: 5px;
}
#editProductModal .modal-content .image-container .draggable.dragged {
	width: 90px!important;
}
#editProductModal .modal-content .image-container .draggable:first-of-type {
	width: 100%!important;
}
#editProductModal .modal-content .image-container .draggable:first-of-type .img-thumb {
	height: 160px;
}
#editProductModal .modal-content .image-container .img-thumb {
	height: 100px;
}
#editProductModal .modal-content .image-container .img-thumb {
	margin-bottom: 0px!important;
}
#editProductModal .edit-product-basic-info {
	width: 370px;
	position: relative;
}

@media (max-width: 779px) { 
	#editProductModal .modal-content {
		width: 100vw!important;
		height: 100vh;
		top: 0;
		right: 0;
		-webkit-flex-direction: row;
		        flex-direction: row;
	}
	#editProductModal .edit-product-basic-info,
	#editProductModal .edit-product-extra {
		width: 100%;
		margin-bottom: 30px;
	}


}
#editProductModal .default-prices {
	display: -webkit-flex;
	display: flex;
	margin-bottom: 0px;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	width: 100%;
}

.product-option-switch {
	    font-size: 15px;
    padding: 15px;
    box-shadow: 0 6px 12px rgba(0,0,0,0.05);
    margin-bottom: 20px;
    border: solid 1px #efefef;
    border-radius: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
    cursor: pointer;
}
.product-option-switch:before {
	    width: 40px;
    height: 20px;
    border-radius: 10px;
    background: #fafafa;
    border: solid 1px #efefef;
    display: inline-block;
    content: " ";
    margin-right: 10px;
    transition: 0.2s;
}

.product-option-switch:after {
	width: 18px;
	height: 18px;
	background: #fff;
	border-radius: 50%;
	border:solid 1px #efefef;
	content: " ";
	position: absolute;
	top: 16px;
	left: 15px;
	transition:0.2s;

}

.product-option-switch.active:after {
	left: 37px;
}

.product-option-switch.active:before {
	background: #28C76F;
}


#editProductModal .default-prices .input-group {
	width: calc(50% - 10px);
	margin-bottom: 0!important;
	margin-top: 15px;
}
#editProductModal .add-product-image {
	width: 100%;
	padding:15px 0;
	border-radius: 6px;
	border:dashed 1px #efefef;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 14px;
    font-weight: 600;
    box-shadow: 0 4px 8px rgba(0,0,0,0.06);
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
}
#editProductModal .add-product-image:before {
	width: 25px;
	height: 25px;
	content: "+";
	line-height: 25px;
	font-size: 17px;
	color: #fff;
	border-radius: 50%;
	margin-right: 10px;
	display: inline-block;
	text-align: center;
	transition: 0.3s;
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
}
#editProductModal .add-product-image:hover:before {
	-webkit-transform: scale(1.2);
	        transform: scale(1.2);
}
#editProductModal .edit-product-extra {
	width: 300px;
}
#editProductModal .modal-content .tab-content {
	height: calc(100vh - 85px);
	padding-top: 0;
	padding-bottom: 100px;
}
#editProductModal .modal-content .switch-table {
	margin-top: 15px;
	border:solid 1px rgba(0,0,0,0.2);
	background: #fafafa;
}
.modal .product-variables label {
	width: calc(25% - 10px);
}

.modal .product-variables label:nth-child(1) {
	width: 100%;
}

.modal .product-variables .input-group {
	width: calc(28% - 10px);
}
.modal .product-variables .input-group input {
	padding:0 0 0 6px;
}
.modal .product-variables .input-group:nth-child(1) {
	width: 40%;
}

.input-group label.switch {
	margin-bottom: 0;
}
.product-modal-footer {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	height: 70px;
	border-top: solid 1px #efefef;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}
.product-modal-footer .save-btn,
.product-modal-footer .cancel-btn {
	margin:0 10px;
	cursor: pointer;
}
.modal #categoryTable {
	margin-bottom: 15px;
}

.modal #categoryTable .table-row div {
	width: auto!important;
}

.modal .product-variables label {
	font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 0px;
}
.modal .product-variables .input-group {
	margin-top: 0;
	margin-bottom: 10px;
}

.modal .nav-position {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}
.modal .nav-position span {
	width: calc(50% - 10px);
	margin:5px 5px 0 5px;
	opacity: 0.4;
	cursor: pointer;
}
.modal .nav-position span:hover,
.modal .nav-position span.active {
	opacity: 1;
}
.modal .nav-position span img {
	width: 100%;
	border:solid 1px #efefef;
}
.color-settings-well {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	border-radius: 6px;
	border:solid 1px #efefef;
	-webkit-align-items: center;
	        align-items: center;
	padding:10px;
}
.nav-logo-settings {
	display: -webkit-flex;
	display: flex;
	background: #efefef;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: center;
	        align-items: center;
	border-radius: 4px;
	border:solid 1px #efefef;
	height: 50px;
	position: relative;
	padding-left: 40px;
}
.nav-logo-settings img {
	height: 100%;
    width: 100%;
    cursor: pointer;
    object-fit: contain;
    padding: 5px;
}

.hide-nav-element {
	width: 26px;
	height: 26px;
	border-radius: 4px;
	position: absolute;
	left: 5px;
	bottom: 11px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	background: #fff;
	border:solid 1px #efefef;
	font-size: 12px;
	cursor: pointer;
}

.hide-nav-element:hover {
	background: #efefef;
}

.hide-nav-element.active .fa.fa-eye-slash:before {
	content: "\f06e"
}

.hide-nav-element span {
	width: 60px;
	text-align: center;
	padding:4px 0px;
	background: #333;
	position: absolute;
	left: calc(50% - 30px);
	bottom: 100%;
	opacity: 0;
	font-size: 11px;
	color: #fff;
	border-radius: 4px;
	visibility: hidden;
	transition:0.2s;
	z-index: 3;
}

.hide-nav-element:hover span {
	opacity: 1;
	visibility: visible;
}
.nav-logo-settings img:hover {
	opacity: 0.5;
}

.nav-logo-settings .publish-switch {
	-webkit-transform: scale(0.8);
	        transform: scale(0.8);
}
.logo-size-settings {
	width: calc(100% - 120px);
	height: 100%;
	border-right:solid 1px #efefef;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	font-weight: 600;
	font-size: 13px;
	padding:0 10px;
	-webkit-align-items: center;
	        align-items: center;
	margin-right: 5px;
}

.logo-size-settings div {
	width: 30px;
	height: 30px;
	border-radius: 6px;
	border:solid 1px #efefef;
	background: #fff;
	color: #333;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	font-size: 18px;
	font-weight: 600;
	cursor: pointer;
}
.logo-size-settings div:last-child {
	margin-right: 0;
}

.logo-size-settings div:hover {
	color: #fff;
	background: #7FCA76;
}

#emailSettings .modal-content {
	height: calc(100vh - 150px);
	top:auto;
	bottom: 0;
}
#emailSettings .close-modal-hotspot {
	background: transparent;
}
#emailSettings .modal-content h4 {
	font-size: 20px;
	margin:0;
}

#emailSettings .modal-content legend {
	font-size: 14px;
	line-height: normal;
	opacity: 0.6;
	margin-bottom: 20px;
}
.email-html-holder {
	width: 100%;
	max-width: 600px;
}
.table-row .fa-pen {
	font-size: 12px;
    padding: 5px;
    border: solid 1px #efefef;
    border-radius: 4px;
    margin-right: 8px;
    margin-left: -10px;
    cursor: pointer;
}
.table-row .fa-pen:hover {
	background: #efefef;
	border-color: #ccc;
}
.switch-table {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	border-radius: 6px;
	overflow: hidden;
	background: #fff;
}
.switch-table .table-row {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	height: 50px;
	color: #000;
	font-size: 14px;
	font-weight: 500;
	padding:0 20px;
	border-bottom: solid 1px #efefef;
}
.switch-table .table-row h4 {
	margin:0;
	font-size: 17px;
}
.switch-table .table-row:last-of-type{
	border-bottom: none;
}

.dark-white-buttons {
	display: -webkit-flex;
	display: flex;
	border-radius: 50px;
	overflow: hidden;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	margin:0;
	position: absolute;
	right: 0;
	top: -11px;
	border:solid 1px #0F121C;
	background: #0F121C;
	box-shadow: 0 0 0 2px rgba(255,255,255,0.1);
}

.dark-white-buttons.not-absolute {
	position: relative;
	top: auto;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	margin:10px auto;
	float: left;
}
.dark-white-buttons.not-absolute button {
	width: auto;
	padding:10px 20px;
}
.dark-white-buttons button {
	outline: none;
	text-transform: uppercase;
	border:none;
	width: 70px;
	margin:0;
	height: 40px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	padding:10px 0;
	background: #0F121C;
	border-radius: 50px;
	cursor:pointer;
	color: #fff;
}

.dark-white-buttons button:not(.active):hover {
	background: #181C29;
}

.dark-white-buttons button.active {
	background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(229,229,229,1) 100%); 
	color: #21283F;
}

.dark-white-buttons button:first-child {
	border-right: solid 1px #efefef;
}
.modal .ace_editor {
	width: 100%!important;
}
.picker-iframe {
	width: 100%;
	margin-top: 30px;
}

#mapPickerModal iframe {
	width: 100%;
	margin-top: 30px;
	border-radius: 10px;
	border: 0;
}

.loader-modal.modal .modal-content {
  height: auto;
  min-height: 280px;
  max-width: 500px;
  border-radius: 0 0 10px 10px;
}

.loader-modal.modal .modal-content .success-icon {
	margin:20px auto;
	-webkit-transform: scale(0.7);
	        transform: scale(0.7);
}
.loader-modal.modal .modal-content .modal-footer {
	padding:0;
}

.loader-modal.modal .modal-content {
	-webkit-transform: translateY(-50px);
	        transform: translateY(-50px);
}
.loader-modal.modal.active .modal-content {
	-webkit-transform: translateY(0px);
	        transform: translateY(0px);
}

.loader-modal .modal-content .modal-title {
  width: 100%;
  text-align: center;
}

.loader-modal .modal-content .modal-title h2,
.loader-modal .modal-content .modal-title {
  margin:0;
}

.loader-modal.modal {
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
}

.loader-modal .page-loader {
  position: relative;
    opacity: 1;
    left: auto;
    top: auto;
    visibility: visible;
    margin:30px auto;
    -webkit-transform: scale(1.2,1.2);
            transform: scale(1.2,1.2);
}
.publish-progress,
.publish-success {
  width: 100%;
}

.success-icon {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border:solid 2px #5FBF80;
  position: relative;
  margin:50px auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.success-icon:before {
  width: 30px;
  height: 15px;
  border-bottom: solid 2px #5FBF80;
  border-left:solid 2px #5FBF80;
  -webkit-transform: rotate(315deg);
          transform: rotate(315deg);
  margin-top: -10px;
  content: " ";
}

.publish-share-container {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	margin-top: 10px;
	width: 100%;
	max-width: 500px;
	margin:0 auto;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-animation:fadeInBottom 1s forwards;
	        animation:fadeInBottom 1s forwards;
}
.publish-share-container h1 {
	margin:15px 0;
	font-size: 20px;
	color: #fff;
	width: 100%;
	text-align: center;
}
.publish-share-card {
	width: 290px;
	border-radius: 6px;
	overflow: hidden;
	position: relative;
	background: #fff;
}
.publish-share-card  .publish-share-card-description {
	padding:15px;
}
.publish-share-card:hover .hover-box {
	opacity: 1;
	visibility: visible;
}

.publish-share-card .hover-box .change-image-btn img {
	-webkit-filter: invert(1);
	        filter: invert(1);
	margin-right: 10px;
}
.publish-share-card h4 {
	margin:0;
	font-size: 16px;
}
.publish-share-card p {
	font-size: 14px;
	margin:0;
	width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.publish-share-card span {
	font-size:12px;
	opacity: 0.4;
	margin:20px 0 0 0;
	display: inline-block;
}
.publish-share-card img {
	height: 160px;
	width: 100%;
	object-fit: cover;
}

.publish-share-card-legend {
	width: 230px;
	font-size: 12px;
	margin-top: 10px;
	color: #fff;
}

.publish-share-card-legend i {
	text-decoration: underline;
}
.publish-share-icons {
	padding:0 0px;
	margin-top: 20px;
	color: #fff;
	width: 200px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.publish-share-icons div {
	margin-bottom: 15px;
	cursor: pointer;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	position: relative;
	-webkit-align-items: center;
	        align-items: center;
	outline: none;
}

.publish-share-icons div span {
	position: absolute;
	opacity: 0;
	width: 70px;
	text-align: center;
	padding:4px;
	background: #000;
	font-size: 12px;
	color: #fff;
	border-radius: 4px;
	 bottom: -26px;
	 left: calc(50% - 35px);
	 transition: 0.2s;
	 visibility: hidden;
	 -webkit-transform: translateY(5px);
	         transform: translateY(5px);
}

.publish-share-icons div:hover span {
	opacity: 1;
	visibility: visible;
	-webkit-transform: none;
	        transform: none;
}
.publish-share-icons .socicon,
.publish-share-icons .fa {
	width: 30px;
	height: 30px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	border-radius: 50%;
	font-size: 13px;
}

.publish-share-icons .fa {
	background: #fff;
	color: #000;
}



#helpModal .modal-content {
	width: 100%;
	max-width: 500px;
	border-radius: 8px;
	height: auto;
}
#helpModal .modal-title p {
	font-size: 16px;
	margin-bottom: 20px;
}
#helpModal .modal-title a {
	color: #99A6C0;
}
#helpModal .modal-title a:hover {
	text-decoration: underline;
}
#helpModal .input-group {
	margin-top: 12px;
}
#helpModal .modal-content .close {
	left: auto;
	right: 20px;
}
#helpModal .modal-content h1 {
	margin-top: 0;
}

.modal.loader-modal {
	display: none;
}

.modal.loader-modal.active {
	display: -webkit-flex;
	display: flex;
	background: rgba(0,0,0,0.8);
	background: linear-gradient(0deg, rgba(60,70,101,1) 0%, rgba(33,40,63,0.7) 100%);
	z-index: 40000;
}

.help-modal-table {
	width: 100%;
	margin-top: 20px;
}
.help-modal-table a {
	float: left;
	padding:20px 10px;
	border-top: solid 1px #efefef;
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}
.help-modal-table a:hover {
	background-color: #fafafa;
}
.help-modal-table a .socicon {
	margin-right: 10px;
}

/**GENERAL TAB STYLES END***/

@media (max-width: 779px) {
	#editProductModal .modal-content .image-container {
		-webkit-flex-wrap: wrap;
		        flex-wrap: wrap;
		-webkit-flex-direction: column-reverse;
		        flex-direction: column-reverse;
	}
	.modal.side.wide .modal-content {
		width: 100vw;
	}
	.wide .unsplash-images .image-picker-thumb {
		height: 80px;
	}

	#editProductModal .add-product-image {
		border:none;
		height: auto;
		width: 130px;
		width: auto;
		padding:10px 20px;
		background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);
		-webkit-flex-direction: row;
		        flex-direction: row;
		color:#fff;
		margin-bottom: 20px;
	}
	#editProductModal .add-option-button {
		    width: 100%!important;
    text-align: center;
    border-radius: 6px;
	}
	#editProductModal .add-product-image:before {
		background: transparent;
	}
	#editProductModal .modal-content .image-container .draggable {
		width: 80px!important;
	}
	#pageSeo.modal .modal-content {
		width: 100vw;
		right: 0;
	}
	.modal.side .modal-content {
		width: 100%!important;
	}
	.promo-modal-container {
		right: 0;
		padding:20px;
	}
	.promo-modal-container .promo-modal-img {
		display: none;
	}
	.modal.side .modal-content {
	/*height: 100vh; */
	height: 100%;	
	top: 0;
	right: 0;
	border-radius: 8px 0 0 8px;
	}
	.side#pageSeo .modal-content .modal-footer {
		position: relative;
		margin-top: 0;
	}
	.modal .react-tagsinput {
		margin-bottom: 15px;
	}
	.create-product-form .variants {
		padding-left: 0px;
		width: 100%;
	}
	.create-product-form .input-group.product-imgs {
		width: 100%;
		padding-right: 0px;
	}
	#editProductModal .wrap .default-prices {
		-webkit-flex-wrap: nowrap;
		        flex-wrap: nowrap;
	}
	#editProductModal .wrap .default-prices .input-group {
		-webkit-flex-shrink: 0;
		        flex-shrink: 0;
		width: 100px!important;
		margin-right: 10px!important;
	}

	#fontSettingsModal.modal.side .modal-content {
		padding:0;
		height: 238px;
		width: 100vw!important;
		border-radius: 0;
	}
	#fontSettingsModal.modal.side .modal-content .close {
		display: none!important;
	}
	#fontSettingsModal .font-tabs div {
		padding:10px 13px;
	}
	#fontSettingsModal .font-group {
		padding-left: 20px;
	}
	.font-group div {
		font-size: 19px;
	}
	.unsplash-images .image-picker-thumb {
	width: calc(50% - 10px)!important;
	height: 150px!important;
	margin: 5px;
	}
	.modal.side .modal-content.with-tabs {
		padding:50px 0px;
	}
	.modal.side#imagePickerModal .modal-content.with-tabs {
		padding-top: 20px;
	}

	.confirm-upload-header {
		-webkit-flex-wrap: wrap;
		        flex-wrap: wrap;
	}

	.confirm-upload-header h1 {
		margin-bottom: 20px;
		display: none;
	}
	.confirm-upload-header .right {
		margin-bottom: 15px;
	}

	.floating-plus-button  {
		top: 10px;
		right: 10px;
	}
	.modal-content .tab-content {
		padding:20px;
	}

	.modal.side#imagePickerModal .modal-content, 
	.modal.side#videoPickerModal .modal-content, 
	.modal.side#musicPickerModal .modal-content, 
	.modal.side#mapPickerModal .modal-content {
		width: calc(100% - 0px)!important;
		-webkit-animation: slideInRight 0.8s forwards;
		        animation: slideInRight 0.8s forwards;
	}

	.modal.side#mapPickerModal .modal-content  {
		padding:30px 0px;
	}
	.modal.side#videoPickerModal .modal-content {
		padding-left: 0px;
		padding-right: 0px;
	}
	.modal-content .tabs .tab {
		padding:15px!important;
	}

	.html-tabs .dark-white-buttons {
		position: fixed;
		top: 10px;
		right: 10px;
	}
	.modal#menuLinksModal .modal-content {
		width: 100%;
		padding:20px;
		padding-bottom: 0;
		height: 100vh;
	}


}

#themeSettingsModal {
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
}

#themeSettingsModal .modal-content {
	visibility: visible;
	opacity: 1;
	-webkit-transform: none;
	        transform: none;
	-webkit-animation:fadeInBottom 0.5s forwards;
	        animation:fadeInBottom 0.5s forwards;
	border-radius: 6px 6px 0 0;
	width: 80%;
	max-width: 1000px;
	height: calc(100vh - 45px);
	padding:0;
	display: block;
}
#themeSettingsModal .close {
	left: auto;
	right: 20px;
}
.themes-navigation {
	width: 270px;
	padding:15px;
	overflow: scroll;
	height: 100%;
	float: left;
	border-right:solid 1px #efefef;
}

.themes-navigation.narrow-fit {
	width: 220px;
}

.themes-navigation.narrow-fit div:after {
	/*width: 100%;
	height: 1px;
	background: #efefef;
	bottom: -2px;
	content: " ";
	position: absolute;
	left: 0;*/
}

.themes-navigation div {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: center;
	        align-items: center;
	padding:15px;
	border-radius: 6px;
	margin-bottom: 6px;
	opacity: 0.7;
	position: relative;
	cursor: pointer;
}

.themes-navigation div img {
	width: 17px;
	height: 19px;
	object-fit: contain;
	margin-right: 15px;
}
.themes-navigation div h4 {
	margin:0;
	font-size: 16px;
}

.themes-navigation div h4 span {
	font-size: 13px;
	line-height: 13px;
	font-weight: normal;
	width: 100%;
	float: left;
}

.themes-navigation div.active, 
.themes-navigation div:hover {
	opacity: 1;
	background: #EBF3FB;
}

.page-name-content {
	width: calc(100% - 220px);
	height: 100%;
	display: none;
	position: absolute;
	right: 0;
	top: 0;
	background: inherit;
    display: none;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.page-name-content.active {
	display: -webkit-flex;
	display: flex;
	-webkit-animation:fadeIn 0.4s forwards;
	        animation:fadeIn 0.4s forwards;
}
.page-name-form {
	width: 100%;
    padding: 40px;
    max-width: 500px;
    margin: 50px auto 0 auto;
    -webkit-animation:fadeInTop 0.5s forwards;
            animation:fadeInTop 0.5s forwards;
}

.page-limit-reached-container {
	width: 100%;
	height: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-content: center;
	        align-content: center;
	-webkit-flex-direction: column;
	        flex-direction: column;
	position: absolute;
	right: 0;
	left: 0;
	background: #fff;
	padding:70px;
	text-align: center;
}

.page-limit-reached-container h1,
.page-limit-reached-container h3 {
	margin:0;
}

.page-limit-reached-container h3 {
	font-weight: normal;
}

.page-limit-reached-container img {
	width: 300px;
	margin:30px auto;
}

.page-limit-reached-container .btn {
	margin:0 auto;
	font-size: 16px;
    border-radius: 40px;
}

.page-name-thumb {
	width: 320px;
	margin:0 auto;	
	border-radius: 10px 10px 0 0;
	border:solid 1px #efefef;
	/*background: #fff;*/
	position: relative;
	box-shadow: 0px 0px 26px rgba(0,0,0,0.1);
	-webkit-animation:fadeInBottom 0.5s forwards;
	        animation:fadeInBottom 0.5s forwards;
	margin-bottom: 40px;
}

.page-name-thumb .page-name-thumb-img {
	width: 100%;
	display: block;
	border-radius: 6px 6px 0 0;
}

.page-name-thumb .layout-tip {
	height: 70px;
	height: 70px;
	object-fit: contain;
	top: 10px;
	left: 10px;
	position: absolute;
	-webkit-animation:sizeIn 0.8s forwards;
	        animation:sizeIn 0.8s forwards;
}

.page-name-tip {
	width: 100%;
	position: absolute;
	bottom: 0;
	right: 0;
	background: #efefef;
	display: -webkit-flex;
	display: flex;
	padding:20px;
	font-size: 16px;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-animation:fadeInBottom 0.5s forwards;
	        animation:fadeInBottom 0.5s forwards;
}
.tip-icon {
	width: 20px;
    height: 20px;
    margin-right: 30px;
    object-fit: contain;
}

.page-name-tip p {
	margin:0;
}

.page-name-tip p img {
	width: 14px;
    height: 14px;
    object-fit: contain;
    -webkit-transform: translateY(3px);
            transform: translateY(3px);
}
.page-limit-number {
	height: 26px;
    padding: 6px 12px;
    position: absolute;
    background: #89C2FF;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    right: 32px;
    top: 3px;
    border-radius: 6px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    overflow: hidden;
    cursor: pointer;
    z-index: 20;
    box-shadow: 0 6px 12px rgba(0,0,0,0.08);
    opacity: 0;
    -webkit-animation:sizeIn 0.3s forwards;
            animation:sizeIn 0.3s forwards;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
}

.page-limit-number.limit-approached{
	background: #ffce44;
}

.page-limit-number.limit-reached{
	background:#de5246;
}

@-webkit-keyframes sizeIn {
	0% {opacity: 0; -webkit-transform: scale(0.7); transform: scale(0.7);}
	100% {opacity: 1; -webkit-transform: scale(1); transform: scale(1);}
}

@keyframes sizeIn {
	0% {opacity: 0; -webkit-transform: scale(0.7); transform: scale(0.7);}
	100% {opacity: 1; -webkit-transform: scale(1); transform: scale(1);}
}

.page-limit-number span {
	position: absolute;
	left: 0;
	height: 100%;
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	opacity: 0;
	text-transform: uppercase;
	transition: 0.2s;
	background: inherit;
}

.page-limit-number:hover span {
	opacity: 1;
}

.themes-config-content {
	width: calc(100% - 270px);
	position: relative;
	padding:40px;
	float: left;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	height: 100%;
	overflow: scroll;
	-webkit-align-content: flex-start;
	        align-content: flex-start;
}

.themes-config-content.wide-fit {
	width: calc(100% - 220px);
}

.themes-config-content.wide-fit .theme-config-card,
.themes-config-content.wide-fit .theme-config-card img {
	border-radius: 0;
}

.themes-config-content.wide-fit .theme-config-card {
	box-shadow: 0 8px 16px rgba(0,0,0,0.05);
}

.themes-config-title {
	width: 100%;
	margin-bottom: 20px;
	float: left;
}

.themes-config-title h2,
.themes-config-title p {
	margin:0;
}
.add-to-favs {
	width: 30px;
	height: 30px;
	border-radius: 6px;
	background: #fff;
	border:solid 1px #efefef;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	opacity: 0;
	visibility: hidden;
	-webkit-transform: scale(0.8);
	        transform: scale(0.8);
	transition: 0.1s;
	position: absolute;
	right: 4px;
	top: 4px;
	z-index: 20;
	font-size: 14px;
}

.add-to-favs .far,
.add-to-favs .fas,
.add-to-favs .fa {
	-webkit-transform: translateY(1px);
	        transform: translateY(1px);
}

.add-to-favs.active {
	background: #E1306C;
	color: #fff;
}

.add-to-favs.active:hover {
	border:solid 1px transparent;
	background: #b91f53;
}

.add-to-favs:hover {
	border:solid 1px #333;
	background: #fafafa;
}

.theme-config-card:hover .add-to-favs {
	opacity: 1;
	visibility: visible;
	-webkit-transform: none;
	        transform: none;
}
.theme-config-card {
	width: calc(50% - 30px);
	margin:15px 15px 15px 15px;
	border-radius: 6px;
	border:solid 1px #efefef;
	cursor: pointer;
	position: relative;
	transition: 0.2s;
}


.theme-config-card img {
	position: relative;
	z-index: 2;
	width: 100%;
	display: block;
	border-radius: 6px 6px 0 0;
}

.theme-config-card p {
	width: 100%;
	margin:0;
	padding:10px;
	font-size: 12px;
	text-align: center;
	color: rgba(0,0,0,0.5);
	background: #fff;
	position: relative;
	border-radius: 0 0 6px 6px;
	z-index: 2;
}
.theme-config-card:after {
	content: " ";
	height: 20px;
	width: calc(100% - 80px);
	background: #000;
	-webkit-filter: blur(10px);
	        filter: blur(10px);
	position: absolute;
	left: 40px;
	opacity: 0;
	bottom: 0;
	transition: 0.2s;
}

.theme-config-card:hover:after,
.theme-config-card.active:after {
	opacity: 0.4;
}

.theme-config-card.active,
.theme-config-card:hover {
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}

.theme-config-card span {
	position: absolute;
	top: 5px;
	left: 5px;
	border-radius: 6px;
	padding:5px;
	text-transform: uppercase;
	font-size:12px;
	color: #fff;
	background: linear-gradient(-135deg, #81FBB8 0%, #28C76F 100%);

}
#themeSettingsModal .font-group {
	display: block;
	white-space: normal;
	overflow: visible;
	overflow: initial;
    margin: 0 0 20px 0;
    width: 100%;
    padding-bottom: 0;
}

#themeSettingsModal .font-group .font-config-preview {
	padding:10px 0px;
	position: relative;
}

#themeSettingsModal .font-group div.font-config-preview:first-child {
	padding-top: 0;
}

#themeSettingsModal .font-group div.font-config-preview:last-child {
	padding-bottom: 0;
}

#themeSettingsModal .font-group .font-config-preview h1 {
	font-size: 60px;
	margin:0;
}
#themeSettingsModal .font-group .font-config-preview h2 {
	font-size: 50px;
	margin:0;
}

#themeSettingsModal .font-group .font-config-preview h3 {
	font-size: 40px;
	margin:0;
}

#themeSettingsModal .font-group .font-config-preview h4 {
	font-size: 30px;
	margin:0;
}

#themeSettingsModal .font-group.font-group-settings {
	padding:0px 0 30px 0;
	border-top:solid 1px #efefef;
}

.input-group .input-group-inner {
	width: 100%;
	position: relative;
	margin-bottom: 10px;
	display: -webkit-flex;
	display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.input-group .input-group-inner .fa-trash {
	width: 24px;
    height: 24px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    color: #fff;
    background: #333;
    border-radius: 50%;
    position: absolute;
    right: 10px;
    top: calc(50% - 10px);
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    transition: 0.2s;
    font-size: 13px;
}

.input-group .input-group-inner:hover .fa-trash {
	opacity: 1;
	visibility: visible;
	-webkit-transform: none;
	        transform: none;
}

.input-group-inner .delete-icon {
	position: absolute;
	width: 20px;
	height: 20px;
}
.input-group .input-group-inner .custom-value-input {
	width: 100%;
}
#customFormModal {
	width: 300px;
	left: auto;
	right: 0;
}

#customFormModal .input-group {
	    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 0px;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding-bottom: 20px;
    margin-bottom: 10px;
    border-bottom: solid 1px #efefef;
}

#customFormModal .input-group label {
	margin-top: 0;
	margin-bottom: 7px;
}

#customFormModal .modal-sticky-footer {
	position: absolute;
    bottom: 10px;
    left: -10px;
    width: 100%;
    background: #fafafa;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 0 0 6px 6px;
    border: solid 1px #efefef;
    padding: 6px 0;
    height: 60px;
    opacity: 0;
    -webkit-animation:fadeInBottom 0.3s forwards;
            animation:fadeInBottom 0.3s forwards;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
}

#customFormModal .modal-content {
	width: 100%;
	padding:20px 20px 90px 20px;
}
.form-element-item {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: center;
	        align-items: center;
	font-size: 16px;
	font-weight: 600;
	border-radius: 6px;
	border:solid 1px #efefef;
	margin-bottom: 12px;
	padding:13px;
	width: 100%;
	cursor: -webkit-grab;
	cursor: grab;
}
.form-element-item:hover {
	border-color:#ccc;
}
.form-element-item img {
	width: 15px;
	height: 15px;
	object-fit: contain;
	margin-right: 8px;
}

.modal-content.no-padding {
	padding:0!important;
}

.modal-content-scroll {
	padding:30px;
	height: 100%;
	width: 100%;
	overflow: auto;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

@media (max-width: 779px) {
	.page-name-content {
		width: 100vw;
	}
	#themeSettingsModal .modal-content {
		width: 100%;
		height: 100%;
		border-radius: 0;
	}

	.themes-navigation.narrow-fit {
		width: 150px;
		padding:5px;
	}

	.themes-navigation.narrow-fit div {
		padding:10px;
	}

	.themes-navigation.narrow-fit div img {
		margin-right: 10px;
		width: 15px;
	}
	#themeSettingsModal .close {
		right: 10px;
		top: 10px;
	}
	.themes-config-content.wide-fit {
		width: calc(100% - 150px);
		padding:20px;
	}

	.themes-config-content.wide-fit .theme-config-card {
		width: 100%;
		margin:10px 0;
	}

	.themes-navigation div h4 {
		font-size: 14px;
	}
	#customFormModal .modal-sticky-footer {
		right: 0;
		bottom: 0;
	}
	.edit-body-mode .custom-form-item-wrapper {
		border: dashed 1px rgba(0,0,0,0.3)!important;
		margin-bottom: 15px;
	}
	.custom-form-item-wrapper .edit-custom-form-item {
		opacity: 1;
		visibility: visible;
	}

	#legalModal .modal-content-scroll {
		padding:0;
	}
	#legalModal .modal-content {
		width: 90%;
	}
}






.font-config-toolbar {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	margin-bottom: 20px;
	margin-top: 20px;
	-webkit-justify-content: space-between;
	        justify-content: space-between;

}


.font-config-toolbar-group {
	font-size: 14px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-flex-shrink: 0;
	        flex-shrink: 0;
}
.font-config-toolbar-group strong {
	margin-right: 10px;
	font-weight: 600;
}
.font-config-toolbar-group span {
	padding:5px 10px;
	border:solid 1px #efefef;
	cursor: pointer;
}

.font-config-toolbar-group span:hover {
	background: #fafafa;
}

.font-config-toolbar-group span:after {
	width: 5px;
	height: 5px;
	border-top: solid 2px #333;
	border-right: solid 2px #333;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
	margin-left: 6px;
	content: " ";
	display: inline-block;
}

.h-size-dropdown {
	position: absolute;
	right: 0px;
	top: calc(50% - 15px);
}

.font-config-toolbar select,
.h-size-dropdown select {
	background: #fff;
	border:solid 1px #efefef;
	outline: none;
	border-radius: 4px;
	margin-left: 5px;
	width: auto;
	height: 30px;
	font-size: 12px;
}

.h-size-dropdown select {
	margin-left: 0;
	background: #fff;
}

.nav-font-config-preview {
	width: 100%;
	height: 50px;
	border:solid 1px #efefef;
	box-shadow: 0 8px 16px rgba(0,0,0,0.1);
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	font-size: 16px;
	font-weight: 600;
}

.nav-font-config-preview a {
	margin:0 15px;
}

.button-config-preview {
	width: 100%;
	position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    float: right;
    padding: 50px 0;
    background-image: url(/assets/img/theme-config/bg.png);
    background-repeat: repeat;
    background-size: 15px;
    box-shadow: inset 0 0 208px rgba(255,255,255,1);
}
.button-config-preview .live-preview-btn {
	font-size: 11px;
	padding:3px 6px;
	border-radius: 4px;
	text-transform: uppercase;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	background: linear-gradient(0deg, #3c4665 0%, #21283F 100%);
	color: #fff;
	position: absolute;
	top: 0px;
	left: 0px;
}


.button-config-preview .explore-btn {
	margin-top: 0;
}


.button-config {
	float: left;
	width: 100%;
}
.button-color-config {
	width: 50%;
	float: left;
}

.button-switch-config {
	float: right;
	width: 50%;
}
.button-config .button-shape-selector {
	margin-bottom: 20px;
}

.color-config {
	width: 100%;
}

.color-config hr {
	opacity: 0.2;
	margin:50px 0 30px 0;
}
.color-config label {
	font-weight: bold;
	font-size: 14px;
	width: 100%;
	margin-bottom: 8px;
	display: block;
}
.color-palette-config {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-flex-wrap: nowrap;
	        flex-wrap: nowrap;
	margin-bottom: 20px;
	transition: 0.2s;
}
.color-palette-config div {
	width: 25%;
	position: relative;
	display: block;
	cursor: pointer;
}

.color-palette-config div:before {
	padding-bottom: 100%;
	width: 100%;
	content: " ";
	display: block;
}

.color-palette-config div span {
	position: absolute;
	font-size: 14px;
	width: 100%;
	bottom: 10px;
	text-align: center;
	color: #fff;
	font-weight: 600;
	text-transform: uppercase;
}
.color-palette-config.selected div:after {
	width: calc(100% - 20px);
	height: calc(100% - 20px);
	top: 10px;
	left: 10px;
	border:dashed 1px #fff;
	opacity: 0;
	border-radius: 6px;
	position: absolute;
	content: " ";
	font-size: 22px;
	color: #ccc;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.color-palette-config div:hover:after {
	opacity: 1;
}

.color-palette-config.selected div.empty:after {
	border-color: #ccc;
	opacity: 1;
	content: "+";
}

.color-palette-config div.empty:hover:after {
	border-color: #333;
	opacity: 1;
}


.color-palette-config:not(.selected):hover {
	box-shadow: 0 0 0 10px rgba(0,0,0,0.1);
}
/**LIVE MODE MYCLICK**/
