.design-block-carousel {
	position: relative;
	width: 100%;
}
.design-block-carousel.design-block-padding-small .click-carousel {
	height: 400px;
}

.design-block-carousel.design-block-padding-big .click-carousel {
	height: 700px;
}
.design-block-carousel.design-block-padding-big.layout-3 .click-carousel {
	height: 800px;
}

.design-block-carousel.design-block-padding-medium.layout-3 .click-carousel {
	height: 650px;
}

.design-block-carousel.design-block-padding-small.layout-3 .click-carousel {
	height: 500px;
}
.click-carousel {
	width: 100%;
	height: 550px;
	position: relative;
}

.layout-3 .click-carousel {
	border:50px solid transparent;
}


.layout-4 .click-carousel:before,
.layout-5 .click-carousel:before {
	display: none;
}
.layout-4 .click-carousel .click-carousel-img {
	display: none;
}

.layout-4 .click-carousel-text,
.layout-5 .click-carousel-text {
	color: #333;
	animation-delay: 0s;
}
.layout-4 .click-carousel-arrow,
.layout-5 .click-carousel-arrow {
	border-color: #333;
}

.layout-5 .click-carousel-arrow  {
	top:auto;
	left: 20px;
	bottom: 60px;
	height: 20px;
	width: 20px;
}
.layout-5 .click-carousel-arrow.next {
	right: auto;
	left: 40px;
}	
.layout-5 .click-carousel {
	max-width: initial;
	padding:0px 0px;
	margin:0px auto;
}

.layout-5 .click-carousel-img {
	width: 50%;
	position: relative;
	object-fit: cover;
	animation:fadeIn 1s forwards;
}
.layout-5 .click-carousel-text {
	padding:70px 40px;
	max-width: 550px;
	margin:0 auto;
}
.layout-5 .click-carousel-item.active {
	display: flex;
	flex-direction: row-reverse;
}

.click-carousel:before {
	position: absolute;
	z-index: 2;
	background: #000;
	opacity: 0.3;
	content: " ";
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}

.click-carousel-arrow {
	height: 40px;
	width: 40px;
	position: absolute;
	cursor: pointer;
	border-left:solid 2px #fff;
	border-bottom:solid 2px #fff;
	left: 40px;
	top: calc(50% - 20px);
	transform: rotate(45deg);
	z-index: 4;
}
.click-carousel-arrow.next {
	transform: rotate(225deg);
	left: auto;
	right: 40px;
}

.click-carousel-item {
	position: relative;
	width: 100%;
	height: 100%;
	display: none;
	overflow: hidden;
}
.click-carousel-item.active {
	display: block;
}

.click-carousel-img {
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	object-fit: cover;
	animation: clickCarouselImg 1s forwards;
}

.design-block-custom-html {
	position: relative;
	z-index: 3;
}

.click-carousel-text {
	height: 100%;
	display: flex;
	flex-direction: column;
	width: 100%;
	margin:0 auto;
	color: #fff;
	position: relative;
	z-index: 3;
	justify-content: center;
	align-items: flex-start;
	opacity: 0;
	animation: clickCarouselText 1s forwards;
	padding:30px;
	animation-delay: 0.7s;
	padding-left: 100px;
	padding-right: 100px;
}
.design-block-align-center .click-carousel-text {
	align-items: center;
	text-align: center;
}

.design-block-align-right .click-carousel-text {
	align-items: flex-end;
	text-align: right;
}

.click-carousel-text button {
	outline: none;
	border: none;
	padding:15px 30px;
	color: #333;
	border-radius: 0;
	text-transform: uppercase;
	margin-top: 40px;
	cursor: pointer;
	font-size: 14px;
}

/*.click-carousel-text h1 {
	font-size: 60px;
	margin:0;
}
.click-carousel-text h2 {
	font-size: 50px;
	margin:0;
}
.click-carousel-text h3 {
	font-size: 40px;
	margin:0;
}
.click-carousel-text h4 {
	font-size: 30px;
	margin:0;
}*/

.click-carousel-text p {
	font-size:18px;
	margin:0;
}

@media (max-width: 779px) {
	.click-carousel-text {
		padding:20px;
	}
}

.layout-2 .click-carousel-arrow,
.layout-4 .click-carousel-arrow {
	left: calc(50% - 40px);
	top: auto;
	bottom: 30px;
	height: 20px;
	width: 20px;
}
.layout-2 .click-carousel-arrow.next,
.layout-4 .click-carousel-arrow.next {
	right: calc(50% - 40px);
	left: auto;
}

@keyframes clickCarouselImg {
	0% {opacity: 0; transform: scale(1);}
	100% {opacity: 1; transform: scale(1.1);}
}

@keyframes clickCarouselText {
	0% {opacity: 0; transform: translateY(50px);}
	100% {opacity: 1; transform: translateY(0px);}
}

.block-title-hidden .click-carousel-text
.block-title-hidden .click-carousel:before  {
	display: none!important;
}

@media (max-width: 779px) {
	.layout-3 .click-carousel {
		border-width: 20px!important;
	}
	.layout-5 .click-carousel {
		flex-direction: column!important;
		height: auto!important;
	}
	.layout-5 .click-carousel-img {
		width: 100%;
		height: 320px!important;
	}
	.layout-5 .click-carousel-item.active {
		display: block!important;
	}

	.layout-5 .click-carousel-text {
		padding:60px 20px;
	}
	.design-block-align-center .click-carousel-text {
		width: 100%!important;
	}
	.layout-5 .click-carousel-arrow {
		top: calc(50% - 10px);
	}
	.layout-5 .click-carousel-arrow.next {
		left: auto;
		right: 20px;
	}
	.layout-2 .click-carousel-text  {
		padding-left: 40px;
		padding-right: 40px;
	}
	.click-carousel-arrow {
		height: 20px;
		width: 20px;
		left: 20px;
		top: auto;
		bottom: 30px;
	}
	.click-carousel-arrow.next {
		transform: rotate(225deg);
		left: auto;
		right: 20px;
	}
	.click-carousel-text h1 {
		font-size: 40px;
		margin:0;
	}
	.click-carousel-text h2 {
		font-size: 35px;
		margin:0;
	}
	.click-carousel-text h3 {
		font-size: 28px;
		margin:0;
	}
	.click-carousel-text h4 {
		font-size: 23px;
		margin:0;
	}
}