.design-block-features {
	width:100%;
	max-width: 1200px;
	padding:0 30px;
	margin:0px auto;
}
.features-block-boxes {
	margin:0 auto;
	display: flex;
	flex-wrap: wrap;
	align-content: center;
	justify-content: flex-start;
}
.layout-1 .features-block-boxes {
	margin-top: 80px;
}

/**EDIT MODE EXCEPTION**/
.features-block-boxes .List {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.features-block-boxes .List .draggable {
	width: calc(33.333% - 40px);
	margin:0 20px 20px 20px;
	cursor: move;
}

.design-block-features.layout-5 .features-block-boxes .List .draggable,
.design-block-features.layout-6 .features-block-boxes .List .draggable  {
	width: calc(50% - 40px);
	margin-bottom: 80px;
}


.features-block-boxes .List .draggable.placeholder {
	border-radius: 6px;
	border:dashed 1px #ccc;
	background:#fafafa;
	height: 220px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.features-block-boxes .List .features-block-box {
	width: 100%;
	margin:0;
}
/**EDIT MODE EXCEPTION END**/
@keyframes featuresPlaceholder {
	0% {opacity: 0.3}
	50% {opacity: 0.6}
	100% {opacity: 0.3;}
}
.design-block-feature-placeholder-wrapper {
	width:100%;
	display: flex;
}
.design-block-feature-placeholder {
	width: calc(33.333% - 40px);
	margin:0 20px 80px 20px;
	height: 220px;
	border-top: solid 200px #ccc;
	position: relative;
	opacity: 0.3;
	animation: featuresPlaceholder 1s infinite;
}

.design-block-feature-placeholder:before {
	width: 100%;
    content: " ";
    margin-top: 10px;
    border-top: 20px solid #ccc;
    border-bottom: solid #ccc 20px;
    height: 10px;
    position: absolute;
    top: 0px;
    left: 0;
}

.design-block-feature-placeholder:after {
	width: 60%;
    content: " ";
    margin-top: 10px;
    background: #ccc;
    height: 20px;
    position: absolute;
    top: 60px;
    left: 0;
}
.features-block-box {
	width: calc(33.333% - 40px);
	padding:0px;
	display: flex;
	margin:0 20px 80px 20px;
	position: relative;
	text-align: left;
	cursor: pointer;
}

.layout-5 .features-block-box,
.layout-6 .features-block-box,
.layout-9 .features-block-box {
	width: calc(50% - 40px);
	margin-bottom: 40px;
}
.layout-7 .features-block-box {
	width: 100%;
	margin-bottom: 80px;
}

.layout-5 .List .features-block-box,
.layout-6 .List .features-block-box {
	width: 100%;
}

.master-container .design-block-background-wrapper .layout-5 .features-block-box h4 {
	font-size: 28px;
	margin-top: 10px;
}
.design-block-features.layout-5 .design-block-title,
.design-block-features.layout-6 .design-block-title,
.design-block-features.layout-9 .design-block-title {
	padding:0 20px;
}	

.design-block-features.layout-5,
.design-block-features.layout-6 {
	max-width: 1400px;
}


.design-block-features.layout-7 {
	max-width: 800px;
}

.design-block-features.layout-7 .features-block-boxes {
	width: 100%;
	flex-direction: column;
	padding:0 50px;
	flex-wrap: wrap;
}

.design-block-features.layout-7 .features-block-box {
	width: 100%!important;	
}

.design-block-features.layout-7 .features-block-boxes .List {
	flex-direction: column;
}
.design-block-features.layout-7 .features-block-image {
	display: block;
	width: 120px;
	margin-right: 30px;
	height: 120px;
}
.design-block-features.layout-7 .features-block-image img {
	object-fit: contain;
	width: 100%;
	height: 100%;
}
.design-block-features.layout-7 .features-block-boxes .List .draggable {
	width:100%;
}
.layout-7 .List .features-block-box {
	width:100%;
	margin-bottom: 50px;
}
.features-block-box:hover .hover-box {
	opacity: 1;
	visibility: visible;
	background: rgba(255,255,255,0.7);
}
.features-block-description {
	position: relative;
	text-align: left!important;
	width: 100%;
}

.features-block-description h4 {
	font-weight: bold!important;
}
.features-block-box .number {
	font-size: 40px;
	font-weight: bold;
	opacity: 0.1;
	line-height: 40px;
	margin-right: 10px;
}
.features-block-box h4,
.features-block-box p {
	margin:0;
	padding:0;
}


.master-container .design-block-background-wrapper .features-block-box h4 {
	font-size: 28px;
	font-weight: bold;
	margin-bottom: 0px;
	margin-top: 20px;
}
.master-container .design-block-background-wrapper .features-block-box p {
	/*font-size: 14px;*/
	line-height: 25px;
}
.features-block-image {
	display: none;
	position: relative;
	width: 100%;
}

.features-block-image .fa,
.features-block-image .far,
.features-block-image .fas {
	width:100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	padding-top: 14px;
	position: absolute;
	left: 0;
	font-weight: 100;
	top: 0;
}

.layout-4 .features-block-image .far,
.layout-4 .features-block-image .fas {
	position: relative;
	justify-content: flex-start;
}

.layout-2 .features-block-image .far,
.layout-5 .features-block-image .far,
.layout-5 .features-block-image .fas {
	align-items: center;
}
.design-block-features.layout-3 .features-block-image .fas {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}
.design-block-features.layout-3 .features-block-image:before,
.design-block-features.layout-5 .features-block-image:before,
.design-block-features.layout-9 .features-block-image:before {
	content: " ";
	padding-top: 100%;
	display: block;
}

.design-block-features.layout-5 .features-block-image:before {
	padding-top: 80%;
}

.design-block-features.layout-6 .features-block-image {
	display: none!important;
}

.design-block-features.layout-3 .features-block-image:after {
	width: 100%;
	height: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	content: " ";
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 100%);
	z-index: 2;
}

.design-block-features.layout-3 .features-block-image:before {

}

.design-block-features.layout-2 .features-block-image,
.design-block-features.layout-9 .features-block-image  {
	display: block;
	width: 100%;
	position: relative;
}
.design-block-features.layout-2 .features-block-image:before,
.design-block-features.layout-9 .features-block-image:before {
	content: " ";
	padding-top: 100%;
	display: block;
}
.design-block-features.layout-2 .features-block-image img {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	object-fit: cover;
	left: 0;
}

.design-block-features.layout-3,
.design-block-features.layout-2   {
	max-width: 1200px;
}
.design-block-features.layout-3 .features-block-image,
.design-block-features.layout-4 .features-block-image,
.design-block-features.layout-5 .features-block-image,
.design-block-features.layout-9 .features-block-image {
	display: block;
}

.design-block-features.layout-3 .features-block-description,
.design-block-features.layout-9 .features-block-description {
	position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px;
    color: #fff;
    z-index: 3;
}

.design-block-features.layout-9 .features-block-description {
	transition: 0.3s;
	background: rgba(0,0,0,0.4);
	opacity: 0;
	padding-top: 60px;
	justify-content: center;
	align-items: center;
	color: #fff!important;
}

.design-block-features.layout-9 .features-block-box .features-block-description {
	opacity: 1;
	padding-top: 20px;
}
.design-block-features.layout-9 .features-block-description h4,
.design-block-features.layout-9 .features-block-description p {
	color: #fff!important;
	text-align: center;
}
.design-block-features.layout-9 {
	max-width: 1400px;
}

.design-block-align-center .features-block-description {
	text-align: center;
}

.design-block-align-right .features-block-description {
	text-align: right;
}

.design-block-features.layout-9 .features-block-box .hover-box {
 background: transparent;
 justify-content: flex-start;
 align-items: flex-start;
 padding:15px;
}


.design-block-features.layout-3 .features-block-description h4,
.design-block-features.layout-3 .features-block-description p {
	color: #fff!important;
}

.design-block-features .design-block-title {
	width: 100%; 
	margin-bottom: 50px;
}

.design-block-features.layout-8  {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
}

.design-block-features.layout-8 .features-block-boxes .List .draggable {
	width: 50%;
	margin:0;
	padding:20px;
}
.design-block-features.layout-8 .features-block-boxes .List .features-block-box {
	margin:0;
	padding:0;
	width: 100%;
}
.design-block-features.layout-8 .features-block-boxes .features-block-box {
	width: 50%;
	flex-direction: column;
	margin:0;
	padding:20px;
}

.design-block-features.layout-8 .features-block-box .number {
	font-size: 23px;
	line-height: normal;
	margin-right: 0;
	border-bottom:solid 1px rgba(0,0,0,0.3);
	padding-bottom: 6px;
	font-weight: normal;
	opacity: 1;
}

.design-block-features.layout-8 .design-block-title {
	width: 40%;
	padding-right: 70px;
	margin-top: 10px;
}

.design-block-features.layout-8 .features-block-boxes {
	width: 60%;
}

.design-block-features.layout-3 .features-block-image img,
.design-block-features.layout-5 .features-block-image img,
.design-block-features.layout-9 .features-block-image img {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	object-fit: cover;
	left: 0;
}

.design-block-features.layout-4 .features-block-image {
	display: flex;
	/*justify-content: center;*/
	align-items: center;
}
.design-block-features.layout-4 .features-block-image img {
	height: 70px;
}
.design-block-features.layout-2 .features-block-box .number,
.design-block-features.layout-3 .features-block-box .number,
.design-block-features.layout-4 .features-block-box .number,
.design-block-features.layout-5 .features-block-box .number,
.design-block-features.layout-6 .features-block-box .number,
.design-block-features.layout-7 .features-block-box .number,
.design-block-features.layout-9 .features-block-box .number  {
	display: none;
}
.design-block-features.layout-4 .features-block-box {
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin:35px 20px;
}
.design-block-features.layout-2 .features-block-box,
.design-block-features.layout-5 .features-block-box,
.design-block-features.layout-6 .features-block-box {
	flex-direction: column;
}
@media (min-width: 779px) {
	.row-1 .features-block-box {
		width: calc(100% - 0px)!important;
	}
	.row-2 .features-block-box {
		width: calc(50% - 40px)!important;
	}
	.row-3 .features-block-box {
		width: calc(33.333% - 40px)!important;
	}
	.row-4 .features-block-box {
		width: calc(25% - 20px)!important;
		margin:0 10px 40px 10px!important;
	}
	.row-5 .features-block-box {
		width: calc(20% - 20px)!important;
		margin:0 10px 40px 10px!important;
	}		
}
@media (max-width: 779px) {
	.design-block-features {
		padding:0 20px;
		margin-top: 20px;
	}
	.design-block-features.layout-8 .design-block-title {
		width: 100%!important;
		padding-right: 30px;
	}

	.design-block-features.layout-8 .features-block-boxes {
		width: 100%;
	}
	.design-block-features.layout-8 .features-block-box .number {
		margin-bottom: 0;
	}

	.design-block-features.layout-8 .features-block-box h4 {
		margin-top: 10px!important;
	}
	.features-block-boxes .List .draggable {
		width: 100%!important;
		margin-bottom: 0;
	}
	.features-block-boxes .features-block-box {
		width: 100%!important;
		margin-bottom: 30px!important;
	}
	.layout-4 .features-block-boxes {
		padding:0 30px;
	}
	.master-container .design-block-background-wrapper .layout-5 .features-block-box h4 {
 		font-size: 24px;
 		margin-bottom: 0;
 	}
 	.layout-1 .features-block-boxes {
		margin-top: 0;
	}
	.design-block-features.layout-7 .features-block-boxes {
		padding:0;
	}
	.design-block-features.layout-9 .features-block-description {
		opacity: 1;
	}
	.master-container .design-block-background-wrapper .features-block-box h4 {
		font-size: 26px!important;
	}
	.master-container .design-block-background-wrapper .features-block-box p {
		font-size: 18px!important;
	}
	.design-block-features .design-block-title {
		padding-left: 0px!important;
		padding-right: 0px!important;
	}
	.features-block-box {
		margin:0 0px 60px 0px!important;
	}
}