#articleContent  {
	width: 100%;
	/*max-width: 1440px;*/
	margin:0 auto;
	padding:0px 83px 0px 83px;
	justify-content: center;
	height: auto;
	min-height: 600px;
}

.nav-2 #articleContent .article-image {
	height: 100vh;
}

#articleContent .article-image {
	padding-right: 30px;
    display: flex;
    justify-content: center;
    align-content: space-around;
    align-items: center;
    position: relative;
    height: calc(100vh - 80px);
    min-height: 600px;
    max-height: 800px;
}
#articleContent .video-frame {
	width: 100%;
	height: 100%;
	position: relative;
}

.article-1 #articleContent .video-frame,
.article-2 #articleContent .video-frame {
	height: 300px;
}

.article-1 #articleContent .video-frame:before,
.article-2 #articleContent .video-frame:before {
	content: " ";
	padding-top: 66.666%;
	display: block;
}

.article-1 #articleContent .video-frame iframe,
.article-2 #articleContent .video-frame iframe {
	position: absolute;
	left: 0;
	top: 0;
}

.article-8 .flex-container#articleContent {
	padding:0;
	height: 600px;
	min-height: initial;
	width: 100vw;
	display: flex!important;
	padding:0!important;
}

.article-8 #articleContent .article-content {
	position: relative;
	color: #fff;
	z-index: 3;
	padding:0;
	min-height: initial;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
	padding:50px;
	max-width: 1200px;
	margin:auto;
}

.article-8 #articleContent.design-page-align-center .article-content {
	align-items: center;
}
.article-8 #articleContent.design-page-align-right .article-content {
	align-items: flex-end;
}

.article-8 .article-content h1 {
	margin-bottom: 0;
}

.article-8 #articleContent .article-image {
	position: absolute;
	width: 100%;
	height: 100%!important	;
	min-height: initial;
	padding:0!important;
}

.article-8 #articleContent .article-image .hover-box {
	width: 100%;
    border: none;
    flex-direction: row;
    height: 40px;
    z-index: 40;
    top: auto;
    bottom: 20px;
    padding:20px;
}

.article-8 #articleContent:hover .article-image .hover-box {
	visibility: visible;
	opacity: 1;

}
.article-8 #articleContent .article-image .hover-box .change-image-btn {
	margin:0 5px;
}

.article-8 #articleContent .article-image img.mySlides,
.article-8 #articleContent .article-image iframe {
	width: 100%;
	height: 100%;
	object-fit: cover;
	max-height: initial!important;
}

#articleContent .video-frame iframe {
	width: 100%;
	height: 100%;
}

#articleContent .article-image.music-embed .cover-video:before {
	padding-bottom: 100%;
}

#articleContent .article-image iframe {
	width: 100%;
}
#articleContent .article-image.video {
	/*height: auto;*/
	position: relative;
}

#articleContent .article-image.video .cover-video {
	animation-delay: 0.5s;
}

#articleContent .article-image.video iframe {
	z-index: 4;
}
#articleContent .article-image img {
	opacity: 0;
    animation: imageIn 1s ease forwards;
    animation-delay: 0s;
    /*box-shadow: 0px 13px 50px 0 rgba(0,0,0,0.1);*/
    max-height: 100%;
    max-width: 100%;
    height: auto;
    /*border-radius: 6px;*/
}
.article-content {
	/*display: flex;
	flex-direction: column;*/
	padding: 10px 0px 30px 30px;
	/*justify-content: center;
	align-items: flex-start;*/
}
.article-content img {
	width: auto;
	height: auto;
	max-width: 100%;
	align-self: center;
}
.article-content h1,
.article-content h2,
.article-content h3,
.article-content h4 {
    padding-bottom: 0px;
    margin-bottom: 0px;
    position: relative;
    overflow: scroll;
    margin-bottom: 0;
    margin-top: 0;
}

.article-content.with-description {
	margin:auto;
}

.article-content.with-description h1 {
	overflow:inherit;
}

.article-content.with-description .btn {
	justify-content: flex-start;
}

.article-content h1.article-big-title {
	margin-bottom: 20px;
}
.article-content .article-description {
	font-size: 18px;
	margin-top: 0;

	line-height: 25px;
}


.article-content h1 a {
	position: relative;
	margin:0px;
	padding:0;
}

.article-content h1 a:after {
	position: absolute;
	width: 100%;
	bottom: 0px;
	content: " ";
	border-bottom: dashed 1px #000;
	left: 0px;
}

.article-2 #articleContent {
	flex-direction: row-reverse;
}

.article-2 #articleContent .article-image {
 	padding-left: 30px;
    display: flex;
    padding-right: 0;
    justify-content: center;
    align-content: space-around;
}

.article-2 #articleContent .article-content {
	padding-left: 30px;
	padding-right: 50px;
}

.article-2 #articleContent .article-content .article-stats {
	text-align: right;
}
.article-3 .nav-bar {
	background:#fff;
}
.article-3 #articleContent {
	flex-direction: column;
	padding:0px 0px 0px 0px;
	display: block;
	height: auto;
}
.article-3 #articleContent .article-image {
	width: calc(100% - 0px);
	margin:0 auto;
	padding:0;
	height: 450px;
	min-height: initial;
}
.article-3 #articleContent .article-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 0;
	box-shadow: none;
	object-position: 50% 50%;
}
.article-3 #articleContent .article-content {
	margin:0 auto;
	width: 100%;
	max-width: 700px;
	margin-top: 40px;
	padding-bottom: 60px;
}
.article-3 #articleContent .article-image.video {
	height: 550px;
	max-height: 700px;
}
.article-3 #articleContent .article-image.video iframe,
.article-3 #articleContent .article-image.video .cover-video {
	height: 100%;
	border-radius: 0;
}

.article-4 #articleContent {
	padding:0px 0px 0px 0px;
}
.article-5 #articleContent {
	padding:0px 0px 0px 0px;
	flex-direction: row-reverse;
}
.article-4 #articleContent .article-content {
	padding-left: 60px;
	padding-right: 60px;
	max-width: 600px;
}
.article-5 #articleContent .article-content {
	padding-right: 50px;
}
.article-4 #articleContent .article-image,
.article-5 #articleContent .article-image {
	padding-right: 0;
	max-height: initial;
	padding-bottom:0;
}

.edit-body-mode.article-4 #articleContent .article-image,
.edit-body-mode.article-5 #articleContent .article-image  {
	height: calc(100vh - 130px);
}

.nav-2.edit-body-mode.article-4 #articleContent .article-image,
.nav-2.edit-body-mode.article-5 #articleContent .article-image  {
	height: calc(100vh - 50px);
}
.article-4 #articleContent .article-image img,
.article-5 #articleContent .article-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 0;
	box-shadow: none;
}

.article-5 #articleContent .article-content.with-description {
	padding:50px;
	max-width: 600px;
}

.article-6 #articleContent {
	padding:0;
	position: relative;
	min-height: 500px;
}

.article-6 #articleContent .article-content {
	padding:50px 0;
	margin-top: 50px;
	margin-bottom: 50px;
}

.article-6 #articleContent .article-image,
.article-7 #articleContent .article-image {
	display: none;
}

.article-7 #articleContent {
	flex-wrap: wrap;
}

.article-7 #articleContent .article-image {
	width: 100%;
	padding:15px 0;
}
.article-7 #articleContent .article-content {
	width: 100%;
	max-width: 600px;
}
#about_map,
#about_music,
#about_video {
	width: 100%;
	height: 100%;
}
#about_map iframe,
#about_music iframe,
#about_video iframe {
	width: 100%;
	height: 100%;
	border:none;
}
@media (min-width: 769px) {
	.article-8.nav-2 .flex-container#articleContent {
		width: calc(100vw - 80px);
	}
}
@media (max-width: 1110px) {
	#articleContent .article-image {
		width: 50%;
	}

	#articleContent .article-content {
		width: 50%;
	}

	.article-6 #articleContent .article-content {
		width: 60%;
	} 
}

@media (max-width: 779px) {
	.flex-container h1 {
		font-size: 28px;
	}
	#articleContent {
		min-height: initial!important;
	}
	.article-1 #articleContent .article-image,
	.article-2 #articleContent .article-image {
		height: auto!important;
		padding-top: 20px;
		padding-bottom: 0!important;
		margin-bottom: 20px!important;
	}
	.article-1 #articleContent .article-image,
	.article-2 #articleContent .article-image {
		max-height: 500px;
	}
	.flex-container#articleContent {
		padding:0px 0px 15px 0px!important;
		display: block;
	}
	.article-6 .article-content h1.article-big-title {
		justify-content: flex-start;
	}
	#articleContent .article-image {
		width: 100%!important;
		padding:0 0px 30px 0!important;
		max-height: initial;
		height: 350px!important;
		min-height: initial;
	}
	#articleContent .article-image img {
		border-radius: 0;
		box-shadow: none;
		max-height: 350px;
	}

	#articleContent .article-content {
		width: 100%;
		padding: 0px 30px 30px 30px!important;
		height: auto!important;
		margin-top: 0!important;
	}
	#articleContent .article-content h1 {
		margin-top: 0;
		overflow:hidden!important;
		width: 100%;
	}
	#articleContent .article-content .article-stats {
		margin-bottom: 0px;
		padding-top: 0;
		box-shadow: none;
		background: transparent;
	}

		.article-6 #articleContent .article-content {
		padding:50px 30px!important;
	}

	.article-6 #articleContent {
		min-height: initial!important;
		height: auto!important;
	}

	.article-6 #articleContent .article-content {
		width: 100%;
	}
	.article-8 #articleContent .article-content {
		margin-top: auto!important;
	}
}
